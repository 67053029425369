import styled from 'styled-components';

import theme2 from '../../../themes/theme2';

export const Container = styled.div``;

export const NoRecord = styled.div`
  text-align: center;
`;

export const Filters = styled.div`
  display: flex;
  margin-bottom: ${theme2.metrics.baseMargin * 2}px;

  button {
    margin-top: 22.5px;
  }

  input {
    height: 40px;
  }
`;
