import { subDays } from 'date-fns';
import moment from 'moment';
/* eslint-disable func-names */
import * as Yup from 'yup';

import { PRESCRIPTION_ANTIMICROBIAL, PRESCRIBER_VET } from './types';

const defaultMessage = 'Campo obrigatório';

const validateBuyer = (prescriptionType, prescriberType, schema) => {
  return parseInt(prescriptionType, 10) !== PRESCRIPTION_ANTIMICROBIAL &&
    parseInt(prescriberType, 10) !== PRESCRIBER_VET
    ? schema.required(defaultMessage)
    : schema;
};

const validatePatient = (prescriptionType, prescriberType, schema) => {
  return parseInt(prescriptionType, 10) === PRESCRIPTION_ANTIMICROBIAL &&
    parseInt(prescriberType, 10) !== PRESCRIBER_VET
    ? schema.required(defaultMessage)
    : schema;
};

const isDateInThePast = Yup.mixed().test(
  'is-date-in-the-past',
  'Não é possível lançar uma receita com data futura',
  function (value) {
    // Verifica se o valor é uma instância de Date
    if (!(value instanceof Date)) {
      return true;
    }
    // Cria um objeto Date com a data atual
    const today = new Date();
    // Verifica se a data informada é menor ou igual à data atual
    return value <= today;
  }
);

const validation = Yup.object().shape({
  prescription: Yup.object().shape({
    prolongedUse: Yup.mixed().when(
      'prescriptionType',
      (prescriptionType, schema) =>
        parseInt(prescriptionType, 10) === PRESCRIPTION_ANTIMICROBIAL
          ? schema.required(defaultMessage)
          : schema
    ),
    prescriptionType: Yup.mixed().required(defaultMessage),
    createdAt: isDateInThePast.required(defaultMessage),
    prescriptionNumber: Yup.string().when(
      'prescriptionType',
      (prescriptionType, schema) =>
        parseInt(prescriptionType, 10) !== PRESCRIPTION_ANTIMICROBIAL
          ? schema.required(defaultMessage)
          : schema
    ),
  }),
  buyer: Yup.object().shape({
    name: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validateBuyer(prescriptionType, prescriberType, schema)
    ),
    documentType: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validateBuyer(prescriptionType, prescriberType, schema)
    ),
    documentNumber: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validateBuyer(prescriptionType, prescriberType, schema)
    ),
    documentState: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validateBuyer(prescriptionType, prescriberType, schema)
    ),
    shippingAgency: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validateBuyer(prescriptionType, prescriberType, schema)
    ),
  }),
  patient: Yup.object().shape({
    name: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validatePatient(prescriptionType, prescriberType, schema)
    ),
    dateOfBirth: Yup.date().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        parseInt(prescriptionType, 10) === PRESCRIPTION_ANTIMICROBIAL &&
          parseInt(prescriberType, 10) !== PRESCRIBER_VET
          ? schema
            .max(
              moment(new Date()).endOf('day'),
              'Data de nascimento maior que a data de hoje'
            )
            .required(defaultMessage)
          : schema
    ),
    genre: Yup.string().when(
      ['prescriptionType', 'prescriberType'],
      (prescriptionType, prescriberType, schema) =>
        validatePatient(prescriptionType, prescriberType, schema)
    ),
  }),

  prescriber: Yup.object().shape({
    name: Yup.string().required(defaultMessage),
    referenceId: Yup.number().required(defaultMessage),
  }),
});

export default validation;
