import React, { Fragment } from 'react';
import { Card } from '../modules';
import PropTypes from 'prop-types';
import './trnCentreResume.scss';
import { currencyString } from '../../utils/masks';

function TrnCentreResumeComponent({
  title,
  handleClose,
  payload,
  handleCancelTransaction,
  handleConfirmTransaction,
  handleClickDetail,
  handleClickAction
}) {

  function getTableHeader() {
    return (
      <div className="row">
        <div className="cell header-cell cell-status">Situação</div>
        <div className="cell header-cell cell-hour">Hora</div>
        <div className="cell header-cell cell-name">Nome do consumidor</div>
        <div className="cell header-cell cell-cardNumber">CPF/Cartão</div>
        <div className="cell header-cell cell-nsu">Nsu</div>
        <div className="cell header-cell cell-detail">Detalhes</div>
        <div className="cell header-cell cell-action">Ações</div>
      </div>
    )
  }

  function getTableSubRow(product) {
    return (
      <div class="sub-row sub-row-infos">
        <div class="sub-cell cell-barcode">{product.barcode}</div>
        <div class="sub-cell cell-description">{product.name ? product.name : "Não localizado"}</div>
        <div class="sub-cell cell-requestedQuantity">{product.requestedQuantity}</div>
        <div class="sub-cell cell-authorizedQuantity">{product.authorizedQuantity}</div>
        <div class="sub-cell cell-discountValue">{currencyString(product.discountValue / 100)}</div>
      </div>
    )
  }

  function getEmptyMessage() {
    return (
      <div className='div-empty-message'>
        Nenhuma transação encontrada no dia solicitado
      </div>
    )
  }

  function getDateYesterday() {
    var currentDate = new Date(); // Obtém a data atual
    currentDate.setDate(currentDate.getDate() - 1); // Define a data para o dia anterior
    return currentDate.toLocaleDateString();
  }

  function getStatusDescription(description) {
    switch (description) {
      case "CONFIRMA": {
        return "Confirmada"
      }
      case "ANULA": {
        return "Anulada"
      }
      case "EFETIVA": {
        return "Pendente"
      }
      default:
        return description
    }
  }

  function getTableRow(sale) {
    return (
      <Fragment>
        <div className={sale.stageTransaction === "EFETIVA" ? "row row-infos row-alert" : "row row-infos"}>
          <div className="cell cell-status">{getStatusDescription(sale.stageTransaction)}</div>
          <div className="cell cell-hour">{sale.centralHourString}</div>
          <div className="cell cell-name">{sale.consumer && sale.consumer.name ? sale.consumer.name : "Não localizado"}</div>
          <div className="cell cell-cardNumber">{sale.consumer && sale.consumer.consumerId ? sale.consumer.consumerId : sale.consumer && sale.consumer.cardId ? sale.consumer.cardId : "Não localizado"}</div>
          <div className="cell cell-nsu">{sale.transaction.transactionCode}</div>
          <div class="cell cell-detail">
            <i className="material-icons" onClick={() => handleClickDetail(sale)}>{sale.showDetail ? "remove_circle_outline" : "add_circle_outline"}</i>
            {/* <Button className="btn-default btn-purple" onClick={() => handleClickDetail(sale)}>Detalhes</Button> */}
          </div>

          <div class="cell cell-button cell-action">


            <td className="more-options">
              <i
                onClick={() => handleClickAction(sale)}
                data-testid="more-options"
                className="material-icons"
              >
                more_vert
              </i>
              {sale.showActions &&
                <div className="dropdown-content" data-testid='data-testid="more-options"'>
                  {sale.stageTransaction === "EFETIVA" ?
                    <Fragment>
                      <a
                        onClick={() => handleConfirmTransaction(sale.transaction.transactionCode)}
                      >
                        Confirmar
                      </a>
                      <a
                        onClick={() => handleCancelTransaction(sale.transaction.transactionCode)}
                      >
                        Cancelar
                      </a>
                    </Fragment>
                    : <a>
                      Indisponível
                    </a>}
                </div>
              }
            </td>



            {/* <Button className="btn-default btn-outline" onClick={() => handleConfirmTransaction(sale.transaction.transactionCode)}>CONFIRMAR</Button>
              <Button className="btn-default btn-outline" onClick={() => handleCancelTransaction(sale.transaction.transactionCode)}>ANULAR</Button> */}
          </div>

        </div>
        {
          sale.showDetail &&
          <div class="sub-row">
            <div class="sub-cell header-cell header-cell-barcode">Código de barras</div>
            <div class="sub-cell header-cell header-cell-description">Descrição</div>
            <div class="sub-cell header-cell header-cell-requestedQuantity">Solicitado</div>
            <div class="sub-cell header-cell header-cell-authorizedQuantity">Autorizado</div>
            <div class="sub-cell header-cell header-cell-discountValue">Valor desconto</div>
          </div>
        }
        {sale.showDetail && sale.products && sale.products.map((product) => { return (getTableSubRow(product)) })}

      </Fragment >
    )
  }

  return (
    <Card title={title}
      handleClose={handleClose}
      closeEnable
      id={"trnCentreResume"}>
      <div className="table">
        <div className='div-message'>Exibindo apenas transações do Portal da Drogaria do dia {getDateYesterday()}</div>
        {(payload && payload.sales.length > 0) ? getTableHeader() : getEmptyMessage()}
        {payload && payload.sales && payload.sales.map((sale) => { return (getTableRow(sale)) })}
      </div>
    </Card>
  );
}

TrnCentreResumeComponent.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

TrnCentreResumeComponent.defaultProps = {};

export default TrnCentreResumeComponent;
