import React from 'react';

import { Card, Table2, Button } from '../modules';
import { ColumnIdentifier, ColumnSearch, OptionButton, Form } from './styles';

class PreOrderList extends React.Component {
  actionsTable = (row) => (
    <span>
      <OptionButton
        className='btn-outline btn-sm'
        onClick={() => this.handleClickUtil(row)}
      >
        utilizar
      </OptionButton>
      <OptionButton
        className='btn-outline btn-sm'
        onClick={() => this.handleClickDel(row)}
      >
        excluir
      </OptionButton>
    </span>
  );

  getIdentification = (row) => row.recoveryDesc || row.recoveryId;

  tableConfig = {
    columns: [
      { title: 'Identificador', customCellrender: this.getIdentification },
      { title: 'Cliente', label: 'customer' },
      { title: 'Vendedor', label: 'seller' },
      { title: 'Total', label: 'total', currencyFormat: true },
      { title: 'Data de Criação', label: 'concludedAt' },
      { customCellrender: this.actionsTable },
    ],
  };

  constructor(props) {
    super(props);
    this.handleClickUtil = this.handleClickUtil.bind(this);
    this.handleClickDel = this.handleClickDel.bind(this);

    this.inputRef = React.createRef();
  }

  handleClickUtil(line) {
    if (line) {
      this.props.onSelectPreOrder(line.recoveryId);
    }
  }

  handleClickDel(line) {
    if (line) {
      this.props.onDeletePreOrder(line);
    }
  }

  handleRef = (ref) => {
    this.inputRef.current = ref;
    ref && ref.focus();
  };

  handleFind = () => {
    this.props.handleFind(this.inputRef.current.value);
  };

  handleKeyDown = (e) => {
    e.key === 'Enter' && this.handleFind();
  };

  render() {
    const { title, listItems, handleClose, handleRefreshClick } = this.props;
    return (
      <Card
        closeEnable
        refreshEnable
        title={title}
        handleClose={handleClose}
        handleRefreshClick={handleRefreshClick}
      >
        <div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <ColumnIdentifier>
              <Form.Label>Buscar por identificador</Form.Label>
              <Form.Control
                required
                id='id-search'
                placeholder='Identificador'
                ref={this.handleRef}
                onKeyDown={this.handleKeyDown}
              />
            </ColumnIdentifier>
            <ColumnSearch xs='auto'>
              <Button
                className='btn-large btn-purple btn-custom'
                onClick={this.handleFind}
              >
                <i className='material-icons'>search</i>
              </Button>
            </ColumnSearch>
          </Form>
          {listItems.length ? (
            <Table2 config={this.tableConfig} data={listItems} />
          ) : (
            <span>Nenhuma informação encontrada</span>
          )}
        </div>
      </Card>
    );
  }
}

export default PreOrderList;
