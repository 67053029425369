import styled from 'styled-components';

import theme from '../../themes/theme2';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${theme.metrics.sidebarWidth}px;
  height: 100vh;
  overflow: hidden;

  .flex {
    display: flex;
  }

  .column {
    flex-direction: column;
  }

  .grow {
    flex-grow: 1;
    min-width: 0;
  }
`;

export const Views = styled.div`
  width: 100%;
`;

export const MainWrapper = styled.div`
  width: ${({ hideCoupon }) => (hideCoupon ? '100%' : '70%')};
`;

export const Coupon = styled.div`
  width: 30%;
  height: calc(100vh - 50px);
`;