import styled from 'styled-components';

export const Title = styled.div`
  h1 {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 131.4%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #212529;
  }

  h3 {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  }
`;

export const BoxContainer = styled.section`
  background: #f4f8fb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
  height: 67px;
  padding: 10px;

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .descricao {
      padding: 0px 15px;
      display: flex;
      align-items: center;

      span {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #60656c;
      }
    }

    .btn {
      padding: 0px;
    }
  }
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid #cccccc;
`;
