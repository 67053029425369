import React, { useState, useEffect } from 'react';
import './password-input-component.scss';

const PasswordInput = React.forwardRef((props, ref) => {
    const {
        value,
        onChange,
        className,
        placeholder,
        onKeyDown,
    } = props;
    const inputTypes = {
        Text: 'text',
        Password: 'password'
    }
    const [inputType, setInputType] = useState(inputTypes.Password);
    const [focused, setFocus] = useState(false);
    const [data, setData] = useState(value);

    function toggleInputType() {
        setInputType(inputType === inputTypes.Text ? inputTypes.Password : inputTypes.Text)
    }
    function onFocus() {
        setFocus(true);
    }
    function onBlur() {
        setFocus(false);
    }
    function onChangeLocal(e) {
        setData(e.target.value);
        if (onChange)
            onChange(e);
    }
    useEffect(() => {
        setData(value);
    }, [value]);

    return (
        <div id="passContainer" className={[className, (focused ? 'active' : '')].join(' ')} >
            { inputType === inputTypes.Text && <input onKeyDown={onKeyDown} type="text" ref={ref} onChange={onChangeLocal} defaultValue={data} onFocus={onFocus} onBlur={onBlur} placeholder={placeholder}></input>}
            { inputType === inputTypes.Password && <input onKeyDown={onKeyDown} type="password" ref={ref} onChange={onChangeLocal} defaultValue={data} onFocus={onFocus} onBlur={onBlur} placeholder={placeholder}></input>}
            <i className="material-icons" onClick={toggleInputType}>visibility</i>
        </div>);
})

export default PasswordInput