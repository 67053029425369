import styled from 'styled-components';

const Label = styled.label`
font-family: 'Dosis';
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;
align-items: center;
color: #000000;
margin-bottom: .3rem;
`

export default Label;