import styled from 'styled-components';
import theme from '../../../themes/default';

export const InputWrapper = styled.div`

  flex: auto;
  
  .input__container {
    position: relative;
    background-color: #fff;
    text-align: center;
    margin-top: 0px;
    width: 100%;
    height: 50px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;

    img, .icon {
      position: absolute;
      width: 55x;
      height: 51px;
      padding: 8px;
      top: 0;
      left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      margin-left: 8px;
      margin-right: 8px;
      font-size: 17px;
      height: 35px;
      width: 100%;
      border: transparent;
      outline: none;
      color: ${theme.palette.grayscale.ultraDark};

      &::placeholder { color: #ccc; }
      &:focus { outline: none; }
    }

    .icon~input, img~input {
      padding-left: 47px;
    }
  }
`;

export default InputWrapper;
