import styled from 'styled-components';

import theme2 from '../../../themes/theme2';

export const CepHeader = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    padding: 0;
    background: transparent;
    border: 0;
    color: ${theme2.palette.link};
    align-self: flex-end;
    font-size: 14px;
    outline: none;
    margin-bottom: 2px;
    line-height: 1.5;
  }
`;
