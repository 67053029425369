import React from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules';
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import { IncreasePriceModalWrapper } from './style';

export class IncreasePrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
    };

    this.modalIncreasePrice = React.createRef();
  }

  componentDidMount() {
    this.modalIncreasePrice.current.open();
  }

  componentWillUnmount() {
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.handleConfirmIncreasePrice();
    }
  };

  render() {
    const {
      handleCloseModalIncreasePrice,
      handleConfirmIncreasePrice,
      handleChangeIncreaseValue,
      increaseValueTotal,
      showIncreaseValueValidationMessage,
      itemSelected,
    } = this.props;

    return (
      <IncreasePriceModalWrapper>
        <Modal
          title='ACRÉSCIMO NO VALOR'
          id='increase-price-component'
          closable={false}
          closeOnOverlayClick
          ref={this.modalIncreasePrice}
          onClickClose={handleCloseModalIncreasePrice}
          className='increaseprice-modal'
          style={{
            width: '30vw',
          }}
        >
          <Form autoComplete='off'>
            <Modal.Body className='increaseprice-modal-form'>
              <Form.Group className='increaseprice-modal-discount'>
                <Form.Group className='increaseprice-modal-discount-items'>
                  <Form.Label>Valor total</Form.Label>
                  <CurrencyInput
                    id='inputTotal'
                    autoFocus={true}
                    prefix='R$'
                    decimalSeparator=','
                    maxLength={11}
                    thousandSeparator='.'
                    required
                    value={increaseValueTotal}
                    onChange={handleChangeIncreaseValue}
                    onKeyDown={this.handleKeyDown}
                  ></CurrencyInput>
                </Form.Group>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id='btn-cancel'
                onClick={handleCloseModalIncreasePrice}
                className='btn-default btn-outline btn-cancel'
              >
                Cancelar
              </Button>
              <Button
                id='btn-confirm'
                className='btn-default btn-purple btn-custom'
                onClick={handleConfirmIncreasePrice}
              >
                Confirmar
              </Button>
              {showIncreaseValueValidationMessage && (
                <span className='user-without-permission'>
                  {"Valor de acréscimo deve ser maior que R$ " + itemSelected.originalPriceValue}
                </span>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </IncreasePriceModalWrapper>
    );
  }
}

export default IncreasePrice;
