import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input';
import { Card, Button } from '../modules';
import { PaymentMoneyWrapper, PaymentMoneyInput } from './style';

class PaymentTef extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTef: this.props.amount ? this.props.amount : '',
      showValueLessThanZeroError: false
    };
  }

  pressKey = e => {
    if (e.key === 'Enter') {
      this.props.handleClickPay(this.state.totalTef);
    }
  }

  updatePaymentValue = () => {
    const value = this.refs.amountTotal.state.value;

    this.setState({
      totalTef: value < this.props.amount ? value : this.props.amount,
      showValueLessThanZeroError: false
    });
  }

  clearValue = () => {
    this.setState({ totalTef: 0 });
  }

  handleConfirm = () => {
    if (this.state.totalTef === 0) {
      this.setState({ showValueLessThanZeroError: true });
      return;
    }
    
    this.props.handleClickPay(this.state.totalTef);
  }

  render() {
    const { totalTef } = this.state;
    return (
      <Card title="TEF" subTitle="Informe o valor" handleClose={this.props.handleClose}
      urlIcone={this.props.image} closeEnable>
        <PaymentMoneyWrapper>
          <PaymentMoneyInput>
            <CurrencyInput
              id="inputPaymentTef"
              ref="amountTotal"
              value={totalTef}
              autoFocus
              selectAllOnFocus
              onChange={this.updatePaymentValue}
              onKeyPress={this.pressKey}
              decimalSeparator=","
              thousandSeparator="."
            />
            <Button
              type="button"
              className="btn-clear-large btn-outline"
              id="clear"
              handleClick={this.clearValue}
            >
              X
            </Button>
            <Button
              type="button"
              className="btn-large btn-outline btn-confirm-pay"
              handleClick={this.handleConfirm}
            >
              CONFIRMAR
            </Button>
            { this.state.showValueLessThanZeroError && <small class="error">Informe um valor maior que zero!</small> }
          </PaymentMoneyInput>

        </PaymentMoneyWrapper>
      </Card>
    );
  }
}

export default PaymentTef;
