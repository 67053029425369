import { isString } from "util";

export const stringErrors = error => {

  const errorObj = (isString(error)) ? JSON.parse(error) : error;

  let msg = '';
  for (let key in errorObj) {
    if (key === 'detail') {
        return (errorObj[key]);
    }

    if (key === 'errors') {
      let msg = '';
      for (let x in errorObj['errors']) {
        msg += x + ' : ';
        msg += errorObj['errors'][x].map((err) => err + "  \n")
      }
      return msg;
    }
    msg += key + ' :  ' + errorObj[key] + "\n";
  }
  msg = (msg === '') ? String(errorObj) : msg;
  return msg
}