import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

export const initialState = {
  selectedCustomer: null,
};

export const reducers = {
  [types.CUSTOMER_SELECT]: (state, { selectedCustomer }) => (
    {
      ...state,
      ...initialState,
      selectedCustomer,
    }
  ),
};

export default createReducer(initialState, reducers);
