import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../modules';
import { Button, Input } from '../modules';
import './card-seven.scss';
import { currencyString, currencyMask } from '../../utils/masks';
import { HeaderSeven, InfoBoxSeven } from './styles';

class CardSeven extends Component {
  config;

  constructor(props) {
    super(props);

    this.modalItems = React.createRef();
  }

  getHeaders = () => {
    return (
      <div className="headerTbSeven">
        <div>Código de Barras</div>
        <div>Descrição</div>
        <div>Valor</div>
        <div>Desc.</div>
        <div>Vlr. Líq.</div>
        <div style={{ minWidth: "44px" }}>Quant.</div>
        <div>Total</div>
      </div>
    );
  }

  getRow = (item) => {
    return (
      <div tabIndex={0} className="rowTbSeven">
        <div>{item.codigoBarras}</div>
        <div>{item.name}</div>
        <div>{currencyString(item.precoBruto)}</div>
        <div>{item.desconto + "%"}</div>
        <div>{currencyString(item.precoLiquido)}</div>
        <div>{item.quantidadeAprovada}</div>
        <div>{currencyString(item.quantidadeAprovada * item.precoLiquido)}</div>
      </div>
    );
  }

  render() {
    const {
      handleClose,
      handleChange,
      handleConfirm,
      showItems,
      handleConfirmItems,
      payload
    } = this.props;

    if (showItems) {
      return (
        <Card
          ref={(ref) => (this.modelRef = ref)}
          autoScroll={false}
          id="card-seven"
          title="Portal da Drogaria - ITENS"
          closeEnable
          handleClose={handleClose}
        >
          <HeaderSeven>
            <div className="customer-name-conecta">
              <p>{payload.messageType === 1 ? "Recuperando a" : "Efetivando a"} Autorização</p>
              <p>{payload.messageType === 1 ? payload.sevenRecoverAuthorizationResponseContract.numeroControleAdministradora : payload.sevenActualizeAuthorizationResponseContract.numeroControleAdministradora}</p>
            </div>
            <div className="info-box-wrapper">
              <InfoBoxSeven>
                <p>Total bruto</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(payload.valorBruto).slice(2)}</p>
              </InfoBoxSeven>
              <InfoBoxSeven>
                <p>Economia de</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(payload.valorBruto - payload.valorTotal).slice(2)}</p>
              </InfoBoxSeven>
              <InfoBoxSeven>
                <p>Total líquido</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(payload.valorTotal).slice(2)}</p>
              </InfoBoxSeven>
            </div>
          </HeaderSeven>
          <div className="tbSeven">
            {this.getHeaders()}
            {payload.messageType === 1 ? payload.sevenRecoverAuthorizationResponseContract.items.map(this.getRow) : payload.sevenActualizeAuthorizationResponseContract.items.map(this.getRow)}
          </div>
          <Button
            onClick={handleConfirmItems}
            className="btn-default btn-outline btn"
          >
            CONFIRMAR
          </Button>
        </Card>
      );
    }

    return (
      <Card
        ref={(ref) => (this.modelRef = ref)}
        autoScroll={false}
        id="card-seven"
        title="Portal da Drogaria"
        closeEnable
        handleClose={handleClose}
      >
        <Input
          required
          id="input-recoverAuth"
          onChange={handleChange}
          type="text"
          placeholder="Recuperar autorização"
          ref={this.nameRef}
        />
        <Button
          onClick={handleConfirm}
          className="btn-default btn-purple btn"
        >
          RECUPERAR AUTORIZAÇÃO
        </Button>
      </Card>
    );
  }
}

CardSeven.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CardSeven;
