import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { OrderValidation } from '../../shared/components/modules';
import SaleService from '../../services/saleService';
import PaymentService from '../../services/paymentService';
import { CustomerService } from '../../services/customerService';

export class OrderValidationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      validationList: [],
      sellerSelected: 0,
      formPaymentList: [],
      isToggleOnOption: false,
      barCode: "",
      solicitation: null,
      operationType: null,
      validationType: null
    };

    this.subscription = null;

    this.props.MessageBus.Subscribe('pos.orderValidation.show', this.handleShow);
    this.props.MessageBus.Subscribe('pos.orderValidation.close', this.handleCloseRk);
    this.props.MessageBus.Subscribe('pos.orderValidation.changed', this.handleChanged);
    this.props.MessageBus.Subscribe('pos.formOfPayments.validation.list', this.handlePaymentMethodList);

    this.saleService = new SaleService();
    this.paymentService = new PaymentService();
    this.customerService = new CustomerService();
    this.modalRef = React.createRef();
  }

  handleChanged = (ch, payload) => {
    if (!payload)
      return;
    let validations = payload.filter((v => v.status !== 1));
    this.setState({
      validationList: validations,
      showAuthorization: false
    });
  }

  clickFormPayment = (e) => {
    this.paymentService.selectFormOfPayment(e.target.id);
    this.setState({
      isToggleOnOption: false
    });
  };

  handleCloseRk = (ch, payload) => {
    this.setState({
      visible: false,
      isToggleOnOption: false,
      validationList: [],
      barCode: ""
    });
    if (this.modalRef.current !== null) this.modalRef.current.close();
  }

  handleShow = (ch, payload) => {
    if (!payload)
      return;

    let validations = payload?.filter((v => v.status !== 1));
    this.setState({
      visible: true,
      validationList: validations,
    });
    this.modalRef.current.open();
  }

  sellerSelectChange = (e) => {
    this.setState({
      sellerSelected: e.target.value
    });
  }

  showAuthorizationClick = (solicitation, operationType, validationType) => {
    this.setState({
      solicitation: solicitation,
      showAuthorization: true,
      barCode: solicitation.barcode,
      operationType: operationType,
      validationType: validationType
    });
  }

  handleCloseAuthorization = () => {
    this.setState({
      showAuthorization: false
    });
  }

  handleConfirmIdentifySeller = () => {
    this.saleService.getSeller(this.state.sellerSelected);
  }

  handlePaymentMethodList = (ch, payload) => {
    const listFormPayment = payload.map((item) => ({
      id: item.id,
      refId: item.referenceId,
      name: item.name,
    }));
    this.setState(
      {
        formPaymentList: listFormPayment,
        isToggleOnOption: true,
      });
  }

  handleClose = () => {
    this.saleService.orderValidationClose();
  }

  paymentMethodClick = () => {
    if (this.state.isToggleOnOption) {
      this.setState(
        {
          formPaymentList: [],
          isToggleOnOption: false,
        });
      return;
    }
    this.paymentService.getFormOfPaymentList("pos.formOfPayments.validation.list");
  }

  selectCustomerClick = () => {
    this.customerService.startCustomerSelection()
    this.handleClose();
  }

  handleAuthorizeSucces = () => {
    this.saleService.setValidationResolved(this.state.barCode, this.state.validationType);
  }

  render() {
    return (this.state.visible &&
      <Fragment>
        <OrderValidation
          clickFormPayment={this.clickFormPayment}
          selectCustomerClick={this.selectCustomerClick}
          validationList={this.state.validationList}
          handleClose={this.handleClose}
          sellerSelectChange={this.sellerSelectChange}
          handleConfirmIdentifySeller={this.handleConfirmIdentifySeller}
          paymentMethodClick={this.paymentMethodClick}
          isToggleOnOption={this.state.isToggleOnOption}
          listFormPayment={this.state.formPaymentList}
          showAuthorizationClick={this.showAuthorizationClick}
          showAuthorization={this.state.showAuthorization}
          handleCloseAuthorization={this.handleCloseAuthorization}
          handleAuthorizeSucces={this.handleAuthorizeSucces}
          showModal={this.state.visible}
          solicitation={this.state.solicitation}
          showAutorizationOnline={this.props.showAutorizationOnline}
          modalRef={this.modalRef}
          operationType={this.state.operationType}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showAutorizationOnline: state.showAutorizationOnline
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(OrderValidationContainer)));