import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideMessageAction } from '../../redux/modules/message/duck';
import { MessageBox } from '../../shared/components/modules';

function MessageBoxContainer({ message, hideMessage }) {
  const handleCancelClick = () => {
    hideMessage();
    message.onCancel();
  };

  const handleConfirmClick = () => {
    hideMessage();
    message.onConfirm();
  };

  return (
    <>
      {message.show ? (
        <MessageBox
          message={message.message}
          okText={message.okText}
          handleConfirm={handleConfirmClick}
          handleCancel={message.onCancel && handleCancelClick}
        />
      ) : (
        ''
      )}
    </>
  );
}

MessageBoxContainer.propTypes = {
  message: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
    okText: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
  }).isRequired,
  hideMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = (dispatch) => ({
  hideMessage: () => dispatch(hideMessageAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageBoxContainer);
