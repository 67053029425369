import React, { TextareaHTMLAttributes } from 'react';

import { ShowMaxLenght } from '../../show-max-length/show-max-length';
import TextArea from './style';

interface ITextAreaComponent
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  showMaxLenght?: boolean;
}

function TextAreaComponent(props: ITextAreaComponent) {
  return (
    <>
      <TextArea
        id={props.id}
        data-testid={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        maxLength={props.maxLength}
        rows={props.rows}
        wrap={props.wrap}
      />
      {props.showMaxLenght && (
        <ShowMaxLenght
          elementId={props.id as string}
          lengthInput={(props.value as string).length}
        />
      )}
    </>
  );
}

export default TextAreaComponent;
