// todo remover quando o resolver problema de id do pos
const queryStringParse = (string) => {
    
  const parsed = {};

  if (string === '') {
      return parsed;
  }

  string = string.substring(string.indexOf('?') + 1);
  
  const arrayParams = string.split('&');
  
  arrayParams.map((value) => {
      const param = value.split('=');
      return parsed[param[0]] = param[1];
  });

  return parsed;
};

const PosId = queryStringParse(window.location.search).posId;
const posType = queryStringParse(window.location.search).posType;
const retailerId = queryStringParse(window.location.search).retailerId;
const protocol = window.location.protocol + '//';
const baseUrl = protocol + window.location.host;

export const ACCESS_TOKEN = 'Access_Token';
const configuration = () => ({
  endPoint: baseUrl + '/posstate',
  HubEndPoint: baseUrl + '/hub/poshub',
  retailerId: retailerId,
  PosId: PosId,
  posType: posType,
});

export default configuration();