/* eslint-disable no-loop-func */

import React from 'react';
import PbmSaleService, { PBM_EPHARMA } from '../../services/pbmSaleService';
import PaymentService from '../../services/paymentService';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { connect } from 'react-redux';
import { PbmSelectPatient } from '../../shared/components/modules';

export class PbmSelectPatientContainer extends React.Component {
    constructor(props) {
        super(props);

        this.form = {
            patient: null
        };
        this.state = {
            payload: null,
        };
        this.formRefs = {
            modalRef: React.createRef(),
            selectedPatient: React.createRef(),
        };

        this.subscription = null;
        this.handlePatientPayload = this.handlePatientPayload.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlePbmPatientSelected = this.handlePbmPatientSelected.bind(this);
        this.reset = this.reset.bind(this);
        this.paymentService = new PaymentService();

        this.props.MessageBus.Subscribe('pos.pbm.selectPatient', this.handlePatientPayload);
        this.props.MessageBus.Subscribe('pos.pbm.closeSelectPatient', this.reset);
    }

    reset() {
        this.subscription = null;
        this.form = {};
        this.setState({
            payload: null
        });
        this.formRefs.modalRef.current && this.formRefs.modalRef.current.close();
    }

    handlePatientPayload(ch, payload) {
        this.setState({
            payload: payload
        });
        this.formRefs.modalRef.current.open();
    }

    handlePbmPatientSelected() {
        this.subscription && this.props.MessageBus.Unsubscribe(this.subscription);
        this.subscription = null;
        this.reset();
    }

    handleConfirm() {
        this.subscription = this.props.MessageBus.Subscribe('pos.order.updated', this.handlePbmPatientSelected);
        this.paymentService.startPaymentWithPbmPatient(this.form);
    }

    handleClose() {
        PbmSaleService.closePatientSelection(PBM_EPHARMA);
    }

    render() {
        return (
            this.state.payload &&
            <PbmSelectPatient
                formRefs={this.formRefs}
                form={this.form}
                handleClose={this.handleClose}
                handleConfirm={this.handleConfirm}
                patients={this.state.payload}
            />
        )
    }
}

/* const mapStateToProps = state => ({
    pbmDescription: state.order.order?.infoPbm && state.order.order?.infoPbm.pbmDescription,
    pbmPrescriptionCopy: state.order.order?.infoPbm && state.order.order?.infoPbm.prescriptionCopy
});*/

export default connect(null, null)(withMessageBus(getContext(PbmSelectPatientContainer)));
