import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ListItemWrapper } from './style';
import { currencyMask } from '../../utils/masks';


class ListParcelCard extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
    handle: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    handle: null,
  };

  constructor(props) {
    super(props);
    this.onHandleKey = this.onHandleKey.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onHandleKey(e) {
    e.preventDefault();

    const code = e.keyCode || e.which;

    if ((code === 9 && !e.shiftKey) || (code === 39 || code === 40)) {
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
        e.preventDefault();
      }
    } else if ((code === 9 && e.shiftKey) || (code === 37 || code === 38)) {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
        e.preventDefault();
      }
    } else if (code === 13) {
      e.preventDefault();
      e.target.click();
    }
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length > 0) {
      const elems = document.getElementById('list-parcel');
      document.getElementById('btn_0').focus();
      elems.addEventListener('keydown', this.onHandleKey);
    }
  }

  render() {
    const {
      items,
      handle,
    } = this.props;

    return (
      <div className="listParcel" id="list-parcel">
        {items && items.map((item, index) => (
          <ListItemWrapper key={item.number} onClick={() => handle(item)} tabIndex="0" id={`btn_${index}`}>
            <div className="listItemParcelNumber">
              <span className="parcel-number">
                {item.number}
                x
              </span>
            </div>
            <span>
              {currencyMask(item.value)}
            </span>
            <span className="brand-name">{item.brandName}</span>
            <span>{item.interestRateDescription}</span>
          </ListItemWrapper>
        ))}
      </div>
    );
  }
}

export default ListParcelCard;
