/* eslint-disable no-useless-constructor */

import React, { Component ,Fragment} from 'react';
import Modal from 'react-modal';
import { Button } from '../button';
import FarmaciaPopularModalMessageWrapper from './style'
import parse from 'html-react-parser';

const customStyle = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-4px 4px 5px 1px rgba(0,0,0,0.39)',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
    padding: '0px 0px 10px 0px',
    minWidth: 'min-content',
    minHeight: 'min-content',
    top: '20%',
    bottom: 'unset',
    textAlign: 'center',
    maxWidth: '600px'
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    display: 'block',
  },
};

class FarmaciaPopularStatusMessage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const defaultHeader= "Não foi possível obter uma resposta do Farmácia Popular";
    const defaultFpMessage =
      (<Fragment>
        <b>Possíveis causas:</b><br/>
        <br/>- Loja sem internet
        <br/>- Ambiente do Programa Farmácia Popular fora do ar
        <br/>- Falha de comunicação entre a loja e o Programa Farmácia Popular
      </Fragment>)
    const {visible, okText = "Tentar Novamente", confirm, close, back, message, headerMessage } = this.props;
    return visible && (
       <Modal
        isOpen={true}
        showCloseIcon={false}
        shouldCloseOnOverlayClick={true}
        center
        ariaHideApp={false}
        style={customStyle}
      >
        <FarmaciaPopularModalMessageWrapper>
          <h1><i className="material-icons">warning</i>{headerMessage || defaultHeader}</h1>
          <p>{typeof(message) == 'string' ? parse(message) : message || defaultFpMessage}</p>
          {back && <Button
            className="btn-default btn-purple"
            type="button"
            onClick={back}>Fechar</Button>}
          {confirm &&  <Button
            className="btn-default btn-purple"
            type="button"
            onClick={confirm}>{okText}</Button>}
          {close && <Button
            className="btn-default btn-purple"
            type="button"
            onClick={close}>Retornar a Venda</Button>}

        </FarmaciaPopularModalMessageWrapper>
      </Modal>);
  }
}

export default FarmaciaPopularStatusMessage;
