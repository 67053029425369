import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 335px;
`;

export const DivTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  h5 {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-align: center;
  }
`;

export const DivAnimation = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SpanBall = styled.span<{
  backgroundColor: string;
  animationDelay: string;
}>`
  width: 14px;
  height: 14px;
  border-radius: 20px;
  margin-right: 0.2rem;
  background: ${(props) => props.backgroundColor};

  @keyframes loading {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    75% {
      transform: scale(1.7);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity: 1;
      animation-delay: 1s;
    }
  }

  &.ball-animation {
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: ${(props) => props.animationDelay};
  }
`;
