export const CUSTOMER_SELECT = 'CUSTUMER_SELECT';
export const SUBMIT_ITEM = 'SUBMIT_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SELECT_ITEM = 'SELECT_ITEM';
export const FETCH_SELLER = 'FETCH_SELLER';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const END_PAYMENT = 'END_PAYMENT';
export const SHOW_BUTTON_PAYMENT_PRE_ORDER = 'SHOW_BUTTON_PAYMENT_PRE_ORDER';
export const SHOW_LIST_PAYMENTS = 'SHOW_LIST_PAYMENTS';
export const CLOSE_LIST_PAYMENTS = 'CLOSE_LIST_PAYMENTS';
export const SHOW_BUTTON_DONE_PRE_ORDER = 'SHOW_BUTTON_DONE_PRE_ORDER';
export const DONE_PRE_ORDER = 'DONE_PRE_ORDER';
export const CARD_LIST_BRANDS = 'CARD_LIST_BRANDS';
export const ORDER_UPDATED = 'ORDER_UPDATED';
export const DELETE_ORDER = 'DELETE_ORDER';
export const CREDICARD_BRANDS_PARCEL = 'CREDICARD_BRANDS_PARCEL';
export const CARD_DETAILS_AUTH = 'CARD_DETAILS_AUTH';
export const CARD_ADD_CODE = 'CARD_ADD_CODE';
export const PAYMENT_INPUT_VALUE = 'PAYMENT_INPUT_VALUE';
export const PAYMENT_ADD_INPUT = 'PAYMENT_ADD_INPUT';
export const PAYMENT_MESSAGES = 'PAYMENT_MESSAGES';
export const PAYMENT_CHANGE_MONEY = 'PAYMENT_CHANGE_MONEY';
export const PAYMENT_AMOUNT_REMANING = 'PAYMENT_AMOUNT_REMANING';
export const SIGNALR_SEND_MESSAGE = 'SIGNALR_SEND_MESSAGE';
export const PAYMENT_COMPLETE = 'PAYMENT_COMPLETE';
export const PAYMENT_TOGGLE_DOCUMENT_BOX = 'PAYMENT_TOGGLE_DOCUMENT_BOX';
export const TEF_OPTIONS_SELECT = 'TEF_OPTIONS_SELECT';
export const TEF_OPTIONS_COMPLETED = 'TEF_OPTIONS_COMPLETED';
export const TEF_INTERACTION = 'TEF_INTERACTION';
export const TEF_INTERACTION_COMPLETED = 'TEF_INTERACTION_COMPLETED';
export const ORDER_PRODUCT_REQUEST = 'ORDER_PRODUCT_REQUEST';
export const ORDER_PRODUCT_DELETE_REQUEST = 'ORDER_PRODUCT_DELETE_REQUEST';
export const ORDER_PRODUCT_DELETE_REQUEST_DONE =
  'ORDER_PRODUCT_DELETE_REQUEST_DONE';
export const ORDER_SET_INSTRUCTIONS = 'ORDER_SET_INSTRUCTIONS';
export const SET_DIALOG_POSITION = 'SET_DIALOG_POSITION';
export const PRE_ORDER_LIST_SHOW = 'PRE_ORDER_LIST_SHOW';
export const PRE_ORDER_DELETE_ITEM_INFO = 'PRE_ORDER_DELETE_ITEM_INFO';
export const PBM_SHOW = 'PBM_SHOW';
export const PBM_HIDE_ALL = 'PBM_HIDE_ALL';
export const PBM_PRODUCTS_SHOW = 'PBM_PRODUCTS_SHOW';
export const LAST_ORDER_LIST_SHOW = 'LAST_ORDER_LIST_SHOW';
export const REPRINT_TEF_LIST_SHOW = 'REPRINT_TEF_LIST_SHOW';
export const CANCELAMENTO_TEF_LIST_SHOW = 'CANCELAMENTO_TEF_LIST_SHOW';
export const RESET_STATE = 'RESET_STATE';
export const ORDER_RESET_STATE = 'ORDER_RESET_STATE';
export const RESET_ADMINISTRATIVE_MENU = 'RESET_ADMINISTRATIVE_MENU';
export const CLOSE_BUTTON_PAYMENT_PRE_ORDER = 'CLOSE_BUTTON_PAYMENT_PRE_ORDER';
export const ORDER_IDENTIFY_SELLER = 'ORDER_IDENTIFY_SELLER';
export const ORDER_IDENTIFY_SELLER_ID = 'ORDER_IDENTIFY_SELLER_ID';

export const LOADING_SCREEN_HIDE = 'LOADING_SCREEN_HIDE';
export const LOADING_SCREEN_SHOW = 'LOADING_SCREEN_SHOW';
export const BLOCK_SHORTCUTS = 'BLOCK_SHORTCUTS';
export const UNBLOCK_SHORTCUTS = 'UNBLOCK_SHORTCUTS';
export const BLOCK_ALL_SHORTCUTS_BUT = 'BLOCK_ALL_SHORTCUTS_BUT';
export const UNBLOCK_ALL_SHORTCUTS = 'UNBLOCK_ALL_SHORTCUTS';
export const CARD_OPENED = 'CARD_OPENED';
export const CARD_CLOSED = 'CARD_CLOSED';
export const SHOW_WITH_SEARCH_BAR = 'SHOW_WITH_SEARCH_BAR';
export const SHOW_WITH_OTHERS = 'SHOW_WITH_OTHERS';
export const SHOW = 'SHOW';
export const CLOSE_ALL = 'CLOSE_ALL';
export const CLOSE_WITHOUT_HIDE_OTHERS = 'CLOSE_WITHOUT_HIDE_OTHERS';
export const MONITOR_NOTAS_LIST_SHOW = 'MONITOR_NOTAS_LIST_SHOW';
export const CLOSE = 'CLOSE';

export const MODAL_MESSAGE_SHOW = 'MODAL_MESSAGE_SHOW';
export const MODAL_MESSAGE_CHANGE = 'MODAL_MESSAGE_CHANGE';
export const MODAL_MESSAGE_HIDE = 'MODAL_MESSAGE_HIDE';

export const UPDATE_LAST_SENT_BODY = 'UPDATE_LAST_SENT_BODY';
export const UPDATE_SHOULD_AUTHENTICATE_OPERATION =
  'UPDATE_SHOULD_AUTHENTICATE_OPERATION';
export const REOPEN_MONITOR = 'REOPEN_MONITOR';
export const CHECK_STATUS_SAT = 'CHECK_STATUS_SAT';
export const CHECK_STATUS_FP = 'CHECK_STATUS_FP';
export const CHECK_STATUS_FISCALFLOW = 'CHECK_STATUS_FISCALFLOW';
export const HAS_CONFIG_RETAILER_FP = 'HAS_CONFIG_RETAILER_FP';
export const CONFIG_RETAILER = 'CONFIG_RETAILER';
export const HAS_CONFIG_USER_FP = 'HAS_CONFIG_USER_FP';
export const PDV_CONTINGENCY_STATUS = 'PDV_CONTINGENCY_STATUS';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const POS_TYPE = 'POS_TYPE';
export const CHECK_STATUS_FUNCIONAL_CARD = 'CHECK_STATUS_FUNCIONAL_CARD';
export const RETAILER = 'RETAILER';
export const LOGIN_IS_OPEN = 'LOGIN_IS_OPEN';
export const SHOW_AUTORIZATION_ONLINE = 'SHOW_AUTORIZATION_ONLINE';
