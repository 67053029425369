// Tela depreciada, talvez irá ser reutilizada?
// export const KEYBOARD_QUANTITY = "showKeyboardQuantity";
export const CUSTOMER = 'showCustomer';
export const CUSTOMER_REGISTRATION = 'showCustomerRegistration';
export const DETAIL_ITEM = 'showDetailItem';
export const CASH_OPEN = 'showCashOpen';
export const CASH_CLOSE = 'showCashClose';
export const CASH_WITH_DRAW = 'showCashWithDraw';
export const CASH_SUPPLY = 'showCashSupply';
export const SNGPC = 'showSngpc';
export const LOT = 'showLot';
export const IDENTIFY_SELLER = 'showIdentifySeler';
export const DOCUMENT_BOX = 'showDocumentBox';
export const PRODUCT_SEARCH = 'showProductSearch';
export const ORDER_LIST = 'showOrderList';
export const PRE_ORDER_LIST = 'showPreOrderList';
export const PBM = 'showPbm';
export const PBM_PRODUCTS = 'showPbmProducts';
export const PAYMENT_MONEY = 'showMoney';
export const PAYMENT_PIX = 'showPix';
export const PAYMENT_OPTIONS = 'showPaymentOptions';
export const SALE_FINISH = 'showSaleFinish';
export const AUTHORIZATION_DETAIL = 'showAuthorizationDetail';
export const CARD_BRAND_LIST = 'showCardBrandList';
export const CREDIT_CARD_PARCEL_LIST = 'showCreditCardParcelList';
export const BUSINESS_AGREEMENT_PARCEL_LIST = 'showBusinessAgreementParcelList';
export const CARD_AUTHORIZATION = 'showCardAuthorization';
export const TEF_INTERACTION = 'showTefInteraction';
export const PAYMENT_CREDIT_PARCEL = 'showPaymentCreditParcel';
export const PAYMENT_AUTHORIZATION = 'showPaymentAuthorization';
export const TEF_MENU_OPTIONS = 'showTefMenuOptions';
export const FARMACIA_POPULAR = 'showFarmaciaPopular';
export const FARMACIA_POPULAR_CONFIRM = 'showFarmaciaPopularConfirm';
export const FARMACIA_POPULAR_ERROR = 'showFarmaciaPopularError';
export const MONITOR_NOTAS_LIST = 'showMonitorNotas';
export const FINANCIAL_DOCUMENT_RECEIPTMENT = 'financialdocumentReceiptment';
export const ORDER_DETAILS = 'showOrderDetails';
export const TEF_OPTIONS = 'showTefOptions';
export const PBM_INITIALIZATION = 'showPbmInitialization';
export const APPLY_PRODUCTS_PBM = 'applyProductsPbm';
export const PBM_AUTHORIZATION_LIST = 'pbmAuthorizationList';
export const TRNCENTRE_RESUME = 'trnCentreResume';
export const LINX_CONECTA_INIT = 'linxConectaInit';
export const LINX_CONECTA_PAYMENT = 'linxConectaPayment';
export const DELIVERY = 'showDelivery';
export const SEVEN = 'showSeven';
export const TRNCENTRE = 'showTrnCentre';
export const CHECKPREORDER = 'showCheckPreOrder';
export const CONTINUOUS_USE = 'showContinuousUse';
export const CONFIG_FUNCIONAL = 'configFuncional';
export const CONFIG_TERMINAL = 'configTerminal';
export const CONFIG_PBM = 'configPBM';
export const CONFIG_TEF = 'configTef';
export const CONFIG_POSCONNECT = 'configPosConnect';
export const CONFIG_ORIZON = 'configOrizon';
export const INITIALIZATION_ORIZON = 'initializationOrizon';
export const PBM_EXTERNAL_AUTHORIZATION = 'pbmExternalAuthorization';
export const SEARCH_CODE = 'SEARCH_CODE';
export const DELIVERY_INFO = 'DELIVERY_INFO';
export const PURCHASE_HISTORY = 'PURCHASE_HISTORY';
export const OBJECTPRO = 'showObjectPro';
export const STOCKLIST = 'STOCKLIST';
export const DOCUMENTPRINT = 'showDocumentPrint';

export const payloadName = (viewName) => {
  return `${viewName}Payload`;
};

export const getPayload = (state, viewName) => {
  return state.viewManager[payloadName(viewName)] || {};
};

export const shouldShow = (state, viewName) => {
  return state.viewManager[viewName];
};

