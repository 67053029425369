/* eslint-disable no-restricted-syntax */

import MessageBusService from '../../../services/MessageBusService';
import ProductService from '../../../services/productService';
import moment from 'moment'

export class CacheFinder {

  static cacheItems = null;

  shortInterval = null;
  shortIntervalSeconds = 60;

  nextProcessTimeout = null;

  db = null;
  dbName = 'ProductsCache';
  storeName = 'products';
  dictionary = 'items_dictionary';
  items = 'items';

  constructor() {
    this.productService = new ProductService();

    this.start = this.start.bind(this);
    this.find = this.find.bind(this);
    this.shouldRefresh = this.shouldRefresh.bind(this);
    this.startItems = this.startItems.bind(this);
    this.refreshCache = this.refreshCache.bind(this);
    this.getCacheDateISO = this.getCacheDateISO.bind(this);
    this.setStorage = this.setStorage.bind(this);
    this.handleError = this.handleError.bind(this);
    this.getNowDateISO = this.getNowDateISO.bind(this);    
  }

  start() {
    if (this.shouldRefresh()) {
      this.deleteDB();
    } else {
      this.createDB();
    }
  }

  deleteDB() {
    const request = window.indexedDB.deleteDatabase(this.dbName);

    request.onsuccess = (event) => {
      console.log(`Event-IndexedDB: Delete IndexedDB success! - DBName ${this.dbName}`);
      this.createDB();
    }

    request.onerror = (event) => {
      console.error(`Event-IndexedDB: An error ocurred Delete IndexedDB - DBName ${this.dbName}`);
      console.error(event);
    }

    request.onblocked = () => {
      console.log(`Event-IndexedDB: Couldn't delete database due to the operation being blocked - DBName ${this.dbName}`);
  };
  }

  removeItemsInLocalStorage() {  
    const storage = Object.keys(localStorage);
    const hasDictionary = storage.some(key => key === this.dictionary);
    const hasItems = storage.some(key => key === this.items);

    if(hasDictionary) localStorage.removeItem(this.dictionary);
    if(hasItems) localStorage.removeItem(this.items);
  }

  createDB() {
    this.removeItemsInLocalStorage();

    const request = window.indexedDB.open(this.dbName, 1);

    request.onerror = (event) => {
      console.error(`Event-IndexedDB: An error ocurred with IndexedDB - DBName ${this.dbName}`);
      console.error(event);
    }

    request.onupgradeneeded = (event) => {
      console.log(`Event-IndexedDB: Upgrade DB success. DBName - ${this.dbName}.`);

      const db = request.result;
      const objectStore = db.createObjectStore(this.storeName, { keyPath: 'id' });
      objectStore.createIndex('product_cache_date', 'lastProcessDate');
      objectStore.createIndex(this.dictionary, 'dictionary');
      objectStore.createIndex(this.items, 'items');
    }

    request.onsuccess = (event) => {
      console.log(`Event-IndexedDB: Create DB success. DBName - ${this.dbName}.`);
      this.db = request.result;
      this.refreshCache();
    }
  }  

  refreshCache() {
    if (this.shouldRefresh()) {

      this.nextProcessTimeout && clearTimeout(this.nextProcessTimeout);
      this.nextProcessTimeout = null;

      clearInterval(this.shortInterval);
      this.shortInterval = setInterval(this.refreshCache, this.shortIntervalSeconds * 1000);

      this.productService.getProductsCache();
      MessageBusService.GetInstance().Subscribe('pos.product.cache', (ch, payload) => {
        this.setStorage(payload);
      });
    } else {
      const cacheDate = this.getCacheDateISO();
      if (cacheDate) {
        var diff = moment(this.getCacheDateISO()).add(24, 'hours').diff(moment(this.getNowDateISO()));

        // adding 20 seconds to the last processed date for the next cache update
        this.nextProcessTimeout = setTimeout(() => {
          this.refreshCache();
        }, diff + 20000);
      }
      this.getDataIndexedDB();
    }
  }

  setStorage(payload) {
    const { lastProcessDate } = payload;

    localStorage.setItem('product_cache_date', lastProcessDate);
    clearInterval(this.shortInterval);

    // setting a timeout based on the last processed date
    var diff = moment(payload.lastProcessDate).add(24, 'hours').diff(moment(this.getNowDateISO()));
    this.nextProcessTimeout = setTimeout(() => {
      this.refreshCache();
    }, diff + 20000);

    Promise.all(
      [fetch(payload.dictionaryUri),
      fetch(payload.productsUri)]
    ).then((values) => {
      Promise.all(
        [values[0].text(),
        values[1].text()]
      ).then((res) => {
        this.setDataIndexedDB(res, lastProcessDate);
      }).catch(this.handleError);
    }).catch(this.handleError);
  }

  setDataIndexedDB = (response, lastProcessDate) => {
    const transactionSet = this.db.transaction(this.storeName, 'readwrite');
    const objectStore = transactionSet.objectStore(this.storeName);

    objectStore.put({ 
      id: 1, 
      lastProcessDate: lastProcessDate, 
      dictionary: response[0],
      items: response[1]
    })

    transactionSet.oncomplete = (event) => {
      console.log('Event-IndexedDB: Transaction setData completed.');
      this.getDataIndexedDB();
    }

    transactionSet.onerror = (event) => {
      console.error('Event-IndexedDB: Transaction setData failed.');
      console.error(event);
    }
  }

  getDataIndexedDB = () => {
    const transactionRead = this.db.transaction(this.storeName, 'readonly');
    const objectStore = transactionRead.objectStore(this.storeName);

    objectStore.openCursor().onsuccess = (event) => {
      const cursor = event.target.result;

      if (cursor) {
        const data = cursor.value;
        this.startItems(data);
      }
    }
  }

  handleError(error) {
    console.error(error);
    localStorage.removeItem('product_cache_date');
    this.shortInterval = setInterval(this.refreshCache, this.shortIntervalSeconds * 1000);
  }

  shouldRefresh() {
    const cacheDate = this.getCacheDateISO();

    // date with no UTC (to match the server processed date)
    // adding 20 seconds for precaution, so the date is for sure after than the last processed date in server
    var nowDate = moment(this.getNowDateISO()).add(20, 'seconds');

    // If it's been 24 hours since the last processed date
    if (!cacheDate || moment(nowDate).isAfter(moment(cacheDate).add(24, 'hours'))) {
      return true;
    }

    return false;
  }

  getCacheDateISO() {
    let cacheDate = localStorage.getItem('product_cache_date');

    return cacheDate;
  }

  // returns a Date with no local UTC
  getNowDateISO() {
    return new Date().toISOString();
  }

  startItems(data) {
    const { dictionary, items } = data;

    if (!dictionary || !items) {
      this.started = false;
      return;
    }

    this.started = true;

    this.dictionary = dictionary.split('\n');

    CacheFinder.cacheItems = items.split('\n').map((item) => {
      const arrItem = item.split('|');

      const description = arrItem[1]
        .split('-')
        .map(wordIndex => parseInt(wordIndex, 16))
        .map(index => this.dictionary[index])
        .join(' ');

      let activePrinciple = arrItem[2];
      if (activePrinciple) {

        activePrinciple = activePrinciple.split('-')
          .map(wordIndex => parseInt(wordIndex, 16))
          .map(index => this.dictionary[index])
          .join(' ');
      }

      let referenceId = arrItem[3];
      if (referenceId) {

        referenceId = referenceId.split('-')
          .map(wordIndex => parseInt(wordIndex, 16))
          .map(index => this.dictionary[index])
          .join(' ');
      }

      return {
        barcode: arrItem[0],
        description,
        activePrinciple,
        referenceId
      };
    });

    return CacheFinder.cacheItems;
  }

  prepareRegex(text) {
    const searchType = text[0] === "%" ? 1 : 0;//Tipo 0 - Pesquisa Iniciando por. Tipo 1 - Pesquisa contendo 
    const textToFind = text.toUpperCase().replace('%', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const searchWords = textToFind.split(' ').filter(word => word);

    const regex = searchWords.map((word, index) => {
      if (index === 0) {
        return searchType === 1 ? `\\w?${word}` : `^${word}`;
      }

      return `+[\\b]?.+${word}`;
    }).join('');
    return new RegExp(regex, 'ig');
  }


  async find(text, limitResult = 10, correlationId) {
    return new Promise((resolve) => {
      if (!CacheFinder.cacheItems) {
        resolve(null);
      }

      const regex = this.prepareRegex(text);

      const foundItems = [];

      for (const item of CacheFinder.cacheItems) {
        regex.lastIndex = 0;

        let isValid = regex.test(item.description);

        if (!isValid) {
          regex.lastindex = 0;
          isValid = regex.test(item.activePrinciple);
        }

        if (isValid) {
          foundItems.push(item);
        }

        if (foundItems.length > limitResult - 1) {
          break;
        }
      }

      resolve({ items: foundItems, correlationId: correlationId });
    });
  }
}
