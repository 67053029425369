import React, { Component } from 'react';

import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { Card } from '../card';
import MessageBox from '../message-box';
import Buyer from './buyer';
import Navigation from './navigation';
import Patient from './patient';
import Prescriber from './prescriber';
import Prescription from './prescription';
import { Container, SngpcWrapper, Button, Footer, Buttons } from './styles';
import { PRESCRIPTION_ANTIMICROBIAL, PRESCRIBER_VET } from './types';
import SchemaValidation from './validation';
import { Modal } from '../modal2';
import { FormModal } from '../card-continuous-use/form-modal';

export class Sngpc extends Component {
  constructor(props) {
    super(props);

    this.autocompleteRef = null;
    this.prescriptionRef = React.createRef();
    this.submitButtonRef = React.createRef();
    this.buyerRef = React.createRef();
    this.patientRef = React.createRef();
    this.formValues = null;

    this.state = {
      showConfirmCopyPrescription: false,
      isValidatingForm: false,
      ...this.manageProps(props),
    };
  }

  componentDidMount() {
    this.prescriptionRef.current.focusOnCreatedAt();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentPrescriptionIndex !==
        this.props.currentPrescriptionIndex ||
      prevProps.controlledItems.prescriptions.length !==
        this.props.controlledItems.prescriptions.length
    ) {
      this.updateState();
    }
  }

  updateState = () => {
    this.setState({ ...this.manageProps(this.props) });
  };

  manageProps = (props) => {
    const { prescriptions, buyer, product } = props.controlledItems;

    const currentPrescription = prescriptions[props.currentPrescriptionIndex];

    const { prescriber, patient, products } = currentPrescription;

    const prescriptionType =
      currentPrescription.prescriptionType ||
      product.productPrescriptionType ||
      '1';

    return {
      buyer: {
        name: buyer.name || '',
        documentType: buyer.documentType || '50',
        documentNumber: buyer.documentNumber || '',
        documentState: buyer.documentState || '',
        shippingAgency: buyer.shippingAgency || '50',
        prescriptionType,
        prescriberType: prescriber.documentType || '',
      },
      patient: {
        name: patient.name || '',
        dateOfBirth:
          (patient.dateOfBirth && new Date(patient.dateOfBirth)) || '',
        genre: patient.genre || '',
        prescriptionType,
        prescriberType: prescriber.documentType || '',
      },
      prescriber: {
        name: prescriber.name || '',
        documentType: prescriber.documentType || '',
        documentNumber: prescriber.documentNumber || '',
        documentSituation: prescriber.documentSituation,
        documentTypeDescription: prescriber.documentTypeDescription || '',
        documentUF: prescriber.documentUF || '',
        referenceId: prescriber.referenceId || '',
      },
      prescription: {
        createdAt:
          (currentPrescription.createdAt &&
            new Date(currentPrescription.createdAt)) ||
          '',
        sequence: currentPrescription.sequence,
        prescriptionNumber: currentPrescription.prescriptionNumber || '',
        prolongedUse: currentPrescription.prolongedUse,
        prescriptionType,
      },
      products,
      prescriptionsSize: prescriptions.length,
    };
  };

  getControlledItems = () => {
    const { controlledItems, currentPrescriptionIndex } = this.props;

    const values = this.formValues;

    const prescriptions = controlledItems.prescriptions.map((item, index) => {
      if (index !== currentPrescriptionIndex) return item;
      let response = {
        ...item,
        ...values.prescription,
        prescriber: values.prescriber,
        patient: {},
        prolongedUse: values.prescription.prolongedUse || false,
      };
      if (
        parseInt(values.prescription.prescriptionType, 10) ===
          PRESCRIPTION_ANTIMICROBIAL &&
        parseInt(values.prescriber.documentType, 10) !== PRESCRIBER_VET
      ) {
        response = { ...response, patient: values.patient };
      }
      return response;
    });

    return {
      buyer:
        parseInt(values.prescription.prescriptionType, 10) !==
          PRESCRIPTION_ANTIMICROBIAL &&
        parseInt(values.prescriber.documentType, 10) !== PRESCRIBER_VET
          ? values.buyer
          : controlledItems.buyer,
      prescriptions,
      product: controlledItems.product,
    };
  };

  convertPrescriptionColor = (color) => {
    switch (color) {
      case 'Esp2V':
        return 'Receita de Controle Especial em 2 vias (Receita Branca) ';
      case 'NtfB':
        return 'Notificação de Receita B (Notificação Azul)';
      case 'NtfEsp':
        return 'Notificação de Receita Especial (Notificação Branca)';
      case 'NtfA':
        return 'Notificação de Receita A (Notificação Amarela)';
      case 'AntMcb':
        return 'Receita Antimicrobiano em 2 vias';
      default:
        return color;
    }
  };

  convertGenre = (genre) => {
    switch (genre) {
      case 'Female':
        return 'Feminino';
      case 'Male':
        return 'Masculino';
      default:
        return genre;
    }
  };

  validateForm = async (validateFunc) => {
    this.setState({ isValidatingForm: true });

    const errors = await validateFunc();
    if (Object.keys(errors).length > 0) {
      this.submitButtonRef.current.click();
    }

    this.setState({ isValidatingForm: false });

    return Object.keys(errors).length === 0;
  };

  handlePrevPrescription = async (validateFunc) => {
    const isValid = await this.validateForm(validateFunc);
    if (isValid) {
      const controlledItems = this.getControlledItems();
      this.props.prevPrescription(controlledItems);
    }
  };

  handleNextPrescription = async (validateFunc) => {
    const isValid = await this.validateForm(validateFunc);
    if (isValid) {
      const controlledItems = this.getControlledItems();
      this.props.nextPrescription(controlledItems);
    }
  };

  handleNewPrescription = async (validateFunc) => {
    const isValid = await this.validateForm(validateFunc);
    if (isValid)
      this.setState({
        showConfirmCopyPrescription: true,
      });
  };

  handleNewPrescriptionCopyPrescription = (copyCurrentPrescription) => {
    this.setState({
      showConfirmCopyPrescription: false,
    });
    this.props.newPrescription(
      this.getControlledItems(),
      copyCurrentPrescription
    );
  };

  handlePrescriberSelect = () => {
    if (
      parseInt(this.formValues.prescription.prescriptionType, 10) !==
        PRESCRIPTION_ANTIMICROBIAL &&
      parseInt(this.formValues.prescriber.documentType, 10) !== PRESCRIBER_VET
    )
      return this.buyerRef.current.focus();

    if (
      parseInt(this.formValues.prescription.prescriptionType, 10) ===
        PRESCRIPTION_ANTIMICROBIAL &&
      parseInt(this.formValues.prescriber.documentType, 10) !== PRESCRIBER_VET
    )
      return this.patientRef.current.focus();

    return true;
  };

  handleSubmit = () => {
    const { isValidatingForm } = this.state;
    if (isValidatingForm) return;

    const { confirm } = this.props;

    const data = this.getControlledItems();

    confirm(data);
  };

  handleCreateNew = (modalRef) => {
    modalRef.current.open();
  }

  closeModal = (modalRef) => {
    modalRef.current.close();
  }
  render() {
    const {
      removePrescription,
      title,
      options,
      close,
      autocompleteQueryFn,
      autocompleteId,
      autocompleteOnKeyPress,
      renderItem,
      documentTypes,
      dispatchingAgencys,
      currentPrescriptionIndex,
      controlledItems,
      modalRef,
      handleSubmitNewPrescriber,
      newPrescriber,
    } = this.props;

    options.genres = options.genres.map((item) => ({
      name: this.convertGenre(item.name),
      value: item.value,
    }));

    options.receiptTypes = options.receiptTypes.map((item) => ({
      name: this.convertPrescriptionColor(item.name),
      value: item.value,
    }));

    options.documentTypes = documentTypes.map((item) => ({
      name: item.description,
      value: item.referenceId,
    }));

    options.dispatchingAgencys = dispatchingAgencys.map((item) => ({
      name: item.description,
      value: item.referenceId,
    }));

    const {
      buyer,
      prescriber,
      patient,
      prescription,
      showConfirmCopyPrescription,
      prescriptionsSize
    } = this.state;

    return (
      <Container data-testid="sngpc-container">
        <Modal
          title="Cadastro de prescritor"
          ref={modalRef}
          closable={true}
          opened={false}
          style={{ width: "50%" }}
        >
          <Modal.Body style={{ padding: "16px 0 0 0" }}>
            <FormModal
              registration={"newPrescriber"}
              closeModal={() => this.closeModal(modalRef)}
              handleSubmit={handleSubmitNewPrescriber}
            />
          </Modal.Body>
        </Modal>
        <Card title={title} closeEnable handleClose={close}>
          <Formik
            initialValues={{
              buyer,
              prescriber,
              patient,
              prescription,
            }}
            onSubmit={this.handleSubmit}
            validationSchema={SchemaValidation}
            enableReinitialize
          >
            {({ values, setFieldValue, validateForm }) => {
              this.formValues = values;
              return (
                <Form>
                  <SngpcWrapper>
                    <Prescription
                      setFieldValue={setFieldValue}
                      data={values.prescription}
                      options={options}
                      ref={this.prescriptionRef}
                    />

                    <Prescriber
                      autocompleteId={autocompleteId}
                      autocompleteQueryFn={autocompleteQueryFn}
                      autocompleteOnKeyPress={autocompleteOnKeyPress}
                      renderItem={renderItem}
                      setFieldValue={setFieldValue}
                      data={values.prescriber}
                      onSelect={this.handlePrescriberSelect}
                      handleCreateNew={() => this.handleCreateNew(modalRef)}
                      newPrescriber={newPrescriber}
                    />

                    {parseInt(values.prescription.prescriptionType, 10) !==
                      PRESCRIPTION_ANTIMICROBIAL &&
                      parseInt(values.prescriber.documentType, 10) !==
                        PRESCRIBER_VET && (
                        <Buyer
                          ref={this.buyerRef}
                          setFieldValue={setFieldValue}
                          options={options}
                          showClearFields={!!buyer.name}
                        />
                      )}

                    {parseInt(values.prescription.prescriptionType, 10) ===
                      PRESCRIPTION_ANTIMICROBIAL &&
                      parseInt(values.prescriber.documentType, 10) !==
                        PRESCRIBER_VET && (
                        <Patient
                          ref={this.patientRef}
                          setFieldValue={setFieldValue}
                          data={values.patient}
                          options={options}
                          showClearFields={!!patient.name}
                        />
                      )}
                  </SngpcWrapper>
                  <Footer>
                    {controlledItems.product.quantity > 1 && false && (
                      <Navigation
                        sequence={currentPrescriptionIndex + 1}
                        size={prescriptionsSize}
                        prevPrescription={() =>
                          this.handlePrevPrescription(validateForm)
                        }
                        newPrescription={() =>
                          this.handleNewPrescription(validateForm)
                        }
                        nextPrescription={() =>
                          this.handleNextPrescription(validateForm)
                        }
                        removePrescription={removePrescription}
                      />
                    )}
                    <Buttons>
                      <Button
                        className="btn-purple"
                        innerRef={this.submitButtonRef}
                        type="submit"
                      >
                        Próximo <span> &nbsp;(Lotes)</span>
                      </Button>
                    </Buttons>
                  </Footer>
                </Form>
              );
            }}
          </Formik>
        </Card>
        {showConfirmCopyPrescription && (
          <MessageBox
            message="Aproveitar estas informações na próxima receita?"
            okText="Sim"
            handleConfirm={() =>
              this.handleNewPrescriptionCopyPrescription(true)
            }
            handleCancel={() =>
              this.handleNewPrescriptionCopyPrescription(false)
            }
            visible={showConfirmCopyPrescription}
          />
        )}
      </Container>
    );
  }
}

Navigation.propTypes = {
  prevPrescription: PropTypes.func.isRequired,
  newPrescription: PropTypes.func.isRequired,
  nextPrescription: PropTypes.func.isRequired,
  removePrescription: PropTypes.func.isRequired,
  sequence: PropTypes.number,
};

Navigation.defaultProps = {
  sequence: null,
};

Sngpc.propTypes = {
  ...Navigation.propTypes,
  currentPrescriptionIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  controlledItems: PropTypes.objectOf(PropTypes.any).isRequired,
  confirm: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  close: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  newPrescriber: PropTypes.object.isRequired,
};

export default Sngpc;
