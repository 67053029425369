import PropTypes from 'prop-types';
import React, { Component } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { InputWrapper } from './style';

class Input extends Component {
  static propTypes = {
    pattern: PropTypes.string,
    imagePath: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    icon: PropTypes.string,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    keyFocus: PropTypes.string,
    attrs: PropTypes.arrayOf(PropTypes.any),
    events: PropTypes.arrayOf(PropTypes.shape({
      handleKeys: PropTypes.arrayOf(PropTypes.string),
      onKeyEvent: PropTypes.func,
    })),
  }

  static defaultProps = {
    pattern: undefined,
    imagePath: null,
    icon: null,
    onKeyPress: null,
    onKeyUp: null,
    onChange: null,
    onInput: null,
    keyFocus: null,
    attrs: [],
    events: [],
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.clear = this.clear.bind(this);

    this.input = React.createRef();
  }

  handleChange = (event) => {
    const { target } = event;

    if (this.props.onChange && (!target.validity || target.validity.valid)) {
      this.props.onChange(event);
    }
  }

  /**
   * Method invoked externally
   *  */
  focus() {
    if (this.input && this.input.current && this.input.current.focus) {
      this.input.current.focus();
    }
  }

  selectText() {
    if (this.input && this.input.current && this.input.current.select) {
      this.input.current.select();
    }
  }

  /**
   * Method invoked externally
   *  */
  value() {
    if (this.input && this.input.current && this.input.current.focus) {
      return this.input.current.value;
    }

    return undefined;
  }

  /**
   * Method invoked externally
   *  */
  clear() {
    if (this.input && this.input.current && this.input.current.focus) {
      this.input.current.value = '';
    }
  }

  componentDidMount() {
    if(this.props.autoFocus) {
      this.input.current.focus();
    }
  }

  render() {
    const {
      imagePath,
      icon,
      onKeyPress,
      keyFocus,
      ...attrs
    } = this.props;

    return (
      <InputWrapper>
        <div className="input__container">
          {imagePath && <img className="responsive-img" src={imagePath} alt="" />}
          {icon && <div className="icon"><i className="material-icons">{ icon }</i></div>}
          <input
            {...attrs}
            className={!(imagePath || icon || this.props.className) ? 'input without-image ' : this.props.className}
            ref={this.input}
            type="text"
            onKeyPress={this.props.onKeyPress}
            onKeyUp={this.props.onKeyUp}
            onChange={this.handleChange}
            onInput={this.props.onInput}
          />
          {
            this.props.events.forEach(event => (
              <KeyboardEventHandler
                handleKeys={event.handleKeys}
                handleFocusableElements
                onKeyEvent={event.onKeyEvent}
              />
            ))
          }
          {keyFocus && (
            <KeyboardEventHandler
              handleKeys={[keyFocus]}
              handleFocusableElements
              onKeyEvent={() => this.input.current.select()}
            />
          )}
        </div>
      </InputWrapper>
    );
  }
}

export default Input;
