/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SortIcon } from '../../../assets/images/sort-amount-up-solid.svg';
import DatePicker from '../date-picker';
import { Card } from '../modules';
import Store from '../../../redux/store';
import { blockAllShortcutsBut, unblockAllShortcuts } from '../../../redux/actions/shortcutsAction';

import { 
  ProductSearchBarContainer, 
  SelectPeriodContainer, 
  ApplyPeriodButton, 
  ProductSearchBar, 
  ButtonsContainer, 
  MessageContainer,
  TableContainer, 
  PeriodButton, 
  Table,
  InfiniteScrollLoader,
  SelectedPeriodText, 
} from './styled';
import { currencyMask } from '../../utils/masks';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { showToast } from '../../utils/util';
import moment from 'moment';

export default function PurchaseHistory({
  data,
  hasMoreData,
  isFetchingNewData,
  selectedPeriod,
  fetchMoreData,
  handleClose,
  handleClickPeriod,
  handleClearSearch,
  handleApplyPeriod,
  handleSearchForProducts,
  handleSort,
  selectPeriodStartDate,
  selectPeriodEndDate,
}) { 
  useEffect(() => { 
    Store.dispatch(blockAllShortcutsBut());
    window.addEventListener('click', handleClickSearchBar);
    
    const header = document.querySelector("#main-header-container");
    const sidebar = document.querySelector("[data-testid='sidebar']");
    const coupon = document.querySelector("#coupon-container");
    const buttonPreOrder = document.querySelector("button#autocomplete_preorder");
    
    const elementsToBlock = [header, sidebar, coupon, buttonPreOrder];    

    elementsToBlock.forEach(element => {
      element.style.pointerEvents = 'none';
    });

    inputRef.current.focus();
    
    return () => {
      window.removeEventListener("click", handleClickSearchBar);
      Store.dispatch(unblockAllShortcuts());
      elementsToBlock.forEach(element => {
        element.style.pointerEvents = 'auto';
      });
    }
  }, []);
  
  const inputRef = useRef(null);
  const tableContainerRef = useRef(null);

  const [applyPeriodHasBeenPressed, setApplyPeriodHasBeenPressed] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: 'date',
    direction: 'DESC'
  });
  
  useEffect(() => {
    if (selectedPeriod === "select-period" && applyPeriodHasBeenPressed && selectPeriodStartDate && selectPeriodEndDate) {
      tableContainerRef.current.focus();
    } // execute when 'select-period' has loaded data;
  }, [selectedPeriod, applyPeriodHasBeenPressed, selectPeriodStartDate, selectPeriodEndDate]);
  
  const handleClickSearchBar = (e) => {
    const searchBar = document.querySelector("#product-barcode");

    if (e.target === searchBar) {
      handleClose();
    }
  }
  
  const activeColorSort = key => {
    if (isFetchingNewData) return;
    
    return sortConfig.key === key ? "#672A94" : "#cccccc";
  };
  
  const requestSort = key => {    
    if (selectedPeriod === "select-period" && selectPeriodEndDate === null && selectPeriodStartDate === null) return;
    
    if (isFetchingNewData) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: `Aguarde os dados serem carregados para executar uma nova ordenação.`,
        hideAfterMilliseconds: 3000
      });
      
      return;
    }
    
    let direction = 'ASC';
    if (sortConfig.key === key && sortConfig.direction === 'ASC') {
      direction = 'DESC';
    }
    
    setSortConfig({ key, direction });
    handleSort(key, direction);
    setSearchTerm("");
  };
  
  const isWaitingForSelection = startDate === null || endDate === null;
  
  let dateIsValid = {
    startDate: false,
    endDate: false,
  };

  function handleChangeDate(value, setDate, field) {
    dateIsValid[field] && setDate(value);
  }

  function handleChangeRawDate(value, setDate, field) {
    if (value === undefined) {
      dateIsValid[field] = true;
      return;
    }

    setDate(null);

    let date = value.replace(/_/g, '');
    if (date.length === 10) dateIsValid[field] = moment(date, "DD/MM/YYYY").isValid();
  }
  
  return (
    <Card
      title="Histórico de compras"
      closeEnable
      handleClose={handleClose}
      id="purchase-history"
    >
      
      <p style={{marginBottom: 0}}>Período de compras</p>
      
      <ButtonsContainer>
        <PeriodButton 
          selected={selectedPeriod === "90-days"}
          id="btn-90-days" 
          onClick={e => {
            tableContainerRef.current.focus();
            handleClickPeriod(e.target.id);
            setApplyPeriodHasBeenPressed(false);
            setSearchTerm("");
          }}
        >
          Últimos 90 dias
        </PeriodButton>
        <PeriodButton 
          selected={selectedPeriod === "6-months"}
          id="btn-6-months" 
          onClick={e => {
            tableContainerRef.current.focus();
            handleClickPeriod(e.target.id);
            setApplyPeriodHasBeenPressed(false);
            setSearchTerm("");
          }}
        >
          Últimos 6 meses
        </PeriodButton>
        <PeriodButton
          selected={selectedPeriod === "select-period"}
          id="btn-select-period" 
          onClick={e => {
            tableContainerRef.current.focus();
            handleClickPeriod(e.target.id);
            setSearchTerm("");
          }}
        >
          Selecionar período
        </PeriodButton>
      </ButtonsContainer>
      
      {selectedPeriod === "select-period" && applyPeriodHasBeenPressed &&
      selectPeriodStartDate && selectPeriodEndDate && 
        <SelectedPeriodText>Histórico de compras do período {format(startDate, 'dd/MM/yyyy')} - {format(endDate, 'dd/MM/yyyy')}</SelectedPeriodText>
      }
  
      {selectedPeriod === "select-period" &&  
      !selectPeriodStartDate && !selectPeriodEndDate &&
      <SelectPeriodContainer>
        <div>
          <DatePicker
            onKeyDown={(e) => {
              if (e.key === 'Backspace') handleChangeRawDate('', setStartDate, 'startDate');
            }}
            onChangeRaw={(e) => {
              handleChangeRawDate(e.target.value, setStartDate, 'startDate')
            }}
            onChange={(value) => {
              handleChangeDate(value, setStartDate, 'startDate');
            }}
            placeholderText="Data inicial"
            selected={startDate}
          />
        </div> 
        <div>
          <DatePicker
            onKeyDown={(e) => {
              if (e.key === 'Backspace') handleChangeRawDate('', setEndDate, 'endDate');
            }}
            onChangeRaw={(e) => {
              handleChangeRawDate(e.target.value, setEndDate, 'endDate')
            }}
            onChange={(value) => {
              handleChangeDate(value, setEndDate, 'endDate');
            }}
            placeholderText="Data final"
            selected={endDate}
          />
        </div>
        <ApplyPeriodButton 
          disabled={isWaitingForSelection}
          onClick={() => {
            setApplyPeriodHasBeenPressed(true);
            handleApplyPeriod(startDate, endDate);
          }}
        >
          Aplicar período
        </ApplyPeriodButton>
      </SelectPeriodContainer>}
           
      {(selectedPeriod === "select-period" && applyPeriodHasBeenPressed && selectPeriodStartDate && selectPeriodEndDate) || (selectedPeriod === "90-days" || selectedPeriod === "6-months") ?
      <ProductSearchBarContainer>
        <i className="material-icons" id="searchHistory" onClick={() => handleSearchForProducts(searchTerm)}>search</i>  
        <i className="material-icons" id="searchClear" onClick={() => {handleClearSearch(); setSearchTerm("")}}>clear</i>
        <ProductSearchBar 
          placeholder="Pesquisar produtos abaixo"
          innerRef={inputRef}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && handleSearchForProducts(searchTerm)}
        />
      </ProductSearchBarContainer> : ""}

      <TableContainer 
        hasMessage={(selectedPeriod === "select-period" && isWaitingForSelection) || data.length === 0}
        id="tableContainerScrollId"
        innerRef={tableContainerRef}
        tabIndex={-1}
      >
        <InfiniteScroll 
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={hasMoreData}
          style={{height: "100%"}}
          scrollableTarget="tableContainerScrollId"
          hasChildren={true}
          loader={data.length > 0 && <InfiniteScrollLoader>Carregando...</InfiniteScrollLoader>}
        >
          <Table striped size="sm" borderless>
            <colgroup>
              <col style={{width: "45%"}} />
              <col style={{width: "25%"}} />
              <col style={{width: "15%"}} />
              <col style={{width: "15%"}} />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <div>
                    <p>Produto</p> 
                    <SortIcon
                      className={sortConfig.key === "product" && sortConfig.direction === "DESC" ? 'svg-desc-order' : ''} 
                      width={16} 
                      height={16} 
                      color={activeColorSort("product")}
                      onClick={() => requestSort("product")}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <p>Fabricante</p> 
                    <SortIcon
                      className={sortConfig.key === "manufacturer" && sortConfig.direction === "DESC" ? 'svg-desc-order' : ''} 
                      width={16} 
                      height={16} 
                      color={activeColorSort("manufacturer")}
                      onClick={() => requestSort("manufacturer")}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <p>Data de compra</p> 
                    <SortIcon 
                      className={sortConfig.key === "date" && sortConfig.direction === "DESC" ? 'svg-desc-order' : ''}
                      width={16} 
                      height={16} 
                      color={activeColorSort("date")}
                      onClick={() => requestSort("date")}
                    />
                  </div>
                </th>
                <th>Valor un. líquido</th>
              </tr>
            </thead>
            {selectedPeriod === "select-period" && !selectPeriodStartDate && !selectPeriodEndDate ? 
              <MessageContainer>
                <tr>
                  <td align="center" colSpan={4}>
                    <i className="material-icons">error_outline</i>
                    <h3>Aguardando seleção do período para exibir resultados.</h3>
                  </td>
                </tr>
              </MessageContainer> : data.length === 0 && !isFetchingNewData ? 
              <MessageContainer>
                <tr>
                  <td align="center" colSpan={4}>
                    <i className="material-icons">error_outline</i>
                    <h3>Cliente selecionado não possui compras.</h3>
                  </td>
                </tr>
              </MessageContainer> : 
              <tbody>
                {data.length > 0 && data.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td title={product.dsProd}>{product.dsProd ?? "-"}</td>
                      <td>{product.nmFabric ?? "-"}</td>
                      <td>{format(new Date(product.dtVd), 'dd/MM/yyyy')}</td>
                      <td>{currencyMask(product.totIt)}</td>
                    </tr>
                  )
                })}
              </tbody>
            }
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Card>
  )
}
