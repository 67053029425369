import FarmaciaPopularIcon from '../../assets/images/farmacia-popular.png';
import AuthorizationsIcon from '../../assets/images/menu/autorizacoes-pbm.png';
import EpharmaIcon from '../../assets/images/menu/epharma.png';
import FuncionalIcon from '../../assets/images/menu/funcional.png';
import SevenIcon from '../../assets/images/menu/seven.png';
import TrnCentreIcon from '../../assets/images/menu/trncentre.PNG';
import DtefPbmIcon from '../../assets/images/menu/pbms-via-tef.png';
import getShortcut, {
  FIRST_OPTION,
  SECOND_OPTION,
  THIRD_OPTION,
  FOURTH_OPTION,
  FIFTH_OPTION,
  SIXTH_OPTION,
  SEVENTH_OPTION,
  LIST_AUTHORIZATIONS,
} from '../../keyboardShortcuts';
import {
  CONFIG_PBM_DTEF,
  CONFIG_PBM_EPHARMA,
  CONFIG_PBM_FARMACIA_POPULAR,
  CONFIG_PBM_FUNCIONAL,
  CONFIG_PBM_ORIZON,
  CONFIG_PBM_SEVEN,
  CONFIG_TRNCENTRE
} from './configs';
import {
  OPERATION_LIST_AUTHORIZATIONS,
  OPERATION_NAME_EPHARMA_PBM,
  OPERATION_NAME_FARMACIA_POPULAR,
  OPERATION_NAME_DTEF_Pbm,
  OPERATION_NAME_FUNCIONAL_PBM,
  OPERATION_NAME_ORIZON,
  OPERATION_NAME_SEVEN_PBM,
  OPERATION_NAME_TRNCENTRE
} from './operations';

const items = [
  {
    operationName: OPERATION_NAME_DTEF_Pbm,
    icon: DtefPbmIcon,
    isHidden: true,
    shortKey: getShortcut(FIRST_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_PBM_DTEF,
  },
  {
    operationName: OPERATION_NAME_FARMACIA_POPULAR,
    icon: FarmaciaPopularIcon,
    isHidden: true,
    shortKey: getShortcut(SECOND_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_PBM_FARMACIA_POPULAR,
  },
  {
    operationName: OPERATION_NAME_EPHARMA_PBM,
    icon: EpharmaIcon,
    isHidden: true,
    shortKey: getShortcut(THIRD_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_PBM_EPHARMA,
  },
  {
    operationName: OPERATION_NAME_FUNCIONAL_PBM,
    icon: FuncionalIcon,
    isHidden: true,
    shortKey: getShortcut(FOURTH_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_PBM_FUNCIONAL,
  },
  {
    operationName: OPERATION_NAME_ORIZON,
    icon: './assets/images/menu/orizon.png',
    isHidden: true,
    shortKey: getShortcut(FIFTH_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_PBM_ORIZON,
  },
  {
    operationName: OPERATION_NAME_SEVEN_PBM,
    icon: SevenIcon,
    isHidden: true,
    shortKey: getShortcut(SIXTH_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_PBM_SEVEN,
  },
  {
    operationName: OPERATION_NAME_TRNCENTRE,
    icon: TrnCentreIcon,
    isHidden: true,
    shortKey: getShortcut(SEVENTH_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_TRNCENTRE,
  },
  {
    operationName: OPERATION_LIST_AUTHORIZATIONS,
    icon: AuthorizationsIcon,
    isHidden: true,
    shortKey: getShortcut(LIST_AUTHORIZATIONS),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  }
];

export default items;
