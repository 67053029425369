import React from 'react';

import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import FormControl from '../@form-control';
import Button from '../button';
import Card from '../card';
import { Table } from '../table2';
import { Filters, NoRecord } from './styles';
import validationSchema from './validation';

export default function SearchCep({
  title,
  onClose,
  onSearch,
  data,
  onSelect,
  ufOptions,
  cityOptions,
  onUFChange,
  onCityChange,
  selectedUf,
  selectedCity
}) {
  const config = {
    selectable: true,
    limitPerPage: 300,
    handleClick: () => { },
    columns: [
      { title: 'Logradouro', label: 'rua' },
      { title: 'Complemento', customCellrender: (item) => getComplemento(item) },
      { title: 'Bairro', label: 'bairro' },
      { title: 'Localidade', label: 'cidade' },
      { title: 'UF', label: 'uf' },
      { title: 'CEP', label: 'cep' }
    ],
  };

  const initialValues = {
    address: '',
    quarter: '',
    uf: selectedUf,
    city: selectedCity,
    ibge: ''
  };
  const getComplemento = (item) => {
    if (item.trechoInicial.length > 0) {
      let complemento ='De '+ item.trechoInicial + ' até ' + item.trechoFinal;
      if (item.idParidade !== 'A') 
        complemento += ' Lado ' + (item.idParidade === 'P' ? 'Par' : 'Impar');
      
      return complemento;
    }
    return 'Sem complemento';
  }
  return (
    <Card title={title} handleClose={onClose} closeEnable>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSearch}
      >
        <Form>
          <Filters>
            <FormControl
              name="address"
              label="Endereço*"
              placeholder="Não utilize nº de casa/apto/lote/prédio ou abreviação"
              flex="1"
              autoComplete="off"
            />
            <FormControl
              label="UF*"
              name="uf"
              options={ufOptions}
              type="select"
              onChange={onUFChange}
              value={selectedUf}
            />
            <FormControl
              label="Cidade*"
              name="city"
              options={cityOptions}
              onChange={onCityChange}
              value={selectedCity}
              type="select"
            />
            <Button data-testid="search-cep-submit" type="submit">
              <i className="material-icons">search</i>
            </Button>
          </Filters>
        </Form>
      </Formik>
      <Table config={config} data={data} onRowSelect={onSelect} />
      {
        data.length === 0 &&
        <NoRecord>
          Nenhum registro encontrado!
        </NoRecord>
      }
    </Card>
  );
}

SearchCep.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
