import React, { Component } from 'react';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import PreOrderInputWrapper from './style';

const customStyle = {
  content: {
    maxWidth: '400px',
    maxHeight: '210px',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    display: 'block',
  },
  iconCloseModal: {
    float: 'right',
    cursor: 'pointer',
  },
};

class CancelOrderInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idTyped: '',
    };

    this.onSavePreOrderId = this.onSavePreOrderId.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onHandlerIdPreOrderChange = idTyped => this.setState({ idTyped })

  onCloseModal() {
    this.props.handlerSetPreOrderId();
    this.setState({ idTyped: '' });
    this.props.handlerStateModal(false);
  }

  onSavePreOrderId(e) {
    e.preventDefault();

    this.props.handlerSetPreOrderId(this.state.idTyped);
    this.setState({ idTyped: '' });
    this.props.handlerStateModal(false);
  }

  render() {
    const { idTyped } = this.state;
    return (
      <Modal isOpen={this.props.showModal} closeOnOverlayClick={false} center style={customStyle} ariaHideApp={false}>
        <i className="material-icons close-modal" style={customStyle.iconCloseModal} onClick={this.onCloseModal}>cancel</i>
        <PreOrderInputWrapper>
          <h3>Número da Nota:</h3>
          <div className="input__container">
            <NumberFormat id="basket-number" ref="txtAmount" value={idTyped} onChange={event => this.onHandlerIdPreOrderChange(event.target.value)} className="value-area" placeholder="Digite o número da ficha" />
            <button id="btn-save-id" onClick={this.onSavePreOrderId}>Inserir</button>
          </div>
        </PreOrderInputWrapper>
      </Modal>
    );
  }
}

export default CancelOrderInput;
