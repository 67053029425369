import React, { Fragment, Component } from 'react';
import Modal from 'react-modal';
import { DetailOrderWrapper, InputWrapper } from './style';
import { Table, Button } from '../modules';
import QrCodeCancelMessage from '../qr-code-cancel-message';

const customStyle = {
  content: {
    maxWidth: '550px',
    maxHeight: 'min-content',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    display: 'block',
  },
  iconCloseModal: {
    float: 'right',
    cursor: 'pointer',
  },
};

class SelectedOrderInput extends Component {
  constructor(props) {
    super(props);
    this.modalQrCodeCancelMessage = React.createRef();
    this.onCancel = this.onCancel.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onPrintTef = this.onPrintTef.bind(this);
    this.state = {
      reason: '',
    };

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.modalRef.current.node.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.modalRef.current.node.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      this.onCloseModal();
    }
  }

  onCloseModal() {
    this.setState({
      reason: '',
    });
    this.props.handlerStateModal(false, true);
  }

  handleCloseQrCodeCancelMessage = () => {
    this.modalQrCodeCancelMessage.current.close();
  };

  handleConfirmQrCodeCancelMessage = () => {
    if (this.props.typeButtonCancelTef)
      this.props.handlerCancelTef(this.props.order);
    else
      this.props.handleOrderCancel(this.props.idOrder);

    this.props.handlerStateModal(false, false);

    this.modalQrCodeCancelMessage.current.close();
  };

  onCancel(e) {
    e.preventDefault();        
    
    if (this.props.order.payments.some(e => e.paymentType.enumValue === 12)) {
      this.modalQrCodeCancelMessage.current.open();
      return;
    }    
    if (this.props.typeButtonCancelTef)
      this.props.handlerCancelTef(this.props.order);
    else
      this.props.handleOrderCancel(this.props.idOrder);

    this.props.handlerStateModal(false, false);
  }

  onPrintTef(e) {
    e.preventDefault();
    this.props.handlerPrintTef(this.props.order);
    this.props.handlerStateModal(false, true);
  }
  columns = [
    { title: 'DESCRIÇÃO', cols: [{ label: { ref: 'name' } }] },
    { title: 'QUANTIDADE', cols: [{ label: { ref: 'quantity' } }] },
    { title: 'TOTAL', cols: [{ label: { ref: 'subTotal' } }] }
  ];

  paymentCols = [
    { title: 'Pagamento', cols: [{ label: { ref: 'name' } }] },
    { title: 'Valor', cols: [{ label: { ref: 'amountFormated' } }] }
  ];

  render() {
    const textButton = this.props.typeButtonPrint ? 'Reimprimir cupom' : 'Confirmar cancelamento';
    const isModoPrint = this.props.typeButtonPrint;    
    return this.props.order ?
      (<Fragment>
        <QrCodeCancelMessage
          modalQrCodeCancelMessage={this.modalQrCodeCancelMessage}
          handleCloseQrCodeCancelMessage={this.handleCloseQrCodeCancelMessage}
          handleConfirmQrCodeCancelMessage={this.handleConfirmQrCodeCancelMessage}>
        </QrCodeCancelMessage>
        <Modal id="order-detail-modal" ref={this.modalRef} isOpen={this.props.showModal} closeOnOverlayClick={true} center style={customStyle} ariaHideApp={false}>
          <i className="material-icons close-modal" style={customStyle.iconCloseModal} onClick={this.onCloseModal}>cancel</i>
          <span>Nº CUPOM: </span>
          <strong>{this.props.order.couponNumber}</strong>
          <DetailOrderWrapper>
            <span className="sub-title">PRODUTOS</span>
            <Table columns={this.columns} items={this.props.order.itens} />
            <span className="sub-title">PAGAMENTOS</span>
            <Table columns={this.paymentCols} items={this.props.order.payments} />

            <h3>Status:</h3>
            <strong>{this.props.order.fiscalDocument && this.props.order.fiscalDocument.reason}</strong>
            <InputWrapper>
              <Button tabIndex="0" autoFocus={true}
                id="btn-conf-cancel"
                type="button"
                className="btn-large btn-purple btn-confirm-pay"
                handleClick={(e) => { isModoPrint ? this.onPrintTef(e) : this.onCancel(e) }}
              >
                {textButton}
              </Button>
            </InputWrapper>
          </DetailOrderWrapper>
        </Modal>
      </Fragment>) : null;
  }
}

export default SelectedOrderInput;
