import styled from "styled-components";
import theme2 from "../../../themes/theme2";

export const CheckPreOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 5px;

  .title-barcode-wrapper {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .section-separator {
    width: 90%;
    margin-top: 0;
    border-color: #d2d0d0;
  }
`;

export const SectionCheckPreOrder = styled.section`
  display: ${({ display }) => display};
  justify-content: ${({ justify }) => justify};
  flex-wrap: ${({ wrap }) => wrap};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  min-height: ${({ minHeight }) => minHeight};
  min-width: 810px;
  background-color: ${({ bg }) => bg};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const HeaderCheckPreOrder = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  .header-label {
    font-size: 14px;
    font-weight: 400;
  }

  .header-data {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const BarcodeCheckPreOrderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 110px;

  input[type="text"] {
    height: 50px;
    width: 100%;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  input[type="text"]::placeholder {
    color: #9e9e9e;
  }

  input[type="text"]:focus {
    border: none;
    outline: none;
  }

  .barcode-group,
  .quantity-group {
    display: flex;
    flex-direction: column;
  }

  .barcode-group {
    flex: 1;
    margin-left: 24px;
    margin-top: 32px;

    label {
      display: flex;
      align-items: center;
    }
  }

  .quantity-group {
    width: 90px;

    input {
      text-align: center;
      font-size: 20px;
    }
  }

  .barcode-input {
    position: relative;

    img {
      position: absolute;
      margin: 5px;
      height: 40px;
    }
    input {
      padding-left: 70px;
    }
  }
`;

export const TableCheckPreOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TableHeaderPreOrder = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  color: ${theme2.palette.brandLight};
  border-bottom: 2px solid #d2d0d0;
`;

export const TableBodyPreOrder = styled.div`
  color: #000000;
  font-weight: 500;
`;

export const TableRowPreOrder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => padding};
  border-bottom: 1px solid #d2d0d0;
`;

export const TableCellPreOrder = styled.span`
  display: flex;
  justify-content: ${({ justify }) => justify};
  flex: 1;
  margin-left: 8px;
  min-width: ${({ minWidth }) => minWidth || "105px"};
`;

export const StatusTag = styled.span`
  display: block;
  width: 100px;
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: ${({ bg }) => bg};
`;
