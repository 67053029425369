import styled from 'styled-components';

import theme2 from '../../../themes/theme2';

export const TableRow = styled.tr``;

export const Options = styled.div`
  margin-top: ${theme2.metrics.baseMargin * 2}px;
  margin-bottom: ${theme2.metrics.baseMargin}px;

  button {
    display: flex;
    align-items: center;
    outline: 0;

    &:focus {
      border: 0 !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }

    span {
      padding-left: 6px;
      color: ${theme2.palette.primary};
      font-size: 16px;
      font-weight: bold;
    }
  }
`;
