import styled from 'styled-components';

import theme2 from '../../../../themes/theme2';

export const Container = styled.tr`
  font-family: 'Ubuntu', 'Dosis';
  font-size: 14px;

  td {
    white-space: normal !important;

    i {
      line-height: inherit;
      color: ${theme2.palette.primary};
    }
  }
`;
