import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  show: false,
  message: '',
  okText: 'OK',
  onConfirm: null,
  onCancel: null,
};

export const TYPE_SHOW_MESSAGE = '@MESSAGE/SHOW';
export const TYPE_HIDE_MESSAGE = '@MESSAGE/HIDE';

export const showMessageAction = createAction(
  TYPE_SHOW_MESSAGE,
  ({ message, okText = 'OK', onConfirm, onCancel }) => ({
    message,
    okText,
    onConfirm,
    onCancel,
  })
);

export const hideMessageAction = createAction(TYPE_HIDE_MESSAGE);

const reducer = handleActions(
  {
    [TYPE_SHOW_MESSAGE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.show = true;
        draft.message = payload.message;
        draft.okText = payload.okText;
        draft.onConfirm = payload.onConfirm;
        draft.onCancel = payload.onCancel;
      }),
    [TYPE_HIDE_MESSAGE]: (state) =>
      produce(state, (draft) => {
        draft.show = false;
      }),
  },
  defaultState
);

export default reducer;
