import React from 'react';

import LogoWrapper from './style';

const Logo = () => (
  <LogoWrapper>
    <img
      src="/assets/images/logo-dark.svg"
      title="Logo Farmacloud"
      alt="Logo Farmacloud"
    />
  </LogoWrapper>
);

export default Logo;
