import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PbmSaleService, { PBM_FUNCIONAL_CARD } from '../../services/pbmSaleService';
import { PBM_EXTERNAL_AUTHORIZATION, shouldShow, closeCard, showCard } from '../../redux/actions/routerAction';
import MessageBusService from '../../services/MessageBusService';
import PbmExternalAuthorizationComponent from '../../shared/components/pbm-external-authorization/pbm-external-authorization';
import moment from 'moment';

export default function PbmExternalAuthorizationContainer(props) {
    const initialForm = { Pbm: 0, CpfCartao: '', Autorizacao: '', Data: new Date(moment().year(), moment().month(), moment().date()) };
    const visible = useSelector(state => shouldShow(state, PBM_EXTERNAL_AUTHORIZATION));
    const [title, setTitle] = useState('PBM');
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialForm);
    
    function handleOpenExternalAuthorizer()
    {
        MessageBusService.GetInstance().Publish('machine.browser.open','http://www.funcionalcard.com.br/autorizadorweb/geral/login.aspx');
    }
    function initializationClose()
    {
        PbmSaleService.closeInitialization(PBM_FUNCIONAL_CARD);
    }
    function handleClose() {
        setFormData(initialForm);
        dispatch(closeCard(PBM_EXTERNAL_AUTHORIZATION));
    }
    function handleInitialization(ch, payload) {
        setTitle(payload.pbmDescription);
        setFormData({ ...formData, Pbm: payload.pbm });
        dispatch(showCard(PBM_EXTERNAL_AUTHORIZATION, null, true));
    }
    function validateForm() {
        return formData.Autorizacao.trim().length > 0 &&
            formData.CpfCartao.trim().length > 0 &&
            formData.Data.toString().trim().length > 0;
    }
    function handleSearchAuthorization() {
        if (validateForm()) {
            const authDateTime = moment(formData.Data).add(moment.duration(formData.Hora)).format('YYYY-MM-DD HH:mm:ss');
            PbmSaleService.recoverAuthorization(formData.CpfCartao, authDateTime, formData.Autorizacao, formData.Pbm);
        }
        else {
            MessageBusService.GetInstance().Publish('pos.dialog', {
                message: 'Todos os campos são obrigatórios para recuperar a pré-autorização!',
            });
        }
    }
    function handleChange(e) {
        let form = { ...formData };
        form[e.target.id] = e.target.value || null;
        setFormData(form);
    }

    useEffect(() => {
        const showSubscription = MessageBusService.GetInstance().Subscribe('pos.pbm.initialization.externalauthorization', handleInitialization);
        const closeSubscription = MessageBusService.GetInstance().Subscribe('pos.pbm.recoverAuthorizationSuccess', handleClose);
        const closeInitialization = MessageBusService.GetInstance().Subscribe('pos.pbm.closeInitialization', handleClose);

        return () => {
            MessageBusService.GetInstance().Unsubscribe(showSubscription);
            MessageBusService.GetInstance().Unsubscribe(closeSubscription);
            MessageBusService.GetInstance().Unsubscribe(closeInitialization);
        };
    }, []);

    return (visible && <PbmExternalAuthorizationComponent
        title={title}
        handleClose={initializationClose}
        form={formData}
        handleSearch={handleSearchAuthorization}
        handleChange={handleChange}
        handleOpenExternalAuthorizer={handleOpenExternalAuthorizer}>
    </PbmExternalAuthorizationComponent>)
}