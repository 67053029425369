import React, { Component } from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';

import FormControl from '../../@form-control';
import { DatePicker } from '../../date-picker';
import Fieldset from '../../fieldset';
import { PRESCRIPTION_ANTIMICROBIAL } from '../types';
import { RadioGroup, ProlongedUse } from './styles';
import { subDays } from 'date-fns';
import { handleTypedDateChange } from '../../../utils/handleDate';

class Prescription extends Component {
  constructor(props) {
    super(props);
    this.createdAtRef = React.createRef();
  }

  focusOnCreatedAt = () => {
    this.createdAtRef.current.focus();
  };

  handlePrescriptionTypeChange = (value) => {
    const { setFieldValue } = this.props;
    setFieldValue('prescription.prescriptionType', value);
    setFieldValue('buyer.prescriptionType', value);
    setFieldValue('patient.prescriptionType', value);
  };

  render() {
    const { options, setFieldValue, data } = this.props;

    return (
      <Fieldset legend="Informações da receita" hasRequiredField>
        <div className="custom-form-row">
          <FormControl
            data-testid="prescriptionType"
            label="Tipo de receita*"
            flex="0.6"
            type="select"
            name="prescription.prescriptionType"
            options={options.receiptTypes}
            onChange={(e) => this.handlePrescriptionTypeChange(e.target.value)}
          />

          <FormControl
            label="Data receita*"
            flex="0.3"
            name="prescription.createdAt"
          >
            <Field name="prescription.createdAt">
              {({ props }) => (
                <DatePicker
                  {...props}
                  ref={this.createdAtRef}
                  onChange={(value) =>
                    setFieldValue('prescription.createdAt', value || '')
                  }
                  onChangeRaw={(e) =>
                    handleTypedDateChange(e.target.value, 'prescription.createdAt', setFieldValue)
                  }
                  selected={data.createdAt}
                  autoFocus
                  id="date-sngpc-receita"
                  minDate={subDays(new Date(), 120)}
                  maxDate={new Date()}
                />
              )}
            </Field>
          </FormControl>

          {parseInt(data.prescriptionType, 10) !==
            PRESCRIPTION_ANTIMICROBIAL && (
              <FormControl
                label="Número da receita*"
                flex="0.3"
                name="prescription.prescriptionNumber"
              >
                <Field name="prescription.prescriptionNumber">
                  {({ field }) => <input maxLength="18" data-testId="receitaNumber" onKeyDown={(e) => {
                    if (!/[0-9]/g.test(e.key) && e.key !== "Backspace" && e.key !== "Enter" && e.key !== "Tab") {
                      e.preventDefault();
                    }
                  }} {...field} />}
                </Field>
              </FormControl>
            )}

          {parseInt(data.prescriptionType, 10) ===
            PRESCRIPTION_ANTIMICROBIAL && (
              <ProlongedUse>
                <FormControl
                  label="Uso Prolongado*"
                  name="prescription.prolongedUse"
                  flex="0.3"
                >
                  <RadioGroup>
                    <div>
                      <input
                        type="radio"
                        id="prescription.prolongedUse.true"
                        name="prescription.prolongedUse"
                        defaultChecked={data.prolongedUse}
                        onChange={() =>
                          setFieldValue('prescription.prolongedUse', true)
                        }
                      />
                      <label htmlFor="prescription.prolongedUse.true">Sim</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="prescription.prolongedUse.false"
                        name="prescription.prolongedUse"
                        defaultChecked={data.prolongedUse === false}
                        onChange={() =>
                          setFieldValue('prescription.prolongedUse', false)
                        }
                      />
                      <label htmlFor="prescription.prolongedUse.true">Não</label>
                    </div>
                  </RadioGroup>
                </FormControl>
              </ProlongedUse>
            )}
        </div>
      </Fieldset>
    );
  }
}

Prescription.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.shape({
    receiptTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  data: PropTypes.shape({
    prolongedUse: PropTypes.bool,
    prescriptionType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    createdAt: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default Prescription;
