import { darken } from 'polished';
import styled from 'styled-components';

import theme from '../../../themes/default';
import theme2 from '../../../themes/theme2';

export const CouponWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  background-color: ${theme.palette.grayscale.white};

  input {
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    font: 16px Dosis;
    text-align: end;
    font-weight: bold;
  }

  header {
    font-size: 20px;
    text-align: center;
    flex: 0 0 70px;
  }

  header button {
    height: 57px;
  }

  .recovery-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .promotion {
    height: 80px;
  }

.box-payment{
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;    
  height: 67px;
}

  .box-b {
    position: relative;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;

    #btn_payment_type{
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: left;
      flex: 1.3;
      .btn{
        padding: none
      }
    }

    #btn_customer {      
      display: flex;
      flex-direction: row;
      flex: 1.3;
      align-items: center;
      justify-content: flex-start;
      padding: 0 ${theme2.metrics.basePadding}px;

      .info {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;

        .customer_name {
          font-size: 16px;
          text-transform: none !important;
          white-space: ${(props) => props.nowrapName && 'nowrap'};
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          width: 93%;
          margin-left: -20px;
        }

        .business_agreement_option {
          font-size: 10px;
          align-self: baseline;
          &:hover {
            color: #ffb200;
          }
        }

        .business_agreement_option {
          width: 100%;
          text-decoration: underline;
          color: blue;
          &:hover {
            color: #ffb200 !important;
          }
        }
      }
    }

    #btn_shopping {
      flex: 0.4;
    }

    .menu-area-b-agreement {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: absolute;
      top: 59px;
      left: 125px;
      justify-content: center;
      border: 1px solid lightgray;
      background-color: white;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      font-size: 15px;
      z-index: 500;
      top: 66px;
      left: 17px;

      &.large {
        width: 90.5%;
      }

      &.medium {
        width: 62%;
      }

      .business-option {
        display: flex;
        justify-content: center;
        outline: none;
        &:hover {
          background-color: #ffb200;
        }
      }

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        height: 38px;
        padding: 0 10px;
        color: ${theme.palette.grayscale.ultraDark};
      }
    }
  }

  .box-c {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    justify-content: center;
    height: 35px;
    width: 100%;

    #btn_business_agreement {
      width: 160px;
      height: 100%;
      i {
        padding-top: 4px;
        font-size: 23px;
        padding-right: 3px;
      }
    }
  }

  .promotion {
    background-color: #5b2e90;
  }

  .main-container {
    max-height: 100%;
    min-height: 0;
    width: 100%;
    padding: 15px 15px 15px 15px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .sub-title-current-business-agreement {
      font-family: 'Dosis';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #6e4b87;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
  }

  .footer-container {
    padding: 10px;
    flex: 0 0 70px;
    #total {
      margin: 0;
      padding: 0 0.5rem;
      float: right;
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 3px;
      cursor: pointer;
    }
  }

  footer span {
    height: inherit;
    font-size: 20px;
    font-weight: bolder;
    display: table-cell;
  }

  .payments-subtitle {
    align-self: center;
    font-size: 14px;
    font-weight: bold;
  }

  .sub-total {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .sub-total-item {
    padding: 3px 0 3px 0;
    cursor: pointer;
  }

  .sub-total-item:first-child,
  .subsidy {
    border-top: 1px solid ${theme.palette.grayscale.light};
  }
  .subsidy {
    margin-top: 10px;
  }

  .total-recebido {
    border-top: 1px solid ${theme.palette.grayscale.light};
    // border-bottom: 2px solid ${theme.palette.grayscale.light};
    padding-bottom: 10px;
  }

  .payments-types-click {
    &:hover {
      cursor: pointer;
      color: ${theme.palette.color[1]};
    }
  }

  .bold {
    font-weight: bold;
  }

  .sub-total-valor {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    float: right;
  }

  button {
    border: none;
    border-radius: 4px;
    padding: 0 16px;
    color: #fff;
    background-color: ${theme.palette.primary[14]};
    letter-spacing: 0.5px;
    cursor: pointer;
    height: 32.4px;
    min-width: 76px;
    align-self: auto;

    &[disabled] {
      cursor: unset;
    }
  }

  .btnListPreOrder {
    margin-right: 5px;
    background-color: ${theme.palette.primary[14]};
    font-size: medium;
    font-family: Arial;
  }

  .btnDonePreOrder {
    margin-right: 5px;
    background-color: ${theme.palette.primary[14]};
  }
`;

export const FooterButton = styled.div`
  color: ${theme.palette.grayscale.white};
  background-color: ${theme2.palette.primary};
  border-radius: ${theme2.metrics.borderRadius}px;
  display: flex;
  justify-content: space-between;
  height: 60px;

  button {
    width: 40%;
  }

  button,
  #total {
    height: 100%;

    &:hover {
      background: ${darken(0.1, theme2.palette.primary)};
    }
  }

  #total {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    border-top-right-radius: ${theme2.metrics.borderRadius}px;
    border-bottom-right-radius: ${theme2.metrics.borderRadius}px;
  }
`;
