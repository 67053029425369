import React from 'react';
import { connect } from 'react-redux';
import { PbmInitialization } from '../../shared/components/modules';
import PbmSaleService, { PBM_EPHARMA } from '../../services/pbmSaleService';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';

class PbmInitializationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      slideToggleValue: false
    };
    this.form = {
      convenio: null,
      cardNumber: null,
      prescriptionDate: null,
      prescriptorUf: null,
      prescriptorDocument: null,
      prescriptorType: null
    };

    this.subscription = null;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInitialization = this.handleInitialization.bind(this);
    this.reset = this.reset.bind(this);
    this.handlePbmStarted = this.handlePbmStarted.bind(this);
    this.handleToggleChange = this.handleToggleChange.bind(this);

    this.props.MessageBus.Subscribe('pos.pbm.initialization.internalauthorization', this.handleInitialization);
    this.props.MessageBus.Subscribe('pos.pbm.closeInitialization', this.reset);
  }
  
  handleInitialization(ch, payload) {
    const infoPbm = this.props.infoPbm;
    if(infoPbm) {
      this.form = {
        convenio: infoPbm.convenio,
        cardNumber: infoPbm.cardNumber,
        prescriptionDate: infoPbm.prescriptionDate,
        prescriptorUf: infoPbm.prescriptorUf,
        prescriptorDocument: infoPbm.prescriptorDocument,
        prescriptorType: infoPbm.prescriptorType
      }
    }
    this.setState({
      payload: payload,
      slideToggleValue: !!infoPbm && 
        !!(infoPbm.prescriptorDocument ||
          infoPbm.prescriptionDocument ||
          infoPbm.prescriptionDate ||
          infoPbm.prescriptorType
        )
    });
  }

  handlePbmStarted() {
    this.subscription && this.props.MessageBus.Unsubscribe(this.subscription);
    this.subscription = null;
    this.reset();
  }

  reset() {
    this.subscription = null;
    this.form = {};
    this.setState({
      payload: null,
      slideToggleValue: false
    });
  }

  handleConfirm() {
    this.subscription = this.props.MessageBus.Subscribe('pos.order.updated', this.handlePbmStarted);
    PbmSaleService.startSale(PBM_EPHARMA, this.form);
    this.form.convenio.tipoReceita.toString() === '1' && this.props.MessageBus.Publish('front.toast.show', {
      title: 'Atenção!',
      message: 'Para este convênio, é necessário reter a receita',
      hideAfterMilliseconds: 10000,
      type: 1
    });
  }

  handleClose() {
    PbmSaleService.closeInitialization(PBM_EPHARMA);
  }

  handleToggleChange(e) {
    if(!e.target.checked) {
      this.form = {
        ...this.form,
        prescriptionDate: null,
        prescriptorUf: null,
        prescriptorDocument: null,
        prescriptorType: null
      }
    }
    this.setState({
      slideToggleValue: e.target.checked
    });
  }

  render() {
    return (this.state.payload && 
      <PbmInitialization
        form={this.form}
        pbmDescription={this.state.payload.pbmDescription}
        convenios={this.state.payload.convenios}
        prescriptorTypes={this.state.payload.prescriptorTypes}
        states={this.state.payload.states}
        handleToggleChange={this.handleToggleChange}
        handleConfirm={this.handleConfirm}
        handleClose={this.handleClose}
        slideToggleValue={this.state.slideToggleValue}
      />
    )
  }
}
const mapStateToProps = state => ({
  infoPbm: state.order.order?.infoPbm
});

export default connect(mapStateToProps, null)(withMessageBus(getContext(PbmInitializationContainer)));