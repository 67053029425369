import styled from 'styled-components';

export const Radio = styled.input`
  margin-right: 0.3rem;

  &:hover {
    cursor: pointer;
  }

  &:after {
    position: absolute;
    width: 14px;
    height: 14px;

    background-color: #ffffff;
    border: 1px solid #a1a1a1;
    box-sizing: border-box;
    content: '';
    visibility: visible;
    border-radius: 20px;
  }

  &:checked:after {
    width: 14px;
    height: 14px;
    border-radius: 20px;
    position: absolute;
    background-color: #5b2e90;
    content: '';
    visibility: visible;
    border: 2px solid #5b2e90;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const Label = styled.label`
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0px 1rem;

  &:first-child {
    margin-left: 0px;
  }
`;
