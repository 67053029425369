/* eslint no-useless-escape: off */
const BLACKLIST = [
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
  '12345678909',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999',
];

const LOOSE_STRIP_REGEX = /[^\d]/g;
const STRICT_STRIP_REGEX = /[-\/.]/g;

export function verifierDigit(numbers) {
  const numbers1 = numbers.split('').map((number) => parseInt(number, 10));
  const modulus = numbers1.length + 1;

  const multiplied = numbers1.map(
    (number, index) => number * (modulus - index)
  );

  const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;

  return mod < 2 ? 0 : 11 - mod;
}

export function verifierDigitCnpj(numbers) {
  let index = 2;
  const reverse = numbers
    .split('')
    .reduce((buffer, number) => [parseInt(number, 10)].concat(buffer), []);

  const sum = reverse.reduce((buffer, number) => {
    buffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
}

export function strip(cpf, isStrict) {
  const regex = isStrict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (cpf || '').toString().replace(regex, '');
}

// mascara
export function MaskCNPJ(cnpj) {
  return strip(cnpj)
    .substr(0, 14)
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{0,4})(\d{0,2})$/, '$1.$2.$3/$4-$5');
}

export function MaskCPF(cpf) {
  const len = strip(cpf).length;
  switch (len) {
    case 3:
    case 4:
    case 5:
      return strip(cpf).replace(/^(\d{3})(\d{0,3})$/, '$1.$2');
    case 6:
    case 7:
    case 8:
      return strip(cpf).replace(/^(\d{3})(\d{3})(\d{0,3})$/, '$1.$2.$3');
    default:
      return strip(cpf).replace(
        /^(\d{3})(\d{3})(\d{3})(\d{0,2})$/,
        '$1.$2.$3-$4'
      );
  }
}

// valida o CNPJ digitado
export const ValidateCNPJ = (cnpj, isStrict = true) => {
  const stripped = strip(cnpj, isStrict);

  // CNPJ must be defined
  if (!stripped) {
    return false;
  }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) {
    return false;
  }

  // CNPJ can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigitCnpj(numbers);
  numbers += verifierDigitCnpj(numbers);
  return numbers.substr(-2) === stripped.substr(-2);
};
// valida o CPF digitado
export const ValidateCPF = (cpf, isStrict = true) => {
  const stripped = strip(cpf, isStrict);

  // CPF must be defined
  if (!stripped) {
    return false;
  }

  // CPF must have 11 chars
  if (stripped.length !== 11) {
    return false;
  }

  // CPF can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 9);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};
