import styled from "styled-components";
import theme2 from "../../../themes/theme2";

export const ContinuousUseFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  font-size: 14px;
  overflow: auto;

  @media (max-height: 700px) {
    min-height: 300px;
  }

  .auto-complete {
    div {
      margin-top: 0;
    }

    .prefix {
      padding-top: 8px;
    }

    .clear {
      top: 8px;
    }

    .render-item {
      display: flex;
      padding: 8px;
      justify-content: space-between;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #d3d3d3;
        outline: none;
      }

      .prescriber-info > span {
        margin-left: 4px;
      }
    }
  }
`;

export const ContinuousUseSection = styled.section`
  width: 100%;
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};

  .error {
    position: absolute;
    bottom: -18px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  margin-right: 8px;

  strong {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    margin-right: 4px;
  }

  div {
    flex: 1;
    margin-bottom: 6px;
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const GroupContinuousUseItems = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  margin: ${({ margin }) => margin};

  .modal-required-fields {
    flex: 1;
    align-self: flex-end;
    font-size: 14px;
  }
`;

export const ContinuousUseItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: ${({ flex }) => flex};
  width: ${({ width }) => width};
  margin: 4px 1% 4px 0;
  position: relative;

  .continuous-use-items-content {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .continuous-use-items-required-fields {
    text-align: right;
    opacity: 0.8;
    font-size: 14px;
  }

  ${({ required }) =>
    required &&
    `
    label::after {
      content: "*";
      margin-left: 4px;
    }
  `}

  .continuous-use-items-input,
  #prescriberAutoComplete {
    height: 40px;
    padding: 0 8px;
    border: 1px solid #c2bebe;
    background-color: ${({ readOnly }) => (readOnly ? "#f3f3f3" : "#fff")};
    border-radius: 4px;

    &::placeholder {
      font-size: 13px;
      font-weight: 400;
      color: #a9a9a9;
    }

    &:focus {
      outline: none;
      border-color: ${theme2.palette.brandLinx};
    }

    ${({ hasError }) =>
      hasError &&
      `
      border-color: red;
    `}
  }
`;
