import { getNumberValue } from "./util";

export const handleTypedDateChange = (
  value: string,
  field: string,
  setFieldValue: Function
) => {
  if (value) {
    let date = getNumberValue(value).replace(
      /(\d{2})(\d{2})(\d{4})/,
      "$2/$1/$3"
    );

    if (date.length === 10) {
      setFieldValue(field, new Date(date));
    }
  }
};
