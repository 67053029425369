import styled from 'styled-components';

import theme from '../../../themes/theme2';

const HeaderWrapper = styled.div`
  display: grid;
  grid-area: header;
  grid-template-columns: 170px auto 200px;
  grid-template-rows: 50px;
  grid-gap: ${theme.metrics.basePadding * 2}px;

  font-size: 0.8rem;
  line-height: 50px;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  background-color: #fff;
  .center {
    display flex;
    flex-wrap: nowrap;
  }
  .center > * {
    justify-content: center;
    padding: 0px 10px 
  }
`;
export default HeaderWrapper;
