import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 16px;
  }

  span {
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
  }

  .disabled {
    color: gray;
  }
`;
