import styled from 'styled-components';

import theme from '../../../themes/theme2';
import { Card as DefaultCard } from '../card';

export const Card = styled(DefaultCard)`
  border: 0;
  box-shadow: none;
  margin: 0;
  min-height: 220px;

  header {
    justify-content: space-between;
  }

  .card-body {
    padding: ${theme.metrics.basePadding * 3}px 0 0;
  }

  input {
    width: 100%;
  }
`;

export const FormContent = styled.div`
  text-align: right;
  padding: 0 4px;

  button {
    margin-top: ${theme.metrics.basePadding * 2}px;
  }
`;
