import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  closeAllCards,
  CASH_WITH_DRAW,
  shouldShow,
  showCard,
} from '../../redux/actions/routerAction';
import * as services from '../../services/cashDeskServices';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import NewCashWithDraw from '../../shared/components/new-cash-with-draw/new-cash-with-draw';
import { Authorization } from '../../shared/components/authorization/authorization';
import { showToast } from '../../shared/utils/util';

export class CashWithdrawContainer extends Component {
  constructor(props) {
    super(props);
    this.props.MessageBus.Subscribe(
      'pos.withdraw.start',
      this.props.cashWithdrawBoxShow
    );
    this.props.MessageBus.Subscribe('pos.withdraw.success', () => {
      this.callMsgSuccess();
      this.close();
    });
    this.props.MessageBus.Subscribe(
      'pos.withdraw.error',
      this.callErrorMessage
    );
    this.props.MessageBus.Subscribe('pos.withdraw.cancel', this.close);

    this.handleClickAuthorization = this.handleClickAuthorization.bind(this);
    this.callErrorMessage = this.callErrorMessage.bind(this);
    this.close = this.close.bind(this);
    this.callMsgSuccess = this.callMsgSuccess.bind(this);

    this.handleClickExcess = this.handleClickExcess.bind(this);
    this.handleClickPaymentExpenses = this.handleClickPaymentExpenses.bind(
      this
    );

    this.handleClickCancel = this.handleClickCancel.bind(this);

    this.state = {
      hasAuthorization: false,
      typeCashWithdraw: '',
    };
  }

  callErrorMessage(response, payload) {
    showToast({
      type: 2,
      title: `SANGRIA DE ${this.state.typeCashWithdraw.toUpperCase()}`,
      html: payload.errors[0].errorMessage
        ? payload.errors[0].errorMessage
        : payload.errors[0],
      hideAfterMilliseconds: 5000,
    });
  }

  callMsgSuccess() {
    showToast({
      type: 0,
      title: `SANGRIA DE ${this.state.typeCashWithdraw.toUpperCase()}`,
      html: 'Sangria realizada com sucesso!',
      hideAfterMilliseconds: 5000,
    });
  }

  handleClickExcess({ Amount, Description }) {
    this.setState({
      ...this.state,
      typeCashWithdraw: 'excesso',
    });

    services.cashWithdrawExcess({ Amount, Description });
  }

  handleClickPaymentExpenses({ Amount, Description, ExpenseType }) {
    this.setState({
      ...this.state,
      typeCashWithdraw: 'despesas',
    });

    services.cashWithdrawPaymentExpenses({ Amount, Description, ExpenseType });
  }

  handleClickAuthorization() {
    this.setState({
      ...this.state,
      hasAuthorization: true,
    });
  }

  handleClickCancel() {
    services.cashWithdrawCancel();
  }

  close = () => {
    this.setState({
      ...this.state,
      hasAuthorization: false,
    });

    this.props.closeCashWithdraw();
  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      hasAuthorization: false,
    });
  }

  render() {
    const hasAuthorization = this.state.hasAuthorization;
    if (!hasAuthorization && this.props.showCashWithDraw)
      return (
        <Authorization
          title={'Autenticar operador'}
          labelTextUser={'Operador Responsável'}
          btnAuthorizationTitle={'Avançar'}
          handleClickConfirm={this.handleClickAuthorization}
          handleClickCancel={this.handleClickCancel}
          authorizeOperator={true}
          service={services}
        />
      );

    return (
      this.props.showCashWithDraw && (
        <NewCashWithDraw
          user={this.state.user}
          handleClickConfirmExcess={this.handleClickExcess}
          handleClickConfirmPaymentExpenses={this.handleClickPaymentExpenses}
          handleClickCancel={this.handleClickCancel}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  showCashWithDraw: shouldShow(state, CASH_WITH_DRAW),
});

const mapDispatchToProps = (dispatch) => ({
  closeCashWithdraw: () => dispatch(closeAllCards()),
  cashWithdrawBoxShow: () => dispatch(showCard(CASH_WITH_DRAW)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CashWithdrawContainer)));
