import React from 'react';
import { connect } from 'react-redux';
import { LinxConectaInitComponent } from '../../shared/components/modules';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import LinxConectaService from '../../services/linxConectaService';

export class LinxConectaInitContainer extends React.Component {
  constructor(props) {
    super(props);

    this.props.MessageBus.Subscribe('pos.linxConecta.startConecta', this.handleStart);
    this.props.MessageBus.Subscribe('pos.linxConecta.empresasList', this.handleEmpresasList);
    this.props.MessageBus.Subscribe('pos.linxConecta.error', this.handleError);
    this.props.MessageBus.Subscribe('pos.linxConecta.cartoesList', this.handleCardList);
    this.props.MessageBus.Subscribe('pos.linxConecta.cadastrarCartao', this.handleSignupLink);
    this.props.MessageBus.Subscribe('pos.linxConecta.lgpdPendente', this.handleLgpdPendente);
    this.props.MessageBus.Subscribe('pos.linxConecta.lgpdNaoConsentido', this.handleLgpdNaoConsentido);
    this.props.MessageBus.Subscribe('pos.linxConecta.lgpdNegado', this.handleLgpdNegado);
    this.props.MessageBus.Subscribe('pos.linxConecta.close', this.handleCloseSubs);
    this.props.MessageBus.Subscribe('pos.linxConecta.solicitarConsentimentoReturn', this.handleConsentimentoReturn);
    this.props.MessageBus.Subscribe('pos.linxConecta.consultarPecPbmsConveniado', this.handlePecPbm);
    this.props.MessageBus.Subscribe('pos.linxConecta.obterFidelizaMais', this.handleFidelizaMais);
    this.props.MessageBus.Subscribe('pos.linxConecta.editarCartao', this.handleEditarCartaoReturn);
    this.props.MessageBus.Subscribe('pos.linxConecta.startFidelizaMais', this.startFidelizaMais);

    this.state = {
      visible: false,
      cardList: [],
      empresas: [],
      showNaoConsentido: false,
      showNegado: false,
      showPendente: false,
      showProtocolo: false,
      messageLgpd: '',
      selected: null,
      urlProtocolo: '',
      protocoloLGPD: '',
      showPecPbmModal: false,
      payloadPecPbm: null,
      showFidelizaMaisModal: false,
      payloadFidelizaMais: null,
    };

    this.form = {
      empresaId: 0,
      cardQuery: ''
    }
  }

  handlePecPbm = (ch, payload) => {
    this.setState({
      showPecPbmModal: true,
      payloadPecPbm: payload
    })
  }

  handleEditarCartaoReturn = (ch, payload) => {
    if (window.desktopApp)
      window.desktopApp.publish('machine.browser.open', payload.editarCartaoUrl);
    else
      window.open(payload.editarCartaoUrl);
    this.context.showMessage('Foi aberto o link para editar o cartão em seu navegador!');
  }

  handleFidelizaMais = (ch, payload) => {
    if (payload.linxConectaObterFidelizaMaisRet.existeAcaoFideliza === "S") {
      /*
      //Este trecho será utilizado para abrir o link em uma nova aba do navegador
      if (window.desktopApp)
        window.desktopApp.publish('machine.browser.open', payload.linxConectaObterFidelizaMaisRet.urlFideliza);
      else
        window.open(payload.linxConectaObterFidelizaMaisRet.urlFideliza);
      this.handleClose();
      this.context.showMessage('Foi aberto o link Fideliza Mais em seu navegador!');
      */
      //Este trecho será utilizado para abrir o link dentro de um modal
      this.setState({
        showFidelizaMaisModal: true,
        payloadFidelizaMais: payload
      })
    } else {
      this.handleClose();
    }
  }

  handleConsultarPecPbmsConveniado = (card) => {
    LinxConectaService.consultarPecPbmsConveniado(card);
  }

  togglePecPbmModal = (e) => {
    this.setState({
      showPecPbmModal: !this.state.showPecPbmModal
    })
  }

  closeFidelizaMaisModal = (e) => {
    this.setState({
      showFidelizaMaisModal: false
    })

    this.handleClose();
  }

  handleConsentimentoReturn = (ch, payload) => {
    this.setState({
      showNaoConsentido: false,
      showProtocolo: true,
      urlProtocolo: payload.linxConectaSolicitarConsentimentoRet.url + payload.linxConectaSolicitarConsentimentoRet.protocolo,
      protocoloLGPD: payload.linxConectaSolicitarConsentimentoRet.protocolo
    });
  }

  handleLgpdPendente = (ch, payload) => {
    this.setState({
      showPendente: true,
      messageLgpd: payload
    })
  }

  handleLgpdNaoConsentido = (ch, payload) => {
    this.setState({
      showNaoConsentido: true,
      messageLgpd: payload
    })
  }

  handleLgpdNegado = (ch, payload) => {
    this.setState({
      showNegado: true,
      messageLgpd: payload
    })
  }

  handleSignupLink(ch, payload) {
    if (window.desktopApp)
      window.desktopApp.publish('machine.browser.open', payload)
    else
      window.open(payload)
  }

  handlePortalLGPDClick = () => {
    if (window.desktopApp)
      window.desktopApp.publish('machine.browser.open', this.state.urlProtocolo)
    else
      window.open(this.state.urlProtocolo)
    this.setState({
      showProtocolo: false
    });
    if (this.state.selected.acaO_LGPD === 'N')
      this.handleClose();
  }

  handleStart = () => {
    LinxConectaService.listarEmpresas();
    this.setState({
      visible: true
    });
  }

  handleCardList = (ch, payload) => {
    this.setState({
      cardList: payload
    });
  }

  handleEmpresasList = (ch, empresas) => {
    this.setState({
      visible: true,
      empresas
    });
  }

  handleError = (ch, error) => {
    this.context.showMessage(error);
  }

  handleSignUp = () => {
    LinxConectaService.cadastrarCartao(this.form.empresaId);
  }

  handleCloseSubs = () => {
    if (this.state.showNaoConsentido || this.state.showNegado || this.state.showPendente || this.state.showProtocolo)
      return;
    this.handleClose();
  }

  handleClose = () => {
    this.setState({
      visible: false,
      cardList: [],
      empresas: [],
      showNaoConsentido: false,
      showNegado: false,
      showPendente: false,
      showProtocolo: false,
      messageLgpd: '',
      selected: null,
      urlProtocolo: '',
      protocoloLGPD: '',
      showPecPbmModal: false,
      payloadPecPbm: null,
      showFidelizaMaisModal: false,
      payloadFidelizaMais: null,
    });
    this.form = {
      empresaId: 0,
      cardQuery: ''
    };
  }

  handleCardSelected = (card) => {
    this.setState({
      selected: card
    })
    LinxConectaService.abrirTransacao(card);
  }

  startFidelizaMais = (ch, payload) => {
    if (payload.linxConectaCartao.cartaoPec === "S")
      LinxConectaService.obterFidelizaMais(payload.linxConectaCartao);
  }

  handleEditarCartao = (card) => {
    LinxConectaService.editarCartao(card);
  }

  handleSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();
    LinxConectaService.consultarCartoes(this.form.empresaId, this.form.cardQuery);
  }

  handleNegadoModalClick = (e) => {
    this.setState({
      showNegado: false
    })
  }

  handlePendenteModalClick = (e) => {
    this.setState({
      showPendente: false
    })
    this.handleClose();
  }

  handleNaoConsentidoYesClick = (e) => {
    LinxConectaService.solicitarTermosLGPD(this.state.selected);
  }

  handleNaoConsentidoNoClick = (e) => {
    if (this.state.selected.acaO_LGPD === 'O') {
      this.setState({
        showNaoConsentido: false,
        showNegado: true
      })
    }
    else {
      this.setState({
        showNaoConsentido: false,
        showPendente: true
      })
    }
  }

  handleSolicitarTermosClick = (card) => {
    this.setState({
      selected: card
    })
    LinxConectaService.solicitarTermosLGPD(card);
  }

  render() {
    return (this.state.visible &&
      <LinxConectaInitComponent
        form={this.form}
        handleSignUp={this.handleSignUp}
        handleClose={this.handleClose}
        handleCardSelected={this.handleCardSelected}
        cardList={this.state.cardList}
        empresas={this.state.empresas}
        handleSearch={this.handleSearch}
        showNaoConsentido={this.state.showNaoConsentido}
        showNegado={this.state.showNegado}
        showPendente={this.state.showPendente}
        urlProtocolo={this.state.urlProtocolo}
        showProtocolo={this.state.showProtocolo}
        handlePendenteModalClick={this.handlePendenteModalClick}
        handleNegadoModalClick={this.handleNegadoModalClick}
        handleNaoConsentidoYesClick={this.handleNaoConsentidoYesClick}
        handleNaoConsentidoNoClick={this.handleNaoConsentidoNoClick}
        handleSolicitarTermosClick={this.handleSolicitarTermosClick}
        messageLgpd={this.state.messageLgpd}
        protocoloLGPD={this.state.protocoloLGPD}
        handlePortalLGPDClick={this.handlePortalLGPDClick}
        handleConsultarPecPbmsConveniado={this.handleConsultarPecPbmsConveniado}
        showPecPbmModal={this.state.showPecPbmModal}
        payloadPecPbm={this.state.payloadPecPbm}
        togglePecPbmModal={this.togglePecPbmModal}
        handleEditarCartao={this.handleEditarCartao}
        showFidelizaMaisModal={this.state.showFidelizaMaisModal}
        payloadFidelizaMais={this.state.payloadFidelizaMais}
        closeFidelizaMaisModal={this.closeFidelizaMaisModal}
      />
    );
  }

}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(LinxConectaInitContainer)));
