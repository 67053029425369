import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import {
  closeCardWithoutHideOthers,
  ORDER_DETAILS,
  getPayload,
  shouldShow,
} from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import OrderDetail from '../../shared/components/order-detail';
import OrderService from '../../services/orderService';

export class OrderDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.props.MessageBus.Subscribe('pos.open.orderDetails', () => {
      this.setState({ showContainer: true });
    });
    this.props.MessageBus.Subscribe('pos.close.orderDetails', () => {
      this.setState({ showContainer: false });
    });
    this.props.MessageBus.Subscribe(
      'pos.order.updated',
      this.handleOrderUpdated.bind(this)
    );

    this.state = {
      discountPercentage: 0,
      totalValue: 0,
      showContainer: false,
    };

    this.orderService = new OrderService();

    this.handleClose = this.handleClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleDiscountChange = this.handleDiscountChange.bind(this);
    this.updateDiscount = this.updateDiscount.bind(this);
    this.handleTotalValueChange = this.handleTotalValueChange.bind(this);
    this.handleOrderUpdated = this.handleOrderUpdated.bind(this);
    this.getPercentageByValue = this.getPercentageByValue.bind(this);
    this.getValueByPercentage = this.getValueByPercentage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { orderTotalDiscount, orderDiscount } = this.props.order;

    if (orderTotalDiscount !== prevProps.order.orderTotalDiscount) {
      this.setState({
        totalValue: orderTotalDiscount,
        discountPercentage: this.getPercentageByValue(
          orderDiscount,
          this.props.order.orderTotal
        ),
      });
    }
  }

  getPercentageByValue(discountValue, totalValue) {
    return parseFloat(((discountValue / totalValue) * 100).toFixed(2));
  }

  getValueByPercentage(percentage, value) {
    return parseFloat((value * (percentage / 100)).toFixed(2));
  }

  handleOrderUpdated(ch, payload) {
    const discountPercentage = this.getPercentageByValue(
      payload.orderDiscount,
      payload.orderTotal
    );
    this.setState({
      discountPercentage,
      totalValue: payload.orderTotalDiscount,
    });
    this.handleClose();
  }

  handleDiscountChange(value) {
    // removing the suffix %
    var str = value
      .substring(0, value.length - 1)
      .replace('.', '')
      .replace(',', '.');
    var realValue = parseFloat(str);

    let totalValue = this.getValueByPercentage(
      100 - realValue,
      this.props.order.orderTotal
    );
    if (totalValue < 0) totalValue = 0;

    this.setState({
      discountPercentage: realValue,
      totalValue,
    });
  }

  handleTotalValueChange(value) {
    // removing the preffix R$
    var str = value.substring(2).replace('.', '').replace(',', '.');
    var realValue = parseFloat(str);

    var discountPercentage = this.getPercentageByValue(
      this.props.order.orderTotal - realValue,
      this.props.order.orderTotal
    );
    if (discountPercentage < 0) discountPercentage = 0;

    this.setState({
      totalValue: realValue,
      discountPercentage,
    });
  }

  handleClose() {
    this.setState({
      discountPercentage: this.getPercentageByValue(
        this.props.order.orderDiscount,
        this.props.order.orderTotal
      ),
      totalValue: this.props.order.orderTotalDiscount,
    });
    this.props.closeContainer();
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.updateDiscount();
    }
  }

  updateDiscount() {
    var totalDiscount = this.props.order.orderTotal - this.state.totalValue;
    this.orderService.updateDiscount(totalDiscount.toFixed(2));
  }

  render() {
    return (
      <Fragment>
        {this.props.showContainer && (
          <OrderDetail
            handleClose={this.handleClose}
            handleDiscountChange={this.handleDiscountChange}
            discountPercentage={this.state.discountPercentage}
            totalValue={this.state.totalValue}
            handleOnBlur={this.handleOnBlur}
            detailDialogPosition={this.props.payload}
            updateDiscount={this.updateDiscount}
            handleTotalValueChange={this.handleTotalValueChange}
            handleKeyDown={this.handleKeyDown}
          />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  order: state.order.order,
  showContainer: shouldShow(state, ORDER_DETAILS),
  payload: getPayload(state, ORDER_DETAILS),
});

const mapDispatchToProps = (dispatch) => ({
  closeContainer: () =>
    dispatch(closeCardWithoutHideOthers(ORDER_DETAILS, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(OrderDetailContainer)));
