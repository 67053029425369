import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import './submenu.scss';
import Shortcut from '../../shortcut';
import { Container, Items, Item, Header, ItemInfo, Image } from './styles';

class Submenu extends Component {
  constructor(props) {
    super(props);

    this.itemsRef = React.createRef();
  }

  componentDidMount() {
    const { firstChild } = this.itemsRef.current;
    if (firstChild) firstChild.focus();
  }

  handleKeyDown = (e) => {
    const keycode = e.keyCode || e.which;
    if (keycode === 13) {
      e.preventDefault();
      e.target.click();
      return;
    }

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        if (e.target.previousSibling) e.target.previousSibling.focus();
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (e.target.nextSibling) e.target.nextSibling.focus();
        break;
      default:
        break;
    }
  };

  handleItemClick = (item) => {
    if (!item.enabled) return;
    this.props.onClick(item.operationName);
  };

  render() {
    const { name, icon, items, innerRef } = this.props;
    return (
      <Container id={"submenu"} innerRef={innerRef} data-testid="submenu">
        <Header>
          {icon} {name}
        </Header>
        <Items data-testid="submenu-items" innerRef={this.itemsRef}>
          {items.map(
            (item) =>
              !item.isHidden && (
                <Fragment>
                  <Item
                    data-testid={`item-${item.operationName}`}
                    key={item.operationName}
                    tabIndex={-1}
                    onKeyDown={this.handleKeyDown}
                    onClick={() => this.handleItemClick(item)}
                    disabled={!item.enabled}
                  >
                    <ItemInfo>
                      {item.subMenu && item.subMenu.length > 0 && <i className="material-icons">{item.showSubMenu ? 'expand_more' : 'chevron_right'}</i>}
                      <Image>
                        <img src={item.icon} alt={item.operationName} />
                      </Image>
                      <span>{item.operationName}</span>
                    </ItemInfo>
                    {item.shortKey && (
                      <Shortcut
                        name={item.shortKey.name}
                        keys={item.shortKey.keys}
                        keyCode={item.shortKey.keyCode}
                        onKeyCodeMatch={() => this.handleItemClick(item)}
                        disabled={!item.enabled}
                      />
                    )}
                  </Item>
                  {item.showSubMenu && (
                    item.subMenu.map(subMenu =>
                      <Item
                        className='subMenu'
                        data-testid={`item-${subMenu}`}
                        key={subMenu}
                        tabIndex={-1}
                        onKeyDown={this.handleKeyDown}
                        onClick={(e) => subMenu.onClick(e)}
                        disabled={!item.enabled}
                      >
                        <ItemInfo>
                          <span>{subMenu.description}</span>
                        </ItemInfo>
                      </Item>
                    )
                  )}
                </Fragment>
              )

          )}
        </Items>
      </Container>
    );
  }
}

Submenu.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Submenu) }),
  ]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      operationName: PropTypes.string,
      icon: PropTypes.string,
      isHidden: PropTypes.bool,
      enabled: PropTypes.bool,
      shortKey: PropTypes.shape({
        keys: PropTypes.string,
        keyCode: PropTypes.number,
      }),
    })
  ).isRequired,
};

export default React.forwardRef((props, ref) => (
  <Submenu innerRef={ref} {...props} />
));
