/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { ConfigPBM } from '../../shared/components/config-pbm'
import { CONFIG_PBM, shouldShow, closeCard } from '../../redux/actions/routerAction';
import pbmSaleService, { PBM_ORIZON, PBM_FUNCIONAL_CARD, PBM_EPHARMA, PBM_SEVEN } from '../../services/pbmSaleService';
import { showToast } from '../../shared/utils/util';
import Configuration from '../../configuration';
import RetailerService from '../../services/retailerService';
import AdministrativeService from '../../services/AdministrativeService';
import MessageBusService from '../../services/MessageBusService';
import { ValidateCNPJ } from '../../shared/utils/modules';
function ConfigPBMContainer(props) {
  const visible = useSelector(state => shouldShow(state, CONFIG_PBM));
  const handleClose = () => { dispatch(closeCard(CONFIG_PBM)); };
  const dispatch = useDispatch();
  const [formDataFarmaciaPopular, setFormDataFarmaciaPopular] = useState({});
  const [formDataSeven, setFormDataSeven] = useState({});
  const radioDefaultValuesFarmaciaPopular = [{ description: 'Homologação', value: 1 }, { description: 'Produção', value: 0 }];
  const [environmentValuesFarmaciaPopular, setEnvironmentValuesFarmaciaPopular] = useState(radioDefaultValuesFarmaciaPopular);
  const [retailerConfigs, setRetailerConfigs] = useState([]);
  const radioDefaultValuesOrizon = [{ description: 'Homologação', value: 0 }, { description: 'Produção', value: 1 }];
  const [formDataOrizon, setFormDataOrizon] = useState({});
  const [environmentValuesOrizon, setEnvironmentValuesOrizon] = useState(radioDefaultValuesOrizon);
  const radioDefaultValuesFuncional = [{ description: 'Homologação', value: 0 }, { description: 'Produção', value: 1 }];
  const [formDataFuncional, setFormDataFuncional] = useState({});
  const [environmentValuesFuncional, setEnvironmentValuesFuncional] = useState(radioDefaultValuesFuncional);
  const radioDefaultValuesEpharma = [{ description: 'Homologação', value: 0 }, { description: 'Produção', value: 1 }];
  const [formDataEpharma, setFormDataEpharma] = useState({});
  const [formDataTrnCentre, setFormDataTrnCentre] = useState({});
  const [environmentValuesEpharma, setEnvironmentValuesEpharma] = useState(radioDefaultValuesEpharma);
  const [terminalFarmaciaPopular, setTerminalFarmaciaPopular] = useState({});
  const funcionalCardPrdURL = "https://stores.funcionalmais.com/graphql";
  const funcionalCardHmlURL = "https://stores-hml.funcionalmais.com/graphql";

  function handleChangeOrizon(e) {
    let form = formDataOrizon;
    form[e.target.id] = e.target.value || null;
    setFormDataOrizon(form);
  }

  function handleChangeSeven(e) {
    let form = formDataSeven;
    form[e.target.id] = e.target.value;
    setFormDataSeven(form);
  }

  function handleChangeTrnCentre(e) {
    let form = formDataTrnCentre;
    form[e.target.id] = e.target.value || null;
    setFormDataTrnCentre(form);
  }

  function handleChangeEpharma(e) {
    let form = formDataEpharma;
    form[e.target.id] = e.target.value || null;
    setFormDataEpharma(form);
  }

  function handleEnvironmentChangeFarmaciaPopular(values) {
    let form = formDataFarmaciaPopular;
    form.config.farmaciaPopular.enviroment = values.find(f => f.selected).value;
    setFormDataFarmaciaPopular(form);
    setEnvironmentValuesFarmaciaPopular(values);
  }

  function handleEnvironmentChangeOrizon(values) {
    let form = formDataOrizon;
    form.environmentValue = values.find(f => f.selected).value;
    setFormDataOrizon(form);
    setEnvironmentValuesOrizon(values);
  }

  function toggleLoading(show) {
    dispatch({
      type: show ? 'LOADING_SCREEN_SHOW' : 'LOADING_SCREEN_HIDE',
      text: 'Carregando...'
    });
  }

  function checkFormDataOrizon() {
    pbmSaleService.validateConfig(convertToUpdateOrizon(formDataOrizon))
      .then(response => {
        if (response.isValid) {
          handleConfirmOrizon();
        }
        else {
          handleErrorOrizon()
        }
      })
      .catch(error => handleErrorOrizon(error));
  }

  function convertToUpdateOrizon(o) {
    var converted = o;
    converted.environment = { type: o.environmentValue, value: o.url };
    converted.pbm = 77000;
    converted.tipoConvenioEpharma = 2;
    converted.nomeServico = 'PBM Orizon';
    return converted;
  }

  function convertToUpdateEpharma(o) {
    var converted = o;
    converted.environment = { type: o.environment, value: o.url };
    converted.pbm = 73000;
    converted.tipoConvenioEpharma = 2;
    converted.nomeServico = 'PBM Epharma';
    return converted;
  }

  function convertToUpdateSeven(o) {
    var converted = o;
    converted.pbm = 75000;
    return converted;
  }

  function updateConfigSeven() {
    pbmSaleService.updateConfig(convertToUpdateSeven(formDataSeven))
      .then(res => {
        showToast({
          type: 0,
          title: 'Configurações Portal da Drogaria',
          html: 'Configuração salva com sucesso!',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      })
      .catch(err => {
        showToast({
          type: 2,
          title: 'Configurações PBM Seven',
          html: 'Houve um erro ao processar solicitação.',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      });
    handleClose();
  }

  function updateConfigOrizon() {
    pbmSaleService.updateConfig(convertToUpdateOrizon(formDataOrizon))
      .then(res => {
        showToast({
          type: 0,
          title: 'Configurações PBM Orizon',
          html: 'Configuração salva com sucesso!',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      })
      .catch(err => {
        showToast({
          type: 2,
          title: 'Configurações PBM Orizon',
          html: 'Houve um erro ao processar solicitação.',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      });
    handleClose();
  }

  function updateConfigEpharma() {
    pbmSaleService.updateConfig(convertToUpdateEpharma(formDataEpharma))
      .then(res => {
        showToast({
          type: 0,
          title: 'Configurações PBM Epharma',
          html: 'Configuração salva com sucesso!',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      })
      .catch(err => {
        showToast({
          type: 2,
          title: 'Configurações PBM Epharma',
          html: 'Houve um erro ao processar solicitação.',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      });
    handleClose();
  }

  function handleConfirmOrizon() {
    updateConfigOrizon();
  }

  function handleConfirmTrnCentre() {
    if (!formDataTrnCentre.cnpj || formDataTrnCentre.cnpj.length !== 14 || !ValidateCNPJ(formDataTrnCentre.cnpj)) {
      showToast({
        type: 2,
        title: 'Validação CNPJ',
        html: 'CNPJ é inválido!',
        hideAfterMilliseconds: 5000
      });
      return;
    }
    pbmSaleService.setConfigTrnCentre(formDataTrnCentre);
  }

  function handleLoadTableTrnCentre() {
    pbmSaleService.loadTableTrnCentre();
  }

  function handleConfirmSeven() {
    if (!formDataSeven.cnpjLojaTerminal || formDataSeven.cnpjLojaTerminal.length !== 14 || !ValidateCNPJ(formDataSeven.cnpjLojaTerminal)) {
      showToast({
        type: 2,
        title: 'Validação CNPJ',
        html: 'CNPJ é inválido!',
        hideAfterMilliseconds: 5000
      });
      return;
    }
    updateConfigSeven();
  }

  function handleConfirmFarmaciaPopular() {
    new AdministrativeService().updateRetailer(formDataFarmaciaPopular)
      .then(res => {
        showToast({
          type: 0,
          title: 'Configurações Farmácia popular',
          html: 'Configuração salva com sucesso!',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      })
      .catch(err => {
        showToast({
          type: 2,
          title: 'Configurações Farmacia popular',
          html: 'Houve um erro ao processar solicitação.',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      });
    handleClose();
  }

  function handleConfirmEpharma() {
    updateConfigEpharma();
  }

  function handleErrorFarmaciaPopular(err) {
    toggleLoading();
    showToast({
      type: 2,
      title: 'Configurações Farmacia Popular',
      html: 'Atençao!<br>Os dados informados não correspondem a uma configuração válida para acesso ao farmacia popular.<br>',
      hideAfterMilliseconds: 8000
    });
  }

  function handleErrorEpharma(err) {
    toggleLoading();
    showToast({
      type: 2,
      title: 'Configurações Epharma',
      html: 'Atençao!<br>Os dados informados não correspondem a uma configuração válida para acesso ao Epharma.<br>',
      hideAfterMilliseconds: 8000
    });
  }

  function handleErrorOrizon(err) {
    toggleLoading();
    showToast({
      type: 2,
      title: 'Configurações PBM Orizon',
      html: 'Atençao!<br>Os dados informados não correspondem a uma configuração válida para acesso ao Pbm Orizon.<br>',
      hideAfterMilliseconds: 8000
    });
  }

  function handleErrorSeven(err) {
    toggleLoading();
    showToast({
      type: 2,
      title: 'Configurações PBM Seven',
      html: 'Atençao!<br>Os dados informados não correspondem a uma configuração válida para acesso ao Portal da Drogaria.<br>',
      hideAfterMilliseconds: 8000
    });
  }

  useEffect(() => {
    const getIdentification = () => {
      if (window.desktopApp) {
        window.desktopApp.subscribe("machine.devices.fp.identificacao", handleIdentificacao);
        window.desktopApp.subscribe("machine.devices.fp.identificacao.error", handleIdentificacaoError);
        window.desktopApp.publish("machine.devices.fp.identificacao", "");
      }
      MessageBusService.GetInstance().Subscribe('pos.trncentre.getConfigSuccess', handleGetConfigTrnCentre);
      MessageBusService.GetInstance().Subscribe('pos.trncentre.setConfigSuccess', handleSetConfigTrnCentre);
      MessageBusService.GetInstance().Subscribe('pos.trncentre.loadTableSuccess', handleLoadTableTrnCentreReturn);
    }

    const serviceCall = () => {
      pbmSaleService.getConfig(Configuration.retailerId, PBM_ORIZON)
        .then(value => {
          if (value) {
            value.url = value.environment.value;
            value.environment = value.environment.type;
          }
          else {
            value = {};
          }
          let tmp = radioDefaultValuesOrizon.map(m => { m.selected = m.value === value.environment; return m });
          setEnvironmentValuesOrizon(tmp);
          setFormDataOrizon(value);
          if (value.environment === 0) {
            showToast({
              type: 3,
              title: 'Configurações PBM Orizon',
              html: 'Ambiente setado para HOMOLOGAÇÃO.',
              hideAfterMilliseconds: 5000
            });
          }
        })
        .catch(error => handleErrorOrizon(error));

      RetailerService.get(Configuration.retailerId)
        .then(value => {
          let tmp = radioDefaultValuesFarmaciaPopular.map(m => { m.selected = m.value === value.config.farmaciaPopular.enviroment; return m });
          setEnvironmentValuesFarmaciaPopular(tmp);
          setFormDataFarmaciaPopular(value);
          if (value.config.farmaciaPopular.enviroment === 1) {
            showToast({
              type: 3,
              title: 'Configurações Farmacia Popular',
              html: 'Ambiente setado para HOMOLOGAÇÃO.',
              hideAfterMilliseconds: 5000
            });
          }
        })
        .catch(error => handleErrorFarmaciaPopular(error));
      pbmSaleService.getConfig(Configuration.retailerId, PBM_FUNCIONAL_CARD)
        .then(value => {
          if (value) {
            value.url = value.environment.value;
            value.environment = value.environment.type;
          }
          else {
            value = {};
          }
          let tmp = radioDefaultValuesFuncional.map(m => { m.selected = m.value === value.environment; return m });
          setEnvironmentValuesFuncional(tmp);
          setFormDataFuncional(value);
          if (value.environment === 0) {
            showToast({
              type: 3,
              title: 'Configurações PBM Funcional',
              html: 'Ambiente setado para HOMOLOGAÇÃO.',
              hideAfterMilliseconds: 5000
            });
          }
        })
        .catch(error => handleErrorFuncional(error));
      pbmSaleService.getConfig(Configuration.retailerId, PBM_EPHARMA)
        .then(value => {
          if (value) {
            value.url = value.environment.value;
            value.environment = value.environment.type;
          }
          else {
            value = {};
          }
          let tmp = radioDefaultValuesEpharma.map(m => { m.selected = m.value === value.environment; return m });
          setEnvironmentValuesEpharma(tmp);
          setFormDataEpharma(value);
          if (value.environment === 0) {
            showToast({
              type: 3,
              title: 'Configurações PBM Epharma',
              html: 'Ambiente setado para HOMOLOGAÇÃO.',
              hideAfterMilliseconds: 5000
            });
          }
        })
        .catch(error => handleErrorEpharma(error));
      pbmSaleService.getConfig(Configuration.retailerId, PBM_SEVEN)
        .then(value => {
          if (!value)
            value = {};
          if (!value.cnpjLojaTerminal) {
            RetailerService.get(Configuration.retailerId)
              .then(retailer => {
                console.log(retailer)
                value.cnpjLojaTerminal = retailer.documentNumber
                setFormDataSeven(value);
              })
          }
          else
            setFormDataSeven(value);
        })
        .catch(error => handleErrorSeven(error));
      pbmSaleService.getConfigTrnCentre();
      RetailerService.get(Configuration.retailerId)
        .then(value => {
          setRetailerConfigs(value.configurations);
        })
    };

    if (visible) {
      serviceCall();
      getIdentification();
    } else {
      setFormDataOrizon({});
      setFormDataFuncional({});
      setFormDataEpharma({});
      setFormDataSeven({});
      setFormDataTrnCentre({});
      setFormDataFarmaciaPopular({});
    }
  }, [visible]);

  function handleEnvironmentChangeFuncional(values) {
    let form = formDataFuncional;
    form.environment = values.find(f => f.selected).value;
    form.url = form.environment === 0 ? funcionalCardHmlURL : funcionalCardPrdURL;
    setFormDataFuncional(form);
    setEnvironmentValuesFuncional(values);
  }

  function handleEnvironmentChangeEpharma(values) {
    let form = formDataEpharma;
    form.environment = values.find(f => f.selected).value;
    setFormDataEpharma(form);
    setEnvironmentValuesEpharma(values);
  }

  function handleChangeFuncional(e) {
    let form = formDataFuncional;
    form[e.target.id] = e.target.value || null;
    setFormDataFuncional(form);
  }

  function handleChangeFarmaciaPopular(e) {
    let form = formDataFarmaciaPopular;
    switch (e.target.id) {
      case 'config.farmaciaPopular.password':
        form.config.farmaciaPopular.password = e.target.value || null;
        break;
      case 'config.farmaciaPopular.login':
        form.config.farmaciaPopular.login = e.target.value || null;
        break;
      default:
        break;
    }

    setFormDataFarmaciaPopular(form);
  }

  function callMutationFuncional() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var graphql = JSON.stringify({
      query: "mutation CreateToken( $user: String!, $pass: String!){ createToken(login: $user, password: $pass){token}}",
      variables: { user: formDataFuncional.user, pass: formDataFuncional.password }
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow'
    };
    toggleLoading(true);
    fetch(formDataFuncional.url, requestOptions)
      .then(response => response.json())
      .then(response => {
        if (response.errors)
          handleGraphqlErrorFuncional();

        else
          handleConfirmFuncional();
      })
      .catch(error => handleGraphqlErrorFuncional(error));
  }

  function handleErrorFuncional(error) {
    toggleLoading();
    showToast({
      type: 2,
      title: 'Configurações PBM Funcional',
      html: 'Houve um erro ao buscar configuração.',
      hideAfterMilliseconds: 5000
    });
  }
  function handleGraphqlErrorFuncional() {
    toggleLoading();
    showToast({
      type: 2,
      title: 'Configurações PBM Funcional',
      html: 'Atenção!<br>Os dados informados não correspondem a uma configuração válida para acesso ao Funcional.<br>',
      hideAfterMilliseconds: 8000
    });
  }

  function updateConfigFuncional() {
    pbmSaleService.updateConfig(convertToUpdateFuncional(formDataFuncional))
      .then(res => {
        showToast({
          type: 0,
          title: 'Configurações PBM Funcional',
          html: 'Configuração salva com sucesso!',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      })
      .catch(err => {
        showToast({
          type: 2,
          title: 'Configurações PBM Funcional',
          html: 'Houve um erro ao processar solicitação.',
          hideAfterMilliseconds: 5000
        });
        toggleLoading();
      });
    handleClose();
  }

  function convertToUpdateFuncional(o) {
    var converted = o;
    converted.environment = { type: o.environment, value: o.url };
    converted.pbm = 76000;
    converted.tipoConvenioEpharma = 2;
    converted.nomeServico = 'PBM Funcional Card';
    return converted;
  }

  function handleConfirmFuncional() {
    updateConfigFuncional();
  }

  const farmaciaPopularPrdURL = "https://farmaciapopular-portal.saude.gov.br/farmaciapopular-portal/login.jsf";
  const farmaciaPopularHmlURL = "https://farmaciapopular-portal-homologacao.saude.gov.br/farmaciapopular-portal/login.jsf";

  function handleClickCadastrarComputador(enviroment) {
    if (enviroment === 0) {
      window.desktopApp ? MessageBusService.GetInstance().Publish('machine.browser.open', farmaciaPopularHmlURL) : window.open(farmaciaPopularHmlURL, '_blank');
    } else if (enviroment === 1) {
      window.desktopApp ? MessageBusService.GetInstance().Publish('machine.browser.open', farmaciaPopularPrdURL) : window.open(farmaciaPopularPrdURL, '_blank');
    } else {
      showToast({
        type: 1,
        title: 'Ambiente indefinido',
        html: "Selecione um ambiente entre 'Homologação' ou 'Produção' para continuar.",
        hideAfterMilliseconds: 5000
      });
    }
  }

  function handleIdentificacao(ch, data) {
    const { estacao, identificacao } = data;

    setTerminalFarmaciaPopular({
      identificacao,
      estacao
    })
  }

  function handleGetConfigTrnCentre(ch, data) {
    if (data) {
      if (!data.cnpj) {
        RetailerService.get(Configuration.retailerId)
          .then(retailer => {
            data.cnpj = retailer.documentNumber
            setFormDataTrnCentre(data);
          })
      }
      else
        setFormDataTrnCentre(data);
    }

  }

  function handleSetConfigTrnCentre(ch, data) {
    setFormDataTrnCentre(data);
    showToast({
      type: 0,
      title: 'Configurações Portal da Drogaria',
      html: 'Configurações salvas com sucesso.',
      hideAfterMilliseconds: 5000
    });
    handleClose();
  }

  function handleLoadTableTrnCentreReturn(ch, data) {
    setFormDataTrnCentre(data);
  }

  function handleIdentificacaoError(ch, data) {
    showToast({
      type: 2,
      title: 'Informações do Terminal - FP',
      html: "Certifique-se de que existe o arquivo '<b>gbasmsb.exe</b>' na pasta <i>'\\bin\\fp\\&lt;seu-sistema-operacional&gt;'</i>",
      hideAfterMilliseconds: 7000
    });
  }

  return (
    visible && <ConfigPBM
      handleClose={handleClose}
      handleChangeOrizon={handleChangeOrizon}
      handleEnvironmentChangeOrizon={handleEnvironmentChangeOrizon}
      toggleLoading={toggleLoading}
      environmentValuesOrizon={environmentValuesOrizon}
      formDataOrizon={formDataOrizon}
      formDataFarmaciaPopular={formDataFarmaciaPopular}
      checkFormDataOrizon={checkFormDataOrizon}
      environmentValuesFuncional={environmentValuesFuncional}
      handleEnvironmentChangeFuncional={handleEnvironmentChangeFuncional}
      formFuncional={formDataFuncional}
      handleChangeFuncional={handleChangeFuncional}
      callMutationFuncional={callMutationFuncional}
      environmentValuesEpharma={environmentValuesEpharma}
      handleEnvironmentChangeEpharma={handleEnvironmentChangeEpharma}
      formEpharma={formDataEpharma}
      handleChangeEpharma={handleChangeEpharma}
      handleChangeSeven={handleChangeSeven}
      handleConfirmSeven={handleConfirmSeven}
      formSeven={formDataSeven}
      handleConfirmEpharma={handleConfirmEpharma}
      handleChangeFarmaciaPopular={handleChangeFarmaciaPopular}
      environmentValuesFarmaciaPopular={environmentValuesFarmaciaPopular}
      handleEnvironmentChangeFarmaciaPopular={handleEnvironmentChangeFarmaciaPopular}
      handleConfirmFarmaciaPopular={handleConfirmFarmaciaPopular}
      handleClickCadastrarComputador={handleClickCadastrarComputador}
      terminalFarmaciaPopular={terminalFarmaciaPopular}
      formTrnCentre={formDataTrnCentre}
      handleChangeTrnCentre={handleChangeTrnCentre}
      handleConfirmTrnCentre={handleConfirmTrnCentre}
      handleLoadTableTrnCentre={handleLoadTableTrnCentre}
      retailerConfigs={retailerConfigs}
    >
    </ConfigPBM>
  );

}

export default ConfigPBMContainer;
