import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import PaymentCreditSale from '../../shared/components/payment-creditsale-parcel';
import PaymentService from '../../services/paymentService';
import { showCard, getPayload, shouldShow, PAYMENT_CREDIT_PARCEL } from '../../redux/actions/routerAction';

class PaymentCreditSaleParcelContainer extends Component {
  constructor(props) {
    super(props);
    this.props.MessageBus.Subscribe('payment.creditsale.installmentoptions', this.props.open);
    this.props.MessageBus.Subscribe('pos.order.deleted', () => this.setState({ creditSaleParcel: false }));
  }

  eventReturn(canal, payload) {
    this.props.creditSaleBrandsParcel(payload);
  }

  service = new PaymentService()

  onHandleCreditSale = installment => {
    this.service.setParcelCreditPayment(installment)
      .catch(error => this.context.showError(error));
  }

  eventReturnParcel(canal, payload) {
    this.service.setParcelCreditPayment(payload)
      .catch(error => this.context.showError(error));
  }

  handleClose = () => {
    this.service.paymentError()
      .catch(err => this.context.showError(err));
  }

  render() {
    return (
      this.props.show && (
        <PaymentCreditSale
          items={this.props.payload}
          onHandleCreditSale={this.onHandleCreditSale}
          handleClose={this.handleClose}
          {...this.props}
        />
      )
    );
  }
}


const mapStateToProps = state => ({
  payload: getPayload(state, PAYMENT_CREDIT_PARCEL),
  show: shouldShow(state, PAYMENT_CREDIT_PARCEL),
});

const mapDispatchToProps = dispatch => ({
  open: (ch, payload) => dispatch(showCard(PAYMENT_CREDIT_PARCEL, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PaymentCreditSaleParcelContainer)));
