import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ShortcutHint } from '../shortcut-hint/shortcut-hint';
import { Container } from './styles';
import Submenu from './submenu';

class MenuItem extends Component {
  constructor(props) {
    super(props);

    this.submenuRef = React.createRef();
    this.menuItemRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEsc);
  }

  handleClickOutside = (event) => {
    if (
      this.submenuRef.current &&
      !this.submenuRef.current.contains(event.target) &&
      this.menuItemRef.current &&
      !this.menuItemRef.current.contains(event.target)
    ) {
      this.props.onClose();
    }
  };

  handleEsc = (event) => {
    const { active, onClose } = this.props;
    if (active && event.keyCode === 27) onClose();
  };

  render() {
    const {
      active,
      name,
      onClick,
      onSubmenuItemClick,
      icon,
      items,
      shortKey,
    } = this.props;

    const Icon = React.cloneElement(icon, {
      fill: "#ffffff",
      stroke: name === "Pbm's disponíveis" && "#ffffff",
      width: 24,
      height: 24,
    });

    const ActiveIcon = React.cloneElement(icon, {
      fill: "#5b2e90",
      stroke: name === "Pbm's disponíveis" && "#5b2e90",
      width: 24,
      height: 24,
    });

    const BigIcon = React.cloneElement(icon, {
      fill: "#5b2e90",
      stroke: name === "Pbm's disponíveis" && "#5b2e90",
      width: 30,
      height: 30,
    });

    return (
      <>
        <Container
          data-testid="menu-item"
          innerRef={this.menuItemRef}
          className={active ? 'active' : ''}
        >
          <button
            data-testid="button-menu-item"
            type="button"
            onClick={() => onClick(name)}
          >
            {active ? ActiveIcon : Icon}
          </button>

          <ShortcutHint
            shortcut={shortKey}
            activateShortcut={() => onClick(name)}
            offsetLeft={0}
            offsetTop={0}
          />
        </Container>
        {active && (
          <Submenu
            innerRef={this.submenuRef}
            items={items}
            icon={BigIcon}
            name={name}
            onClick={onSubmenuItemClick}
          />
        )}
      </>
    );
  }
}

MenuItem.propTypes = {
  active: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmenuItemClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  bigIcon: PropTypes.string.isRequired,
  activeIcon: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      operationName: PropTypes.string,
      icon: PropTypes.string,
      isHidden: PropTypes.bool,
      enabled: PropTypes.bool,
      shortKey: PropTypes.shape({
        keys: PropTypes.string,
        keyCode: PropTypes.number,
      }),
    })
  ).isRequired,
  shortKey: PropTypes.shape({
    keys: PropTypes.string,
    keyCode: PropTypes.number,
  }).isRequired,
};

export default MenuItem;
