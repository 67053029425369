import React, { Component } from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';

import FormControl from '../../@form-control';
import { DatePicker } from '../../date-picker';
import Fieldset from '../../fieldset';
import { handleTypedDateChange } from '../../../utils/handleDate';

class Patient extends Component {
  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
  }

  focus = () => {
    this.nameRef.current.focus();
  };

  clearPatient = () => {
    const { setFieldValue } = this.props;
    setFieldValue('patient.name', '');
    setFieldValue('patient.dateOfBirth', '');
    setFieldValue('patient.genre', '');
  };

  render() {
    const { setFieldValue, data, options, showClearFields } = this.props;
    return (
      <Fieldset
        legend="Informações do paciente"
        hasRequiredField
        onClearFieldsClick={this.clearPatient}
        clearFieldsText={
          showClearFields ? 'Limpar informações do paciente' : ''
        }
      >
        <div className="custom-form-row">
          <FormControl
            label="Nome do Paciente*"
            name="patient.name"
            flex="0.5"
            ref={this.nameRef}
          />

          <FormControl
            label="Data nascimento*"
            flex="0.3"
            name="patient.dateOfBirth"
          >
            <Field
              name="patient.dateOfBirth"
              component={DatePicker}
              onChange={(value) =>
                setFieldValue('patient.dateOfBirth', value || '')
              }
              onChangeRaw={(e) =>
                handleTypedDateChange(e.target.value, 'patient.dateOfBirth', setFieldValue)
              }
              selected={data.dateOfBirth}
              id="date-sngpc-nascimento"
              maxDate={new Date()}
            />
          </FormControl>

          <FormControl
            label="Sexo*"
            name="patient.genre"
            flex="0.3"
            options={[{ name: '', value: '' }, ...options.genres]}
            type="select"
          />
        </div>
      </Fieldset>
    );
  }
}

Patient.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.shape({
    genres: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  data: PropTypes.shape({
    dateOfBirth: PropTypes.instanceOf(Date),
  }).isRequired,
  showClearFields: PropTypes.bool.isRequired,
};

export default Patient;
