import styled from 'styled-components';

export const ClientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  div 
  {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    
    :first-child{
      flex-direction: column;
      flex: 1 1 60%;
    }
    :nth-child(2){
      flex: 1 1 40%;
      padding: 20px 0 20px 10px;
    }
    &.authorization {
      border-top: 1px solid #ccc;
      flex: 0 0 100%;
      padding: 20px 0;
      align-items: center;
    }
    p {
      display: block;
      margin: 0px;
      padding: 10px 0;
      flex: 1 1 auto;
    }
    span {
      flex: 0 0 50%;
    }
    span.red{
      color: #F50101;
    }
    span:nth-child(3),span:nth-child(4){
      border-bottom: 1px solid #ccc;
    }
    span:nth-child(2),span:nth-child(4), span:nth-child(6){
      text-align: right;
    }
    :nth-child(2) span {
      display: block;
      padding: 5px 0;
    }

    &.authorization span
    {
      color: #FB0000;
      border-bottom: none;
      flex 0 0 100%;
      font-family: Neo Sans Pro;
      text-align: left;
      font-size: 13px;
    }
    .custom-form-control {
      padding: 0px;
      flex: 1 1 40%;
      margin-right: 10px;
    }
    #passContainer
    {
      flex: 1 1 40%;
      padding: 1px 0 1px 10px;
      border-radius: 3px;
    }
    .custom-form-control input, #passContainer input {
      font-family: Neo Sans Pro;
      font-size: 13px;
    }
    .custom-form-control input:focus{
      box-shadow: 0 0 5px 0.05rem #5b2e90;
      outline: none;
    }
    
    button {
      flex: 0 0 80px;
      height: 32px;
      margin-left: 10px;
    }
    input::-webkit-outer-spin-button, 
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const ContainerSolicitacao = styled.div`
  flex-direction: column;
  margin: 0px;

  div {
    margin: 0px;
    padding: 0px;
  }

  button.solicitacao-online {
    border: none;
    height: 40px !important;
    flex: 1;
  }

  .solicitacao-title-line {
    flex: 0.7;
    text-align: center !important;
  }

  .authorization {
    padding: 0px !important;
    border-top: none !important;
  }
`;
