import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "../../@form-control";
import { Button } from "../../modules";
import { AutoComplete } from "../../auto-complete";
import { ContinuousUseItems, GroupContinuousUseItems } from "../style";

export const PrescriberContinuousUse = ({
  values,
  setFieldValue,
  handleCreateNew,
  autocompleteQueryFn,
  renderItem,
  errors,
  touched,
  manage,
}) => {
  const handleSelectPrescriber = (value) => {
    setFieldValue("prescriber.id", value?.id || "");
    setFieldValue("prescriber.referenceId", value?.referenceId || "");
    setFieldValue("prescriber.name", value?.name || "");
    setFieldValue("prescriber.documentNumber", value?.documentNumber || "");
    setFieldValue(
      "prescriber.documentDescription",
      value?.documentTypeDescription || value?.documentDescription || ""
    );
    setFieldValue("prescriber.documentUF", value?.documentUF || "");

    document.querySelector("#prescriberAutoComplete").focus();
  };

  const handleClearPrescriberInput = (e) => {
    if (e.key === "Backspace") {
      setFieldValue("prescriber.id", "");
      setFieldValue("prescriber.referenceId", "");
      setFieldValue("prescriber.name", "");
      setFieldValue("prescriber.documentNumber", "");
      setFieldValue("prescriber.documentDescription", "");
      setFieldValue("prescriber.documentUF", "");
    }
  };

  useEffect(() => {
    if (manage?.hasOwnProperty("prescriber")) {
      let value = {
        ...manage.prescriber,
        name: manage.prescriber.description,
      };
      handleSelectPrescriber(value);
    }
  }, [manage]);

  return (
    <GroupContinuousUseItems>
      <ContinuousUseItems
        width="38%"
        required={values.prescriber.required}
        hasError={errors.prescriber?.name && touched.prescriber?.name}
      >
        <FormControl
          className="continuous-use-items-input"
          id="prescriber"
          name="prescriber.name"
          label="Nome do prescritor"
        >
          <AutoComplete
            id="prescriberAutoComplete"
            icon="search"
            autoFocus
            queryOnEnter
            type="text"
            placeholder="Nome ou número do registro"
            maxLength={50}
            tabIndex="0"
            queryFn={autocompleteQueryFn}
            onSelect={(value) => handleSelectPrescriber(value)}
            onKeyPress={(e) => handleClearPrescriberInput(e)}
            value={values.prescriber.name}
            renderItem={renderItem}
          />
        </FormControl>
      </ContinuousUseItems>
      <ContinuousUseItems width="15%" readOnly>
        <FormControl
          className="continuous-use-items-input"
          id="documentNumber"
          name="prescriber.documentNumber"
          label="Número registro"
          readOnly
        />
      </ContinuousUseItems>
      <ContinuousUseItems width="14%" readOnly>
        <FormControl
          className="continuous-use-items-input"
          id="documentDescription"
          name="prescriber.documentDescription"
          label="Conselho"
          readOnly
        />
      </ContinuousUseItems>
      <ContinuousUseItems width="10%" readOnly>
        <FormControl
          className="continuous-use-items-input"
          id="documentUF"
          name="prescriber.documentUF"
          label="UF conselho"
          readOnly
        />
      </ContinuousUseItems>
      <ContinuousUseItems flex={1}>
        <Button
          className="btn-outline"
          onClick={() => handleCreateNew("newPrescriber")}
        >
          CRIAR NOVO PRESCRITOR
        </Button>
      </ContinuousUseItems>
    </GroupContinuousUseItems>
  );
};

PrescriberContinuousUse.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  autocompleteQueryFn: PropTypes.func.isRequired,
  renderItem: PropTypes.elementType.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  manage: PropTypes.object.isRequired,
};
