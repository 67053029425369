import Configuration from '../configuration';
import HttpAccess from './httpAccess';
import handlePost from '../shared/utils/handlePost';
import { AbstractService } from './abstractService';
import { payloadToBase64Zip } from '../shared/utils/util';
import { redoLastRequest } from '../services/authorizationService';

class AuthService extends AbstractService {
  constructor() {
    super('/Authentication');
  }

  login = (credentials) => this.invokeAction('/login', { credentials });

  logout = () => this.invokeAction('/logout');

  authentication = async (
    referenceId,
    password,
    httpAccess = HttpAccess,
    configuration = Configuration
  ) => {
    const url = `${this.getBaseUrl()}/Authentication`;
    const body = {
      password,
      referenceId,
    };

    const response = await handlePost(url, body, httpAccess);
    window.sessionStorage.setItem('Access_Token', response.token);
    return response;
  };

  authenticatePos = async (
    posType,
    httpAccess = HttpAccess,
    configuration = Configuration
  ) => {
    const url = `${this.getBaseUrl()}/Authentication/pos`;
    const body = {
      posId: configuration.PosId,
      retailerId: configuration.retailerId,
      posType,
    };
    return new Promise((resolve) => {
      payloadToBase64Zip(JSON.stringify(body)).then((res) => {
        handlePost(url, { credentials: res }, httpAccess).then((token) =>
          resolve(token)
        );
      });
    });
  };

  authorizationSuperUser = async (
    userCredentials,
    httpAccess = HttpAccess,
    configuration = Configuration
  ) => {
    const credentials = userCredentials;

    const url = `${this.getBaseUrl()}/authorization/superuser`;

    payloadToBase64Zip(JSON.stringify(credentials)).then(async (res) => {
      const body = {
        credentials: res,
      };

      const response = await handlePost(url, body, httpAccess);

      window.sessionStorage.setItem('Access_Token', response.token);
    });
  };

  authorizationSuperUserWithLastRequest = (
    userCredentials,
    lastSentBody,
    lastCalledEndpoint,
    handleClose
  ) => {
    payloadToBase64Zip(JSON.stringify(userCredentials)).then((res) => {
      const payload = { ...lastSentBody, credentials: res };

      redoLastRequest(payload, lastCalledEndpoint).then((res) => {
        if (res.status === 999) return;

        handleClose();
      });
    });
  };

  authorizationSuperUserWithLastRequestAndCredentialsGenerated = async (
    alternativeCredentials,
    lastSentBody,
    lastCalledEndpoint,
    handleClose
  ) => {
    const payload = { ...lastSentBody, credentials: alternativeCredentials };

    return redoLastRequest(payload, lastCalledEndpoint).then((res) => {
      if (res.status === 999) return;

      handleClose();
    });
  };

  licenseUnlock = async (configuration = Configuration, httpAccess = HttpAccess) => {
    const body = {
      retailerId: configuration.retailerId,
      posId: configuration.PosId
    };
    return this.invokeAction('/unlockLicense', body, httpAccess);
  };

}

export default new AuthService();
