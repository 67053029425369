import React, { Component } from 'react';
import { Filters } from './style';
import { Card, Table } from '../modules';

import { Period } from '../period/period';

class CancelOrderList extends Component {
  columnsDefault;

  columnsPrint;

  constructor(props) {
    super(props);
    this.init();

    this.filters = {
      initialDate: new Date(),
      finalDate: new Date()
    };

    this.tableRef = React.createRef();
  }

  focus = () => {
    this.periodRef.focus();
  }

  componentWillUnmount() {
    this.props.handleClose();
  }

  init() {
    this.columnsPrint = [
      { title: 'Nº CUPOM', cols: [{ label: { ref: 'couponNumber' } }] },
      { title: 'NSU', cols: [{ label: { ref: 'controlCode' } }] },
      { title: 'OPERADOR', cols: [{ label: { ref: 'seller' } }] },
      { title: 'CONSUMIDOR', cols: [{ label: { ref: 'customer' } }] },
      { title: 'TOTAL', cols: [{ label: { ref: 'total' } }] },
      { title: 'DATA', cols: [{ label: { ref: 'documentDate' } }] },
      { title: 'STATUS', cols: [{ label: { ref: 'canceled' } }] },
    ];

    this.columnsDefault = [
      { title: 'Nº CUPOM', cols: [{ label: { ref: 'couponNumber' } }] },
      { title: 'OPERADOR', cols: [{ label: { ref: 'seller' } }] },
      { title: 'CONSUMIDOR', cols: [{ label: { ref: 'customer' } }] },
      { title: 'TOTAL', cols: [{ label: { ref: 'total' } }] },
      { title: 'DATA/HORA', cols: [{ label: { ref: 'documentDate' } }] }
    ];
  }

  handleLineClick = (line) => {
    this.props.onHandleSelectPreOrder(line.item);
  }

  setColumns(typeTef) {
    if (typeTef) {
      return this.columnsPrint;
    }

    return this.columnsDefault;
  }

  handleConfirm = () => {
    if (this.periodRef.validate()) {
      this.props.handleClick(this.filters.initialDate, this.filters.finalDate);
    }
  }

  checkEnter = (e) => {
    switch (e.key) {
      case 'Enter':
        this.handleConfirm();
        break;
      case 'ArrowDown':
        this.tableRef.current.focusFirstRow();
        break;
      default:
        break;
    }
  }

  render() {
    const {
      title,
      listItens,
      handleClose,
      handleRefreshClick,
      showExtraColumns
    } = this.props;

    const columns = this.setColumns(showExtraColumns);

    return (
      <Card className="cardfix" shouldFlex autoScroll={false} title={title} handleClose={handleClose} closeEnable handleRefreshClick={handleRefreshClick} refreshEnable>
        <div>
          <Filters>
            <Period
              ref={(ref) => this.periodRef = ref}
              object={this.filters}
              fieldStart="initialDate"
              fieldEnd="finalDate"
              handleKeyDown={this.checkEnter}
              toastError
              autoFocus
            />
            <div className="refresh">
              <i tabIndex="0" onClick={this.handleConfirm} onKeyDown={this.checkEnter} className="material-icons">
                search
            </i>
            </div>
          </Filters>
        </div>
        <Table hover ref={this.tableRef} columns={columns} items={listItens} lineClick={this.handleLineClick} typeList="last-list" scrollable />
      </Card>
    );
  }
}

export default CancelOrderList;
