import React, { Component } from 'react';

import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

import { Button, Card } from '../modules';
import {
  Container,
  InputGroup,
  CashCloseGrid,
  CashCloseFormActions,
  GridCellEmpty,
} from './style';

class CashClose extends Component {
  static propTypes = {
    disableConfirm: PropTypes.bool,
    title: PropTypes.string,
    posId: PropTypes.string,
    handleClose: PropTypes.func,
    paymentTypeList: PropTypes.arrayOf(PropTypes.any),
    handleClick: PropTypes.func,
  };

  static defaultProps = {
    disableConfirm: null,
    title: null,
    posId: null,
    handleClose: null,
    paymentTypeList: null,
    handleClick: null,
  };

  fieldsValue = {};

  constructor(props) {
    super(props);
    this.state = { userError: '', passwordError: '' };
  }

  clearForm = () => {
    this.formEl.reset();
  };

  componentDidMount() {
    // for some reason, without the timeout, the focus doesnt work if you are opening that component for the second + time

    // something that i noticed, when you open that screen for the first time, the cash-close SHOW action is dispatched before the componentDidMount method
    // but after the first time, the componentDidMount is called before the dispatch of the action (at least on the console output)
    // thats probably the reason its only working of the first time, but at the moment i couldnt think of anything, other than the timeout, that could fix it
    setTimeout(() => {
      this.fieldsValue[
        this.props.paymentTypeList[0].enumValue
      ].theInput.focus();
    }, 0);
  }

  submitHandler = (event) => {
    event.preventDefault();

    const values = [];

    for (const [key, value] of Object.entries(this.fieldsValue)) {
      values[key] = value.state ? value.state.value : value.value;
    }

    values.description = 'fechamento de caixa';

    this.props.handleClick(values);
  };

  render() {
    const { title, posId, handleClose, paymentTypeList } = this.props;
    return (
      <Container>
        <Card
          id="card-close"
          title={`${title} - ${posId}`}
          handleClose={handleClose}
          closeEnable
          autoScroll
        >
          <form
            onSubmit={(e) => this.submitHandler(e)}
            ref={(form) => {
              this.formEl = form;
            }}
          >
            <CashCloseGrid>
              {paymentTypeList &&
                paymentTypeList.map((paymentType, index) => {
                  if (paymentType.enumValue !== 99) {
                    const paymentTypeKey = paymentType.name
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()
                      .replace(/ /g, '_');
                    return (
                      <InputGroup key={index} id={index}>
                        <label htmlFor={paymentTypeKey}>
                          {paymentType.name}
                        </label>
                        <div>
                          <CurrencyInput
                            prefix="R$ "
                            id={paymentTypeKey}
                            name={paymentTypeKey}
                            ref={(ref) =>
                              (this.fieldsValue[paymentType.enumValue] = ref)
                            }
                            decimalSeparator=","
                            thousandSeparator="."
                          />
                          <small>Informe o total</small>
                        </div>
                      </InputGroup>
                    );
                  }

                  return null;
                })}

              <GridCellEmpty />
            </CashCloseGrid>
            <CashCloseFormActions>
              <Button
                id="btnClearFields"
                className="btn-large btn-outline btn-custom"
                type="button"
                handleClick={this.clearForm}
                disabled={this.props.disableConfirm}
              >
                Limpar
              </Button>
              <Button
                id="btnDone"
                className="btn-large btn-purple btn-custom"
                type="submit"
                disabled={this.props.disableConfirm}
              >
                Confirmar
              </Button>
            </CashCloseFormActions>
          </form>
        </Card>
      </Container>
    );
  }
}
export default CashClose;
