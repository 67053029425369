import { endOfDay, startOfDay, subDays } from 'date-fns';
import * as Yup from 'yup';

const defaultMessage = 'Campo obrigatório';

const validation = Yup.object().shape({
  pbm: Yup.string().required(defaultMessage),
  status: Yup.string().required(defaultMessage),
  initialDate: Yup.date()
    .required(defaultMessage)
    .min(startOfDay(subDays(new Date(), 7)), 'Intervalo máximo de 7 dias')
    .max(endOfDay(new Date()), 'Não é possível selecionar uma data futura'),
  finalDate: Yup.date()
    .required(defaultMessage)
    .max(endOfDay(new Date()), 'Não é possível selecionar uma data futura')
    .when('initialDate', (initialDate, schema) =>
      initialDate
        ? schema.min(
            initialDate,
            'Não é possível selecionar uma data inferior a Data Inicial'
          )
        : schema
    ),
});

export default validation;
