import React from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules';
import { currencyMask } from '../../utils/masks';
import { Form, Table } from 'react-bootstrap';
import './style.scss';

export class DiscountQuantity extends React.Component {
  constructor(props) {
    super(props);

    this.modalConfirm = React.createRef();
  }

  componentDidMount() {
    this.modalConfirm.current.open();
  }

  customCellrender = (item) => (
    <i className="material-icons">{item.selected ? 'check_box' : 'check_box_outline_blank'}</i>
  );

  getHeadersQuantity = () => {
    return (
      <tr>
        <th>Selecionar</th>
        <th>Descrição lista de desconto</th>
        <th>A partir de</th>
        <th>Preço unitário (R$)</th>
        <th>Preço total (R$)</th>
      </tr>
    );
  };

  getHeadersTakePay = () => {
    return (
      <tr>
        <th>Selecionar</th>
        <th>Descrição lista de desconto</th>
        <th>Pague</th>
        <th>Leve</th>
        <th>Preço unitário (R$)</th>
        <th>Preço total (R$)</th>
      </tr>
    );
  };

  getHeadersDefault = () => {
    return (
      <tr>
        <th>Selecionar</th>
        <th>Nome do produto</th>
        <th>Quantidade</th>
        <th>Preço unitário (R$)</th>
        <th>Preço total (R$)</th>
      </tr>
    );
  };

  getRowQuantity = (item, idx) => {
    return (
      <tr id={'quantity_' + idx} onClick={() => this.props.handleChangeSelected(item)}>
        <td>
          <i className="material-icons">{item.selected ? 'radio_button_checked' : 'radio_button_unchecked'}</i>
        </td>
        <td> {item.description}</td>
        <td> {item.quantity}</td>
        <td>
          {currencyMask(
            this.props.data.discountDefault.price - (item.discountRate / 100) * this.props.data.discountDefault.price
          )}
        </td>
        <td>
          {currencyMask(
            item.quantity *
              (this.props.data.discountDefault.price -
                (item.discountRate / 100) * this.props.data.discountDefault.price)
          )}
        </td>
      </tr>
    );
  };

  getRowTakePay = (item, idx) => {
    return (
      <tr id={'take_pay_' + idx} onClick={() => this.props.handleChangeSelected(item)}>
        <td>
          <i className="material-icons">{item.selected ? 'radio_button_checked' : 'radio_button_unchecked'}</i>
        </td>
        <td> {item.description}</td>
        <td> {item.payQuantity}</td>
        <td> {item.takeQuantity}</td>
        <td> {currencyMask(this.props.data.discountDefault.price)}</td>
        <td> {currencyMask(item.payQuantity * this.props.data.discountDefault.price)}</td>
      </tr>
    );
  };

  getRowDefault = (item, idx) => {
    return (
      <tr id={'default_' + idx} onClick={() => this.props.handleChangeSelected(item)}>
        <td>
          <i className="material-icons">{item.selected ? 'radio_button_checked' : 'radio_button_unchecked'}</i>
        </td>
        <td> {item.description}</td>
        <td> {item.quantity}</td>
        <td> {currencyMask(item.price)}</td>
        <td> {currencyMask(item.quantity * item.price)}</td>
      </tr>
    );
  };

  render() {
    const { data, handleCancel, handleConfirm } = this.props;

    return (
      <Modal
        title={data.discountDefault.description}
        closable={true}
        opened={true}
        ref={this.modalConfirm}
        onClickClose={handleCancel}
        id="discount-quantity"
        className="col-8"
        style={{
          height: '100vh',
          width: '60vw',
          maxWidth: '80vw',
        }}
      >
        <Modal.Body>
          <Form.Row className="row-title">
            <div className="father">
              <div className="info">
                <i className="material-icons">info</i>
              </div>
              <label>
                Este produto possui descontos por quantidades compradas que podem ser ofertadas ao consumidor.
              </label>
            </div>
          </Form.Row>
          {data.quantityDiscount.length > 0 ? (
            <Form.Row className="quantity-discount-wrapper">
              <Form.Label>Descontos por quantidade:</Form.Label>
              <Table className="table-sm" striped hover>
                <thead>{this.getHeadersQuantity()}</thead>
                <tbody>{data.quantityDiscount.map(this.getRowQuantity)}</tbody>
              </Table>
            </Form.Row>
          ) : (
            ''
          )}
          {data.takePayDiscount.length > 0 ? (
            <Form.Row className="take-pay-discount-wrapper">
              <Form.Label>Descontos pague leve:</Form.Label>
              <Table className="table-sm" striped hover>
                <thead>{this.getHeadersTakePay()}</thead>
                <tbody>{data.takePayDiscount.map(this.getRowTakePay)}</tbody>
              </Table>
            </Form.Row>
          ) : (
            ''
          )}
          <Form.Row className="unit-price-wrapper">
            <Form.Label>Preço unitário:</Form.Label>
            <Table className="table-sm" striped hover>
              <thead>{this.getHeadersDefault()}</thead>
              <tbody>{this.getRowDefault(data.discountDefault)}</tbody>
            </Table>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer
          style={{
            position: 'sticky',
            bottom: '0',
          }}
        >
          <Button
            id="btn-cancel"
            className="btn-default btn-outline"
            type="button"
            onClick={handleCancel}
            style={{ flex: '1' }}
          >
            Cancelar
          </Button>
          <Button
            id="btn-confirm"
            className="btn-default btn-outline"
            type="button"
            onClick={handleConfirm}
            style={{ flex: '1' }}
          >
            Aplicar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DiscountQuantity;
