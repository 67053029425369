import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListBrandsCard from '../../shared/components/card-list-brand';
import PaymentService from '../../services/paymentService';
import { showCard, closeAllCards, CARD_BRAND_LIST, shouldShow, getPayload } from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';

export class ListBrandCardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = ({
      cardListBrand: '',
      title: '',
      subTitle: '',
      imageCard: '',
    });

    this.props.MessageBus.Subscribe('payment.creditcard.brandsWithAmount', this.eventCreditReturn.bind(this));
    this.props.MessageBus.Subscribe('payment.debit.brandsWithAmount', this.eventDebitReturn.bind(this));
    this.props.MessageBus.Subscribe('pos.order.deleted', this.props.closeCardBrandList);

    this.paymentService = new PaymentService();
  }

  eventCreditReturn(canal, payload) {
    const componentPayload = {
      cardListBrand: payload,
      title: 'Crédito',
      subTitle: 'Escolha uma bandeira',
      imageCard: './assets/images/payment-types/creditcard.svg',
    };
    
    this.props.openCardBrandList(componentPayload);
  }

  eventDebitReturn(canal, payload) {
    const componentPayload = {
      cardListBrand: payload,
      title: 'Débito',
      subTitle: 'Escolha uma bandeira',
      imageCard: './assets/images/payment-types/debito.svg',
    };
    
    this.props.openCardBrandList(componentPayload);
  }

  onHandleBrand = (brand, amount) => {
    this.paymentService.inputBrand(brand, amount)
      .catch(error => this.context.showError(error));
  }

  handleClose = () => {
    this.paymentService.backPayment()
      .catch(err => this.context.showError(err));
  }

  render() {
    return (
      this.props.showCardBrandList && (
        <ListBrandsCard
          items={this.props.payload.cardListBrand}
          handleOnBrand={this.onHandleBrand}
          title={this.props.payload.title}
          subTitle={this.props.payload.subTitle}
          imageCard={this.props.payload.imageCard}
          handleClose={this.handleClose}
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  showCardBrandList: shouldShow(state, CARD_BRAND_LIST),
  payload: getPayload(state, CARD_BRAND_LIST)
});

const mapDispatchToProps = dispatch => ({
  openCardBrandList: payload => dispatch(showCard(CARD_BRAND_LIST, payload)),
  closeCardBrandList: () => dispatch(closeAllCards())
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(ListBrandCardContainer)));
