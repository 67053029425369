import styled from 'styled-components';

const ClienteAreaWrapper = styled.div`
  justify-self: right;
  margin-right: 15px;

  .img-client {
    height: auto;
    max-width: 85px;
    height: auto;
    padding: 7px 0;
  }
`;

export default ClienteAreaWrapper;
