import React from 'react';
import { Card, Button } from '../modules';
import { Form, Col } from 'react-bootstrap';
import { DatePicker } from '../date-picker';
import './pbm-external-authorization.scss';

export default function PbmExternalAuthorizationComponent(props) {
    const {
        title,
        handleClose,
        handleChange,
        handleSearch,
        form,
        handleOpenExternalAuthorizer
    } = props;

    return (<Card
        id="card-pbm-search-authorization"
        closeEnable
        title={title}
        handleClose={handleClose}>
        <h3>Dados da dispensação</h3>
        <Form>
            <Form.Row>
                <Form.Group as={Col} controlId="CpfCartao">
                    <Form.Label>CPF ou número do cartão</Form.Label>
                    <Form.Control
                        placeholder="Informe CPF ou Núm. Cartão"
                        defaultValue={form.CpfCartao}
                        onChange={handleChange}
                        type="text"
                        autoFocus
                    />
                </Form.Group>
                <Form.Group as={Col} controlId="Autorizacao">
                    <Form.Label>Número da pré-autorização</Form.Label>
                    <Form.Control
                        placeholder="Núm. Pré-autorização"
                        defaultValue={form.Autorizacao}
                        type="text"
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId="Data">
                    <Form.Label>Data da autorização</Form.Label>
                    <DatePicker
                        id="fieldData"
                        placeholder="12/05/1984"
                        selected={form.Data}
                        className="form-control"
                        onChange={(value) =>
                            handleChange({ target: { id: 'Data', value } })
                        }>
                    </DatePicker>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Col>
                    <span className="accessAuthorizer" onClick={handleOpenExternalAuthorizer}>
                        <i className="material-icons">info</i>
                        Acesse o Autorizador
                    </span>
                </Col>
                <Col className="right">
                    <Button id="confirm" className="btn3 btn-purple btn-custom" onClick={handleSearch}>Consultar</Button>
                </Col>
            </Form.Row>
        </Form>
    </Card>)
}