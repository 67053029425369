/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import PropTypes from 'prop-types';

import { Cell } from './cell-render';

import './row.style.scss';

export class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreOptionsToggled: false,
    };

    this.optionsRef = null;

    this.onClickEvent = this.onClickEvent.bind(this);
  }

  onClick = () => {
    if (this.props.onRowSelect && this.props.selectable) {
      this.props.onRowSelect(this.props.rowData);
    }
  };

  handleKeyDown = (e) => {
    if (this.props.selectable) {
      switch (e.key) {
        case 'Enter':
        case ' ':
          e.preventDefault();
          e.stopPropagation();
          this.props.onRowSelect(this.props.rowData);
          break;
        case 'ArrowUp':
          if (e.target.previousSibling) {
            e.target.previousSibling.focus();
          } else if (this.props.focusFilter) {
            this.props.focusFilter();
          }
          break;
        case 'ArrowDown':
          if (e.target.nextSibling) {
            e.target.nextSibling.focus();
          }
          break;
        default:
          break;
      }
    }
  };

  componentDidMount() {
    if (this.props.moreOptionsToShow) {
      window.addEventListener('click', this.onClickEvent);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickEvent);
  }

  onClickEvent(e) {
    this.state.moreOptionsToggled && e.target !== this.optionsRef && this.setState({ moreOptionsToggled: false });
  }

  toggleMoreOptions = () => {
    this.setState({
      moreOptionsToggled: true,
    });
  };

  render() {
    const { columns, rowData, selectable, selected, moreOptionsToShow } = this.props;

    const tabIndex = selectable ? 0 : null;
    let classes = [selectable ? 'table-row' : 'row-disabled'];

    if (selected === rowData) {
      classes.push('active');
    }

    classes = classes.join(' ');

    return (
      <>
        <tr
          className={classes}
          autoFocus={selected}
          tabIndex={tabIndex}
          ref={this.rowRef}
          onKeyDown={this.handleKeyDown}
          onClick={this.onClick}
        >
          {columns.map((column, index) => (
            <Cell key={column.label || index} value={rowData} {...column} />
          ))}
          {moreOptionsToShow && (
            <td className="more-options">
              <i
                data-testid="more-options"
                ref={(ref) => (this.optionsRef = ref)}
                className="material-icons"
                onClick={this.toggleMoreOptions}
              >
                more_vert
              </i>
              {this.state.moreOptionsToggled && (
                <div className="dropdown-content" data-testid='data-testid="more-options"'>
                  {moreOptionsToShow.map((option) =>
                    !option.isVisible || option.isVisible(rowData) ? (
                      <a
                        key={`option-${option.name}`}
                        className={!option.isEnabledFn(rowData) ? 'disabled' : ''}
                        onClick={() => option.isEnabledFn(rowData) && option.onClick(rowData)}
                      >
                        {option.name}
                      </a>
                    ) : (
                      ''
                    )
                  )}
                </div>
              )}
            </td>
          )}
        </tr>
      </>
    );
  }
}

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  selectable: PropTypes.bool,
  onRowSelect: PropTypes.func,
};

Row.defaultProps = {
  selectable: false,
  onRowSelect: null,
  selected: null,
};

export default Row;
