import React from 'react'
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { ConfigTerminal } from '../../shared/components/config-terminal'
import { CONFIG_TERMINAL, shouldShow, closeCard } from '../../redux/actions/routerAction';
import { showToast } from '../../shared/utils/util';

export class ConfigTerminalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.props.MessageBus.Subscribe('machine.devices.config.sendSaveFile', this.handleSaveFile);
  }

  handleSaveFile = (ch, payload) => {
    console.log(payload);
    if (window.desktopApp)
      window.desktopApp.publish('machine.devices.config.saveFile', payload);
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case 'name':
        this.props.config.printer.name = e.target.value;
        break;
      case 'port':
        this.props.config.printer.port = e.target.value;
        break;
      case 'cnpjHomolog':
        this.props.config.danfe.sat.cnpjHomolog = e.target.value;
        break;
      default:
        this.props.config[e.target.id] = e.target.value
    }
    this.forceUpdate();
  }

  handleToggleKioskChange = (e) => {
    this.props.config.window.kiosk = e.target.checked;
    this.forceUpdate();
  }

  handleToggleConfigFromBdChange = (e) => {
    this.props.config.useConfigsFromBD = e.target.checked;
    this.forceUpdate();
  }

  handleChangeType = (e) => {
    this.props.config.posType = e.find(f => f.selected).value;
    this.forceUpdate();
  }

  handleChangeDocument = (e) => {
    this.props.config.danfe.fiscalType = e.find(f => f.selected).value;
    this.forceUpdate();
  }

  handleChangePrinterOption = (e) => {
    this.props.config.danfe.printerOption = e.find(f => f.selected).value;
    this.forceUpdate();
  }

  handleClose = () => {
    this.props.close();
  }

  handleConfirm = () => {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.config.saveConfig', this.props.config);
      showToast({
        type: 3,
        title: 'Configurações Terminal',
        html: 'Feche a tela de vendas e abra novamente para aplicar as novas configurações.',
        hideAfterMilliseconds: 5000
      });
      this.handleClose();
    } else {
      showToast({
        type: 2,
        title: 'Configurações Terminal',
        html: 'Configurações nao podem ser alteradas a partir do navegador',
        hideAfterMilliseconds: 5000
      });
    }
  }

  render() {
    return (
      this.props.visible && <ConfigTerminal
        handleClose={this.handleClose}
        form={this.props.config}
        handleChange={this.handleChange}
        handleConfirm={this.handleConfirm}
        handleToggleKioskChange={this.handleToggleKioskChange}
        handleChangeType={this.handleChangeType}
        handleChangeDocument={this.handleChangeDocument}
        handleChangePrinterOption={this.handleChangePrinterOption}
        handleToggleConfigFromBdChange={this.handleToggleConfigFromBdChange}
      >
      </ConfigTerminal>
    );
  }
}
const mapStateToProps = state => ({
  visible: shouldShow(state, CONFIG_TERMINAL),
  config: state.config
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeCard(CONFIG_TERMINAL))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(ConfigTerminalContainer)));
