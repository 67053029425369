import styled from "styled-components";

export const SelectBoxWrapper = styled.div`
  position: relative;

  .clear-input-select {
    position: absolute;
    top: 10px;
    right: 0;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

export const SelectBoxItems = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  padding: 8px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.25);
`;

export const SelectBoxItem = styled.span`
  font-size: 15px;
  margin-bottom: 4px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: #d3d3d3;
  }

  &:focus {
    background-color: #d3d3d3;
    outline: none;
  }
`;
