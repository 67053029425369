import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../modules';
import ListParcelCard from '../list-parcel-card';

const PaymentCreditSale = (props) => {
  const {
    items,
    onHandleCreditSale,
    handleClose,
  } = props;

  return (
    <Card
      title="CREDIÁRIO"
      subTitle="Escolha as parcelas"
      handleClose={handleClose}
      urlIcone="./assets/images/payment-types/creditSale.svg"
      closeEnable
    >
      <ListParcelCard
        items={items}
        handle={onHandleCreditSale}
      />
    </Card>
  );
};

PaymentCreditSale.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  onHandleCreditSale: PropTypes.func,
  handleClose: PropTypes.func,
};

PaymentCreditSale.defaultProps = {
  items: [],
  onHandleCreditSale: null,
  handleClose: null,
};

export default PaymentCreditSale;
