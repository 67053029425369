import { PbmTableWrapper, ModalWrapper, ModalTitleWrapper, ModalFooterWrapper } from './apply-pbm-products-component.style'
import React, { Fragment } from 'react';
import { Modal } from '../modal2';
import { Button, } from '../modules';
import { currencyString } from '../../utils/masks';

export class ApplyPbmProductsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.inputFile = React.createRef();
    this.confirmButton = React.createRef();
    this.hasApprovedItems = props.approvedItems && props.approvedItems.length > 0;
    this.hasNotApprovedItems = props.notApprovedItems && props.notApprovedItems.length > 0;
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  getHeaders = () => {
    return (
      <ul className="header">
        <li>EAN</li>
        <li>Produto</li>
        <li className="center">Qtd Solicitada</li>
        <li className="center">Qtd Autorizada</li>
        <li className="right">Vlr. Produto(R$)</li>
        <li className="right">% Desconto</li>
        <li className="right">Vlr. c/ Desconto(R$)</li>
        <li className="right">Vlr.Subsídio(R$)</li>
      </ul>
    );
  }

  getRow = (data, idx) => {
    return (
      <ul className="datarow" key={`row-${idx}`}>
        <li>{data.barcode}</li>
        <li>{data.name.toLowerCase()}</li>
        <li className="center">{data.requestedQuantity} un</li>
        <li className="center">{data.authorizedQuantity} un</li>
        <li className="right" >{currencyString(data.totalValue)}</li>
        <li className="right">{data.percentageDiscountValue.toFixed(2)}</li>
        <li className="right">{currencyString(data.totalValueWithDiscount)}</li>
        <li className="right">{currencyString(data.totalSubsidy)}</li>
        {data.authorizedQuantity === 0 &&
          <li>{data.rejectionReason}</li>
        }
      </ul>
    );
  }
  getRowTotal = (data, idx) => {
    return (
      <ul className="datarow" key={`row-${idx}`}>
        <li></li>
        <li>{data.name.toLowerCase()}</li>
        <li className="center">{data.requestedQuantity} un</li>
        <li className="center">{data.authorizedQuantity} un</li>
        <li className="right" >{currencyString(data.totalValue)}</li>
        <li className="right">{data.percentageDiscountValue.toFixed(2)}</li>
        <li className="right">{currencyString(data.totalValueWithDiscount)}</li>
        <li className="right">{currencyString(data.totalSubsidy)}</li>
        {data.authorizedQuantity === 0 &&
          <li>{data.rejectionReason}</li>
        }
      </ul>
    );
  }

  closeModal() {
    this.modal.current.close();
  }

  handleUploadClick() {
    this.inputFile.current.click();
  }

  componentDidMount() {
    this.modal.current.open();
    this.confirmButton.current.focus();
  };

  render() {
    return (
      <ModalWrapper>
        <Modal
          title=''
          closable={false}
          opened={true}
          ref={this.modal}>
          <Modal.Body id="body">
            <ModalTitleWrapper>
              {'AUTORIZAÇÃO  - ' + this.props.pbmDescription}
            </ModalTitleWrapper>
            <PbmTableWrapper className="col-12" >
              {this.hasApprovedItems &&
                <div>
                  <h1>
                    Produtos autorizados
                  </h1>
                  {this.getHeaders()}
                  {!this.props.approvedItems.length && <ul className="datarow norecords"><li>Nenhuma informação encontrada</li></ul>}
                  {this.props.approvedItems.map(this.getRow)}
                </div>}
              {this.hasNotApprovedItems &&
                <div>
                  <h1>
                    Produtos Não autorizados
                  </h1>
                  {!this.hasApprovedItems && this.getHeaders()}
                  {!this.props.notApprovedItems.length && <ul className="datarow norecords"><li>Nenhuma informação encontrada</li></ul>}
                  {this.props.notApprovedItems.map(this.getRow)}
                </div>
              }
              {this.props.pbmPrescriptionCopy !== 0 &&
                <Fragment>
                  <h1>
                    Upload de receitas
                </h1>
                  <div className="files">
                    <div className="fileselector" onClick={this.handleUploadClick}>
                      <i className="material-icons" tabIndex="0" >cloud_upload</i>
                      <span className="selected">{(this.props.files.length === 0 ? 'Selecione ao menos um arquivo.' : this.props.files.length + ' arquivos selecionados.') + (this.props.recusedFiles.length > 0 ? ' ' + this.props.recusedFiles.length + ' ' + (this.props.recusedFiles.length === 1 ? 'arquivo recusado' : 'arquivos recusados') + ' por ultrapassar o limíte de 2MB.' : '')}</span>
                    </div>
                    <ol>
                      {
                        this.props.files.map((item, index) =>
                          <li key={item.name}>{item.name}<i onClick={(e) => this.props.handleFileRemove(index)} className="material-icons">close</i></li>
                        )
                      }
                    </ol>
                    <input type="file" multiple={true} ref={this.inputFile} onChange={this.props.handleFileChange}></input>
                  </div>
                </Fragment>
              }
            </PbmTableWrapper>
          </Modal.Body>
          <Modal.Footer>
            <ModalFooterWrapper>
              <Button
                id="btn-cancel"
                className="btn-default btn-outline"
                type="button"
                onClick={this.props.handleClose}
              >
                Cancelar
            </Button>
              <Button
                id="btn-confirm"
                className="btn-purple btn-default"
                type="button"
                onClick={this.props.handleConfirm}
                ref={this.confirmButton}
              >
                Confirmar
              </Button>
            </ModalFooterWrapper>
          </Modal.Footer>
        </Modal>
      </ModalWrapper>
    )
  }
}
export default ApplyPbmProductsComponent;
