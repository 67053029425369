import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  closeAllCards,
  FINANCIAL_DOCUMENT_RECEIPTMENT,
  shouldShow,
  showCard,
  CUSTOMER
} from '../../redux/actions/routerAction';
import FinancialDocumentService from '../../services/financialDocumentService';
import { FinancialDocumentReceiptmentComponent } from '../../shared/components/financial-document-receiptment/financial-document-receiptment.component';
import { getContext } from '../../shared/hoc/getContext';
import { currencyString } from '../../shared/utils/masks';
import { withMessageBus } from '../../shared/utils/messageBus';

import { showToast } from '../../shared/utils/util';

export class FinancialDocumentReceiptmentContainer extends Component {
  constructor(props) {
    super(props);
    this.props.MessageBus.Subscribe(
      'pos.financialDocument.cancel',
      this.handleCloseKey
    );
    this.props.MessageBus.Subscribe(
      'pos.financialDocument.start',
      this.props.open
    );
    this.props.MessageBus.Subscribe(
      'pos.financialDocument.find',
      this.handleReturn
    );
    this.props.MessageBus.Subscribe(
      'pos.financialDocument.success',
      this.handleSuccess
    );
    this.props.MessageBus.Subscribe(
      'pos.financialDocument.error',
      this.handleError
    );
    this.props.MessageBus.Subscribe(
      'pos.customer.listed',
      this.handleCustomerSearch
    );

    this.state = {
      data: [],
      totalSelected: '0',
      discountType: 1,
      debitValue: '0,00',
      discountValue: '0',
      interestSelectedValue: 0,
      interestManualValue: 0,
      valueToReceive: '0,00',
      selectedCustomer: null,
      isAnyDataReturned: false,
      startDate: null,
      endDate: null,
      debitValueErrorMessage: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCustomer !== this.state.selectedCustomer) {
      this.setState({
        data: [],
        totalSelected: '0',
        discountType: 1,
        debitValue: '0,00',
        discountValue: '0',
        interestSelectedValue: 0,
        interestManualValue: 0,
        valueToReceive: '0,00',
        isAnyDataReturned: true
      });
    }

    if (prevState.totalSelected !== this.state.totalSelected) {
      this.setState({
        discountValue: "0,00"
      })
    }
  }

  handleCloseKey = () => {
    this.props.close();
    this.setState({
      data: [],
      selectedCustomer: null,
      startDate: null,
      endDate: null,
      debitValueErrorMessage: null
    });
  };

  handleClose = () => {
    FinancialDocumentService.back();
  };

  handleError = (ch, payload) => {
    this.context.showMessage('Erro ao tentar baixar o título: ' + payload.reason);
  };

  handleConfirm = () => {
    const selectedItems = this.state.data.filter((item) => item.selected);
    if (selectedItems.length === 0) return;

    const ids = selectedItems.map((item) => item.id);
    let disc = this.maskToValue(this.state.discountValue);
    if (this.state.discountType === 2) {
      disc = this.state.debitValue * (disc / 100);
    }
    FinancialDocumentService.confirm(
      ids,
      this.maskToValue(this.state.debitValue),
      disc,
      this.state.interestManualValue
    );
  };

  validateDebitValue = () => {
    const { valueToReceive, interestManualValue, discountValue, debitValue, discountType, totalSelected, interestSelectedValue } = this.state;
    const titulosCount = this.state.data.filter((item) => item.selected).length;
    const parsedTotalSelected = parseFloat(totalSelected.toFixed(2));
    let parsedDebitValue = parseFloat(debitValue.toFixed(2));
    let parsedDiscountValue = discountType === 1 ? parseFloat(discountValue.replace('.', '').replace(',', '.')) : (parseFloat(discountValue.replace('.', '').replace(',', '.')) / 100) * parsedDebitValue;
    parsedDiscountValue = Math.round(parsedDiscountValue * 100) / 100;
    let parsedValueToReceive = parseFloat(valueToReceive.replace('.', '').replace(',', '.'));
    let discountLimit = parsedDebitValue > 0 ? parsedDebitValue - (titulosCount / 100) : parsedDebitValue;
    discountLimit = Math.round(discountLimit * 100) / 100
    let valueToReceiveLimit = parsedDebitValue + interestManualValue - parsedDiscountValue;
    valueToReceiveLimit = Math.round(valueToReceiveLimit * 100) / 100;
    let errorMessage = null;

    const totalDocumentsWithoutInterest = parsedTotalSelected - interestSelectedValue;

    if (titulosCount / 100 >= parsedValueToReceive)
      errorMessage = 'Valor a receber deve ser maior que R$' + titulosCount.toFixed(2) / 100;
    else if (parsedValueToReceive < interestManualValue)
      errorMessage = 'Não é permitido valor menor que R$' + interestManualValue.toFixed(2) + " (Total de juros)";
    else if (parsedDiscountValue > discountLimit)
      errorMessage = 'Não é permitido valor de desconto maior que R$' + discountLimit.toFixed(2);
    else if (parsedValueToReceive > valueToReceiveLimit)
      errorMessage = 'Não é permitido valor a receber maior que R$' + valueToReceiveLimit.toFixed(2);
    else if (parsedDebitValue > Math.ceil(totalDocumentsWithoutInterest * 100) / 100) {
      errorMessage = "Valor 'Total dos títulos' é maior que a soma do saldo devedor, impossível continuar."
    }
    if (parsedValueToReceive <= 0)
      errorMessage = 'Valor a receber deve ser maior que R$' + titulosCount.toFixed(2) / 100;

    this.setState({ debitValueErrorMessage: errorMessage });
  }

  maskToValue = (value) => {
    if (typeof value !== 'string') return value;
    return parseFloat(
      value.replace('R$', '').replace('.', '').replace(',', '.')
    );
  };

  handleSuccess = () => {
    this.setState({
      totalSelected: 0,
      discountValue: '0,00',
      valueToReceive: '0,00',
      debitValue: 0,
      interestSelectedValue: 0,
      interestManualValue: 0,
    });
    this.searchFinancialDocuments(this.state.selectedCustomer.referenceId, this.state.startDate, this.state.endDate);
  };

  handleReturn = (ch, payload) => {
    this.setState({
      data: payload.map((i) => {
        i.installments = `${i.financialDocumentInstallment}/${i.financialDocumentInstallmentQtde}`;
        i.showChild = false;
        return i;
      }),
      totalSelected: 0,
      discountValue: '0,00',
      valueToReceive: '0,00',
      debitValue: 0,
      interestSelectedValue: 0,
      interestManualValue: 0,
      isAnyDataReturned: payload.length > 0
    });
  };

  searchFinancialDocuments = (customerReferenceId, startDate, endDate) => {
    if (customerReferenceId && startDate !== null && endDate !== null) {
      FinancialDocumentService.find({
        filterType: 0,
        customerReferenceId,
        startDate,
        endDate,
      });
    } else if (customerReferenceId && startDate === null && endDate === null) {
      FinancialDocumentService.find({
        filterType: 1,
        customerReferenceId
      })
    }
  }

  handleShowChild = (index) => {
    var changes = this.state.data
    changes[index].showChild = !changes[index].showChild;
    this.setState({ data: changes });
  }

  handleFind = (filter) => {
    const { startDate, endDate, cliente } = filter;

    this.setState({
      startDate: startDate !== null ? startDate : null,
      endDate: endDate !== null ? endDate : null,
    })

    if (cliente === "" && this.state.selectedCustomer === null) {
      showToast({
        type: 1,
        title: "Verifique as informações",
        html: "Selecione um cliente para realizar a pesquisa de títulos.",
        hideAfterMilliseconds: 4000
      })
      return;
    }

    if (cliente === "" && this.state.selectedCustomer !== null) {
      this.searchFinancialDocuments(this.state.selectedCustomer.referenceId, this.state.startDate, this.state.endDate);
      return;
    }

    this.props.showCustomerSelector({
      query: cliente,
      source: "financial-document-receiptment",
      onSelect: (selected) => {
        this.setState({ selectedCustomer: selected }, () => {
          const customerReferenceId = this.state.selectedCustomer.referenceId;

          this.searchFinancialDocuments(customerReferenceId, this.state.startDate, this.state.endDate);
        })
        this.props.hideCustomerSelector();
        this.props.open();
      }
    });
  };

  handleDiscountTypeChange = (value) => {
    this.setState({
      discountType: value,
      discountValue: '0,00',
      valueToReceive: this.getValueToReceive(
        this.state.debitValue,
        '0',
        value,
        this.state.interestManualValue
      ),
    });
  };

  updateTotal = () => {
    const total = this.state.data
      .filter((item) => item.selected === true)
      .reduce((sum, value) => sum + value.financialDocumentBalance, 0);
    const totalSelected = this.state.data
      .filter((item) => item.selected === true)
      .reduce((sum, value) => sum + value.financialDocumentBalanceWithInterest, 0);
    const totalInterest = this.state.data
      .filter((item) => item.selected === true)
      .reduce((sum, value) => sum + value.interestValue, 0);

    this.setState({
      totalSelected: Math.round(totalSelected * 100) / 100,
      debitValue: Math.round(total * 100) / 100,
      valueToReceive: this.getValueToReceive(
        total.toString(),
        this.state.discountValue,
        this.state.discountType,
        totalInterest
      ),
      interestSelectedValue: Math.round(totalInterest * 100) / 100,
      interestManualValue: Math.round(totalInterest * 100) / 100,
      debitValueErrorMessage: null
    });
  };

  handleChangeSelected = (item) => {
    item.selected = !item.selected;
    this.updateTotal();
  };

  handleChangeSelectedAll = (selected) => {
    const { data } = this.state;
    const items = data.map((item) => {
      return { ...item, selected };
    });
    this.setState({ data: items }, () => this.updateTotal());
  };

  handleDebitChange = (value) => {
    value = parseFloat(
      value.replace('.', '').replace(',', '.').replace('R$ ', '')
    );
    this.setState({
      debitValue: value,
      valueToReceive: this.getValueToReceive(
        value,
        this.state.discountValue,
        this.state.discountType,
        this.state.interestManualValue
      ),
    }, () => {
      this.validateDebitValue();
    });
  };

  handleInterestChange = (value) => {
    value = parseFloat(
      value.replace('.', '').replace(',', '.').replace('R$ ', '')
    );
    this.setState({
      discountValue: '0,00',
      interestManualValue: isNaN(value) ? 0 : value,
      valueToReceive: this.getValueToReceive(
        this.state.debitValue,
        this.state.discountValue,
        this.state.discountType,
        isNaN(value) ? 0 : value,
      ),
    }, () => {
      this.validateDebitValue();
    });
  };

  handleDiscountChange = (value) => {
    const discountValue = value.replace('R$ ', '').replace('% ', '');

    this.setState({
      discountValue,
      valueToReceive: this.getValueToReceive(
        this.state.debitValue,
        discountValue,
        this.state.discountType,
        this.state.interestManualValue
      ),
    }, () => {
      this.validateDebitValue();
    });
  };

  handleValueToReceiveChange = (value) => {
    value = parseFloat(value.replace('.', '').replace(',', '.').replace('R$ ', '').replace('% ', ''));
    const { discountValue, interestManualValue, discountType } = this.state;
    const interest = parseFloat(interestManualValue);
    const debitValue = this.state.data
      .filter((item) => item.selected === true)
      .reduce((sum, value) => sum + value.financialDocumentBalance, 0);

    let parsedDiscountValue = parseFloat(discountValue.replace('.', '').replace(',', '.'));
    parsedDiscountValue = discountType === 1 ? parsedDiscountValue : (value / (1 - (parsedDiscountValue / 100))) - value;
    let totalFinancialDocuments = parsedDiscountValue > 0 ? value + parsedDiscountValue : value - interest;
    totalFinancialDocuments = totalFinancialDocuments < 0 ? 0 : totalFinancialDocuments;
    totalFinancialDocuments = totalFinancialDocuments > debitValue ? debitValue : totalFinancialDocuments;

    this.setState({
      valueToReceive: currencyString(value).split('$')[1] || '0,00',
      debitValue: totalFinancialDocuments
    }, () => {
      this.validateDebitValue();
    });
  };

  getValueToReceive = (
    total,
    discountValueStr,
    discountType,
    interestValue
  ) => {
    let discountValue = discountValueStr === "" ? 0 : parseFloat(
      discountValueStr.replace('.', '').replace(',', '.')
    );

    if (discountType === 2) {
      discountValue = total * (discountValue / 100);
    }

    let valueToReceive = total - discountValue + interestValue;

    if (valueToReceive < 0) {
      valueToReceive = 0;
    }

    return currencyString(valueToReceive).split('$')[1] || '0,00';
  };


  handleCleanCustomer = () => {
    this.setState({
      data: [],
      totalSelected: '0',
      discountType: 1,
      debitValue: '0,00',
      discountValue: '0',
      interestSelectedValue: 0,
      interestManualValue: 0,
      valueToReceive: '0,00',
      selectedCustomer: null,
      startDate: null,
      endDate: null,
      debitValueErrorMessage: null
    })
  }

  showDialog = (message) => {
    console.log(message);
    this.props.MessageBus.Publish('pos.dialog', { message });
  }

  render() {
    return (
      this.props.show && (
        <FinancialDocumentReceiptmentComponent
          data={this.state.data}
          handleConfirm={this.handleConfirm}
          handleClose={this.handleClose}
          handleFind={this.handleFind}
          filter={this.filter}
          form={this.form}
          discountType={this.state.discountType}
          handleChangeSelected={this.handleChangeSelected}
          handleChangeSelectedAll={this.handleChangeSelectedAll}
          totalSelected={this.state.totalSelected}
          handleDiscountTypeChange={this.handleDiscountTypeChange}
          discountValue={this.state.discountValue}
          debitValue={this.state.debitValue}
          handleDiscountChange={this.handleDiscountChange}
          handleDebitChange={this.handleDebitChange}
          valueToReceive={this.state.valueToReceive}
          interestSelectedValue={this.state.interestSelectedValue}
          interestManualValue={this.state.interestManualValue}
          handleInterestChange={this.handleInterestChange}
          selectedCustomer={this.state.selectedCustomer}
          handleCleanCustomer={this.handleCleanCustomer}
          isAnyDataReturned={this.state.isAnyDataReturned}
          startDateState={this.state.startDate}
          endDateState={this.state.endDate}
          handleShowChild={this.handleShowChild}
          handleValueToReceiveChange={this.handleValueToReceiveChange}
          showDialog={this.showDialog}
          debitValueErrorMessage={this.state.debitValueErrorMessage}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  show: shouldShow(state, FINANCIAL_DOCUMENT_RECEIPTMENT),
});

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(showCard(FINANCIAL_DOCUMENT_RECEIPTMENT, null, true)),
  close: () => dispatch(closeAllCards()),
  showCustomerSelector: (value) => dispatch(showCard(CUSTOMER, value, true)),
  hideCustomerSelector: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(FinancialDocumentReceiptmentContainer)));
