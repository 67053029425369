import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  online: false,
  retailerHasConfig: false,
  userHasConfig: false
};

const farmaciaPopularStatusReducer = createReducer(initialState, {
[types.CHECK_STATUS_FP]: (state, action) => ({
    ...state,
    online: action.payload,
  }),
  [types.HAS_CONFIG_RETAILER_FP]: (state, action) => ({
    ...state,
    retailerHasConfig: action.payload,
  }),
  [types.HAS_CONFIG_USER_FP]: (state, action) => ({
    ...state,
    userHasConfig: action.payload,
  })
});

export default farmaciaPopularStatusReducer;