import React, { useState } from 'react';
import { InputGroupBtn, InputMoneyToCheck, SpanBtnAdd } from '../style';

import BtnAddSvg from '../../../../../assets/images/btn-add.svg';

interface IInputCheckPartial {
  index: number;
  handleClickBtnAdd: any;
  setFocusBtnSuccess: any;
}

function InputCheckPartial(inputProps: IInputCheckPartial) {
  const [value, setValue] = useState(0);

  const handleChange = (event, maskedvalue, floatvalue) => {
    setValue(floatvalue);
  };

  const addValueToCheckPartial = () => {
    if (!value) return;

    inputProps.handleClickBtnAdd(inputProps.index, value);

    setValue(0);
  };

  const eventClickEnter = (event) => {
    if (event.key === 'Enter') {
      addValueToCheckPartial();

      let nextInputId = `insert-value-check-partial-${inputProps.index + 1}`;

      let inputSelect = document.querySelector(`#${nextInputId}`) as any;

      if (inputSelect) inputSelect.focus();
      else inputProps.setFocusBtnSuccess();
    }
  };

  return (
    <InputGroupBtn>
      <InputMoneyToCheck
        id={`insert-value-check-partial-${inputProps.index}`}
        data-testid={`-${inputProps.index}`}
        decimalSeparator=','
        thousandSeparator='.'
        value={value}
        onChangeEvent={handleChange}
        maxLength={10}
        onKeyPress={eventClickEnter}
      />
      <SpanBtnAdd
        data-testid={`span-btn-add-${inputProps.index}`}
        onClick={() => addValueToCheckPartial()}
      >
        <img src={BtnAddSvg} />
      </SpanBtnAdd>
    </InputGroupBtn>
  );
}

export default InputCheckPartial;
