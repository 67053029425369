import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const Navigation = ({
  prevPrescription,
  newPrescription,
  nextPrescription,
  removePrescription,
  sequence,
  size,
}) => (
  <Container>
    <span>
      <i
        className={`material-icons ${size <= 1 ? 'disabled' : ''}`}
        onClick={removePrescription}
      >
        remove_circle_outline
      </i>
    </span>
    <span>
      <i
        className={`material-icons ${sequence <= 1 ? 'disabled' : ''}`}
        onClick={prevPrescription}
      >
        keyboard_arrow_left
      </i>
    </span>
    <span />

    <h3>{`Receita ${sequence} de ${size}`}</h3>

    <span>
      <i
        className={`material-icons ${size <= sequence ? 'disabled' : ''}`}
        onClick={nextPrescription}
      >
        keyboard_arrow_right
      </i>
    </span>
    <span>
      <i className="material-icons" onClick={newPrescription}>
        add_circle_outline
      </i>
    </span>
  </Container>
);

Navigation.propTypes = {
  prevPrescription: PropTypes.func.isRequired,
  newPrescription: PropTypes.func.isRequired,
  nextPrescription: PropTypes.func.isRequired,
  removePrescription: PropTypes.func.isRequired,
  sequence: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
};

export default Navigation;
