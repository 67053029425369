import AbstractService from './abstractService';
import HttpAccess from './httpAccess';
import Configuration from '../configuration';
import handlePost from '../shared/utils/handlePost';
import { ISolicitationSangriaDespesaContract } from '../shared/interfaces/solicitation-sangria-despesa-contract';
import { ISolicitationLiberacaoLimiteContract } from '../shared/interfaces/solicitation-liberacao-limite.contract';
import { ISolicitationCoberturaOfertaContract } from '../shared/interfaces/solicitation-cobertura-oferta-contract';
import { ISolictationExcluirPreVendaContract } from '../shared/interfaces/solicitation-excluir-pre-venda.contract';
import { ISolicitationProdutoSemEstoqueContract } from '../shared/interfaces/solicitation-produto-sem-estoque.contract';
import { ISolicitationLoteRecenteContract } from '../shared/interfaces/solicitation-lote-recente.contract';

export class SolicitationAuthorizeService {
  private abstractService = new AbstractService(
    'app',
    HttpAccess,
    Configuration
  );

  public startSolicitationSangriaDespesa(
    solicitation: ISolicitationSangriaDespesaContract
  ) {
    const url = `${this.abstractService.getBaseUrl()}/app/startSolicitationSangriaDespesa`;
    const { identificaoDespesa, valor } = solicitation;

    const body = {
      identificaoDespesa,
      valor,
    };

    return handlePost(url, body, HttpAccess);
  }

  public startSolicitationLiberacaoLimite(
    solicitation: ISolicitationLiberacaoLimiteContract
  ) {
    const url = `${this.abstractService.getBaseUrl()}/app/startSolicitationLiberacaoLimite`;
    const { customer } = solicitation;

    const body = {
      customer,
    };

    return handlePost(url, body, HttpAccess);
  }

  public startSolicitationProdutoSemEstoque(
    solicitation: ISolicitationProdutoSemEstoqueContract
  ) {
    const url = `${this.abstractService.getBaseUrl()}/app/startSolicitationLiberacaoProdutoSemEstoque`;
    const { name, barcode, quantity } = solicitation;

    const body = {
      name,
      barcode,
      quantity
    };

    return handlePost(url, body, HttpAccess);
  }

  public startSolicitationLoteRecente(
    solicitation: ISolicitationLoteRecenteContract
  ) {
    const url = `${this.abstractService.getBaseUrl()}/app/startSolicitationLiberacaoLoteRecente`;
    const { name, barcode, quantity } = solicitation;

    const body = {
      name,
      barcode,
      quantity
    };

    return handlePost(url, body, HttpAccess);
  }

  public startSolicitationCoberturaOferta(
    solicitation: ISolicitationCoberturaOfertaContract
  ) {
    const url = `${this.abstractService.getBaseUrl()}/app/startSolicitationCoberturaOferta`;
    const { valorAtual, valorNovo } = solicitation;

    const body = {
      valorAtual,
      valorNovo,
    };

    return handlePost(url, body, HttpAccess);
  }

  public startSolicitationExcluirPreVenda(
    solicitation: ISolictationExcluirPreVendaContract
  ) {
    const url = `${this.abstractService.getBaseUrl()}/app/startSolicitationExcluirPreVenda`;
    const { codigo, valor } = solicitation;

    const body = {
      codigo,
      valor,
    };

    return handlePost(url, body, HttpAccess);
  }

  public showAutorizationOnline() {
    const url = `${this.abstractService.getBaseUrl()}/app/showAutorizationOnline`;

    return handlePost(url, {}, HttpAccess);
  }

  public cancelSolicitation(solicitationId: string) {
    const url = `${this.abstractService.getBaseUrl()}/app/cancelSolicitation`;

    const body = {
      solicitationId,
    };

    return handlePost(url, body, HttpAccess);
  }
}
