import styled from 'styled-components';

import theme from '../../../themes/default';
import theme2 from '../../../themes/theme2';

export const CardWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: ${theme.panelSize.centralized};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: ${theme.palette.grayscale.white};
  min-height: 300px;
  height: 100%;
  overflow: hidden;
  margin: 15px;

  ${(props) => (props.shouldFlex ? 'flex: 1 1 auto' : '')}

  .card-body {
    flex: 1 1 auto;
    ${(props) => (props.shouldFlex ? 'flex: 1 1 auto' : '')}
    ${(props) => (props.autoScroll ? 'overflow: auto' : '')}
  }

  &.loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
`;

export const CardTitle = styled.header`
  display: flex;
  flex-direction: row;
  flex: 0 0 40px;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 0 2px;
  margin-top:  ${theme2.metrics.basePadding * 3 - 2}px;
  margin-left:  ${theme2.metrics.basePadding * 3 - 2}px;
  margin-right:  ${theme2.metrics.basePadding * 3 - 2}px;
  ${(props) =>
    props.marginButtonTitle ? `margin-bottom: ${props.marginButtonTitle}` : ''}

  .container-title {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;

    .title {
      font-family: 'Dosis';
      position: initial;
      flex: 1;
      font-size: ${theme.textPrimary.size};
      text-transform: ${theme.text.uppercase};
      max-width: initial;
      margin-bottom: 0;
      color: #000000;
      line-height: 32px;
    }

    .sub-title {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 138.2%;
      text-align: center;
      color: #9e9e9e;
    }
  }

  .title {
    ${(props) => (!props.hasIcon ? 'position: absolute' : '')}
    max-width: max-content;
    flex: 1;
    font-size: ${theme.textPrimary.size};
    text-transform: ${theme.text.uppercase};
  }

  .sub-title {
    font-size: 18px;
    text-transform: initial;
    color: #dcdcdc;
    margin-left: 10px;
  }

  img {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  button {
    text-decoration: none;
    color: #9e9e9e;
    background-color: white;
    border: 0;
    outline: none;
    font-size: 10px;
    cursor: pointer;
    font-family: Arial;
    padding: 0 ${theme2.metrics.basePadding}px;

    &:first-of-type {
      margin-left: auto;
    }

    &:last-child {
      padding-right: 0;
    }

    &:hover {
      color: ${theme.palette.primary[0]};
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
`;

export const CardFooter = styled.footer`
  display: flex;
  flex-direction: row;
  flex: 0 0 80px;
`;