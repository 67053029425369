import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { overlayService } from '../overlay';
import { BackModalWraper, ModalWraper, ModalHeader, ModalBody, ModalFooter } from './modal.style';

export class Modal extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    closable: PropTypes.bool,
    onOpen: PropTypes.func,
    children: PropTypes.any,
    closeOnOverlayClick: PropTypes.bool
  };

  static defaultProps = {
    closable: false,
    children: null,
    opened: false,
    closeOnOverlayClick: false,
    className: null,
    style: {}
  };

  static Body = ModalBody;
  static Footer = ModalFooter;

  constructor(props) {
    super(props);

    this.state = {
      show: !!props.opened
    }

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    if (!this.props.noOverlay)
      overlayService.hideOverlay();

    document.removeEventListener('keydown', this.handleKeyPress);
    document.removeEventListener('click', this.handleClick);
  }

  handleKeyPress(e) {
    if (e.key === 'Escape' && (this.props.closable || this.props.closeOnOverlayClick)) {
      this.close();
    }
  }

  handleClick(e) {
    if (!this.props.closeOnOverlayClick) return;
    e.target === this.wrapperRef && this.close();
  }

  focus = () => {
    if (!this.props.noOverlay)
      overlayService.focusOverlay();
  }

  open = () => {
    this.setState({
      show: true
    }, () => {
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    });
    if (!this.props.noOverlay)
      overlayService.showOverlay();
  }

  close = () => {
    if (this.props.onClickClose)
      this.props.onClickClose();
    this.hide();
  }

  hide = () => {
    this.setState({
      show: false
    });

    this.props.handleClose && this.props.handleClose();
    if (!this.props.noOverlay)
      overlayService.hideOverlay();
  }

  render() {
    const { children, title } = this.props;

    return (
      this.state.show && (
        <BackModalWraper style={{ zIndex: this.props.zIndex }} id={this.props.id} innerRef={ref => this.wrapperRef = ref} className="modal-back-wrapper">
          <ModalWraper style={this.props.style} className={'modal-wrapper ' + this.props.className || ''}>
            <ModalHeader className="modal-header">
              <span className="title">{title}</span>
              <span className="close">
                {this.props.closable && (
                  <i onClick={this.close} className="material-icons unselectable">close</i>
                )}
              </span>
            </ModalHeader>
            {children}
          </ModalWraper>
        </BackModalWraper>
      )
    );
  }
}