import React from 'react';
import { connect } from 'react-redux';
import {
  TRNCENTRE_RESUME,
  showCard,
  shouldShow,
  closeAllCards,
} from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { TrnCentreResumeComponent } from '../../shared/components/modules';
import PbmSaleService from '../../services/pbmSaleService';

export class TrnCentreResumeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false
    };

    this.props.MessageBus.Subscribe('pos.trncentre.showResume', this.handleShow);
    this.props.MessageBus.Subscribe('pos.trncentre.getResumeDetail', this.handleGetResumeDetail);
    this.props.MessageBus.Subscribe('pos.trncentre.resumeChanged', this.handleResumeChanged);
  }

  handleShow = (ch, payload) => {
    this.props.open();
    this.setState({
      payload: payload,
    });
  };

  handleResumeChanged = (ch, payload) => {
    PbmSaleService.startTrnCentreResume();
  };

  handleGetResumeDetail = (ch, payload) => {
    let newPayload = this.state.payload;
    let newSales = newPayload.sales.map((sale) => {
      if (sale.transaction.transactionCode === payload.transaction.transactionCode) {
        return {
          ...payload,
          showDetail: !sale.showDetail
        }
      }
      return {
        ...sale
      }
    });
    newPayload.sales = newSales;
    this.setState({
      payload: newPayload,
    });
  };

  handleClose = () => {
    this.props.close();
  };

  handleClickDetail = (sale) => {
    PbmSaleService.trnCentreGetResumeDetail(sale);
  };

  handleClickAction = (saleSelected) => {
    let newPayload = this.state.payload;
    let newSales = newPayload.sales.map((sale) => {
      if (sale.transaction.transactionCode === saleSelected.transaction.transactionCode) {
        return {
          ...saleSelected,
          showActions: !sale.showActions
        }
      }
      return {
        ...sale,
        showActions: false
      }
    });
    newPayload.sales = newSales;
    this.setState({
      payload: newPayload,
    });
  };

  handleCancelTransaction = (transactionCode) => {
    PbmSaleService.trnCentreManualCancelTransaction(transactionCode);
  };

  handleConfirmTransaction = (transactionCode) => {
    PbmSaleService.trnCentreManualConfirmTransaction(transactionCode);
  };

  render() {
    return (
      this.props.visible && (
        <TrnCentreResumeComponent
          title="Portal da Drogaria - Extrato"
          handleClose={this.handleClose}
          payload={this.state.payload}
          handleClickDetail={this.handleClickDetail}
          handleCancelTransaction={this.handleCancelTransaction}
          handleConfirmTransaction={this.handleConfirmTransaction}
          handleClickAction={this.handleClickAction}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  visible: shouldShow(state, TRNCENTRE_RESUME)
});

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(showCard(TRNCENTRE_RESUME, null, true)),
  close: () => dispatch(closeAllCards())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(TrnCentreResumeContainer)));
