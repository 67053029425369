import styled from 'styled-components';
import theme2 from '../../../../../themes/theme2';

export const UndoValuesMovimentType = styled.h3`
  font-size: 1rem;
  font-weight: 600;

  padding: 10px 5px;
`;

export const UndoValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 50%;
  margin-bottom: 30px;

  .caption {
    margin-bottom: 5px;
  }

  .check-values {
    width: 100%;
    overflow-y: auto;
    max-height: 186px;
  }

  .subtotal {
    width: 100%;
    text-align: end;
    font-size: 1.1rem;
    font-weight: 600;
    border-top: 2px solid gray;
    margin-top: 5px;
    padding-right: 33px;
  }
`;

interface UndoValuesProps {
  barcode: boolean;
}

export const UndoValues = styled.div<UndoValuesProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
  margin: 3px 0;
  font-size: 1rem;

  i {
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: ${({ barcode }) => (barcode ? 'unset' : 'pointer')};

    img {
      width: 21px;
    }
  }
`;
