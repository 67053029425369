import React, { Fragment } from 'react';

import KeyboardEventHandler from 'react-keyboard-event-handler';

import getShortcut, { SET_USER } from '../../../keyboardShortcuts.js';
import LabelTitleValue from '../label-title-value';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';
import OperatorWrapper from './style';

function nome(str) {
  var arr = str.split(' ');
  let name = arr[0][0];
  if (arr.length > 1)
    name = name + arr[1][0]
  return name;
}

const OperatorInput = ({
  handleClickEscape,
  handleKeyPress,
  handleClickToogleMenu,
  handleOptionChangeUser,
  handleOptionLogout,
  textInput,
  operatorName,
  isExpanded,
  isToggleOnInputOperator,
  hasUserLogged,
}) => (
  <OperatorWrapper>
    <div
      id='identifier-operator'
      className='operator-area label-operator'
      tabIndex='0'
    >
      <ShortcutHint
        shortcut={getShortcut(SET_USER)}
        activateShortcut={handleOptionChangeUser}
      />
      <KeyboardEventHandler
        handleKeys={['esc']}
        handleFocusableElements
        onKeyEvent={handleClickEscape}
      />
      {isToggleOnInputOperator ? (
        <input
          id='operator'
          ref={textInput}
          type='text'
          className='operator-input'
          placeholder='Código do operador'
          onKeyPress={(e) => handleKeyPress(e)}
          autoFocus
          onClick={handleOptionChangeUser}
        />
      ) : (
        <Fragment>
          <div onClick={handleClickToogleMenu} className='div-user-name'>
            {operatorName && <div className='div-name'><p>{nome(operatorName)}</p></div>}
            <LabelTitleValue
              className='identifier-operator-label'
              title=''
              value={operatorName || 'IDENTIFICAR OPERADOR'}
              textClass='active'
              icon={operatorName === null || operatorName === "" || operatorName === undefined}
              changeArrow={!isExpanded}
            />
            <div className="icon-align">
              <i className="material-icons">{isExpanded ? 'expand_less' : 'expand_more'}</i>
            </div>
          </div>
          {isExpanded &&
            <Fragment>
              <div className='menu-area'>
                <span id='change' onClick={handleOptionChangeUser}>
                  <img src='./assets/images/trocar-usuario.svg' alt='Trocar Usuário' />
                  Trocar Usuário
                </span>
                <hr />
                <span id='logoff' onClick={handleOptionLogout}>
                  <i className='material-icons'>input</i>
                  Sair
                </span>
              </div>
            </Fragment>
          }
        </Fragment>
      )}
    </div>
  </OperatorWrapper>
);

export default OperatorInput;
