import styled from 'styled-components';

export const ShortcutHintWrapper = styled.span`
    position: absolute;
    width: 35px;
    line-height: initial;
    text-align: center;
    font-size: 14px !important;
    z-index: 20;
    height: 17px !important;
    font-weight: bold !important;
    opacity: 0.8;
    font-family: Arial;
    background: gray;
    color: white;
    border-radius: 5px;
`;