import styled from 'styled-components';
import theme from '../../../themes/default';

export const OptionListWrapper = styled.div`
  font-family: Dosis;
  display: flex;
  flex-direction: column;
  height: min-content !important;

  .esc-option {
      text-decoration: none;
      align-self: flex-end;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 17px;
    text-transform: uppercase;
    padding: 7px;
    align-self: center;
  }
`;

export const OptionsWrapper = styled.div`
  font-family: Dosis;
  display: flex;
  flex-direction: row;
  min-height: 100%;

  .option-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    max-height: 25px;
    
    color: ${theme.palette.primary[14]};
    text-decoration: none;
    text-transform: ${theme.text.uppercase};
    padding: 6px 8px 6px 8px;
    font-weight: 300;
    border-radius: 16px;

    i {
      width: 24px;
      height: 24px;
    }
    
    &.disabled {
      pointer-events: none;
      touch-action: none;
      outline: none;
      color: #c7c7c7;
    }

    transition:
      background 260ms ease-out,
      border-color 260ms ease-out,
      color 260ms ease-out,
      box-shadow 260ms ease-out,
      font-weight 260ms ease-out;
  }

  .option-text:hover {
    background-color: #EEEEEE;
    
    cursor: pointer;
  }
`
