import { AbstractService } from './abstractService';

export class ProductService extends AbstractService {
    constructor() {
        super('/Product');
    }

    startProductSearch = () => this.invokeAction('/startShowCaseProduct');

    cancelShowCaseProduct = () => this.invokeAction('/cancelShowCaseProduct');

    listProductsByCriteria = (criteria, page, pageCount, criteriaType = 1) => this.invokeAction('/listProductsByCriteria', { criteria: criteria, offset: page, pageSize: pageCount, criteriaType });

    getProductsCache = () => this.invokeAction('/getProductsCache', null, false);

    getQuantityByBarcodes = (codes) => this.invokeAction('/getQuantityAndValueByBarcodes', { barCodes: codes }, false);

    getStock = (referenceId) => this.invokeAction('/getStock', { productReferenceId: referenceId }, false);
}

export default ProductService;