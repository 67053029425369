import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { LinxConectaProducts } from '../../shared/components/modules';
import LinxConectaService from '../../services/linxConectaService';
import { showToast } from '../../shared/utils/util';
import SaleService from '../../services/saleService';

export class LinxConectaProductsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.saleService = new SaleService();

    this.state = {
      visible: false,
      productList: [],
      shouldShowWarningModal: false,
      valorTotalBruto: 0,
      valorTotalLiquido: 0,
      fidelidade: false,
      showPecPbmModal: false,
      selectedItem: null,
    };

    this.subscription = null;

    this.props.MessageBus.Subscribe('pos.linxConecta.validarProdutosList', this.handleProductList);
    this.props.MessageBus.Subscribe('pos.linxConecta.descontosClose', this.handleClose);

  }

  handleProductList = (ch, payload) => {
    this.setState({
      visible: true,
      productList: payload.products,
      messageToShow: payload.mensagem,
      valorTotalBruto: payload.valorTotalBruto,
      valorTotalLiquido: payload.valorTotalLiquido,
      fidelidade: payload.fidelidade
    });
  }

  handleShowMessage = () => {
    if (!this.state.messageToShow) return;
    showToast({
      type: 3,
      title: 'Linx Conecta',
      html: this.state.messageToShow.replace(/\|/g, '<br/>'),
      hideAfterMilliseconds: 9000
    });
  }

  handleOrderUpdated = () => {
    this.props.MessageBus.Unsubscribe('pos.linxConecta.itemUpdated', this.handleOrderUpdated);
    if (!this.state.visible)
      return
    LinxConectaService.validarProdutos();
  }

  handleConfirm = () => {
    if (this.state.productList.some(product => product.status === 2) && !this.state.shouldShowWarningModal) {
      this.setState({ shouldShowWarningModal: true });
      return;
    }
    LinxConectaService.finalizarVenda();
    this.handleClose();
  }

  handleConfirmBack = () => {
    this.handleClose();
  }

  handleWarningProceed = () => {
    this.handleConfirm();
  }

  handleWarningReturn = () => {
    this.handleConfirmBack();
  }

  handleClose = () => {
    this.setState({
      visible: false,
      productList: [],
      shouldShowWarningModal: false,
      valorTotalBruto: 0,
      valorTotalLiquido: 0,
      fidelidade: false,
      showPecPbmModal: false,
      selectedItem: null,
    });
  }

  handleChangeQtt = (e) => {
    this.setState({
      productList: this.state.productList.map((item) => {
        if (item.barcode === e.target.id && e.target.value > 0) {
          item.quantidade = e.target.value;
          item.valorDesconto = (item.quantidade * item.valorUnitarioBrutoProduto) - (item.quantidade * item.valorUnitarioLiquidoProduto);
          if (item.appliedDiscount)
            item.appliedDiscount.totalDiscountValue = parseFloat(item.valorDesconto.toFixed(2));
          const payload = {
            barcode: item.barcode,
            quantity: item.quantidade,
            appliedDiscount: item.appliedDiscount,
            sequentialId: item.sequentialId,
            isFromPbm: item.isFromPbm
          };
          this.props.MessageBus.Subscribe('pos.linxConecta.itemUpdated', this.handleOrderUpdated);
          this.saleService.updateItem(payload).catch(error => this.context.showMessage(error));

        }
        return item;
      })
    });
  }

  handleShowMessageProd = (e) => {
    this.state.productList.map((item) => {
      if (item.barcode === e.target.id) {
        this.context.showMessage(item.mensagem ?? 'Nenhum retorno da administradora encontrado para esse produto.');
      }
      return item;
    });
  }

  handlePecPbmModal = (item) => {
    this.setState({
      showPecPbmModal: true,
      selectedItem: item,
    })
  }

  togglePecPbmModal = () => {
    this.setState({
      showPecPbmModal: !this.state.showPecPbmModal,
    })
  }

  handleShowMessageCoupom = (e) => {
    this.context.showMessage(this.state.messageToShow);
  }

  render() {
    return (this.state.visible &&
      <Fragment>
        <LinxConectaProducts
          productList={this.state.productList}
          messageToShow={this.state.messageToShow}
          handleConfirm={this.handleConfirm}
          handleClose={this.handleClose}
          handleShowMessage={this.handleShowMessage}
          customerName={this.props.customerName}
          showWarningModal={this.state.shouldShowWarningModal}
          handleWarningProceed={this.handleWarningProceed}
          handleWarningReturn={this.handleWarningReturn}
          valorTotalBruto={this.state.valorTotalBruto}
          valorTotalLiquido={this.state.valorTotalLiquido}
          fidelidade={this.state.fidelidade}
          message={this.state.messageToShow}
          handleChangeQtt={this.handleChangeQtt}
          handleShowMessageProd={this.handleShowMessageProd}
          handleShowMessageCoupom={this.handleShowMessageCoupom}
          handleConfirmBack={this.handleConfirmBack}
          handlePecPbmModal={this.handlePecPbmModal}
          showPecPbmModal={this.state.showPecPbmModal}
          selectedItem={this.state.selectedItem}
          togglePecPbmModal={this.togglePecPbmModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerName: state.order.order?.customer && state.order.order?.customer.name
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(LinxConectaProductsContainer)));
