import HttpAccess from './httpAccess';
import Configuration from '../configuration';
import handlePost from '../shared/utils/handlePost';
import AbstractService from './abstractService';

export class PaymentService extends AbstractService {
  constructor(httpAccess = HttpAccess, configuration = Configuration) {
    super('/payment');
    this.httpAccess = httpAccess;
    this.baseEndpoint = configuration.endPoint;
    this.baseEndpointContingency = configuration.endPointContingency;
    this.retailerId = configuration.retailerId;
    this.baseUrlMock = configuration.saleMock;
    this.posID = configuration.PosId;
  }

  getPosConnectConfig = () => this.invokeAction('/GetPosConnectConfig');

  sendToPosConnect = (orderId) => this.invokeAction('/posConnectCreateOrder', { orderId });

  setPosConnectConfig = (posConnectConfig) => this.invokeAction('/SetPosConnectConfig', { posConnectConfig });

  newReprintTef = (orderId) => this.invokeAction('/reprintTefCoupons', { orderId })

  // alternate endpoints in case of PBM sales
  startPayment = (alternateEndpoint) => {
    const url = `${this.getBaseUrl()}/payment/${alternateEndpoint || "start"}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
    };
    return handlePost(url, body, this.httpAccess);
  };

  startPaymentWithPbmPatient = (patient) => {
    const url = `${this.getBaseUrl()}/payment/startPbm`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      ...patient
    };
    return handlePost(url, body, this.httpAccess);
  };

  cancelPaymentOnOrder = (nsu) => {
    const url = `${this.getBaseUrl()}/payment/cancelPaymentOnOrder`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      nsu: nsu,
    };

    return handlePost(url, body, this.httpAccess);
  }

  inputBrand = (brand, amount) => {
    const url = `${this.getBaseUrl()}${brand.url}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      brandId: brand.id,
      amount,
    };
    return handlePost(url, body, this.httpAccess);
  }

  setParcelCreditPayment = (installment) => {
    const url = `${this.getBaseUrl()}${installment.url}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      installment: installment.number,
    };
    return handlePost(url, body, this.httpAccess);
  }

  setCodeCardPayment = (creditDetails, authorizationCode) => {
    const url = `${this.getBaseUrl()}${creditDetails.url}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      authorizationCode,
    };
    return handlePost(url, body, this.httpAccess);
  }

  setMoneyInput = (amount, urlSend) => {
    const url = `${this.getBaseUrl()}${urlSend}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      amount,
    };
    return handlePost(url, body, this.httpAccess);
  }

  setPixInput = (amount, urlSend) => {
    const url = `${this.getBaseUrl()}${urlSend}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      amount,
    };
    return handlePost(url, body, this.httpAccess);
  }

  donePayment = () => {
    const url = `${this.getBaseUrl()}/payment/done`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
    };
    return handlePost(url, body, this.httpAccess);
  }

  doneCreditTefPayment = (data) => {
    const url = `${this.getBaseUrl()}/payment/credit/tef/authorization`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      ...data,
    };
    return handlePost(url, body, this.httpAccess);
  }

  doneDebitTefPayment = (data) => {
    const url = `${this.getBaseUrl()}/payment/debit/tef/authorization`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      ...data,
    };
    return handlePost(url, body, this.httpAccess);
  }

  couponAuthorization = (data) => {
    console.log(data);
    const url = `${this.getBaseUrl()}/payment/couponauthorization`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      ...data,
    };
    return handlePost(url, body, this.httpAccess);
  }

  addCpfCnpj = (documentNumber) => {
    const body = {
      documentNumber,
      retailerId: this.retailerId,
      posId: this.posID,
    };
    const url = `${this.getBaseUrl()}/payment/documentNumber`;
    return handlePost(url, body, this.httpAccess);
  }

  validarFormaPgto = (formapagto, valoraut) => {
    const url = `${this.getBaseUrl()}/payment/ValidarFormaPagto`;
    const body = {
      formapagto,
      valoraut
    };
    return handlePost(url, body, this.httpAccess);
  }

  backPayment = () => {
    const url = `${this.getBaseUrl()}/payment/back`;
    return handlePost(url, null, this.httpAccess);
  }

  linxConectaBack = () => {
    const url = `${this.getBaseUrl()}/payment/linxConectaBack`;
    return handlePost(url, null, this.httpAccess);
  }

  getFormOfPaymentList = (responseRk) => {
    const url = `${this.getBaseUrl()}/payment/getFormOfPaymentList`;
    return handlePost(url, { responseRk }, this.httpAccess);
  }

  selectFormOfPayment = (id) => {
    const body = {
      id,
    };
    const url = `${this.getBaseUrl()}/payment/SetFormOfPayment`;
    return handlePost(url, body, this.httpAccess);
  }

  reprintCouponTef = (orderId) => {
    const url = `${this.getBaseUrl()}/payment/credit/tef/coupons/print`;
    const body = {
      orderId,
    };
    return handlePost(url, body, this.httpAccess);
  }

  reprintTef = () => {
    if (window.desktopApp && window.desktopApp.publish) {
      // Mensagem recebida após a impressão do último recibo de cartão
      const token = window.desktopApp.subscribe('machine.devices.tef.printlast.completed', (channel, data) => {
        window.desktopApp.unsubscribe(token);
      });

      // Envia mensagem solicitando a impressão do último recibo de cartão
      window.desktopApp.publish('machine.devices.tef.printlast', {});
    }
  };

  tefDebitCardBrand = (data) => {
    const url = `${this.getBaseUrl()}/payment/debit/tef/brand`;
    return handlePost(url, data, this.httpAccess);
  }

  tefCreditCardBrand = (data) => {
    const url = `${this.getBaseUrl()}/payment/credit/tef/brand`;
    return handlePost(url, data, this.httpAccess);
  }

  paymentError = (error) => {
    const url = `${this.getBaseUrl()}/payment/error`;
    const body = {
      error,
    };
    return handlePost(url, body, this.httpAccess);
  }

  paymentQrError = () => {
    const url = `${this.getBaseUrl()}/payment/qrerror`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID
    };
    return handlePost(url, body, this.httpAccess);
  }

  undoPayment = (data) => {
    const url = `${this.getBaseUrl()}/payment/remove`;
    const body = {
      retailerId: this.retailerId,
      posId: this.posID,
      ...data,
    };
    return handlePost(url, body, this.httpAccess);
  }

  pay = (paymentType) => {
    const url = `${this.getBaseUrl()}${paymentType.url}`;
    const body = {
      retailerId: this.retailerId,
      posId: this.PosId,
      paymentTypeId: paymentType.id,
      amount: paymentType.availableAmount
    };

    return handlePost(url, body, this.httpAccess);
  }

  tefTransactionsSucessfullConfirmed = () => {
    const url = `${this.getBaseUrl()}/payment/tefTransactionsSucessfullConfirmed`;
    const body = {
      retailerId: this.retailerId,
      posId: this.PosId
    };

    return handlePost(url, body, this.httpAccess);
  }
}

export default PaymentService;
