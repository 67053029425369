import { AbstractService } from './abstractService';

export const PBM_EPHARMA = 73000;
export const PBM_SEVEN = 75000;
export const PBM_FUNCIONAL_CARD = 76000;
export const PBM_ORIZON = 77000;
export const PBM_PAYMENT_ENDPOINT = 'startPbm';

class PbmSaleService extends AbstractService {
  constructor() {
    super('/PbmSale');
  }

  getInitializationData = (pbmEnum) =>
    this.invokeAction('/initialization', { pbmEnum });

  closeInitialization = (pbmEnum) =>
    this.invokeAction('/closeInitialization', { pbmEnum });

  closePatientSelection = (pbmEnum) =>
    this.invokeAction('/closeSelectPatient', { pbmEnum });

  startSale = (pbmEnum, form, paymentEndpoint = PBM_PAYMENT_ENDPOINT) =>
    this.invokeAction('/start', { pbmEnum, paymentEndpoint, ...form });

  applyPbmProducts = (receitas) =>
    this.invokeAction('/applyPbmProducts', { receitas });

  closeItemsToApply = () => this.invokeAction('/closeItemsToApply');

  startSevenPBM = () => this.invokeAction('/startSevenPBM');

  startTrnCentre = () => this.invokeAction('/startTrnCentre');

  startTrnCentreResume = () => this.invokeAction('/trnCentreStartResume');

  trnCentreGetResumeDetail = (sale) => this.invokeAction('/trnCentreGetResumeDetail', sale);

  trnCentreManualConfirmTransaction = (transactionCode) => this.invokeAction(`/trnCentreConfirmTransaction/${transactionCode}`);

  trnCentreManualCancelTransaction = (transactionCode) => this.invokeAction(`/trnCentreCancelTransaction/${transactionCode}`);

  sevenRecoverAuthorization = (authorizationCode) => this.invokeAction('/sevenRecoverAuthorization', { authorizationCode });

  openAuthorizationList = () => this.invokeAction('/openAuthorizationList');

  closeAuthorizationList = () => this.invokeAction('/closeAuthorizationList');

  filterAuthorizations = (filter) =>
    this.invokeAction('/filterAuthorizations', filter);

  cancelPbm = (authorization, pbmEnum, chaveNFCe, orderId) =>
    this.invokeAction('/cancelPbm', {
      authorization,
      pbmEnum,
      chaveNFCe,
      orderId,
    });

  reprintCoupon = (authorization, pbmEnum) =>
    this.invokeAction('/reprintCoupon', { authorization, pbmEnum });

  uploadPrescription = (authorization, pbmEnum, orderId, receitas) =>
    this.invokeAction('/uploadPrescription', {
      pbmEnum,
      authorization,
      orderId,
      receitas,
    });

  getConfig = (retailerId, pbmEnum) =>
    this.invokeAction('/getConfig', { retailerId, pbmEnum });

  getConfigTrnCentre = () => this.invokeAction('/getConfigTrnCentre');

  trnCentreConfirmTransaction = (cpf, cardNumber, transactionCode) => this.invokeAction('/trnCentreConfirmTransaction', { cpf, cardNumber, transactionCode });

  setConfigTrnCentre = (config) => this.invokeAction('/setConfigTrnCentre', config);

  loadTableTrnCentre = () => this.invokeAction('/loadTableTrnCentre');

  updateConfig = (config) => this.invokeAction('/updateConfig', config);

  sevenProcessRecoveredFile = (file) => this.invokeAction('/sevenProcessRecoveredFile', { file });

  validateConfig = (config) => this.invokeAction('/validateConfig', config);

  sevenApplyProducts = (preAuthorizationResponseContract) => this.invokeAction('/sevenApplyProducts', preAuthorizationResponseContract);

  trnCentreApplyProducts = (finalize) => this.invokeAction('/trnCentreApplyProducts', { finalize });

  finalizeSale = () => this.invokeAction('/finalizeSale');

  recoverAuthorization = (documentNumber, createdAt, authorization, pbmEnum) =>
    this.invokeAction('/recoverAuthorization', {
      documentNumber,
      createdAt,
      authorization,
      pbmEnum,
    });

  reverse = (orderId) => this.invokeAction('/cancelOnlyPbm', { orderId });
}

export default new PbmSaleService();
