import styled from 'styled-components';

const PdvInformationWrapper = styled.div`
  .TitleWrapper {
    margin: 0 20px 0;
    display: flex;
    line-height: 0.8;
    align-items: center;
    color: #5b2e90;
    
    span {
      margin-left: 2px;
    }
  }
`;

export default PdvInformationWrapper;
