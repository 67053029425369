import React, { Fragment } from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules'
import './order-validation-component.style.scss';
import AuthorizationValidation from '../authorization-validation/authorization-validation-component';
import { OperationEnum } from '../../enums/operation';

export class OrderValidation extends React.Component {

  constructor(props) {
    super(props);

    this.modalConfirm = React.createRef();

  }

  getRow = (validation) => {
    if (validation?.type === 0) {
      return (
        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i><div className='divDescription'>Suas configurações não permitem a venda de produtos sem estoque sem autorização.</div>
            <div className='divInfos'>
              <div className='divInfosCol'>
                <div className='divProductColuns'>
                  <div className='cProd'><b>Produto</b></div>
                  <div className='cQtde'><b>Quantidade</b></div>
                </div>
                <div className='divProductDescription'>
                  <div className='cProd'>{validation?.product?.barcode} - {validation?.product?.name}</div>
                  <div className='cQtde'>{validation.product.quantity}</div>
                </div>
              </div>
              <Button
                className="btn-default btn-purple"
                type="button"
                onClick={(e) => this.props.showAuthorizationClick({ barcode: validation?.product?.barcode, name: validation?.product?.name, quantity: validation?.product?.quantity }, OperationEnum.ProdutoSemEstoque, validation?.type)}
              >
                SOLICITAR AUTORIZAÇÃO
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (validation?.type === 1) {
      return (
        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i><div className='divDescription'>Para finalizar o pedido é necessário selecionar uma forma de pagamento:</div>
            <div className="icon-align">
              <div
                className='divFormOfPayment'
                onClick={this.props.paymentMethodClick}
              ><h6 className='description'>SELECIONE A FORMA DE PAGAMENTO</h6> <i className="material-icons">{this.props.isToggleOnOption ? 'expand_less' : 'expand_more'}</i></div>
            </div>
            {this.props.isToggleOnOption && (
              <div className="menu-area">
                {this.props.listFormPayment && this.props.listFormPayment.map(payment => (
                  <span
                    className="payment-option"
                    key={payment.id}
                    id={payment.id}
                    onClick={this.props.clickFormPayment}
                    tabIndex="0"
                  >
                    {payment.name}
                  </span>
                ))
                }
              </div>
            )}
          </div>
        </div>
      );
    }
    if (validation?.type === 2) {
      return (

        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i><div className='divDescription'>Para finalizar o pedido é necessário informar o vendedor:</div>
            <div className='divSolution'>
              <div className='divSellerInfo'>
                <input
                  type='number'
                  className='operator-input'
                  onChange={(e) => this.props.sellerSelectChange(e)}
                  placeholder='Digite o código do vendedor'
                />
                <Button
                  className="btn-default btn-purple"
                  type="button"
                  onClick={this.props.handleConfirmIdentifySeller}
                >
                  CONFIRMAR
                </Button>
              </div>
            </div>
          </div>
        </div >
      );
    }
    if (validation?.type === 3) {
      return (

        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i>
            <div className='divCustomerInfo'>
              <div className='divDescription'>Nesta venda é necessário selecionar um consumidor.</div>
              <Button
                className="btn-default btn-purple"
                type="button"
                onClick={this.props.selectCustomerClick}
              >
                SELECIONAR CONSUMIDOR
              </Button>
            </div>
          </div>
        </div >
      );
    }

    if (validation?.type === 4) {
      return (

        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i><div className='divDescription'>Não foi informado consumidor e a filial não possui um consumidor final padrão configurado.
              Acesse o cadastro de filial no sistema de retaguarda e verifique.</div>
          </div>
        </div>
      );
    }

    if (validation?.type === 5) {
      return (

        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i>
            <div className='divHasNoProductInfo'>
              <div className='divDescription'>Para finalizar o pedido é preciso informar pelo menos um item.</div>
              <Button
                className="btn-default btn-purple hasNoProductButton"
                type="button"
                onClick={this.props.handleClose}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (validation?.type === 6) {
      return (

        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i>
            <div className='divHasNoProductInfo'>
              <div className='divDescription'>Existem produtos controlados sem as informações do receituário.</div>
              <Button
                className="btn-default btn-purple hasNoProductButton"
                type="button"
                onClick={this.props.handleClose}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (validation?.type === 7) {
      return (
        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i><div className='divDescription'>Suas configurações não permitem a venda de produtos com lotes de vencimento mais recente sem autorização.</div>
            <div className='divInfos'>
              <div className='divInfosCol'>
                <div className='divProductColuns'>
                  <div className='cProd'><b>Produto</b></div>
                  <div className='cQtde'><b>Quantidade</b></div>
                </div>
                <div className='divProductDescription'>
                  <div className='cProd'>{validation?.product?.barcode} - {validation?.product?.name}</div>
                  <div className='cQtde'>{validation.product.quantity}</div>
                </div>
              </div>
              <Button
                className="btn-default btn-purple"
                type="button"
                onClick={(e) => this.props.showAuthorizationClick({ barcode: validation?.product?.barcode, name: validation?.product?.name, quantity: validation?.product?.quantity }, OperationEnum.LoteMaisRecente, validation?.type)}
              >
                SOLICITAR AUTORIZAÇÃO
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (validation?.type === 8) {
      return (

        <div className='divCard'>
          <div className='divTitulo'>{validation?.description}</div>
          <div className='divBorder'>
            <i className="material-icons">info</i>
            <div className='divHasNoProductInfo'>
              <div className='divDescription'>Existem produtos que exigem lote sem o lote informado.</div>
              <Button
                className="btn-default btn-purple hasNoProductButton"
                type="button"
                onClick={this.props.handleClose}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    if (this.modalConfirm.current !== null) this.modalRef.current.open();
  }

  componentWillUnmount() {
    this.props.handleClose();
    if (this.modalConfirm.current !== null) this.modalConfirm.current.close();
  }

  render() {
    const {
      handleClose,
      validationList,
      showAuthorization,
      handleCloseAuthorization,
      handleAuthorizeSucces,
      showModal,
      solicitation,
      showAutorizationOnline,
      modalRef,
      operationType
    } = this.props;

    if (this.modalConfirm.current !== null)
      if (showModal)
        this.modalConfirm.current.open();
      else
        this.modalConfirm.current.close();

    return (
      <Fragment>
        {showAuthorization ?
          <AuthorizationValidation
            showAutorizationOnline={showAutorizationOnline}
            handleClose={handleCloseAuthorization}
            handleAuthorizeSucces={handleAuthorizeSucces}
            operationType={operationType}
            solicitation={solicitation}
          >
          </AuthorizationValidation>
          :
          ""
        }
        <Modal
          closeOnOverlayClick
          ref={modalRef}
          opened={true}
          title=""
          onClickClose={handleClose}
          className="modalValidation"
          id="modalValidation"
          key="modalValidation"
        >
          <Modal.Body
            key='ModalBody'
            className='ModalBody'>
            {/* {validationList.map(this.getRow)} */}
            {(this.getRow(validationList[0]))}
            {(this.getRow(validationList[1]))}
            {(this.getRow(validationList[2]))}
          </Modal.Body>
        </Modal>

      </Fragment >
    );
  }
}

export default OrderValidation;