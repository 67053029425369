import styled from 'styled-components';

const focusStyle = `
  &:hover, &:focus {
    cursor:pointer;
    color:black;
    font-weight: bold;
    background-color: #FFB200;
    tab-index: 5;
  }
`;

export const ItemRender = styled.span`
  display: flex;
  flex: 0 0 50px;
  height: 50px;
  border-top: 1px solid #e4e0e0;
  align-items: center;
  padding-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  
  .left {
    width: 60%;
  }

  .right {
    span {
      padding-right: 8px;
    }
  }

  ${focusStyle}
`;