import styled from 'styled-components';


export const ModalWrapper = styled.div`
  #body{
    padding: 0px;
  }
  .modal-wrapper
  {
    width: 95%;
    padding: 0px;
  }
  
  div.files
  {
    padding: 5px 15px 15px 15px;
    outline: none;
    user-select: none;
  }
  div.files i
  {
    color: #6A5A8C;
    font-size: 36px;
    vertical-align: middle;
    margin-right: 10px
  }

  div.files input[type=file]
  {
      display: none;
  }
  div.files ol
  {
    margin: 0px;
    margin-left: 45px;
    padding: 0px;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
  div.files li
  {
    padding: 0px;
    margin: 0px;
    flex: 0 1 auto;
    min-width: 90px;
    user-select: none;
  }
  div.files li i
  {
    font-size: 25px;
    cursor: pointer;
  }
  div.files li i:hover
  {
    font-weight: bold;
  }
  div.files .fileselector
  {
    display: inline-block;
    cursor: pointer;
  }
`;

export const ModalTitleWrapper = styled.div`
   font-size: 20px;
   font-weight: bold;
   border-bottom: 1px solid #dfdfdf;
   text-align: left;
   flex: 0 0 100%;
   font-family: Dosis;
   padding: 8px 20px;
   color: #000;
   text-transform: uppercase;
`;

export const ModalFooterWrapper = styled.div`
   background-color: #fff;
   border-top: 1px solid #dfdfdf;
   display: flex;
   justify-content: flex-end;
   flex: 0 0 100%;
   text-align: right;
   padding: 10px;
`;

export const PbmTableWrapper = styled.div`
    background-color: #fff;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    
    h1
    {
      font-size: 14px;
      margin: 10px 5px;
      font-family: 'NeoSansProRegular';
      font-weight: normal;
    }
    
    ul.header {
      flex: 0 0 28px;
    }
    ul.header,
    ul.datarow,
    ul.totalrow {
      list-style: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    ul.header li,
    ul.datarow li,
    ul.totalrow li {
      flex: 0 1 11.5%;
      padding: 5px;
      min-width: 105px;
    }
    ul.header li {
      font-weight: bold;
    }
    ul.datarow li
    {
      text-transform: capitalize;
    }
    ul.header li:nth-child(2), 
    ul.datarow li:nth-child(2),
    ul.totalrow li  
    {
      flex: 1 0 170px;
    }
    ul.header li.center, 
    ul.datarow li.center,
    ul.totalrow li.center
    {
      text-align: center;
    }
    ul.header li.right, 
    ul.datarow li.right,
    ul.totalrow li.right  
    {
      text-align: right;
    }
    ul.datarow:nth-child(2n+1){
      background-color: #F8F8F8;
    }
    ul.datarow.norecords:hover li {
      background-color: transparent;
    }
    ul.datarow.norecords li {
      text-align: center;
      flex: 1 1 auto;
    }
    ul.datarow li:nth-child(9){
      flex: 0 0 100%;
    }
    ul.totalrow li {
      font-weight: bold;
      text-transform: capitalize;
    }
    ul.totalrow li:first-child {
      flex: 0 0 330px;
    }
    ul.datarow:last-child li{
      font-weight: bold;
    }
    
    @media (max-height: 720px){
      max-height: 530px;
    }
    @media (min-height: 721px) and (max-height: 960px) {
       max-height: 610px;
    }
    @media (min-height: 961px) and (max-height: 1024px) {
      max-height: 830px;
    }
    @media (min-height: 1025px) and (max-height: 2048px) {
      max-height: 900px;
    }
`;