import { AbstractService } from './abstractService';

export default class AdministrativeService extends AbstractService {
  constructor() {
    super('/Administrative');
  }

  static _instance = new AdministrativeService();

  updateRetailer = (retailer) => this.invokeAction('/UpdateRetailer', retailer);

  getCep = (cep) => this.invokeAction('/GetCep', { cep });

  getConfigs = (retailer, posId) =>
    this.invokeAction('/GetConfigs', { retailer, posId });

  getPosType = (retailer, posId) =>
    this.invokeAction('/GetPosType', { retailer, posId });

  getCepByAddress = (uf, cidade, logradouro, bairro) => {
    this.invokeAction('/GetCepByAdress', {
      uf,
      cidade,
      bairro,
      logradouro,
    });
  };

  validateUser = (referenceId) =>
    this.invokeAction('/ValidateUser', { referenceId });

  startOfferCoverage = () => this.invokeAction('/startOfferCoverage');
  startIncreasePrice = () => this.invokeAction('/startIncreasePrice');

  static GetInstance() {
    return (
      AdministrativeService._instance ||
      (AdministrativeService._instance = new AdministrativeService())
    );
  }
}
