/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import uuid from 'uuid/v4';
import PropTypes from 'prop-types';
import { DatePicker, Select } from './../modules';

import './main-style.scss';

export class FormControl extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    name: PropTypes.string,
    object: PropTypes.objectOf(PropTypes.any),
    field: PropTypes.string,
    component: PropTypes.any, // can be either a function or an object
    flex: PropTypes.string,
    onChange: PropTypes.func,
    initialvalue: PropTypes.any, // string or number
    required: PropTypes.bool,
    propToShow: PropTypes.string,
    propToValue: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    handleKeyDown: PropTypes.func,
    autoFocus: PropTypes.bool
  }

  static defaultProps = {
    label: null,
    name: null,
    object: null,
    field: null,
    component: 'input',
    flex: null,
    onChange: null,
    initialvalue: null,
    required: null,
    children: null,
    propToShow: null,
    propToValue: null,
    handleKeyDown: null,
    autoFocus: false
  }

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };

    this.controlRef = React.createRef();

    if (this.props.initialvalue && !this.getValue()) {
      this.setValue(this.props.initialvalue, false);
    }
  }

  componentDidUpdate() {
    if (this.props.initialvalue && !this.getValue()) {
      this.setValue(this.props.initialvalue, false);
    }
  }

  /**
   * Method invoked externally
   *  */
  focus = () => {
    if (this.controlRef && this.controlRef.current && this.controlRef.current.focus) {
      this.controlRef.current.focus();
    }
  }

  getIdForComponent() {
    const {
      label,
      name,
      ...attrs
    } = this.props;

    if (attrs.id) return attrs.id;

    if (name) return name;

    if (label) return label.normalize('NFD').replace(/[\u0300-\u036f, \s]/g, '').toLowerCase();

    if (this.generatedId) return this.generatedId;

    this.generatedId = uuid();

    return this.generatedId;
  }

  getValue = () => {
    if (this.props.component === DatePicker) {
      if (!this.props.object) return null;

      const value = this.props.object[this.props.field];

      return value ? new Date(value) : null;
    }

    if (!this.props.object) return '';

    return this.props.object[this.props.field] || '';
  }

  isValidDate = () => {
    if(this.props.component !== DatePicker){
      return false;
    }

    return this.controlRef.current.isValidDate();
  }

  validate() {
    const isInvalid = this.props.required && !this.getValue();

    if (isInvalid) {
      this.setState({ errorMessage: 'Campo requerido' });
    } else {
      this.setState({ errorMessage: null });
    }

    return !isInvalid;
  }

  setValue = (value, forceUpdate = true) => {
    if (!this.props.object || !this.props.field) return;

    this.props.object[this.props.field] = value;
    if (forceUpdate) {
      this.forceUpdate();
    }
  }

  handleChange = e => {
    if (this.props.component === DatePicker || this.props.component === Select) {
      this.setValue(e);
      this.props.onChange && this.props.onChange(e)
    } else {
      const { value } = e.target;
      if (
        this.props.field && 
        this.props.object && 
        value !== this.getValue() &&
        (!this.props.maxLength || this.props.maxLength >= e.target.value.length)
        ) {
        this.setValue(value);
        this.props.onChange && this.props.onChange(e)
      }
    }
  }

  handleKeyDown = (e) => {
    this.props.handleKeyDown && this.props.handleKeyDown(e);
  }

  render() {
    const {
      label,
      component,
      help,
      flex,
      children
    } = this.props;
    const id = this.getIdForComponent();

    const Component = component;

    const style = {
      flex: flex || '',
    };

    return (
      <div id={id} className={`custom-form-control ${this.state.invalid ? 'control-invalid' : ''}`} style={style}>
        { label && label.length > 0 && <label
          htmlFor={`${id}_input`}
        >
          {label}
        </label>
    	  }
        { children ? children : (
          <Component
            {...this.props}
            ref={this.controlRef}
            className="control"
            onChange={this.handleChange}
            id={`${id}_input`}
            value={this.getValue()}
            selected={this.getValue()}
            handleKeyDown={this.handleKeyDown}
          />
        ) }
        { (this.state.errorMessage || this.props.errorMessage) && (
          <small className="error" id={`${id}_erro`}>
            {this.state.errorMessage || this.props.errorMessage}
          </small>
        ) }
        { help && <small className="help" id={`${id}_help`}>{help}</small> }
      </div>
    );
  }
}
