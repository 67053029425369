import React, { Fragment } from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules'
import { Form, InputGroup, Col } from 'react-bootstrap';

import './linx-conecta-init-component.scss';

export class LinxConectaInitComponent extends React.Component {
  constructor(props) {
    super(props);

    this.empresaRef = React.createRef();
    this.modalConfirm = React.createRef();
    this.tableRef = React.createRef();
    this.nameRef = React.createRef();
    this.modalWarning = React.createRef();
  }

  handleChange = (e) => {
    this.props.form[e.target.id] = e.target.value;
  }

  getFirstValue = (empresas) => {
    if (empresas && empresas.length > 0) {
      return empresas[0].empreS_ID;
    }
    return null;
  }

  handleKeyDown = (e, card) => {
    switch (e.key) {
      case 'Enter':
        return this.props.handleCardSelected(card);
      case 'ArrowUp':
        if (e.target.previousSibling) {
          return e.target.previousSibling.focus();
        }
        setTimeout(() => {
          this.nameRef.current.select();
        })
        break;
      case 'ArrowDown':
        return e.target.nextSibling && e.target.nextSibling.focus();
      default:
        break;
    }
  }

  getClassName(status, acao) {
    if (status === 0)
      return 'statusGreen'
    if (status === 99 || acao === 'N')
      return 'statusBlue'
    if (acao === 'O')
      return 'statusRed'
    return 'statusUndefined'
  }

  getRow = (card) => {
    return (
      <div
        key={card.cartaO_ID}
        tabIndex={0} className="rowTbConecta"
        onClick={() => { this.props.handleCardSelected(card) }}
        onKeyDown={(e) => { this.handleKeyDown(e, card) }}
      >
        <div>{card.cartaO_NOME}</div>
        <div>{card.cartaO_IMPORTACAO}</div>
        <div>{card.empresA_NOME}</div>
        <div>{card.titulaR_CPF}</div>
        <div>{card.saldO_PONTOS}</div>
        <div>{card.statuS_CARTAO}</div>
        {card.mensageM_LGPD && (<div className={this.getClassName(card.statuS_TERMO_CONSENTIMENTO, card.acaO_LGPD)}>{card.mensageM_LGPD}</div>)}

        <div className='btnTbConecta'>
          <Button className="btn-default btn-outline btn-rows"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.handleEditarCartao(card)
            }}
          >
            EDITAR CARTÃO
          </Button>
          <Button className="btn-default btn-outline btn-rows"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.handleConsultarPecPbmsConveniado(card)
            }}
          >
            MINHA SAÚDE
          </Button>
          {card.mensageM_LGPD && <Button className="btn-default btn-outline btn-rows"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.handleSolicitarTermosClick(card);
            }}>
            SOLICITAR TERMOS LGPD
          </Button>}
          {card.mensageM_LGPD && <Button className="btn-default btn-outline btn-rows"
            onClick={this.props.handleSearch}
          >
            CONSULTAR STATUS
          </Button>}
        </div>
      </div>
    );
  }

  getHeaders = () => {
    return (
      <div className="headerTbConecta">
        <div>Nome</div>
        <div>Cartão</div>
        <div>Empresa</div>
        <div>CPF</div>
        <div>Pontos</div>
        <div>Status</div>
      </div>
    );
  }

  mapEmpresas = () => {
    return (
      <Fragment>
        {this.props.empresas.map(e => {
          return <option key={e.empreS_ID} value={e.empreS_ID}>{e.fantasia}</option>
        })}
      </Fragment>
    )
  }

  checkArrowDown = (e) => {
    if (e.key === 'ArrowDown') {
      const firstChild = this.tableRef.current.querySelector('tbody').firstChild;
      firstChild && firstChild.focus();
    }
  }

  componentDidMount() {
    this.modalConfirm.current.open();
    this.nameRef.current.focus();
  }

  getNaoConsentidoModal = () => {
    return (
      <Modal
        closable={false}
        style={{
          maxHeight: '70vh',
          height: "fit-content",
          width: '45vw'
        }}
        ref={this.modalWarning}
      >
        <Modal.Body className="mdl-body-confirmation">
          <i style={{ color: "blue", fontSize: '80px', textAlign: 'center' }} className="material-icons">info</i>
          <p className="title"><strong>STATUS DO CONSENTIMENTO LGPD</strong></p>
          <p >Cliente não consentiu com os termos de uso para utilização de suas informações.</p>
          <p >Deseja emitir o termo de consentimento?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-conecta-proceed"
            className="btn-default btn-purple"
            type="button"
            onClick={this.props.handleNaoConsentidoYesClick}
            style={{ textTransform: "none" }}
          >
            Sim
          </Button>
          <Button
            id="btn-conecta-return"
            className="btn-default btn-purple"
            type="button"
            onClick={this.props.handleNaoConsentidoNoClick}
            style={{ textTransform: "none" }}
          >
            Não
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  getNegadoModal = () => {
    return (
      <Modal
        closable={false}
        style={{
          maxHeight: '70vh',
          height: "fit-content",
          width: '45vw'
        }}
        ref={this.modalWarning}
      >
        <Modal.Body className="mdl-body-confirmation">
          <i style={{ color: "red", fontSize: '80px', textAlign: 'center' }} className="material-icons" >error</i>
          <p className="title"><strong>STATUS DO CONSENTIMENTO LGPD</strong></p>
          <p >Não será possível realizar a venda para este consumidor.</p>
          <p >Motivo: Consumidor não consentiu com os termos para utilização de suas informações.</p>
          <p >Status atual: <strong>{this.props.messageLgpd}</strong></p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-conecta-proceed"
            className="btn-default btn-purple"
            type="button"
            onClick={this.props.handleNegadoModalClick}
            style={{ textTransform: "none" }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  getFidelizaMaisModal = (payloadFidelizaMais) => {
    return (
      <Modal
        title={" Fideliza Mais"}
        id="linx-conecta-init-component"
        closeOnOverlayClick={false}
        closable={false}
        ref={this.modalConfirm}
        className="full-screen-modal"
        style={{
          margin: '10px',
          padding: '10px',
          maxHeight: '98vh',
          height: '98vh',
          width: '98vw',
        }}
      >
        <Modal.Body
          className="full-screen-modal-body"
          style={{
            margin: 0,
            padding: 0,
            height: 'calc(100vh - 64px)', // Ajusta altura total menos a altura do footer
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          <iframe
            src={payloadFidelizaMais.urlFideliza}
            style={{ border: 'none', width: '100%', height: '100%' }}
            title="Modal Content"
          ></iframe>
        </Modal.Body>
        <Modal.Footer
          className="full-screen-modal-footer"
          style={{
            padding: '10px',
            textAlign: 'center',
            height: '64px', // Ajuste conforme a altura do rodapé
            boxSizing: 'border-box',
          }}
        >
          <Button
            id="btn-close"
            className="btn-default btn-purple"
            onClick={this.props.closeFidelizaMaisModal}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  getPecPbmModal = (payloadPecPbm) => {
    return (
      <Modal
        title="Lista Minha Saúde"
        id="linx-conecta-init-component"
        closeOnOverlayClick={false}
        closable={false}
        ref={this.modalConfirm}
        className="col-11"
        style={{
          maxHeight: '70vh',
          height: "fit-content",
          width: '45vw'
        }}>
        <Modal.Body>
          <div className="tbConecta">
            {this.getHeadersPecPbm()}
            {payloadPecPbm.status === 0 && payloadPecPbm.pecPbms.map(this.getRowPecPbm)}
            {payloadPecPbm.status === 1 && (<div tabIndex={0} className="rowTbConecta rowPecPbm" style={{ fontSize: '16px' }}>{payloadPecPbm.msg}</div>)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-close"
            className={"btn-default btn-purple"}
            onClick={this.props.togglePecPbmModal}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }

  getRowPecPbm = (pecPbms) => {
    return (
      <div
        key={pecPbms.id}
        tabIndex={0}
        className="rowTbConecta rowPecPbm">
        <div>{pecPbms.descricao}</div>
        <div>{pecPbms.abrangenciaDescricao}</div>
        <div>{pecPbms.tipoDescontoDescricao}</div>
        <div>{pecPbms.pecPbmConveniado.dtInsercao}</div>
        <div>{pecPbms.pecPbmConveniado.pecPbmIntegracao.conteudo}</div>
        <div>{pecPbms.pecPbmConveniado.pecPbmOpcao.opcaoDescricao}</div>
        <div>{pecPbms.pecPbmConveniado.pecPbmOpcao.opcaoInformacaoAdicional}</div>
      </div>
    );
  }

  getHeadersPecPbm = () => {
    return (
      <div className="headerTbConecta headerPecPbm">
        <div>Descrição</div>
        <div>Abrangência</div>
        <div>Tipo desconto</div>
        <div>Data inserção</div>
        <div>Integração</div>
        <div>Opção</div>
        <div>Inf. adicional</div>
      </div>
    );
  }

  getPendenteModal = () => {
    return (
      <Modal
        closable={false}
        style={{
          maxHeight: '70vh',
          height: "fit-content",
          width: '45vw'
        }}
        ref={this.modalWarning}
      >
        <Modal.Body className="mdl-body-confirmation">
          <i className="material-icons-outlined" style={{ color: "blue", fontSize: '80px', textAlign: 'center' }}>info</i>
          <p className="title"><strong>STATUS DO CONSENTIMENTO LGPD</strong></p>
          <p >Notificação de status de consentimento LGPD.</p>
          <p >Consumidor não consentiu com os termos para utilização de suas informações.</p>
          <p >Status atual: <strong>{this.props.messageLgpd}</strong></p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-conecta-proceed"
            className="btn-default btn-purple"
            type="button"
            onClick={this.props.handlePendenteModalClick}
            style={{ textTransform: "none" }}
          >
            Prosseguir
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  getProtocoloModal = () => {
    return (
      <Modal
        closable={false}
        style={{
          maxHeight: '70vh',
          height: "fit-content",
          width: '45vw'
        }}
        ref={this.modalWarning}
      >
        <Modal.Body className="mdl-body-confirmation">
          <i className="material-icons-outlined" style={{ color: "blue", fontSize: '80px', textAlign: 'center' }}>info</i>
          <p className="title"><strong>SOLICITAÇÃO DO TERMO DE CONSENTIMENTO LGPD</strong></p>
          <p >A sua solicitação do termo de consentimento foi registrada com sucesso!</p>
          <p >O protocolo para consulta é: <strong>{this.props.protocoloLGPD}</strong></p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-conecta-proceed"
            className="btn-default btn-purple"
            type="button"
            onClick={this.props.handlePortalLGPDClick}
            style={{ textTransform: "none" }}
          >
            SEGUIR PARA O PORTAL LGPD
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const {
      handleClose,
      empresas,
      handleSignUp,
      cardList,
      handleSearch,
      showNaoConsentido,
      showNegado,
      showPendente,
      showProtocolo,
      showPecPbmModal,
      payloadPecPbm,
      showFidelizaMaisModal,
      payloadFidelizaMais,
    } = this.props;

    if (showNaoConsentido)
      return this.getNaoConsentidoModal()

    if (showNegado)
      return this.getNegadoModal()

    if (showPendente)
      return this.getPendenteModal()

    if (showProtocolo)
      return this.getProtocoloModal()

    if (showPecPbmModal)
      return this.getPecPbmModal(payloadPecPbm.linxConectaConsultarPecPbmsConveniadoRet);

    if (showFidelizaMaisModal)
      return this.getFidelizaMaisModal(payloadFidelizaMais.linxConectaObterFidelizaMaisRet);


    return (
      <Modal
        title="Linx Conecta - Selecione um consumidor"
        id="linx-conecta-init-component"
        closable={false}
        closeOnOverlayClick={false}
        ref={this.modalConfirm}
        opened={true}
        onClickClose={handleClose}
        className="col-11"
        style={
          {
            height: '85vh',
            width: '85vw'
          }
        }
      >
        <Modal.Body>
          <Form onSubmit={handleSearch}>
            <Form.Row className="conecta-form-header col-bg-6 col-md-12 flex-column flex-md-row">
              <Form.Group as={Col} controlId="empresaId">
                <Form.Label className="conecta-label">Empresa</Form.Label>
                <Form.Control
                  required
                  as="select"
                  placeholder="Empresa"
                  defaultValue={this.getFirstValue(empresas)}
                  onChange={this.handleChange}
                  ref={this.empresaRef}
                >
                  {this.mapEmpresas()}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="cardQuery">
                <Form.Label className="conecta-label">CPF/Nome</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    onChange={this.handleChange}
                    type="text"
                    onKeyDown={this.checkArrowDown}
                    placeholder="Consultar consumidor por Nome, CPF ou Cartão"
                    ref={this.nameRef}
                  />
                </InputGroup>
              </Form.Group>
              <Button onClick={handleSearch} className="btn-default btn-purple btn-tops">
                LOCALIZAR CONSUMIDOR
              </Button>
              <Button className="btn-default btn-purple btn-tops" onClick={handleSignUp}>CADASTRAR CARTÃO</Button>
            </Form.Row>
          </Form>
          <div className="tbConecta">
            {this.getHeaders()}
            {cardList.map(this.getRow)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-cancel"
            onClick={handleClose}
            className="btn-default btn-outline btn-cancel"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default LinxConectaInitComponent;
