import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closeAllCards, PURCHASE_HISTORY, shouldShow } from '../../redux/actions/routerAction';
import { 
  differenceInDays, 
  subMonths, 
  isAfter,
  subDays
} from 'date-fns';

import PurchaseHistory from '../../shared/components/purchase-history';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import OrderService from '../../services/orderService';
import { showToast } from '../../shared/utils/util';
import configuration from '../../configuration';
import Store from '../../redux/store';
import { blockAllShortcutsBut, unblockAllShortcuts } from '../../redux/actions/shortcutsAction';

export class PurchaseHistoryContainer extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selectedPeriod: "90-days",
      selectPeriodStartDate: null,
      selectPeriodEndDate: null,
      data: [],
      totalRecords: 0,
      currentPage: 1,
      hasMoreData: true,
      sortingType: "DESC",
      sortingCol: "dtVd",
      searchTerm: null,
      
      isFetchingNewData: true,
    }
    
    this.props.MessageBus.Subscribe('pos.order.history.success', this.setSearchedData);
    this.props.MessageBus.Subscribe('pos.order.history.error', this.handleError);
    
    this.orderService = new OrderService();
    
    this.ninetyDaysPeriod = {
      startDate: subDays(new Date(), 90),
      endDate: new Date()
    }
    
    this.sixMonthsPeriod = {
      startDate: subMonths(new Date(), 6),
      endDate: new Date()
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showPurchaseHistoryComponent !== this.props.showPurchaseHistoryComponent && this.props.showPurchaseHistoryComponent && this.props.selectedCustomer !== null) {
      this.search("90-days", this.state.currentPage, undefined, "DESC", "dtVd");
    }
    
    if (prevProps.loading !== this.props.loading && this.props.showPurchaseHistoryComponent) {
      if (!this.props.loading.visible) {
        // setTimeout is used to take the action to dispatch after all other asynchronous actions has finished (Loading);
        setTimeout(() => Store.dispatch(blockAllShortcutsBut()), 0);
      }
    }
    
    if (prevProps.showPurchaseHistoryComponent !==  this.props.showPurchaseHistoryComponent && !this.props.showPurchaseHistoryComponent) {
      Store.dispatch(unblockAllShortcuts());
    }
  }
  
  setSearchedData = (ch, payload) => {
    if (payload.totalRecords > this.state.totalRecords) {
      this.setState({totalRecords: payload.totalRecords});
    }
    
    this.setState({
      data: this.state.data.concat(payload.orders), 
      totalRecords: payload.totalRecords,
      isFetchingNewData: false,
    });
  }
  
  search = (period, page, searchTerm, sortingType, sortingCol, showLoader) => {    
    this.setState({isFetchingNewData: true});
    
    let payload = {
      cdCli: this.props.selectedCustomer.referenceId,
      cdFilial: configuration.retailerId,
      pageSize: 20,
      pageNumber: page,
      dsProd: searchTerm,
      sortingType,
      sortingCol,
      dtIni: null,
      dtFim: null,
    };
    
    switch (period) {
      case "90-days":
        payload.dtIni = this.ninetyDaysPeriod.startDate;
        payload.dtFim = this.ninetyDaysPeriod.endDate;
        break;
      case "6-months":
        payload.dtIni = this.sixMonthsPeriod.startDate;
        payload.dtFim = this.sixMonthsPeriod.endDate;
        break;
      case "select-period":
        payload.dtIni = this.state.selectPeriodStartDate;
        payload.dtFim = this.state.selectPeriodEndDate;
        break;
      default:
        break;
    }
    
    this.orderService.getPurchaseHistory(payload, showLoader);
  }
  
  fetchMoreData = () => {
    if (this.state.selectedPeriod === "select-period" && this.state.totalRecords === 0) return;
    
    let pageToCall = this.state.currentPage;
    
    if (this.state.data.length >= this.state.totalRecords && this.state.data.length !== 0 && this.state.totalRecords !== 0) {
      this.setState({hasMoreData: false});
      return;
    }
    
    pageToCall++;
    
    const {selectedPeriod, searchTerm, sortingCol, sortingType} = this.state;
    
    this.search(selectedPeriod, pageToCall, searchTerm, sortingType, sortingCol, false);
    
    this.setState({currentPage: pageToCall});
  }
  
  handleClose = () => {
    if (this.state.isFetchingNewData) return;
    
    this.props.close();
    this.setState({
      selectedPeriod: "90-days",
      selectPeriodStartDate: null,
      selectPeriodEndDate: null,
      data: [],
      currentPage: 1,
      totalRecords: 0,
      hasMoreData: true,
      sortingType: "DESC",
      sortingCol: "dtVd",
    });
  }
  
  handleError = (ch, payload) => {
    console.log(payload);
    
    this.setState({isFetchingNewData: false});
    
    showToast({
      type: 2,
      title: 'Histórico de Compras',
      html: `<b>Erro inesperado:</b> </br> ${payload.title}`,
      hideAfterMilliseconds: 10000
    });
  }
  
  handleApplyPeriod = (startDate, endDate) => { 
    if (isAfter(startDate, new Date())) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: 'A data inicial não pode ser maior que a data atual. Verifique as informações e tente novamente.',
        hideAfterMilliseconds: 4000
      });
      return;
    }
    
    if (isAfter(startDate, endDate)) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: 'A data final deve ser maior ou igual que a data inicial. Verifique as informações e tente novamente.',
        hideAfterMilliseconds: 4000
      });
      return;
    }
    
    if (differenceInDays(endDate, startDate) > 365) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: 'Período máximo para consulta é de 1 ano (365 dias). Verifique as datas informadas.',
        hideAfterMilliseconds: 4000
      });
      return;
    }
    
    if (isAfter(endDate, new Date())) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: 'A data final não pode ser maior que a data atual. Verifique as informações e tente novamente.',
        hideAfterMilliseconds: 4000
      });
      return;
    }
    
    this.setState({
      selectPeriodStartDate: startDate,
      selectPeriodEndDate: endDate
    }, () => this.search("select-period", 1, null, this.state.sortingType, this.state.sortingCol));
  }
  
  handleClickPeriod = (btnId) => {   
    if (this.state.isFetchingNewData) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: `Aguarde os dados serem carregados para executar uma nova busca no histórico.`,
        hideAfterMilliseconds: 3000
      });
      
      return;
    }
    
    switch (btnId) {
      case "btn-90-days":
        if (this.state.selectedPeriod === "90-days") return;
        this.setState({
          selectedPeriod: "90-days",
          data: [], 
          currentPage: 1, 
          totalRecords: 0, 
          hasMoreData: true,
          searchTerm: "",
          selectPeriodStartDate: null,
          selectPeriodEndDate: null,
        });
        this.search("90-days", 1, null, this.state.sortingType, this.state.sortingCol);
        break;
      case "btn-6-months": 
        if (this.state.selectedPeriod === "6-months") return;
        this.setState({
          selectedPeriod: "6-months", 
          data: [], 
          currentPage: 1, 
          totalRecords: 0, 
          hasMoreData: true,
          searchTerm: "",
          selectPeriodStartDate: null,
          selectPeriodEndDate: null,
        });
        this.search("6-months", 1, null, this.state.sortingType, this.state.sortingCol);
        break;
      case "btn-select-period":
        if (this.state.selectedPeriod === "select-period") return;
        this.setState({
          selectedPeriod: "select-period",
          data: [], 
          currentPage: 1, 
          totalRecords: 0, 
          hasMoreData: true,
          searchTerm: "",
          selectPeriodStartDate: null,
          selectPeriodEndDate: null,
        });
        break;
      default:
        break;
    }
  }
  
  handleSort = (key, direction) => {
    this.setState({
      data: [],
      currentPage: 1,
      totalRecords: 0,
      hasMoreData: true,
      sortingType: direction,
      searchTerm: null
    });

    switch (key) {
      case "product":
        this.search(this.state.selectedPeriod, 1, null, direction, "dsProd");
        this.setState({sortingCol: "dsProd"});
        break;
      case "manufacturer":
        this.search(this.state.selectedPeriod, 1, null, direction, "nmFabric");
        this.setState({sortingCol: "nmFabric"});
        break;
      case "date":
        this.search(this.state.selectedPeriod, 1, null, direction, "dtVd");
        this.setState({sortingCol: "dtVd"});
        break;
      default:
        break;
    }
  }
  
  handleSearchForProducts = (searchTerm) => {    
    if (searchTerm.length <= 2) return;
    if (searchTerm === this.state.searchTerm) return;
    
    if (this.state.isFetchingNewData) {
      showToast({
        type: 1,
        title: 'Histórico de Compras',
        html: `Aguarde os dados serem carregados para executar uma nova pesquisa.`,
        hideAfterMilliseconds: 3000
      });
      
      return;
    }
    
    this.setState({
      data: [],
      currentPage: 1,
      totalRecords: 0,
      hasMoreData: true,
      searchTerm,
    });
    
    this.search(this.state.selectedPeriod, 1, searchTerm, this.state.sortingType, this.state.sortingCol);
  }
  
  handleClearSearch = () => {
    this.setState({
      data: [],
      currentPage: 1,
      totalRecords: 0,
      hasMoreData: true,
      searchTerm: null
    }, this.search(this.state.selectedPeriod, 1, null, this.state.sortingType, this.state.sortingCol));
  }
  
  render() {
    return (  
      this.props.showPurchaseHistoryComponent &&
      this.props.selectedCustomer && 
      <PurchaseHistory
        data={this.state.data}
        hasMoreData={this.state.hasMoreData}
        isFetchingNewData={this.state.isFetchingNewData}
        selectedPeriod={this.state.selectedPeriod}
        selectPeriodStartDate={this.state.selectPeriodStartDate}
        selectPeriodEndDate={this.state.selectPeriodEndDate}
        fetchMoreData={this.fetchMoreData}
        handleClose={this.handleClose}
        handleClickPeriod={this.handleClickPeriod}
        handleClearSearch={this.handleClearSearch}
        handleApplyPeriod={this.handleApplyPeriod}
        handleSearchForProducts={this.handleSearchForProducts}
        handleSort={this.handleSort}
      />
    )
  }
}

const mapStateToProps = state => ({
  showPurchaseHistoryComponent: shouldShow(state, PURCHASE_HISTORY),
  selectedCustomer: state.customer.selectedCustomer,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PurchaseHistoryContainer)));