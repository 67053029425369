import React from 'react';
import { Card } from '../modules';
import { Table, Form, Col, Button } from 'react-bootstrap';
import Row from './Row';
import './style.scss';

function CardDocumentPrinting(props) {

    const {
        handleClose,
        handleChange,
        handleFind,
        handlePrintDocument,
        documentTypes,
        labelData,
        data
    } = props;

    let handleKeyDown = (e) => {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                handleFind();
                break;
            default:
                break;
        }
    };

    let getName = (typeName) => {
        switch (typeName) {
            case 'OpeningCashDesk':
                return "Abertura de caixa";
            case 'ClosingCashDesk':
                return "Fechamento de caixa";
            case 'Withdraw':
                return "Sangria";
            case 'Supply':
                return "Suprimento";
            case 'CustomerSellingModel':
                return "Crediário";
            case 'CustomerBusinessAgreement':
                return "Convênio";
            case 1:
                return "Abertura de caixa";
            case 2:
                return "Fechamento de caixa";
            case 3:
                return "Sangria";
            case 4:
                return "Suprimento";
            case 5:
                return "Crediário";
            case 6:
                return "Convênio";
            default:
                return "";
        }
    };

    let getHeaders = () => {
        return (
            <tr>
                <th />
                <th>Pos Id</th>
                <th>{labelData}</th>
                <th>Usuário</th>
                <th>Descrição</th>
                <th>Ações</th>
            </tr>
        );
    };

    return (
        <Card
            autoScroll={false}
            id='document-printing-control'
            title='Impressão de documentos'
            closeEnable
            handleClose={handleClose}
        >
            <Form>
                <Form.Row>
                    <Form.Group className='col-xs-12 col-lg-8' controlId='Query'>
                        <Form.Label>Busca</Form.Label>
                        <Form.Control
                            label='Busca'
                            required
                            placeholder='Busca por Usuário'
                            onChange={handleChange}
                            onKeyDown={(e) => handleKeyDown(e)}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId='Type'>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control
                            required
                            as='select'
                            placeholder='Tipo'
                            defaultValue={2}
                            onChange={handleChange}
                        >
                            {
                                documentTypes.map((t) => (
                                    <option key={t.value} value={t.value}>
                                        {getName(t.name)}
                                    </option>
                                ))
                            }

                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={handleFind}>
                            <i className='material-icons'>search</i>
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            {data && data.length > 0 && (
                <>
                    <Table className='table-sm' striped responsive hover>
                        <thead>{getHeaders()}</thead>
                        <tbody>
                            {data.map((item) => (
                                <Row
                                    key={item.id}
                                    item={item}
                                    handlePrintDocument={handlePrintDocument}
                                />
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </Card>
    );
}

export default CardDocumentPrinting;