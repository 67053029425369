import React, { useState } from 'react';
import { Card, Button } from '../modules';
import {
  PaymentAuthorizationWrapper,
  PaymentAuthorizationHeader,
  PaymentAuthorizationInputWrapper,
  PaymentAuthorizationInput,
} from './style';
import { currencyMask } from '../../utils/masks';

const CardPaymentAuthorization = ({
  items,
  handleClose,
  handleClickFin,
  title,
  subTitle,
  image,
  isCredit,
}) => {
  const [codeNsu, setCodeNsu] = useState('');
  const [errorCodeNsu, setErrorCodeNsu] = useState(false);

  const handleChange = (e) => {
    const regex = /[^0-9]/g;
    let value = e.target.value.replace(regex, '');
    setCodeNsu(value);

    if (regex.test(e.target.value)) {
      setErrorCodeNsu(true);
    } else {
      setErrorCodeNsu(false);
    }
  };

  const handleSubmit = () => {
    setErrorCodeNsu(false);
    handleClickFin(items, codeNsu);
  };

  return (
    <Card
      title={title}
      subTitle={subTitle}
      urlIcone={image}
      handleClose={handleClose}
      closeEnable
    >
      <PaymentAuthorizationWrapper>
        {isCredit && (
          <PaymentAuthorizationHeader>
            {`${currencyMask(items.installmentValue) || ''} em ${
              items.installmentNumber || ''
            }x `}
          </PaymentAuthorizationHeader>
        )}
        {!isCredit && (
          <PaymentAuthorizationHeader>
            {`${items.brand || ''} ${currencyMask(items.total) || ''}`}
          </PaymentAuthorizationHeader>
        )}
        <PaymentAuthorizationInputWrapper>
          <PaymentAuthorizationInput>
            <input
              type='text'
              id='codAut'
              name='codAut'
              value={codeNsu}
              placeholder='Informe o número NSU'
              autoFocus
              maxLength={20}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSubmit();
              }}
            />
            {errorCodeNsu && <span>O campo NSU, aceita somente números!</span>}
          </PaymentAuthorizationInput>
          <Button
            id='btn-finish'
            type='button'
            className='btn-large btn-outline btn-confirm-pay'
            handleClick={() => handleSubmit()}
          >
            Finalizar
          </Button>
        </PaymentAuthorizationInputWrapper>
      </PaymentAuthorizationWrapper>
    </Card>
  );
};

export default CardPaymentAuthorization;
