import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  shouldShow,
  OBJECTPRO,
  closeCard,
  showCard,
} from '../../redux/actions/routerAction';

import AdministrativeService from '../../services/AdministrativeService';

import MessageBusService from '../../services/MessageBusService';
import { ObjectProService } from '../../services/objectproService';
import ObjectProComponent from '../../shared/components/objectpro/objectpro-component';
import { IRegisterObjectPro } from '../../shared/interfaces/register-objectpro';
import { showToast } from '../../shared/utils/util';

export default function ObjectProContainer() {
  const visible = useSelector((state) => shouldShow(state, OBJECTPRO));

  const dispatch = useDispatch();
  const objectProService = new ObjectProService();

  useEffect(() => {
    const errorConsultaObjectPro = MessageBusService.GetInstance().Subscribe(
      'pos.objectpro.consultarCliente.error',
      callErrorMessage
    );

    const openObjectPro = MessageBusService.GetInstance().Subscribe(
      'pos.objectpro.open',
      () => {
        dispatch(showCard(OBJECTPRO, null, true));
      }
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(errorConsultaObjectPro);
      MessageBusService.GetInstance().Unsubscribe(openObjectPro);
    };
  }, []);

  function callErrorMessage(response, payload) {
    showToast({
      type: 2,
      title: payload.errors[0].title ?? `Erro ao consultar cliente`,
      html: payload.errors[0].errorMessage
        ? payload.errors[0].errorMessage
        : payload.errors[0],
      hideAfterMilliseconds: 5000,
    });
  }

  function handleClose() {
    dispatch(closeCard(OBJECTPRO));
  }

  function getClientes(cpfCartao: string) {
    objectProService.getClientes(cpfCartao);
  }

  function getCep(cep: string) {
    AdministrativeService.GetInstance().getCep(cep);
  }

  function saveClienteObjectPro(customer: IRegisterObjectPro): void {
    objectProService.saveCliente(customer);
  }

  function selectClient(customer: IRegisterObjectPro): void {
    objectProService.selecionarCliente(customer);
  }

  return (
    visible && (
      <ObjectProComponent
        eventclickSearch={getClientes}
        saveCliente={saveClienteObjectPro}
        cancel={handleClose}
        getCep={getCep}
        selectClient={selectClient}
      />
    )
  );
}
