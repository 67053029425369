import React from 'react';

import { connect } from 'react-redux';

import { closeAllCards, showCard } from '../../redux/actions/routerAction';
import { DELIVERY_INFO, shouldShow } from '../../redux/actions/views';
import DeliveryService from '../../services/deliveryService';
import { DeliveryInfo } from '../../shared/components/modules';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { ValidateCPF } from '../../shared/utils/modules';
import SearchCepContainer from '../search-cep';
import AdministrativeService from '../../services/AdministrativeService';

export class DeliveryInfoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchCep: false,
      data: {},
      cepCallback: false,
      isKeyDownEnter: false,
    };

    this.props.MessageBus.Subscribe('pos.administrative.getCepReturn', this.handleGetCep);
    this.props.MessageBus.Subscribe('pos.delivery.info.show', this.handleShow);
    this.props.MessageBus.Subscribe('pos.delivery.info.close', this.handleClose);
    this.deliveryService = new DeliveryService();
  }

  handleShow = (ch, payload) => {
    this.setState({
      data: payload,
    });

    this.props.showDeliveryInfo();
  };

  handleConfirmSave = () => {
    if (
      !this.state.data.deliveryCustomer.cpf ||
      !ValidateCPF(this.state.data.deliveryCustomer.cpf)
    ) {
      this.context.showMessage('CPF inválido!');
      return;
    }
    this.deliveryService.updateCustomer(this.state.data, true);
  };

  handleClose = () => {
    this.setState({
      cepCallback: false,
      data: {}
    });
    this.props.hideDeliveryInfo();
  };

  handleConfirmWhithoutSave = () => {
    if (this.state.data.deliveryCustomer.cpf && !ValidateCPF(this.state.data.deliveryCustomer.cpf)) {
      this.context.showMessage('CPF inválido!');
      return;
    }
    if (!this.state.data.deliveryCustomer.cpf && this.state.data.deliveryCustomer.cPFNaNota) {
      this.context.showMessage('CPF obrigatório');
      return;
    }
    this.deliveryService.updateCustomer(this.state.data, false);
  };

  handleIdentifyCepClick = () => {
    this.setState({ showSearchCep: true });
  };

  handleSearchCepClose = () => this.setState({ showSearchCep: false });

  handleSelectCep = (payload) => {
    const { show } = this.props;
    if (show) {
      const delivery = { ...this.state.data };
      delivery.deliveryCustomer.deliveryCustomerAddress.street = payload.rua;
      delivery.deliveryCustomer.deliveryCustomerAddress.neighborhood = payload.bairro;
      delivery.deliveryCustomer.deliveryCustomerAddress.zipCode = payload.cep;
      delivery.deliveryCustomer.deliveryCustomerAddress.city = payload.cidade;
      delivery.deliveryCustomer.deliveryCustomerAddress.state = payload.uf;

      this.setState({
        showSearchCep: false,
        data: { ...delivery },
        cepCallback: true
      });
    }
  };
  handleGetCep = (channel, payload) => this.handleSelectCep(payload);
  handleSearchCep = (event) => {
    if (this.state.isKeyDownEnter) {
      this.setState({ isKeyDownEnter: false });
    } else {
      const cep = event.target.value.replace(/[^0-9]+/g, '');
      if (cep.length !== 8) return;
      new AdministrativeService().getCep(cep);
    }
  };
  handleKeyDownZipCode = (e) => {
    if (e.key === 'Enter') {
      this.setState({ isKeyDownEnter: true });
      e.preventDefault();
      this.handleSearchCep(e);
    }
  };
  handleChange = (e) => {
    const delivery = { ...this.state.data };
    switch (e.target.id) {
      case 'name':
      case 'cpf':
      case 'email':
      case 'phone':
        delivery.deliveryCustomer[e.target.id] = e.target.value;
        break;
      case 'zipCode':
      case 'state':
      case 'city':
      case 'neighborhood':
      case 'street':
      case 'number':
      case 'complement':
        delivery.deliveryCustomer.deliveryCustomerAddress[e.target.id] = e.target.value;
        break;
      case 'deliveryObs':
        delivery[e.target.id] = e.target.value;
        break;
      case 'input_cpfNaNota':
        delivery.deliveryCustomer.cpfNaNota = e.target.checked;
        break;
      default:
        break;
    }
    this.setState(
      {
        data: { ...delivery },
        cepCallback: false,
      }
    );
  }

  render() {
    const { show } = this.props;
    const { showSearchCep, data } = this.state;
    return showSearchCep ? (
      <SearchCepContainer
        show={showSearchCep}
        onClose={this.handleSearchCepClose}
        onSelect={this.handleSelectCep}
      />
    ) : (
      show && (
        <DeliveryInfo
          data={data}
          handleClose={this.handleClose}
          handleConfirmSave={this.handleConfirmSave}
          handleConfirmWhithoutSave={this.handleConfirmWhithoutSave}
          handleBlurZipCode={this.handleSearchCep}
          handleKeyDownZipCode={this.handleKeyDownZipCode}
          onIdentifyCepClick={this.handleIdentifyCepClick}
          cepCallback={this.state.cepCallback}
          handleChange={this.handleChange}
          isOnline={this.props.isOnline}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  show: shouldShow(state, DELIVERY_INFO),
  isOnline: state.pdvContingency.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
  showDeliveryInfo: () => dispatch(showCard(DELIVERY_INFO, null, true)),
  hideDeliveryInfo: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(DeliveryInfoContainer)));
