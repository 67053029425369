import { all, takeLatest, put } from 'redux-saga/effects';

import {
  LOADING_SCREEN_SHOW,
  LOADING_SCREEN_HIDE,
} from '../../actions/actionTypes';
import {
  blockAllShortcutsBut,
  unblockAllShortcuts,
} from '../../actions/shortcutsAction';
import { TYPE_SHOW_MESSAGE, TYPE_HIDE_MESSAGE } from '../message/duck';

export function* blockShortcuts() {
  yield put(blockAllShortcutsBut());
}

export function* unblockShortcuts() {
  yield put(unblockAllShortcuts());
}

export default all([
  takeLatest(LOADING_SCREEN_SHOW, blockShortcuts),
  takeLatest(TYPE_SHOW_MESSAGE, blockShortcuts),
  takeLatest(LOADING_SCREEN_HIDE, unblockShortcuts),
  takeLatest(TYPE_HIDE_MESSAGE, unblockShortcuts),
]);
