import styled from 'styled-components';
import theme2 from '../../../themes/theme2';

const InputGroup = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: column;

    input:focus, textarea:focus {
        border: 1px solid ${theme2.palette.primary};
        box-shadow: none;
        outline: none;
    }
`

export default InputGroup;