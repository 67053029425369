import React from 'react';
import { ListBoxItemWrapper, ListBoxItemContentWrapper } from './style';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';

class ListBoxItem extends React.Component {
  render() {
    const { imagePath, label, handleClick, shortKey, handleKeyDown, enabled } = this.props;

    return (
      <ListBoxItemWrapper id="list-box-item-wrapper" tabIndex={enabled ? "0" :""} onClick={() =>  handleClick(label) } onKeyDown={handleKeyDown}>
        <ListBoxItemContentWrapper tabIndex="-1"  className={enabled ? '' : 'disabled'}>
          {imagePath && <img src={imagePath} alt="content" />}
          {
            shortKey &&  (
              <ShortcutHint shortcut={shortKey} activateShortcut={() => handleClick(label)}
              offsetLeft={0} offsetTop={0}/>
            )
          }
          <span>{label}</span>
        </ListBoxItemContentWrapper>
      </ListBoxItemWrapper>
    );
  }
}
export default ListBoxItem;
