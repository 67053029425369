import styled from 'styled-components';

import theme2 from '../../../themes/theme2';

export const TableWrapper = styled.table`
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${theme2.palette.tableBgOdd};
      }
    }
  }

  td {
    font-family: 'Ubuntu', 'Dosis';
    font-size: 14px;
    color: ${theme2.palette.grayDark};
    padding: 0;
    height: 55px;
  }

  &.table {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 3px;

    tr {
      width: 100%;

      th {
        text-align: left;
        padding-left: 15px;
        padding-right: 5px;
        border: none;
        position: sticky;
        position: -webkit-sticky; /* Safari */
        top: -16px;
        background: white;

        &.center {
          text-align: center;
        }
      }
    }

    .table-header {
      flex: 0 0 26px;
      font-weight: bold;

      .table-cell {
        align-items: flex-start;
        border: none;
      }
    }

    .table-body {
      flex-direction: column;
      flex: 1;
    }

    .table-row {
      flex-direction: row;
      flex: 0 0 38px;
      padding: 6px;
      box-sizing: border-box;
    }

    .table-cell {
      vertical-align: middle;
      flex: 1;
      align-items: flex-start;
      border: none;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        padding-right: 16px;
      }

      &:first-child {
        padding-left: 16px;
      }

      &.input {
        padding: 4px 5px;
      }

      .input__container {
        margin-top: 0;
        height: 26px;

        input {
          height: 20px;
        }
      }
    }
    .table-cell.no-padding {
      &:last-child {
        padding-right: 0px;
      }

      &:first-child {
        padding-left: 0px;
      }
    }

    input {
      font-size: 16px;
      font-family: dosis;
      color: black;
    }
  }

  &.spaced-rows-in-card {
    .table-row {
      border-top: 1px solid #e9e9e9;
      border-left: 1px solid #e9e9e9;
      border-right: 2px solid #e9e9e9;
      border-bottom: 2px solid #e9e9e9;

      margin-top: 8px;
    }
  }

  &.selectable {
    tbody .table-row {
      &:hover,
      &:focus,
      &.active {
        cursor: pointer;
        color: black;
        font-weight: bold;
        background-color: #ffb200;
        tab-index: 5;
      }
    }
  }

  .collapsable-cell {
    i {
      height: 17px;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }

    .content-cell {
      flex-direction: column;
      flex: 1;
    }

    .other-values {
      display: none;
      flex-direction: row;
      transition: 0.7s;

      .other-value {
        padding-top: 10px;
      }
    }

    &.open .other-values {
      display: flex;
      flex-direction: column;
      transition: 0.7s;
    }
  }

  .empty-message {
    color: #ccccd0;
    font-size: 17px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
  }

  .customer .row-disabled {
    td {
      position: relative;
      padding-top: 0 !important;
      padding-bottom: 23px !important;
    }

    td:first-child {
      &::before {
        content: '!';
        font-family: 'Ubuntu', 'Dosis';
        font-size: 13px;
        text-align: center;
        color: orange;
        position: absolute;
        left: 17px;
        bottom: 7px;
        border-radius: 50%;
        border: 2px solid orange;
        width: 20px;
        height: 20px;
      }
    }

    td:first-child {
      &::after {
        content: ' Atenção!';
        font-family: 'Ubuntu', 'Dosis';
        font-size: 13px;
        font-weight: bold;
        color: orange;
        position: absolute;
        height: 30px;
        left: 41px;
        bottom: -4px;
      }
    }

    #spanCpf {
      &::before {
        content: 'Cliente inativo, para voltar a fazer venda realize a ativação no retaguarda.';
        font-family: 'Ubuntu', 'Dosis';
        font-size: 13px;
        position: absolute;
        height: 30px;
        left: 102px;
        bottom: -5px;
      }
    }

    td:last-child {
      padding-top: 8px !important;
      padding-bottom: 12px !important;
    }

    &:focus {
      outline: none !important;
    }

    i {
      color: #807f7c !important;
    }
  }
`;

export default TableWrapper;
