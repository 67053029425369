import styled from 'styled-components';

export const StockWrapper = styled.div`
  text-align: center;

  img 
  {
    height: 30px;
  }
  button{
      border: none;
      outline: none;
      background-color: transparent;
  }
  button:hover img, button:focus img 
  {
    background-color: #d9cee7;
  }
`;
