
import * as types from './actionTypes';

export const closeListPayments = () => (dispatch) => {
  dispatch({ type: types.CLOSE_LIST_PAYMENTS, payload: null });
};

export const paymentMoneyInputValue = paymentMoney => (dispatch) => {
  dispatch({ type: types.PAYMENT_INPUT_VALUE, payload: paymentMoney });
};

export const paymentMoneyInput = moneyInput => (dispatch) => {
  dispatch({ type: types.PAYMENT_ADD_INPUT, payload: moneyInput });
};

export const paymentChangeMoney = (payload) => (dispatch) => {
  dispatch({ type: types.PAYMENT_CHANGE_MONEY, payload });
};

export const paymentAmountRemaing = payload => (dispatch) => {
  dispatch({ type: types.PAYMENT_AMOUNT_REMANING, payload });
};

export const tefOptionsSelect = () => (dispatch) => {
  dispatch({ type: types.TEF_OPTIONS_SELECT });
};

export const tefOptionsCompleted = () => (dispatch) => {
  dispatch({ type: types.TEF_OPTIONS_COMPLETED });
};

export const tefInteraction = () => (dispatch) => {
  dispatch({ type: types.TEF_INTERACTION });
};
