import React from 'react';
import PropTypes from 'prop-types';

const Thead = ({
  columns, order = 'asc', handleClick, items, handleHeader
}) => (
  // This needs to be destroyed
    <thead>
      <tr>
        {columns.map((column) => {
          const colRef = column.cols.map(col => col.label.ref || column.title.replace(/( )/g, '_')).join('-');
          const classes = column.classHeader ? column.classHeader : `${
            column.className}` ? column.className : `${
              handleClick}` ? `order-${order}` : '';
          const elem = column.type === 'checkbox' ? (<input type="checkbox" onClick={e => handleHeader(e)} id="checkMaster" />) : column.title;
          return (
            <th
              key={colRef}
              className={classes}
              colSpan={column.cols.length}
              onClick={() => handleClick(colRef, order)}
              align={column.align}
            >
              {elem}
            </th>
          );
        })}
      </tr>
    </thead>
  );

Thead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      cols: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              ref: PropTypes.string,
            }),
          ]).isRequired,
          component: PropTypes.any,
        }),
      ).isRequired,
    }),
  ).isRequired,
  order: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

export default Thead;
