import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../card';
import { currencyMask } from '../../utils/masks';
import { Button } from '../button';
import { Table, STYLE_SPACED_ROW_CARD } from '../table2';
import FarmaciaPopularWrapper, { HeadWrapper, Summary } from './style';
import { currencyString } from '../../utils/masks';

const tableAuthorization = {
  customClass: `${STYLE_SPACED_ROW_CARD} authorization`,
  columns: [
    { title: 'NÚMERO PRÉ-AUTORIZAÇÃO', label: 'number' },
    { title: 'PACIENTE', label: 'people' }
  ]
}

export class FarmaciaPopularConfirm extends Component {
  constructor(props) {
    super(props);
    this.hasNotApprovedItems = this.props.authorization.items.filter(v => v.quantityAuthorized === 0).length > 0;
  }
  customCellrenderNotApproved = (item) => (
    <span onClick={()=> this.props.message(item.reponseDescription)}>
        <i className="material-icons">warning</i>
    </span>
  );
  customCellrenderApproved = (item) => (
      <i className="material-icons approved">check_circle_outline</i>
  );

  getTotal(items) {
    let value = 0;
    items.forEach(p => {
      value += p.priceSale;
    });
    return value;
  }

  getTotalSubsidy(items) {
    let value = 0;
    items.forEach(p => {
      value += p.priceSubsidyGovernment;
    });
    return value;
  }

  getTotalPatient(items) {
    let value = 0;
    items.forEach(p => {
      value += p.priceSubsidyPatient;
    });
    return value;
  }
  getRowApproved = (item, index) => {
    return (
      <div  className="flexcontainer" key={index}>
        <div className="flexheader">
          <div className="status">STATUS</div>
          <div className="product">PRODUTO</div>
          <div>QUANT SOLICITADA</div>
        </div>
        <div className="flexrow" >
          <div className="status" >{this.customCellrenderApproved(item)}</div>
          <div className="product" >{item.name}</div>
          <div >{item.prescriptionQuantity}</div>
        </div>
        <div className="flexheader">
          <div>QUANT AUTORIZADA</div>
          <div>PREÇO</div>
          <div>SUBSÍDIO</div>
          <div>VLR CLIENTE</div>
          <div></div>
        </div>
        <div className="flexrow" >
          <div >{item.quantityAuthorized}</div>
          <div >{currencyString(item.priceSale)}</div>
          <div >{currencyString(item.priceSubsidyGovernment)}</div>
          <div >{currencyString(item.priceSubsidyPatient)}</div>
          <div >{item.customMessage}</div>
        </div>
      </div>
    );
  }
  getRowNotApproved = (item, index) => {
    return (
      <div className="flexcontainer" key={index}>
        <div className="flexheader notapproved">
          <div className="status"> STATUS</div>
          <div className="product">PRODUTO</div>
          <div>QTD SOLICITADA</div>
          <div>QTD AUTORIZADA</div>
          <div className="flexcell"></div>
        </div>
        <div className="flexrow notapproved" >
          <div className="status">{this.customCellrenderNotApproved(item)}</div>
          <div className="product">{item.name}</div>
          <div>{item.prescriptionQuantity}</div>
          <div>{item.quantityAuthorized}</div>
          <div>{(item.customMessage)}</div>
        </div>
      </div>
    );
  }
  render() {
    const {
      title,
      confirm,
      close,
    } = this.props;

    const { items } = this.props.authorization;

    const preAuthorization = [{
      number: this.props.authorization.number,
      people: this.props.authorization.people
    }];

    return (
      <FarmaciaPopularWrapper>
        <Card title={title} closeEnable handleClose={close}>
            <HeadWrapper>
              <Table config={tableAuthorization} data={preAuthorization} />
              <Summary>
                <div className="subtotal-wrapper">
                  <label>Total Venda</label>
                  <strong>{currencyMask(this.getTotal(items))}</strong>
                </div>
                <div className="discount-wrapper">
                  <label style={{ fontWeight: 'bold' }}>Total Subsídio</label>
                  <strong>{currencyMask(this.getTotalSubsidy(items))}</strong>
                </div>
                <div className="total-wrapper">
                  <label>Total Cliente</label>
                  <strong>{currencyMask(this.getTotalPatient(items))}</strong>
                </div>
              </Summary>
            </HeadWrapper>
            <h3>Autorizados</h3>
            <div>
              {
                items.filter(v => v.quantityAuthorized> 0).map((m,i)=> this.getRowApproved(m,i))
              }
            </div>  
            { this.hasNotApprovedItems &&
              <Fragment>
                <h3>Não Autorizados</h3>
                <div>
                  {
                    items.filter(v => v.quantityAuthorized === 0).map((m,i)=> this.getRowNotApproved(m,i))
                  }
              </div>  
              </Fragment>
            } 
            <div className="custom-form-row actions">
              <Button className="btn-outline" handleClick={confirm}>CONFIRMAR</Button>
            </div>
        </Card>
      </FarmaciaPopularWrapper>
    );
  }
}

FarmaciaPopularConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  authorization: PropTypes.objectOf(PropTypes.any).isRequired,
  confirm: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default FarmaciaPopularConfirm;
