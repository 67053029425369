import React, { Fragment } from 'react';
import { Button } from '../modules';
import { Form, Col } from 'react-bootstrap';
import { PasswordInput } from '../password';
import './pbm-config-trncentre-component.scss';

function PbmConfigTrnCentreComponent(props) {
    const {
        form,
        handleChange,
        handleConfirm,
        handleLoadTable
    } = props;

    return (
        <Fragment>
            <div id="bodytrncentre" className="body">
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="cnpj">
                            <Form.Label>CNPJ de comunicação</Form.Label>
                            <Form.Control
                                type='number'
                                pattern='[0-9]'
                                placeholder="CNPJ de comunicação"
                                defaultValue={form?.cnpj}
                                onChange={(e) => handleChange(e)}
                                onKeyPress={(e) => {
                                    const keyCode = e.keyCode || e.which;
                                    const keyValue = String.fromCharCode(keyCode);
                                    if (e.target.value.length > 13)
                                        e.preventDefault();
                                    if (/\+|-|\.|,|e|E/.test(keyValue))
                                        e.preventDefault();
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="baseUrl">
                            <Form.Label>Base Url</Form.Label>
                            <Form.Control
                                placeholder="URL"
                                defaultValue={form?.baseUrl}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="clientId">
                            <Form.Label>Client Id</Form.Label>
                            <Form.Control
                                placeholder="ID de autenticação do web service"
                                defaultValue={form?.clientId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="clientSecret">
                            <Form.Label>Client Secret</Form.Label>
                            <PasswordInput value={form?.clientSecret} onChange={(e) =>
                                handleChange({ target: { id: 'clientSecret', value: e.target.value } })
                            }></PasswordInput>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="apiVersion">
                            <Form.Label>Api Version</Form.Label>
                            <Form.Control
                                placeholder="Versão da Api"
                                defaultValue={form?.apiVersion}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="centralHour">
                            <Form.Label>Última carga de tabelas</Form.Label>
                            <Form.Control
                                placeholder="Não executada"
                                disabled="true"
                                defaultValue={form?.centralHour}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <div>
                            <Button id="loadtables" className="btn btn-large btn-purple btn-custom" onClick={handleLoadTable}>Executar carga de tabelas</Button>
                        </div>
                    </Form.Row>
                </Form>
            </div>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
            </div>
        </Fragment>
    );
}
export default PbmConfigTrnCentreComponent;