import React, { Component } from 'react';
import MainWrapper from './style';
import { connect } from 'react-redux';
import { ORDER_DETAILS } from '../../../redux/actions/routerAction';

class Main extends Component {
  render() {
    const { viewManager } = this.props;

    const openedCards = Object.keys(viewManager).filter(
      (key) => viewManager[key]
    );
    const shouldShow =
      openedCards.length <= 2 &&
      (!openedCards[0] ||
        openedCards[0] === ORDER_DETAILS ||
        openedCards[0] === 'showShortCutMenu');

    const { children, retailer } = this.props;
    return (
      <MainWrapper className={shouldShow ? this.props.fiscalType === 0 && this.props.posType === '2' ? 'bg-homolog' : (retailer.retailer?.status !== 5 ? 'bg-farm' : 'bg-imp') : ''}>
        {children}
      </MainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  viewManager: state.viewManager,
  fiscalType: state.config && state.config.danfe && state.config.danfe.fiscalType,
  posType: state.posType.posType,
  retailer: state.retailer
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
