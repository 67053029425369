import styled from 'styled-components';

const MessageBoxWrapper = styled.div`    
    display: block;
    height: 100%;
    text-align: center;

    #title {
        font-size: 1.17em;
        font-family: Dosis;
        font-weight: bold;
    }

    p{
        letter-spacing: .5px;
        font-size: 20px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 450px;
    }

    .modal__footer {
        display: flex;
        justify-content: center;
        > div { margin: 0 10px; }
    }
`;
export default MessageBoxWrapper;
