import React, { useState } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import PopupMenu from '../popupmenu';
import { Container } from './styles';

function Row({
  item,
  handleChangeSelected,
  handleRecovery,
  sendWhatsApp,
  sendEmail,
  copyToClipBoard,
  handleRefreshPaymentLink,
  handleSendToPosConnect,
  handleCancel,
  getName,
  posType,
}) {
  const iconRef = React.createRef();
  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const [openTop, setOpenTop] = useState(false);

  function handleClick(event) {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setShowPopupMenu(false);
    }
  }

  function handlePopupMenu(e) {
    setOpenTop(false);
    setShowPopupMenu(!showPopupMenu);

    if (window.innerHeight < 900 && e.clientY / window.innerHeight >= 0.65) {
      setOpenTop(true);
    } else if (e.clientY / window.innerHeight >= 0.8) {
      setOpenTop(true);
    }
  }

  return (
    <Container
      tabIndex='0'
      className='selectable'
      onClick={() => handleChangeSelected(item)}
    >
      <td>
        <i
          className={`material-icons ${item.selected ? 'active' : 'checkbox'}`}
        >
          {item.selected ? 'check_box' : 'check_box_outline_blank'}
        </i>
      </td>
      <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</td>
      <td>{getName(item.deliveryInfo.deliveryStatus)}</td>
      <td>{item.deliveryInfo.deliveryCustomer.name}</td>
      <td>
        {`${item.deliveryInfo.deliveryCustomer.deliveryCustomerAddress?.street} ${item.deliveryInfo.deliveryCustomer.deliveryCustomerAddress?.neighborhood}`}
      </td>
      <td>{item.currentSellerName}</td>
      <td>
        <i
          data-testid='delivery-options'
          ref={iconRef}
          className='material-icons'
          onClick={(e) => handlePopupMenu(e)}
        >
          more_vert
        </i>
        {showPopupMenu && (
          <PopupMenu
            item={item}
            handleRecovery={handleRecovery}
            sendWhatsApp={sendWhatsApp}
            sendEmail={sendEmail}
            copyToClipBoard={copyToClipBoard}
            handleRefreshPaymentLink={handleRefreshPaymentLink}
            handleSendToPosConnect={handleSendToPosConnect}
            handleCancel={handleCancel}
            onClick={handleClick}
            posType={posType}
            openTop={openTop}
          />
        )}
      </td>
    </Container>
  );
}

Row.propTypes = {
  item: PropTypes.shape({
    deliveryInfo: PropTypes.shape({
      deliveryCustomer: PropTypes.shape({
        deliveryCustomerAddress: PropTypes.shape({
          street: PropTypes.string,
          neighborhood: PropTypes.string,
        }),
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      deliveryStatus: PropTypes.number,
    }),
    paymentLinkInfo: PropTypes.shape({
      paymentLinkValues: PropTypes.shape({
        accessUrl: PropTypes.string,
      }),
    }),
    selected: PropTypes.bool,
    createdAt: PropTypes.string,
    currentSellerName: PropTypes.string,
  }).isRequired,
  handleChangeSelected: PropTypes.func.isRequired,
  handleRecovery: PropTypes.func.isRequired,
  sendWhatsApp: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  copyToClipBoard: PropTypes.func.isRequired,
  handleRefreshPaymentLink: PropTypes.func.isRequired,
  handleSendToPosConnect: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  getName: PropTypes.func.isRequired,
  posType: PropTypes.string,
};

export default Row;
