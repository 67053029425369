import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { CpfCnpjForm } from '../../shared/components/modules';
import {
  MaskCNPJ, MaskCPF, ValidateCNPJ, ValidateCPF, strip, stringErrors,
} from '../../shared/utils/modules';
import PaymentService from '../../services/paymentService';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { showCard, closeAllCards, DOCUMENT_BOX, shouldShow, getPayload } from '../../redux/actions/routerAction';

class CpfCnpjContainer extends Component {
  constructor(props) {
    super(props);

    this.props.MessageBus.Subscribe('pos.documentNumber.show', this.props.openDocumentBox);
    this.props.MessageBus.Subscribe('pos.documentNumber.back', this.props.closeDocumentBox);
    this.props.MessageBus.Subscribe('pos.documentNumber.failed', (canal, payload) => this.eventFailedReturn(payload));
    this.props.MessageBus.Subscribe('pos.order.deleted', this.props.closeDocumentBox);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.state = {
      customerDocument: '',
    };

    this.mascaraCPF = true;

    this.cpfCnpjComponentRef = React.createRef();
  }

  eventFailedReturn = (payload) => {
    this.context.showMessage(`${(payload ? stringErrors(payload) : 'Erro ao informar CPF')}`);
  }

  setFocusInput = () => {
    this.cpfCnpjComponentRef.current.focusInput();
  }

  showContextMessage = (message) => {
    const htmlMessage = `<p style="width: 200px;"><b>${message}</b></p>`

    setTimeout(() => this.context.showMessage(htmlMessage, 'Atenção', this.setFocusInput), 100);
  }

  handleKeyUp = (e) => {
    let value = e.target.value;

    this.setState({ customerDocument: value });

    // keyCode 8 refers to backspace
    if (e.keyCode !== 8) {
      if (strip(value, true).length < 12) {
        value = MaskCPF(value);
        this.mascaraCPF = true;
      } else {
        value = MaskCNPJ(value);
        this.mascaraCPF = false;
      }
    } else if (value.length === 14 && !this.mascaraCPF) {
      value = MaskCPF(e.target.value);
      this.mascaraCPF = true;
    }
    e.target.value = value;
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handlerSubmit(e.target.value);
    }
  }

  getFormatedDocument = (customerHasCnpj) => {
    if (!this.props.customer) return '';

    let formatedValue = '';

    if (customerHasCnpj) {
      formatedValue = MaskCNPJ(this.props.customer.cpf);
      formatedValue = "**.***" + formatedValue.substring(6);
    } else {
      formatedValue = MaskCPF(this.props.customer.cpf);
      formatedValue = "***.***" + formatedValue.substring(7);
    }

    return formatedValue;
  }

  handleConfirm = () => {
    this.handlerSubmit(this.state.customerDocument);
  }

  handlerSubmit = (value) => {
    if (value && strip(value, true).length <= 11 && !ValidateCPF(value)) {
      this.showContextMessage('CPF inválido!');
      return;
    }
    if (value && strip(value, true).length > 11 && !ValidateCNPJ(value)) {
      this.showContextMessage('CNPJ inválido!');
      return;
    }
    const service = new PaymentService();
    service.addCpfCnpj(value)
      .catch(error => this.context.showError(error));
    this.setState({ customerDocument: null });
  }

  handleClose = () => {
    this.setState({ customerDocument: null });
    const service = new PaymentService();
    service.backPayment()
      .catch(err => this.context.showError(err));
  }

  render() {
    const customerHasCnpj = this.props.customer?.cpf?.length > 11;
    const labelCpfCnpj = this.props.customer ? "Informar outro CPF ou CNPJ" : "Informe o CPF ou CNPJ";

    return (
      <Fragment>
        {this.props.showDocumentBox && (
          <CpfCnpjForm
            ref={this.cpfCnpjComponentRef}
            handleKeyUp={(...parms) => this.handleKeyUp(...parms)}
            handleClick={this.handlerSubmit}
            handleClose={() => this.handleClose()}
            handleKeyDown={this.handleKeyDown}
            handleConfirm={this.handleConfirm}
            customer={this.props.customer}
            customerDocument={this.state.customerDocument}
            labelDocument={customerHasCnpj ? 'CNPJ' : 'CPF'}
            labelCpfCnpj={labelCpfCnpj}
            formatedDocument={this.getFormatedDocument(customerHasCnpj)}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showDocumentBox: shouldShow(state, DOCUMENT_BOX),
  payload: getPayload(state, DOCUMENT_BOX),
  customer: state.order.order?.customer
});

const mapDispatchToProps = dispatch => ({
  openDocumentBox: () => dispatch(showCard(DOCUMENT_BOX)),
  closeDocumentBox: () => dispatch(closeAllCards())
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(CpfCnpjContainer)));
