import React from 'react';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import Toast from 'react-bootstrap/Toast';
import Col from 'react-bootstrap/Col';
import './toast.style.scss';

export class ToastContainer extends React.Component {
  constructor(props) {
    super(props);

    this.props.MessageBus.Subscribe('front.toast.show', this.addToast);

    this.state = {
      toastList: []
    };
  }

  addToast = (ch, toast) => {
    toast.key = `toast-${this.state.toastList.length}`;
    const toastList = this.state.toastList;
    toastList.push(toast);
    this.setState({
      toastList: toastList
    });
  }

  getToastInfo = (type) => {
    switch(type) {
      case 0:
        return {class: 'success', icon: 'check_circle_outline'};
      case 1:
        return {class: 'warning', icon: 'warning'};
      case 2: 
        return {class: 'error', icon: 'error_outline'};
      case 3: 
      default:
        return {class: 'info', icon: 'info'};
    }
  }

  handleClose = (key) => {
    const toastList = this.state.toastList;
    const idx = this.state.toastList.findIndex(t => t.key === key);
    toastList.splice(idx, 1);
    this.setState({
      toastList: toastList
    })
  }

  renderToast = (toast) => {
    const info = this.getToastInfo(toast.type);
    return (
      <Toast
        id={toast.key}
        key={toast.key}
        show={true}
        onClose={() => {this.handleClose(toast.key)}}
        className={info.class}
        autohide={!!toast.hideAfterMilliseconds}
        delay={toast.hideAfterMilliseconds}
      >
        <Toast.Header>
          <i className="header-icon material-icons">{info.icon}</i>
          <strong className="header-title mr-auto">{toast.title}</strong>
        </Toast.Header>
        {toast.message && <Toast.Body>{toast.message}</Toast.Body>}
        {toast.html && <Toast.Body dangerouslySetInnerHTML={{ __html: toast.html }}/>}
      </Toast>
    )
  }

  render() {
    return (
      <Col className="toast-group">
        {this.state.toastList.map(this.renderToast)}
      </Col>
    )
  }
}


export default withMessageBus(getContext(ToastContainer));