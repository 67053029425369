import React from 'react';
import PropTypes from 'prop-types';
import LabelTitleValue from '../label-title-value';
import PdvInformationWrapper from './style';

const PdvInformation = props => (
  <PdvInformationWrapper data-testid="text-pdvInformation">
    <LabelTitleValue title="Terminal: " value={props.PosName} />
    {/* {props.posCash && (
      props.openingCount
        ? <LabelTitleValue title="ABERTURA" value={props.openingCount} />
        : <LabelTitleValue title="Caixa" value={'FECHADO'} />
    )} */}
  </PdvInformationWrapper>);

PdvInformation.propTypes = {
  PosName: PropTypes.string.isRequired,
  openingCount: PropTypes.string,
};
export default PdvInformation;
