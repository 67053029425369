import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showCard, AUTHORIZATION_DETAIL,TEF_INTERACTION, shouldShow, getPayload, closeCardWithoutHideOthers } from '../../redux/actions/routerAction';
import PaymentService from '../../services/paymentService';
import { TefInteraction } from '../../shared/components/modules';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { show } from '../../redux/actions/loadingScreenAction';

class TefInteractionContainer extends Component {
  constructor(props) {
    super(props);

    this.service = new PaymentService();

    this.onHandleClickFin = this.onHandleClickFin.bind(this);

    this.handleClose = this.handleClose.bind(this);

    this.eventTefMessage = this.eventTefMessage.bind(this);

    if (window.desktopApp) {
      window.desktopApp.subscribe('machine.devices.tef.requestdata', (channel, msg) => {
        this.eventTefMessage(msg);
      });
    }
  }

  eventTefMessage(payload) {
    const componentPayload = {
      title: 'TEF',
      subTitle: payload.header,
      image: './assets/images/payment-types/creditcard.svg',
      inputCard: payload
    };

    this.props.openTefInteraction(componentPayload);
  }

  onHandleClickFin(code) {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.tef.requestdata.completed', { value: code.replace(/[_ /]+/g, '') });
      this.props.closeTefInteraction();
    }
  }

  handleClose() {
    if (window.desktopApp) {
      this.props.show('Carregando...');
      window.desktopApp.publish('machine.devices.tef.cancel.pending.operation', '');
    }
  }

  render() {
    return (
      this.props.showTefInteraction && (
        <TefInteraction
          handleClickFin={this.onHandleClickFin}
          handleClose={this.handleClose}
          title={this.props.payload.title}
          subTitle={this.props.payload.subTitle}
          image={this.props.payload.image}
          typeInput={this.props.payload.inputCard}
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  payload: getPayload(state, TEF_INTERACTION),
  showTefInteraction: shouldShow(state, TEF_INTERACTION)
});

const mapDispatchToProps = dispatch => ({
  openTefInteraction: payload => dispatch(showCard(TEF_INTERACTION, payload)),
  closeTefInteraction: () => {
      dispatch(closeCardWithoutHideOthers(TEF_INTERACTION));
      dispatch(showCard(AUTHORIZATION_DETAIL));
    },
  show: param => dispatch(show({ text: param })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(TefInteractionContainer)));

