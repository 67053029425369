import React, { Component } from 'react';
import './config/Reactotron';
import { Provider } from 'react-redux';
import Configuration from './configuration';
import {
  UPDATE_SHOULD_AUTHENTICATE_OPERATION,
  UPDATE_CONFIG,
  HAS_CONFIG_RETAILER_FP,
  CONFIG_RETAILER,
  RETAILER,
  POS_TYPE,
  PDV_CONTINGENCY_STATUS,
  CLOSE_ALL
} from './redux/actions/actionTypes';
import Store from './redux/store';
import Routes from './routes/index';
import AuthService from './services/authService';
import AdministrativeService from './services/AdministrativeService';
import httpAccess from './services/httpAccess';
import MessageBusService from './services/MessageBusService';
import { CacheFinder } from './shared/components/auto-complete/cache-finder';
import MachineCommunicator from './shared/helpers/machineCommunicator';
import './custom.scss';
import configService from './services/configService';
import retailerService from './services/retailerService';
import { showInfoModal } from './shared/utils/util'
import { clearConnections } from './redux/modules/connectionPool/duck';

class App extends Component {
  static _instance;

  constructor(props) {
    super(props);

    this.subscription = null;
    this.Start();

    MessageBusService.GetInstance().Subscribe(
      'pos.disconnect',
      this.disconnect
    );
    if (window.desktopApp) {
      window.desktopApp.subscribe('machine.devices.desktop.config', this.handleConfig);
      window.desktopApp.subscribe('machine.devices.desktop.getConfigs', this.handleGetConfigs);
      //via desktopapp só será iniciada conexão com hub no evento connecttohub que recebe se existe conexão com cloud ou não
      window.desktopApp.subscribe('connecttohub',
        (topic, message) => {
          Store.dispatch({
            type: PDV_CONTINGENCY_STATUS,
            isOnline: message.isOnline
          });
          console.log('Initializing Hub isOnline:' + message.isOnline);
          this.ConnectToHub(message.isOnline);
        });
    }
    else if (Configuration.endPointContingency === undefined) {
      //fora do desktopapp somente inicia hub se não informado hub contingencia
      this.ConnectToHub(true);
    }

    this.authenticated = false;
  }

  async ConnectToHub(onLine) {
    MessageBusService.GetInstance().CreateConnection(onLine);
  };

  async Start() {
    try {
      window.sessionStorage.removeItem('Access_Token');
      MessageBusService.GetInstance().Subscribe(
        'pos.state.multipleKeys',
        this.handleMultiple.bind(this)
      );
      MessageBusService.GetInstance().Subscribe(
        'pos.clear.token',
        this.clearToken.bind(this)
      );
      MessageBusService.GetInstance().Subscribe(
        'pos.wsConnected',
        this.handleWsConnected
      );
      MessageBusService.GetInstance().Subscribe(
        'authenticate.pos',
        this.authPos
      );

      MessageBusService.GetInstance().Subscribe(
        'pos.contingency.startSynchronization',
        this.startContingencySynchronization.bind(this)
      );

      MessageBusService.GetInstance().Subscribe(
        'pos.contingency.finishedSynchronization',
        this.finishedContingencySynchronization.bind(this)
      );
      MessageBusService.GetInstance().Subscribe(
        'pos.contingency.changeFinished',
        () => {
          //fecha todos os cards
          Store.dispatch({ type: CLOSE_ALL });
          showInfoModal({ message: null });
        }
      );

      MachineCommunicator.initialize();
    } catch (err) {
      console.error(err);
      if (err.status !== 200) {
        MessageBusService.GetInstance().Publish('pos.dialog', {
          message: 'PosId não autorizado',
        });
      }
    }
  }

  handleWsConnected = () => {
    if (this.authenticated) return;
    this.authenticated = true;
    MessageBusService.GetInstance().Publish('machine.devices.wsConnected');

    new AdministrativeService().getPosType(Configuration.retailerId, Configuration.PosId)
      .then((res) => {
        Store.dispatch({
          type: POS_TYPE,
          payload: res,
        });
        this.authPos(res);
      });
  };

  authPos = (posType) => {
    const bus = MessageBusService.GetInstance();
    AuthService.authenticatePos(posType)
      .then((res) => {
        if (!res) return;

        Store.dispatch({
          type: HAS_CONFIG_RETAILER_FP,
          payload: res.hasFarmaciaPopularConfig,
        });

        Store.dispatch({
          type: UPDATE_CONFIG,
          config: { ...Store.getState().config },
        });

        this.setFiscalFlowUrlIfNotExists();
        this.getRetailerInfo();


        if (res && res.shouldAuthenticateOperation) {
          Store.dispatch({
            type: UPDATE_SHOULD_AUTHENTICATE_OPERATION,
            payload: true,
          });
          return;
        }

        bus.Publish('pos.login.success');
        window.sessionStorage.setItem('Access_Token', res.token);
        this.getStates();
      });
  };
  getRetailerInfo() {
    retailerService
      .get(Configuration.retailerId)
      .then((res) => {
        const { city, state, tradeName, zipCode, id, documentNumber, status } = res;
        Store.dispatch({ type: RETAILER, retailer: { ...{ status, city, state, tradeName, zipCode, id, documentNumber } } });
        Store.dispatch({ type: CONFIG_RETAILER, configRetailer: res.configurations });
      });
  }
  getRetailerConfig() {
    configService
      .getRetailerConfig(Configuration.retailerId)
      .then((res) => {
        Store.dispatch({ type: CONFIG_RETAILER, configRetailer: res })
      }
      );
  }

  setFiscalFlowUrlIfNotExists() {
    if (Store.getState().config.danfe) {
      // dispara evento para salvar fiscalflowurl do config.json para o pos caso não exista salvo no banco
      const { retailerId } = Configuration;
      const posId = Configuration.PosId;
      const configFiscalFlow = {
        Name: 'FiscalFlowUrl',
        Value:
          Store.getState().config.danfe.fiscalType === 1
            ? Store.getState().config.danfe.MIDeUrl
            : Store.getState().config.danfe.sat.url,
      };
      configService.insertConfigIfNotExists(
        retailerId,
        posId,
        configFiscalFlow
      );
    }
  }

  getStates() {
    MessageBusService.GetInstance().Subscribe(
      'pos.reload',
      this.handleMultiple.bind(this)
    );
    httpAccess.get(`${Configuration.endPoint}/posstate`);
    new CacheFinder().start();
  }

  handleMultiple(event, payload) {
    payload.forEach((e) => {
      MessageBusService.GetInstance().Publish(e.routingKey, e.data);
    });
  }

  clearToken() {
    window.sessionStorage.removeItem('Access_Token');
  }

  startContingencySynchronization() {
    //Limpa qualquer connection que esteja na lista do pool de conexões
    Store.dispatch(clearConnections());
    showInfoModal({ message: "Sincronizando vendas para o servidor, aguarde..." });
  }

  finishedContingencySynchronization() {
    //fecha todos os cards
    Store.dispatch({ type: CLOSE_ALL });
    showInfoModal({ message: null });
  }

  disconnect = () => {
    window.location.replace(
      `${Configuration.endPoint}/Authentication/Disconnect`
    );
  };

  handleConfig = (_ch, config) => {
    config = {
      ...config,
      configurations:
        Store.getState().config && Store.getState().config.configurations,
    };
    Store.dispatch({ type: UPDATE_CONFIG, config });
  };

  handleGetConfigs = (_ch, config) => {
    new AdministrativeService().getConfigs(config.retailerId, config.posId)
      .then((res) => {
        MessageBusService.GetInstance().Publish('machine.devices.getConfigsReturn', res);
      });
  };

  render() {
    return (
      <Provider store={Store}>
        <Routes />
      </Provider>
    );
  }
}
export default App;
