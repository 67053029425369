import styled from 'styled-components';

export const CancelOrderListWrapper = styled.div`
    .scrollable 
    {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }
`;

export const Filters = styled.div`
   display: flex;
   justify-content: flex-end;

   input {       
       height: 30px;
       padding-left: 10px;
   
       &:nth-child(2) {
           margin-left: 10px;
       }
    }

    .refresh {
        margin: -5px 0 0 10px;
        color: #5b2e90;
        font-size: 36px;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;

        i {
            &:hover {
                cursor: pointer;
                color: #FFB201;
            }
            padding-bottom: 4px;
        }
    }
`;

