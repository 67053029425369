import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  margin: 1rem;
  margin-top: 0;
`;

export const ContainersShortcuts = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardShortcut = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  flex: 1;
  margin: 0.5rem;

  height: 100px;

  &:hover {
    cursor: pointer;
  }

  span.title {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  }
`;
