import styled from 'styled-components';

import theme from '../../../themes';

export const ItemContainer = styled.div`
  position: relative;

  .item-name-tooltip {
    display: block;
    opacity: 1;
    position: absolute;
    z-index: 999;
    left: 10px;
    background-color: #fff;
    border: 1px solid #5b2e90;
    border-radius: 4px;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.2); 
    min-height: 30px;
    width: 320px;
    padding: 3px 4px;
    transition: all ease-in-out 200ms;

    transform: translateY(-85%);
  }

  .item-name-tooltip.first-item {
    transform: translateY(35px);
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 15px;
  align-items: center;
  overflow: hidden;

  justify-content: space-between;

  &:focus,
  &:hover,
  &.active {
    background-color: #ffb200;
    cursor: pointer;
  }

  border-bottom: ${props => props.hasBorderBottom ? "1px solid #eee" : "none"};
  height: ${props => props.hasAddInfo ? "65px" : "40px"};
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  min-width: 0;

  margin-bottom: ${props => props.hasOnlyDiscount ? "26px" : "0"};
`;

export const ItemDesc = styled.div`
  ${props => props.hovered ? "font-weight: bold" : ""}

  display: flex;

  .item-qtde {
    margin-right: 8px;
    white-space: nowrap;
  }

  .item-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }
`;

export const ExtraInfo = styled.div`
  ${props => props.hovered ? "font-weight: bold" : ""}
  color: ${theme.theme2.palette.link};
  text-decoration: underline;
  margin: 4px 0;

  .tooltip-text {
    visibility: hidden;
    width: 300px;    
    background-color: ${theme.theme2.palette.brandLinx};
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;    
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  p:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    margin-left: 30px;
    margin-top: -35px;
    position: fixed;
  }

  .tooltip-image {
    width: 30px;
    height: 30px;
  }

  p {
    margin: 5px 0 0 0;
    display: flex;

    img {
      margin-right: 5px;
    }

    span {
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${({ hasMoreInfo }) => hasMoreInfo && `
    p {
      padding: 10px 0;
      border-bottom: 1px solid #e0e0e0;
    }
  `}
`;

export const ItemValues = styled.div`
  text-align: right;
  float: right;

  .item-total-desc {
    font-weight: bold;
  }
`;