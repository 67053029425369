import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  online: false,
};

const farmaciaPopularStatusReducer = createReducer(initialState, {
[types.CHECK_STATUS_FUNCIONAL_CARD]: (state, action) => ({
    ...state,
    online: action.payload,
  }),
});

export default farmaciaPopularStatusReducer;