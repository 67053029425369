import styled from 'styled-components';

export const CardPaymentWrapper = styled.div`
  width: 75%;
  margin-left: 15%;
`;

export const PaymentAuthorizationWrapper = styled.div`
  margin: 30px 20px 15px 20px;
  height: 200px;
  position: relative;

  .btn-finish {
    position: absolute;
    bottom: 0;
    width: 150px;
    height: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PaymentAuthorizationHeader = styled.header`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

export const PaymentAuthorizationInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
`;

export const PaymentAuthorizationInput = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    width: calc(100% - 40px);
    height: 50px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    border: 2px solid #eee;
  }

  span {
    position: absolute;
    bottom: -25px;
    color: red;
    font-size: 13px;
  }
`;
