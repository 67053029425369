import React from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules';
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import { OfferModalWrapper } from './style';
import { OperationEnum } from '../../enums/operation';
import AuthorizationOnline from '../authorization-online/authorization-online';
import { showToast } from '../../../shared/utils/util';
export class OfferCoverage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
    };

    this.modalOfferCoverage = React.createRef();
  }

  componentDidMount() {
    this.modalOfferCoverage.current.open();
    document.querySelector('#inputDiscountModal') &&
      document.querySelector('#inputDiscountModal').focus();
    document.addEventListener('click', this.handleCloseOfferModal);
    document.addEventListener('keydown', this.handleCloseOfferModal);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleCloseOfferModal);
    document.removeEventListener('click', this.handleCloseOfferModal);
  }

  handleCloseOfferModal = (e) => {
    if (!this.modalOfferCoverage.current.state.show) {
      this.props.handleCloseModalOfferCoverage(e);
    }
  };

  handleKeyDown = (e) => {
    const items = document.querySelectorAll('input[data="offer"]');

    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      this.previous(items);
    }
    if (e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'Enter') {
      this.next(items);
    }
    if (
      e.key === 'Enter' &&
      (e.target.id === 'inputPassword' ||
        (this.props.loggedUserHasPermission && e.target.id === 'inputReason'))
    ) {
      this.props.handleConfirmOfferCoverage();
    }
  };

  previous = (items) => {
    if (this.state.index === 0) {
      items[0].focus();
    } else {
      var idx = this.state.index - 1;
      items[idx].focus();
      this.setState({ index: idx });
    }
  };

  next = (items, e) => {
    if (this.state.index === items.length - 1) {
      items[items.length - 1].focus();
    } else {
      var idx = this.state.index + 1;
      items[idx].focus();
      this.setState({ index: idx });
    }
  };

  clickInput = (e) => {
    const items = document.querySelectorAll('input[data="offer"]');
    var arrayInputs = [];

    items.forEach((item) => {
      arrayInputs.push(item);
    });

    var idx = arrayInputs.indexOf(e.target);
    this.setState({ index: idx });
  };

  validateFieldsToAuthorizationOnline = () => {
    if (
      !this.props.discountPercentOffer ||
      !this.props.subTotalOffer ||
      !this.props.reasonDiscountOffer
    ) {
      showToast({
        type: 2,
        title: 'Atenção!',
        html: 'os campos % Desconto, Valor total e Motivo são obrigatórios.',
        hideAfterMilliseconds: 5000,
      });

      return false;
    }

    return true;
  };

  render() {
    const {
      handleClose,
      handleCloseModalOfferCoverage,
      handleChangeOfferModal,
      handleChangeDiscountPercentOffer,
      handleChangeSubTotalOffer,
      handleFindUser,
      handleConfirmOfferCoverage,
      verifyDiscountPercentageLimits,
      offerModalIsVisible,
      loggedUserHasPermission,
      userWithoutPermission,
      messageErrorOfferCoverage,
      inputError,
      discountPercentOffer,
      subTotalOffer,
      reasonDiscountOffer,
      codeUserOffer,
      nameUserOffer,
      passwordUserOffer,
      showAutorizationOnline,
      productPrice,
      factoryPrice,
      showAditionalInfo,
      margemLucro
    } = this.props;

    return (
      <OfferModalWrapper status={`${reasonDiscountOffer.length}%`}>
        <Modal
          title='COBERTURA DE OFERTAS'
          id='offer-discount-component'
          closable={false}
          closeOnOverlayClick
          ref={this.modalOfferCoverage}
          opened={offerModalIsVisible}
          onClickClose={handleClose}
          className='offer-modal'
          style={{
            width: '35vw',
          }}
        >
          <Form autoComplete='off'>
            <Modal.Body className='offer-modal-form'>
              <Form.Group className='offer-modal-discount'>
                <Form.Group className='offer-modal-discount-items'>
                  <Form.Label>% Desconto</Form.Label>
                  <CurrencyInput
                    id='inputDiscountModal'
                    data='offer'
                    allowNegative
                    suffix='%'
                    value={discountPercentOffer}
                    className={`discount-input ${inputError.message && 'red-color'
                      }`}
                    decimalSeparator=','
                    maxLength='6'
                    thousandSeparator='.'
                    required
                    onChange={handleChangeDiscountPercentOffer}
                    onKeyDown={this.handleKeyDown}
                    onBlur={verifyDiscountPercentageLimits}
                    onClick={this.clickInput}
                  ></CurrencyInput>
                  <span className='input-message-error'>
                    {inputError.message}
                  </span>
                </Form.Group>

                <Form.Group className='offer-modal-discount-items'>
                  <Form.Label>Valor total</Form.Label>
                  <CurrencyInput
                    id='inputTotal'
                    data='offer'
                    allowNegative
                    prefix='R$'
                    value={subTotalOffer}
                    className={`discount-input ${inputError.message && 'red-color'
                      }`}
                    decimalSeparator=','
                    maxLength={11}
                    thousandSeparator='.'
                    required
                    onChange={handleChangeSubTotalOffer}
                    onKeyDown={this.handleKeyDown}
                    onBlur={verifyDiscountPercentageLimits}
                    onClick={this.clickInput}
                  ></CurrencyInput>
                </Form.Group>
              </Form.Group>
              {showAditionalInfo &&
                <Form.Group className='offer-modal-discount'>
                  <Form.Group className='offer-modal-discount-items'>
                    <Form.Label>Preço de Custo</Form.Label>
                    <CurrencyInput
                      disabled={true}
                      allowNegative
                      prefix='R$'
                      value={factoryPrice}
                      className={`discount-input`}
                      decimalSeparator=','
                      thousandSeparator='.'
                    ></CurrencyInput>
                  </Form.Group>
                  <Form.Group className='offer-modal-discount-items'>
                    <Form.Label>Margem de Lucro</Form.Label>
                    <CurrencyInput
                      disabled={true}
                      allowNegative
                      suffix='%'
                      value={margemLucro}
                      className={`discount-input`}
                      decimalSeparator=','
                      thousandSeparator='.'
                    ></CurrencyInput>
                  </Form.Group>
                </Form.Group>
              }
              <Form.Group className='offer-modal-discount-items'>
                <Form.Label>Motivo</Form.Label>
                <Form.Control
                  id='inputReason'
                  data='offer'
                  required
                  type='text'
                  placeholder='Informe o motivo do desconto'
                  name='reasonDiscountOffer'
                  value={reasonDiscountOffer}
                  onChange={handleChangeOfferModal}
                  onKeyDown={this.handleKeyDown}
                  onClick={this.clickInput}
                ></Form.Control>
                <span
                  className={
                    reasonDiscountOffer.length === 100
                      ? 'counter limit'
                      : 'counter'
                  }
                >
                  {reasonDiscountOffer.length} / 100
                </span>
                <div className='status-reason'></div>
              </Form.Group>

              {!loggedUserHasPermission && showAutorizationOnline?.show && (
                <>
                  <AuthorizationOnline
                    operation={OperationEnum.CoberturaOferta}
                    solicitation={{
                      valorAtual: productPrice,
                      valorNovo: subTotalOffer,
                    }}
                    handleClickConfirm={handleConfirmOfferCoverage}
                    validateFields={() =>
                      this.validateFieldsToAuthorizationOnline()
                    }
                  />

                  <Form.Group
                    id='autorization-online'
                    className='offer-modal-discount '
                  >
                    <Form.Group className='offer-modal-discount-items name-user'>
                      <Form.Label>Usuário</Form.Label>
                      <Form.Control
                        id='inputCodeUser'
                        data='offer'
                        required
                        type='text'
                        name='codeUserOffer'
                        value={codeUserOffer}
                        onChange={handleChangeOfferModal}
                        onKeyDown={this.handleKeyDown}
                        onClick={this.clickInput}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className='offer-modal-discount-items password-user'>
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        id='inputPassword'
                        data='offer'
                        required
                        type='password'
                        name='passwordUserOffer'
                        value={passwordUserOffer}
                        onChange={handleChangeOfferModal}
                        onKeyDown={this.handleKeyDown}
                        onClick={this.clickInput}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Group>
                </>
              )}

              {!loggedUserHasPermission && !showAutorizationOnline?.show && (
                <div>
                  <Form.Group className='offer-modal-discount-items responsible'>
                    <Form.Label>Responsável</Form.Label>
                  </Form.Group>

                  <Form.Group className='offer-modal-discount'>
                    <Form.Group className='offer-modal-discount-items code-user'>
                      <Form.Label>Código</Form.Label>
                      <Form.Control
                        id='inputCodeUser'
                        data='offer'
                        required
                        type='text'
                        name='codeUserOffer'
                        value={codeUserOffer}
                        onChange={handleChangeOfferModal}
                        onKeyDown={this.handleKeyDown}
                        onClick={this.clickInput}
                        onBlur={handleFindUser}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className='offer-modal-discount-items name-user'>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        id='inputNameUser'
                        data='offer'
                        required
                        type='text'
                        name='nameUserOffer'
                        readonly='true'
                        value={nameUserOffer}
                        onChange={handleChangeOfferModal}
                        onKeyDown={this.handleKeyDown}
                        onClick={this.clickInput}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className='offer-modal-discount-items password-user'>
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        id='inputPassword'
                        data='offer'
                        required
                        type='password'
                        name='passwordUserOffer'
                        value={passwordUserOffer}
                        onChange={handleChangeOfferModal}
                        onKeyDown={this.handleKeyDown}
                        onClick={this.clickInput}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Group>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button
                id='btn-cancel'
                className='btn-default btn-outline btn-cancel'
                onClick={handleCloseModalOfferCoverage}
              >
                Cancelar
              </Button>
              <Button
                id='btn-confirm'
                className='btn-default btn-purple btn-custom'
                onClick={handleConfirmOfferCoverage}
              >
                Confirmar
              </Button>
              {userWithoutPermission && (
                <span className='user-without-permission'>
                  {messageErrorOfferCoverage}
                </span>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </OfferModalWrapper>
    );
  }
}

export default OfferCoverage;
