import React, { Component } from 'react';
import { Card } from '../modules';
import { SaleFinishWrapper } from './style';
import { Button } from '../modules';
import { showToast } from '../../utils/util'

class SaleFinish extends Component {

  componentDidMount() {
    this.btnRef.focus();
  }

  copyToClipBoard(str) {

    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.btnRef.focus();
    showToast({
      type: 0,
      title: 'Link de pagamento',
      html: 'Link Copiado',
      hideAfterMilliseconds: 1000
    });
  }

  sendWhatsApp(number, text) {
    window.open('https://api.whatsapp.com/send?phone=' + number + '&text=' + text)
  }

  sendEmail(email, text) {
    window.location.href = 'mailto:' + email + '?subject=Link%20de%20Pagamento&body=' + text;
    this.btnRef.focus();
  }

  render() {
    const { handleClickDone, title, text, textButton, paymentLinkInfo, saleSuccess, showPrinterButton, handleClickImprimirCupom } = this.props;

    return (
      <Card title={title}>
        <SaleFinishWrapper>
          {saleSuccess ? <img src="./assets/images/venda-checked.svg" alt="" /> : <img src="./assets/images/venda-not-checked.svg" alt="" />}
          &nbsp;&nbsp;
          {paymentLinkInfo ?
            <div className="paymentLinkInfo">LINK DE PAGAMENTO: {paymentLinkInfo.accessUrl}
              <div>Seu link expira em: {paymentLinkInfo.expiryDate}</div>
              <Button
                className="button"
                type="button"
                onClick={() => this.sendWhatsApp('55' + paymentLinkInfo.phone, paymentLinkInfo.sendMessage + ' ' + paymentLinkInfo.accessUrl)}
              >
                <img class="btnimg" src="./assets/images/whatsapp.png" alt="" />
                <span class="tooltiptext">Enviar Link via WhatsApp</span>
              </Button>
              <Button
                className="button"
                type="button"
                onClick={() => this.sendEmail(paymentLinkInfo.email, paymentLinkInfo.sendMessage + ' ' + paymentLinkInfo.accessUrl)}
              >
                <i className="material-icons">
                  mail_outline
                </i>
                <span class="tooltiptext">Enviar via E-mail</span>
              </Button>
              <Button
                className="button"
                type="button"
                onClick={() => this.copyToClipBoard(paymentLinkInfo.accessUrl)}
              >
                <i className="material-icons">
                  content_copy
                </i>
                <span class="tooltiptext">Copiar Link de Pagamento</span>
              </Button>
            </div>
            : <div></div>}
          <div className="sub-title">{text}</div>
          {showPrinterButton ?
            <Button ref={ref => this.btnRef = ref} id="btn_finish" className="btn-large btn-finish" handleClick={handleClickImprimirCupom}>IMPRIMIR CUPOM</Button> : ""}
          <Button ref={ref => this.btnRef = ref} id="btn_finish" className="btn-large btn-finish" handleClick={handleClickDone}>{textButton}</Button>
        </SaleFinishWrapper>
      </Card>
    );
  }
}

export default SaleFinish;
