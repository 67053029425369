import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "../../@form-control";
import { Button } from "../../modules";
import { SelectBox } from "../../select-box";
import { ContinuousUseItems, GroupContinuousUseItems } from "../style";

export const Pathology = ({
  data,
  values,
  setFieldValue,
  handleCreateNew,
  errors,
  touched,
  manage,
}) => {
  const pathologySelect = data
    ?.filter(({ active }) => active)
    .map((item) => {
      return { ...item, name: item.description };
    });

  pathologySelect.sort((a, b) => {
    return a.name?.localeCompare(b.name);
  });

  const handleSelectPathology = (value) => {
    setFieldValue("pathology.id", value?.id || "");
    setFieldValue("pathology.referenceId", value?.referenceId || "");
    setFieldValue("pathology.name", value?.name || "");
  };

  useEffect(() => {
    if (manage?.hasOwnProperty("pathology")) {
      let value = {
        ...manage.pathology,
        name: manage.pathology.description,
      };
      handleSelectPathology(value);
    }
  }, [manage]);

  return (
    <GroupContinuousUseItems margin="8px 0 0 0">
      <ContinuousUseItems
        width="80%"
        required={values.pathology.required}
        hasError={errors.pathology?.name && touched.pathology?.name}
      >
        <FormControl label="Patologia" id="pathology" name="pathology.name">
          <SelectBox
            className="continuous-use-items-input"
            placeholder="Informe a patologia"
            data={pathologySelect}
            searchTerm={values.pathology.name}
            field={"pathology.name"}
            setValue={(value) => handleSelectPathology(value)}
          />
        </FormControl>
      </ContinuousUseItems>

      <ContinuousUseItems flex={1}>
        <Button
          className="btn-outline"
          onClick={() => handleCreateNew("newPathology")}
        >
          CRIAR NOVA PATOLOGIA
        </Button>
      </ContinuousUseItems>
    </GroupContinuousUseItems>
  );
};

Pathology.propTypes = {
  data: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  manage: PropTypes.object.isRequired,
};
