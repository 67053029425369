import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  closeAllCards,
  PRE_ORDER_LIST,
  shouldShow,
} from '../../redux/actions/routerAction';
import {
  donePreOrder,
  objectPreListToDelete,
} from '../../redux/actions/orderAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import PreOrderInput from '../../shared/components/pre-order-input';
import PreOrderList from '../../shared/components/pre-order-list';
import SaleService from '../../services/saleService';
import { OrderService } from '../../services/orderService';
import { MessageBox } from '../../shared/components/modules';
import { cacheSngpc } from '../sngpc/cache-sngpc';

import moment from 'moment';

export class PreOrderContainer extends Component {
  static propTypes = {
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
    donePreOrder: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      listPreOrderItems: [],
      showInputPreOrderID: false,
      showMessage: false,
      idPreOrderDel: null,
    };

    this.props.MessageBus.Subscribe(
      'pos.order.listOpened',
      this.getListPreOrderItens.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.order.orderDeletedById',
      this.eventReturnPreOrderDel.bind(this)
    );
    this.props.MessageBus.Subscribe('pos.order.updated', this.handleClose);

    this.onSelectPreOrder = this.onSelectPreOrder.bind(this);
    this.onDeletePreOrder = this.onDeletePreOrder.bind(this);
    this.handleInputPreOrderID = this.handleInputPreOrderID.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refresh = this.refresh.bind(this);
    this.service = new SaleService();
    this.orderService = new OrderService();
  }

  refresh() {
    this.orderService
      .getPreOrderList()
      .catch((error) => this.context.showError(error));
  }

  donePreOrder() {
    this.props.donePreOrder();
  }

  getListPreOrderItens(canal, payload) {
    const listPreOrderItems = payload.orders.map((item) => ({
      id: item.id,
      recoveryId: item.recoveryId,
      recoveryDesc: item.recoveryDesc,
      customer: item.customer?.name || ' ---- ',
      seller: item.currentSellerName || ' ---- ',
      total: item.total,
      concludedAt: moment(item.concludedAt).format('DD/MM/YYYY HH:mm'),
    }));

    this.setState({
      listPreOrderItems,
    });
  }

  eventReturnPreOrderDel() {
    this.refresh();
    this.showConfirmDialog(false);
  }

  onSelectPreOrder(preOrderID) {
    this.service
      .recoverPreOrder(preOrderID, false)
      .catch((error) => this.context.showError(error));
    this.props.hidePreOrderList();
  }

  onDeletePreOrder(line) {
    let object = {
      recoveryDesc: line.recoveryDesc || line.recoveryId,
      total: line.total,
      title: 'Exclusão de pré-venda',
      page: 'LISTAGEM DE PRÉ-VENDAS',
    };

    this.props.objectPreListToDelete(object);

    this.setState({
      showMessage: true,
      idPreOrderDel: line.id,
    });
  }

  showConfirmDialog(isVisible) {
    this.setState({ showMessage: isVisible });
  }

  confirmMessage(confirm) {
    if (confirm) {
      this.service
        .deleteOrderById(this.state.idPreOrderDel)
        .catch((error) => this.context.showError(error));
    }

    this.setState({ showMessage: false });
  }

  handleInputPreOrderID(visible) {
    this.setState({
      showInputPreOrderID: visible,
    });
  }

  handleClose = () => {
    if (this.props.showPreOrderList && !cacheSngpc.lotConfirmedFromSngpc) {
      this.props.hidePreOrderList();
    }
  };

  onSetPreOrderId(preOrderId) {
    this.orderService
      .addPreOrderId(preOrderId)
      .catch((error) => this.context.showError(error));
  }

  filterPreOrder = (recoveryDesc) => {
    this.orderService.getPreOrderList(recoveryDesc);
  };

  render() {
    const { listPreOrderItems, showInputPreOrderID } = this.state;

    return (
      <Fragment>
        {this.state.showMessage && (
          <MessageBox
            message='Deseja realmente excluir a pré-venda?'
            okText='Sim'
            handleConfirm={() => this.confirmMessage(true)}
            handleCancel={() => this.confirmMessage(false)}
          />
        )}
        {this.props.showPreOrderList && (
          <PreOrderList
            title='Listagem de pré-vendas'
            listItems={listPreOrderItems}
            onSelectPreOrder={this.onSelectPreOrder}
            handleClose={this.handleClose}
            handleRefreshClick={this.refresh}
            onDeletePreOrder={this.onDeletePreOrder}
            handleFind={this.filterPreOrder}
          />
        )}

        <PreOrderInput
          showModal={showInputPreOrderID}
          handlerSetPreOrderId={this.onSetPreOrderId}
          handlerStateModal={this.handleInputPreOrderID}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showPreOrderList: shouldShow(state, PRE_ORDER_LIST),
});

const mapDispatchToProps = (dispatch) => ({
  hidePreOrderList: () => dispatch(closeAllCards()),
  donePreOrder: () => dispatch(donePreOrder()),
  objectPreListToDelete: (data) => dispatch(objectPreListToDelete(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(PreOrderContainer)));
