import * as Yup from 'yup';

import { ValidateCPF } from '../../utils/validates/cnpj-cpf';

const defaultMessage = 'Campo obrigatório';

const validation = Yup.object().shape({
  name: Yup.string().required(defaultMessage),
  cpf: Yup.string()
    .required(defaultMessage)
    .test('cpfValid', 'CPF Inválido', (value) => value && ValidateCPF(value)),
  email: Yup.string().email('E-mail Inválido').nullable(),
  phoneNumber: Yup.string().test(
    'phoneNumberValid',
    'Celular/WhatsApp Inválido',
    (value) => {
      if (!value) return true;
      return value.replace(/[^0-9]+/g, '').length === 11;
    }
  ),
  zipCode: Yup.string()
    .test('zipCodeValid', 'CEP Inválido', (value) => {
      if (!value) return true;
      return value.replace(/[^0-9]+/g, '').length === 8;
    }),
  street: Yup.string(),
  neighborhood: Yup.string(),
});

export default validation;
