import React from 'react';

import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';

import { Authorization } from '../../shared/components/authorization/authorization';
import { OperationEnum } from '../../shared/enums/operation';

export class RequireAuthContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastCalledEndpoint: null,
      visible: false,
      title: null,
    };

    this.props.MessageBus.Subscribe('pos.open.requireAuth', this.handleOpen);
    this.props.MessageBus.Subscribe(
      'pos.close.requireAuth',
      this.handleModalClose
    );
  }

  handleOpen = (ch, payload) => {
    this.setState({
      lastCalledEndpoint: payload.lastCalledEndpoint,
      visible: true,
      title: payload.title,
    });
  };

  handleModalClose = () => {
    this.setState({
      visible: false,
      title: null,
    });
  };

  render() {
    const { orderDeleteItemInfo, showAutorizationOnline } = this.props;

    return (
      this.state.visible && (
        <Authorization
          title={
            orderDeleteItemInfo
              ? orderDeleteItemInfo.data.title
              : 'Autorização necessária'
          }
          labelTextUser={'Usuário'}
          btnAuthorizationTitle={'Confirmar'}
          handleClickConfirm={this.handleModalClose}
          handleClickCancel={this.handleModalClose}
          page={orderDeleteItemInfo ? orderDeleteItemInfo.data.page : ''}
          showAutorizationOnline={
            showAutorizationOnline?.show && orderDeleteItemInfo
          }
          lastCalledEndpoint={this.state.lastCalledEndpoint}
          operation={OperationEnum.ExcluirPreVenda}
          solicitation={{
            codigo: orderDeleteItemInfo
              ? orderDeleteItemInfo.data.recoveryDesc
              : 0,
            valor: orderDeleteItemInfo ? orderDeleteItemInfo.data.total : 0,
          }}
          closeModal={() => this.setState({ visible: false })}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  shouldAuthenticate: state.util.shouldAuthenticateOperation,
  lastSentBody: state.util.lastSentBody,
  orderDeleteItemInfo: state.order.orderDeleteItemInfo,
  showAutorizationOnline: state.showAutorizationOnline,
});

export default connect(
  mapStateToProps,
  null
)(withMessageBus(getContext(RequireAuthContainer)));
