import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../modules';
import { currencyMask } from '../../utils/masks';
import { Table, CELL_ALIGN_CENTER } from '../table2';
import { PbmConfirmWraper } from './pbm-confirm-component.style';
import Button from '../button';

export class PbmConfirmComponent extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    pbm: PropTypes.objectOf(PropTypes.any)
  };

  static defaultProps = {
    pbm: {
      grossTotal: 0,
      netTotal: 0,
      quantityTotal: 0,
      authorizedItems: []
    }
  }

  customCellrender = item => (
    <span className={`circle ${item.status.toLowerCase()}`} />
  )

  tableConfig = {
    selectable: false,
    columns: [
      { title: 'Status', fixedWidth: 35, customCellrender: this.customCellrender, centerTitle: true },
      { title: 'Produto', label: 'description' },
      { title: 'Qtd. Solicitada', label: 'requestedQuantity', aligment: CELL_ALIGN_CENTER, fixedWidth: 80, titleAlign: 'center' },
      { title: 'Qtd. Autorizada', label: 'authorizedQuantity', aligment: CELL_ALIGN_CENTER, fixedWidth: 80, titleAlign: 'center' },
      { title: '% Desconto', label: 'discountPercentage', aligment: CELL_ALIGN_CENTER, fixedWidth: 80, titleAlign: 'center' },
      { title: 'Vlr. Unitário', label: 'unitPrice', type: 'currency', aligment: CELL_ALIGN_CENTER, fixedWidth: 80, titleAlign: 'center' },
      { title: 'Vlr. Desconto', label: 'totalDiscount', type: 'currency', aligment: CELL_ALIGN_CENTER, fixedWidth: 80, titleAlign: 'center' },
      { title: 'Vlr. total', label: 'totalValue', type: 'currency', aligment: CELL_ALIGN_CENTER, fixedWidth: 80, titleAlign: 'center' },
      { title: 'Retorno', label: 'operatorMessage', fixedWidth: 320 }
    ]
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedItemTable: null
    }
  }

  onRowSelect = selectedItemTable => {
    this.setState({
      selectedItemTable
    });
  }

  render() {
    const {
      handleClose,
      pbm
    } = this.props;

    return (
      <Modal
        ref={ref => this.optionsModalRef = ref}
        isOpen
        title={"Detalhes da Transação - PBM"}
        closeEnable
        onCloseModal={handleClose}
        autoScroll={false}
      >
        <Modal.ModalBody>
          <PbmConfirmWraper>
            <Table
              config={this.tableConfig}
              data={pbm.authorizedItems}
              onRowSelect={this.onRowSelect}
              selected={this.state.selectedItemTable}
            />
            <div className="row-total">
              <span className="total-group"><b>Valor Total Bruto:</b>{currencyMask(pbm.grossTotal)}</span>
              <span className="total-group"><b>Valor Total Subsídio:</b>{currencyMask(pbm.totalValueSubsidy)}</span>
              <span className="total-group"><b>Valor Total Líquido:</b>{currencyMask(pbm.netTotal)}</span>
              <span className="total-group"><b>Quantidade Total:</b>{pbm.quantityTotal}</span>
            </div>
            <div className="legend">
              <span className="legend-item">
                <span className="circle approved" />Aprovado
              </span>
              <span className="legend-item">
                <span className="circle partial" />Aprovado Parcialmente
              </span>
              <span className="legend-item">
                <span className="circle refused" />Não Aprovado
              </span>
            </div>
          </PbmConfirmWraper>
        </Modal.ModalBody>
        <Modal.ModalFooter>
          <Button className="btn btn-outline" handleClick={this.props.cancel}>Desistir do atendimento</Button>
          <Button className="btn btn-outline" handleClick={this.props.confirm}>Confirmar e Receber</Button>
        </Modal.ModalFooter>
      </Modal>
    );
  }
}
