import React from 'react';

import { ModalComponent } from '../modal-component';
import { ContainerComponent } from '../container';
import { ButtonComponent } from '../button-component';
import ImgSuccess from '../../../assets/images/img-success.svg';
import ImgWarning from '../../../assets/images/img-warning.svg';
import { ContainerBody, ContainerImg, ContainerTitle } from './style';

interface IShowMessage {
  title: string;
  message: string;
  handleClickConfirm: any;
  handleClickCancel: any;
  showBtnCancel?: boolean;
  titleBtnCancel?: string;
  titleBtnConfirm?: string;
  type: 'warning' | 'success';
}

export function ShowMessage(props: IShowMessage) {
  const confirmar = () => {
    props.handleClickConfirm();
  };

  const cancel = () => {
    props.handleClickCancel();
  };

  return (
    <ModalComponent title='' show={true} onClose={() => cancel()}>
      <ContainerComponent id='container-show-message'>
        <div className='row'>
          <ContainerImg>
            {props.type === 'success' && <img src={ImgSuccess} />}
            {props.type === 'warning' && <img src={ImgWarning} />}
          </ContainerImg>
        </div>
        <div className='row' style={{ flexDirection: 'column' }}>
          <ContainerTitle>
            <h2>{props.title}</h2>
          </ContainerTitle>
          <ContainerBody>
            <h4>{props.message}</h4>
          </ContainerBody>
        </div>
        <div className='row'>
          {props.showBtnCancel ? (
            <div className='btn-group'>
              <ButtonComponent
                title={props.titleBtnCancel ? props.titleBtnCancel : 'Cancelar'}
                name='btn-cancel'
                onClick={cancel}
              />

              <ButtonComponent
                className='btn-b-success'
                title={
                  props.titleBtnConfirm ? props.titleBtnConfirm : 'Concluir'
                }
                onClick={confirmar}
              />
            </div>
          ) : (
            <div className='btn-group' style={{ justifyContent: 'flex-end' }}>
              <ButtonComponent
                className='btn-b-success'
                title={
                  props.titleBtnConfirm ? props.titleBtnConfirm : 'Concluir'
                }
                onClick={confirmar}
              />
            </div>
          )}
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}
