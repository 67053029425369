import React, { Component } from "react";

import getShortcut, { COUPON_ITEMS } from "../../../keyboardShortcuts.js";
import Item from "../item";
import { ShortcutHint } from "../modules";
import ItemBoxWrapper, { HeaderWrapper } from "./style";

export class ItemBox extends Component {
  constructor(props) {
    super(props);

    this.handleOpenShorcut = this.handleOpenShorcut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);

    this.refLastItem = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order.items !== this.props.order.items) {
      this.refLastItem.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  getProdutId(product) {
    if (!product) {
      return null;
    }

    return product.productId;
  }

  handleOpenShorcut() {
    const firstItem = document.querySelector(".item-list .item-wrapper");
    if (firstItem) {
      this.handleClick(this.props.order.items[0]);
      firstItem.focus();
    }
  }

  handleClick(item) {
    this.activeItem = item;

    this.props.handleClick(item);
  }

  handleNext() {
    const { items } = this.props.order;

    const newIndex = items.indexOf(this.props.selectedEditing) + 1;

    if (newIndex < items.length) {
      this.handleClick(items[newIndex]);

      document.querySelectorAll(".item-list .item-wrapper")[newIndex].focus();
    }
  }

  handlePrev() {
    const { items } = this.props.order;

    const newIndex = items.indexOf(this.props.selectedEditing) - 1;

    if (newIndex >= 0) {
      this.handleClick(items[newIndex]);

      document.querySelectorAll(".item-list .item-wrapper")[newIndex].focus();
    }
  }

  render() {
    const {
      order,
      handleEditPrescription,
      handleEditLot,
      handleEditContinuousUse,
      handleClose,
      selectedEditing,
    } = this.props;

    let qttTotalItems = 0;
    order.items && order.items.forEach((i) => (qttTotalItems += i.quantity));
    return (
      <>
        <HeaderWrapper className="item-header">
          <p>Itens</p>
          <div id="countItem">{qttTotalItems}</div>
        </HeaderWrapper>
        <ItemBoxWrapper>
          <div className="item-list">
            <ShortcutHint
              shortcut={getShortcut(COUPON_ITEMS)}
              activateShortcut={this.handleOpenShorcut}
            />
            {order.items &&
              order.items.map((item, i, { length }) => {
                const seller = item.sellerId;

                const id = `product-${item.productId}${seller ? `-seller-${seller}` : ""
                  }-sequential-${item.sequentialId}`;

                const isLast = length - 1 === i;
                const isFirst = i === 0;
                return (
                  <Item
                    id={id}
                    key={id}
                    {...item}
                    isActive={item === selectedEditing}
                    sellerId={seller}
                    handleClick={() => this.handleClick(item)}
                    handleEditPrescription={handleEditPrescription}
                    handleEditLot={handleEditLot}
                    handleEditContinuousUse={handleEditContinuousUse}
                    handleClose={handleClose}
                    handleNext={this.handleNext}
                    handlePrev={this.handlePrev}
                    isLast={isLast}
                    isFirst={isFirst}
                    anyContinuousUseFieldRequired={order?.anyContinuousUseFieldRequired}
                    isTrnCentreProduct={item.productIsTrnCentreProduct}
                  />
                );
              })}
            <div ref={this.refLastItem}></div>
          </div>
        </ItemBoxWrapper>
      </>
    );
  }
}

export default ItemBox;
