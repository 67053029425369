import React from 'react';
import { ButtonComponent } from '../../../button-component';
import { ContainerComponent } from '../../../container';
import { ModalComponent } from '../../../modal-component';
import { UndoValues, UndoValuesMovimentType, UndoValuesWrapper } from './style';
import IconMinus from '../../../../../assets/images/icon-menos.svg';
import IconInfo from '../../../../../assets/images/icon-info.svg';

interface IUndoValuesRecord {
  title: string;
  dataToUndoValues: any;
  dataValues: any;
  removeValue: any;
  handleClickConfirm: any;
  handleClickCancel: any;
}

export function UndoValuesRecord(props: IUndoValuesRecord) {
  const handleConfirm = () => {
    const item = props.dataToUndoValues.item;
    const newItemValues = {
      ...item,
      totalConferido: item.totalConferido - (total - subtotal),
    };

    props.handleClickConfirm(newItemValues);
  };

  const cancel = () => {
    props.handleClickCancel();
  };

  const setMaskMoneyToSpan = (value: number) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  const category = props.dataToUndoValues.category;

  const total = props.dataToUndoValues.valuesToCheck.reduce(
    (acc, value) => acc + value.checkValue,
    0
  );
  const subtotal = props.dataValues[category].reduce(
    (acc, value) => acc + value.checkValue,
    0
  );

  return (
    <ModalComponent title={props.title} show={true} onClose={() => cancel()}>
      <ContainerComponent id='container-undo-values-record'>
        <div className='row'>
          <UndoValuesMovimentType>
            Tipo de movimento: {props.dataToUndoValues.tipoMovimento}
          </UndoValuesMovimentType>
        </div>

        <UndoValuesWrapper>
          <span className='caption'>
            {props.dataValues[category].length === 0
              ? 'Todos os valores foram removidos'
              : props.dataValues[category].length === 1
              ? 'Valor não salvo'
              : 'Valores não salvos'}
          </span>

          <div className='check-values'>
            {props.dataValues[category].map((item, i) => {
              const barcode = item.inputType === 'barcode';
              return (
                <UndoValues key={i} barcode={barcode}>
                  <span>{setMaskMoneyToSpan(item.checkValue)}</span>
                  <i>
                    <img
                      src={barcode ? IconInfo : IconMinus}
                      title={
                        barcode
                          ? 'Valor inserido por escaneamento, alteração não permitida'
                          : ''
                      }
                      alt='Icone'
                      onClick={
                        barcode
                          ? () => {}
                          : () => props.removeValue(i, category)
                      }
                    />
                  </i>
                </UndoValues>
              );
            })}
          </div>

          <span className='subtotal'>{setMaskMoneyToSpan(subtotal)}</span>
        </UndoValuesWrapper>

        <div className='row'>
          <div className='btn-group'>
            <ButtonComponent
              title='Cancelar'
              name='btn-cancel'
              onClick={cancel}
            />

            <ButtonComponent
              className='btn-b-success'
              title='Confirmar'
              onClick={handleConfirm}
            />
          </div>
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}
