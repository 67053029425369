import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const Button = styled.button<{
  spanSize: string | undefined;
  colorSpan: string | undefined;
}>`
  background: #ffffff;
  border: 2px solid #5b2e90;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  min-width: 130px;
  height: 40px;

  span {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: bold;
    font-size: ${(x) => (x.spanSize ? x.spanSize : '17px')};
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(x) => (x.colorSpan ? x.colorSpan : '#5B2E90')};
  }

  &.btn-b-success {
    background: #5b2e90;

    span {
      color: #ffffff;
    }
  }

  &:focus,
  &:active {
    border: 2px solid #e49d15;
    outline: none;
  }

  &:disabled {
    cursor: no-drop;
    background: ${theme.palette.lightGray} !important;
    border: none;
  }

  img {
    margin-left: 7px;
  }
`;
