import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { showCard, closeAllCards, TRNCENTRE, shouldShow } from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import CardTrnCentre from '../../shared/components/card-trncentre';
import PbmSaleService from '../../services/pbmSaleService';
import { showToast } from '../../shared/utils/util';

export class CardTrnCentreContainer extends Component {
  title = 'Portal da Drogaria';

  static propTypes = {
    showTrnCentre: PropTypes.bool,
    showTrnCentreSelector: PropTypes.func.isRequired,
    hideTrnCentreSelector: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    showTrnCentre: false,
  }

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.props.MessageBus.Subscribe('pos.trncentre.startShow', this.handleShow);
    this.props.MessageBus.Subscribe('pos.trncentre.showItems', this.handleShowItens);
    this.props.MessageBus.Subscribe('pos.trncentre.closeItems', this.handleClose);
    this.props.MessageBus.Subscribe('pos.trncentre.error', this.handleError);
    this.props.MessageBus.Subscribe('pos.trncentre.cancelationSuccess', this.handleCancelSuccess);
    this.props.MessageBus.Subscribe('pos.trncentre.finalizeSale', this.handleFinalizeSale);

    this.state = {
      cardNumber: "",
      transactionCode: "",
      cpf: "",
      showItems: false,
      payload: null,
      showCpfField: false,
      showCardField: false
    }
  }

  handleCancelSuccess = (ch, payload) => {
    showToast({
      type: 3,
      title: 'Portal da Drogaria',
      html: 'Transação Portal da Drogaria anulada com sucesso!',
      hideAfterMilliseconds: 5000
    });
  }

  handleFinalizeSale = (ch, payload) => {
    PbmSaleService.finalizeSale()
  }

  handleShowItens = (ch, payload) => {
    this.handleShow();
    this.setState({
      showItems: true,
      payload: payload
    });
  }

  handleError = (ch, payload) => {
    this.context.showMessage(payload);
  }

  handleChangeCpf = (e) => {
    this.setState({
      cpf: e.target.value
    });
  };

  handleChangeCardNumber = (e) => {
    this.setState({
      cardNumber: e.target.value
    });
  };

  handleChangeNsu = (e) => {
    this.setState({
      transactionCode: e.target.value
    });
  };

  handleConfirm = () => {
    PbmSaleService.trnCentreConfirmTransaction(this.state.cpf, this.state.cardNumber, this.state.transactionCode)
  }

  handleConfirmItems = () => {
    if (this.state.payload.finalize)
      PbmSaleService.finalizeSale()
    this.handleClose();
  }

  handleShow = (ch, payload) => {
    this.props.showTrnCentreSelector();
    this.setState({
      showCpfField: payload?.showCpfField,
      showCardField: payload?.showCardField
    });
  }

  handleShowMessageProd = (e) => {
    this.state.payload.items.map((item) => {
      if (item.codigoBarras === e.target.id) {
        this.context.showMessage(item.informativeText ?? 'Nenhum retorno da administradora encontrado para esse produto.', "Texto informativo");
      }
      return item;
    });
  }

  handleClose() {
    this.props.hideTrnCentreSelector();
    this.setState({
      authorizationCode: "",
      showItems: false,
      payload: null,
      cpf: "",
      cardNumber: "",
      transactionCode: ""
    });
  }

  render() {
    const { showTrnCentre } = this.props;

    return (
      <Fragment>
        {showTrnCentre && (
          <CardTrnCentre
            handleClose={this.handleClose}
            handleChangeCpf={this.handleChangeCpf}
            handleChangeCardNumber={this.handleChangeCardNumber}
            handleConfirm={this.handleConfirm}
            showItems={this.state.showItems}
            payload={this.state.payload}
            handleConfirmItems={this.handleConfirmItems}
            showCpfField={this.state.showCpfField}
            showCardField={this.state.showCardField}
            handleChangeNsu={this.handleChangeNsu}
            handleShowMessageProd={this.handleShowMessageProd}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showTrnCentre: shouldShow(state, TRNCENTRE),
});

const mapDispatchToProps = dispatch => ({
  showTrnCentreSelector: () => dispatch(showCard(TRNCENTRE, null, true, true)),
  hideTrnCentreSelector: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(CardTrnCentreContainer)));