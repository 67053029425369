import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  show: false
};

const showAutorizationOnlineReducer = createReducer(initialState, {
  [types.SHOW_AUTORIZATION_ONLINE]: (state, payload) => ({
    ...state,
    show: payload.show
  })
});

export default showAutorizationOnlineReducer;