import React, { Component, Fragment } from 'react';
import './style.scss';
import { currencyMask } from '../../utils/masks';
import CurrencyInput from 'react-currency-input';

export class FarmaciaPopularBolsaFamilia extends Component {
  constructor(props) {
    super(props);
  }

  getRowProduct = (item) => {
    return (
      <div className='div-dados-produtos'>
        <div className='div-barcode'>{item.barCode}</div>
        <div className='div-name'>{item.name}</div>
        <div className='div-qtde'>{item.prescriptionQuantity}</div>
        <div className='div-preco'>{currencyMask(item.price)}</div>
        <div className='div-precobs'>
          <CurrencyInput
            id='input-precobs'
            prefix='R$'
            value={item.farmaciaPopularBolsaFamiliaPrice}
            className='subtotal-input'
            decimalSeparator=','
            maxLength={11}
            thousandSeparator='.'
            onChange={e => this.props.handleChangeBolsaFamiliaPrice(e, item.barCode)}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div id='farmacia-popular-bolsa-familia'>
          <div className='div-titulo'>
            FARMÁCIA POPULAR
          </div>
          <div className='div-mensagem'>
            O Farmácia Popular identificou que o CPF informado faz parte do Bolsa Família.
            Os seguintes produtos devem ser reautorizados com os respectivos valores referentes ao Bolsa Família.
            <br />
            Dúvidas com relação ao preço do Bolsa Família no Farmácia Popular? <a onClick={this.props.handleSignupLink} id='div-link'>Clique aqui!</a>
          </div>
          <div className='div-produtos-titulo'>PRODUTOS</div>
          <div className='div-produtos'>
            <div className='div-cabecalho-produtos'>
              <div className='div-barcode'>Código de barras</div>
              <div className='div-name'>Descrição</div>
              <div className='div-qtde'>Quantidade</div>
              <div className='div-preco'>Preço enviado</div>
              <div className='div-precobs'>Preço Bolsa Família</div>
            </div>
            {this.props.productsList?.map(this.getRowProduct)}
          </div>
        </div >
      </Fragment >
    );
  }
}

export default FarmaciaPopularBolsaFamilia;