import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { Table } from '../table2';
import { Button } from '../modules';

const customStyle = {
  content: {
    maxWidth: '650px',
    maxHeight: '310px',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    display: 'block',
  },
  ichandleCloseModal: {
    float: 'right',
    cursor: 'pointer',
  },
};

class SelectedPaymentOrder extends Component {

  cancelBtnCell = row => (
    <span>
      <Button className="btn-outline btn-sm" onClick={() => this.handleCancel(row)}>cancelar</Button>
    </span>
  );

  tableConfig = {
    selectable: false,
    limitPerPage: 300,
    columns: [
      { title: 'NOME', label: 'name' },
      { title: 'VALOR', label: 'amount' },
      { title: 'NSU', label: 'controlCode'},
      { title: 'Cód. Autorização', label: 'authorizationCode'},
      { customCellrender: this.cancelBtnCell, fixedWidth: 110 }
    ],
  };

  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEsc = this.handleEsc.bind(this);
  }

  componentDidMount() {
    this.modalRef.node.addEventListener("keydown", this.handleEsc, false);
  }

  componentWillUnmount() {
    this.modalRef.node.removeEventListener("keydown", this.handleEsc, false);
  }

  handleCloseModal() {
    this.props.handleCloseModal();
  }

  handleCancel(obj) {
    this.props.handlerCancelTef(obj);
  }

  handleEsc(e) {
    e.stopPropagation();
    this.props.handleCloseModal();
  }

  render() {
    return (
      <Fragment>
        <Modal
          ref={ref => this.modalRef = ref}
          isOpen={this.props.showModal} 
          closeOnOverlayClick 
          center 
          style={customStyle}
          ariaHideApp={false}
        >
          <i className="material-icons close-modal" style={customStyle.ichandleCloseModal} onClick={this.handleCloseModal}>cancel</i>
          <span>Meio de pagamento: </span>
          <strong>{this.props.listPayOrder.namePay}</strong>
          <Table
            style={{ marginTop: '20px' }} 
            config={this.tableConfig} 
            data={this.props.listPayOrder.listPay}
          />
        </Modal>
      </Fragment>
    );
  }
}

export default SelectedPaymentOrder;
