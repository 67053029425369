import React, { Component } from "react";

import { connect } from "react-redux";

import { orderUpdated } from "../../redux/actions/orderAction";
import {
  closeAllCards,
  getPayload,
  DETAIL_ITEM,
} from "../../redux/actions/routerAction";
import { ControlledItemsService } from "../../services/controlledItemsService";
import { LotService } from "../../services/lotService";
import SaleService from "../../services/saleService";
import ItemBox from "../../shared/components/item-box";
import { getContext } from "../../shared/hoc/getContext";
import { withMessageBus } from "../../shared/utils/messageBus";
import { stringErrors } from "../../shared/utils/stringErrors";

class ItemBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.saleService = new SaleService();
    this.lotService = new LotService();
    this.controlledItemsService = new ControlledItemsService();

    this.handleClick = this.handleClick.bind(this);
    this.handleEditLot = this.handleEditLot.bind(this);

    this.props.MessageBus.Subscribe("pos.order.updated", (ch, payload) =>
      this.props.orderUpdated.bind(this)(payload)
    );
    this.props.MessageBus.Subscribe("pos.item.deletedFailed", (error) =>
      this.context.showMessage(stringErrors(error))
    );
  }

  handleClick = (item) => {
    const {
      showCardDetailAuthorization,
      showCardListBrand,
      showCredicardParcel,
      showDebitoDetailAuthorization,
      showPaymentMoney,
      showPaymentTypeList,
      showSaleFinish,
      showTefMessage,
    } = this.props.payment;

    const paymentWindowsState = [
      showCardDetailAuthorization,
      showCardListBrand,
      showCredicardParcel,
      showDebitoDetailAuthorization,
      showPaymentMoney,
      showPaymentTypeList,
      showSaleFinish,
      showTefMessage,
    ];

    if (paymentWindowsState.some((v) => v)) {
      return;
    }

    this.saleService.startUpdateItem(item.productId, item.sequentialId);
  };

  handleEditPrescription = (productId) => {
    return this.controlledItemsService.startControledItem(productId);
  };

  handleEditLot(productId, sequentialId) {
    this.lotService.startLotIdentification(productId, sequentialId);
  }

  handleEditContinuousUse = (productBarcode) => {
    const payload = {
      barcode: productBarcode,
    };

    return this.saleService.continuousUseStart(payload);
  };

  handleClose = () => {
    this.saleService.cancelUpdateItem();
  };

  render() {
    return (
      <ItemBox
        order={this.props.order}
        handleClick={this.handleClick}
        handleEditPrescription={this.handleEditPrescription}
        handleEditLot={this.handleEditLot}
        handleEditContinuousUse={this.handleEditContinuousUse}
        selectedEditing={this.props.itemSelected}
        handleClose={this.handleClose}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order.order,
  payment: state.payment,
  itemSelected: getPayload(state, DETAIL_ITEM),
});

const mapDispatchToProps = (dispatch) => ({
  closeDetailsItem: () => dispatch(closeAllCards()),
  orderUpdated: (param) => dispatch(orderUpdated(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(ItemBoxContainer)));
