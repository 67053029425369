import React, { useEffect, useRef } from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules'
import { Table } from '../table2';
import './style.css';

export default function StockList(props) {
    const { onClose, visible, item, stock, handleSort, tableConfig } = props;
    const modalRef = useRef(null);


    useEffect(() => {
        if (visible)
            modalRef.current.open();
    }, [visible]);

    return (
        <Modal title="ESTOQUE DE PRODUTOS"
            onClickClose={onClose}
            ref={modalRef}
            className="stockModal"
            closable={false}
            opened
        >
            <Modal.Body>
                <p className='itemName'>{item.name}</p>
                <span className='label'>ESTOQUE DE PRODUTOS NAS FILIAIS</span>
                <div className='table-container'>
                    {stock.length === 0 && <p className='norecords'>Nenhum registro encontrado!</p>}
                    <Table
                        config={tableConfig}
                        data={stock}
                        handleSort={handleSort}
                    >
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='footer'>
                    <Button
                        id="btn-cancel"
                        className="btn-default btn-purple"
                        onClick={onClose} autoFocus>
                        FECHAR
                    </Button>
                </div>

            </Modal.Footer>
        </Modal>);
}