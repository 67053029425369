import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IncreaseDecreaseInput from '../increaseDecreaseInput/increaseDecreaseInput.js'
import { Card, Button } from '../modules';
import { WarningLabel } from './style';
import './style.scss';

export const LotControl = ({ item, handleConfirm, handleClose, handleChangeQuantity, totalQuantity }) => {
  useEffect(() => {
    setTimeout(() => {
      const input = document.querySelector('.content-cell input');
      if (input) input.focus();
    }, 100);
  });

  const canConfirm = totalQuantity === item.product.quantity;

  const footer =
    item.lots.length !== 0 ?
      <div className='div-footer'>
        <Button className="btn-outline" handleClick={handleClose}>
          CANCELAR
        </Button>
        <Button className={canConfirm ? "btn-purple" : "btn-purple disabled"} disabled={!canConfirm} handleClick={handleConfirm}>
          CONFIRMAR
        </Button>
      </div> :
      <WarningLabel>
        Não é possível 'Confirmar' pois não há lotes vinculados com este produto.
      </WarningLabel>

  return (
    <Card id="card-lotes" title="CONTROLE DE LOTES" closeEnable handleClose={handleClose} footer={footer} >
      <div className='header'>
        <div className='sub-header'>
          <div className='sub-header-item-prod-header'>Produto</div>
          <div className='sub-header-item-prod'>{item.product.name}</div>
        </div>
        <div className='sub-header'>
          <div className='sub-header-item-header'>Estoque</div>
          <div className='sub-header-item'>{item.product.stock}</div>
        </div>
        <div className='sub-header'>
          <div className='sub-header-item-header'>Quantidade da venda</div>
          <div className='sub-header-item'>{item.product.quantity}</div>
        </div>
      </div>
      <div className='table'>
        <div className='table-header'>
          <div className='table-colunm'>Lote</div>
          <div className='table-colunm'>Data de Vencimento</div>
          <div className='table-colunm'>Estoque</div>
          <div className='table-colunm'>Prioridade de venda</div>
          <div className='table-colunm'>Quantidade</div>
        </div>
        <div className='div-traco'></div>
        {item.lots.map((i, index) => {
          const expirationDate = new Date(i.expirationDate);
          const formattedDate = `${expirationDate.getDate()}/${expirationDate.getMonth() + 1}/${expirationDate.getFullYear()}`;
          return (
            <div className='table-row'>
              <div className='table-row-item'>{i.number}</div>
              <div className='table-row-item'>{formattedDate}</div>
              <div className='table-row-item'>{i.quantity}</div>
              <div className='table-row-item'>
                <div className={index === 0 ? 'div-prioridade-alta' : 'div-prioridade-baixa'}>{index === 0 ? 'Alta' : 'Baixa'}</div>
              </div>
              <div className='table-colunm'>
                <IncreaseDecreaseInput
                  quantity={i.typedQuantity}
                  handleChangeValue={(value) => handleChangeQuantity(index, value)}
                ></IncreaseDecreaseInput></div>
            </div>
          );
        })}
      </div>
    </Card >
  );
};

LotControl.propTypes = {
  title: PropTypes.string.isRequired,
  item: PropTypes.shape({
    lots: PropTypes.arrayOf(PropTypes.any),
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LotControl;
