import styled from 'styled-components';

const DateClockWrapper = styled.div`

  display: flex;
  justify-content: center;

  .date,
  .clock {
    white-space: nowrap;
    margin: 0 .5em;

    [class*='icon'] {
      font-size: 16px;
      position: relative;
      top: 3px;
    }

  }
  .clock {
    width: 130px;
    display: block;
  }
`;

export default DateClockWrapper;
