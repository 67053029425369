import handleGetWithoutLoading from '../shared/utils/handleGetWithoutLoading';
import HttpAccess from './httpAccess';

export class FarmaciaPopularStatusService  {
  constructor(httpAccess = HttpAccess,) {
    this.httpAccess = httpAccess;
  }
  CheckStatus = (url) => handleGetWithoutLoading(url, this.httpAccess);
}
export default FarmaciaPopularStatusService;
