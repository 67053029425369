import React, { useEffect } from 'react';
import { HTMLAttributes } from 'react';
import { useState } from 'react';
import onKeyDownEnter from '../../../utils/onKeyDownEnterToNextInput';
import { IRadioList } from '../interface/radio.interface';
import { Label, Radio, RadioGroup } from './style';

interface IPropRadioComponent extends HTMLAttributes<HTMLInputElement> {
  radioList: IRadioList[];
  value: any;
}

function RadioComponent(props: IPropRadioComponent) {
  const [radioList, setRadioList] = useState<IRadioList[]>(props.radioList);

  return (
    <RadioGroup>
      {radioList.map((x, index) => {
        return (
          <Label key={index}>
            <Radio
              id={`${props.id}-${index}`}
              type={'radio'}
              value={x.value}
              name={x.name}
              checked={x.value == props.value}
              onChange={props.onChange}
              onKeyDown={($event) =>
                onKeyDownEnter($event, `${props.id}-${index}`)
              }
            />
            {x.label}
          </Label>
        );
      })}
    </RadioGroup>
  );
}

export default RadioComponent;
