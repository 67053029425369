import styled from 'styled-components';

export const OptionListWrapper = styled.ul`
  font-family: 'Dosis';  
  padding: 15px;  
  position: relative;

  a { 
      text-decoration: none;
      position:absolute;
      top: 10px;
      right: 20px;
  }
`;
export const OptionListTitle = styled.div`
    margin-left: 20px; 
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;  
`;
export const OptionListContent = styled.div`
   margin: 25px auto;
   width: 90%;
`;
