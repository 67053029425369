import { Component } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../../shared/utils/messageBus';
import { checkStatusFP } from '../../../redux/actions/farmacialPopularStatusAction'
import { FarmaciaPopularStatusService } from '../../../services/farmaciaPopularStatusService';

class FarmaciaPopularStatus extends Component {
  constructor(props) {
    super(props);

    this.fpInterval = null
    this.state = {
      fpStatusUrl: 'https://farmaciapopular-autorizador.saude.gov.br/farmaciapopular-autorizador/services/ServicoSolicitacaoWS?wsdl',
      fpIntervalSeconds: 60
    };
    this.fpStatusService = new FarmaciaPopularStatusService();

    if (window.desktopApp) {
      window.desktopApp.subscribe('machine.devices.desktop.config', this.handleConfigDesktopApp)
    }
    else {
      this.fpStatusTimer();
    }
  }

  handleConfigDesktopApp = (canal, payload) => {
    this.setState({
      fpStatusUrl: payload.fp && payload.fp.urlStatus ? payload.fp.urlStatus : this.state.fpStatusUrl,
      fpIntervalSeconds: payload.fp && payload.fp.interval ? payload.fp.interval : this.state.fpIntervalSeconds
    });
    this.fpStatusTimer();
  }

  fpStatusTimer = () => {
    if (this.fpInterval != null) {
      clearInterval(this.fpInterval);
    }
    else {
      this.fpCheckStatus();
    }
    this.fpInterval = setInterval(this.fpCheckStatus, this.state.fpIntervalSeconds * 1000);
  }

  fpCheckStatus = () => {
    this.fpStatusService.CheckStatus(this.state.fpStatusUrl)
      .then(value => {
        this.props.checkStatusFP(true);
      })
      .catch(err => {
        this.props.checkStatusFP(false);
      });
  }
  render() {
    return "";
  }
}
const mapDispatchToProps = dispatch => ({
  checkStatusFP: isOn => dispatch(checkStatusFP(isOn))
});

export default connect(null, mapDispatchToProps)(withMessageBus(FarmaciaPopularStatus));
