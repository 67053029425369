import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import FormControl from '../../@form-control';
import { AutoComplete } from '../../auto-complete';
import Fieldset from '../../fieldset';
import { Button } from "../../modules";

function Prescriber({
  autocompleteId,
  autocompleteQueryFn,
  autocompleteOnKeyPress,
  renderItem,
  setFieldValue,
  data,
  onSelect,
  handleCreateNew,
  newPrescriber,
}) {
  function handleSelectDoctor(prescriber) {
    setFieldValue('prescriber.name', (prescriber && prescriber.name) || '');
    setFieldValue(
      'prescriber.documentType',
      (prescriber && prescriber.documentType) || ''
    );
    setFieldValue(
      'buyer.prescriberType',
      (prescriber && prescriber.documentType) || ''
    );
    setFieldValue(
      'patient.prescriberType',
      (prescriber && prescriber.documentType) || ''
    );
    setFieldValue(
      'prescriber.documentNumber',
      (prescriber && prescriber.documentNumber) || ''
    );
    setFieldValue(
      'prescriber.documentSituation',
      prescriber && prescriber.documentSituation
    );
    setFieldValue(
      'prescriber.documentTypeDescription',
      (prescriber && prescriber.documentTypeDescription) || ''
    );
    setFieldValue(
      'prescriber.documentUF',
      (prescriber && prescriber.documentUF) || ''
    );
    setFieldValue(
      'prescriber.referenceId',
      (prescriber && prescriber.referenceId) || ''
    );

    if (prescriber && prescriber.referenceId) onSelect(prescriber);
  }

  useEffect(() =>{
    if (Object.keys(newPrescriber).length > 0) 
      handleSelectDoctor(newPrescriber);
  }, [newPrescriber])

  return (
    <Fieldset legend="Informações do prescritor" hasRequiredField>
      <div className="custom-form-row">
        <FormControl
          name="prescriber.name"
          label="Nome ou número do registro*"
          flex="1"
        >
          <AutoComplete
            id={autocompleteId}
            icon="search"
            autoFocus
            queryOnEnter
            type="text"
            placeholder="Nome ou número do registro"
            maxLength={50}
            tabIndex="0"
            queryFn={autocompleteQueryFn}
            onSelect={(value) => handleSelectDoctor(value)}
            onKeyPress={autocompleteOnKeyPress}
            value={data.name}
            renderItem={renderItem}
          />
        </FormControl>
        <FormControl
          disabled
          label="Conselho*"
          name="prescriber.documentTypeDescription"
          flex="0.2"
        />
        <FormControl
          disabled
          label="Número registro*"
          name="prescriber.documentNumber"
          flex="0.2"
        />
        <FormControl
          disabled
          label="UF Conselho*"
          name="prescriber.documentUF"
          flex="0.1"
        />
        <Button
          className="btn-outline"
          style={{width: '160px', alignSelf: 'flex-end', height: '40px'}}
          onClick={handleCreateNew}
        >
          CRIAR NOVO PRESCRITOR
        </Button>
      </div>
    </Fieldset>
  );
}

Prescriber.propTypes = {
  autocompleteId: PropTypes.string,
  autocompleteQueryFn: PropTypes.func.isRequired,
  autocompleteOnKeyPress: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  newPrescriber: PropTypes.object.isRequired,
};

Prescriber.defaultProps = {
  autocompleteId: '',
};

export default Prescriber;
