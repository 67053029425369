import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: fit-content;
  margin: 1rem 0px;

  &:first-child {
    margin-top: 0px;
  }

  .flex-1 {
    flex: 1;
    margin: 0px 0.7rem;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  .group-btn {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;

    button {
      margin: 0px 0.5rem;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .container-legend {
    display: flex;
    flex: 1;

    .legend {
      font-family: 'Dosis';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      color: #411e5a;
    }

    .line {
      flex: 1;
      border-bottom: 1px solid #dee2e6;
    }
  }
`;

export const Table = styled.table`
  width: 100%;

  thead {
    border-top: 1px solid #c2bebe;
    border-bottom: 1px solid #c2bebe;
  }

  thead > tr > th {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #411e5a;

    padding: 0.5rem;
  }

  thead > tr > th:first-child {
    text-align: start;
  }

  tbody > tr {
    background: #f8f8f8;
    height: 45px;

    &:nth-of-type(odd) {
      background-color: #f8f8f8;
    }

    &:nth-of-type(even) {
      background-color: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }

    td {
      font-family: Dosis;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;

      padding: 0.5rem;
      text-transform: uppercase;
    }

    td:last-child {
      padding-right: 0px;
    }
  }
`;
