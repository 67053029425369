import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input';
import { Card, Button } from '../modules';
import { PaymentPixWrapper, PaymentPixInput, InputWrapper } from './style';

class PaymentPix extends Component {
  manualChangedValueDiscount = false;

  constructor(props) {
    super(props);

    this.state = {
      pendingAmout: this.props.amount,
      amountDelivered: this.props.amount,
      amountDiscount: this.props.amount,
      pendingAmountNextPayment: 0,
      change: 0,
      partialSale: false,
    };
  }

  handleConfirm = async () => {
    const { amountDiscount, pendingAmout, amountDelivered } = this.state;
    const invalidAmountInputValue = amountDelivered > pendingAmout || amountDiscount > pendingAmout || amountDelivered <= 0;
    
    if (!invalidAmountInputValue) {
      this.props.handleClickPay({ amountPayment: this.state.amountDelivered, cash: this.state.amountDiscount });
    }
  }

  handleKeyDown = (e) => {
    e.key === 'Enter' && this.handleConfirm();
  }

  componentDidMount() {
    this.amountInput.theInput.focus();
  }

  onChangePartialSale = (e) => {
    const isPartial = e.target.checked;

    if (!isPartial) {
      const pendingAmount = this.state.pendingAmout - this.state.amountDelivered;
      this.setState({
        amountDiscount: this.state.pendingAmout,
        pendingAmountNextPayment: pendingAmount < 0 ? 0 : pendingAmount,
        change: this.state.amountDelivered - this.state.pendingAmout
      });

      this.manualChangedValueDiscount = false;
    }

    this.setState({
      partialSale: isPartial
    });
  }

  refreshValues = (formatedValue, value, element) => {
    const elId = element.target.id;

    if (!this.manualChangedValueDiscount && elId === 'inputDiscountSale') {
      this.manualChangedValueDiscount = true;
    }

    const { partialSale, pendingAmout } = this.state;

    const amountDelivered = this.amountInput.state.value;

    let amountDiscount

    if ((!partialSale || !this.manualChangedValueDiscount || amountDelivered < amountDiscount) && elId === 'inputPixClient') {
      amountDiscount = amountDelivered;

      amountDiscount = amountDiscount > pendingAmout ? pendingAmout : amountDiscount;
    }

    const pendingAmountNextPayment = pendingAmout - amountDiscount;

    let change = amountDelivered - amountDiscount;

    change = change > 0 ? change : 0;

    this.setState({
      amountDelivered,
      amountDiscount,
      pendingAmountNextPayment,
      change
    });
  }

  formatValue(value) {
    if (value || value === 0) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }

  render() {
    const {
      pendingAmout,
      amountDelivered,
      amountDiscount,
      pendingAmountNextPayment,
    } = this.state;

    return (
      <Card
        title="PIX"
        subTitle="Informe o valor"
        handleClose={this.props.handleClose}
        urlIcone="./assets/images/payment-types/pix.png"
        closeEnable
      >
        <PaymentPixWrapper>
          <div>
            <span>TOTAL A RECEBER:&nbsp;</span>
            <strong>
              {this.formatValue(pendingAmout)}
            </strong>
          </div>
          <PaymentPixInput>
            <InputWrapper>
              <fieldset>
                <label>Valor transferido pelo cliente</label>
                <CurrencyInput
                  id="inputPixClient"
                  ref={ref => this.amountInput = ref}
                  value={amountDelivered}
                  selectAllOnFocus
                  onKeyDown={this.handleKeyDown}
                  onChange={this.refreshValues}
                  decimalSeparator=","
                  thousandSeparator="."
                />
                {(amountDiscount > amountDelivered) && (
                  <small className="error">
                    Valor maior que o entregue pelo cliente
                  </small>
                )}
                {(amountDiscount > pendingAmout) && (
                  <small className="error">
                    Valor maior que o total a receber
                  </small>
                )}
                {(amountDelivered > pendingAmout) && (
                  <small className="error">
                    O valor não pode ultrapassar o total a receber
                  </small>
                )}
                {(amountDelivered <= 0) && (
                  <small className="error">
                    O valor a descontar da venda deve ser maior que zero
                  </small>
                )}
              </fieldset>
            </InputWrapper>

            <div className="box">
              <div className="leftover">
                <div>
                  Restante a Receber:
                  <strong>
                    {this.formatValue(pendingAmountNextPayment)}
                  </strong>
                </div>
              </div>
            </div>
          </PaymentPixInput>

          <div>
            <div className="wrapper-btn">
              <Button
                type="button"
                className="btn-default btn-purple"
                disabled={amountDelivered > pendingAmout || amountDiscount > amountDelivered || amountDiscount > pendingAmout || amountDelivered <= 0}
                handleClick={this.handleConfirm}
              >
                CONFIRMAR
              </Button>
            </div>
          </div>
        </PaymentPixWrapper>
      </Card>
    );
  }
}

export default PaymentPix;
