import styled from 'styled-components';
import theme2 from '../../../themes/theme2';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  
  background-color: rgba(0, 0, 0, 0.3);
  
  z-index: 1400;

  position: absolute;
  top: 0;
  left: 0;  
`;

export const ModalWrapper = styled.div`
  background-color: #ffffff;
  
  justify-self: center;
  align-self: center;
  
  border-radius: 4px;
  
  min-height: 200px;
  max-height: 90vh;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  z-index: 9999;
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
`;

export const ModalHeader = styled.header`
  display: flex;
  padding: 20px 35px;
  
  align-items: center;
  
  h5 {
    align-self: center;
    line-height: normal;
    font-size: 18px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  
  i.material-icons {
    margin-left: auto;
    cursor: pointer;
    
    &:hover {
      font-weight: bold;
    }
  }
  
  border-bottom: 1px solid #c2bebe;
`;

export const ModalContent = styled.div`
    flex: 1;  

    display: flex;
    align-items: center;

    padding: 0 16px;

    i.material-icons {
      font-size: 26px;
      margin-right: 14px;
      color: ${theme2.palette.brandLinx}
    }

    p {
      margin: 10px 0;
    }

    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: center;

    .label {
      font-weight: bold;
    }

    .btn-show-hide {
      display: block;
      margin: 10px 0;
    }

    .justify-start {
      justify-content: start;
    }
`;

export const ModalFooter = styled.footer`  
  background-color: #EEF0F3;
  height: 66px;
  margin-top: auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
`;

export const ButtonFooter = styled.button`
  background-color: ${props => props.color || theme2.palette.brandLinx};
  
  min-width: 116px;
  height: 34px;
  
  font-size: 14px;
  color: #ffffff;
  
  border-radius: 3px;
  
  outline: none;
  border: none;
  margin: 0 10px;

  :last-child
  {
    margin-right: 0px; 
  }
`;

export const ModalFooterExtraContent = styled.div`
  margin-right: auto;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;

  i {
    color: ${theme2.palette.brandLinx};
    margin-right: 8px;
  }
`;

