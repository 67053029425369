import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  isOnline: true,
};

const pdvContingencyStatusReducer = createReducer(initialState, {
    [types.PDV_CONTINGENCY_STATUS]: (state, action) => ({
        ...state,
        isOnline: action.isOnline,
    })
});

export default pdvContingencyStatusReducer;