import React from 'react';
import CardQueryComponent from '../card-query-component';
import { ProductService } from '../../../services/productService';
import SaleService from '../../../services/saleService';

export class ProductSearch extends React.Component {
  constructor(props) {
    super(props);

    this.productService = new ProductService();
    this.saleService = new SaleService();
    this.config = {
      customClass: 'product',
      selectable: true,
      limitPerPage: 300,
      channelToListenResponse: 'pos.product.listed',
      queryFn: this.doSearch.bind(this),
      lazy: true,
      reloadInPercent: 0.95,
      columns: [
        { title: 'Nome', label: 'name', fixedWidth: '20vw' },
        { title: 'Fabricante', label: 'supplier', fixedWidth: '18vw' },
        { title: 'Princípio Ativo', label: 'activePrinciple', fixedWidth: '18vw' },
        { title: 'Estoque', label: 'stock', fixedWidth: '8vw' },
        { title: 'Valor', label: 'price', currencyFormat: true, fixedWidth: '10vw' },
        { title: 'Estoque de filiais', label: 'referenceId', customCellrender: this.props.renderBranchesStock, customCellClass: 'no-padding' }
      ],
      fieldPaginated: 'result'
    };

    if (this.props.initialQuery) {
      this.doSearch(this.props.initialQuery.query, 1, 300);
    }

    this.focusRowIdx = this.focusRowIdx.bind(this);
  }

  doSearch(query, page, limit) {
    if (query.charAt(0) === '&')
      query = query.replace("&", "");
    else
      query = this.props.toggleOnOptionSearchRuleSelected === 1 ? "%" + query : query;

    const onlyNumbers = /^[\d]+$/.test(query);
    if (onlyNumbers) {
      this.saleService
        .addItem(query, this.props.quantity)
        .catch(error => {
          this.context.showMessage(error);
        });
      return;
    }
    let criteriaType = query && query.length > 0 && query[0] === "%" ? 0 : 1;
    query = criteriaType === 0 ? query.substring(1) : query;
    this.productService.listProductsByCriteria(query, page, limit, criteriaType)
  }

  focusRowIdx(idx) {
    this.cardRef.focusRowIdx(idx);
  }

  focusFilter() {
    this.cardRef.focusFilter();
  }

  focusInput() {
    this.cardRef.focusInput();
  }

  render() {

    return (
      <CardQueryComponent
        {...this.props}
        ref={ref => this.cardRef = ref}
        config={this.config}
        title={'Pesquisa de Produtos'}
        placeholder="Produto"
        onHandleKeyDown={this.onHandleKeyDown}
        messageWhenEmptyTable="Nenhum produto encontrado"
      />
    )
  }
}

export default ProductSearch;
