import styled from 'styled-components';
import theme from '../../../themes/default';

const CpfCnpjWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;

  .customer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    .customer-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .customer-data {
        display: flex;
        flex-direction: column;
        margin-right: 24px;

        div:first-child {
          margin-bottom: 16px;
        }

        .customer-data-group {
          display: flex;
          flex-direction: column;
          color: ${theme.palette.primary[16]};
          font-weight: 500;
  
          span:nth-child(1) {
            font-size: 13px;
            display: block;
            margin-bottom: 4px;
          }

          span:nth-child(2) {
            font-size: 20px;
            text-transform: uppercase;
          }
        }
      }
    }

    .section-divider {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 24px 0;

      hr {
        width: 100%;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        display: block;
        margin: 0 24px;
      }
    }
  }

  .cpf-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-group-cpf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    label {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 12px;
    }

    input {
      width: 344px;
      color: ${theme.palette.grayscale.ultraDark};
      outline: none;
      font-size: 20px;
      padding: 12px;
      text-align: center;

      &:focus {
        box-shadow: 0 0 0 3px rgba(91, 46, 144, 0.4);
        border: 1px solid ${theme.palette.primary[14]};
      }

      &:hover {
        border: 1px solid ${theme.palette.primary[14]};
      }
    }
  }

  .cpf-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    button:nth-child(1) {
      margin-right: 12px;
    }

    button:nth-child(2) {
      margin-left: 12px;
    }
  }

  .button-linx {
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 108px;
    max-width: 216px;
    height: 40px;
    padding: 0 12px;
    flex: 1;

    &:focus {
      box-shadow: 0 0 0 3px rgba(91, 46, 144, 0.4);
    }
  }

  .button-outline {
    border: 1px solid ${theme.palette.primary[14]};
    background-color: #ffffff;
    color: ${theme.palette.primary[14]};

    &:hover {
      background-color: ${theme.palette.primary[14]};
      color: #ffffff;
    }
  }

  .button-primary {
    border: none;
    background-color: ${theme.palette.primary[14]};
    color: #ffffff;

    &:hover {
      background-color: ${theme.palette.primary[15]};
    }
  }
`;

export default CpfCnpjWrapper;
