import styled from 'styled-components';
import theme from '../../../themes/default';

const FooterWrapper = styled.div`

  display: grid;
  grid-area: footer;
  grid-template-columns: 50% 50%;
  grid-template-rows: 60px;

  line-height: 60px;

  padding: 0 25px;

  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

  background-color: ${theme.palette.grayscale.white};

  .configurations { text-align: right; }

  .info {
    font-size: 25px;
    color: #5b2e90;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;
export default FooterWrapper;
