import React, { useState } from 'react';

import ReactModal from 'react-modal';

import { ButtonComponent } from '../../button-component';
import {
  Container,
  ContainerButton,
  DivAnimation,
  DivTitle,
  SpanBall,
} from './style';

export default function ModalLiberacao(props: any) {
  const [btnCancelOnClick, setBtnCancelOnClick] = useState<boolean>(false);

  const cancel = () => {
    setBtnCancelOnClick(true);
    props.onCancel();
  };

  return (
    <ReactModal
      isOpen={true}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      style={customStyles}
    >
      <Container>
        <DivTitle>
          {!btnCancelOnClick && <h5>Aguardando liberação...</h5>}

          {btnCancelOnClick && <h5>Cancelando solicitação...</h5>}
        </DivTitle>

        <DivAnimation>
          <SpanBall
            className='ball-animation ball-1'
            backgroundColor='#6166c5'
            animationDelay='0s'
          />
          <SpanBall
            className='ball-animation ball-2'
            backgroundColor='#5387B9'
            animationDelay='0.3s'
          />
          <SpanBall
            className='ball-animation ball-3'
            backgroundColor='#47A2B0'
            animationDelay='0.5s'
          />
          <SpanBall
            className='ball-animation ball-4'
            backgroundColor='#40B2AA'
            animationDelay='0.7s'
          />
          <SpanBall
            className='ball-animation ball-5'
            backgroundColor='#36CCA0'
            animationDelay='0.9s'
          />
        </DivAnimation>

        <ContainerButton>
          <ButtonComponent
            className='btn-b-success'
            title={'Cancelar Solicitação'}
            disabled={btnCancelOnClick}
            onClick={cancel}
            style={{
              background: '#6E35B1',
              width: '218px',
            }}
            propsLabel={{
              style: {
                fontFamily: 'Dosis',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
                color: '#FFFFFF',
                textTransform: 'uppercase',
              },
            }}
          />
        </ContainerButton>
      </Container>
    </ReactModal>
  );
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    padding: '1.5rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    display: 'block',
    padding: '1.2rem',
    zIndex: 10001,
  },
};
