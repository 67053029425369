import React from 'react';

import store from '../../../redux/store';
import MessageBusService from '../../../services/MessageBusService';
import { Modal } from '../modal2';
import { FormControl, TextArea, Button } from '../modules';
import { FormDetail } from './style';

export class RequireAuth extends React.Component {
  static defaultProps = {
    closable: true,
    requireUsername: true,
    requirePassword: true,
    requireReason: false,
    currentSellerReferenceId: null,
  };

  constructor(props) {
    super(props);

    this.fieldsRef = {
      operatorRefId: React.createRef(),
      password: React.createRef(),
      reason: React.createRef(),
    };
    this.form = {
      operatorRefId: '',
      password: '',
      reason: '',
    };

    this.modalConfirm = React.createRef();
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleDialogClosed = this.handleDialogClosed.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);

    this.subscription = null;

    this.state = {
      reasonCharLeft: 255,
      inputError: {
        operatorRefId: false,
        password: false,
      }
    };
  }

  componentDidMount() {
    this.modalConfirm.current.open();
    setTimeout(() => {
      const state = store.getState(store);
      if (this.fieldsRef.operatorRefId.current != null) {
        if (
          this.props.shouldSetUsername &&
          !state.util.shouldAuthenticateOperation &&
          state.order.order?.currentSellerReferenceId
        ) {
          this.form.operatorRefId = state.order.order?.currentSellerReferenceId;
          this.fieldsRef.operatorRefId.current.value =
            state.order.order?.currentSellerReferenceId;
          this.fieldsRef.password.current.focus();
          return;
        }
        this.fieldsRef.operatorRefId.current.focus();
        return;
      }
      if (this.fieldsRef.password.current) {
        this.fieldsRef.password.current.focus();
        return;
      }
      this.fieldsRef.reason.current && this.fieldsRef.reason.current.focus();
    }, 30);
    document
      .querySelector('.modal-back-wrapper')
      .addEventListener('keydown', this.handleKeyDown);
  }

  handleConfirm(e) {
    e.stopPropagation();
    e.preventDefault();
    this.subscription = MessageBusService.GetInstance().Subscribe(
      'pos.dialog-closed',
      this.handleDialogClosed
    );
    this.props.handleConfirm(this.form);
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleConfirm(e);
      return;
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      this.props.closable && this.props.handleClose();
    }
  }

  handleDialogClosed() {
    if (this.subscription) {
      MessageBusService.GetInstance().Unsubscribe(this.subscription);
      this.subscription = null;
    }
    this.fieldsRef.operatorRefId.current &&
      this.fieldsRef.operatorRefId.current.focus();
  }

  componentWillUnmount() {
    if (this.subscription) {
      MessageBusService.GetInstance().Unsubscribe(this.subscription);
      this.subscription = null;
      const component = document.querySelector('.modal-back-wrapper');
      component && component.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleReasonChange(e) {
    this.setState({
      reasonCharLeft: 255 - e.target.value.length,
    });
  }

  handleChange(e) {
    this.form[e.target.id] = e.target.value;
    this.forceUpdate();
  }

  handleRequired(e) {
    let field = e.target.id;

    if(e.target.value === '' && e.target.required){
      this.setState((old) =>
        ({ inputError: { ...old.inputError, [field]: true} })
        )
    }else{
      this.setState((old) =>
        ({ inputError: { ...old.inputError, [field]: false} })
        )
    }
  }

  render() {
    const { reasonCharLeft } = this.state;

    const { requireReason, requirePassword, requireUsername } = this.props;
    return (
      <Modal
        title={this.props.title}
        ref={this.modalConfirm}
        closable={this.props.closable}
        opened
        style={{minWidth: "500px"}}
        handleClose={this.props.handleClose}
        closeOnOverlayClick={this.props.closable}
      >
        <FormDetail>
          <Modal.Body className="require-auth">
            <form>
              {requireUsername && (
                <div className="form-group-require-auth">
                  <label htmlFor="operatorRefId"> Usuário </label>
                  <input
                    id="operatorRefId"
                    className={`form-control ${this.state.inputError.operatorRefId && "input-error"}`}
                    onChange={this.handleChange}
                    ref={this.fieldsRef.operatorRefId}
                    value={this.form.operatorRefId}
                    aria-describedby="operatorRefIdHelp"
                    placeholder="Usuário"
                    name="usuario"
                    required
                    onBlur={(e) => this.handleRequired(e)}
                  />
                  <span className="message-error">{this.state.inputError.operatorRefId && "Campo obrigatório!"}</span>
                </div>
              )}

              {requirePassword && (
                <div className="form-group-require-auth">
                  <label htmlFor="password"> Senha </label>
                  <input
                    type="password"
                    id="password"
                    className={`form-control ${this.state.inputError.password && "input-error"}`}
                    placeholder="Senha"
                    ref={this.fieldsRef.password}
                    value={this.form.password}
                    onChange={this.handleChange}
                    name="senha"
                    required
                    onBlur={(e) => this.handleRequired(e)}
                  />
                  <span className="message-error">{this.state.inputError.password && "Campo obrigatório!"}</span>
                </div>
              )}
              {requireReason && (
                <div className="custom-form-row">
                  <FormControl
                    required
                    label={`Motivo do Cancelamento (${reasonCharLeft} caracteres restantes)`}
                    onChange={this.handleReasonChange}
                    maxLength={255}
                    component={TextArea}
                    object={this.form}
                    field="reason"
                    flex="1"
                    ref={this.fieldsRef.reason}
                  />
                </div>
              )}
              <Button
                type="submit"
                className="btn-large"
                onClick={this.handleConfirm}
              >
                Confirmar
              </Button>
            </form>
          </Modal.Body>
        </FormDetail>
      </Modal>
    );
  }
}
