import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { Row } from './row';

export class Body extends React.Component {
  render() {
    const { customClass, data, columns, selectable, onRowSelect, focusFilter, selected, moreOptionsToShow } = this.props;

    return (
      <tbody className={`table-body ${customClass}`}>
        {data &&
          data.map((rowData, index) => (
            <Row
              focusFilter={focusFilter}
              key={uuid()}
              rowData={rowData}
              columns={columns}
              selectable={!rowData.active || rowData.active === 'ACTIVE' ? selectable : false}
              onRowSelect={(row) => onRowSelect(row, index)}
              selected={selected}
              moreOptionsToShow={moreOptionsToShow}
            />
          ))}
      </tbody>
    );
  }
}

Body.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  selectable: PropTypes.bool,
  onRowSelect: PropTypes.func,
};

Body.defaultProps = {
  selectable: false,
  data: null,
  onRowSelect: null,
};

export default Body;
