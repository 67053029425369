import { AbstractService } from './abstractService';

export default class FarmaciaPopularService extends AbstractService {
  constructor() {
    super('/FarmaciaPopular');
  }

  Start = () => this.invokeAction('/start');

  StartBack = () => this.invokeAction('/startBack');

  Authorize = (payload) => this.invokeAction('/authorize', payload);

  AuthorizedBack = () => this.invokeAction('/authorizedBack');

  showCustomerInfo = () => this.invokeAction('/showCustomerInfo');

  setCustomerInfo = (customerInfo) => this.invokeAction('/setCustomerInfo', { customerInfo });

  AuthorizedConfirmed = (payload) =>
    this.invokeAction('/authorizedConfirmed', payload);

  reprintCouponPbm = (OrderId) =>
    this.invokeAction('/reprintCouponPbm', { OrderId });

  retryAuthorization = () => this.invokeAction('/retryAuthorizationConfirm');

  startOrderCancellation = () => this.invokeAction('/startOrderCancellation');
}
