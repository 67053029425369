import AbstractService from './abstractService';
import HttpAccess from './httpAccess';
import Configuration from '../configuration';
import handlePost from '../shared/utils/handlePost';
import { IRegisterObjectPro } from '../shared/interfaces/register-objectpro';

export class ObjectProService {
  private abstractService = new AbstractService(
    'objectPro',
    HttpAccess,
    Configuration
  );

  public getClientes(cpfCartao: string) {
    const url = `${this.abstractService.getBaseUrl()}/objectPro/ConsultarClientes`;
    const body = {
      cpfCartao,
    };

    return handlePost(url, body, HttpAccess);
  }

  public saveCliente(customer: IRegisterObjectPro) {
    const url = `${this.abstractService.getBaseUrl()}/objectPro/CadastrarClientes`;
    const body = {
      customer,
    };

    return handlePost(url, body, HttpAccess);
  }

  public selecionarCliente(customer: IRegisterObjectPro) {
    const url = `${this.abstractService.getBaseUrl()}/objectPro/SelecionarClientes`;
    const body = {
      customer,
    };

    return handlePost(url, body, HttpAccess);
  }
}
