import * as types from './actionTypes';

export const orderUpdated = (order) => (dispatch) => {
  dispatch({
    type: types.ORDER_UPDATED,
    payload: order,
  });
};

export const deleteOrder = () => async (dispatch) => {
  dispatch({
    type: types.DELETE_ORDER,
  });

  dispatch({
    type: types.CLOSE_LIST_PAYMENTS,
    payload: [],
  });
};

export const showButtonPaymentPreOrder = () => (dispatch) => {
  dispatch({ type: types.SHOW_BUTTON_PAYMENT_PRE_ORDER });
};

export const showButtonDonePreOrder = () => (dispatch) => {
  dispatch({ type: types.SHOW_BUTTON_DONE_PRE_ORDER });
};

export const donePreOrder = () => (dispatch) => {
  dispatch({ type: types.DONE_PRE_ORDER });
  dispatch({ type: types.ORDER_RESET_STATE });
};

export const clearOrder = () => (dispatch) => {
  dispatch({ type: types.ORDER_RESET_STATE });
};

export const identifySeller = (seller) => (dispatch) => {
  dispatch({ type: types.ORDER_IDENTIFY_SELLER, seller });
};

export const identifySellerId = (sellerId) => (dispatch) => {
  dispatch({ type: types.ORDER_IDENTIFY_SELLER_ID, sellerId });
};

export const setDialogPosition = (x, y) => (dispatch) => {
  dispatch({ type: types.SET_DIALOG_POSITION, position: { x: x, y: y } });
};

export const objectPreListToDelete = (data) => (dispatch) => {
  dispatch({ type: types.PRE_ORDER_DELETE_ITEM_INFO, data });
};

export const setShowAutorizationOnline = (showAutorizationOnline) => (
  dispatch
) => {
  dispatch({
    type: types.SHOW_AUTORIZATION_ONLINE,
    show: showAutorizationOnline,
  });
};
