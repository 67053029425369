import React, { Component } from 'react';
import DateClockWrapper from './style';

class DateClock extends Component {
  constructor() {
    super();
    this.state = {
      date: new Date().toLocaleDateString(),
      hour: new Date().toLocaleTimeString(),
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer.bind(this), 1000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => this.setState({ hour: new Date().toLocaleTimeString() })

  render() {
    return (
      <DateClockWrapper>
        <span className="date">
          <i className="material-icons"> today </i>
          {' '}
          {this.state.date}
        </span>

        <span className="clock">
          <i className="material-icons"> timer </i>
          {' '}
          {this.state.hour}
        </span>
      </DateClockWrapper>
    );
  }
}

export default DateClock;
