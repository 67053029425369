import React from 'react';

import FooterWrapper from './style';

const Footer = (props) => (
  <FooterWrapper id={'footer-wrapper'}>
    <div className='info'>{props.info}</div>
  </FooterWrapper>
);

export default Footer;
