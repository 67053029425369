import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { ModalHeader, ModelBody } from './style';

import BtnBack from '../../../assets/images/btn-close.svg';

interface IModal {
  title: string;
  onClose: any;
  show: boolean;
  children: JSX.Element | JSX.Element[];
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    padding: '1.5rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    display: 'block',
    padding: '1.2rem',
  },
};

export function ModalComponent(modal: IModal) {
  useEffect(() => {
    document.addEventListener('keydown', close);

    return () => document.removeEventListener('keydown', close);
  }, []);

  const close = (e) => {
    if (e.keyCode === 27) {
      modal.onClose();
    }
  };

  return (
    <ReactModal
      isOpen={modal.show}
      shouldCloseOnEsc={false}
      style={customStyles}
      ariaHideApp={false}
    >
      <ModalHeader>
        <div className='title'>
          <h1>{modal.title}</h1>
        </div>
        <div className='container-btn-close'>
          <span
            data-testid='btn-close-modal'
            className='btn-close'
            onClick={modal.onClose}
          >
            <img src={BtnBack} />
          </span>
        </div>
      </ModalHeader>

      <ModelBody>{modal.children}</ModelBody>
    </ReactModal>
  );
}
