import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SelectedOrderInput, LastOrderList, RequireAuth } from '../../shared/components/modules';
import { showCard, getPayload, ORDER_LIST, shouldShow, closeCard } from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { currencyString, maskDate } from '../../shared/utils/masks';
import SaleService from '../../services/saleService';
import PaymentService from '../../services/paymentService';
import { OrderService } from '../../services/orderService';
import moment from 'moment'
import { modalMessageShow, modalMessageHide } from '../../redux/actions/modalMessageAction';

export class LastOrderContainer extends Component {
  constructor(props) {
    super(props);

    this.service = new SaleService();
    this.paymentService = new PaymentService();
    this.orderService = new OrderService();

    this.orderListRef = React.createRef();

    this.state = ({
      listOrder: [],
      showInputOrderID: false,
      order: { idOrder: '' },
      dateInterval: '',
      requireAuth: false,
      orderId: null,
      listPubSub: [
        'pos.order.listFinished',
        'pos.order.cancelled',
        'pos.order.cancelFailed',
        'pos.order.cancelPbmFailed',
        'pos.order.identifyCancellationUser',
        'machine.devices.coupon.cancellation',
        'machine.devices.tef.reprintcoupon.completed',
        'machine.devices.coupon.cancellation.error']
    });
    this.setSubscriptions();
  }

  setSubscriptions() {
    this.props.MessageBus.Subscribe('pos.order.listFinished', this.getListOrder);
    this.props.MessageBus.Subscribe('pos.order.cancelFailed', this.handleCloseShowError);
    this.props.MessageBus.Subscribe('pos.order.cancelPbmFailed', this.handleShowError);
    this.props.MessageBus.Subscribe('machine.devices.tef.reprintcoupon.completed', this.handleClose);
    this.props.MessageBus.Subscribe('machine.devices.coupon.cancellation.error', this.handleCancelError);
    window.desktopApp && window.desktopApp.subscribe('machine.devices.tef.cancelcoupon.completed', this.handleClose);
  }

  componentDidMount() {
    const now = new Date();
    this.setState({ dateInterval: now.toLocaleDateString() });
  }

  getListOrder = (canal, payload) => {
    const mergedPayload = { ...this.props.payload, ...payload };
    this.props.orderListShow(mergedPayload)
    let listOrder = [];

    if (this.props.payload.printTef || this.props.payload.cancelTef) {
      let list = payload.orders.filter(cl => cl.payments.some(r => r.controlCode != null));

      for (var item in list) {
        // eslint-disable-next-line
        list[item].payments.forEach((element, idx) => {
          if (element.controlCode !== null) {
            listOrder.push({
              couponNumber: list[item].receiptNumber,
              customer: list[item].customer ? list[item].customer.name : 'Não Identificado',
              seller: list[item].currentSellerName ? list[item].currentSellerName : 'Não Identificado',
              total: currencyString(element.amount),
              canceled: element.canceled ? 'Cancelado' : 'Ativo',
              idOrder: list[item].id,
              documentDate: new Date(list[item].fiscalDocument.returnDate).toLocaleString("pt-BR"),
              controlCode: element.controlCode,
              // eslint-disable-next-line
              idOrder: list[item].id,
              fiscalDocument: list[item].fiscalDocument,
              itens: [{
                key: idx,
                name: 'NSU: ' + element.controlCode + ' - ' + element.brand.name,
                price: currencyString(element.amount),
                quantity: 1,
                subTotal: currencyString(element.amount),
              }],
              payments: [{
                key: idx,
                amount: element.cash ? element.cash : element.amount,
                amountFormated: currencyString(element.cash ? element.cash : element.amount),
                name: element.paymentType.name,
                paymentType: element.paymentType,
                controlCode: element.controlCode,
                authorizationCode: element.authorizationCode,
                creationDate: new Date(list[item].fiscalDocument.returnDate).toLocaleString("pt-BR")
              }],
              id: `order-id-${item}-${idx}`,
              key: `order-key-${item}-${idx}`
            });
          }
        });
      }
    } else {
      listOrder = payload.orders.map((item, idx) => ({
        couponNumber: item.receiptNumber,
        customer: item.customer ? item.customer.name : 'Não Identificado',
        seller: item.currentSellerName ? item.currentSellerName : 'Não Identificado',
        total: currencyString(item.totalDiscount),
        documentDate: new Date(item.fiscalDocument.returnDate).toLocaleString("pt-BR"),
        itens: item.items.map((product, index) => ({
          name: product.product.name,
          price: currencyString(product.product.price),
          quantity: product.quantity,
          subTotal: currencyString(product.subTotal),
          id: `item-id-${index}`,
          key: `item-key-${index}`
        })),
        payments: item.payments.map(p => {
          return {
            amount: currencyString(p.cash ? p.cash : p.amount),
            name: p.paymentType.name,
            paymentType: p.paymentType
          }
        }),
        idOrder: item.id,
        fiscalDocument: item.fiscalDocument,
        id: `order-id-${idx}`,
        key: `order-key-${idx}`
      }));
    }

    this.setState({ listOrder });
  }

  onSelectOrder = (order) => {
    this.setState({
      showInputOrderID: true,
      order
    });
  }

  onFilterOrders = (start, end) => {
    if (moment(start).isAfter(moment(end))) {
      this.context.showMessage('A data inicial não pode ser maior que a data final');
      return;
    }
    this.orderService.getListLastOrder(moment(start).startOf('day').toISOString(), moment(end).endOf('day').toISOString())
      .catch(error => this.context.showError(error));
  }

  focusOrderLine = () => {
    const elem = document.querySelector(`#${this.state.order.id}`);
    elem && elem.focus();
  }

  handleInputOrderID = (visible, refocus) => {
    if (!visible && refocus) {
      this.focusOrderLine();
    }
    this.setState({
      showInputOrderID: visible
    });
  }

  closeModal = () => {
    this.clearState();
    this.props.hideOrderList();
  }

  handleCloseShowError = (payload) => {
    this.setState({
      showInputOrderID: false,
      requireAuth: false,
    });

    this.context.showMessage(payload);
  }

  handleShowError = (channel, payload) => {
    this.setState({
      showInputOrderID: false,
      requireAuth: false,
    });

    this.context.showMessage(payload);
  }

  handleClose = (ch, data) => {
    if (ch == 'machine.devices.tef.cancelcoupon.completed')
      this.orderService.setTefPaymentToCancelled({ OrderId: data.result.idOrder, ControlCode: data.result.controlCode });
    this.clearState();
    this.props.hideOrderList();
  }

  handleKeyPress(e) {
    e.target.value = maskDate(e.target.value);
  }

  handleCancelOperation = ({ reason }) => {
    if (reason.length < 15) {
      this.context.showMessage('O motivo do cancelamento deve conter entre 15 e 255 caracteres.');
      return;
    }
    if (window.desktopApp) {
      this.focusOrderLine();
      this.setState({
        requireAuth: false
      })
      this.props.showModal(() => {
        if (!this.orderListRef.current) return;
        this.orderListRef.current.handleConfirm();
        this.orderListRef.current.focus();
      });
    }
    this.orderService.cancelOrderId(this.state.order.idOrder, reason, this.props.tpAmb)
      .catch(error => this.context.showError(error))
  }

  handleModalClose = () => {
    this.setState({
      requireAuth: false
    });
    this.focusOrderLine();
  }

  handleOrderCancel = () => {
    this.setState({
      requireAuth: true
    });
  }

  handlerPrintTef = (orderObj) => {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.tef.reprintcoupon', orderObj);
    }
  }

  handlerCancelTef = (orderObj) => {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.tef.cancelcoupon', orderObj);
    }
  }

  identifyCancellationUser = () => {
    this.setState({
      showInputOrderID: false,
      requireAuth: true,
    });
  }

  closeIdentify() {
    this.setState({
      showInputOrderID: false,
      requireAuth: false,
    });
  }

  clearState() {
    this.setState({
      listOrder: [],
      showInputOrderID: false,
      order: { idOrder: '' },
      dateInterval: '',
      requireAuth: false,
      orderId: null
    });
  }

  render() {
    const { listOrder, showInputOrderID, requireAuth } = this.state;
    return (
      <Fragment>
        {this.props.showOrderList && (
          <LastOrderList
            ref={this.orderListRef}
            listItens={listOrder}
            handleClose={this.handleClose}
            handleClick={this.onFilterOrders}
            handleKeyPress={(e) => this.handleKeyPress(e)}
            onHandleSelectPreOrder={this.onSelectOrder}
            title='Listagem de últimas vendas'
            txtDateInteval={this.state.dateInterval}
            showExtraColumns={this.props.payload.reprintTef || this.props.payload.cancelTef}
          />
        )}
        {requireAuth &&
          <RequireAuth
            title="Cancelar a venda"
            handleConfirm={this.handleCancelOperation}
            handleClose={this.handleModalClose}
            requireUsername={false}
            requirePassword={false}
            requireReason
          />
        }
        <SelectedOrderInput
          showModal={showInputOrderID}
          order={this.state.order}
          idOrder={this.state.order.idOrder}
          handleOrderCancel={this.handleOrderCancel}
          handlerStateModal={this.handleInputOrderID}
          handlerPrintTef={this.handlerPrintTef}
          typeButtonPrint={this.props.payload.reprintTef}
          typeButtonCancelTef={this.props.payload.cancelTef}
          handlerCancelTef={this.handlerCancelTef}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  payload: getPayload(state, ORDER_LIST) || {},
  showOrderList: shouldShow(state, ORDER_LIST),
  tpAmb: state.config && state.config.danfe && state.config.danfe.tpAmb
});

const mapDispatchToProps = dispatch => ({
  hideOrderList: () => dispatch(closeCard(ORDER_LIST)),
  orderListShow: (payload) => dispatch(showCard(ORDER_LIST, payload, true)),
  showModal: callback => dispatch(modalMessageShow(callback)),
  hideModal: () => dispatch(modalMessageHide())
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(LastOrderContainer)));
