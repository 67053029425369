import styled from 'styled-components';

export const ModalHeader = styled.div`
    display: flex;

    .title {
        display: flex;
        flex: 1;
        align-items: center;

        h1 {
            font-family: 'Dosis';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 0px;
        }
    }

    .container-btn-close {
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }
`

export const ModelBody = styled.div`
    display: flex;
    margin: 1rem 0px 0px 0px;
`