import React, { Component } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { currencyMask } from '../../utils/masks';
import Input from '../input';

export class Cell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      value: this.mapRow(),
    };
  }

  render() {
    const isCollapsableIsList =
      this.props.collapsable &&
      Array.isArray(this.mapRow()) &&
      this.mapRow().length > 1;

    const renderer = this.getRenderToCell();

    return (
      <td>
        <div className={this.getClassesCell()} style={this.getStylesCell()}>
          <span className="content-cell" style={this.props.style}>
            {renderer}
            {isCollapsableIsList && (
              <span className="other-values">
                {this.mapRow().map(
                  (item, index) =>
                    index > 0 && (
                      <span key={index} className="other-value">
                        {item}
                      </span>
                    )
                )}
              </span>
            )}
          </span>
          {isCollapsableIsList && (
            <i className="material-icons" onClick={this.changeStateCollapse}>
              {this.state.opened ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
            </i>
          )}
        </div>
      </td>
    );
  }

  updateValue = (e) => {
    const { value } = e.target;

    this.setState({ value });

    this.props.value[this.props.label] = value;
  };

  getRenderToCell = () => {
    const {
      fixValue,
      customCellrender,
      value,
      editable,
      pattern,
    } = this.props;

    if (fixValue) {
      return fixValue;
    }

    if (customCellrender) {
      return customCellrender(value);
    }

    let renderer = this.mapRow();

    renderer = Array.isArray(renderer) ? renderer[0] : renderer;

    if (editable) {
      renderer = (
        <Input
          pattern={pattern}
          value={this.state.value}
          onChange={this.updateValue}
        />
      );
    }

    return renderer;
  };

  mapRow = () => {
    if (this.props.fixValue) {
      return this.props.fixValue;
    }
    if (!this.props.value && this.props.value !== 0) {
      return '';
    }
    const value = this.props.value[this.props.label] || this.props.textIfNull;

    if (value && (value instanceof Date || this.props.type === 'date')) {
      try {
        const date = value instanceof Date ? value : new Date(value);

        const strDate = moment(date).format(this.props.mask || 'DD/MM/YYYY');

        return strDate === 'Invalid date' ? '' : strDate;
      } catch (err) {
        return value;
      }
    } else if (this.props.currencyFormat || this.props.type === 'currency') {
      return currencyMask(value || 0);
    }

    if (this.props.percentageFormat) {
      return `${value || 0}%`;
    }

    return value;
  };

  getStylesCell = () => {
    const { fixedWidth, aligment } = this.props;

    const styles = {
      textAlign: aligment,
    };

    if (fixedWidth && fixedWidth > 0) {
      styles.maxWidth = `${fixedWidth}px`;
      styles.minWidth = styles.maxWidth;
      styles.width = styles.maxWidth;
    } else {
      styles.width = fixedWidth;
    }

    return styles;
  };

  getClassesCell = () => {
    const { fixValue, collapsable, editable, customCellClass } = this.props;

    let cellClasses = 'table-cell';

    if (!fixValue) {
      cellClasses += collapsable ? ' collapsable-cell' : '';
      cellClasses += this.state.opened ? ' open' : '';
      cellClasses += editable ? ' input' : '';
      cellClasses += customCellClass ? ' ' + customCellClass : '';
    }

    return cellClasses;
  };

  changeStateCollapse = (e) => {
    e.stopPropagation();

    const { opened } = this.state;

    this.setState({ opened: !opened });
  };
}

Cell.propTypes = {
  fixedWidth: PropTypes.number,
  fixValue: PropTypes.string,
  collapsable: PropTypes.bool,
  editable: PropTypes.bool,
  aligment: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.objectOf(PropTypes.any),
  pattern: PropTypes.string,
  type: PropTypes.string,
};

Cell.defaultProps = {
  fixedWidth: null,
  fixValue: null,
  collapsable: false,
  editable: false,
  aligment: '',
  label: '',
  value: {},
  pattern: null,
  type: null
};

export default Cell;
