import styled from 'styled-components';

export const Div = styled.div`
    display: flex;
    justify-content: flex-end;

    span {
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #5B2E90;
        margin-top: .3rem;
    }
`