
import * as types from './actionTypes'

export const blockShortcuts = (shortcutsToBlock) => (dispatch) => {
    dispatch({
      type: types.BLOCK_SHORTCUTS,
      shortcutsToBlock
    });
  };
  
export const unblockShortcuts = (shortcuts) => (dispatch) => {
  dispatch({
    type: types.UNBLOCK_SHORTCUTS,
    shortcuts
  });
};

export const blockAllShortcutsBut = (shortcutsToNotBlock) => { return { type: types.BLOCK_ALL_SHORTCUTS_BUT, shortcutsToNotBlock } };

export const unblockAllShortcuts = (shortcuts) => { return { type: types.UNBLOCK_ALL_SHORTCUTS,shortcuts } };