import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialVisibleState = {
  cardOpened: false,
  cardTitles: []
};

const cardReducer = createReducer(initialVisibleState, {
  [types.CARD_OPENED]: (state, action) => {
    const cards = state.cardTitles;
    const idx = state.cardTitles.findIndex(s => s === action.title);
    if(idx === -1) {
      cards.push(action.title);
    }
    return {
      cardOpened: true,
      cardTitles: cards
    }
  },
  [types.CARD_CLOSED]: (state, action) => {
    const cards = state.cardTitles;
    const idx = state.cardTitles.findIndex(t => t === action.title);
    cards.splice(idx, 1);
    return {
      cardOpened: cards.length > 0,
      cardTitles: cards
    }
  }
});

export default cardReducer;
