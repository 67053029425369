import CashOpenIcon from '../../assets/images/cash-register.png';
import PayOffIcon from '../../assets/images/menu/baixa-de-titulos.png';
import CancelTefIcon from '../../assets/images/menu/cancelar-comprovante-tef.png';
import DeliveryControlIcon from '../../assets/images/menu/delivery-control.png';
import CashCloseIcon from '../../assets/images/menu/fechamento-de-caixa.png';
import CashCloseIconPartial from '../../assets/images/menu/fechamento-de-caixa-parcial.svg';
import LinxConectaIcon from '../../assets/images/menu/linx-conecta.png';
import PreOrderListIcon from '../../assets/images/menu/listagem-de-pre-vendas.png';
import NotesMonitorIcon from '../../assets/images/menu/monitor-notas-fiscais.png';
import NewSaleIcon from '../../assets/images/menu/nova-venda.png';
import PickupIcon from '../../assets/images/menu/sangria.png';
import CashSupplyIcon from '../../assets/images/menu/suprimento.png';
import ObjectProIcon from '../../assets/images/menu/objectpro.svg';
import PrinterIcon from '../../assets/images/menu/impressora.svg';
import getShortcut, {
  PAY_OFF,
  PRE_SALE_LIST,
  CLOSE_REGISTER,
  NEW_SALE,
  REGISTER_PICK_UP,
  OPEN_REGISTER,
  SUPPLY,
  LINX_CONECTA,
  DELIVERY_CONTROL,
  NOTES_MONITOR,
  OBJECTPRO,
} from '../../keyboardShortcuts';
import { CONFIG_DELIVERY, CONFIG_DTEF, CONFIG_LINX_CONECTA } from './configs';
import {
  OPERATION_NEW_SALE,
  OPERATION_CASH_OPEN,
  OPERATION_SANGRIA,
  OPERATION_CASH_SUPPLY,
  OPERATION_CANCEL_TEF,
  OPERATION_PAY_OFF,
  OPERATION_NAME_LINX_CONECTA,
  OPERATION_NAME_DELIVERY_CONTROL,
  OPERATION_PRE_ORDER_LIST,
  OPERATION_CASH_CLOSE,
  OPERATION_MONITOR_NOTAS,
  OPERATION_CASH_CLOSE_PARTIAL,
  OPERATION_OBJECTPRO,
  OPERATION_DOCUMENT_PRINTING
} from './operations';

const items = [
  {
    operationName: OPERATION_NEW_SALE,
    icon: NewSaleIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(NEW_SALE),
    showBothPdv: true,
    enabled: true,
    showPdvContingency: true,
  },
  {
    operationName: OPERATION_PRE_ORDER_LIST,
    icon: PreOrderListIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(PRE_SALE_LIST),
    showBothPdv: true,
    enabled: true,
    showPdvContingency: true,
  },
  {
    operationName: OPERATION_CASH_CLOSE,
    icon: CashCloseIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(CLOSE_REGISTER),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: false,
  },
  {
    operationName: OPERATION_CASH_CLOSE_PARTIAL,
    icon: CashCloseIconPartial,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(CLOSE_REGISTER),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: false,
  },
  {
    operationName: OPERATION_CANCEL_TEF,
    icon: CancelTefIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    // shortKey: EventList.reprintLastVoucher,
    showBothPdv: false,
    enabled: true,
    configName: CONFIG_DTEF,
    showPdvContingency: false,
  },
  {
    operationName: OPERATION_PAY_OFF,
    icon: PayOffIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(PAY_OFF),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: false,
  },
  {
    operationName: OPERATION_MONITOR_NOTAS,
    icon: NotesMonitorIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(NOTES_MONITOR),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: false,
  },
  {
    operationName: OPERATION_CASH_SUPPLY,
    icon: CashSupplyIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(SUPPLY),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: false,
  },
  {
    operationName: OPERATION_SANGRIA,
    icon: PickupIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(REGISTER_PICK_UP),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: false,
  },

  {
    operationName: OPERATION_CASH_OPEN,
    icon: CashOpenIcon,
    showOnCashDeskOpened: false,
    shortKey: getShortcut(OPEN_REGISTER),
    showBothPdv: true,
    enabled: true,
    showPdvContingency: true,
  },

  {
    operationName: OPERATION_NAME_LINX_CONECTA,
    icon: LinxConectaIcon,
    isHidden: true,
    shortKey: getShortcut(LINX_CONECTA),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_LINX_CONECTA,
    showPdvContingency: false,
  },

  {
    operationName: OPERATION_NAME_DELIVERY_CONTROL,
    icon: DeliveryControlIcon,
    isHidden: true,
    shortKey: getShortcut(DELIVERY_CONTROL),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
    configName: CONFIG_DELIVERY,
    showPdvContingency: true,
  },

  {
    operationName: OPERATION_OBJECTPRO,
    icon: ObjectProIcon,
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(OBJECTPRO),
    showBothPdv: true,
    enabled: true,
    showPdvContingency: false,
  },

  {
    operationName: OPERATION_DOCUMENT_PRINTING,
    icon: PrinterIcon,
    showOnCashDeskOpened: true,
    //shortKey: getShortcut(REGISTER_PICK_UP),
    showBothPdv: false,
    enabled: true,
    showPdvContingency: true,
  },
];

export default items;
