import styled from 'styled-components';

import theme2 from '../../../themes/theme2';
import theme from '../../../themes/theme2';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div {
    padding-bottom: ${theme.metrics.basePadding * 2}px;
  }

  button {
    align-self: flex-end;
    padding: 0 20px;
  }
`;

export const Cep = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.2;

  margin-right: 15px;

  label {
    margin-bottom: 3px;
    white-space: nowrap;
    line-height: 20px;
  }

  input {
    padding: 0 10px;
    width: 100%;
    height: 40px;
    border: 1px solid ${theme2.palette.border};
    border-radius: 3px;
    box-sizing: border-box;
    background: #fff;
  }

  .error {
    color: red;
    font-size: 12px;
  }
`;

export const CepHeader = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    padding: 0;
    background: transparent;
    border: 0;
    color: ${theme2.palette.link};
    align-self: flex-end;
    font-size: 14px;
    outline: none;
  }
`;
