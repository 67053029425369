import React, { Component } from 'react';
import { Card, Button } from '../modules';
import Modal from 'react-modal';
import { IdentifyUserWrapper } from './style';



class IdentifyUser extends Component {

  submitHandler = event => {
    
    event.preventDefault();
    if ((this.referenceIdInput.value !== '') && (this.passwordInput.value !== '')) {
      this.props.handleClick(this.referenceIdInput.value, this.passwordInput.value);
    }
    else {
      
      return false
    }
  };

  render() {
    const customStyle = {
      content: {
        maxWidth: '430px',
        maxHeight: '400px',
        background: '#ffffff',
        padding:'1px',
        margin: 'auto',
        border: 'none',
      },
      overlay: {
        background: 'rgba(0, 0, 0, 0.60)',
        display: 'block',
      },
      iconCloseModal: {
        float: 'right',
        cursor: 'pointer',
      },
    };
    const { showModal, title, onCloseModal } = this.props;

    return (
      <Modal isOpen={showModal} closeOnOverlayClick={true} center style={customStyle} ariaHideApp={false}>
      <IdentifyUserWrapper>
        <Card title={title} handleClose={onCloseModal} closeEnable>
          <form onSubmit={e => this.submitHandler(e)} ref={form => (this.formEl = form)}>
            <label htmlFor="ReferenceId">Código do Usuário</label>
            <input id="ReferenceId" type="text" required placeholder="Informe o seu código de usuário" ref={(input) => { this.referenceIdInput = input; }} />
            <label htmlFor="password">Senha</label>
            <input id="password" type="password" required placeholder="Informe a sua senha" ref={(input) => { this.passwordInput = input; }} />
            <Button className="btn-large btn-custom" type="submit" >Confirmar</Button>
          </form>
        </Card>
      </IdentifyUserWrapper>
      </Modal>
    );
  }
}

export default IdentifyUser;
