import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';
import { Card, Button } from '../modules';
import {
  CardListBrandWrapper,
  PaymentCreditWrapper,
  PaymentCreditInput,
} from './style';

class ListBrandsCard extends Component {
  textInput = {};

  static propTypes = {
    title: PropTypes.string,
    handleClose: PropTypes.func,
    handleOnBrand: PropTypes.func.isRequired,
    subTitle: PropTypes.string,
    imageCard: PropTypes.string,
    items: PropTypes.shape({
      amount: PropTypes.number,
      brands: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    }),
  };

  static defaultProps = {
    title: null,
    handleClose: null,
    subTitle: null,
    imageCard: null,
    items: {
      amount: 0,
      brands: [],
    },
  };

  constructor(props) {
    super(props);

    const { items } = this.props;

    this.state = { amount: items.amount, error: '' };
  }

  componentDidMount() {
    if (this.textInput) {
      this.textInput.theInput.focus();
    } else {
      const firstChild = document.getElementById('list-brands').firstChild;

      if (firstChild) {
        firstChild.focus();
      }
    }

    const elems = document.getElementById('list-brands');
    const idElem = document.getElementById('inputAmount');

    elems.addEventListener('keydown', this.onHandleBrandKey);
    idElem.addEventListener('keydown', this.onHandleInputKey);
  }

  // eslint-disable-next-line class-methods-use-this
  onHandleBrandKey = event => {
    event.preventDefault();

    const code = event.keyCode || event.which;
    if ((code === 9 && !event.shiftKey) || (code === 39 || code === 40)) {
      if (event.target.nextSibling) {
        event.target.nextSibling.focus();
      }

      event.preventDefault();
    } else if ((code === 9 && event.shiftKey) || (code === 37 || code === 38)) {
      if (event.target.previousSibling) {
        event.target.previousSibling.focus();
      } else {
        document.getElementById('inputAmount').focus();
      }

      event.preventDefault();
    } else if (code === 13) {
      event.preventDefault();
      event.target.click();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onHandleInputKey = event => {
    const code = event.keyCode || event.which;

    if (code === 40) {
      document.getElementById('list-brands').firstChild.focus();
      event.preventDefault();
    }
  }

  handleConfirm(brand, amountInput) {
    const {
      items,
      handleOnBrand,
    } = this.props;

    this.setState({ error: '' });
    if (amountInput > items.amount) {
      this.setState({ amount: items.amount, error: 'Pagamento com cartão deve ser menor ou igual ao total do cupom!' });
    } else if (amountInput > 0) {
      handleOnBrand(brand, amountInput);
      this.setState({ amount: items.amount });
    } else {
      this.setState({ amount: items.amount, error: 'Informe um valor maior que zero!' });
    }
  }

  clearValue() {
    this.setState({ amount: 0 });
  }

  render() {
    const {
      title,
      handleClose,
      subTitle,
      imageCard,
      items,
    } = this.props;

    const {
      error,
      amount,
    } = this.state;

    return (
      <Card
        title={title}
        handleClose={handleClose}
        subTitle={subTitle}
        urlIcone={imageCard}
        closeEnable
      >
        <PaymentCreditWrapper>
          <PaymentCreditInput>
            <CurrencyInput
              id="inputAmount"
              ref={(text) => { this.textInput = text; }}
              placeholder="Informe o valor"
              value={amount}
              selectAllOnFocus
              decimalSeparator=","
              thousandSeparator="."
            />
            <span className="inputError" id="inputError">{error}</span>
          </PaymentCreditInput>
          <Button
            type="button"
            className="btn-clear btn-outline"
            handleClick={() => this.clearValue()}
          >
            X
          </Button>
        </PaymentCreditWrapper>
        <CardListBrandWrapper>
          <div className="wrapper" id="list-brands">
            {items.brands.map((item, index) => 
            item.name && (
              <button className="list-button" key={index} onClick={() => { this.handleConfirm(item, this.textInput.state.value); }}>
                <img src={imageCard} alt={item.name} className="flag" />
                <span className="text-card">{item.name && item.name}</span>
              </button>
            ))}
          </div>
        </CardListBrandWrapper>
      </Card>
    );
  }
}

export default ListBrandsCard;
