import React, { useEffect } from 'react';

import { addDays, subDays, subMinutes } from 'date-fns';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showMessageAction } from '../../../redux/modules/message/duck';
import FormControl from '../@form-control';
import { DatePicker } from '../date-picker';
import { Card, Table2, Button } from '../modules';
import { PbmAuthorizationList, Filters } from './styles';
import validationSchema from './validation';

function PbmAuthorizationListComponent({
  title,
  handleClose,
  handleSearch,
  handlePrescriptionUpload,
  handleReprint,
  handleReverse,
  pbmOptions,
  statusOptions,
  authorizations,
}) {
  const minDate = subDays(new Date(), 7);
  const maxDate = subMinutes(addDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 1), 1);

  const initialValues = {
    initialDate: minDate,
    finalDate: maxDate,
    pbm: '-1',
    status: '-1',
  };

  const createdAtRef = React.createRef();
  const dispatch = useDispatch();

  useEffect(() => {
    handleSearch(initialValues);
    
    return () => {};
    // eslint-disable-next-line
  }, []);

  function isReverseVisible(item) {
    if (/*item.pbmEnum === 0 &&*/ item.status === 4) return true;
    return false;
  }

  function isEnabledFn() {
    return true;
  }

  function isVisibleReprint(item) {
    if (item.status === 4) return true;
    return false;
  }

  function isVisibleUpload(item) {
    if (item.status !== 4 && item.status !== 3) return false;
    return item.needToUploadPrescription && !item.prescriptionUploaded;
  }

  function renderStatus(row) {
    const status = statusOptions.find((s) => s.value === row.status);
    return status.name;
  }

  function renderUpload(item) {
    if (item.pbmEnum !== 73000) return '--';
    return item.needToUploadPrescription ? 'Sim' : 'Não';
  }

  function renderUploaded(item) {
    if (item.pbmEnum !== 73000) return '--';
    return item.prescriptionUploaded ? 'Sim' : 'Não';
  }

  function handleShowReverseMessage(item) {
    console.log(item);
    dispatch(
      showMessageAction({
        message:
          'Essa operação faz o estorno somente no ambiente do '+item.pbmDescription+', não cancela o documento fiscal.',
        onConfirm: () => handleReverse(item),
        onCancel: () => { },
      })
    );
  }

  const tableConfig = {
    columns: [
      { title: 'PBM', label: 'pbmDescription' },
      {
        title: 'Nº Autorização',
        label: 'authorization',
        textIfNull: 'Não gerada',
      },
      { title: 'N. Nota', label: 'receiptNumber' },
      { title: 'Série', label: 'receiptSeries' },
      { title: 'Valor', label: 'value', currencyFormat: true },
      {
        title: 'Status',
        customCellrender: renderStatus,
      },
      {
        title: 'Data/Hora',
        label: 'dateDocument',
        type: 'date',
        mask: 'DD/MM/YYYY HH:mm',
      },
      {
        title: 'Exige Receita',
        customCellrender: renderUpload,
      },
      {
        title: 'Upload',
        customCellrender: renderUploaded,
      },
    ],
    moreOptionsToShow: [
      {
        name: 'Upload Receita',
        onClick: handlePrescriptionUpload,
        isEnabledFn,
        isVisible: isVisibleUpload,
      },
      {
        name: 'Reimprimir',
        onClick: handleReprint,
        isEnabledFn,
        isVisible: isVisibleReprint,
      },
      {
        name: 'Estornar',
        onClick: handleShowReverseMessage,
        isEnabledFn,
        isVisible: isReverseVisible,
      },
    ],
  };

  return (
    <Card title={title} handleClose={handleClose} closeEnable>
      <PbmAuthorizationList className="box-wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSearch}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Filters>
                <FormControl
                  data-testid="pbm"
                  label="PBM"
                  flex="0.4"
                  type="select"
                  name="pbm"
                  options={pbmOptions}
                />
                <FormControl
                  data-testid="status"
                  label="Status"
                  flex="0.4"
                  type="select"
                  name="status"
                  options={statusOptions}
                />

                <FormControl label="Data Inicial" flex="0.3" name="initialDate">
                  <Field name="initialDate">
                    {({ props }) => (
                      <DatePicker
                        data-testid="initialDate"
                        {...props}
                        ref={createdAtRef}
                        onChange={(value) => {
                          setFieldValue('initialDate', value || '');
                        }}
                        selected={values.initialDate}
                        // minDate={minDate}
                        // maxDate={maxDate}
                        autoFocus
                      />
                    )}
                  </Field>
                </FormControl>

                <FormControl label="Data Final" flex="0.3" name="finalDate">
                  <Field name="finalDate">
                    {({ props }) => (
                      <DatePicker
                        {...props}
                        onChange={(value) =>
                          setFieldValue('finalDate', value || '')
                        }
                        selected={values.finalDate}
                      // minDate={
                      //   values.initialDate &&
                      //   differenceInDays(values.initialDate, minDate) > 0
                      //     ? values.initialDate
                      //     : minDate
                      // }
                      // maxDate={maxDate}
                      />
                    )}
                  </Field>
                </FormControl>
                <Button
                  data-testid="pbm-submit"
                  type="submit"
                  className="btn-purple"
                >
                  <i className="material-icons">search</i>
                </Button>
              </Filters>
            </Form>
          )}
        </Formik>
        <Table2 config={tableConfig} data={authorizations} />
      </PbmAuthorizationList>
    </Card>
  );
}

PbmAuthorizationListComponent.propTypes = {
  title: PropTypes.string.isRequired,
  pbmOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handlePrescriptionUpload: PropTypes.func.isRequired,
  handleReprint: PropTypes.func.isRequired,
  handleReverse: PropTypes.func.isRequired,
  authorizations: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.string,
    })
  ).isRequired,
};

PbmAuthorizationListComponent.defaultProps = {};

export default PbmAuthorizationListComponent;
