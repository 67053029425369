import React from 'react';
import PropTypes from 'prop-types';
import SelectWrapper from './styles';

export const SelectPaymentType = React.forwardRef(({
  handleClickToogleMenu, isToggleOnOption, listFormPayment, clickFormPayment, selectedPaymentMethodName, icon, children, disabled
}, ref) => (
  // disabled
  <SelectWrapper innerRef={ref} className={`title-select ${disabled ? 'disabled' : ''}`} onClick={disabled ? null : handleClickToogleMenu} tabIndex="0">
    {isToggleOnOption && (
      <div className="menu-area">
        {listFormPayment && listFormPayment.map(payment => (
          <span
            className="payment-option"
            key={payment.id}
            id={payment.id}
            onClick={clickFormPayment}
            tabIndex="0"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                clickFormPayment(e);
              }
            }}
          >
            {payment.name}
          </span>
        ))
        }
      </div>
    )}
    <div>
      {selectedPaymentMethodName || 'FORMA DE PAGAMENTO'}
    </div>
    {children}
  </SelectWrapper>
));

SelectPaymentType.propTypes = {
  handleClickToogleMenu: PropTypes.func.isRequired,
  isToggleOnOption: PropTypes.bool,
};

SelectPaymentType.defaultProps = {
  isToggleOnOption: undefined,
};
