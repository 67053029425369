import React, { useEffect, useRef, useState } from "react";

export const KeyboardNavigationProvider = ({ children, ...props }) => {
  const [elements, setElements] = useState([]);
  const elementsRef = useRef(null);

  let buttonSubmit = null;

  elements.forEach((element) => {
    if (element.type === "submit") buttonSubmit = element;
  });

  useEffect(() => {
    if (elementsRef.current) {
      setElements(
        elementsRef.current.querySelectorAll(
          "input:read-write:enabled, select, button"
        )
      );
    }
  }, []);

  const previous = (index) => {
    const previousItem = index - 1;

    if (previousItem === -1) {
      elements[elements.length - 1].focus();
      return;
    }
    elements[previousItem].focus();
  };

  const next = (index) => {
    const nextItem = index + 1;

    if (nextItem === elements.length) {
      elements[0].focus();
      return;
    }
    elements[nextItem].focus();
  };

  const selectSubmitButton = (key, event) => {
    const { dataset } = event.target;
    return (
      dataset.hasOwnProperty("selectSubmitButton") &&
      dataset.selectSubmitButton === key
    );
  };

  const submitWithEnter = (event) => {
    const { dataset } = event.target;
    return dataset.hasOwnProperty("submitWithEnter") && dataset.submitWithEnter;
  };

  const handleKeyDown = (e, index) => {
    const { key } = e;
    const { tagName } = e.target;

    if (key === "Tab") e.preventDefault();
    if (key === "Enter" && tagName === "INPUT" && !submitWithEnter(e))
      e.preventDefault();

    if (
      (elements[index].tagName === "SELECT" && key === "ArrowLeft") ||
      (elements[index].tagName === "SELECT" && key === "ArrowRight")
    )
      e.preventDefault();

    if (selectSubmitButton(key, e)) {
      buttonSubmit.focus();
      return;
    }

    if (key === "ArrowLeft") {
      previous(index);
    }
    if (key === "ArrowRight" || key === "Tab") {
      next(index);
    }
  };

  elements.forEach((item, i) => {
    item.addEventListener("keydown", (e) => handleKeyDown(e, i));
  });

  useEffect(() => {
    return () => {
      elements.forEach((item, i) => {
        item.removeEventListener("keydown", (e) => handleKeyDown(e, i));
      });
    };
  }, []);

  return (
    <div ref={elementsRef} {...props}>
      {children}
    </div>
  );
};
