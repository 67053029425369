import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const Container = styled.div`
  position: fixed;
  z-index: 21;
  height: 100%;
  width: ${theme.metrics.sidebarWidth}px;
  background: ${theme.palette.primary};
  text-align: center;
  padding-top: 10px;
  display:flex;
  flex-direction: column;

  > div {
    flex: 0 0 30px;
  }
`;
