import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../modules';
import ListParcelCard from '../list-parcel-card';

const PaymentCrediCard = (props) => {
  const {
    items,
    onHandleCredicard,
    handleClose,
  } = props;

  return (
    <Card
      title="CRÉDITO"
      subTitle="Escolha as parcelas"
      handleClose={handleClose}
      urlIcone="./assets/images/payment-types/creditcard.svg"
      closeEnable
    >
      <ListParcelCard
        items={items}
        handle={onHandleCredicard}
      />
    </Card>
  );
};

PaymentCrediCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  onHandleCredicard: PropTypes.func,
  handleClose: PropTypes.func,
};

PaymentCrediCard.defaultProps = {
  items: [],
  onHandleCredicard: null,
  handleClose: null,
};

export default PaymentCrediCard;
