import styled from 'styled-components';

import theme from '../../../../themes/theme2';

const LogoWrapper = styled.div`
  font-size: 18px;
  text-align: center;

  padding: 0 0 0 10px;
  background-color: white;
  color: ${theme.palette.brandLinx};
  display: flex;
  align-items: center;
  width: 145px;

  span {
    color: ${theme.palette.brandFarma};
    text-transform: uppercase;
    display: inline-block;
  }
`;

export default LogoWrapper;
