import React, { useState } from 'react';
import './increaseDecreaseInput.scss';

function IncreaseDecreaseInput(props) {

    const handleIncrement = () => {
        props.handleChangeValue(props.quantity + 1);

    };

    const handleDecrement = () => {
        if (props.quantity > 0) {
            props.handleChangeValue(props.quantity - 1);
        }
    };

    return (
        <div id="increaseDecreaseInput" className="custom-input">
            <button className="decrement-button" onClick={handleDecrement}>
                <div className='div-text'>-</div>
            </button>
            <input className="input" value={props.quantity} readOnly />
            <button className="increment-button" onClick={handleIncrement}>
                <div className='div-text'>+</div>
            </button>
        </div>
    );
}

export default IncreaseDecreaseInput;
