import * as types from './actionTypes';

export * from './views';

export const showCard = (
  card,
  payload,
  hideCoupon,
  showShortCutMenu = false,
  hideFooter = false
) => {
  return {
    type: types.SHOW,
    payload,
    card,
    hideCoupon: !!hideCoupon,
    showShortCutMenu: false,
    hideFooter: hideFooter,
  };
};

export const showWithOthers = (card, payload, hideCoupon) => (dispatch) => {
  dispatch({
    type: types.SHOW_WITH_OTHERS,
    payload,
    card,
    hideCoupon
  });
};

export const showWithSearchBar = (card, payload) => (dispatch) => {
  dispatch({
    type: types.SHOW_WITH_SEARCH_BAR,
    payload,
    card,
  });
};

export const closeAllCards = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_ALL,
  });
};

export const closeCard = (card) => (dispatch) => {
  dispatch({
    type: types.CLOSE,
    card,
  });
};
export const closeCardWithoutHideOthers = (card, showShortCutMenu, hideCoupon) => (
  dispatch
) => {
  dispatch({
    type: types.CLOSE_WITHOUT_HIDE_OTHERS,
    card,
    showShortCutMenu,
    hideCoupon
  });
};
