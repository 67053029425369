import React from 'react';
import HeaderWrapper from './styles';
import Logo from './logo';
import PdvInformation from '../../../container/pdv-information';
import OperatorInformation from '../../../container/operator-information';
import DeliveryOption from '../../../container/delivery-option';
import StatusConnection from '../../../container/service-on-off';
import ClienteArea from './client-area';

const Header = ({ name, logo }) => (
  <HeaderWrapper id='main-header-container'>
    <Logo name={name} />
    <div className='center'>
      <DeliveryOption />
      <StatusConnection />
    </div>
    {/* <PdvInformation /> */}
    {/* <ClienteArea path={logo} /> */}
    <OperatorInformation />
  </HeaderWrapper>
);

export default Header;
