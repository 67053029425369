import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import PbmSaleService, {
  PBM_EPHARMA,
  PBM_FUNCIONAL_CARD,
  PBM_ORIZON,
} from '../../../services/pbmSaleService';

import TefPbmSaleService from '../../../services/tefPbmSaleService';
import * as cashDeskServices from '../../../services/cashDeskServices';
import FinancialDocumentService from '../../../services/financialDocumentService';
import LinxConectaService from '../../../services/linxConectaService';

import {
  OPERATION_LAST_PBM_SALE,
  OPERATION_CASH_OPEN,
  OPERATION_SANGRIA,
  OPERATION_CASH_SUPPLY,
  OPERATION_PAY_OFF,
  OPERATION_NAME_LINX_CONECTA,
  OPERATION_CASH_CLOSE,
  OPERATION_CASH_CLOSE_PARTIAL,
  OPERATION_MONITOR_NOTAS,
  OPERATION_NAME_DTEF_Pbm,
  OPERATION_NAME_EPHARMA_PBM,
  OPERATION_LIST_AUTHORIZATIONS,
  OPERATION_NAME_CONFIG_FUNCIONAL,
  OPERATION_NAME_FUNCIONAL_PBM,
  OPERATION_NAME_CONFIG_ORIZON,
  OPERATION_NAME_CONFIG_TERMINAL,
  OPERATION_NAME_CONFIG_TEF,
  OPERATION_NAME_CONFIG_PBM,
  OPERATION_NAME_ORIZON,
  OPERATION_OBJECTPRO,
} from '../../../container/menu/operations';
import { CardShortcut, Container, ContainersShortcuts } from './style';
import { showCard } from '../../../redux/actions/routerAction';

interface IShortcuts {
  title: string;
  img: string;
  cardName: string;
  payload: any;
  hideCoupon: boolean;
  operationName: string;
}

export function ShortCutMenu() {
  const [shortcuts, setShortCuts] = useState<IShortcuts[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setShortCuts([
      {
        title: 'Sangria',
        img: require('../../../assets/images/shortcuts/withdraw.svg'),
        cardName: 'showCashWithDraw',
        payload: null,
        hideCoupon: true,
        operationName: 'Sangria',
      },
      {
        title: 'Suprimentos',
        img: require('../../../assets/images/shortcuts/supply.svg'),
        cardName: 'showCashSupply',
        payload: null,
        hideCoupon: true,
        operationName: 'Suprimento',
      },
      {
        title: 'Recebimentos',
        img: require('../../../assets/images/shortcuts/receivement.svg'),
        cardName: '',
        payload: null,
        hideCoupon: true,
        operationName: 'Baixa de títulos',
      },
      {
        title: 'Conferência parcial',
        img: require('../../../assets/images/shortcuts/partial-conference.svg'),
        cardName: '',
        payload: null,
        hideCoupon: true,
        operationName: 'Conferência parcial',
      },
    ]);
  }, []);

  const handleClickShortcuts = (shortcut: IShortcuts) => {
    switch (shortcut.operationName) {
      case OPERATION_NAME_DTEF_Pbm:
        TefPbmSaleService.startSale();
        break;
      case OPERATION_LAST_PBM_SALE:
        TefPbmSaleService.reprintLastSalePbm();
        break;
      case OPERATION_NAME_EPHARMA_PBM:
        PbmSaleService.getInitializationData(PBM_EPHARMA);
        break;
      case OPERATION_NAME_FUNCIONAL_PBM:
        PbmSaleService.getInitializationData(PBM_FUNCIONAL_CARD);
        break;
      case OPERATION_NAME_ORIZON:
        PbmSaleService.getInitializationData(PBM_ORIZON);
        break;
      case OPERATION_LIST_AUTHORIZATIONS:
        PbmSaleService.openAuthorizationList();
        break;
      case OPERATION_CASH_CLOSE:
        cashDeskServices.cashCloseStart(false);
        break;
      case OPERATION_CASH_CLOSE_PARTIAL:
        cashDeskServices.cashCloseStart(true);
        break;
      case OPERATION_PAY_OFF:
        FinancialDocumentService.start();
        break;
      case OPERATION_SANGRIA:
        cashDeskServices.cashWithdrawStart();
        break;
      case OPERATION_CASH_OPEN:
      case OPERATION_CASH_SUPPLY:
        cashDeskServices.cashSupplyStart();
        break;
      case OPERATION_MONITOR_NOTAS:
      case OPERATION_NAME_CONFIG_PBM:
      case OPERATION_NAME_CONFIG_TERMINAL:
      case OPERATION_NAME_CONFIG_TEF:
      case OPERATION_NAME_CONFIG_FUNCIONAL:
      case OPERATION_NAME_CONFIG_ORIZON:
      case OPERATION_OBJECTPRO:
        dispatchShowCard(shortcut);
        break;
      case OPERATION_NAME_LINX_CONECTA:
        LinxConectaService.listarEmpresas();
        break;
      default:
        break;
    }
  };

  const dispatchShowCard = (shortcut: IShortcuts) => {
    dispatch(
      showCard(shortcut.cardName, shortcut.payload, shortcut.hideCoupon)
    );
  };

  return (
    <Container>
      <ContainersShortcuts>
        {shortcuts.length &&
          shortcuts.map((shortcut, index) => {
            return (
              <CardShortcut
                key={index}
                onClick={() => handleClickShortcuts(shortcut)}
              >
                <img src={shortcut.img} />
                <span className='title'>{shortcut.title}</span>
              </CardShortcut>
            );
          })}
      </ContainersShortcuts>
    </Container>
  );
}
