export default function onKeyDownEnter($event: any, idName: any) {
  if ($event.key === 'Enter') {
    let inputs = Array.from(
      document.querySelectorAll(`[data-container="true"] input`)
    );

    if (!inputs.length) return;

    let index = inputs.indexOf(inputs.filter((x) => x.id === idName)[0]);

    if (index + 1 >= inputs.length) {
      let buttons = Array.from(
        document.querySelectorAll(`[data-container="true"] button`)
      );

      let btnToFocus = document.getElementById(
        `${buttons.filter((x) => x.id)[0].id}`
      );

      btnToFocus?.focus();

      return;
    } else if (index < 0) return;
    else {
      let inputToFocus = document.querySelector(
        `#${inputs[index + 1].id}`
      ) as any;

      inputToFocus.focus();
    }
  }
}
