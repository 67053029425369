import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { ModalWrapper } from './modal.style';
import { ModalBody } from './modal-body';
import { ModalFooter } from './modal-footer';

const optionsStyle = {
  content: {
    padding: '8px',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    display: 'block',
    padding: '1.2rem',
  },
};

export class Modal extends Component {
  static ModalBody = ModalBody;
  static ModalFooter = ModalFooter;

  constructor(props) {
    super(props);

    this.state = {
      open: props.isOpen
    }
  }

  onCloseModal = () => {
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    } else {
      this.setState({
        open: false
      });
    }
  }

  render() {
    const {
      title,
      children,
      closable
    } = this.props;

    return (
      <ReactModal
        ref={ref => this.optionsModalRef = ref}
        isOpen={this.state.open}
        onClose={this.onCloseModal}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        center
        style={optionsStyle}
        ariaHideApp={false}
      >
        <ModalWrapper>
          { closable && <a className="esc-option" href="#/" onClick={this.onCloseModal}> VOLTAR (ESC) </a> }
          <span className="modal-title">{title}</span>
          { children }
        </ModalWrapper>
      </ReactModal>
    );
  }
}