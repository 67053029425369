const countSymbol = Symbol();
const processFuncSymbol = Symbol();
const createOverlayFuncSymbol = Symbol();

class OverlayService {
    [countSymbol] = 0;

    showOverlay = () => {
        this[processFuncSymbol](1);
    }
    hideOverlay = () => {
        this[processFuncSymbol](-1);
    }
    focusOverlay = () => {
        document.querySelector('.overlay').focus();
    }
    overlayOpened = () => {
        return this[countSymbol];
    }

    [processFuncSymbol] = (add) => {
        const newCount = this[countSymbol] + add;

        this[countSymbol] = newCount < 0 ? 0 : newCount;
        
        let overlay = document.querySelector('.overlay') || this[createOverlayFuncSymbol]();

        const action = this[countSymbol] === 0 ? 'remove' : 'add';

        overlay.classList[action]('show');
    }

    [createOverlayFuncSymbol] = () => {
        const overlay = document.createElement('div');
        const attr = document.createAttribute("tabindex");
        attr.value = 0;
        overlay.attributes.setNamedItem(attr);
        overlay.classList.add('overlay');
        document.querySelector('body').appendChild(overlay);

        return overlay;
    }
}

export default new OverlayService();
