import * as signalR from '@aspnet/signalr';

export default class WSConnection {
  _connection = null;
  stoping = false;
  connectionString = null;
  reconnectOnFailure = false;

  constructor(connectionString, reconnectOnFailure = false) {
    this.connectionString = connectionString;
    this.reconnectOnFailure = reconnectOnFailure;
  }

  async Connect() {
    try {
      if (this.stoping) {
        this._connection.stop();
        return;
      }

      await this._connection.start();
    } catch (err) {
      console.info('Retry connect in 5 sec.');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      console.info('Retring connect.');
      await this.Connect();
    }
  }

  Stop() {
    this.stoping = true;
    this._connection.stop();
  }

  async OnClose() {
    console.log(`OnClose: ${this.connectionString}`);

    if (this.reconnectOnFailure && !this.stoping) {
      console.log(`Calling connect again...`);
      await this.Connect();
    }
  }

  IsConnected() {
    return (
      this._connection &&
      this._connection.state === signalR.HubConnectionState.Connected
    );
  }

  IsDisconnected() {
    return (
      this._connection &&
      this._connection.state === signalR.HubConnectionState.Disconnected
    );
  }

  BuildConection() {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.connectionString)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    return connection;
  }

  async Start(Handle) {
    this._connection = this.BuildConection();
    this._connection.onclose(this.OnClose.bind(this));
    this._connection.on('Handle', Handle);
    await this.Connect();
    return this._connection;
  }
}
