/* eslint-disable no-useless-constructor */

import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { Table, Form, Col, Button } from 'react-bootstrap';

import { showToast } from '../../utils/util';
import { Card } from '../modules';
import './style.scss';
import Row from './row';
import { Options } from './styles';

class CardDelivery extends Component {
  config;

  constructor(props) {
    super(props);
  }

  copyToClipBoard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    showToast({
      type: 0,
      title: 'Link de pagamento',
      html: 'Link Copiado',
      hideAfterMilliseconds: 1000,
    });
  }

  sendWhatsApp(number, text) {
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=${text}`);
  }

  sendEmail(email, text) {
    window.location.href = `mailto:${email}?subject=Link%20de%20Pagamento&body=${text}`;
  }

  getHeaders = () => {
    return (
      <tr>
        <th />
        <th>Data/Hora</th>
        <th>Situação</th>
        <th>Cliente</th>
        <th>Endereço</th>
        <th>Vendedor</th>
        <th>Ações</th>
      </tr>
    );
  };

  customCellrender = (item) => (
    <i className={`material-icons ${item.selected ? 'active' : ''}`}>
      {item.selected ? 'check_box' : 'check_box_outline_blank'}
    </i>
  );

  getName = (name) => {
    switch (name) {
      case 'None':
        return 'Todos em andamento';
      case 'AwaitingForPayment':
        return 'Aguardando pagamento';
      case 'AwaitingForPaymentLink':
        return 'Aguardando link de pagamento';
      case 'AwaitingForNF':
        return 'Aguardando emissão da nota';
      case 'AwaitingForDelivery':
        return 'Aguardando entrega';
      case 'OutForDelivery':
        return 'Saiu para entrega';
      case 'Delivered':
        return 'Entregue';
      case 'Canceled':
        return 'Cancelada';
      case 'PaymentLinkRejected':
        return 'Pagamento rejeitado';
      case 'PaymentLinkUnderInvestigation':
        return 'Pagamento em investigação';
      case 'PaymentCancelled':
        return 'Pagamento cancelado';
      case 'AwaitingForSendToPosConnect':
        return 'Aguardando envio para PosConnect';
      case 1:
        return 'Aguardando pagamento';
      case 2:
        return 'Aguardando emissão da nota';
      case 3:
        return 'Aguardando entrega';
      case 4:
        return 'Saiu para entrega';
      case 5:
        return 'Entregue';
      case 6:
        return 'Cancelada';
      case 7:
        return 'Aguardando link de pagamento';
      case 8:
        return 'Pagamento rejeitado';
      case 9:
        return 'Pagamento em investigação';
      case 10:
        return 'Pagamento cancelado';
      case 11:
        return 'Aguardando envio para PosConnect';
      default:
        return '';
    }
  };

  mapStatus = (status) => {
    return (
      <Fragment>
        {status.map((s) => (
          <option key={s.value} value={s.value}>
            {this.getName(s.name)}
          </option>
        ))}
      </Fragment>
    );
  };

  handleKeyDown(e) {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        this.props.handleFind();
        break;
      default:
        break;
    }
  }

  render() {
    const {
      handleClose,
      handleFind,
      handleChange,
      data,
      status,
      handleOutForDelivery,
      handleGetStatusPaymentLink,
      handleDelivered,
      handleAwaitingForDelivery,
      handlePrint,
      buttonDisabled,
      handleChangeSelected,
      handleRecovery,
      handleRefreshPaymentLink,
      handleSendToPosConnect,
      handleCancel,
      posType,
      isOnline,
    } = this.props;

    data.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    return (
      <Card
        ref={(ref) => (this.modelRef = ref)}
        autoScroll={false}
        id='card-delivery-control'
        title='Controle de delivery'
        closeEnable
        handleClose={handleClose}
        style={this.optionsStyle}
      >
        <Form>
          <Form.Row>
            <Form.Group className='col-xs-12 col-lg-8' controlId='query'>
              <Form.Label>Busca</Form.Label>
              <Form.Control
                label='Busca'
                required
                placeholder='Busca por cliente, valor ou bairro'
                onChange={handleChange}
                onKeyDown={(e) => this.handleKeyDown(e)}
                autoFocus
              />
            </Form.Group>
            <Form.Group as={Col} controlId='deliveryStatus'>
              <Form.Label>Situação</Form.Label>
              <Form.Control
                required
                as='select'
                placeholder='Situação'
                defaultValue={0}
                onChange={handleChange}
              >
                {this.mapStatus(status)}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Button onClick={handleFind}>
                <i className='material-icons'>search</i>
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        {data && data.length > 0 && (
          <>
            <Options>
              <Form.Group>
                <Button
                  className='buttonCheck'
                  type='button'
                  onClick={() => this.props.handleCheck()}
                >
                  <i
                    className={`material-icons ${this.props.check ? 'active' : 'checkbox'
                      }`}
                  >
                    {this.props.check ? 'check_box' : 'check_box_outline_blank'}
                  </i>
                </Button>
                <Button
                  className='buttonHeader'
                  type='buttonHeader'
                  onClick={handleAwaitingForDelivery}
                  disabled={buttonDisabled}
                >
                  <img
                    src='./assets/icons/relogio.svg'
                    alt='Aguardando entrega'
                    title='Aguardando entrega'
                  />
                  <span>Aguardando entrega</span>
                </Button>
                <Button
                  className='buttonHeader'
                  type='buttonHeader'
                  onClick={handleOutForDelivery}
                  disabled={buttonDisabled}
                >
                  <img
                    src='./assets/icons/capacete.svg'
                    alt='Saiu para entrega'
                    title='Saiu para entrega'
                  />
                  <span>Saiu para entrega</span>
                </Button>
                <Button
                  className='buttonHeader'
                  type='buttonHeader'
                  onClick={handleDelivered}
                  disabled={buttonDisabled}
                >
                  <img
                    src='./assets/icons/cubo.svg'
                    alt='Entregue'
                    title='Entregue'
                  />
                  <span>Entregue</span>
                </Button>

                <Button
                  className='buttonHeader'
                  type='buttonHeader'
                  onClick={handlePrint}
                  disabled={buttonDisabled}
                >
                  <img
                    src='./assets/icons/impressora.svg'
                    alt='Controle de entrega'
                    title='Controle de entrega'
                  />
                  <span>Controle de entrega</span>
                </Button>
                {isOnline && (
                  <Button
                    className='buttonHeader'
                    type='buttonHeader'
                    onClick={handleGetStatusPaymentLink}
                    disabled={buttonDisabled}
                  >
                    <img
                      src='./assets/icons/cartao.svg'
                      alt='Atualizar pagamento'
                      title='Atualizar pagamento'
                    />
                    <span>Atualizar pagamento</span>
                  </Button>
                )}
              </Form.Group>
            </Options>
            <Table className='table-sm' striped responsive hover>
              <thead>{this.getHeaders()}</thead>
              <tbody>
                {data.map((item) => (
                  <Row
                    key={item.id}
                    item={item}
                    handleChangeSelected={handleChangeSelected}
                    handleRecovery={handleRecovery}
                    sendWhatsApp={this.sendWhatsApp}
                    sendEmail={this.sendEmail}
                    copyToClipBoard={this.copyToClipBoard}
                    handleRefreshPaymentLink={handleRefreshPaymentLink}
                    handleSendToPosConnect={handleSendToPosConnect}
                    handleCancel={handleCancel}
                    getName={this.getName}
                    posType={posType}
                  />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Card>
    );
  }
}

CardDelivery.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CardDelivery;
