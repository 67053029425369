import styled from 'styled-components';
import theme from '../../../themes/default';

const PreOrderInputWrapper = styled.div`

    padding-top:30px;

    button {
        border: none;
        border-radius: 2px;
        padding: 0 16px;
        color: #fff;
        background-color: #26a69a;
        letter-spacing: .5px;
        cursor: pointer;
        height: 32.4px;
        min-width: 76px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .input__container {
        text-align: center;
        width: 100%;
        height: 50px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }

  .value-area{
      font-size: 20px;
      height: 46px;
      width: 75%;
      border: transparent;
      outline: none;
      color: ${theme.palette.grayscale.ultraDark};

    &:focus {
      outline: none;
    }
  }

  .close-modal{
    float:right;
    top:1px;
  }

`;

export default PreOrderInputWrapper;
