import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import {
  showCard,
  closeAllCards,
  DETAIL_ITEM,
  shouldShow,
  getPayload,
  showWithOthers,
} from '../../redux/actions/routerAction';
import { STOCKLIST } from '../../redux/actions/views';
import { getContext } from '../../shared/hoc/getContext';
import Detail from '../../shared/components/detail-item';
import SaleService from '../../services/saleService';
import { OrderService } from '../../services/orderService';
import AdministrativeService from '../../services/AdministrativeService';
import { MessageBox, RequireAuth } from '../../shared/components/modules';
import Configuration from '../../configuration';
import { payloadToBase64Zip, showToast } from '../../shared/utils/util';

export class DetailConteiner extends Component {
  constructor(props) {
    super(props);

    this.saleService = new SaleService();
    this.orderService = new OrderService();
    this.administrativeService = new AdministrativeService();

    this.props.MessageBus.Subscribe('pos.item.updateFailed', this.handleError);
    this.props.MessageBus.Subscribe('pos.item.deleteFailed', (ch, payload) =>
      this.context.showMessage(payload.message)
    );
    this.props.MessageBus.Subscribe(
      'pos.item.deleted',
      this.props.closeDetailsItem
    );
    this.props.MessageBus.Subscribe(
      'pos.open.item',
      this.openDetail.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.close.item',
      this.props.closeDetailsItem
    );
    this.props.MessageBus.Subscribe(
      'pos.item.requireAuth',
      this.handleRequireAuth
    );
    this.props.MessageBus.Subscribe(
      'pos.offercoverage.show',
      this.handleStartOfferCoverage
    );
    this.props.MessageBus.Subscribe(
      'pos.increaseprice.show',
      this.handleStartIncreasePrice
    );
    this.props.MessageBus.Subscribe('pos.user.info.success', this.validateUser);
    this.props.MessageBus.Subscribe(
      'pos.user.info.notfound',
      this.validateUser
    );
    this.props.MessageBus.Subscribe(
      'pos.offercoverage.authorizationerror',
      this.handleOfferCoverage
    );

    this.handleChangeQtt = this.handleChangeQtt.bind(this);
    this.handleChangeDiscount = this.handleChangeDiscount.bind(this);
    this.startDiscountValues = this.startDiscountValues.bind(this);
    this.getDiscountErrorMessage = this.getDiscountErrorMessage.bind(this);
    this.getSubTotalDiscountError = this.getSubTotalDiscountError.bind(this);
    this.handleChangeSubTotal = this.handleChangeSubTotal.bind(this);
    this.getOnlyNumbersOfCurrencyValue = this.getOnlyNumbersOfCurrencyValue.bind(
      this
    );
    this.calculateMaxAndMinValues = this.calculateMaxAndMinValues.bind(this);
    this.getValueAsNumber = this.getValueAsNumber.bind(this);
    this.getFixedValueWithComma = this.getFixedValueWithComma.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showAditionalInfo: true,
      showDiscount: false,
      showMessage: false,
      errorMessage: null,
      order: {
        ...this.props.order,
      },
      itemSelected: {
        ...this.props.itemSelected,
      },
      productHasDiscount: false,
      offerModalIsVisible: false,
      loggedUserHasPermission: false,
      userWithoutPermission: false,
      messageErrorOfferCoverage: '',
      inputError: {
        field: '',
        error: '',
        message: '',
      },
      subTotalOffer: 0,
      margemLucro: 0,
      discountPercentOffer: 0,
      increaseValueTotal: 0,
      showIncreaseValueValidationMessage: false,
      reasonDiscountOffer: '',
      codeUserOffer: '',
      nameUserOffer: '',
      passwordUserOffer: '',
    };

    this.maxDiscountPercentage = 97;
    this.minDiscountPercentage = 2.5;
    this.messageErrorMaxDiscountPercentage = `Desconto máximo permitido é ${this.maxDiscountPercentage}%`;
    this.messageErrorMinDiscountPercentage = `Desconto mínimo permitido é ${this.minDiscountPercentage
      .toString()
      .replace('.', ',')}%`;

    this.operatorRefId = null;
    this.password = null;

    this.productId = null;
    this.sequentialId = null;
    this.orderId = null;
    this.subscription = null;
  }

  handleRequireAuth = () => {
    this.setState({
      requireAuth: true,
    });
  };

  handleError = (ch, payload) => {
    this.context.showMessage(payload.reason);
    this.subscription && this.props.MessageBus.Unsubscribe(this.subscription);
    this.subscription = null;
  };

  openDetail(ch, { sequentialId }) {
    this.productId = null;
    this.sequentialId = null;
    this.orderId = null;
    this.operatorRefId = null;
    this.password = null;
    const selectedItem = this.props.order.items.filter((item) => {
      return item.sequentialId === sequentialId;
    })[0];
    this.props.showDetailsItem(selectedItem);
    this.setState({ offerModalIsVisible: false });
  }

  componentDidUpdate() {
    if (
      this.props.showDetailItemComponent &&
      this.props.itemSelected.productId &&
      (this.productId !== this.props.itemSelected.productId ||
        this.sequentialId !== this.props.itemSelected.sequentialId ||
        this.orderId !== this.props.order.id)
    ) {
      this.productId = this.props.itemSelected.productId;
      this.sequentialId = this.props.itemSelected.sequentialId;
      this.orderId = this.props.order.id;
      if (this.props.itemSelected.applicableDiscount) {
        this.startDiscountValues(true);
      } else {
        this.startDiscountValues(false);
      }
    }
  }

  startDiscountValues(hasDiscount) {
    const { itemSelected } = this.props;
    const { appliedDiscount } = itemSelected;

    let discountValue = null;
    let subTotalDiscount = itemSelected.subTotalDiscount;

    if (hasDiscount) {
      itemSelected.applicableDiscount = this.calculateMaxAndMinValues(
        itemSelected
      );
    }

    if (hasDiscount && appliedDiscount !== null) {
      discountValue = appliedDiscount.percentageDiscountValue;

      if (appliedDiscount.type === 0) {
        discountValue = (
          (appliedDiscount.totalDiscountValue /
            (itemSelected.applicableDiscount.productPrice *
              itemSelected.quantity)) *
          100
        ).toFixed(2);
      } else {
        subTotalDiscount =
          (itemSelected.productPrice * itemSelected.quantity || 1) -
          itemSelected.productPrice *
          itemSelected.quantity *
          (discountValue / 100);
        subTotalDiscount = parseFloat(subTotalDiscount.toFixed(2));
      }
    }

    this.setState({
      productHasDiscount: hasDiscount,
      itemSelected: {
        ...this.props.itemSelected,
        subTotalDiscount,
        appliedDiscount: hasDiscount && {
          ...appliedDiscount,
          value:
            discountValue !== null
              ? discountValue.toString().replace('.', ',')
              : '0,00',
        },
        applicableDiscount: hasDiscount && {
          ...itemSelected.applicableDiscount,
        },
      },
      order: {
        ...this.props.order,
      },
      errorMessage: this.props.errorMessage,
    });
  }

  handleChangeQtt = (e) => {
    let qtd = e.target.value;
    if (!qtd || /^[\d]+$/.test(qtd)) {
      if (!isNaN(parseInt(qtd))) {
        const value = parseInt(qtd);
        qtd = value > 2147483647 ? 2147483647 : value;
      }
      this.setState((old) => {
        const newState = { ...old };

        const trueQtd = parseFloat(qtd) || 1;

        const { itemSelected } = newState;

        itemSelected.quantity = parseFloat(qtd) || '';
        itemSelected.subTotal = itemSelected.productPrice * trueQtd;

        if (this.state.productHasDiscount) {
          itemSelected.appliedDiscount.type = 1;
          itemSelected.applicableDiscount = this.calculateMaxAndMinValues(
            itemSelected
          );

          let subTotalDiscount = itemSelected.subTotalDiscount;
          const discountValue =
            parseFloat(
              itemSelected.appliedDiscount.value
                .replace('.', '')
                .replace(',', '.')
            ) || 0;
          subTotalDiscount =
            itemSelected.productPrice * trueQtd -
            itemSelected.productPrice * trueQtd * (discountValue / 100);

          if (subTotalDiscount < 0) {
            subTotalDiscount = 0;
          }

          itemSelected.subTotalDiscount = subTotalDiscount
            .toFixed(2)
            .replace('.', ',');

          const trueAppliedDiscountValue = itemSelected.appliedDiscount.value;
          const errorMessage = this.getDiscountErrorMessage(
            parseFloat(trueAppliedDiscountValue.replace(',', '.')),
            itemSelected.applicableDiscount
          );

          const errorAndValue = this.getSubTotalDiscountError(
            parseFloat(itemSelected.subTotalDiscount.replace(',', '.')),
            itemSelected.applicableDiscount
          );

          const subTotalDiscountError = errorAndValue.error;
          itemSelected.subTotalDiscount = errorAndValue.value;

          newState.errorMessage = errorMessage;
          newState.subTotalDiscountError = subTotalDiscountError;
        } else {
          itemSelected.subTotalDiscount = itemSelected.productPrice * trueQtd;
        }
        return newState;
      });
    }
  };

  calculateMaxAndMinValues(itemSelected) {
    const { applicableDiscount } = itemSelected;
    const totalProductValue = itemSelected.quantity * itemSelected.productPrice;
    applicableDiscount.maximumDiscountValue =
      totalProductValue * (applicableDiscount.maximumPercentage / 100);
    applicableDiscount.minimumDiscountValue =
      totalProductValue * (applicableDiscount.minimumPercentage / 100);
    applicableDiscount.minimumApplicableValue = (
      totalProductValue - applicableDiscount.maximumDiscountValue
    ).toFixed(2);
    applicableDiscount.maximumApplicableValue = (
      totalProductValue - applicableDiscount.minimumDiscountValue
    ).toFixed(2);
    applicableDiscount.minimumDiscountValue = applicableDiscount.minimumDiscountValue.toFixed(
      2
    );
    applicableDiscount.maximumDiscountValue = applicableDiscount.maximumDiscountValue.toFixed(
      2
    );
    return applicableDiscount;
  }

  getValueAsNumber(value) {
    return parseFloat(value.toString().replace(',', '.'));
  }

  getFixedValueWithComma(value) {
    return parseFloat(value.toString().replace(',', '.'))
      .toFixed(2)
      .replace('.', ',');
  }

  sendToUpdate = (e) => {
    e && e.preventDefault();

    if (
      !this.state.itemSelected.quantity ||
      this.state.itemSelected.quantity === '0'
    ) {
      this.context.showMessage('Quantidade inválida!');
    } else if (this.state.errorMessage) {
      this.context.showMessage('O desconto informado é inválido!');
    } else {
      let appliedDiscount;
      const { itemSelected } = this.state;
      if (this.state.productHasDiscount) {
        const subTotalDiscount = parseFloat(
          itemSelected.subTotalDiscount.toString().replace(',', '.')
        );
        const totalProductPrice =
          itemSelected.productPrice * itemSelected.quantity;
        const totalDiscountValue = totalProductPrice - subTotalDiscount;
        appliedDiscount = {
          type: itemSelected.appliedDiscount.type,
          totalDiscountValue: parseFloat(totalDiscountValue.toFixed(2)),
          percentageDiscountValue: parseFloat(
            itemSelected.appliedDiscount.value.toString().replace(',', '.')
          ),
          isNegociatedBySalesperson:
            itemSelected.appliedDiscount.isNegociatedBySalesperson,
          isOfferCoverage: itemSelected.appliedDiscount.isOfferCoverage,
          reason: itemSelected.appliedDiscount.reason,
          approval: itemSelected.appliedDiscount.approval,
        };
      }

      const payload = {
        operatorRefId: this.operatorRefId,
        password: this.password,
        barcode: itemSelected.productBarcode,
        quantity: itemSelected.quantity,
        appliedDiscount: appliedDiscount,
        sequentialId: itemSelected.sequentialId,
        isFromPbm: itemSelected.isFromPbm,
      };

      this.subscription = this.props.MessageBus.Subscribe(
        'pos.order.updated',
        this.handleOrderUpdated
      );

      this.saleService
        .updateItem(payload)
        .catch((error) => this.context.showError(error));

      this.referenceId = null;
      this.password = null;
    }
  };

  handleOrderUpdated = () => {
    this.subscription && this.props.MessageBus.Unsubscribe(this.subscription);
    this.subscription = null;
    this.state.requireAuth && this.setState({ requireAuth: false });
    this.props.closeDetailsItem();

    this.productId = null;
    this.sequentialId = null;
    this.orderId = null;
    this.operatorRefId = null;
    this.password = null;
  };

  confirmDelete(confirm) {
    if (confirm) {
      this.subscription = this.props.MessageBus.Subscribe(
        'pos.order.updated',
        this.handleOrderUpdated
      );
      this.saleService
        .deleteItem(this.productId, this.state.itemSelected.sequentialId)
        .catch((error) => this.context.showError(error));
    }

    this.showConfirmDialog(false);
  }

  showConfirmDialog(isVisible) {
    this.setState({
      ...this.state,
      showMessage: isVisible,
    });
  }

  sendToDelete = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.showConfirmDialog(this.state.itemSelected.productId);
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && !this.state.errorMessage) {
      this.sendToUpdate(e);
    }
  };

  handleClose() {
    this.productId = null;
    this.sequentialId = null;
    this.orderId = null;
    this.operatorRefId = null;
    this.password = null;
    this.saleService.cancelUpdateItem();
  }

  handleChangeSubTotal(value) {
    const { itemSelected } = this.state;

    const trueQtd = itemSelected.quantity || 1;
    value = value.substring(2);

    const trueValue = parseFloat(value.replace('.', '').replace(',', '.')) || 0;
    let discountValue = itemSelected.productPrice * trueQtd - trueValue;
    discountValue =
      (discountValue / (itemSelected.productPrice * trueQtd)) * 100;
    discountValue = parseFloat(discountValue.toString());

    if (discountValue < 0) {
      discountValue = 0;
    }

    discountValue = discountValue.toFixed(2).replace('.', ',');

    const errorAndValue = this.getSubTotalDiscountError(
      trueValue,
      itemSelected.applicableDiscount
    );
    let errorMessage = this.getDiscountErrorMessage(
      parseFloat(discountValue.replace(',', '.')),
      itemSelected.applicableDiscount
    );

    let discountType = 0;
    if (!errorAndValue.error && errorMessage) {
      if (errorMessage === 'min') {
        discountValue = itemSelected.applicableDiscount.minimumPercentage;
      } else {
        discountValue = itemSelected.applicableDiscount.maximumPercentage;
      }
      errorMessage = null;
      discountType = 1;
    }

    const subTotalDiscountError = errorAndValue.error;

    this.setState((old) => {
      return {
        subTotalDiscountError: subTotalDiscountError,
        itemSelected: {
          ...old.itemSelected,
          subTotalDiscount: errorAndValue.value,
          appliedDiscount: {
            ...old.itemSelected.appliedDiscount,
            isNegociatedBySalesperson: true,
            value: discountValue,
            type: discountType,
          },
        },
        errorMessage,
      };
    });
  }

  getSubTotalDiscountError(value, applicableDiscount) {
    const max =
      applicableDiscount.discountType === -1
        ? parseFloat(applicableDiscount.suggestedValue)
        : parseFloat(applicableDiscount.maximumDiscountValue);
    const min = parseFloat(applicableDiscount.minimumApplicableValue);

    let subTotalDiscountError = null;
    if (value > max) subTotalDiscountError = 'max';
    if (value < min) subTotalDiscountError = 'min';

    return {
      error: subTotalDiscountError,
      value,
    };
  }

  getDiscountErrorMessage(discountValue, applicableDiscount) {
    const percentageDiscountValue =
      (applicableDiscount.suggestedValue / applicableDiscount.productPrice) *
      100;
    const max =
      applicableDiscount.discountType === -1
        ? Math.ceil(percentageDiscountValue * 100) / 100
        : parseFloat(applicableDiscount.maximumPercentage);
    const min = parseFloat(applicableDiscount.minimumPercentage);

    if (discountValue > max) return 'max';
    if (discountValue < min) return 'min';

    return null;
  }

  handleChangeDiscount(value) {
    value = this.getOnlyNumbersOfCurrencyValue(value);
    const discountValue =
      parseFloat(value.replace('.', '').replace(',', '.')) || 0;
    const { applicableDiscount } = this.state.itemSelected;

    let subTotalDiscount =
      (this.state.itemSelected.productPrice *
        this.state.itemSelected.quantity || 1) -
      this.state.itemSelected.productPrice *
      this.state.itemSelected.quantity *
      (discountValue / 100);

    if (subTotalDiscount < 0) {
      subTotalDiscount = 0;
    } else {
      // Arrendondando o subTotal para baixo, para que o desconto seja arredondado para cima
      subTotalDiscount = (Math.floor(subTotalDiscount * 1000) / 1000)
        .toFixed(2)
        .replace('.', ',');
    }

    const errorMessage = this.getDiscountErrorMessage(
      discountValue,
      applicableDiscount
    );

    const trueSubTotalValue = parseFloat(subTotalDiscount.replace(',', '.'));
    const errorValue = this.getSubTotalDiscountError(
      trueSubTotalValue,
      applicableDiscount
    );
    const subTotalDiscountError = errorValue.error;

    subTotalDiscount = errorValue.value;

    this.setState((old) => ({
      order: {
        ...old.order,
      },
      itemSelected: {
        ...old.itemSelected,
        subTotalDiscount: subTotalDiscount,
        appliedDiscount: {
          ...old.itemSelected.appliedDiscount,
          isNegociatedBySalesperson: true,
          value: value,
          type: 1,
        },
      },
      subTotalDiscountError: subTotalDiscountError,
      errorMessage: errorMessage,
    }));
  }

  getOnlyNumbersOfCurrencyValue(value) {
    value = value.toString();
    if (value && value.indexOf(',') === -1) {
      value = value.concat(',00');
    }
    if (value.endsWith('%')) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleConfirmAuth = ({ operatorRefId, password }) => {
    this.operatorRefId = operatorRefId;
    this.password = password;
    this.sendToUpdate();
  };

  handleModalClose = () => {
    this.operatorRefId = null;
    this.password = null;

    this.setState({
      requireAuth: false,
    });
  };

  clearIputErrorMessage = () => {
    this.setState({
      inputError: {
        error: '',
        message: '',
      },
    });
  };

  handleStartOfferCoverage = (ch, payload) => {
    this.setState({
      offerModalIsVisible: !this.state.offerModalIsVisible,
      loggedUserHasPermission: payload.hasPermissionOnOfferCoverage,
      showAditionalInfo: payload.hasPermissionOnFactoryPrice,
    });
  };

  handleStartIncreasePrice = (ch, payload) => {
    if (!payload) {
      showToast({
        type: 2,
        title: 'ACRÉSCIMO NO PRODUTO',
        html: 'Usuário não autorizado!',
        hideAfterMilliseconds: 10000,
      });
      return;
    }
    this.setState({
      increasePriceModalIsVisible: true
    });
  };

  handleOpenModalOfferCoverage = () => {
    this.props.MessageBus.Subscribe(
      'pos.offercoverage.updateitem',
      this.handleOfferCoverage
    );

    this.administrativeService
      .startOfferCoverage()
      .catch((error) => console.log(error));
  };

  handleOpenModalIncreasePrice = () => {
    this.administrativeService
      .startIncreasePrice()
      .catch((error) => console.log(error));
  };

  handleCloseModalIncreasePrice = () => {
    this.setState({
      increasePriceModalIsVisible: false,
      showIncreaseValueValidationMessage: false,
      increaseValueTotal: 0
    });
  }

  handleCloseModalOfferCoverage = () => {
    this.props.MessageBus.Unsubscribe('pos.offercoverage.updateitem');

    this.setState({
      offerModalIsVisible: !this.state.offerModalIsVisible,
      userWithoutPermission: false,
      inputError: {
        field: '',
        error: '',
        message: '',
      },
      subTotalOffer: 0,
      margemLucro: 0,
      discountPercentOffer: 0,
      reasonDiscountOffer: '',
      codeUserOffer: '',
      nameUserOffer: '',
      passwordUserOffer: ''
    });
  };

  handleChangeDiscountPercentOffer = (e) => {
    let trueValue = this.getValueAsNumber(e);

    trueValue <= this.maxDiscountPercentage &&
      this.setState({ discountPercentOffer: trueValue });

    if (trueValue >= this.maxDiscountPercentage) {
      this.setState({
        discountPercentOffer: this.maxDiscountPercentage,
        inputError: {
          error: 'max',
          message: this.messageErrorMaxDiscountPercentage,
        },
      });
    }

    if (trueValue < this.minDiscountPercentage) {
      this.setState({
        inputError: {
          error: 'min',
          message: this.messageErrorMinDiscountPercentage,
        },
      });
    }

    if (
      trueValue >= this.minDiscountPercentage &&
      trueValue <= this.maxDiscountPercentage
    ) {
      this.clearIputErrorMessage();
    }

    let discountPercent =
      trueValue <= this.maxDiscountPercentage
        ? trueValue
        : this.maxDiscountPercentage;
    let currencyDiscount =
      ((100 - discountPercent) / 100) * this.state.itemSelected.subTotal;

    let totalMargemLucro = ((currencyDiscount - this.state.itemSelected.productFactoryPrice) / currencyDiscount) * 100;

    this.setState({
      subTotalOffer: currencyDiscount,
      margemLucro: totalMargemLucro
    });
  };

  handleChangeIncreaseValue = (e) => {
    let value = e.replace('R$', '');
    let trueValue = parseFloat(value.replace('.', '').replace(',', '.')) || 0;
    this.setState({ increaseValueTotal: trueValue });
  };

  handleChangeSubTotalOffer = (e) => {
    let value = e.replace('R$', '');
    let trueValue = parseFloat(value.replace('.', '').replace(',', '.')) || 0;

    let discountPercent = 0;

    if (trueValue >= this.state.itemSelected.subTotal) {
      this.setState({ subTotalOffer: this.state.itemSelected.subTotal });
    } else {
      let totalMargemLucro = ((trueValue - this.state.itemSelected.productFactoryPrice) / trueValue) * 100;
      this.setState({
        subTotalOffer: trueValue,
        margemLucro: totalMargemLucro
      });
      discountPercent =
        ((this.state.itemSelected.subTotal - trueValue) /
          this.state.itemSelected.subTotal) *
        100;
    }

    if (discountPercent > this.maxDiscountPercentage) {
      this.setState({
        discountPercentOffer: this.maxDiscountPercentage,
        inputError: {
          error: 'max',
          message: this.messageErrorMaxDiscountPercentage,
        },
      });
    } else {
      this.setState({ discountPercentOffer: discountPercent });
    }

    if (discountPercent < this.minDiscountPercentage) {
      this.setState({
        inputError: {
          error: 'min',
          message: this.messageErrorMinDiscountPercentage,
        },
      });
    }

    if (
      discountPercent >= this.minDiscountPercentage &&
      discountPercent < this.maxDiscountPercentage
    ) {
      this.clearIputErrorMessage();
    }
  };

  verifyDiscountPercentageLimits = (e) => {
    let discountPercent = 0;
    if (e.target.id === 'inputDiscountModal') {
      discountPercent = this.getValueAsNumber(e.target.value);
    }
    if (e.target.id === 'inputTotal') {
      let value = e.target.value.replace('R$', '');
      let trueValue = parseFloat(value.replace('.', '').replace(',', '.')) || 0;

      discountPercent =
        (1 - trueValue / this.state.itemSelected.subTotal) * 100;
    }

    if (discountPercent > this.maxDiscountPercentage) {
      this.setState({
        discountPercentOffer: this.maxDiscountPercentage,
        subTotalOffer:
          this.state.itemSelected.subTotal *
          ((100 - this.maxDiscountPercentage) / 100),
        inputError: {
          field: e.target.id,
          error: 'max',
          message: this.messageErrorMaxDiscountPercentage,
        },
      });
    }

    if (discountPercent >= this.maxDiscountPercentage) {
      setTimeout(() => {
        this.clearIputErrorMessage();
      }, 2000);
    }
  };

  handleChangeOfferModal = (e) => {
    const { name, value } = e.target;

    if (name === 'reasonDiscountOffer' && value.length <= 100) {
      this.setState({ [name]: value });
    }

    if (name !== 'reasonDiscountOffer') {
      this.setState({ [name]: value });
    }

    if (name === 'codeUserOffer') {
      let trueValue = value.replace(/[^0-9]/g, '');
      this.setState({ [name]: trueValue });
    }
  };

  handleFindUser = (e) => {
    if (e.target.value !== '') {
      this.administrativeService
        .validateUser(e.target.value)
        .catch((error) => console.log(error));
    }
  };

  handleConfirmIncreasePrice = () => {
    if (this.state.increaseValueTotal <= this.state.itemSelected.originalPriceValue) {
      this.setState({ showIncreaseValueValidationMessage: true });
      return;
    };

    const payloadIncreasePrice = {
      sellerReferenceId: this.props.sellerId,
      sequentialId: this.state.itemSelected.sequentialId,
      retailerId: Configuration.retailerId,
      posId: Configuration.PosId,
      barcode: this.state.itemSelected.productBarcode,
      quantity: this.state.itemSelected.quantity,
      increasedPriceValue: this.state.increaseValueTotal
    };

    this.saleService
      .increasePriceValue(payloadIncreasePrice)
      .catch((error) => console.log(error));


    this.handleCloseModalIncreasePrice();

    this.subscription = this.props.MessageBus.Subscribe(
      'pos.increaseprice.updateitem',
      this.handleIncreasePriceUpdateItem
    );
  };

  handleIncreasePriceUpdateItem = (ch, payload) => {
    const { sequentialId } = this.state.itemSelected;
    const selectedItem = payload.items.filter((item) => {
      return item.sequentialId === sequentialId;
    })[0];

    this.props.MessageBus.Unsubscribe('pos.increaseprice.updateitem');
    this.props.showDetailsItem(selectedItem);
  };


  validateUser = (ch, payload) => {
    if (ch === 'pos.user.info.success') {
      this.setState({
        nameUserOffer: payload.name,
        userWithoutPermission: false,
        messageErrorOfferCoverage: '',
        passwordUserOffer: '',
      });

      document.getElementById('inputPassword').focus();
    }

    if (ch === 'pos.user.info.notfound') {
      this.setState({
        nameUserOffer: '',
        passwordUserOffer: '',
        userWithoutPermission: true,
        messageErrorOfferCoverage: 'Usuário não encontrado!',
      });

      document.getElementById('inputCodeUser').focus();
    }
  };

  handleConfirmOfferCoverage = (solicitation) => {
    if (solicitation?.alternativeCredentials) {
      this.sendToOfferCoverage(solicitation?.alternativeCredentials);
    } else {
      const items = document.querySelectorAll('input[data="offer"]');
      var idx = [];

      items.forEach((item) => {
        if (
          item.value === '0,00%' ||
          item.value === 'R$0,00' ||
          item.value === ''
        ) {
          idx.push({ id: item.id });
        }
      });

      if (idx.length > 0) {
        document.getElementById(`${idx[0].id}`).focus();
      } else {
        if (this.state.discountPercentOffer < this.minDiscountPercentage) {
          document.getElementById('inputDiscountModal').focus();
          return;
        }

        const payloadUser = {
          retailerId: Configuration.retailerId,
          posType: Configuration.posType,
          posId: Configuration.PosId,
          username: this.state.codeUserOffer,
          password: this.state.passwordUserOffer,
        };

        payloadToBase64Zip(JSON.stringify(payloadUser)).then((token) => {
          let subTotalDiscount =
            this.state.itemSelected.subTotal *
            (this.state.discountPercentOffer / 100);

          subTotalDiscount = Math.round(subTotalDiscount * 100) / 100;

          if (subTotalDiscount === this.state.itemSelected.subTotal) {
            subTotalDiscount -= 0.01;
          }

          this.sendToOfferCoverage(token);
        });
      }
    }
  };

  sendToOfferCoverage = (token) => {
    let subTotalDiscount =
      this.state.itemSelected.subTotal *
      (this.state.discountPercentOffer / 100);

    subTotalDiscount = Math.round(subTotalDiscount * 100) / 100;

    if (subTotalDiscount === this.state.itemSelected.subTotal) {
      subTotalDiscount -= 0.01;
    }

    const payloadOffer = {
      sellerReferenceId: this.props.sellerId,
      sequentialId: this.state.itemSelected.sequentialId,
      retailerId: Configuration.retailerId,
      posId: Configuration.PosId,
      barcode: this.state.itemSelected.productBarcode,
      quantity: this.state.itemSelected.quantity,
      appliedDiscount: {
        type: 0,
        totalDiscountValue: subTotalDiscount.toFixed(2),
        percentageDiscountValue: this.state.discountPercentOffer,
        isNegociatedBySalesperson: true,
        reason: this.state.reasonDiscountOffer,
      },
      credentials: token,
    };

    this.saleService
      .offerCoverage(payloadOffer)
      .catch((error) => console.log(error));

    this.setState({
      margemLucro: 0
    })
  };

  handleOfferCoverage = (ch, payload) => {
    if (ch === 'pos.offercoverage.authorizationerror') {
      this.setState({
        userWithoutPermission: true,
        messageErrorOfferCoverage: payload.message,
      });
      return;
    }

    const { sequentialId } = this.state.itemSelected;

    const selectedItem = payload.items.filter((item) => {
      return item.sequentialId === sequentialId;
    })[0];

    this.setState(() => ({
      offerModalIsVisible: !this.state.offerModalIsVisible,
      userWithoutPermission: false,
      inputError: {
        field: '',
        error: '',
        message: '',
      },
      subTotalOffer: 0,
      discountPercentOffer: 0,
      reasonDiscountOffer: '',
      codeUserOffer: '',
      nameUserOffer: '',
      passwordUserOffer: '',
    }));

    this.props.MessageBus.Unsubscribe('pos.offercoverage.updateitem');
    this.props.showDetailsItem(selectedItem);
  };

  render() {
    return (
      <Fragment>
        {this.props.showDetailItemComponent &&
          this.state.itemSelected.productId && (
            <Detail
              ref={(ref) => {
                this.detailItemRef = ref;
              }}
              itemSelected={this.state.itemSelected}
              increaseValueTotal={this.state.increaseValueTotal}
              showIncreaseValueValidationMessage={this.state.showIncreaseValueValidationMessage}
              order={this.state.order}
              handleChangeQtt={this.handleChangeQtt}
              handleKeyPress={this.handleKeyPress}
              sendToDelete={this.sendToDelete}
              sendToUpdate={this.sendToUpdate}
              handleClose={this.handleClose}
              errorMessage={this.state.errorMessage}
              handleChangeDiscount={(e, changed) =>
                this.handleChangeDiscount(e, changed)
              }
              productHasDiscount={this.state.productHasDiscount}
              handleChangeSubTotal={this.handleChangeSubTotal}
              subTotalDiscountError={this.state.subTotalDiscountError}
              handleOpenModalOfferCoverage={this.handleOpenModalOfferCoverage}
              handleOpenModalIncreasePrice={this.handleOpenModalIncreasePrice}
              handleCloseModalOfferCoverage={this.handleCloseModalOfferCoverage}
              handleCloseModalIncreasePrice={this.handleCloseModalIncreasePrice}
              handleChangeOfferModal={this.handleChangeOfferModal}
              handleChangeDiscountPercentOffer={
                this.handleChangeDiscountPercentOffer
              }
              handleChangeSubTotalOffer={this.handleChangeSubTotalOffer}
              handleFindUser={this.handleFindUser}
              handleConfirmOfferCoverage={this.handleConfirmOfferCoverage}
              handleConfirmIncreasePrice={this.handleConfirmIncreasePrice}
              handleChangeIncreaseValue={this.handleChangeIncreaseValue}
              verifyDiscountPercentageLimits={
                this.verifyDiscountPercentageLimits
              }
              offerModalIsVisible={this.state.offerModalIsVisible}
              margemLucro={this.state.margemLucro}
              increasePriceModalIsVisible={this.state.increasePriceModalIsVisible}
              loggedUserHasPermission={this.state.loggedUserHasPermission}
              userWithoutPermission={this.state.userWithoutPermission}
              messageErrorOfferCoverage={this.state.messageErrorOfferCoverage}
              inputError={this.state.inputError}
              discountPercentOffer={this.state.discountPercentOffer}
              subTotalOffer={this.state.subTotalOffer}
              reasonDiscountOffer={this.state.reasonDiscountOffer}
              codeUserOffer={this.state.codeUserOffer}
              nameUserOffer={this.state.nameUserOffer}
              passwordUserOffer={this.state.passwordUserOffer}
              maxDiscountPercentage={this.maxDiscountPercentage}
              showAutorizationOnline={this.props.showAutorizationOnline}
              showAditionalInfo={this.state.showAditionalInfo}
              openStockList={() => {
                var item = this.state.itemSelected;
                item.referenceId = item.productReferenceId;
                item.name = item.productName;
                item.hideCoupon = false;
                if (this.props.isOnline)
                  this.props.openStockList(this.state.itemSelected);
                else
                  showToast({
                    type: 2,
                    title: 'Estoque de filiais',
                    html: 'Funcionalidade não disponível na malha offline!',
                    hideAfterMilliseconds: 5000,
                  });
              }}
            />
          )}
        {this.state.showMessage && (
          <MessageBox
            message='Deseja realmente excluir o produto do pedido?'
            okText='Sim'
            handleConfirm={() => this.confirmDelete(true)}
            handleCancel={() => this.confirmDelete(false)}
            visible={this.state.showMessage}
          />
        )}
        {this.state.requireAuth && (
          <RequireAuth
            title='Aplicar desconto'
            handleConfirm={this.handleConfirmAuth}
            handleClose={this.handleModalClose}
          />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  showDetailItemComponent: shouldShow(state, DETAIL_ITEM),
  itemSelected: getPayload(state, DETAIL_ITEM),
  errorMessage: state.order.errorMessage,
  order: state.order.order,
  sellerId: state.order.sellerId ?? 0,
  showAutorizationOnline: state.showAutorizationOnline,
  isOnline: state.pdvContingency.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
  showDetailsItem: (payload) =>
    dispatch(showCard(DETAIL_ITEM, payload, false, true)),
  closeDetailsItem: () => dispatch(closeAllCards()),
  openStockList: (item) => dispatch(showWithOthers(STOCKLIST, item, false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(DetailConteiner)));
