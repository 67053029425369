import React, {Fragment} from 'react';
import { Button } from '../modules';
import { Form, Col } from 'react-bootstrap';
import { RadioButton } from '../radio-button';
import { PasswordInput } from '../password';
import './pbm-config-funcional-component.scss';

function PbmConfigFuncionalComponent(props) {
    const {        
        environmentValues,
        handleEnvironmentChange,
        form,
        handleChange,
        callMutation
    } = props;

    return (
        <Fragment>
            <div className="body">
                <Form>
                    <Form.Row className="row-init">
                        <RadioButton title="Ambiente" values={environmentValues} onChange={handleEnvironmentChange} />
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="url">
                            <Form.Label>Endereço Integração</Form.Label>
                            <Form.Control
                                placeholder="Url"
                                defaultValue={form?.url}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="user">
                            <Form.Label>Usuário</Form.Label>
                            <Form.Control
                                placeholder="Usuário"
                                defaultValue={form?.user}
                                onChange={handleChange}
                            />
                        </Form.Group>                    
                        <Form.Group as={Col} controlId="password">
                            <Form.Label>Senha</Form.Label>
                            <PasswordInput value={form?.password} onChange={(e) =>
                                handleChange({ target: { id: 'password', value: e.target.value } })
                            }></PasswordInput>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={callMutation}>Salvar</Button>
            </div>
        </Fragment>
    );
}
export default PbmConfigFuncionalComponent;