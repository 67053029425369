import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const CashSupplyWrapper = styled.div`
  position: relative;
  height: 100%;

  .card-body {
    align-items: center;
    justify-content: center;
  }

  form {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${theme.metrics.basePadding * 2}px;
    align-items: end;

    input {
      padding: 0 ${theme.metrics.basePadding * 2}px;
      width: 290px;
      height: 40px;
      border: 1px solid ${theme.palette.buttonBorder};
      border-radius: ${theme.metrics.borderRadius}px;
    }
  }

  #card-supply {
    height: 100%;
  }
`;

export const InputGroup = styled.div`
  span {
    color: #fe2e2e;
    height: 25px;
  }

  small {
    color: #888484;
  }
`;

export const ConfirmFormActions = styled.div`
  .btn-custom {
    width: 138px;
  }
`;
