import { PERMISSION_TYPE_VENDA } from './permissions';

export default class UserPermissionService {
    static _instance = new UserPermissionService();

    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    getPermissions = () => {
        let token = window.sessionStorage.getItem('Access_Token');
        return this.parseJwt(token);
    };

    hasPermission(perm, permissionType = PERMISSION_TYPE_VENDA) {
        let permissions = this.getPermissions();
        return permissions[perm + permissionType] == 'true';
    }

    static GetInstance() {
        return (
            UserPermissionService._instance ||
            (UserPermissionService._instance = new UserPermissionService())
        );
    }
}