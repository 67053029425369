import React, { Component, Fragment } from 'react';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { PbmConfirmComponent } from '../../shared/components/pbm-confirm/pbm-confirm-component';
import TefPbmSaleService from '../../services/tefPbmSaleService';
import SaleService from '../../services/saleService';
import { Modal } from '../../shared/components/modal2';
import { Button } from '../../shared/components/button';

class PbmConfirmContainer extends Component {
  static propTypes = { };

  static defaultProps = { }

  constructor(props) {
    super(props);

    this.saleService = new SaleService();

    this.state = {
      show: false,
      payload: {}
    }

    this.props.MessageBus.Subscribe('pos.pbm.showAuthorizedItemsChanged', (ch, payload) => {
      this.setState({ show: true, payload });
    });

    this.props.MessageBus.Subscribe('pos.order.deleted', () => {
      this.cancelMessage();
      this.setState({ showConfirmCancel:false, show: false, payload: null });
    });

    this.props.MessageBus.Subscribe('pos.pbm.back', () => {
      this.cancelMessage();
      this.setState({ showConfirmCancel:false, show: false, payload: null });
    });
  }

  confirm = () => {
    TefPbmSaleService.applyProductsInSalesConfirmationPbm(this.state.payload.productsSalePbmContract.authorizedItems);
  }

  cancel = () => {
    this.modalConfirm.open();
  }

  confirmMessage = () => {
    this.saleService.deleteOrder();
  }

  cancelMessage = () => {
    if (this.modalConfirm) {
      this.modalConfirm.close();
    }
  }

  onOpenModal = () => {
    this.cancelRef.focus();
  }

  render() {
    const {
      show,
      payload
    } = this.state;

    return show && (
      <Fragment>
        <PbmConfirmComponent 
          confirm={this.confirm}
          cancel={this.cancel}
          pbm={payload.productsSalePbmContract}
        />
        <Modal title="Confirmação" onOpen={this.onOpenModal} closable={false} ref={ref => this.modalConfirm = ref}>
          <Modal.Body>
            Ao desistir do atendimento será necessário acessar o Portal da Drogaria e gerar um novo número de autorização.
            <br />
            <br />
            Deseja desistir deste atendimento?
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-outline" handleClick={this.confirmMessage}>Sim</Button>
            <Button className="btn btn-outline" ref={ref => this.cancelRef = ref} handleClick={this.cancelMessage}>Não</Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default withMessageBus(getContext(PbmConfirmContainer));