import ChatIcon from '../../assets/images/menu/chat.png';
import DocumentationIcon from '../../assets/images/menu/documentacao.png';
import ClientPortal from '../../assets/images/menu/portal-cliente.png';
import VirtualTourIcon from '../../assets/images/menu/visita-virtual.png';

const {
  OPERATION_VIRTUAL_TOUR,
  OPERATION_CHAT,
  OPERATION_DOCUMENTATION,
  OPERATION_CLIENT_PORTAL,
} = require('./operations');

const items = [
  {
    operationName: OPERATION_VIRTUAL_TOUR,
    icon: VirtualTourIcon,
    isHidden: false,
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
  {
    operationName: OPERATION_CHAT,
    icon: ChatIcon,
    isHidden: false,
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
  {
    operationName: OPERATION_DOCUMENTATION,
    icon: DocumentationIcon,
    isHidden: false,
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
  {
    operationName: OPERATION_CLIENT_PORTAL,
    icon: ClientPortal,
    isHidden: false,
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
];

export default items;
