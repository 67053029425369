import React from "react";
import PropTypes from "prop-types";
import FormControl from "../../@form-control";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  GroupContinuousUseItems,
} from "../style";

export const initialValuesNewPathology = {
  newPathology: {
    pathology: "",
  },
};

export const NewPathology = ({ errors, touched }) => {
  return (
    <ContinuousUseSection>
      <GroupContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newPathology?.pathology && touched.newPathology?.pathology
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newPathology"
            name="newPathology.pathology"
            label="Patologia *"
            placeholder="Informe o nome da patologia"
            autoFocus
            data-select-submit-button="Tab"
            data-submit-with-enter={true}
          />
        </ContinuousUseItems>
      </GroupContinuousUseItems>
    </ContinuousUseSection>
  );
};

NewPathology.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};
