import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input';

import { Card, Button } from '../modules';
import { CashWithdrawWrapper, InputGroup, ConfirmFormActions } from './style';

class CashWithdraw extends Component {
  constructor(props) {
    super(props);
    this.amountInput = null;
    this.motivoInput = null;
  }

  submitHandler = (event) => {
    let validate = true;

    event.preventDefault();
    if (this.refs.amountInput.state.value < 0) {
      validate = false;
      this.refs.amountInput.state.value = 0;
      this.refs.amountInput.placeholder = 'Informe um valor maior que zero!';
    }

    if (this.motivoInput.value === '') {
      validate = false;
      this.motivoInput.value = '';
      this.motivoInput.style.borderColor = '#FE2E2E';
      this.motivoInput.placeholder = 'Informe o motivo!';
    }

    if (validate) {
      this.props.handleClick(
        this.refs.amountInput.state.value,
        this.motivoInput.value
      );
    }
  };

  render() {
    const { handleClose, title, closeEnable = true } = this.props;
    return (
      <CashWithdrawWrapper>
        <Card
          id="card"
          title={title}
          closeEnable={closeEnable}
          handleClose={handleClose}
        >
          <form onSubmit={this.submitHandler}>
            <InputGroup>
              <label htmlFor="amount">Valor</label>
              <div>
                <CurrencyInput
                  id="amount"
                  ref="amountInput"
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </div>
            </InputGroup>
            <InputGroup>
              <label htmlFor="description">Informe o motivo</label>
              <div>
                <input
                  id="description"
                  className="medium-size input-custom"
                  type="text"
                  ref={(input) => {
                    this.motivoInput = input;
                  }}
                />
              </div>
            </InputGroup>
            <ConfirmFormActions>
              <Button
                id="btn-confirm"
                className="btn-large btn-purple btn-custom"
                type="submit"
                handleClick={this.validate}
              >
                Confirmar
              </Button>
            </ConfirmFormActions>
          </form>
        </Card>
      </CashWithdrawWrapper>
    );
  }
}
export default CashWithdraw;
