import styled from 'styled-components';

import theme from '../../../themes/default';

const operatorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;

  .operator-area {
    outline-style: none;
  }

  .label-title-value-wrapper,
  .operator-area {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .div-user-name{
    display : flex
    align-items: center;
  }

  .icon-align{
    margin-top: 10px;
  }

  .div-name{
    border: 1px solid #b86bc5; 
    border-radius: 100%; 
    height: 30px;
    width: 30px;
    margin-right: 10px;
    background-color: #5b2e90 ;  
    text-align: center;  
    p{
      color:white
      font-family: 'Dosis';
      font-weight: bold;
      margin-top: -10px;
    }
  }

  input {
    appearance: none;
    background: none;
    border: none;
    outline: none;
  }

  .menu-area {
    min-width: 200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 46px;    
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 15px;
    z-index: 2;
    margin-left: -110px;

    hr {
      margin: 0px 10px 0px 10px;
      border: 0.8px solid ${theme.palette.grayscale.light};
    }

    span {
      
      padding: 30px
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      height: 38px;
      color: ${theme.palette.grayscale.ultraDark};

      i,
      img {
        color: ${theme.palette.color[1]};
        padding-right: 15px;
      }
      &:focus,
    &:hover {   
      background-color: #ff9200;
      box-shadow: 0 0 8px #5b2e90;
      border-color: #5b2e90;
    }
    }
  }
`;
export default operatorWrapper;
