import React from "react";
import PropTypes from "prop-types";
import FormControl from "../../@form-control";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  GroupContinuousUseItems,
} from "../style";

export const initialValuesNewPrescriptionOrigin = {
  newPrescriptionOrigin: {
    prescriptionOrigin: "",
  },
};

export const NewPrescriptionOrigin = ({ errors, touched }) => {
  return (
    <ContinuousUseSection>
      <GroupContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newPrescriptionOrigin?.prescriptionOrigin &&
            touched.newPrescriptionOrigin?.prescriptionOrigin
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newPrescriptionOrigin"
            name="newPrescriptionOrigin.prescriptionOrigin"
            label="Origem *"
            placeholder="Informe a origem da prescrição"
            autoFocus
            data-select-submit-button="Tab"
            data-submit-with-enter={true}
          />
        </ContinuousUseItems>
      </GroupContinuousUseItems>
    </ContinuousUseSection>
  );
};

NewPrescriptionOrigin.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};
