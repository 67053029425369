import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { showCard, CREDIT_CARD_PARCEL_LIST, shouldShow, getPayload } from '../../redux/actions/routerAction';
import PaymentCrediCard from '../../shared/components/payment-credicard-parcel';
import PaymentService from '../../services/paymentService';
import { show } from '../../redux/actions/loadingScreenAction'

export class PaymentCrediCardParcelContainer extends Component {
  constructor(props) {
    super(props);
    this.props.MessageBus.Subscribe('payment.creditcard.installmentoptions', this.eventReturn.bind(this));
    this.props.MessageBus.Subscribe('pos.order.deleted', this.orderDeletedEvent.bind(this));
    if (window.desktopApp) {
       window.desktopApp.subscribe('machine.devices.tef.set.installments.completed', this.eventReturnParcel.bind(this));
    }
  }

  eventReturn(canal, payload) {
    const componentPayload = {
      creditcardParcel: payload
    }
    this.props.openCreditCardParcelList(componentPayload);
  }

  orderDeletedEvent() {
    // if the view is opened, setting the payload to null when the order is deleted
    if(this.props.showCreditCardParcelList) {
      this.props.openCreditCardParcelList('');
    }
  }

  service = new PaymentService()

  onHandleCredicard = installment => {
    if (window.desktopApp)
      window.desktopApp.publish('machine.devices.tef.set.installments', installment);
    else
      this.service.setParcelCreditPayment(installment).catch(error => this.context.showError(error));
  }

  eventReturnParcel(canal, payload) {
    this.service.setParcelCreditPayment(payload).catch(error => this.context.showError(error));
  }
  
  handleClose = () => {    
    if (window.desktopApp) {
        window.desktopApp.publish('machine.devices.tef.cancel.pending.operation', '')
        this.props.showLoadingScreen();
    }
    else {
      const service = new PaymentService();
      service.paymentError()
        .catch(err => this.context.showError(err));
    }
  }

  render() {
    const visible = this.props.showCreditCardParcelList;
    return (
      <div>
        {visible && (
          <PaymentCrediCard
            items={this.props.payload.creditcardParcel}
            onHandleCredicard={this.onHandleCredicard}
            handleClose={this.handleClose}
            {...this.props}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showCreditCardParcelList: shouldShow(state, CREDIT_CARD_PARCEL_LIST),
  payload: getPayload(state, CREDIT_CARD_PARCEL_LIST)
});

const mapDispatchToProps = dispatch => ({
  openCreditCardParcelList: payload => dispatch(showCard(CREDIT_CARD_PARCEL_LIST, payload)),
  showLoadingScreen: () => dispatch(show({text: 'Carregando...'}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PaymentCrediCardParcelContainer)));
