import * as types from '../actions/actionTypes';
import createReducer from './createReducer';

const initialState = {
  order: {
    items: [],
    orderDiscount: 0,
    orderTotalDiscount: 0,
    infoPbm: { paymentEndpoint: '' },
    customer: {},
    infoLinxConecta: {},
  },
  paymentType: '',
  seller: null,
  sellerId: 0,
  showButtonPaymentPreOrder: true,
  detailDialogPosition: {},
};

const orderReducer = createReducer(initialState, {
  [types.ORDER_RESET_STATE]: (state) => ({
    ...initialState,
    listPreOrderItens: state.listPreOrderItens,
  }),
  [types.ORDER_UPDATED]: (state, action) => ({
    ...state,
    order: action.payload,
  }),
  [types.UPDATE_ITEM]: (state, action) => ({
    ...state,
    order: action.payload,
  }),
  [types.DELETE_ITEM]: (state, action) => ({
    ...state,
    order: action.payload,
  }),
  [types.SHOW_BUTTON_PAYMENT_PRE_ORDER]: (state) => ({
    ...state,
    showButtonPaymentPreOrder: true,
  }),
  [types.SHOW_BUTTON_DONE_PRE_ORDER]: (state) => ({
    ...state,
  }),
  [types.DELETE_ORDER]: (state) => ({
    ...state,
    ...initialState,
  }),
  [types.DONE_PRE_ORDER]: (state) => ({
    ...state,
    order: initialState.order,
  }),
  [types.ORDER_IDENTIFY_SELLER]: (state, payload) => ({
    ...state,
    seller: payload.seller,
  }),
  [types.ORDER_IDENTIFY_SELLER_ID]: (state, payload) => ({
    ...state,
    sellerId: payload.sellerId,
  }),
  [types.SET_DIALOG_POSITION]: (state, payload) => ({
    ...state,
    detailDialogPosition: payload.position,
  }),
  [types.PRE_ORDER_DELETE_ITEM_INFO]: (state, payload) => ({
    ...state,
    orderDeleteItemInfo: payload,
  })
});

export default orderReducer;
