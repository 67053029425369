import React from "react";
import PropTypes from "prop-types";
import FormControl from "../../@form-control";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  GroupContinuousUseItems,
} from "../style";

export const initialValuesNewPrescriberSpecialty = {
  newPrescriberSpecialty: {
    prescriberSpecialty: "",
  },
};

export const NewPrescriberSpecialty = ({ errors, touched }) => {
  return (
    <ContinuousUseSection>
      <GroupContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newPrescriberSpecialty?.prescriberSpecialty &&
            touched.newPrescriberSpecialty?.prescriberSpecialty
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newPrescriberSpecialty"
            name="newPrescriberSpecialty.prescriberSpecialty"
            label="Especialidade *"
            placeholder="Informe o nome da especialidade"
            autoFocus
            data-select-submit-button="Tab"
            data-submit-with-enter={true}
          />
        </ContinuousUseItems>
      </GroupContinuousUseItems>
    </ContinuousUseSection>
  );
};

NewPrescriberSpecialty.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};
