import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Pbm as PbmComponent } from '../../shared/components/modules';
import { PbmProductList } from '../../shared/components/pbm-product-list/pbm-product-list';
import { showCard, closeAllCards, PBM, PBM_PRODUCTS, shouldShow } from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import TefPbmSaleService from '../../services/tefPbmSaleService';
import PbmSalePaymentService from '../../services/pbmSalePaymentService';
import PaymentService from '../../services/paymentService';

class PbmContainer extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;
    this.paymentService = new PaymentService();

    this.state = {
      activePbms: {
        networksDatas: []
      },
      isCardOrCpfNumber: false,
      productsContract: {  }
    };

    // consumerUnitPrice
    this.tableConfig = {
      columns: [
        {title: 'Produto', label: 'description', fixedWidth: 250},
        {title: 'Qtd', label: 'authorizedQuantityRequested', fixedWidth: 35},
        {title: '% Desconto', label: 'discount', percentageFormat: true},
        {title: 'Valor Bruto', label: 'medicineTotalValueWithoutDiscount', currencyFormat: true},
        {title: 'Valor Liquido', label:'medicineTotalValueDiscounted', currencyFormat: true},
      ],
      customClass: 'spaced-rows-in-card'
    }

    if (window.desktopApp) {
      window.desktopApp.subscribe(
        'machine.devices.tef.pbm.parameters.completed',
        (channel, data) => {
          if (data) {
            TefPbmSaleService.processParametersPbm(data);
          };
        }
      );

      window.desktopApp.subscribe(
        'machine.devices.tef.pbm.products.completed',
        (channel, msg) => {
          TefPbmSaleService.processProductsPbm(msg);
        }
      )

      window.desktopApp.subscribe(
        'machine.devices.tef.pbm.sale.completed',
        (channel, payload) => {
          TefPbmSaleService.confirmedSaleTransactionPbm(payload);
        }
      );

      window.desktopApp.subscribe(
        'machine.devices.tef.pbm.sale.finish.completed',
        (channel, payload) => {
          PbmSalePaymentService.saleFinished(payload);
        }
      );

      window.desktopApp.subscribe(
        'machine.devices.tef.pbm.finishTransactionsTef.completed',
        (channel, payload) => {
          this.paymentService.tefTransactionsSucessfullConfirmed(payload);
        }
      );
    }

    this.props.MessageBus.Subscribe('pos.pbm.salePbmFailed', this.handleShowError);
    this.props.MessageBus.Subscribe('pos.pbm.error', this.handleShowError);
    this.props.MessageBus.Subscribe('pos.pbm.back', () => {
      if(this.props.showPbm || this.props.showPbmProducts) {
        this.props.closeAllPbm();
      }
    });
    this.props.MessageBus.Subscribe('pos.pbm.doneProcessProducts', this.doneProcessingProducts);
    this.props.MessageBus.Subscribe('pos.pbm.doneProcessParameters', this.doneProcessingParameters);
  }

  handleShowError = (ch, payload) => {
    this.context.showMessage(payload && payload.reason);
  }

  doneProcessingParameters = (ch, payload) => {
    this.setState({
      activePbms: payload.activePbms
    }, ()=> this.props.startSalePbm());
  }

  doneProcessingProducts = (ch, payload) => {
    this.setState({
      productsContract: payload.productsPbmContract
    }, ()=>this.props.startProductsSalePbm());
  }

  doneApplyingProducts = () => {
    if(this.subscription){
      this.props.MessageBus.Unsubscribe(this.subscription);
      this.requestClosePbm();
    }
  }

  handleClick = (selectedPbm, doctoNumber) => {
    if(!selectedPbm || !doctoNumber) return;
    
    TefPbmSaleService.openProductsPbm({
      networkPbm: selectedPbm.number,
      authorization: this.state.isCardOrCpfNumber ? null : doctoNumber,
      cardNumber: this.state.isCardOrCpfNumber ? doctoNumber : null,
      couponNumber: this.state.activePbms.couponNumber
    });
  }

  requestClosePbm = () => {
    TefPbmSaleService.backSalePbm();
  }

  onNumberTypeChanged = e => {
    this.setState({ isCardOrCpfNumber: e.target.checked });
  }

  applyProducts = () => {
    this.subscription = this.props.MessageBus.Subscribe('pos.order.updated', this.doneApplyingProducts);
    TefPbmSaleService.applyProducts({
      couponNumber: this.state.productsContract.couponNumber,
      authorization: this.state.productsContract.authorization,
      controlNumber: this.state.productsContract.controlNumber,
      pbmNetwork: this.state.productsContract.pbmNetwork,
      products: this.state.productsContract.medicines
    });
    this.props.closeAllPbm();
  }

  render() {
    return (
      <Fragment>
        {(this.props.showPbm && this.state.activePbms && (
          <PbmComponent
            title={`Pbm's via TEF`}
            handleClick={this.handleClick}
            handleClose={this.requestClosePbm}
            items={this.state.activePbms.networksDatas}
            imgUrl='./assets/images/pinpad.png'
            onNumberTypeChanged={this.onNumberTypeChanged}
            isCardOrCpfNumber={this.state.isCardOrCpfNumber}
          />
        )) ||
        (this.props.showPbmProducts && this.state.productsContract && (
          <PbmProductList
            products={this.state.productsContract.medicines}
            totalQuantity={this.state.productsContract.quantityTotalMedicinesCalculated}
            noDiscountTotValue={this.state.productsContract.totalValueWithoutDiscount}
            discountTotValue={this.state.productsContract.totalValueDiscounted}
            totalValueSubsidy={this.state.productsContract.totalValueSubsidy}
            tableConfig={this.tableConfig}
            title="Produtos Autorizados"
            handleClose={this.requestClosePbm}
            confirmProducts={this.applyProducts}
          />
        ))}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showPbm: shouldShow(state, PBM),
  showPbmProducts: shouldShow(state, PBM_PRODUCTS)
});

const mapDispatchToProps = dispatch => ({
  startSalePbm: (ch, payload) => dispatch(showCard(PBM, payload)),
  startProductsSalePbm: (ch, payload) => dispatch(showCard(PBM_PRODUCTS, payload)),
  closeAllPbm: () => dispatch(closeAllCards())
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PbmContainer)));
