import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input';

import { Card, Button } from '../modules';
import { CashSupplyWrapper, InputGroup, ConfirmFormActions } from './style';

class CashSupply extends Component {
  constructor(props) {
    super(props);
    this.valorInput = null;
  }

  submitHandler = (event) => {
    event.preventDefault();
    const amount = this.refs.amountInput.state.value;

    if (amount > 0) {
      this.props.handleClick(amount);
    } else {
      this.refs.amountInput.state.value = 0;
      this.refs.amountInput.placeHolder = 'Valor maior que zero!';
    }
  };

  render() {
    const { handleClose, title, closeEnable = true } = this.props;
    return (
      <CashSupplyWrapper>
        <Card
          id="card-supply"
          title={title}
          closeEnable={closeEnable}
          handleClose={handleClose}
        >
          <form onSubmit={(e) => this.submitHandler(e)}>
            <InputGroup>
              <label htmlFor="amount">Valor</label>
              <div>
                <CurrencyInput
                  id="amount"
                  ref="amountInput"
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </div>
            </InputGroup>

            <ConfirmFormActions>
              <Button
                id="confirmar"
                className="btn-large btn-purple btn-custom"
                type="submit"
              >
                Confirmar
              </Button>
            </ConfirmFormActions>
          </form>
        </Card>
      </CashSupplyWrapper>
    );
  }
}
export default CashSupply;
