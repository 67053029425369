import handlePost from '../shared/utils/handlePost';
import Configuration from '../configuration';
import HttpAccess from './httpAccess';
import Store from '../redux/store/index';

export class AbstractService {
  constructor(service, httpAccess = HttpAccess, configuration = Configuration) {
    this.service = service;
    this.httpAccess = httpAccess;
    this.baseEndpoint = configuration.endPoint;
    this.baseEndpointContingency = configuration.endPointContingency;
    this.retailerId = configuration.retailerId;
    this.baseUrlMock = configuration.saleMock;
    this.posID = configuration.PosId;
  }
  getBaseUrl = () => {
    let url = Store.getState().pdvContingency.isOnline && this.baseEndpointContingency ? this.baseEndpointContingency : this.baseEndpoint;

    console.log('baseEndpointContingency :' + this.baseEndpointContingency);
    console.log('baseEndpoint :' + this.baseEndpoint);
    console.log('Abstract Service Url:' + url);
    return url;
  }
  invokeAction(path, body, showLoader = true) {
    const url = `${this.getBaseUrl()}${this.service}${path}`;
    const bodyToSend = this.addInDefaultBody(body);
    return handlePost(url, bodyToSend, this.httpAccess, showLoader);
  }

  addInDefaultBody(complBody) {
    const defaultBody = {
      retailerId: this.retailerId,
      posId: this.posID,
    };

    if (!complBody) return defaultBody;

    Object.keys(complBody).forEach((key) => {
      defaultBody[key] = complBody[key];
    });

    return defaultBody;
  }
}

export default AbstractService;
