import MessageBusService from '../../services/MessageBusService';
var JSZip = require("jszip");

export const payloadToBase64Zip = (payload) => {
  return new Promise((resolve) => {
    var zip = new JSZip();
    zip.file("credentials.txt", payload);
    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        var reader = new FileReader();
        reader.readAsDataURL(content);
        reader.onloadend = function () {
          resolve(reader.result.substr(reader.result.indexOf('base64,') + 7));
        }
      })
  })
}

export const Base64ZipToFile = (payload) => {
  return new Promise((resolve) => {
    var text = atob(payload);
    resolve(text);
  });
}
// type 
// 0 - Success (green)
// 1 - Alert (orange)
// 2 - Error (red)
// 3 - Info (purple)
// toast = {type, title, message, html, hideAfterMilliseconds}
export const showToast = (toast) => {
  MessageBusService.GetInstance().Publish('front.toast.show', toast);
};

// type 
// 0 - cached
// 1 - warning
// 2 - done
// 3 - error
// payload = {type: int, message: string, moreInfo: string, progressing: bool}
export const showInfoModal = (payload) => {
  MessageBusService.GetInstance().Publish('front.infoModal.show', payload);
};

export const getNumberValue = (value) => {
  let numberValue = value.replace(/\D+/g, "");

  return numberValue;
};