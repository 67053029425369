import React, { Component } from 'react'
import { LinxConectaPayment } from '../../shared/components/modules';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { closeAllCards, shouldShow, LINX_CONECTA_PAYMENT, getPayload, showCard } from '../../redux/actions/routerAction';
import PaymentService from '../../services/paymentService';
import { connect } from 'react-redux';

export class LinxConectaPaymentContainer extends Component {
  constructor(props) {
    super(props);

    this.props.MessageBus.Subscribe('pos.linxConecta.formaPagtoList', this.handleList);
    this.paymentService = new PaymentService();
    
  }

  handleClose = () => {
    this.paymentService.linxConectaBack()
      .catch(err => this.context.showError(err));
  }

  handleList = (ch, payload) => {
    this.props.openComponent(payload);
  }

  handleConfirm = (paymentFormId) => {
    this.paymentService.validarFormaPgto(
      paymentFormId,
      this.props.payload.amount);
  }

  currencyToValue = (value) => parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.'));

  render() {
    return (
      this.props.visible && <LinxConectaPayment
        items={this.props.payload.linxConectaFormasPagamento.items}
        amount={this.props.payload.amount}
        handleConfirm={this.handleConfirm}
        handleClose={this.handleClose}
        conectaInfo={this.props.conectaInfo}
      />
    )
  }
}

const mapStateToProps = state => ({
  visible: shouldShow(state, LINX_CONECTA_PAYMENT),
  payload: getPayload(state, LINX_CONECTA_PAYMENT),
  conectaInfo: state.order.order?.infoLinxConecta
});

const mapDispatchToProps = dispatch => ({
  openComponent: (payload) => dispatch(showCard(LINX_CONECTA_PAYMENT, payload)),
  closeAllCards: () => dispatch(closeAllCards())
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(LinxConectaPaymentContainer)));