import styled from 'styled-components';
import theme from '../../../themes/default';

const ButtonItemWrapper = styled.div`
     button {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: none;
        font-size: 16px;
        font-family:  inherit;
        outline:none;
        box-shadow: -4px 0px 5px -5px rgba(0,0,0,.25);
        // padding: 0 3vw;
        white-space: nowrap;

        &.button--actived {
            outline:none;
            background-color: ${theme.palette.grayscale.active};
            border: 1px solid ${theme.palette.border[1]};
            border-radius: 4px;
            transform: scale(1.07);
            box-shadow: none;
            font-weight: 600;
        }

        &.button--text-overflowed {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

`;
export default ButtonItemWrapper;
