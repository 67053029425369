import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';

import { Button } from './button-style';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  tooltip?: string;
  icon?: any;
  sizeSpan?: string;
  colorSpan?: string;
  propsLabel?: HTMLAttributes<HTMLSpanElement>;
}

export function ButtonComponent(props: IButton) {
  return (
    <Button
      type='button'
      {...props}
      title={props.tooltip ? props.tooltip : props.title}
      spanSize={props.sizeSpan}
      colorSpan={props.colorSpan}
    >
      <span {...props.propsLabel}>{props.title}</span>
      {props.icon && <img src={props.icon} />}
    </Button>
  );
}
