/* eslint-disable no-loop-func */

import React from 'react';
import { ApplyPbmProductsComponent } from '../../shared/components/modules';
import PbmSaleService from '../../services/pbmSaleService';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { connect } from 'react-redux';

export class ApplyPbmProductsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalItems: 0,
      approvedItems: null,
      notApprovedItems: null,
      files: [],
      recusedFiles: []
    };

    this.form = {
      convenio: null,
      cardNumber: null
    };

    this.subscription = null;
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleItemsPayload = this.handleItemsPayload.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.reset = this.reset.bind(this);

    this.props.MessageBus.Subscribe('pos.pbm.itemsToApply', this.handleItemsPayload);
    this.props.MessageBus.Subscribe('pos.pbm.closeItemsToApply', this.reset);
    this.props.MessageBus.Subscribe('pos.order.deleted', this.reset);
    this.props.MessageBus.Subscribe('pos.order.concluded', this.reset);
  }

  handleItemsPayload(ch, payload) {
    let approvedItems = [...payload.filter(f => f.authorizedQuantity > 0)];
    let notApprovedItems = [...payload.filter(f => f.authorizedQuantity === 0)];
    if (approvedItems.length > 0)
      approvedItems.push(this.calculateTotals(approvedItems));
    if (notApprovedItems.length > 0)
      notApprovedItems.push(this.calculateTotals(notApprovedItems));

    this.setState({
      approvedItems,
      notApprovedItems,
      totalItems: payload.length
    });
  }
  calculateTotals(items) {
    var totalRequested = items.reduce((a, b) => { return a + b.requestedQuantity }, 0);
    var totalAuthorizedQuantity = items.reduce((a, b) => a + b.authorizedQuantity, 0);
    var totalProductValue = items.reduce((a, b) => a + b.totalValue, 0);
    var totalProductValueWithDiscount = items.reduce((a, b) => a + b.totalValueWithDiscount, 0);
    var totalSubsidy = items.reduce((a, b) => a + b.totalSubsidy, 0);
    var totalpercentageDiscountValue = items.reduce((a, b) => a + b.percentageDiscountValue, 0);
    return {
      barcode: '',
      name: 'TOTAL:',
      requestedQuantity: totalRequested,
      authorizedQuantity: totalAuthorizedQuantity,
      totalValue: totalProductValue,
      percentageDiscountValue: totalpercentageDiscountValue,
      totalValueWithDiscount: totalProductValueWithDiscount,
      totalSubsidy: totalSubsidy
    };
  }

  reset() {
    this.componentRef && this.componentRef.closeModal();
    this.subscription && this.props.MessageBus.Unsubscribe(this.subscription);
    this.subscription = null;
    this.setState({
      totalItems: 0,
      approvedItems: null,
      notApprovedItems: null,
      files: [],
      recusedFiles: []
    });
  }

  handleConfirm() {
    this.subscription = this.props.MessageBus.Subscribe('pos.order.updated', this.reset);
    PbmSaleService.applyPbmProducts(this.state.files);
  }

  handleClose() {
    PbmSaleService.closeItemsToApply();
  }

  handleFileRemove(index) {
    var files = this.state.files;
    files.splice(index, 1);
    this.setState({ files: files });
  }

  handleFileChange(fileEvent) {
    function loadFile(file) {
      var reader = new FileReader();
      if ((file.size / 1024 / 1024) > 2) {
        var recusedFiles = this.state.recusedFiles;
        if (recusedFiles.filter(item => item === fileEvent.target.files[i].name).length === 0) {
          recusedFiles.push(fileEvent.target.files[i].name);
        }
        this.setState({ recusedFiles: recusedFiles });
        return;
      }
      reader.readAsDataURL(fileEvent.target.files[i]);
      reader.onload = (e) => {
        var arr = this.state.files;
        arr.push({
          name: file.name,
          extensao: file.name.split('.')[file.name.split('.').length - 1],
          base64: reader.result.substring(reader.result.indexOf('base64,') + 7)
        });
        this.setState({ files: arr });
      };
    }
    for (var i = 0; i < fileEvent.target.files.length; i++) {
      if (this.state.files.filter(item => item.name === fileEvent.target.files[i].name).length === 0) {
        loadFile.bind(this)(fileEvent.target.files[i]);
      }
    }
    fileEvent.target.value = '';
  }

  render() {
    return (
      this.state.totalItems > 0 &&
      <ApplyPbmProductsComponent
        ref={ref => this.componentRef = ref}
        pbmDescription={this.props.pbmDescription}
        pbmPrescriptionCopy={this.props.pbmPrescriptionCopy}
        approvedItems={this.state.approvedItems}
        notApprovedItems={this.state.notApprovedItems}
        handleConfirm={this.handleConfirm}
        handleClose={this.handleClose}
        handleFileChange={this.handleFileChange}
        handleFileRemove={this.handleFileRemove}
        files={this.state.files}
        recusedFiles={this.state.recusedFiles}
      />
    )
  }
}

const mapStateToProps = state => ({
  pbmDescription: state.order.order?.infoPbm && state.order.order?.infoPbm.pbmDescription,
  pbmPrescriptionCopy: state.order.order?.infoPbm && state.order.order?.infoPbm.prescriptionCopy
});

export default connect(mapStateToProps, null)(withMessageBus(getContext(ApplyPbmProductsContainer)));
