import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '../../button-component';
import { ContainerComponent } from '../../container';
import { ModalComponent } from '../../modal-component';
import { Hr, Title } from '../style';
import BarCode from '../../../../assets/images/barcode-to-conference-animation.svg';
import { ImgContainer, ContainerTable } from './styled';

import * as cashDeskServices from '../../../../services/cashDeskServices';
import MessageBusService from '../../../../services/MessageBusService';
import { showToast } from '../../../utils/util';
import { IConference } from '../interfaces/conference';
import {
  BarCodeTypeCategoryEnum,
  BarCodeTypeEnum,
} from '../enum/barCodeTypeEnum';
import { ShowMessage } from '../../show-message/show-message';

interface IConferenceScanner {
  handleClickConfirm: any;
  handleClickCancel: any;
}

export function ConferenceScanner(props: IConferenceScanner) {
  const [conferenceList, setConferenceList] = useState<IConference[]>([]);
  const [barCode, setBarCode] = useState('');
  const [listToAddConference, setList] = useState<IConference>();
  const [showMessage, setShowMessage] = useState<boolean>(false);

  let code = '';

  useEffect(() => {
    document.addEventListener('keypress', readBarCode);

    const barCodeSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.barcode.success',
      setBarCodeSuccess
    );

    const barCodeError = MessageBusService.GetInstance().Subscribe(
      'pos.barcode.error',
      callErrorMessage
    );

    const barCodeRead = MessageBusService.GetInstance().Subscribe(
      'pos.barcode.alreadyRead',
      callAlreadyMessage
    );

    const barCodeUnread = MessageBusService.GetInstance().Subscribe(
      'pos.unread.barcode.success',
      () => props.handleClickCancel()
    );

    const barCodeUnreadError = MessageBusService.GetInstance().Subscribe(
      'pos.unread.barcode.error',
      callErrorMessageBarcodeUnreadError
    );

    return () => {
      document.removeEventListener('keypress', readBarCode);

      MessageBusService.GetInstance().Unsubscribe(barCodeSuccess);
      MessageBusService.GetInstance().Unsubscribe(barCodeError);
      MessageBusService.GetInstance().Unsubscribe(barCodeRead);
      MessageBusService.GetInstance().Unsubscribe(barCodeUnread);
      MessageBusService.GetInstance().Unsubscribe(barCodeUnreadError);
    };
  }, []);

  useEffect(() => {
    if (barCode) {
      cashDeskServices.getBarCode(barCode);

      setBarCode('');
    }
  }, [barCode]);

  useEffect(() => {
    if (!listToAddConference) return;

    setConferenceList([...conferenceList, listToAddConference]);
  }, [listToAddConference]);

  const confirmar = () => {
    if (!conferenceList.length) {
      showToast({
        type: 1,
        title: 'Atenção!',
        html: 'Não existe nenhum documento conferido.',
        hideAfterMilliseconds: 5000,
      });

      return;
    }

    props.handleClickConfirm(conferenceList);
  };

  const readBarCode = (event) => {
    if (event.keyCode === 13) {
      setBarCode(code);

      code = '';

      return;
    }

    code = code + event.key;
  };

  function setMaskMoney(value: number) {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  }

  const setBarCodeSuccess = (response, payload) => {
    const { id, amount, barCodeType } = payload[0];

    setList({
      id: id,
      descricao: BarCodeTypeEnum[barCodeType],
      value: amount,
      category: BarCodeTypeCategoryEnum[barCodeType],
    });
  };

  const callErrorMessage = () => {
    showToast({
      type: 2,
      title: 'Erro ao buscar documento!',
      html: 'Documento não encontrado ou não pertence a este caixa.',
      hideAfterMilliseconds: 5000,
    });
  };

  const callAlreadyMessage = () => {
    showToast({
      type: 1,
      title: 'Atenção!',
      html: 'Documento já conferido.',
      hideAfterMilliseconds: 5000,
    });
  };

  const callErrorMessageBarcodeUnreadError = () => {
    showToast({
      type: 2,
      title: 'Erro ao cancelar conferências!',
      html: 'Houve um erro ao tentar cancelar as conferências efetuadas.',
      hideAfterMilliseconds: 5000,
    });
  };

  const cancel = () => {
    if (!conferenceList.length) {
      props.handleClickCancel();

      return;
    }

    setShowMessage(true);
  };

  const confirmCancelBarCodeUnread = () => {
    let barcodeLists = conferenceList.map((x) => x.id);

    cashDeskServices.getBarCodeUnread(barcodeLists);
  };

  if (showMessage)
    return (
      <ShowMessage
        title={'Atenção!'}
        message={'Tem certeza que deseja cancelar os itens já conferidos?'}
        handleClickConfirm={confirmCancelBarCodeUnread}
        handleClickCancel={() => setShowMessage(false)}
        type={'warning'}
        showBtnCancel={true}
        titleBtnCancel='Não'
        titleBtnConfirm='Sim'
      />
    );

  return (
    <ModalComponent title='Conferência' show={true} onClose={() => cancel()}>
      <ContainerComponent id='container-cash-close-scanner'>
        <div className='row'>
          <Title style={{ marginBottom: '.5rem' }}>
            <h3>Aguardando Leitura dos Códigos de Barras</h3>
          </Title>

          <ImgContainer>
            <img src={BarCode} />
            <hr className='line' />
          </ImgContainer>
        </div>

        <div className='row' style={{ marginBottom: '0px' }}>
          <Hr />
        </div>

        <div className='row' style={{ flexDirection: 'column' }}>
          <Title>
            <h3>Comprovantes conferidos - {conferenceList.length}</h3>
          </Title>

          <ContainerTable>
            {!conferenceList.length ? (
              <span style={{ width: '100%' }}>
                Nenhum comprovante conferido
              </span>
            ) : (
              <table data-testid={'table-scanner'}>
                <tbody>
                  {conferenceList.map((conference, index) => {
                    return (
                      <tr key={index}>
                        <td>{conference.descricao}</td>
                        <td>
                          {setMaskMoney(+conference.value).replace('R$', 'R$ ')}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </ContainerTable>
        </div>

        <div className='row' style={{ marginBottom: '0px' }}>
          <Hr />
        </div>

        <div className='row'>
          <div
            className='btn-group'
            style={{ flexDirection: 'column', alignItems: 'center' }}
          >
            <ButtonComponent
              title={'Cancelar'}
              name='btn-cancel'
              onClick={cancel}
              colorSpan={'#FFB200'}
              style={{
                width: '75%',
                marginBottom: '.5rem',
                border: '2px solid #FFB200',
              }}
            />

            <ButtonComponent
              className='btn-b-success'
              title={'Conferir'}
              onClick={confirmar}
              style={{ width: '75%' }}
            />
          </div>
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}
