import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0px;
  flex-direction: column;
`;

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Hr = styled.hr`
  border: 1px solid #b4b0b0;
  flex: 0.9;
  margin: 0px;
`;

export const SpanTitle = styled.span`
  flex: 1;
  margin: 0px;
  text-align: center;
  heigth: 23px;
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`;
