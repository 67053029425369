import React from 'react'
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { ConfigPosConnect } from '../../shared/components/config-posconnect';
import PaymentService from '../../services/paymentService';
import { CONFIG_POSCONNECT, shouldShow, closeCard, showCard } from '../../redux/actions/routerAction';

export class ConfigPosConnectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
    this.props.MessageBus.Subscribe('pos.posconnect.showconfig', this.handleShow);
    this.props.MessageBus.Subscribe('pos.posconnect.closeconfig', this.handleClose);
  }

  handleShow = (ch, payload) => {
    this.setState({
      form: payload
    });
    this.props.show();
    this.forceUpdate();
  }

  handleClose = () => {
    this.setState({
      form: {}
    });
    this.props.close();
    this.forceUpdate();
  }

  handleConfirm = () => {
    new PaymentService().setPosConnectConfig(this.state.form);
  }

  handleChange = (e) => {
    this.state.form[e.target.id] = e.target.value;
  };

  render() {
    return (
      this.props.visible && <ConfigPosConnect
        handleClose={this.handleClose}
        handleConfirm={this.handleConfirm}
        form={this.state.form}
        handleChange={this.handleChange}
      >
      </ConfigPosConnect>
    );
  }
}
const mapStateToProps = state => ({
  visible: shouldShow(state, CONFIG_POSCONNECT),
});

const mapDispatchToProps = dispatch => ({
  show: () => dispatch(showCard(CONFIG_POSCONNECT, null, true)),
  close: () => dispatch(closeCard(CONFIG_POSCONNECT))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(ConfigPosConnectContainer)));
