import React from 'react';
import { ItemRender, OptionsRender, Select } from './style';
import { currencyString } from '../../shared/utils/masks';
import Stock from '../../assets/images/stock.png';
import { showToast } from '../../shared/utils/util';

export const FindBytypedText = (props) => (
  <OptionsRender {...props} >
    <div className="container">
      <div className="anchor">Pesquisar por mais resultados de: <b>{`${props.item}...`}</b></div>
      <div className="options" onClick={(event) => props.clickDivOption(event)}>
        <div className="stockOptions">
          <div>
            Produto:
          </div>
          <div>
            <Select className={`title-select`} onClick={(event) => props.clickToggleOnOption(event, 'isToggleOnOptionStock')} >
              {props.options.toggleOnOptionStock[props.toggleOnOptionStockSelected].name}
              <div className="icon-align">
                <i className="material-icons">{props.isToggleOnOptionStock ? 'expand_less' : 'expand_more'}</i>
              </div>
              {props.isToggleOnOptionStock && (
                <div className="menu-area">
                  {props.options.toggleOnOptionStock.map(option => (
                    <span
                      key={option.value}
                      id='toggleOnOptionStockSelected'
                      tabIndex="0"
                      onClick={e => { props.clickSelectToggleOnOptionStock(option) }}
                    >
                      {option.name}
                    </span>
                  ))}
                </div>
              )}
            </Select>
          </div>
        </div>
        <div className="ruleOptions">
          <div>
            Regra de pesquisa:
          </div>
          <div>
            <Select className={`title-select`} onClick={(event) => props.clickToggleOnOption(event, 'isToggleOnOptionSearchRule')} >
              {props.options.toggleOnOptionSearchRule[props.toggleOnOptionSearchRuleSelected].name + props.query}
              < div className="icon-align">
                <i className="material-icons">{props.isToggleOnOptionSearchRule ? 'expand_less' : 'expand_more'}</i>
              </div>
              {props.isToggleOnOptionSearchRule && (
                <div className="menu-area">
                  {props.options.toggleOnOptionSearchRule.map(option => (
                    <span
                      key={option.value}
                      id='toggleOnOptionSearchRuleSelected'
                      tabIndex="0"
                      onClick={e => { props.clickSelectToggleOnOptionSearchRule(option) }}
                    >
                      {option.name + props.query}
                    </span>
                  ))}
                </div>
              )}
            </Select>
          </div>
        </div>
      </div>
    </div >
  </OptionsRender >
);

export const Item = props => (
  (props.optionStock === 0 || (props.optionStock === 1 && props.aditionalInfo && props.aditionalInfo.quantity > 0) || (props.optionStock === 2 && props.aditionalInfo && props.aditionalInfo.quantity <= 0)) ?
    <ItemRender className="item-list" {...props}>
      <span className="ds-item">{props.item.description}</span>
      <small className="ds-manufacturer bold">Fabricante</small>
      <span className="manufacturer">{props.aditionalInfo && props.aditionalInfo.supplier ? props.aditionalInfo.supplier : '?'}</span>
      <small className="ds-stock bold center">Estoque</small>
      <span className="stock center">{props.aditionalInfo ? props.aditionalInfo.quantity : '?'}</span>
      <small className="ds-price bold center">Valor</small>
      <span className="price center">{props.aditionalInfo ? currencyString(props.aditionalInfo.price) : '?'}</span>
      <small className="active-principle">{props.item.activePrinciple}</small>
      {props.item.referenceId && <>
        <small className='stock-label'>Estoque de Filiais</small>
        <div className='stock-eg'><button onClick={(e) => {
          const { item } = props;
          item.name = item.description;
          item.fromCache = true;
          e.preventDefault();
          e.stopPropagation();
          if (props.isOnline)
            props.openStockList(item);
          else
            showToast({
              type: 2,
              title: 'Estoque de filiais',
              html: 'Funcionalidade não disponível na malha offline!',
              hideAfterMilliseconds: 5000
            });

        }}><img src={Stock}></img>
        </button></div>
      </>
      }
    </ItemRender> : ''
);
