import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input';

import MessageBusService from '../../../services/MessageBusService';
import { Card, Button } from '../modules';
import { CashOpenWrapper, Container } from './style';

class CashOpen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userError: this.props.message,
      message: this.props.message,
    };

    this.focusUserCode = this.focusUserCode.bind(this);
    this.submitHandler = this.submitHandler.bind(this);

    this.amountValue = 0;
  }

  componentDidMount() {
    MessageBusService.GetInstance().Subscribe(
      'pos.dialog-closed',
      this.focusUserCode
    );
    if (this.props.needUser) {
      this.userCodInput.focus();
      return;
    }

    this.props.needAmount && this.amountInput.theInput.focus();
  }

  componentDidUpdate() {
    if (this.amountInput) {
      this.amountInput.theInput.value = this.amountValue;
    }
    if (this.props.message !== this.state.message) {
      this.setState({
        message: this.props.message,
        userError: this.props.message,
      });
    }
  }

  componentWillUnmount() {
    if (this.dialogSub) {
      MessageBusService.GetInstance().Unsubscribe(this.dialogSub);
    }
  }

  focusUserCode() {
    if (this.userCodInput) {
      this.userCodInput.focus();
    }
  }

  submitHandler = (event) => {
    let validate = true;
    event.preventDefault();

    if (this.amountInput) {
      this.amountValue = this.amountInput.theInput.value;
    }

    let userError = '';
    let amountError = '';

    if (this.props.needAmount && this.amountInput.theInput.value < 0) {
      validate = false;
      this.amountInput.theInput.focus();
      amountError = 'Informe um valor maior que zero!';
    }

    if (this.props.needUser && this.userCodInput.value === '') {
      validate = false;
      userError = 'Informe seu código de usuário!';
      this.userCodInput.focus();
    }

    this.setState({
      userError,
      amountError,
    });

    if (validate) {
      const values = [];
      if (this.props.needUser) values.push(this.userCodInput.value);
      if (this.props.needAmount) values.push(this.amountInput.theInput.value);
      this.props.handleClick(...values);
    }
  };

  render() {
    const { title, needUser, needAmount } = this.props;

    return (
      <Container>
        <Card title={title} className="card-cash" style={{ height: '100%' }}>
          <CashOpenWrapper
            id="form-cash"
            key="formIdentifySeller"
            onSubmit={(e) => this.submitHandler(e)}
            ref={(form) => {
              this.formEl = form;
            }}
          >
            {needUser && (
              <div className="inputGroup">
                <label htmlFor="userCode">Código do Operador</label>
                <input
                  id="userCode"
                  type="number"
                  ref={(input) => {
                    this.userCodInput = input;
                  }}
                  autoFocus
                />
                <span className="error" id="errorUser">
                  {this.state.userError}
                </span>
              </div>
            )}
            {needAmount && (
              <div className="inputGroup">
                <label htmlFor="amount">Valor de Abertura</label>
                <CurrencyInput
                  id="amount"
                  ref={(ref) => (this.amountInput = ref)}
                  decimalSeparator=","
                  thousandSeparator="."
                  placeholder="Informe o valor inicial"
                />
                <span className="error" id="amountError">
                  {this.state.amountError}
                </span>
              </div>
            )}
            <Button
              id="btn-confirm"
              className="btn-large btn-purple btn-custom"
              type="submit"
            >
              Confirmar
            </Button>
          </CashOpenWrapper>
        </Card>
      </Container>
    );
  }
}

export default CashOpen;
