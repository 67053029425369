import React from 'react';
import { ButtonIcon } from './style';

const TooltipIcon = (props) => {
  return (
    <ButtonIcon
      width={props.width}
      position={props.position}
      customValue={props.customValue}
      color={props.color}
      aria-label={props.children}
      role="tooltip"
      data-testid="tooltipIcon"
    >
      <i className="material-icons-outlined">help</i>
    </ButtonIcon>
  )
}

export default TooltipIcon;
