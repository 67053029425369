import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MaskCNPJ } from "../../../utils/modules";
import FormControl from "../../@form-control";
import { Button } from "../../modules";
import { SelectBox } from "../../select-box";
import { ContinuousUseItems, GroupContinuousUseItems } from "../style";

export const HospitalClinic = ({
  data,
  values,
  setFieldValue,
  handleCreateNew,
  errors,
  touched,
  manage,
}) => {
  const hospitalClinicSelect = data?.filter(({ active }) => active);

  hospitalClinicSelect.sort((a, b) => {
    return a.name?.localeCompare(b.name);
  });

  const handleSelectHospitalClinic = (value) => {
    setFieldValue("hospitalClinic.id", value?.id || "");
    setFieldValue("hospitalClinic.referenceId", value?.referenceId || "");
    setFieldValue("hospitalClinic.name", value?.name || "");
    setFieldValue("hospitalClinic.cnpj", MaskCNPJ(value?.hospitalCnpj) || "");
  };

  useEffect(() => {
    if (manage?.hasOwnProperty("hospitalClinic")) {
      handleSelectHospitalClinic(manage.hospitalClinic);
    }
  }, [manage]);

  return (
    <GroupContinuousUseItems>
      <ContinuousUseItems
        width="39%"
        required={values.hospitalClinic.required}
        hasError={errors.hospitalClinic?.name && touched.hospitalClinic?.name}
      >
        <FormControl
          className="continuous-use-items-input"
          id="hospitalClinic"
          name="hospitalClinic.name"
          label="Hospital ou Clínica"
        >
          <SelectBox
            className="continuous-use-items-input"
            placeholder="Informe o hospital ou clínica"
            data={hospitalClinicSelect}
            searchTerm={values.hospitalClinic.name}
            field={"hospitalClinic.name"}
            setValue={(value) => handleSelectHospitalClinic(value)}
          />
        </FormControl>
      </ContinuousUseItems>
      <ContinuousUseItems width="40%" readOnly>
        <FormControl
          className="continuous-use-items-input"
          id="cnpj"
          name="hospitalClinic.cnpj"
          label="CNPJ"
          readOnly
        />
      </ContinuousUseItems>
      <ContinuousUseItems flex={1}>
        <Button
          className="btn-outline"
          onClick={() => handleCreateNew("newHospitalClinic")}
        >
          CRIAR NOVO
        </Button>
      </ContinuousUseItems>
    </GroupContinuousUseItems>
  );
};

HospitalClinic.propTypes = {
  data: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  manage: PropTypes.object.isRequired,
};
