import React, { Fragment } from 'react';
import { MessageDetail } from './style';
import { Modal } from '../modal2';
import parse from 'html-react-parser';
import Button from '../button';

export class LicenseModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalMessage = React.createRef();
        this.state = { message: '' };
        this.field = React.createRef();
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    closable = () => true;

    componentDidMount() {
        if (this.props.visible) {
            this.modalMessage.current.open();
            document.querySelector('.overlay').addEventListener('keydown', this.handleKeyDown);
            this.modalMessage.current.focus();
        }
    }

    componentDidUpdate() {
        this.modalMessage.current.focus();
    }

    componentWillUnmount() {
        // document.querySelector('.overlay').removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (this.closable() && (e.key === 'Escape' || e.key === 'Enter')) {
            this.modalMessage.current.close();
        }
        e.preventDefault();
    }

    parseMessageWithLink(message) {
        var splitter = "#UrlPortalFinanceiro#";
        var arrayMessage = message.message.split(splitter);
        return (
            <div>
                <p>{parse(arrayMessage[0])}
                    <a style={{ color: 'purple', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { this.handleOpenLink(message.link) }}>{message.link}
                    </a>
                    {parse(arrayMessage[1])}
                </p>
            </div>
        )
    }

    handleOpenLink(link) {
        if (window.desktopApp)
            window.desktopApp.publish('machine.browser.open', link)
        else
            window.open(link)
    }

    render() {
        return (
            <Modal
                title={this.props.message.title}
                closable={false}
                ref={this.modalMessage}
                opened
                style={{
                    overflow: 'hidden',
                    maxWidth: '40vw',
                    width: 'auto',
                    border: '1px solid #dfdfdf'
                }}
                zIndex="1100"
                handleClose={this.props.handleClose}
                closeOnOverlayClick={false}
            >
                <Modal.Body>
                    <MessageDetail>
                        {
                            this.props.message
                            && <i className="material-icons" icon={'warning'}></i>
                        }
                        {this.props.message.message && this.props.message.message.length > 0 ?
                            <div className="moreinfo">
                                {this.props.message.hasLink ?
                                    <p>
                                        {this.parseMessageWithLink(this.props.message)}
                                    </p>
                                    :
                                    <p>{parse(this.props.message.message)}
                                    </p>
                                }
                            </div> : null
                        }

                    </MessageDetail>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <div className="btn-group">
                            <Button
                                id="btn-close"
                                className={"btn-default btn-purple"}
                                type="button"
                                onClick={this.props.handleClose}
                                style={{ margin: '16px' }}
                            >
                                Ok
                            </Button>
                        </div>
                    }
                    {
                        this.props.message.allowUnlock &&
                        <div className="btn-unlock">
                            <Button
                                id="btn-confirm"
                                className={"btn-default btn-purple"}
                                type="button"
                                onClick={this.props.handleUnlock}
                                style={{ margin: '16px' }}
                            >
                                Desbloqueio em confiança
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}
export default LicenseModal