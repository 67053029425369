import { differenceInMilliseconds } from 'date-fns';
import { all, takeEvery, put, select, delay } from 'redux-saga/effects';

import { show, hide } from '../../actions/loadingScreenAction';
import { showMessageAction as showMessage } from '../message/duck';
import {
  TYPE_ADD_CONNECTION,
  TYPE_REMOVE_FIRST_CONNECTION,
  TYPE_REMOVE_CONNECTION,
  removeConnection,
} from './duck';

export function* connectionAdded({ payload }) {
  yield put(show({ text: payload.name || 'Carregando' }));
}

export function* connectionRemoved() {
  const connections = yield select((state) => state.connectionPool.connections);
  if (connections.length === 0) yield put(hide());
}

export function* timeout() {
  while (true) {
    const connections = yield select(
      (state) => state.connectionPool.connections
    );

    const connection = connections.find(
      (item) =>
        differenceInMilliseconds(new Date(), item.startTime) > item.timeout
    );

    if (connection) {
      yield put(removeConnection(connection.id));
      yield put(
        showMessage({
          message: 'Tempo máximo excedido ao realizar requisição',
          onConfirm: () => {},
        })
      );
    }

    if (process.env.NODE_ENV === 'test') {
      break;
    }
    yield delay(1000);
  }
}

export default all([
  timeout(),
  takeEvery(TYPE_ADD_CONNECTION, connectionAdded),
  takeEvery(TYPE_REMOVE_FIRST_CONNECTION, connectionRemoved),
  takeEvery(TYPE_REMOVE_CONNECTION, connectionRemoved),
]);
