import styled from 'styled-components';

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .order-input {
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    font: 15px Dosis;
    text-align: start;
  }

  .header {
    align-self: center;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .input-header {
    font: 14px Dosis;
    font-weight: bold;
  }
`

export const ButtonWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`