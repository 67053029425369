import { AbstractService } from './abstractService';

export default class CatalogService extends AbstractService {
  constructor() {
    super('/Catalog');
  }
  getCities = () => this.invokeAction('/GetCities');

  getCityByState = (stateAbbr) => this.invokeAction('/GetCityByState', { stateAbbr });
  
  getPrescriber = (criteria, page, pageCount) => this.invokeAction('/GetPrescriber', { criteria, page, pageCount });

  addPrescriber = (payload) => this.invokeAction('/AddPrescriber', payload );
  
  addPrescriberBySngpc = (payload) => this.invokeAction('/AddPrescriberBySngpc', payload );

  getPrescriberSpecialty = (payload) => this.invokeAction('/GetPrescriberSpecialty', payload );
  
  addOrUpdatePrescriberSpecialty = (payload) => this.invokeAction('/AddOrUpdatePrescriberSpecialty', payload );
  
  getPrescriptionOrigin = (payload) => this.invokeAction('/GetPrescriptionOrigin', payload );
  
  addOrUpdatePrescriptionOrigin = (payload) => this.invokeAction('/AddOrUpdatePrescriptionOrigin', payload );

  getHospitalClinic = (payload) => this.invokeAction('/GetHospitalClinic', payload );
  
  addOrUpdateHospitalClinic = (payload) => this.invokeAction('/AddOrUpdateHospitalClinic', payload );

  getPathology = (payload) => this.invokeAction('/GetPathology', payload );
  
  addOrUpdatePathology = (payload) => this.invokeAction('/AddOrUpdatePathology', payload );
}
