import styled from 'styled-components';

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px;

    width: 100%;
    min-height: 40px;
    resize: none;
    overflow: hidden;
`

export default TextArea;