import { AbstractService } from './abstractService';

export class ControlledItemsService extends AbstractService {
  constructor() {
    super('/ControlledItems');
  }

  startControledItem = (productReferenceId) =>
    this.invokeAction('/StartControledItem', { productReferenceId });

  addControledItem = (payload) =>
    this.invokeAction('/AddControledItem', payload);

  cancelControlledItem = () => this.invokeAction('/CancelControlledItem');

  getDoctors = (criteria, page, pageCount) =>
    this.invokeAction('/ListDoctorsByCriteria', { criteria, page, pageCount });
}

export default ControlledItemsService;
