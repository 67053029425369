import React, { useEffect, useState } from 'react';

import MessageBusService from '../../../services/MessageBusService';
import { Authorization } from '../authorization/authorization';
import { ButtonComponent } from '../button-component/button-component';
import { ContainerComponent } from '../container/container-component';
import { Field } from '../field/field';
import { ModalComponent } from '../modal-component/modal-component';
import { verifyErrorInContainerDom } from '../validation-errors/validation-errors';

interface INewCashOpen {
  handleClickConfirm: any;
  handleClickCancel: any;
}

interface ICashOpen {
  openingAmount: string;
  description: string;
}

export function NewCashOpen(props: INewCashOpen) {
  const [cashOpen, setCashOpen] = useState<ICashOpen>({
    openingAmount: '0',
    description: '',
  });
  const [touched, setTouched] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const [lastCalledEndPoint, setLastCalledEndPoint] = useState('');

  const handleChangeMoeda = (event, maskedvalue, floatvalue) => {
    setCashOpen({
      ...cashOpen,
      openingAmount: floatvalue,
    });
  };

  const confirmar = () => {
    setTouched(true);

    if (verifyErrorInContainerDom('container-abertura')) return;

    props.handleClickConfirm({
      openingAmount: cashOpen.openingAmount,
      description: cashOpen.description,
    });
  };

  const cancel = () => {
    props.handleClickCancel();
  };

  useEffect(() => {
    const requireAuthCashDesk = MessageBusService.GetInstance().Subscribe(
      'pos.open.requireAuthCashDesk',
      (ch, payload) => {
        setLastCalledEndPoint(payload.lastCalledEndpoint);
        setHasPermission(false);
      }
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(requireAuthCashDesk);
    };
  }, []);

  if (!hasPermission)
    return (
      <Authorization
        title='Autorização de abertura de caixa'
        labelTextUser='Usuário'
        btnAuthorizationTitle='Autorizar abertura'
        handleClickConfirm={confirmar}
        handleClickCancel={cancel}
        authorizeOperator={false}
        lastCalledEndpoint={lastCalledEndPoint}
        page={'ABERTURA_DE_CAIXA'}
      />
    );

  return (
    <ModalComponent title='Abertura de caixa' show onClose={() => cancel()}>
      <ContainerComponent id='container-abertura'>
        <div className='row'>
          <Field
            id='valor-abertura'
            placeholder='Informe o valor inicial'
            labelText='Valor de Abertura'
            type='text'
            typeInput='money'
            showError={false}
            value={cashOpen?.openingAmount}
            onChange={handleChangeMoeda}
            touched={touched}
            autoFocus={true}
          />
        </div>
        <div className='row'>
          <Field
            id='textarea-observacao'
            placeholder='observações'
            labelText='Observações'
            type=''
            typeInput='textarea'
            showError={false}
            value={cashOpen.description}
            onChange={(e) =>
              setCashOpen({
                ...cashOpen,
                description: e.target.value,
              })
            }
            maxLength={100}
            rows={1}
            wrap='hard'
          />
        </div>
        <div className='row'>
          <div
            className='btn-group'
            style={{
              flexDirection: 'row-reverse',
            }}
          >
            <ButtonComponent
              className='btn-b-success'
              title='Confirmar'
              onClick={() => confirmar()}
            />
            <ButtonComponent
              title='Cancelar'
              name='btn-cancel'
              onClick={() => cancel()}
            />
          </div>
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}
