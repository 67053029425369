import styled from 'styled-components';

const StatusConnectionWrapper = styled.div`
  .TitleWrapper {
    margin: 0 6px;
    display: flex;
    align-items: center;
    line-height: 0.8;
    white-space: nowrap;
  }

  span {
    margin-left: 2px;
  }

  .title-online span {
    color: #42b72a;
  }

  .title-offline span {
    color: #fa3e3e;
  }
`;

export default StatusConnectionWrapper;
