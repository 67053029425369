import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Coupon,
  SelectedPaymentOrder,
  PreOrderId,
} from '../../shared/components/modules';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import ItemBox from '../item-box';
import PaymentService from '../../services/paymentService';
import SaleService from '../../services/saleService';
import { OrderService } from '../../services/orderService';
import { CustomerService } from '../../services/customerService';
import AuthorizationService from '../../services/authorizationService';
import * as orderActions from '../../redux/actions/orderAction';
import * as paymentActions from '../../redux/actions/paymentAction';
import { getMessage } from '../../messages';
import {
  shouldShow,
  showCard,
  showWithOthers,
  IDENTIFY_SELLER,
  SALE_FINISH,
  ORDER_DETAILS,
  PURCHASE_HISTORY,
  showWithSearchBar,
} from '../../redux/actions/routerAction';
import { selectCustomer } from '../../redux/actions/custumerAction';
import { SolicitationAuthorizeService } from '../../services/solicitation-authorize.service';
import { payloadToBase64Zip } from '../../shared/utils/util';

const MESSAGE_BUS_CHANNEL_NEED_IDENTIFY_SELLER =
  'pos.farmaciapopular.sellerrequired';

export class CouponContainer extends Component {
  static propTypes = {
    MessageBus: PropTypes.objectOf(PropTypes.any),
    selectedCustomer: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    deleteOrder: PropTypes.func.isRequired,
    paymentAmountRemaing: PropTypes.func.isRequired,
    paymentChangeMoney: PropTypes.func.isRequired,
    payment: PropTypes.shape({
      changeMoney: PropTypes.bool,
      amountRemaning: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    MessageBus: null,
    selectedCustomer: null,
  };

  constructor(props) {
    super(props);

    this.eventFailedReturn = this.eventFailedReturn.bind(this);
    this.eventPaymentChangeUpdated = this.eventPaymentChangeUpdated.bind(this);
    this.eventFinishReturn = this.eventFinishReturn.bind(this);
    this.renderCoupon = this.renderCoupon.bind(this);
    this.renderSubsidy = this.renderSubsidy.bind(this);
    this.handleSaleDoneEvent = this.handleSaleDoneEvent.bind(this);
    this.openBusinessAgreements = this.openBusinessAgreements.bind(this);
    this.setBusinessAgreements = this.setBusinessAgreements.bind(this);
    this.renderBusinessAgreementOption = this.renderBusinessAgreementOption.bind(
      this
    );
    this.handleBusinessAgreementSelect = this.handleBusinessAgreementSelect.bind(
      this
    );
    this.finish = this.finish.bind(this);
    this.paymentsDoneReturn = this.paymentsDoneReturn.bind(this);
    this.openOrderDetail = this.openOrderDetail.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setBusinessAgreementSelected = this.setBusinessAgreementSelected.bind(
      this
    );
    this.customerService = new CustomerService();
    this.orderService = new OrderService();
    this.paymentService = new PaymentService();
    this.saleService = new SaleService();
    this.authorizationService = new AuthorizationService();
    this.solicitationAuthorizeService = new SolicitationAuthorizeService();

    this.handleClientAuthorizationError = this.handleClientAuthorizationError.bind(
      this
    );

    this.handleSendDone = this.handleSendDone.bind(
      this
    );

    this.state = {
      paymentType: '',
      labelButtonPayment: 'PAGAR',
      showModal: false,
      listPay: [],
      paymentsOrder: [],
      selectedCustomer: null,
      orderTotalSubsidy: null,
      discountMessage: null,
      orderDiscount: this.props.order.order.orderDiscount,
      businessAgreements: [],
      selectedBusinessAgreement: '',
      showBusinessAgreements: null,
      totalPaidValue: 0,
      requirePreOrderId: false,
      showProductMessageModal: false,
      showCustomerMessageModal: false,
      productMessage: '',
      customerInfo: null,
      currentBusinessAgreement: '',
    };
  }

  componentWillUnmount() {
    const { MessageBus } = this.props;

    MessageBus.Unsubscribe('pos.order.donePreSale');
  }

  componentDidMount() {
    const { MessageBus } = this.props;

    MessageBus.Subscribe('pos.payment.setTypeFailed', this.eventFailedReturn);
    MessageBus.Subscribe('pos.item.addFailed', this.eventFailedReturn);
    MessageBus.Subscribe(
      'pos.payment.paymentsDoneList',
      this.paymentsDoneReturn
    );
    MessageBus.Subscribe(
      'pos.payment.changeUpdated',
      this.eventPaymentChangeUpdated
    );
    MessageBus.Subscribe('pos.order.deleted', this.eventFinishReturn);
    MessageBus.Subscribe('pos.order.send.done', this.handleSendDone);
    MessageBus.Subscribe('pos.couponcomponent', this.renderCoupon);
    MessageBus.Subscribe('pos.farmaciapopular.applied', this.renderSubsidy);
    MessageBus.Subscribe('sale.done', this.handleSaleDoneEvent);
    MessageBus.Subscribe('pos.order.concluded', this.handleSaleDoneEvent);
    MessageBus.Subscribe(
      'pos.businessAgreements.listed',
      this.setBusinessAgreements
    );
    MessageBus.Subscribe(
      'pos.order.updated',
      this.setBusinessAgreementSelected
    );

    MessageBus.Subscribe('pos.order.donePreSale', this.handleOrderIdentified);
    MessageBus.Subscribe(
      MESSAGE_BUS_CHANNEL_NEED_IDENTIFY_SELLER,
      this.handleNeedIdentifySeller
    );
    MessageBus.Subscribe('pos.product.showInfo', this.handleProductInfo);
    MessageBus.Subscribe('pos.customer.showInfo', this.handleClientInfo);
    MessageBus.Subscribe(
      'pos.customer.showInfoAuthorizationError',
      this.handleClientAuthorizationError
    );
    MessageBus.Subscribe(
      'pos.customer.showInfoAuthorizationSuccess',
      this.handleClientAuthorizationSuccess
    );

    MessageBus.Subscribe(
      'pos.app.showAutorizationOnline',
      this.setShowAutorizationOnline
    );
    MessageBus.Subscribe(
      'pos.exception.error',
      this.handleException
    );
  }

  componentDidUpdate(prevProps, prevState) { }

  handleException = (ch, payload) => {
    var erro = {
      message: payload.message,
      title: payload.title,
      status: 500,
      detail: payload.detail
    }
    this.context.showError(erro)
  };

  handleClientInfo = (ch, payload) => {
    var info = {
      ...payload,
      showAuthorizationForm: false,
      userReferenceId: '',
      password: '',
    };
    this.setState({ showCustomerMessageModal: true, customerInfo: info });
  };

  setShowAutorizationOnline = (ch, payload) => {
    this.props.setShowAutorizationOnline(payload);
  };

  handleClientAuthorizationError = (ch, payload) => {
    var info = this.state.customerInfo;
    info.authenticationMessage = payload.message;
    this.setState({
      customerInfo: info,
    });
  };
  handleClientAuthorizationSuccess = (ch, payload) => {
    this.closeMessageModal();
  };

  handleClientAuthorityChange = (e) => {
    var info = this.state.customerInfo;
    info[e.target.id] = e.target.value;
    this.setState({
      customerInfo: info,
    });
  };

  handleProductInfo = (ch, payload) => {
    this.setState({
      showProductMessageModal: true,
      productMessage: payload.message,
    });
  };
  handleToggleAuthorizeForm = () => {
    var customerInfo = this.state.customerInfo;
    customerInfo.showAuthorizationForm = !customerInfo.showAuthorizationForm;
    this.setState({ customerInfo });
  };

  handleAuthorizeSale = (e) => {
    if (e.alternativeCredentials) {
      this.authorizationService.Authorize({
        credentials: e.alternativeCredentials,
      });
    } else {
      const { password, userReferenceId } = this.state.customerInfo;

      const credentials = {
        username: userReferenceId,
        password: password,
      };

      payloadToBase64Zip(JSON.stringify(credentials)).then((token) => {
        this.authorizationService.Authorize({ credentials: token });
      });
    }
  };

  handleNeedIdentifySeller = (ch, payload) => {
    this.context.showMessage(payload);
  };

  handleOrderIdentified = (channel, payload) => {
    this.setState({
      requirePreOrderId: false,
    });
    if (!this.props.order.seller) {
      this.props.showIdentifySeller();
      return;
    }
    const alternate = payload.paymentEndpoint;
    if (alternate) {
      this.paymentService
        .startPayment(alternate)
        .catch((error) => this.context.showError(error));
      return;
    }
    this.orderService
      .donePreOrder()
      .catch((error) => this.context.showError(error));
  };

  setBusinessAgreementSelected = (ch, payload) => {
    const param = payload.customer && {
      name: payload.customer.name,
      referenceId: payload.customer.referenceId,
      selectedBusinessAgreement:
        payload.customer && payload.customer.businessAgreementName,
    };

    this.handleCurrentBusinessAgreement(payload);

    this.props.selectCustomer(param);
  };

  handleCurrentBusinessAgreement = (payload) => {
    if (this.state.orderTotalSubsidy) return;

    let businessAgreement = '';

    if (payload.infoPbm) businessAgreement = `Venda ${payload.infoPbm?.pbmDescription}`;
    if (payload.infoLinxConecta) businessAgreement = 'Venda Linx Conecta';
    if (payload.objectProInfo?.isOrderObjectPro) businessAgreement = 'Venda Objectpro';

    this.setState({ currentBusinessAgreement: businessAgreement });
  }

  enableSelectionPreOrder() {
    this.props.showButtonPaymentPreOrder();
  }

  deleteOrder() {
    this.props.deleteOrder();
  }

  paymentsDoneReturn(canal, payload) {
    let totalPaidValue = 0;

    payload.paymentsOrder.forEach((p) => {
      totalPaidValue += p.paymentType !== 99 ? p.amount : 0;
    });

    this.setState({
      paymentsOrder: payload.paymentsOrder,
      totalPaidValue: totalPaidValue,
    });

    this.props.paymentAmountRemaing(payload.amount);
  }

  eventPaymentChangeUpdated(canal, payload) {
    if (payload.amount > 0) {
      this.props.paymentChangeMoney(true);
    }
    this.props.paymentAmountRemaing(payload.amount);
  }

  eventFailedReturn(canal, payload) {
    switch (payload.reason) {
      case 'Not Found':
        this.context.showMessage('Produto não encontrado ou inativo!');
        break;
      case 'Product Not Available':
        this.context.showMessage('Produto sem estoque!');
        break;
      case 'Product Not Found':
        this.context.showMessage('Produto não encontrado ou inativo!');
        break;
      default:
        this.context.showMessage(payload.reason);
    }
  }

  handleClickDonePreOrder = () => {
    this.showOrderValidations();
  };

  handleClickListPayment = (e) => {
    if (e) {
      e.preventDefault();
    }

    const instructions =
      this.props.selectedBusinessAgreement &&
      this.props.selectedBusinessAgreement.instructions;
    if (instructions) {
      this.context.showMessage(
        instructions,
        'Instruções do Convênio',
        this.finish
      );
    } else {
      this.showOrderValidations();
    }
  };

  showOrderValidations() {
    this.saleService.orderValidationShow(
      this.props.order.order.orderId
    );
  }

  finish() {
    //TODO tentar descobrir o motivo de disparar 3 vezes esse evento com o order no props vazio
    //Por algum motivo esse finish estava sendo disparado várias vezes e vinha com o order vazio em todas, menos em 1, esse if impede que dispare o startPayment sem ter o order correto com valor no total
    if (this.props.order.order.orderTotal === 0)
      return;

    if (this.props.fiscalFlow.onlineFiscalFlow === false && window.desktopApp) {
      this.context.showMessage(
        'Falha de conexão com Fiscal Flow favor verificar.'
      );
    } else {
      this.paymentService
        .startPayment(this.props.order.order.paymentEndpoint)
        .catch((error) => this.context.showError(error));
    }
  }

  handleClickCustomerSelection = (e) => {
    if (e) e.preventDefault();

    this.customerService
      .startCustomerSelection()
      .catch((error) => this.context.showError(error));
  };

  handlePreOrderDone() {
    if (this.props.order.order.recoveryDesc && !this.props.order.seller) {
      this.props.showIdentifySeller();
      return;
    }
    if (this.props.order.order.recoveryDesc) {
      this.handleOrderIdentified(null, this.props.order.order);
      return;
    }
    this.setState({
      requirePreOrderId: true,
    });
  }

  handleOrderDone() {
    this.finish();
  }

  handleSendDone = (canal, payload) => {
    if (this.props.posType == "1")
      this.handlePreOrderDone();
    else
      this.handleOrderDone();
  }

  finishPreOrder = (recoveryDesc) => {
    this.orderService.setRecoveryDesc(recoveryDesc);
  };

  handleFormPaymentCoupon = (objPay) => {
    this.setState({
      showModal: true,
      listPay: objPay,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      listPay: [],
    });
  };

  handlerCancelTef = (data) => {
    this.setState({ showModal: false, listPay: [] });

    data['namePay'] = this.state.listPay.namePay;
    data['paymentType'] = this.state.listPay.paymentType;

    this.paymentService
      .undoPayment(data)
      .catch((error) => this.context.showError(error));
  };

  eventFinishReturn() {
    this.setState({
      listPay: [],
      paymentsOrder: [],
      orderTotalSubsidy: null,
      selectedBusinessAgreement: '',
      businessAgreements: null,
      totalPaidValue: 0,
    });
  }

  handleClickStartFill = () => {
    this.customerService.startFill();
  };

  renderCoupon(channel, payload) {
    this.props.paymentAmountRemaing(payload.remainingAmount);
  }

  renderSubsidy(channel, payload) {
    this.setState({
      orderTotalSubsidy: payload.orderTotalSubsidy,
      currentBusinessAgreement: 'Venda Farmacia Popular'
    });
  }

  handleKeyDown(e, id) {
    switch (e.key) {
      case 'Escape':
        this.setState({ showBusinessAgreements: false });
        break;
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        this.handleBusinessAgreementSelect(id);
        break;
      case 'Tab':
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          if (e.target.previousSibling) e.target.previousSibling.focus();
          else
            e.target.parentElement.children[
              e.target.parentElement.children.length - 1
            ].focus();
        } else {
          if (e.target.nextSibling) e.target.nextSibling.focus();
          else e.target.parentElement.children[0].focus();
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        if (e.target.nextSibling) e.target.nextSibling.focus();
        else e.target.parentElement.children[0].focus();
        break;
      case 'ArrowUp':
        e.preventDefault();
        e.stopPropagation();
        if (e.target.previousSibling) e.target.previousSibling.focus();
        else
          e.target.parentElement.children[
            e.target.parentElement.children.length - 1
          ].focus();
        break;
      default:
        break;
    }
  }

  renderBusinessAgreementOption(b, idx) {
    return (
      <div
        key={`option-business-${idx}`}
        className='business-option'
        onClick={() =>
          this.handleBusinessAgreementSelect(b.businessAgreementReferenceId)
        }
        onKeyDown={(e) => this.handleKeyDown(e, b.businessAgreementReferenceId)}
        tabIndex={idx}
      >
        <span>{b.businessAgreementName}</span>
      </div>
    );
  }

  handleSaleDoneEvent() {
    this.setState({
      listPay: [],
      paymentsOrder: [],
      orderTotalSubsidy: null,
      businessAgreements: null,
      selectedBusinessAgreement: '',
      totalPaidValue: 0,
      requirePreOrderId: false,
      currentBusinessAgreement: '',
    });
    this.props.paymentChangeMoney(false);
  }

  openBusinessAgreements(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState(
      {
        showBusinessAgreements:
          this.state.businessAgreements && !this.state.showBusinessAgreements,
      },
      this.focusBusinessAgreementList.bind(this)
    );
  }

  focusBusinessAgreementList() {
    const menuArea = document.querySelector('.menu-area-b-agreement');
    if (menuArea) {
      menuArea.children[0].focus();
      menuArea.addEventListener('focusout', () => {
        // the timeout is needed to check if any of the convênio options are focused.
        // without the timeout will just return false everytime
        setTimeout(() => {
          document.activeElement.className !== 'business-option' &&
            this.setState({ showBusinessAgreements: false });
        }, 0);
      });
    }
  }

  setBusinessAgreements(ch, payload) {
    this.setState({
      businessAgreements: payload,
    });
  }

  handleBusinessAgreementSelect(id) {
    this.orderService.businessAgreementSelected(id);
  }

  openOrderDetail(event) {
    const x = event.clientX;
    const y = event.clientY;
    this.props.showOrderDetail(x, y);
  }

  handlePurchaseHistory = () => {
    if (this.props.saleFinishIsActive) return;

    this.props.showPurchaseHistory();
  };

  closeMessageModal = () => {
    this.setState({
      showProductMessageModal: false,
      showCustomerMessageModal: false,
      customerInfo: null,
    });
  };

  render() {
    return (
      <Fragment>
        <Coupon
          {...this.props}
          {...this.state}
          selectedCustomer={this.props.selectedCustomer}
          handleClickListPayment={this.handleClickListPayment}
          handleClickDonePreOrder={this.handleClickDonePreOrder}
          handleClickCustomerSelection={this.handleClickCustomerSelection}
          openBusinessAgreements={this.openBusinessAgreements}
          changeMoney={this.props.payment.changeMoney}
          amountRemaning={this.props.payment.amountRemaning}
          formsPayment={this.state.paymentsOrder}
          totalPaidValue={this.state.totalPaidValue}
          handleFormPaymentCoupon={this.handleFormPaymentCoupon}
          hasBusinessAgreement={
            this.state.businessAgreements &&
            this.state.businessAgreements.length > 0
          }
          businessAgreementList={this.state.businessAgreements}
          selectedBusinessAgreement={
            this.props.selectedCustomer?.selectedBusinessAgreement ||
            this.state.selectedBusinessAgreement
          }
          showBusinessAgreements={this.state.showBusinessAgreements}
          renderBusinessAgreementOption={this.renderBusinessAgreementOption}
          openOrderDetail={this.openOrderDetail}
          handlePurchaseHistory={this.handlePurchaseHistory}
          closeMessageModal={this.closeMessageModal}
          authorizeSale={this.handleAuthorizeSale}
          toggleAuthorizeForm={this.handleToggleAuthorizeForm}
          handleClientAuthorityChange={this.handleClientAuthorityChange}
          posType={this.props.posType}
          currentBusinessAgreement={this.state.currentBusinessAgreement}
          isOnline={this.props.isOnline}
        >
          <ItemBox />
        </Coupon>
        <SelectedPaymentOrder
          showModal={this.state.showModal}
          listPayOrder={this.state.listPay}
          handleCloseModal={this.handleCloseModal}
          handlerCancelTef={this.handlerCancelTef}
        />
        {this.state.requirePreOrderId && (
          <PreOrderId
            finishPreOrder={this.finishPreOrder}
            handleClose={() => {
              this.setState({ requirePreOrderId: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order,
  payment: state.payment,
  selectedCustomer: state.customer.selectedCustomer,
  saleFinishIsActive: shouldShow(state, SALE_FINISH),
  fiscalFlow: state.fiscalFlow,
  posType: state.posType.posType,
  isOnline: state.pdvContingency.isOnline,
  retailer: state.retailer,
  user: state.util.user
});

const mapDispatchToProps = (dispatch) => ({
  selectCustomer: (selectedCustomer) =>
    dispatch(selectCustomer(selectedCustomer)),
  deleteOrder: () => dispatch(orderActions.deleteOrder()),
  paymentChangeMoney: (payload) =>
    dispatch(paymentActions.paymentChangeMoney(payload)),
  paymentAmountRemaing: (param) =>
    dispatch(paymentActions.paymentAmountRemaing(param)),
  showIdentifySeller: () =>
    dispatch(showCard(IDENTIFY_SELLER, null, false, true)),
  showOrderDetail: (x, y) => dispatch(showWithOthers(ORDER_DETAILS, { x, y })),
  showPurchaseHistory: () => dispatch(showWithSearchBar(PURCHASE_HISTORY)),
  setShowAutorizationOnline: (showAutorizationOnline) =>
    dispatch(orderActions.setShowAutorizationOnline(showAutorizationOnline)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CouponContainer)));
