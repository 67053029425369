import styled from 'styled-components';

export const AbasGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`
export const Span = styled.span`
    background: #E9EFF5;
    border: 2px solid #E9EFF5;
    box-sizing: border-box;
    border-radius: 6px;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 45px;

    .title {
        font-family: Dosis;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #5B2E90;
    }

    &.selected {
        background: #FFFFFF;

    }

    &:hover {
        cursor: pointer;
    }
`