import React from 'react';
import LabelTitleValue from '../label-title-value';
import StatusConnectionWrapper from './style';

const StatusConnection = props => (
  <StatusConnectionWrapper>
    {props.visible === true && (<LabelTitleValue title={props.title} value={props.isOnline} {...props} />)}
  </StatusConnectionWrapper>);

export default StatusConnection;
