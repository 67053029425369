import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '../button-component';
import { Container, ContainerBtn, Hr, Row, SpanTitle } from './style';
import MessageBusService from '../../../services/MessageBusService';
import { ISolicitationContract } from '../../interfaces/solicitation-contract';
import { SolicitationAuthorizeService } from '../../../services/solicitation-authorize.service';
import { OperationEnum } from '../../enums/operation';
import { ISolicitationSangriaDespesaContract } from '../../interfaces/solicitation-sangria-despesa-contract';
import { IAcceptedSolicitation } from '../../interfaces/accepted-solicitation';
import AuthService from '../../../services/authService';
import { useSelector } from 'react-redux';
import ModalLiberacao from './modal-liberacao/modal-liberacao';
import { showToast } from '../../utils/util';
import { ISolicitationLiberacaoLimiteContract } from '../../interfaces/solicitation-liberacao-limite.contract';
import { ISolicitationCoberturaOfertaContract } from '../../interfaces/solicitation-cobertura-oferta-contract';
import { ISolictationExcluirPreVendaContract } from '../../interfaces/solicitation-excluir-pre-venda.contract';
import { ISolicitationProdutoSemEstoqueContract } from '../../interfaces/solicitation-produto-sem-estoque.contract';
import { ISolicitationLoteRecenteContract } from '../../interfaces/solicitation-lote-recente.contract';

interface IAuthorizationOnline {
  operation: OperationEnum;
  solicitation?:
  | ISolicitationSangriaDespesaContract
  | ISolicitationLiberacaoLimiteContract
  | ISolicitationCoberturaOfertaContract
  | ISolictationExcluirPreVendaContract
  | ISolicitationProdutoSemEstoqueContract
  | ISolicitationLoteRecenteContract;
  lastCalledEndpoint?: string;
  handleClickConfirm: (solicitation: IAcceptedSolicitation) => any;
  handleClickCancel: (solicitation: ISolicitationContract) => any;
  validateFields: () => boolean;
  closeModal: () => any;
}

export default function AuthorizationOnline(props: IAuthorizationOnline) {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [solicitation, setSolicitation] = useState<ISolicitationContract>();

  const solicitationAuthorizeService = new SolicitationAuthorizeService();

  const lastSentBody = useSelector((state) => (state as any).util.lastSentBody);

  useEffect(() => {
    const createSolicitationSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.app.createSolicitation.success',
      (response: any, payload: any) =>
        onCreateSolicitationSuccess(response, payload)
    );
    const createSolicitationError = MessageBusService.GetInstance().Subscribe(
      'pos.app.createSolicitation.error',
      callErrorMessage
    );

    const authorizeSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.app.changestatus.accepted',
      authorizationSuccess
    );

    const authorizeDenied = MessageBusService.GetInstance().Subscribe(
      'pos.app.changestatus.denied',
      () => authorizationDenied()
    );

    const cancelSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.app.cancel.success',
      () => operationCancelSuccess()
    );

    const cancelError = MessageBusService.GetInstance().Subscribe(
      'pos.app.cancel.error',
      callErrorMessage
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(createSolicitationSuccess);
      MessageBusService.GetInstance().Unsubscribe(createSolicitationError);
      MessageBusService.GetInstance().Unsubscribe(authorizeSuccess);
      MessageBusService.GetInstance().Unsubscribe(authorizeDenied);
      MessageBusService.GetInstance().Unsubscribe(cancelSuccess);
      MessageBusService.GetInstance().Unsubscribe(cancelError);
    };
  }, []);

  const solicitarLiberacaoOnline = () => {
    if (props.validateFields() === false) return;

    if (props.operation === OperationEnum.SangriaDespesa) {
      solicitationAuthorizeService.startSolicitationSangriaDespesa(
        props.solicitation as ISolicitationSangriaDespesaContract
      );
    }

    if (props.operation === OperationEnum.LiberacaoLimite) {
      solicitationAuthorizeService.startSolicitationLiberacaoLimite(
        props.solicitation as ISolicitationLiberacaoLimiteContract
      );
    }

    if (props.operation === OperationEnum.CoberturaOferta) {
      solicitationAuthorizeService.startSolicitationCoberturaOferta(
        props.solicitation as ISolicitationCoberturaOfertaContract
      );
    }

    if (props.operation === OperationEnum.ExcluirPreVenda) {
      solicitationAuthorizeService.startSolicitationExcluirPreVenda(
        props.solicitation as ISolictationExcluirPreVendaContract
      );
    }

    if (props.operation === OperationEnum.ProdutoSemEstoque) {
      solicitationAuthorizeService.startSolicitationProdutoSemEstoque(
        props.solicitation as ISolicitationProdutoSemEstoqueContract
      );
    }

    if (props.operation === OperationEnum.LoteMaisRecente) {
      solicitationAuthorizeService.startSolicitationLoteRecente(
        props.solicitation as ISolicitationLoteRecenteContract
      );
    }

  };

  const authorizationSuccess = (response, payload: IAcceptedSolicitation) => {
    setShowLoader(false);

    if (props.closeModal) props.closeModal();

    if (
      payload.solicitation.operation === OperationEnum.LiberacaoLimite ||
      payload.solicitation.operation === OperationEnum.CoberturaOferta ||
      payload.solicitation.operation === OperationEnum.ProdutoSemEstoque ||
      payload.solicitation.operation === OperationEnum.LoteMaisRecente
    ) {
      props.handleClickConfirm(payload);

      return;
    }

    AuthService.authorizationSuperUserWithLastRequestAndCredentialsGenerated(
      payload.alternativeCredentials,
      lastSentBody,
      props.lastCalledEndpoint,
      props.handleClickCancel
    );
  };

  const authorizationDenied = () => {
    setShowLoader(false);

    showToast({
      type: 2,
      title: 'Atenção!',
      html: 'Autorização para esse procedimento foi negada.',
      hideAfterMilliseconds: 5000,
    });
  };

  const operationCancelSuccess = () => {
    setShowLoader(false);

    showToast({
      type: 1,
      title: 'Atenção!',
      html: 'Solicitação cancelada.',
      hideAfterMilliseconds: 5000,
    });
  };

  const callErrorMessage = (response, payload) => {
    props.handleClickCancel(payload);
  };

  const onCreateSolicitationSuccess = (response, payload) => {
    setShowLoader(true);
    setSolicitation(payload);
  };

  const callCancellOperion = async () => {
    await solicitationAuthorizeService.cancelSolicitation(
      solicitation?.id as any
    );
  };

  if (showLoader) {
    return <ModalLiberacao onCancel={() => callCancellOperion()} />;
  }

  return (
    <Container>
      <ContainerBtn>
        <ButtonComponent
          className='btn-b-success solicitacao-online'
          title={'Solicitar liberação on-line'}
          onClick={solicitarLiberacaoOnline}
          style={{
            background: '#6E35B1',
            width: '218px',
          }}
          propsLabel={{
            style: {
              fontFamily: 'Dosis',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
              color: '#FFFFFF',
              textTransform: 'uppercase',
            },
          }}
        />
      </ContainerBtn>

      <Row>
        <Hr className='solicitacao-line' />
        <SpanTitle className='solicitacao-title-line'>
          {' '}
          ou senha do responsável{' '}
        </SpanTitle>
        <Hr className='solicitacao-line' />
      </Row>
    </Container>
  );
}
