import AbstractService from './abstractService';

class OfflineService extends AbstractService {
  static _instance = new OfflineService();

  constructor() {
    super('/Contingency');
  }

  change = (posType, isOnline) =>
    this.invokeAction('/change', { posType, isOnline }, false);

  static GetInstance() {
    return (
      OfflineService._instance ||
      (OfflineService._instance = new OfflineService())
    );
  }
}

export default OfflineService;
