import styled from 'styled-components';
import theme from '../../../themes/default';

export const FarmaciaPopularWrapper = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  h3 {
    color: ${theme.palette.color[1]}
    flex: 0 0 90px;
  }

  .dispensation {
    margin-bottom: 30px;
  }

  button {
    float: right;
    height: 50px;
    width: 100px;
  }

  .actions {
    margin-top: 20px;
    justify-content: flex-end;
  }

  .table {
    flex: 0 0 auto;
    margin-bottom: 20px;
  }
  .input__container
  {
    box-shadow: none;
    position: static
    text-align: left;
    width: auto;
    height: auto;
  }
  .input__container input
  {
    padding: 0 10px;
    width: 100%;
    height: 30px;
    border: 1px solid #d3d1d1;
    border-radius: 3px;
    font-size: 16px;
    box-sizing: border-box;
    background: #fff;
    margin: 0px;
    height: 28px !important;
  }
  table tr {
    border: none !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  table td, table th
  {
    margin: 0px !important;
    font-size: 12px;
    text-align: center;
    width: 100px;
  }
  table th
  {
    border-top: none;
    border-bottom: 1px dashed #ccc !important;
    padding: 0px 2px !important;

  }
  table td
  {
    padding: 5px 2px !important;
    border-bottom: 1px solid #dee2e6 !important;
  }
  table th:first-child, table td:first-child
  {
    text-align: left;
    width: unset;
  }
  table tr:last-child td
  {
    border-bottom: none !important;
  }
  table td input {
    width: 100%;
    border: 1px solid #d3d1d1;
    border-radius: 3px;
    font-size: 12px;
    padding: 0px 10px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default FarmaciaPopularWrapper;


export const FarmaciaPopularModalMessageWrapper = styled.div`
  h1{
    padding: 10px 0px;
    margin: 0px;
    font-size: 26px;
    display: block;
    background-color: #f8f8f8;
    border-bottom: 1px dotted #ccc;
  }
  h1 i
  {
    vertical-align: middle;
    font-size: 50px;
    color: #FED859;
  }
  p
  {
    text-align: left;
    padding: 20px;
  }
`