import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input';
import { Card, Button } from '../modules';
import { PaymentMoneyWrapper, PaymentMoneyInput, InputWrapper } from './style';
import { SlideToggle } from '../slide-toggle/slide-toggle';

const MAX_VALUE_FOR_MONEY = 999999.99

class PaymentMoney extends Component {
  manualChangedValueDiscount = false;

  constructor(props) {
    super(props);
    
    this.state = {
      pendingAmout: this.props.amount,
      amountDelivered: this.props.amount,
      amountDiscount: this.props.amount,
      pendingAmountNextPayment: 0,
      change: 0,
      partialSale: false,
    };
  }

  handleConfirm = async () => {
    const { amount, totalMoney, amountDiscount, pendingAmout, amountDelivered } = this.state;
    const invalidAmountInputValue = amountDelivered > MAX_VALUE_FOR_MONEY || totalMoney > amount || amountDiscount > pendingAmout || amountDelivered <= 0;

    if (!invalidAmountInputValue) {
      this.props.handleClickPay({ amountPayment: this.state.amountDelivered, cash: this.state.amountDiscount });
    }
  }

  handleKeyDown = (e) => {
    e.key === 'Enter' && this.handleConfirm();
  }

  componentDidMount() {
    this.amountInput.theInput.focus();
  }

  onChangePartialSale = (e) => {
    const isPartial = e.target.checked;

    if (!isPartial) {
      const pendingAmount = this.state.pendingAmout - this.state.amountDelivered;
      this.setState({
        amountDiscount: this.state.pendingAmout,
        pendingAmountNextPayment: pendingAmount < 0 ? 0 : pendingAmount,
        change: this.state.amountDelivered - this.state.pendingAmout 
      });

      this.manualChangedValueDiscount = false;
    }

    this.setState({
      partialSale: isPartial
    });
  }

  refreshValues = (formatedValue, value, element) => {
    const elId = element.target.id;

    if (!this.manualChangedValueDiscount && elId === 'inputDiscountSale') {
      this.manualChangedValueDiscount = true;
    }
    
    const { partialSale, pendingAmout } = this.state;

    const amountDelivered = this.amountInput.state.value;

    let amountDiscount = this.discountInput.state.value;

    if ((!partialSale || !this.manualChangedValueDiscount || amountDelivered < amountDiscount) && elId === 'inputMoneyClient') {
      amountDiscount = amountDelivered;
      
      amountDiscount = amountDiscount > pendingAmout ? pendingAmout : amountDiscount;
    }

    const pendingAmountNextPayment = pendingAmout - amountDiscount;

    let change = amountDelivered - amountDiscount;
    
    change = change > 0 ? change : 0;

    this.setState({
      amountDelivered,
      amountDiscount,
      pendingAmountNextPayment,
      change
    });
  }

  formatValue(value) {
    if (value || value === 0) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }

  render() {
    const {
      pendingAmout,
      amountDelivered,
      amountDiscount,
      pendingAmountNextPayment,
      change
    } = this.state;

    return (
      <Card
        title="Dinheiro"
        subTitle="Informe o valor"
        handleClose={this.props.handleClose}
        urlIcone="./assets/images/payment-types/dinheiro.svg"
        closeEnable
      >
        <PaymentMoneyWrapper>
          <div>
            <span>TOTAL A RECEBER:&nbsp;</span>
            <strong>
              {this.formatValue(pendingAmout)}
            </strong>
          </div>
          <PaymentMoneyInput>
            <InputWrapper>
              <fieldset>
                <label>Valor entregue pelo cliente</label>
                <CurrencyInput
                  id="inputMoneyClient"
                  ref={ref => this.amountInput = ref}
                  value={amountDelivered}
                  selectAllOnFocus
                  onKeyDown={this.handleKeyDown}
                  onChange={this.refreshValues}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </fieldset>
              <div className="slideToggle">
                <label>Pagamento Parcial &nbsp;</label>
                <SlideToggle
                  ref={(ref) => {this.slideRef = ref;}}
                  onChange={this.onChangePartialSale}
                  checked={this.state.partialSale}
                />
              </div>
              <fieldset>
                <label>Valor a descontar na venda</label>
                <CurrencyInput
                  id="inputDiscountSale"
                  value={amountDiscount}
                  selectAllOnFocus
                  ref={ref => this.discountInput = ref}
                  onKeyDown={this.handleKeyDown}
                  disabled={!this.state.partialSale}
                  onChange={this.refreshValues}
                  decimalSeparator=","
                  thousandSeparator="."
                />
                {(amountDiscount > amountDelivered) && (
                  <small className="error">
                    Valor maior que o entregue pelo cliente
                  </small>
                )}
                {(amountDiscount > pendingAmout) && (
                  <small className="error">
                    Valor maior que o total a receber
                  </small>
                )}
                {(amountDelivered <= 0) && (
                  <small className="error">
                    O valor a descontar da venda deve ser maior que zero
                  </small>
                )}
                {(amountDelivered > MAX_VALUE_FOR_MONEY) && (
                  <small className="error">
                    O valor não pode ser maior que {this.formatValue(MAX_VALUE_FOR_MONEY)}
                  </small>
                )}
              </fieldset>
            </InputWrapper>

            <div className="box">
              TROCO
              <span>
                {this.formatValue(change)}
              </span>
              <div className="leftover">
                <div>
                  Restante a Receber:
                  <strong>
                    {this.formatValue(pendingAmountNextPayment)}
                  </strong>
                </div>
              </div>
            </div>
          </PaymentMoneyInput>

          <div>
            <div className="wrapper-btn">
              <Button
                type="button"
                className="btn-default btn-purple"
                disabled={amountDelivered > MAX_VALUE_FOR_MONEY || amountDiscount > amountDelivered || amountDiscount > pendingAmout || amountDelivered <= 0}
                handleClick={this.handleConfirm}
              >
                CONFIRMAR
              </Button>
            </div>
          </div>
        </PaymentMoneyWrapper>
      </Card>
    );
  }
}

export default PaymentMoney;
