export const CONFIG_DELIVERY = 'DeliveryAvailable';
export const CONFIG_LINX_CONECTA = 'LINX_CONECTA';
export const CONFIG_DTEF = 'DTEF';
export const CONFIG_PBM_DTEF = 'PBM_DTEF';
export const CONFIG_PBM_FARMACIA_POPULAR = 'PBM_FARMACIA_POPULAR';
export const CONFIG_PBM_EPHARMA = 'PBM_EPHARMA';
export const CONFIG_PBM_FUNCIONAL = 'PBM_FUNCIONAL';
export const CONFIG_PBM_SEVEN = 'PBM_SEVEN';
export const CONFIG_TRNCENTRE = 'TRNCENTRE';
export const CONFIG_PBM_ORIZON = 'PBM_ORIZON';
