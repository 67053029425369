
import MessageBusService from '../../services/MessageBusService';

const MachineCommunicator = {
  sendToMachine: (chanel, data) => {
    if (window.desktopApp && window.desktopApp.publish) {
      window.desktopApp.publish(chanel, data);
    }
  },
  initialize: () => {
    const instance = MessageBusService.GetInstance();

    window.serviceBus = instance;
    instance.Subscribe('machine', MachineCommunicator.sendToMachine.bind(this));
  },
};

export default MachineCommunicator;
