import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const Container = styled.div`
  height: 100%;

  td {
    i {
      color: ${theme.palette.primary};
      font-size: 16px;
    }
  }
`;

export const Form = styled.form`
  display: flex;

  font-family: 'Ubuntu', 'Dosis';

  button {
    margin-left: ${theme.metrics.baseMargin}px !important;
  }
`;

export const Input = styled.input`
  font-family: 'Ubuntu', 'Dosis' !important;
  font-size: 14px;
  border: 1px solid ${theme.palette.buttonBorder};
  border-radius: ${theme.metrics.borderRadius}px;
  padding: 0 ${theme.metrics.basePadding * 2}px;
  width: 100%;
  height: 40px;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    box-shadow: 0 0 8px ${theme.palette.primary};
  }

  &::placeholder {
    color: ${theme.palette.grayMedium};
  }

  outline: 0;
`;

export const EmptyData = styled.div`
  flex: 1;
  height: calc(100% - 59px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: ${theme.metrics.baseMargin * 2}px;
  }
`;

export const Select = styled.div`

  background-color: #eee;
  border-radius: 0;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 5px; 
  padding-right: 20px;
  &.title-select {
    white-space: nowrap;
  }
  &.disabled {
    color: #ebebe4 !important;
  }

  &:focus {
    outline: none;
  }

  .icon-align {
    display: flex;
  }

  .menu-area {
    cursor: pointer;
    position: absolute;
    top: 95px;
    width: auto;
    display: grid;
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 15px;
    z-index: 500;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 auto;
      font-weight: bold;
      height: 40px;
      line-height: 42px;
      padding: 0 10px;      
      &:focus,
      &:hover {
        background-color: #ffb200;
      }
    }
  }
`;

export const OptionsRender = styled.div`
  .container{
    padding-top: 5px; 
    padding-left: 0px; 
    padding-right: 0px; 
    max-width: 100%;
  }
  .options{   
    margin: 10px 0px 10px 0px;
    padding-left: 5px; 
    display: flex;
    padding-right: 5px; 
    border-radius: 4px;
    border: 1px solid #C0C0C0;
    background-color: #eee;
  }
  .stockOptions{       
    display: flex; 
    width: 210px;   
  }
  .ruleOptions{       
    display: flex;    
  }
  .anchor{    
    padding-left: 0px; 
    padding-right: 5px; 
    color: #1E0FF2;
    text-decoration:underline;       
    &:hover,
    &:focus {
      cursor: pointer;
      color: black;
      font-weight: bold;
      background-color: #ffb200;
      tab-index: 5;
    }
  }
`;