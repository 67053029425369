import styled from 'styled-components';
import theme from '../../../themes/theme2';

export const IncreasePriceModalWrapper = styled.div`
  .increaseprice-modal {
    position: relative;

    .title {
      justify-content: start !important;
      border-bottom: 1px solid #d3d1d1;
      padding-bottom: 10px;
    }

    div {
      padding: 0;
    }

    .increaseprice-modal-form {
      padding: 0 3px;
    }

    .increaseprice-modal-discount {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;

      .increaseprice-modal-discount-items {
        display: flex;
        flex-direction: column;
        width: 48%;
        position: relative;

        input {
          line-height: 1.5px;
          height: calc(1.5em + 0.55rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          font-family: 'Ubuntu', 'Dosis';
        }

        #inputDiscountModal,
        #inputTotal {
          text-align: right;

          &:focus {
            border-color: #ced4da !important;
            outline: none;
            box-shadow: 0 0 5px 0.05rem #5b2e90 !important;
          }
        }

        .red-color {
          color: red;
        }
      }

      .code-user {
        width: 15%;
      }

      .name-user {
        width: 52%;
      }

      .password-user {
        width: 25%;
      }
    }

    #autorization-online.increaseprice-modal-discount {
      flex-direction: column;

      .increaseprice-modal-discount-items {
        width: 100%;
      }
    }
  }

  .status-reason {
    background: ${theme.palette.primary};
    margin-top: 3px;
    height: 3px;
    width: ${(props) => props.status};
  }

  .counter {
    color: ${theme.palette.primary};
    position: absolute;
    right: 4%;
    margin-top: 5px;
    font-size: 0.75rem;
    font-weight: bold;
  }

  .limit {
    color: red !important;
  }

  .responsible {
    border-bottom: 1px solid #d3d1d1;
    margin-top: 30px;
    margin-bottom: 0;
  }

  button {
    margin: 10px 0;
  }

  .user-without-permission {
    position: absolute;
    color: red;
    margin-top: 10px;
    right: 4%;
    bottom: 10px;
  }

  .input-message-error {
    position: absolute;
    top: 67px;
    color: red;
    font-size: 13px;
  }

  .form-control {
    &:focus {
      border-color: #ced4da !important;
      outline: none;
      box-shadow: 0 0 5px 0.05rem #5b2e90 !important;
    }
  }
`;
