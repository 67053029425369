import React from 'react';
import QuantityItemButtonWrapper from './style';
import Input from '../input';
import getShortcut, { QUANTITY_INPUT_FOCUS } from '../../../keyboardShortcuts.js';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';

class QuantityItemButton extends React.Component {
  constructor(props) {
    super(props);

    this.focus = this.focus.bind(this);
  }

  focus() {
    this.qtdRef.selectText();
  }

  render() {
    const { onBlur, quantityCurrent, message, onChange } = this.props;
    return (
      <QuantityItemButtonWrapper>
        <div className="container-teste">
          <ShortcutHint activateShortcut={this.focus} shortcut={getShortcut(QUANTITY_INPUT_FOCUS)}/>
          <Input
            pattern={'[0-9]+'}
            maxLength="10"
            ref={(ref) => this.qtdRef = ref}
            value={quantityCurrent}
            onChange={onChange}
            onBlur={onBlur}
          />
          <span className="warning-message">{message}</span>
        </div>
      </QuantityItemButtonWrapper>
    )
  }
}

export default QuantityItemButton;
