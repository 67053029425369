import React from 'react';
import { Form } from 'react-bootstrap';

import Card from '../card';
import { Button, SlideToggle } from '../modules';
import { Buttons, CepHeader, DeliveryCardContainer } from './styles';

export class DeliveryInfo extends React.Component {
  constructor(props) {
    super(props);

    this.confirmBtnRef = React.createRef();
    this.numberRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cepCallback) {
      this.numberRef.current.focus();
    }
  }

  render() {
    const { data, handleClose, handleConfirmSave, handleConfirmWhithoutSave, handleChange, isOnline } = this.props;

    return (
      <DeliveryCardContainer>
        <Card title="Informações da entrega" handleClose={handleClose} closeEnable>
          <Form>
            <Form.Row>
              <Form.Group className="col-xs-8 col-lg-6" controlId="name">
                <Form.Label>Nome do cliente</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  autoFocus={!this.props.data.cepCallback}
                  value={data && data.deliveryCustomer && data.deliveryCustomer.name}
                  maxLength="150"
                  disabled={
                    data && data.deliveryCustomer && data.deliveryCustomer.customerAutomaticallyLoaded ? 'disabled' : ''
                  }
                />
              </Form.Group>
              <Form.Group className="col-xs-12 col-lg-4" controlId="cpf">
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={data && data.deliveryCustomer && data.deliveryCustomer.cpf}
                  placeholder="CPF somente números"
                  maxLength="11"
                  disabled={
                    data && data.deliveryCustomer && data.deliveryCustomer.customerAutomaticallyLoaded ? 'disabled' : ''
                  }
                />
              </Form.Group>
              <Form.Group className="col-xs-12 col-lg-2" controlId="cpf">
                <Form.Label style={{ marginBottom: '10px' }}>CPF na nota</Form.Label>
                <SlideToggle
                  controlId="cpfNaNota"
                  style={{
                    alignSelf: 'center',
                    marginLeft: '13px',
                    marginBottom: '7px',
                  }}
                  onChange={handleChange}
                  checked={data && data.deliveryCustomer && data.deliveryCustomer.cPFNaNota}
                  id="cpfNaNota"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="col-xs-12 col-lg-8" controlId="email">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={data && data.deliveryCustomer && data.deliveryCustomer.email}
                  maxLength="50"
                />
              </Form.Group>
              <Form.Group className="col-xs-12 col-lg-4" controlId="phone">
                <Form.Label>Celular/WhatsApp</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={data && data.deliveryCustomer && data.deliveryCustomer.phone}
                  placeholder="DDD + Número"
                  maxLength="11"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="col-xs-12 col-lg-3" controlId="zipCode">
                <CepHeader>
                  <Form.Label>CEP</Form.Label>
                  {isOnline && (
                    <button type="button" onClick={this.props.onIdentifyCepClick}>
                      Não sei o CEP
                    </button>
                  )}
                </CepHeader>
                <Form.Control
                  onBlur={isOnline ? this.props.handleBlurZipCode : false}
                  onKeyDown={isOnline ? this.props.handleKeyDownZipCode : false}
                  onChange={handleChange}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.zipCode
                  }
                  placeholder="00000000"
                  maxLength="8"
                />
              </Form.Group>

              <Form.Group className="col-xs-12 col-lg-2" controlId="state">
                <Form.Label>UF</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  placeholder={isOnline ? 'Informe o CEP' : 'Informe a UF'}
                  disabled={isOnline ? 1 : false}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.state
                  }
                  maxLength="2"
                />
              </Form.Group>
              <Form.Group className="col-xs-12 col-lg-4" controlId="city">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  placeholder={isOnline ? 'Informe o CEP' : 'Informe a Cidade'}
                  disabled={isOnline ? 1 : false}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.city
                  }
                  maxLength="100"
                />
              </Form.Group>

              <Form.Group className="col-xs-12 col-lg-3" controlId="neighborhood">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.neighborhood
                  }
                  maxLength="50"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="col-xs-12 col-lg-8" controlId="street">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.street
                  }
                  maxLength="150"
                />
              </Form.Group>
              <Form.Group className="col-xs-12 col-lg-2" controlId="number">
                <Form.Label>Número</Form.Label>
                <Form.Control
                  autoFocus={this.props.cepCallback}
                  onChange={handleChange}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.number
                  }
                  maxLength="15"
                  ref={this.numberRef}
                />
              </Form.Group>
              <Form.Group className="col-xs-12 col-lg-2" controlId="complement">
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={
                    data &&
                    data.deliveryCustomer &&
                    data.deliveryCustomer.deliveryCustomerAddress &&
                    data.deliveryCustomer.deliveryCustomerAddress.complement
                  }
                  maxLength="50"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="col-xs-12 col-lg-12" controlId="deliveryObs">
                <Form.Label>Anotações da entrega</Form.Label>
                <Form.Control onChange={handleChange} value={data && data.deliveryObs} maxLength="200" />
              </Form.Group>
            </Form.Row>
          </Form>
          <Buttons>
            {!isOnline && (
              <span className="infoPdvOfflineDelivery" >
                <i className="material-icons">info</i>
                PDV operando Offline. As informações poderão ser utilizadas somente para essa venda.
              </span>
            )}
            {isOnline && (
              <Button
                id="btn-confirm"
                className="btn-default btn-outline"
                type="button"
                onClick={handleConfirmSave}
                ref={this.confirmBtnRef}
              >
                Atualizar cadastro
              </Button>
            )}
            <Button
              id="btn-cancel"
              className="btn-default btn-outline"
              type="button"
              onClick={handleConfirmWhithoutSave}
            >
              Salvar nesta entrega
            </Button>
          </Buttons>
        </Card>
      </DeliveryCardContainer>
    );
  }
}

export default DeliveryInfo;
