import React, { Fragment } from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules'
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import { currencyMask } from '../../utils/masks';
import './linx-conecta-products-component.style.scss';
import { HeaderConecta, InfoBoxConecta, Label } from './styles';

export class LinxConectaProducts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOverflowed: true,
      showTooltip: false,
      hovered: false,
    };

    this.productNameRef = React.createRef();
    this.modalConfirm = React.createRef();
    this.confirmBtnRef = React.createRef();
    this.modalWarning = React.createRef();
  }

  getDescricaoDesconto(status) {
    switch (status) {
      case 0:
        return this.props.fidelidade ? 'FIDELIDADE' : 'CONVÊNIO'
      case 2:
        return 'BLOQUEADO'
      case 3:
        return 'LOJA'
      default:
        return 'SEM DESCONTO'
    }
  }

  setProdShowHint(prod, showHint) {
    prod.showHint = showHint
    this.setState({ showTooltip: true })
  }

  getRow = (prod) => {
    return (
      <Fragment
        key={prod.barcode}>
        {prod.showHint && this.state.showTooltip &&
          <div className={`item-name-tooltip`}>
            {prod.name}
          </div>
        }
        <tr>
          <td>{prod.barcode}</td>
          <td className="tdDesc"
            ref={this.productNameRef}
            hovered={this.state.hovered}
            onMouseOver={() => this.setProdShowHint(prod, true)}
            onMouseOut={() => this.setProdShowHint(prod, false)}
          >
            {prod.name}
          </td>
          <td>{currencyMask(prod.valorUnitarioBrutoProduto)}</td>
          <td>
            {prod.status !== 2 ?
              <input
                className="inputQtt"
                type="number"
                id={prod.barcode}
                autoComplete="off"
                maxLength={9}
                value={prod.quantidade}
                onChange={this.props.handleChangeQtt}
              />
              : prod.quantidade}
          </td>
          <td>{prod.percentualDesconto} %</td>
          <td>{currencyMask(prod.valorDesconto)}</td>
          <td>{currencyMask(prod.valorTotalLiquidoProduto)}</td>
          <td>{prod.exigeReceita ? <Label>Sim</Label> : <Label>Não</Label>}</td>
          <td className="details-conecta"><i className="material-icons">info</i>
            <a id={prod.barcode} onClick={this.props.handleShowMessageProd} href="#">Detalhes</a>
          </td>
          <td><label className={'labelDesc' + prod.status}>{this.getDescricaoDesconto(prod.status)}</label></td>
          <td className="details-conecta"><i className="material-icons">info</i>
            <a id={prod.barcode} onClick={() => this.props.handlePecPbmModal(prod)} href="#">Detalhes</a>
          </td>
        </tr>
      </Fragment>
    );
  }

  componentDidMount() {
    this.modalConfirm.current.open();
    this.confirmBtnRef.current.focus();
  }

  componentWillUnmount() {
    if (this.modalConfirm.current !== null) this.modalConfirm.current.close();
  }

  getHeaders = () => {
    return (
      <tr>
        <th scope="col" style={{ width: "120px" }}>Cód. Barras</th>
        <th scope="col" className="tdDesc">Descrição</th>
        <th scope="col" style={{ width: "100px" }}>Preço Un.</th>
        <th scope="col" style={{ width: "80px" }}>Qtde.</th>
        <th scope="col" style={{ width: "80px" }}>% Desc.</th>
        <th scope="col" style={{ width: "100px" }}>Valor Desc.</th>
        <th scope="col" style={{ width: "100px" }}>Valor Total</th>
        <th scope="col" style={{ width: "80px" }}>Receita</th>
        <th scope="col" style={{ width: "100px" }}>Retorno Adm.</th>
        <th scope="col" style={{ width: "150px" }}>Origem Desc.</th>
        <th scope="col" style={{ width: "100px" }}>Minha Saúde</th>
      </tr>
    );
  }

  getPecPbmModal = (selectedItem) => {
    return (
      <Modal
        title="Minha Saúde"
        id="linx-conecta-init-component"
        closeOnOverlayClick={false}
        closable={false}
        ref={this.modalConfirm}
        className="col-11"
        style={{
          maxHeight: '70vh',
          height: "fit-content",
          width: '45vw'
        }}>
        <Modal.Body>
          <div className="tbConecta">
            {this.getHeadersPecPbm()}
            {selectedItem.pecPbm !== null &&
              (<div tabIndex={0} className="rowTbConecta">
                <div>{selectedItem.pecPbm.descricao}</div>
                <div>{selectedItem.pecPbm.abrangenciaDescricao}</div>
                <div>{selectedItem.pecPbm.tipoDescontoDescricao}</div>
              </div>)}
            {selectedItem.pecPbm === null &&
              (<div tabIndex={0} className="rowTbConecta" style={{ fontSize: '16px' }}>
                Não foi encontrado retorno do Minha Saúde
              </div>)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn-close"
            className={"btn-default btn-purple"}
            onClick={this.props.togglePecPbmModal}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }

  getHeadersPecPbm = () => {
    return (
      <div className="headerTbConecta">
        <div>Descrição</div>
        <div>Abrangência</div>
        <div>Tipo desconto</div>
      </div>
    );
  }

  render() {
    const {
      handleClose,
      handleConfirm,
      customerName,
      productList,
      showWarningModal,
      handleWarningProceed,
      handleWarningReturn,
      valorTotalBruto,
      valorTotalLiquido,
      fidelidade,
      message,
      handleShowMessageCoupom,
      handleConfirmBack,
      showPecPbmModal,
      selectedItem,
    } = this.props;

    const approvedProducts = productList.filter(p => p.status !== 2);
    const notApprovedProducts = productList.filter(p => p.status === 2);

    if (showPecPbmModal)
      return this.getPecPbmModal(selectedItem);

    if (showWarningModal) {
      return (
        <Modal
          title="Linx Conecta - Aviso"
          closable={false}
          style={{
            maxHeight: '70vh',
            height: "fit-content",
            width: '45vw'
          }}
          ref={this.modalWarning}
        >
          <Modal.Body className="mdl-body-confirmation">
            <p>Ao prosseguir, os produtos <strong>não aprovados</strong> poderão ser vendidos em outros meios de pagamento.</p>
            <p>Retorne para a edição da venda caso precise excluí-los ou, para cancelar toda a transação, clique em <strong>Nova Venda</strong>.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="btn-conecta-proceed"
              className="btn-default btn-purple"
              type="button"
              onClick={handleWarningProceed}
              style={{ textTransform: "none" }}
            >
              Quero prosseguir
            </Button>
            <Button
              id="btn-conecta-return"
              className="btn-default btn-outline"
              type="button"
              onClick={handleWarningReturn}
              style={{ textTransform: "none" }}
            >
              Retornar para a edição da venda
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    return (
      <Modal
        title="Linx Conecta"
        closable={false}
        closeOnOverlayClick
        ref={this.modalConfirm}
        opened={true}
        onClickClose={handleClose}
        className="col-11"
        style={{
          height: '85vh',
          width: '85vw'
        }}
      >
        <Modal.Body>
          <HeaderConecta>
            <div className="customer-name-conecta">
              <p>Cliente</p>
              <p>{customerName}</p>
            </div>
            <div className="info-box-wrapper">
              <InfoBoxConecta>
                <p>Total bruto</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(valorTotalBruto).slice(2)}</p>
              </InfoBoxConecta>
              <InfoBoxConecta>
                <p>Economia de</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(valorTotalBruto - valorTotalLiquido).slice(2)}</p>
              </InfoBoxConecta>
              <InfoBoxConecta>
                <p>Total líquido</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(valorTotalLiquido).slice(2)}</p>
              </InfoBoxConecta>
            </div>

          </HeaderConecta>
          {approvedProducts.length > 0 &&
            <Fragment>
              <Row className="table-title">
                <span>{fidelidade ? "PRODUTOS" : "PRODUTOS APROVADOS"}</span>
              </Row>
              <Table className="table-sm" striped responsive hover>
                <thead>
                  {this.getHeaders()}
                </thead>
                <tbody>
                  {approvedProducts.map(this.getRow)}
                </tbody>
              </Table>
            </Fragment>
          }
          {notApprovedProducts.length > 0 &&
            <Fragment>
              <Row className="table-title">
                <span>PRODUTOS NÃO APROVADOS</span>
              </Row>
              <Table className="table-sm" striped responsive hover>
                <thead>
                  {this.getHeaders()}
                </thead>
                <tbody>
                  {notApprovedProducts.map(this.getRow)}
                </tbody>
              </Table>
            </Fragment>
          }
        </Modal.Body>
        <Modal.Footer className="mdl-footer">
          <td><i className="material-icons">info</i></td>
          {message && message.length > 100 ?
            <label>{message.substring(0, 100) + "..."}<a onClick={handleShowMessageCoupom} href="#">   Saiba Mais</a></label>
            :
            <label>{message}</label>
          }
          <Button
            id="btn-confirm-conecta"
            className="btn-default btn-purple"
            type="button"
            onClick={handleConfirm}
            ref={this.confirmBtnRef}
          >
            AVANÇAR
          </Button>
          <Button
            id="btn-cancel-conecta"
            className="btn-default btn-outline"
            type="button"
            onClick={handleConfirmBack}
          >
            VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LinxConectaProducts;
