import React, { useState } from 'react';
import moment from 'moment';
import PopupMenu from '../popupmenu';
import { Container } from './styles';

function Row(props) {
  const {
    item,
    handlePrintDocument
  } = props;

  const iconRef = React.createRef();
  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const [openTop, setOpenTop] = useState(false);

  function handleClick(event) {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setShowPopupMenu(false);
    }
  }

  function handlePopupMenu(e) {
    setOpenTop(false);
    setShowPopupMenu(!showPopupMenu);

    if (window.innerHeight < 900 && e.clientY / window.innerHeight >= 0.65) {
      setOpenTop(true);
    } else if (e.clientY / window.innerHeight >= 0.8) {
      setOpenTop(true);
    }
  }

  return (
    <Container
      tabIndex='0'
      className='selectable'
    >
      <td />

      <td>
        {item.posId}
      </td>
      <td>
        {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
      </td>
      <td>
        {item.user}
      </td>
      <td>
        {item.description}
      </td>
      <td>
        <i
          data-testid='document-printing-options'
          ref={iconRef}
          className='material-icons'
          onClick={(e) => handlePopupMenu(e)}
        >
          more_vert
        </i>
        {showPopupMenu && (
          <PopupMenu
            item={item}
            handlePrintDocument={handlePrintDocument}
            onClick={handleClick}
            openTop={openTop}
          />
        )}
      </td>
    </Container>
  );
}

export default Row;
