import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  background: #f4f8fb;

  thead > tr {
    border-bottom: 2px solid #cccccc;

    th {
      font-family: Dosis;
      font-style: normal;
      font-weight: 500;
      line-height: 131.4%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #5b2e90;
      padding: 0.5rem 0px;

      &:nth-child(n+2){
        text-align: right;
      }
    }
  }

  tbody > tr {
    border-bottom: 1px solid #cccccc;

    td {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 138.2%;
      color: #000000;
      padding: 0.5rem 0px;
      text-align: center;

      &.success {
        color: #26b483;
      }

      &.error {
        color: #f35958;
      }

      &.warning {
        color: #faa46b;
      }
    }

    td:first-child {
      font-weight: bold;
      text-align: start;
    }

    td:last-child {
      font-weight: bold;
    }
  }
`;
