import styled from 'styled-components';

export const Span = styled.span`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #F35958;
    margin-top: .3rem;
`