import styled from 'styled-components';
import theme from '../../../themes/default';

export const PaymentButtonListWrapper = styled.div`
  height: -webkit-fill-available;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 10px;
  max-height: min-content;
  min-height: min-content

  button {
    font-family: dosis;
    display: flex;
    align-items: center;
    min-height: 120px;
    font-size: 16px;
    outline: none;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid ${theme.palette.border[2]};
    padding: 0 15px 0 15px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    &:focus, &:hover {
      outline: none;
      background-color: ${theme.palette.grayscale.active};
      border: 1px solid ${theme.palette.primary[14]}
    }
  }

  .description-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;

    .shortcuts-text {
      color: #888;
      font-size: 14px;
    }
  }

  .item-total {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 2.6rem;
    margin-right: 20px;
  }

  .item-icon {
    width: 40px;
    height: 40px;
  }
`;
