import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { showCard, BUSINESS_AGREEMENT_PARCEL_LIST, shouldShow } from '../../redux/actions/routerAction';
import PaymentBusinessAgreementParcel from '../../shared/components/payment-business-agreement-parcel';
import PaymentService from '../../services/paymentService';

class PaymentBusinessAgreementParcelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = ({ businessAgreementParcel: '' });
    this.props.MessageBus.Subscribe('payment.businessAgreement.installmentoptions', this.eventReturn.bind(this));
    this.props.MessageBus.Subscribe('pos.order.deleted', () => this.setState({ BusinessAgreementParcel: false }));
  }

  eventReturn(canal, payload) {
    this.setState({
      businessAgreementParcel: payload,
    });

    this.props.showBusinessAgreementParcelList(payload);
  }

  service = new PaymentService();

  onHandleBusinessAgreement = installment => {
    this.service.setParcelCreditPayment(installment).catch(error => this.context.showError(error));
  }

  eventReturnParcel(canal, payload) {
    this.service.setParcelCreditPayment(payload).catch(error => this.context.showError(error));
  }

  handleClose = () => {
    const service = new PaymentService();
    service.backPayment()
      .catch(err => this.context.showError(err));
  }

  render() {
    const listParcel = this.state.businessAgreementParcel;
    return (
      <div>
        {this.props.show && (
          <PaymentBusinessAgreementParcel
            items={listParcel}
            onHandleBusinessAgreement={this.onHandleBusinessAgreement}
            handleClose={this.handleClose}
            {...this.props}
          />
        )}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  payment: state.payment,
  show: shouldShow(state, BUSINESS_AGREEMENT_PARCEL_LIST),
});

const mapDispatchToProps = dispatch => ({
  showBusinessAgreementParcelList: payload => dispatch(showCard(BUSINESS_AGREEMENT_PARCEL_LIST, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PaymentBusinessAgreementParcelContainer)));
