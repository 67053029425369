import styled from 'styled-components';

import theme from '../../../../themes/theme2';

export const Container = styled.div`
  position: absolute;
  left: ${theme.metrics.sidebarWidth}px;
  top: 50px;
  height: 100%;
  background: white;
  width: max-content;
  padding-top: 20px;
  height: calc(100% - 60px);
  overflow: auto;
`;

export const Header = styled.div`
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 15px;

  svg {
    margin-right: 10px;
  }
`;

export const Items = styled.div`
  margin: 0;
`;

export const Item = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  border: 0;
  background: transparent;
  width: 100%;

  &:focus {
    background-color: #ffa400;
    outline: none;
  }

  &:hover {
    background-color: #f1f3f6;
  }

  &:disabled {
    color: gray;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
`;

export const Image = styled.div`
  width: 32px;
  margin-right: 10px;

  img {
    max-width: 22px;
  }
`;
