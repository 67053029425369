import styled from 'styled-components';

export const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  align-items: center;
  justify-items: center;

  div {
    display: flex;
    align-items: center;

    input {
      width: 15px;
    }

    label {
      padding-left: 5px;
    }
  }
`;

export const ProlongedUse = styled.div`
  @media (max-width: 1279px) {
    flex: 1;
    display: flex;
    justify-content: center;

    & > div {
      max-width: 150px;
    }
  }
`;
