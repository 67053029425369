import Configuration from '../configuration';
import { removeConnection } from '../redux/modules/connectionPool/duck';
import Store from '../redux/store/index';
import MessageBusService from './MessageBusService';

const successfulHttpCodes = [0, 200, 201, 203, 204];
const headers = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  PosId: Configuration.PosId,
  Authorization: `Bearer ${window.sessionStorage.getItem('Access_Token')}`,
});
const buildRequest = (method, body) => ({
  method,
  headers: headers(),
  body: JSON.stringify(body),
});

const requestOptions = {
  method: 'GET',
  redirect: 'follow',
  mode: 'no-cors',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, OPTIONS',
};

const httpAccess = () => ({
  get: (url) =>
    fetch(url, {
      headers: headers(),
    })
      .then(async (value) => {
          if (value.status > 399) {
            const error = await value.json();
            throw error;
          }
          return value.text();
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        throw error;
      }),

  getWithCors: (url) =>
    fetch(url, requestOptions)
      .then(async (value) => {
          if (value.status > 399) {
            const error = await value.json();
            throw error;
          }
          return value.text();
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        throw error;
      }),

  getSat: (url, id) =>
    fetch(url, requestOptions)
      .then(async (value) => {
          if (successfulHttpCodes.includes(value.status))
            Store.dispatch(removeConnection(id));
          if (value.status > 399) {
            const error = await value.json();
            throw error;
          }
          return value.text();
        },
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        Store.dispatch(removeConnection(id));
        throw error;
      }),

  post: (url, body, id) =>
    fetch(url, buildRequest('POST', body))
      .then(async (value) => {
          if (value.status === 401) {
            window.sessionStorage.removeItem('Access_Token');
            if (Store.getState(Store).util.shouldAuthenticateOperation) {
              MessageBusService.GetInstance().Publish('pos.open.login');
              return;
            }
            MessageBusService.GetInstance().Publish('authenticate.pos');
            return;
          }
          if (
            successfulHttpCodes.includes(value.status) &&
            !url.endsWith('/login')
          )
            Store.dispatch(removeConnection(id));

          if (value.status === 999) return value;
          if (value.status > 399) {
            Store.dispatch(removeConnection(id));
            const error = await value.json();
            throw error;
          }

          if (value.headers && value.headers.has('Content-Type') && value.headers.get('Content-Type').indexOf("application/pdf") > -1)
            return value;

          return value.text();
        },
        (error) => {
          if (error.status === 999) return error;
          throw error;
        }
      )
      .catch((error) => {
        Store.dispatch(removeConnection(id));
        if (error.status === 999) return error;
        throw error;
      }),

  put: (url, body) =>
    fetch(url, buildRequest('PUT', body))
      .then((value) => value.text())
      .catch((error) => {
        throw error;
      }),
});

export default httpAccess();
