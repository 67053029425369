import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaymentMoney, PaymentTef } from '../../shared/components/modules';
import { showCard, closeAllCards, PAYMENT_MONEY, shouldShow } from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import PaymentService from '../../services/paymentService';

export class PaymentMoneyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = ({ moneyPayload: 0 });

    this.onHandleClickPay = this.onHandleClickPay.bind(this);

    this.props.MessageBus.Subscribe('payment.money.inputValue', this.eventReturn.bind(this));
    this.props.MessageBus.Subscribe('payment.tef.inputValue', this.eventReturnTef.bind(this));
    this.props.MessageBus.Subscribe('pos.order.deleted', this.close.bind(this));
    this.paymentService = new PaymentService();
  }

  close(canal, payload) {
    this.props.closePaymentMoney();
  }

  eventReturn(canal, payload) {
    this.setState({
      moneyPayload: payload.amount,
      url: payload.confirmUrl,
      tef: false,
      money: true,
    });

    this.props.openPaymentMoney();
  }

  eventReturnTef(canal, payload) {
    this.setState({
      moneyPayload: payload.amount,
      url: payload.confirmUrl,
      tef: true,
      money: false,
    });

    this.props.openPaymentMoney();
  }

  onHandleClickPay(amount) {
    this.paymentService.setMoneyInput(amount, this.state.url)
      .catch(err => this.context.showError(err));
  }

  handleClose = () => {
    this.props.closePaymentMoney();
    this.paymentService.backPayment()
      .catch(err => this.context.showError(err));
  }

  render() {
    const moneyPayload = this.state.moneyPayload;
    return (
      <div>
        {this.props.showPaymentMoney && this.state.money && (
          <PaymentMoney
            handleClickPay={this.onHandleClickPay}
            amount={moneyPayload}
            handleClose={this.handleClose}
            image="./assets/images/payment-types/dinheiro.svg"
          />
        )}
        {this.props.showPaymentMoney && this.state.tef && (
          <PaymentTef
            handleClickPay={this.onHandleClickPay}
            amount={moneyPayload}
            handleClose={this.handleClose}
            image="./assets/images/payment-types/creditcard.svg"
          />
        )}
      </div>
    );
  }

}

const mapStateToProps = state => ({
  payment: state.payment,
  showPaymentMoney: shouldShow(state, PAYMENT_MONEY)
});

const mapDispatchToProps = dispatch => ({
  openPaymentMoney: payload => dispatch(showCard(PAYMENT_MONEY, payload)),
  closePaymentMoney: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PaymentMoneyContainer)));
