import React, { Component } from 'react'

import { Loader } from '../../shared/components/modules'
import { connect } from 'react-redux'

class LoadingScreen extends Component {

  render(){
    const { visible, text } = this.props.loading

    return(
      visible && <Loader text={text} /> 
    )
  }

}

const mapStateToProps = state => ({
  loading: state.loading
})

export default connect(mapStateToProps)(LoadingScreen)