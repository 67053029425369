import React from "react";

import { Field, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import uuid from "uuid/v4";

import { Container, MaxLengthCounter } from "./styles";

const FormControl = React.forwardRef(
  (
    { id, label, name, type, options, flex, children, lengthCounter, ...props },
    ref
  ) => {
    const formId = id || uuid();
    const placeholder =
      props.placeholder !== undefined && props.placeholder !== "";
    const selectPlaceholder = type === "select" && placeholder;
    const showMaxLengthCounter = lengthCounter >= 0 && props.maxLength !== "";
    return (
      <Container className="formControl" style={{ flex }}>
        {label && <label htmlFor={formId}>{label}</label>}
        {children || (
          <Field innerRef={ref} id={formId} name={name} {...props} as={type}>
            {selectPlaceholder ? (
              <>
                <option value="" disabled selected hidden>
                  {props.placeholder}
                </option>

                {options &&
                  options.map(({ name: optionName, value }, index) => (
                    <option key={index} value={value}>
                      {optionName}
                    </option>
                  ))}
              </>
            ) : (
              options &&
              options.map(({ name: optionName, value }, index) => (
                <option key={index} value={value}>
                  {optionName}
                </option>
              ))
            )}
          </Field>
        )}
        {showMaxLengthCounter && (
          <MaxLengthCounter>
            {lengthCounter} / {props.maxLength}
          </MaxLengthCounter>
        )}
        <ErrorMessage name={name} className="error" component="div" />
      </Container>
    );
  }
);

FormControl.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["input", "select"]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  flex: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  lengthCounter: PropTypes.number,
};

FormControl.defaultProps = {
  id: null,
  label: null,
  flex: "",
  type: "input",
  options: null,
  children: null,
};

export default FormControl;
