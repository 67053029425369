import styled from 'styled-components';
import theme2 from '../../../themes/theme2';

export const InfoBoxTrnCentre = styled.div`
  height: 100%;
  background-color: ${theme2.palette.brandLinx};
  color: #ffffff;
  margin: 0 8px;
  padding: 12px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  
  width: 100%;
  
  &:last-child {
    margin-right: 0;
  }
  
  & > p.info-box-value {
    span {
      font-size: 14px;
    }
    
    margin-top: 6px;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
  }
`;

export const HeaderTrnCentre = styled.div`
  display: flex;
  align-items: center;
  
  margin: 24px 0;
  
  .customer-name-conecta {
    text-transform: uppercase;
    
    p:last-child {
      margin-top: 8px;
      font-weight: bold;
    }
  }
  
  & p {
    margin-bottom: 0;
  }
  
  .info-box-wrapper {
    display: flex;
    margin-left: auto;
    width: 50%;
  }
`;

export const Label = styled.span`
  padding: 4px 12px;
  background-color: #A5DFDF;
  font-weight: bold;
  border-radius: 20px;
`;