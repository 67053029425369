import React, { Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Button } from '../../components/button'
import { currencyString } from '../../utils/masks';
import { Card, Period } from '../modules';
import moment from 'moment';
import './invoice-monitor.scss';
import getShortcut, { FIRST_OPTION, SECOND_OPTION, THIRD_OPTION, FOURTH_OPTION, FIFTH_OPTION, SIXTH_OPTION } from '../../../keyboardShortcuts';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';


export class InvoiceMonitor extends React.Component {
  constructor(props) {
    super(props);
    // trazer dados com limite de 90 dias
    this.statusDescription = [
      { value: 0, description: 'Autorizada' },
      { value: 1, description: 'Contingência' },
      { value: 2, description: 'Contingência Rejeitada' },
      { value: 3, description: 'Cancelada' },
      { value: 4, description: 'Inutilizada' },
      { value: 5, description: 'Erro de Emissão' },
      { value: 6, description: 'Processo Incompleto' },
      { value: '', description: 'Todas' }
    ];

    this.state = {
    }
  }

  getHeaders = () => {
    return (
      <ul className="header">
        <li></li>
        <li></li>
        <li>Número</li>
        <li>Série</li>
        <li>Valor</li>
        <li>Consumidor</li>
        <li>Situação</li>
        <li>Motivo</li>
        <li>Data/Hora</li>
        <li>Pagamento</li>
      </ul>
    );
  }
  getCheckbox = (item, i) => this.props.selectedItem == null || this.props.selectedIndex !== this.props.data.indexOf(item) ? "check_box_outline_blank" : "check_box";

  getRow = (data, idx) => {
    return (
      <div className="datacontainer" >
        <ul className="datarow" key={`row-${idx}`} onClick={() => { this.props.handleShowChild(idx) }}>
          <li><button onClick={(event) => { event.stopPropagation(); this.props.handleSelectItem(data, idx); }}><i className="material-icons">{this.getCheckbox(data, idx)}</i></button></li>
          <li><i className="material-icons purple">{data.showChild ? "remove_circle_outline" : "add_circle_outline"}</i></li>
          <li>{data.receiptNumber}</li>
          <li>{data.receiptSeries}</li>
          <li>{currencyString(data.totalOrder)}</li>
          <li>{(data.customer && data.customer.name) || ""}</li>
          <li>{data.orderStatus.description}</li>
          <li title={data.fiscalDocument.reason}>{data.fiscalDocument.reason}</li>
          <li>{moment(data.fiscalDocument.returnDate.toLocaleString("pt-BR")).format('DD/MM/YYYY HH:mm')}</li>
          <li>{(data.paymentList.length === 1 && data.paymentList[0].name) || 'DIVERSOS'}</li>
        </ul>
        {
          this.getChild(data, idx)
        }
      </div>
    );
  }

  getChild = (data) => {
    return (
      <div className={data.showChild ? "child" : "child hidden"}>
        <ul className="headerchild">
          <li>
            Cód Barra
        </li>
          <li>
            Descrição
        </li>
          <li>
            Quantidade
        </li>
        <li>
            Valor Total
        </li>
        </ul>
        {
          data.items && data.items.length === 0 &&
          <ul className="datachild full" >
            <li>Nenhum produto encontrado</li>
          </ul>
        }
        {
          data.items && data.items.map(i => this.getItem(i))
        }
        <ul className="headerchild full">
          <li>
            Motivo
        </li>
        </ul>
        <ul className="datachild full" >
          <li>{data.fiscalDocument.reason}</li>
        </ul>
      </div>
    )
  }

  getItem = (item, idx) => {
    return (
      <ul className="datachild" key={`row-${idx}`} >
        <li><div className="circle"></div>{item.product.barcode}</li>
        <li>{item.product.name}</li>
        <li>{item.quantity}</li>
        <li>R${item.subTotal}</li>
      </ul>)
  }

  handleChange = (e) => {
    this.props.form[e.target.id] = e.target.value || null;
  }

  mapStatus = () => {
    return (
      <Fragment>
        {this.statusDescription.map((e, i) => {
          return <option key={i} value={e.value}>{e.description}</option>
        })}
      </Fragment>
    )
  }
  actionVisualize = () => {
    this.props.monitorActions.shouldEnableVisualizar(this.props.selectedItem) && this.props.handleVisualize(this.props.selectedItem);
  }
  actionReprintTef = () => {
    this.props.monitorActions.shouldEnableReprintTef(this.props.selectedItem) && this.props.handleReprintTef(this.props.selectedItem);
  }
  actionReauthorize = () => {
    this.props.monitorActions.shouldEnableReauthorize(this.props.selectedItem) && this.props.handleReauthorize(this.props.selectedItem);
  }
  actionSearch = () => {
    this.props.monitorActions.shouldEnableConsultar(this.props.selectedItem) && this.props.handleConsultar(this.props.selectedItem);
  }
  actionCancel = () => {
    this.props.monitorActions.shouldEnableCancel(this.props.selectedItem) && this.props.handleCancel(this.props.selectedItem);
  }
  actionDisable = () => {
    this.props.monitorActions.shouldEnableInutilizar(this.props.selectedItem) && this.props.handleUnusable(this.props.selectedItem);
  }

  render() {
    const {
      data,
      handleClose,
      handleSearch,
      form,
      selectedItem,
      monitorActions
    } = this.props;


    return (
      <Card autoScroll={false} title="Monitor de notas" handleClose={handleClose} closeEnable className="note-monitor-card" shouldFlex id="monitorCard">
        <Form>
          <Form.Row>
            <Period
              className="col col-xs-12 col-sm-12 col-md-2 col-lg-2"
              labelStart="Data Inicial"
              labelEnd="Data final"
              object={form}
              fieldEnd="endDate"
              fieldStart="startDate"
              bootstrap
            />
            <Form.Group as={Col} controlId="number">
              <Form.Label>Número</Form.Label>
              <Form.Control
                required
                placeholder="Número"
                onChange={this.handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group as={Col} controlId="series">
              <Form.Label>Série</Form.Label>
              <Form.Control
                required
                placeholder="Série"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="status">
              <Form.Label>Situação</Form.Label>
              <Form.Control
                required
                as="select"
                placeholder="Situação"
                defaultValue={form.status}
                onChange={this.handleChange}
              >
                {this.mapStatus()}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} style={{ maxWidth: '140px' }}>
              <Button onClick={handleSearch} className="btn-purple" style={{ width: '100%' }}>
                <i className="material-icons">
                  search
                </i>
                 <span>Filtrar</span>
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <div className="actions">
          <Button className={"btn-purple " + (!monitorActions.shouldEnableVisualizar(selectedItem) ? "disabled" : "")}
            onClick={this.actionVisualize}>Visualizar
            {
              monitorActions.shouldEnableVisualizar(selectedItem) &&
              <ShortcutHint
                offsetLeft={0}
                offsetTop={40}
                shortcut={getShortcut(FIRST_OPTION)}
                activateShortcut={this.actionVisualize}
              />
            }
          </Button>
          <Button className={"btn-purple " + (!monitorActions.shouldEnableReprintTef(selectedItem) ? "disabled" : "")}
            onClick={this.actionReprintTef}>Comprovante TEF
            {
              monitorActions.shouldEnableReprintTef(selectedItem) &&
              <ShortcutHint
                offsetLeft={0}
                offsetTop={40}
                shortcut={getShortcut(SECOND_OPTION)}
                activateShortcut={this.actionReprintTef}
              />
            }

          </Button>
          <Button className={"btn-purple " + (!monitorActions.shouldEnableReauthorize(selectedItem) ? "disabled" : "")}
            onClick={this.actionReauthorize}>Retransmitir Nota
            {
              monitorActions.shouldEnableReauthorize(selectedItem) &&
              <ShortcutHint
                offsetLeft={0}
                offsetTop={40}
                shortcut={getShortcut(THIRD_OPTION)}
                activateShortcut={this.actionReauthorize}
              />
            }

          </Button>
          <Button className={"btn-purple " + (!monitorActions.shouldEnableConsultar(selectedItem) ? "disabled" : "")}
            onClick={this.actionSearch}>Consultar
            {
              monitorActions.shouldEnableConsultar(selectedItem) &&
              <ShortcutHint
                offsetLeft={0}
                offsetTop={40}
                shortcut={getShortcut(FOURTH_OPTION)}
                activateShortcut={this.actionSearch}
              />
            }

          </Button>
          <Button className={"btn-purple " + (!monitorActions.shouldEnableCancel(selectedItem) ? "disabled" : "")}
            onClick={this.actionCancel}>Cancelar
            {
              monitorActions.shouldEnableCancel(selectedItem) &&
              <ShortcutHint
                offsetLeft={0}
                offsetTop={40}
                shortcut={getShortcut(FIFTH_OPTION)}
                activateShortcut={this.actionCancel}
              />
            }
          </Button>
          <Button className={"btn-purple " + (!monitorActions.shouldEnableInutilizar(selectedItem) ? "disabled" : "")}
            onClick={this.actionDisable}>Inutilizar
           {
              monitorActions.shouldEnableInutilizar(selectedItem) &&
              <ShortcutHint
                offsetLeft={0}
                offsetTop={40}
                shortcut={getShortcut(SIXTH_OPTION)}
                activateShortcut={this.actionDisable}
              />
            }
          </Button>

        </div>
        <div className="scrollTable">
          {this.getHeaders()}
          <div className="scroll">
            {!data.length && <ul className="datarow norecords"><li>Nenhuma informação encontrada</li></ul>}
            {data.map(this.getRow)}
          </div>
        </div>
      </Card>
    );
  }
}

export default InvoiceMonitor;