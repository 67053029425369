import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withMessageBus } from "../../shared/utils/messageBus";
import {
  showCard,
  closeAllCards,
  CHECKPREORDER,
  shouldShow,
} from "../../redux/actions/routerAction";
import { getContext } from "../../shared/hoc/getContext";
import CardCheckPreOrder from "../../shared/components/card-check-preorder";
import SaleService from "../../services/saleService";

export class CardCheckPreOrderContainer extends Component {
  title = "CONFERÊNCIA DE PRÉ-VENDA";

  static propTypes = {
    showCheckPreOrder: PropTypes.bool,
    showCheckPreOrderSelector: PropTypes.func.isRequired,
    hideCheckPreOrderSelector: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    showCheckPreOrder: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.saleService = new SaleService();

    this.props.MessageBus.Subscribe("pos.preorder.check.show", this.handleShow);
    this.props.MessageBus.Subscribe(
      "pos.preorder.check.error",
      this.handleError
    );
    this.props.MessageBus.Subscribe(
      "pos.preorder.check.updated.success",
      this.handleUpdate
    );

    this.props.MessageBus.Subscribe(
      "pos.preorder.check.cleaned",
      this.handleUpdate
    );

    this.state = {
      checkInfo: null,
      focusBarcode: false,
    };
  }

  handleError = (ch, payload) => {
    this.setState({ focusBarcode: false });
    const title = "Conferência Pré-venda";
    this.context.showMessage(payload, title, this.handleFocusBarcode);
  };

  handleFocusBarcode = () => {
    this.setState({ focusBarcode: true });
  };

  handleCheck = (payload) => {
    this.saleService.checkItemPreOrder(payload);
  };

  handleConfirm = () => {
    this.saleService.recoverPreOrder(this.state.checkInfo.recoveryId, true);
    this.props.hideCheckPreOrderSelector();
  };

  handleUpdate = (ch, payload) => {
    this.setState({
      checkInfo: payload,
      focusBarcode: false,
    });
  };

  handleRestartPreOrder = () => {
    this.saleService.restartPreOrderCheck(this.state.checkInfo.orderId);
  };

  handleShow = (ch, payload) => {
    this.props.showCheckPreOrderSelector();
    this.setState({
      checkInfo: payload,
    });
  };

  handleClose() {
    this.props.hideCheckPreOrderSelector();
    this.saleService.cleanPreOrderCheck(this.state.checkInfo.orderId);
  }

  render() {
    const { showCheckPreOrder } = this.props;

    return (
      <Fragment>
        {showCheckPreOrder && (
          <CardCheckPreOrder
            preOrder={this.state.checkInfo}
            handleClose={this.handleClose}
            handleCheck={this.handleCheck}
            handleRestartPreOrder={this.handleRestartPreOrder}
            handleConfirm={this.handleConfirm}
            focusBarcode={this.state.focusBarcode}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showCheckPreOrder: shouldShow(state, CHECKPREORDER),
});

const mapDispatchToProps = (dispatch) => ({
  showCheckPreOrderSelector: () =>
    dispatch(showCard(CHECKPREORDER, null, true)),
  hideCheckPreOrderSelector: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CardCheckPreOrderContainer)));
