import React from 'react';
import { ListItemWrapper, ListItemContentWrapper, ListWrapper } from './style';

const ListTefOption = ({ item, handle }) => (
    <ListItemWrapper onClick={() => handle(item)} tabIndex="0" >
        <ListItemContentWrapper tabIndex="-1" className="divList">
            <ListWrapper>
                {item.value}
            </ListWrapper>
        </ListItemContentWrapper>
    </ListItemWrapper>
);

export default ListTefOption;
