export enum OperationEnum {
  ExcluirPreVenda = 1,
  CoberturaOferta = 2,
  LiberacaoLimite = 3,
  SangriaDespesa = 4,
  ProdutoSemEstoque = 5,
  LoteMaisRecente = 6
}

export enum OperationDescription {
  'Exclusão de pre-venda' = 1,
  'Cobertura de oferta' = 2,
  'Liberação de limite' = 3,
  'Sangria de despesa' = 4,
  'Venda de produto sem estoque' = 5,
  'Venda de lote mais recente' = 6
}
