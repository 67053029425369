import styled from 'styled-components';

export const OptionsRender = styled.div`
  .container{
    padding-top: 5px; 
    padding-left: 0px; 
    padding-right: 0px; 
    max-width: 100%;
  }
  .options{   
    margin: 10px 10px 10px 10px;
    padding-left: 5px; 
    display: flex;
    padding-right: 5px; 
    border-radius: 4px;
    border: 1px solid #C0C0C0;
    background-color: #eee;
  }
  .stockOptions{       
    display: flex; 
    width: 210px;   
  }
  .ruleOptions{       
    display: flex;    
  }
  .anchor{    
    padding-left: 14px; 
    padding-right: 5px; 
    color: #1E0FF2;
    text-decoration:underline;       
    &:hover,
    &:focus {
      cursor: pointer;
      color: black;
      font-weight: bold;
      background-color: #ffb200;
      tab-index: 5;
    }
  }
`;

export const Select = styled.div`

  background-color: #eee;
  border-radius: 0;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 5px; 
  padding-right: 20px;
  &.title-select {
    white-space: nowrap;
  }
  &.disabled {
    color: #ebebe4 !important;
  }

  &:focus {
    outline: none;
  }

  .icon-align {
    display: flex;
  }

  .menu-area {
    cursor: pointer;
    position: absolute;
    top: 64px;
    width: auto;
    display: grid;
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 15px;
    z-index: 500;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 auto;
      font-weight: bold;
      height: 40px;
      line-height: 42px;
      padding: 0 10px;      
      &:focus,
      &:hover {
        background-color: #ffb200;
      }
    }
  }
`;

export const SaleWrapper = styled.div`

  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 15px;
  margin-bottom: 10px;
  &.showInfo {
    flex: 0 0 127px;
    .filterOptions {
      i::before{
        content: 'keyboard_arrow_up'
      }
      .info {
        cursor: pointer;
        font-family: Dosis;
        display: flex;
        flex-direction: row;
        margin: 0px 60px 0 85px;
        background-color: white;
        overflow: auto;
        align-items: center;
        height: 50px
        font-size: 14px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        
        strong{
          color: #0F7BE8;
        }
        div {
          margin-left: 10px;
          flex: 1 1 auto;
          text-align: left;
        }
        div:last-child{
          flex : 0 0 60px;
        }
      }
    }
  }

  position: relative;

  .auto-complete {
    flex: 1;
    margin-left: 15px;
  }
  font-family: Arial;

  .pre-order {    
    margin-left: 10px;
    width: 50px;
    button {
      display: flex;
      width: 100%;
      height: 50px;
      background-color: white;
      color: #5b2e90;
      border: unset;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

      .tooltiptext {
        width: 200px;
        margin-top: 38px;
        margin-left: -80px;
        background-color: gray;
      }

      &:focus,
      &:hover,
      &:active {
        color: white;
        background-color: #5b2e90 !important;
        box-shadow: 0 0 8px #5b2e90;
        outline: none !important;
      }
    }
  }
  .filterOptions {
    flex: 0 0 100%;
    text-align: center;
    font-size: 11px;

    i::before{
      content: 'keyboard_arrow_down'
    }
    i {
      vertical-align: middle;
      font-size: 15px;
    }

    .info {
      display:none;
    }

    .button {
      cursor: pointer
      background-color: #fff;
      display: inline-block;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 0 20px 0 10px;
      line-height: 27px;
    }
  }
`;



export const ItemRender = styled.span`
  display: flex;
  flex-wrap: wrap;  
  height: 38px;
  border-top: 1px solid #e4e0e0;
  align-items: center;
  padding: 6px;
  &.anchor {
    color: #1E0FF2;
    text-decoration:underline;
  }
  &.item-list {
    display: grid;
    grid-template-columns: 1fr 160px 80px 80px 80px; 
    grid-template-rows: 15px 20px;
    grid-template-areas:
      'a b c d stock-label'
      'h e f g stock';

    .ds-item {
      grid-area: a;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ds-manufacturer {
      grid-area: b;
    }
    .ds-stock {
      grid-area: c;
    }
    .ds-price {
      grid-area: d;
    }
    .manufacturer {
      grid-area: e;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .stock {
      grid-area: f;
    }
    .price {
      grid-area: g;
    }
    .active-principle {
      grid-area: h;
      font-size: 11px;
      font-weight: bold;
      color: dimgrey;
    }
  
    .center {
      display: flex;
      justify-content: center;
    }

    small {
      font-size: 12px;
    }
    .stock-label{ 
      grid-area: stock-label;
    }
    .stock-eg{ 
      grid-area: stock;
      text-align: center;

    }
    .stock-eg button{
      background-color: transparent;
      border: none;
      outline: none;
    }
    .stock-eg img{
      height: 20px;
    }
    .stock-eg img:hover{
      background-color: #d9cee7;
    }
  }

  bold {
    font-weight: bold;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    color: black;
    font-weight: bold;
    background-color: #ffb200;
    tab-index: 5;
  }
`;
