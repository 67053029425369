import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Button, CopyToClipboard } from '../modules';
import { RadioButton } from '../radio-button';
import { PasswordInput } from '../password';
import './config-farmacia-popular-component.scss';

function ConfigFarmaciaPopularComponent(props) {
    const {            
        form,
        handleChange,
        enviromentValues,
        handleEnvironmentChange,
        handleConfirm,
        handleClickCadastrarComputador,
        terminalFarmaciaPopular
    } = props;

    const requiredLabelRender = (label) => {
        return (<>
          <span>{label} </span>
          <span style={{color: "red", fontWeight: 600}}>*</span>
        </>)
    }

    return (              
        <div className="body">
            <Form>                
                <Form.Row>
                    <RadioButton class="radio" title="Ambiente" values={enviromentValues} onChange={handleEnvironmentChange}/>
                </Form.Row>                       
            </Form>
            <Form.Row>
                <Form.Group as={Col} controlId="config.farmaciaPopular.login">
                    <Form.Label>{requiredLabelRender("Usuário")}</Form.Label>
                    <Form.Control
                        defaultValue={form?.config?.farmaciaPopular?.login}
                        onChange={(e) => handleChange(e)}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId="config.farmaciaPopular.password">
                    <Form.Label>{requiredLabelRender("Senha")}</Form.Label>
                    <PasswordInput 
                        value={form?.config?.farmaciaPopular?.password} 
                        onChange={(e) => handleChange({ target: { id: 'config.farmaciaPopular.password', value: e.target.value } })
                    }></PasswordInput>                   
                </Form.Group>
            </Form.Row> 
            <Form.Row>                        
                <Form.Group as={Col} controlId="estacao">
                    <Form.Label>Estação</Form.Label>
                    <label
                        id="cadastrar-computador-link" 
                        aria-label="Clique aqui para acessar o ambiente do Farmácia Popular e cadastrar o computador."
                        onClick={() => handleClickCadastrarComputador(form?.config?.farmaciaPopular?.enviroment)}
                    >
                        Cadastrar computador
                    </label>
                    <div className="input-with-copy-container">
                        <CopyToClipboard content={terminalFarmaciaPopular.estacao}/>
                        <Form.Control value={terminalFarmaciaPopular.estacao}/>
                    </div>
                </Form.Group>
            </Form.Row>
            <Form.Row>                        
                <Form.Group as={Col} controlId="identificacao">
                    <Form.Label>Identificação</Form.Label>
                    <div className="input-with-copy-container">
                        <CopyToClipboard content={terminalFarmaciaPopular.identificacao} />
                        <Form.Control value={terminalFarmaciaPopular.identificacao}/>
                    </div>
                </Form.Group>
            </Form.Row>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
            </div>
        </div>
    );
}

export default ConfigFarmaciaPopularComponent;