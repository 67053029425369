import styled from 'styled-components';
import { Table as DefaultTable } from 'react-bootstrap';

import theme2 from '../../../themes/theme2';

export const InfiniteScrollLoader = styled.h4`
  text-align: center;
  margin: 10px 0;
  color: ${theme2.palette.brandLinx};
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  
  &:focus {
    outline: none !important;
  }
  
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  
  td[colspan="4"] {
    text-align: center !important;
  }
  
  ${props => props.hasMessage ? `
    display: inline-flex;
    flex: 1;
    
    div.table-responsive {
      height: 100%;
    }
    
    div.table-responsive > table {
      height: 100%;
    }
    
    div.table-responsive > table tbody {
      height: 100%;
    }
    
    div.table-responsive > table tbody tr {
      height: 100%;
      background-color: #ffffff !important;
    }
    
    div.table-responsive > table tbody tr td {     
      height: 100%;
      text-align: center;
    }
  ` : ""}
`;

export const Table = styled(DefaultTable)`
  position: relative;
  margin: 0 !important;
  height: 100%;
  
  th {
    text-align: left;
    vertical-align: center;
    padding: 8px 0;
    
    &:first-child {
      padding-left: 5px;
    }
    
    &:last-child {
      text-align: right;
      padding-right: 5px;
    }
    
    div {
      display: flex;
      align-items: center;
      
      p {
        white-space: nowrap;
        margin-bottom: 0;
      }
      
      svg {
        margin-left: 5px;
        cursor: pointer;
        transition: transform ease-in-out 150ms;
      }
      
      svg.svg-desc-order {
        transform: rotateX(180deg);
      }
    }
  }

  td {
    font-family: 'Ubuntu', 'Dosis';
    font-size: 14px;
    vertical-align: middle !important;
    text-align: left;
    color: ${theme2.palette.grayDark};
    
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    padding: 10px 15px 10px 0;
    
    &:first-child {
      padding-left: 5px;
    }
    
    &:last-child {
      text-align: right;
      padding-right: 5px;
    }
  }

  tbody {
    tr {
      &:focus {
        background-color: ${theme2.palette.brandFarma};
      }
      &:nth-child(odd) {
        background-color: ${theme2.palette.tableBgOdd} !important;
      }
    }
  }
`;

export const PeriodButton = styled.button`
  padding: 5px 0;
  min-width: 120px;
  width: fit-content;
  font-size: 14px;
  
  margin-right: 10px;
  
  border-radius: 15px;
  
  background-color: ${props => props.selected ? theme2.palette.brandLinx : "#ffffff"};
  color: ${props => props.selected ? "#ffffff" : theme2.palette.brandLinx};
  
  border: 1px solid ${props => props.selected ? theme2.palette.link : theme2.palette.brandLinx};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  }
`;

export const ButtonsContainer = styled.div`
  min-height: 30px;
  display: flex;
  margin-top: 5px;
`;

export const ProductSearchBarContainer = styled.div`
  position: relative;
  
  i.material-icons#searchHistory {
    position: absolute;
    top: 14px;
    left: 6px;
    color: ${theme2.palette.brandLinx};
    cursor: pointer;
  }
  
  i.material-icons#searchClear {
    position: absolute;
    top: 14px;
    left: 32px;
    color: ${theme2.palette.brandLinx};
    cursor: pointer;
  }
`;

export const ProductSearchBar = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
  background-color: transparent;
  padding: 4px 60px;
  
  margin-top: 12px;
  
  &:focus {
    outline: none;
    border-bottom: 2px solid #cccccc;
  }
  
  &::placeholder {
    font-style: italic;
  }
`;

export const SelectPeriodContainer = styled.div`
  display: flex;
  margin-top: 8px;
  padding: 8px;
  
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;

  & > div {
    width: 180px;
    
    p {
      font-size: 12px;
      margin: 0;
      margin-top: 4px;
      color: red;
    }
    
    &:first-child {
      margin-right: 20px;
    }
    
    input {
      width: 100%;
      padding: 3px 10px;
    }
  }
`;

export const ApplyPeriodButton = styled.button`
  margin-left: auto;
  width: 180px;
  height: auto;
  font-size: 14px;
  
  border-radius: 14px;
  
  background-color: #007BFF;
  color: #ffffff;
  cursor: pointer;
  
  outline: none;
  border: none;
  
  &:disabled {
    background-color: #C2BEBE;
    cursor: default;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  }
`;

export const MessageContainer = styled.tbody`
  i {
    font-size: 48px;
    color: #E6E8EB;
  }
  
  h3 {
    margin-top: 5px;
    font-weight: normal;
    font-size: 18px;
    color: #C2BEBE;
  }
`;

export const SelectedPeriodText = styled.p`
  margin: 12px 0 0 0;
`;