import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import BarcodeIcon from '../../assets/images/barcode.png';
import getShortcut, {
  PRODUCT_SEARCH as PRODUCT_SEARCH_SHORTCUT,
  PRE_SALE_LIST,
} from '../../keyboardShortcuts';
import { addQuantity } from '../../redux/actions/quantityAction';
import {
  ORDER_DETAILS,
  PRODUCT_SEARCH,
  showCard,
  PRE_ORDER_LIST,
  shouldShow,
  SEARCH_CODE,
  showWithSearchBar,
  STOCKLIST
} from '../../redux/actions/routerAction';
import { OrderService } from '../../services/orderService';
import ProductService from '../../services/productService';
import SaleService from '../../services/saleService';
import { CacheFinder } from '../../shared/components/auto-complete/cache-finder';
import { AutoComplete, Input } from '../../shared/components/modules';
import QuantityItemButton from '../../shared/components/quantity-item';
import { ShortcutHint } from '../../shared/components/shortcut-hint/shortcut-hint';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { FindBytypedText, Item } from './item-render';
import { SaleWrapper } from './style';

export class SearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      props: null,
      message: undefined,
      quantity: 1,
      adicionalInfoProducts: [],
      suggestionHeight: 'auto',
      showSearchInfo: false,
      isToggleOnOptionStock: false,
      isToggleOnOptionSearchRule: false,
      options: {
        toggleOnOptionStock: [
          {
            value: 0,
            name: 'Cadastrado (Todos)',
          },
          {
            value: 1,
            name: 'Com estoque',
          },
          {
            value: 2,
            name: 'Sem estoque',
          }
        ],
        toggleOnOptionSearchRule: [
          {
            value: 0,
            name: 'Iniciar por: ',
          },
          {
            value: 1,
            name: 'Contém: ',
          }
        ]
      },
      toggleOnOptionStockSelected: 0,
      toggleOnOptionSearchRuleSelected: 0
    };

    this.containerRef = React.createRef();

    this.lastRequestCorrelationId = null;

    this.manualDebounce = null;

    this.requestResponseTimes = 0;

    this.getCacheProducts = this.getCacheProducts.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.toggleShowInfo = this.toggleShowInfo.bind(this);
    this.orderService = new OrderService();

    this.saleService = new SaleService();
    this.cacheFinder = new CacheFinder();
    this.productService = new ProductService();

    this.props.MessageBus.Subscribe(
      'pos.product.quantity',
      this.productsQuantityAndValueReturned.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.product.showcase.start',
      this.handleOpenSearch.bind(this)
    );
    this.props.MessageBus.Subscribe('pos.order.updated', () =>
      this.setState({ quantity: 1 })
    );

  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    clearTimeout(this.manualDebounce);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    if (!this.containerRef.current) return;
    const height = this.containerRef.current.parentNode.offsetHeight;
    this.setState({
      suggestionHeight: `${height - (90 + this.state.showSearchInfo ? 80 : 0)
        }px`,
    });
  };

  handleOpenSearch() {
    if (this.autocompleteRef) {
      this.props.openProductSearch(this.autocompleteRef.getText());
    }
  }


  ClickSelectToggleOnOptionRuleSelected = (option) => {
    this.setState({ toggleOnOptionSearchRuleSelected: option.value });
    this.forceUpdate();
    let query = option.value === 1 ? "&%" + this.autocompleteRef.getText() : "&" + this.autocompleteRef.getText();
    this.autocompleteRef.doSearch(query);
    this.focusProductField();
  };

  ClickSelectToggleOnOptionStock = (option) => {
    this.setState({ toggleOnOptionStockSelected: option.value });
    this.focusProductField();
  };

  ClickToggleOnOption = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ [value]: !this.state[value] });
  };

  ClickDivOption = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  focusProductField() {
    if (this.autocompleteRef) {
      this.autocompleteRef.focusInputField();
    }
  }

  selectProduct = (value, clear) => {
    if (/^[\d]+$/.test(value)) {
      this.saleService
        .addItem(value, this.state.quantity)
        .then(() => clear())
        .catch((error) => {
          this.context.showError(error);
        });
    } else {
      this.advancedSearchSelected(value);
    }
  };

  productsQuantityAndValueReturned(ch, { correlationId, result }) {
    this.requestResponseTimes--;
    if (
      this.lastRequestCorrelationId === correlationId ||
      this.requestResponseTimes < 1
    ) {
      this.setState({
        adicionalInfoProducts: result,
      });
    } else {
      this.forceUpdate();
    }
  }

  selectSpecificProduct = (product) => {
    if (!product) return;

    this.focusProductField();
    if (this.state.showSearchInfo) this.toggleShowInfo();
    let barcode = product.barcode.split(',')[0];
    this.saleService
      .addItem(barcode, this.state.quantity)
      .catch((error) => this.context.showError(error));
  };

  renderItem(props) {
    const { item } = props;

    let foundItem = null;
    if (item.barcode) {
      foundItem = this.state.adicionalInfoProducts.find((p) =>
        p.id.some((id) => item.barcode.split(',').includes(id))
      );
    }

    const itemRenderer = <Item {...props} aditionalInfo={foundItem} optionStock={this.state.toggleOnOptionStockSelected} openStockList={this.props.openStockList} isOnline={this.props.isOnline} />;

    return item.description ? itemRenderer : <FindBytypedText {...props}
      isToggleOnOptionSearchRule={this.state.isToggleOnOptionSearchRule}
      isToggleOnOptionStock={this.state.isToggleOnOptionStock}
      clickToggleOnOption={this.ClickToggleOnOption}
      clickDivOption={this.ClickDivOption}
      options={this.state.options}
      query={this.autocompleteRef.getText()}
      toggleOnOptionStockSelected={this.state.toggleOnOptionStockSelected}
      toggleOnOptionSearchRuleSelected={this.state.toggleOnOptionSearchRuleSelected}
      clickSelectToggleOnOptionSearchRule={this.ClickSelectToggleOnOptionRuleSelected}
      clickSelectToggleOnOptionStock={this.ClickSelectToggleOnOptionStock} />;
  }

  getCacheProducts(query, limit) {
    this.clearStateOptions();
    if (query.charAt(0) === '&')
      query = query.replace("&", "");
    else
      query = this.state.toggleOnOptionSearchRuleSelected === 1 ? "%" + query : query;

    const promise = this.cacheFinder.find(query, limit);

    promise.then((response) => {
      if (!response) {
        return;
      }
      clearTimeout(this.manualDebounce);
      this.manualDebounce = setTimeout(() => {
        const { items } = response;
        if (items && items.length > 0) {
          const barcodes = [].concat(
            ...items.map((item) => item.barcode.split(','))
          );
          this.productService.getQuantityByBarcodes(barcodes).then((res) => {
            this.lastRequestCorrelationId = res;
            this.requestResponseTimes++;
          });
        } else {
          this.setState({
            adicionalInfoProducts: [],
          });
        }
      }, 500);
    });

    return promise;
  }

  onBlur = (e) => {
    if (!e.target.value || parseFloat(e.target.value) === 0)
      this.setState({ quantity: 1 });
  };

  onChange(e) {
    if (!isNaN(parseInt(e.target.value, 10))) {
      const value = parseInt(e.target.value, 10);
      e.target.value = value > 2147483647 ? 2147483647 : value;
    }
    this.setState({
      quantity: e.target.value,
    });
    this.props.addQuantity(e.target.value);
  }

  advancedSearchSelected = (query) => {
    this.productService.startProductSearch(query);
  };

  openPreOrderList = () => {
    this.orderService
      .getPreOrderList()
      .catch((error) => this.context.showError(error));
    this.props.preOrderListShow();
  };
  toggleShowInfo = () => {
    this.setState({ showSearchInfo: !this.state.showSearchInfo });
  };

  clearStateOptions = () => {
    this.setState({
      isToggleOnOptionSearchRule: false,
      isToggleOnOptionStock: false
    });
  };

  handleShowMessage = (message, title) => {
    this.context.showMessage(
      message,
      title,
      this.handleFocus
    );
  }

  handleFocus = () => {
    this.focusProductField();
  }

  render() {
    const { viewManager } = this.props;
    const openedCards = Object.keys(viewManager).filter(
      (key) => viewManager[key]
    );
    const shouldShow =
      (openedCards.length <= 2 &&
        (!openedCards[0] ||
          openedCards[0] === ORDER_DETAILS ||
          openedCards[0] === 'showShortCutMenu')) ||
      this.props.shouldShowWithOthers;

    const { suggestionHeight } = this.state;

    return (
      shouldShow && (
        <SaleWrapper
          innerRef={this.containerRef}
          className={this.state.showSearchInfo ? 'showInfo' : ''}
        >
          <QuantityItemButton
            quantityCurrent={this.state.quantity}
            onBlur={this.onBlur}
            onChange={this.onChange}
            message={this.state.message}
          />
          <AutoComplete
            id='product-barcode'
            autoFocus
            component={Input}
            type='text'
            imagePath={BarcodeIcon}
            placeholder='Busque por nome ou pelo código de barras'
            maxLength={50}
            tabIndex='0'
            queryFn={this.getCacheProducts}
            propToShow='description'
            renderItem={this.renderItem}
            shortcutInput={getShortcut(PRODUCT_SEARCH_SHORTCUT)}
            onSelect={this.selectSpecificProduct}
            onSuggestByInput={this.selectProduct}
            advancedSearchSelected={this.advancedSearchSelected}
            showAdvancedSearchOption
            ref={(ref) => (this.autocompleteRef = ref)}
            containerStyle={{ position: 'initial' }}
            suggestionStyle={{ maxHeight: suggestionHeight }}
            itemsCustomStyle={this.state.showSearchInfo ? 'showInfo' : ''}
            hideItemsOnFocusOut={this.props.hideItemsOnFocusOut}
            clearStateOptions={this.clearStateOptions}
            adicionalInfoProducts={this.state.adicionalInfoProducts}
            handleShowMessage={this.handleShowMessage}
          />
          <div className='pre-order'>
            <Button
              id='autocomplete_preorder'
              className='btn btn-outline custom-tooltip'
              onClick={this.openPreOrderList}
              style={{ alignItems: 'center' }}
            >
              <span className='tooltiptext'>
                Pesquisar pré-venda por um identificador
              </span>
              <i className='material-icons'>shopping_cart</i>
              <ShortcutHint
                offsetLeft={0}
                offsetTop={0}
                shortcut={getShortcut(PRE_SALE_LIST)}
                activateShortcut={this.openPreOrderList}
              />
            </Button>
          </div>
          <div className='filterOptions'>
            <div className='info' onClick={this.toggleShowInfo}>
              <div>
                <strong>%</strong>: Caractere coringa. Usado para filtrar
                produtos que contenha texto no nome
              </div>
              <div>
                <b>Ex:</b> <strong>%</strong>ana
              </div>
            </div>
            {/* <div className='button' onClick={this.toggleShowInfo}>
              <i className='material-icons'></i>Opções de filtragem
            </div> */}
          </div>
        </SaleWrapper>
      )
    );
  }
}

SearchContainer.propTypes = {
  MessageBus: PropTypes.shape({
    Subscribe: PropTypes.func,
  }).isRequired,
  preOrderListShow: PropTypes.func.isRequired,
  openProductSearch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addQuantity: (quantity) => dispatch(addQuantity(quantity)),
  openProductSearch: (payload) => dispatch(showCard(PRODUCT_SEARCH, payload, true, true)),
  preOrderListShow: () => dispatch(showCard(PRE_ORDER_LIST, null, true, true)),
  openStockList: (item) => dispatch(showWithSearchBar(STOCKLIST, item))
});

const mapStateToProps = (state) => ({
  viewManager: state.viewManager,
  shouldShowWithOthers: shouldShow(state, SEARCH_CODE),
  hideItemsOnFocusOut: !shouldShow(state, STOCKLIST),
  isOnline: state.pdvContingency.isOnline
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(SearchContainer)));
