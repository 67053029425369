import styled from 'styled-components';

export const IdentifyUserWrapper = styled.div`
display: flex;
aling-content:end;
.btn-custom {
    margin: 10px 0 0 calc(100% - 127px);
    padding: 0 16px;
    color: #fff;
    background-color: #26a69a;
    letter-spacing: .5px;
    height: 50px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 2px;
    cursor: pointer;
    width: 120px;
  }
    font-size: 16px;
    
input {
    margin: 8px 0 8px 0;        
    padding-left: 20px;        
    width: calc(100% - 30px);  
    height: 50px;
    border:1px solid black;
    border-radius:3px; 
    font-size: 20px;  
}

   
`;
