import styled from 'styled-components';

export const DatepickerWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  align-items: center;

  .react-datepicker-popper {
    position: fixed !important;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
    }
  }

  i {
    position: absolute;
    padding-right: 5px;
    font-size: 22px;
    color: #5b2e90;

    &:hover {
      color: #ffb201;
      cursor: pointer;
    }

    &.disabled {
      color: #807f7c !important;
      &:hover {
        color: #807f7c !important;
        cursor: unset !important;
      }
    }
  }
`;

export const MainWrapper = styled.div`
  .invalidDate {
    position: absolute;
    color: red;
    margin-left: 5px;
  }
`;
