import React, { useEffect, useState } from 'react';

import MessageBusService from '../../../../services/MessageBusService';
import { MaskCPF } from '../../../utils/validates/cnpj-cpf';
import { ButtonComponent } from '../../button-component/button-component';
import Card from '../../card';
import { Field } from '../../field';
import { IRadioList } from '../../field/interface/radio.interface';
import { Container, Row } from '../objectpro-styled';
import './register-style.css';
import useDebounce from '../../../utils/useDebounce';
import { IRegisterObjectPro } from '../../../interfaces/register-objectpro';
import { verifyErrorInContainerDom } from '../../validation-errors/validation-errors';
import { showToast } from '../../../utils/util';
import { useSelector } from 'react-redux';

interface IobjectproProps {
  clickSave: (cliente: IRegisterObjectPro) => IRegisterObjectPro;
  cancel: () => any;
  getCep: (cep: string) => any;
  registerObject: IRegisterObjectPro;
}

class registerObjectPro implements IRegisterObjectPro {
  cartao: string = '';
  cpf: string = '';
  rg: string = '';
  nome: string = '';
  sexo = undefined;
  nascimento = undefined;
  telefone1: string = '';
  telefone2: string = '';
  email: string = '';
  cep: string = '';
  logradouro: string = '';
  numero: string = '';
  complemento: string = '';
  bairro: string = '';
  cidade: string = '';
  uf: string = '';
  cpfVendedor: string = '';
  observacao: string = '';
}

export default function ObjectProNewRegisterComponent(props: IobjectproProps) {
  const [cliente, setCliente] = useState<IRegisterObjectPro>(
    new registerObjectPro()
  );
  const [cep, setCep] = useDebounce(500, '');
  const [touched, setTouched] = useState(false);
  const [address, setAddress] = useState<{
    cep: string;
    rua: string;
    cidade: string;
    bairro: string;
    uf: string;
  }>();
  const seller = useSelector((state) => state.order.sellerId);

  useEffect(() => {
    if (!address) return;

    setCliente({
      ...cliente,
      cep: address.cep,
      logradouro: address.rua,
      cidade: address.cidade,
      bairro: address.bairro,
      uf: address.uf,
    });
  }, [address]);

  useEffect(() => {
    const cepReturn = MessageBusService.GetInstance().Subscribe(
      'pos.administrative.getCepReturn',
      (ch, payload) => setAddress(payload)
    );

    if (props.registerObject) {
      setCliente({
        ...props.registerObject,
      });
    }

    return () => {
      MessageBusService.GetInstance().Unsubscribe(cepReturn);
    };
  }, []);

  useEffect(() => {
    if (!cep) return;

    let cepWithoutMask = cep.replace(/\D/g, '');

    if (cepWithoutMask && cepWithoutMask.length === 8)
      props.getCep(cepWithoutMask);
  }, [cep]);

  const salvar = () => {
    setTouched(true);

    if (verifyErrorInContainerDom('card-cadastro-objectpro')) return;

    if (cliente.sexo === undefined) {
      showToast({
        type: 1,
        title: `Atenção`,
        html: 'Informe o sexo para continuar.',
        hideAfterMilliseconds: 5000,
      });

      return;
    }

    if (!seller) {
      showToast({
        type: 3,
        title: `Atenção`,
        html: 'É necessário informar o operador.',
        hideAfterMilliseconds: 5000,
      });

      document
        .getElementById('identifier-operator')
        ?.querySelector('.label-title-value-wrapper')
        ?.classList.add('has-error');

      return;
    }

    props.clickSave(cliente);
  };

  const cancel = () => {
    props.cancel();
  };

  return (
    <Card
      id='card-cadastro-objectpro'
      title={'OBJECTPRO - CADASTRO DE CLIENTE'}
      handleClose={() => cancel()}
      closeEnable
      autoScroll
      orientationVertical={true}
      marginButtonTitle={'.5rem'}
      escAction={() => cancel()}
    >
      <Container
        id='container-cadastro-cliente-objectpro'
        className='grid'
        data-container={true}
      >
        <Row>
          <div className='container-legend'>
            <div className='legend'>Informações principais</div>
            <div className='line'></div>
          </div>
        </Row>
        <Row className='grid-template-row-1'>
          <div className='flex-1' style={{ flex: '2' }}>
            <Field
              id='numero-cartao'
              placeholder='Informe o numero do cartão'
              labelText='Número de cartão'
              type='text'
              typeInput='custom'
              showError={true}
              value={cliente.cartao}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  cartao: e.target.value,
                })
              }
              autoFocus={true}
              touched={touched}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
            />
          </div>

          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='cpf'
              placeholder='Somente números'
              labelText='CPF'
              type='text'
              typeInput='normal'
              showError={true}
              value={MaskCPF(cliente.cpf)}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  cpf: e.target.value,
                })
              }
              autoFocus={false}
              maxLength={14}
              touched={touched}
            />
          </div>

          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='rg'
              placeholder='Somente números'
              labelText='RG'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.rg}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  rg: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>

          <div className='flex-1' style={{ flex: '3' }}>
            <Field
              id='nome'
              placeholder='Nome completo do cliente'
              labelText='Nome'
              type='text'
              typeInput='normal'
              showError={true}
              value={cliente.nome}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  nome: e.target.value,
                })
              }
              autoFocus={false}
              touched={touched}
            />
          </div>
        </Row>

        <Row className='grid-template-row-2'>
          <div className='flex-1'>
            <Field
              id='sexo'
              placeholder=''
              labelText='Sexo'
              type='text'
              typeInput={'radio'}
              showError={false}
              value={cliente.sexo}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  sexo: e.target.value,
                })
              }
              autoFocus={false}
              radioList={[
                {
                  name: 'sexo',
                  label: 'Masculino',
                  value: 1,
                } as IRadioList,
                {
                  name: 'sexo',
                  label: 'Feminino',
                  value: 2,
                } as IRadioList,
              ]}
            />
          </div>

          <div className='flex-1'>
            <Field
              id='dataNascimento'
              placeholder='dd/mm/aaaa'
              labelText='Data de nascimento'
              type='text'
              typeInput='datetime'
              showError={true}
              value={cliente.nascimento}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  nascimento: e,
                })
              }
              autoFocus={false}
              touched={touched}
            />
          </div>

          <div className='flex-1'>
            <Field
              id='telefone'
              placeholder='(99) 9999-9999'
              labelText='Telefone'
              type='text'
              typeInput='custom'
              showError={false}
              value={cliente.telefone1}
              mask={[
                '(',
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  telefone1: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>

          <div className='flex-1'>
            <Field
              id='celular'
              placeholder='(99) 9 9999-9999'
              labelText='Celular'
              type='text'
              typeInput='custom'
              showError={false}
              value={cliente.telefone2}
              mask={[
                '(',
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  telefone2: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>

          <div className='flex-1'>
            <Field
              id='email'
              placeholder='endereco@email.com'
              labelText='E-mail'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.email}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  email: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>
        </Row>

        <Row>
          <div className='container-legend'>
            <div className='legend'>Endereço</div>
            <div className='line'></div>
          </div>
        </Row>

        <Row className='grid-template-row-3'>
          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='cep'
              placeholder='99999-999'
              labelText='CEP'
              type='text'
              typeInput={'custom'}
              showError={false}
              value={cep}
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              onChange={(e) => setCep(e.target.value)}
              autoFocus={false}
            />
          </div>

          <div className='flex-1' style={{ flex: '2' }}>
            <Field
              id='rua'
              placeholder='Logradouro'
              labelText='Rua'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.logradouro}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  logradouro: e.target.value,
                })
              }
              autoFocus={false}
              touched={touched}
            />
          </div>

          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='numero'
              placeholder='9999'
              labelText='Número'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.numero}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  numero: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>

          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='complemento'
              placeholder='Informe o complemento'
              labelText='Complemento'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.complemento}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  complemento: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>
        </Row>

        <Row className='grid-template-row-4'>
          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='bairro'
              placeholder='Informe o bairro do cliente'
              labelText='Bairro'
              type='text'
              typeInput={'normal'}
              showError={false}
              value={cliente.bairro}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  bairro: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>

          <div className='flex-1' style={{ flex: '2' }}>
            <Field
              id='cidade'
              placeholder='Informe a cidade'
              labelText='Cidade'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.cidade}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  cidade: e.target.value,
                })
              }
              autoFocus={false}
              touched={touched}
            />
          </div>

          <div className='flex-1' style={{ flex: '1' }}>
            <Field
              id='uf'
              placeholder='UF'
              labelText='UF'
              type='text'
              typeInput='normal'
              showError={false}
              value={cliente.uf}
              onChange={(e) =>
                setCliente({
                  ...cliente,
                  uf: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>
        </Row>

        <Row>
          <div
            className='btn-group'
            style={{
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <ButtonComponent
              title='Voltar'
              name='btn-cancel'
              onClick={() => cancel()}
              style={{ marginRight: '1rem' }}
            />

            <ButtonComponent
              id={'btn-b-succes-save'}
              name='btn-save'
              className='btn-b-success'
              title={'Cadastrar cartão'}
              onClick={() => salvar()}
              style={{ width: '210px' }}
            />
          </div>
        </Row>
      </Container>
    </Card>
  );
}
