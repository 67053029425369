import styled from 'styled-components';

export const ListBoxItemWrapper = styled.li`
  list-style-type: none;
  padding: 0;
  box-sizing: border-box;
    &:focus {
      div {
        background-color: #FFB200;
        border: 1px solid #5B2E90;
      }
    }
    div.disabled
    {
      background-color: #fff;
      outline: none;
      user-select: none;
    }
    div.disabled > *
    {
      opacity: 0.3;
    }
    div.disabled::after{
      display: block;
      position: absolute;
      right: 10px;
      top 15px;
      font-size: 12px;
      content: "Clique aqui";
      color: #007bff;
      text-decoration: underline
    }
`;

export const ListBoxItemContentWrapper = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #d0d3d9;
  &:hover{
    background-color: #FFB200;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 6px;
    left: 14px;
  }
  span {
    position: absolute;
    text-align: center;
    vertical-align: center;
    line-height: -10px;
    top: 14px;
    left: 56px;
    font-weight: bold;
  }
  
`;
