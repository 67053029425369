import React from 'react';
import { ClientContainer, ContainerSolicitacao } from './styled';
import PasswordInput from '../password/password-input-component';
import Button from '../button';
import { FormControl } from '../form-control/form-control';
import AuthorizationOnline from '../authorization-online/authorization-online';
import { OperationEnum } from '../../enums/operation';
import { useSelector } from 'react-redux';

function CustomerInfo(props) {
  const passwordInputRef = React.createRef();
  const confirmBtnRef = React.createRef();

  const showAutorizationOnline = useSelector(
    (state) => state.showAutorizationOnline
  );

  const { handleClientAuthorityChange, authorizeSale } = props;
  const {
    status,
    statusDescription,
    message,
    creditLimit,
    amountSpent,
    balance,
    showAuthorizationForm,
    password,
    authenticationMessage,
    name,
  } = props.customerInfo;

  return (
    <ClientContainer>
      <div>
        {status != null && (
          <p>
            Situação Cliente: <span>{statusDescription}</span>
          </p>
        )}
        <p>{message}</p>
      </div>
      {status != null && (
        <div>
          <span>Limite:</span>
          <span>
            R${creditLimit !== null ? creditLimit.toFixed(2) : '0.00'}
          </span>
          <span>Débito:</span>
          <span>
            R${amountSpent !== null ? amountSpent.toFixed(2) : '0.00'}
          </span>
          <span>Saldo:</span>
          {balance >= 0 ? (
            <span>R${balance !== null ? balance.toFixed(2) : '0.00'}</span>
          ) : (
            <span className='red'>
              -R$ {balance !== null ? (balance * -1).toFixed(2) : '0.00'}
            </span>
          )}
        </div>
      )}

      {showAuthorizationForm && (
        <ContainerSolicitacao>
          {showAutorizationOnline?.show && (
            <>
              <AuthorizationOnline
                operation={OperationEnum.LiberacaoLimite}
                solicitation={{ customer: name }}
                lastCalledEndpoint={props.lastCalledEndpoint}
                handleClickConfirm={authorizeSale}
                handleClickCancel={() => undefined}
                validateFields={() => true}
              />

              <div className='authorization'>
                <FormControl
                  type='number'
                  pattern='[0-9]'
                  autoFocus
                  field='userReferenceId'
                  object={props.customerInfo}
                  placeholder='Usuário supervisor'
                  onKeyPress={(e) => {
                    const keyCode = e.keyCode || e.which;
                    const keyValue = String.fromCharCode(keyCode);
                    if (/\+|-|\.|,/.test(keyValue)) e.preventDefault();

                    if (e.key === 'Enter') {
                      passwordInputRef.current.focus();
                    }
                  }}
                  onChange={(e) =>
                    handleClientAuthorityChange({
                      target: { id: 'userReferenceId', value: e.target.value },
                    })
                  }
                ></FormControl>
                <PasswordInput
                  ref={passwordInputRef}
                  value={password}
                  placeholder='Senha supervisor'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      confirmBtnRef.current.focus();
                    }
                  }}
                  onChange={(e) =>
                    handleClientAuthorityChange({
                      target: { id: 'password', value: e.target.value },
                    })
                  }
                ></PasswordInput>
                <Button
                  ref={confirmBtnRef}
                  handleClick={authorizeSale}
                  className='btn-purple'
                >
                  Confirmar
                </Button>
                {authenticationMessage && authenticationMessage.length > 0 && (
                  <span>{authenticationMessage}</span>
                )}
              </div>
            </>
          )}
          {!showAutorizationOnline?.show && (
            <div className='authorization' style={{ flexDirection: 'row' }}>
              <FormControl
                type='number'
                pattern='[0-9]'
                autoFocus
                field='userReferenceId'
                object={props.customerInfo}
                placeholder='Usuário supervisor'
                onKeyPress={(e) => {
                  const keyCode = e.keyCode || e.which;
                  const keyValue = String.fromCharCode(keyCode);
                  if (/\+|-|\.|,/.test(keyValue)) e.preventDefault();

                  if (e.key === 'Enter') {
                    passwordInputRef.current.focus();
                  }
                }}
                onChange={(e) =>
                  handleClientAuthorityChange({
                    target: { id: 'userReferenceId', value: e.target.value },
                  })
                }
              ></FormControl>
              <PasswordInput
                ref={passwordInputRef}
                value={password}
                placeholder='Senha supervisor'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    confirmBtnRef.current.focus();
                  }
                }}
                onChange={(e) =>
                  handleClientAuthorityChange({
                    target: { id: 'password', value: e.target.value },
                  })
                }
              ></PasswordInput>
              <Button
                ref={confirmBtnRef}
                handleClick={authorizeSale}
                className='btn-purple'
              >
                Confirmar
              </Button>
              {authenticationMessage && authenticationMessage.length > 0 && (
                <span>{authenticationMessage}</span>
              )}
            </div>
          )}
        </ContainerSolicitacao>
      )}
    </ClientContainer>
  );
}
export default CustomerInfo;
