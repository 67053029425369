import React from 'react';
import PropTypes from 'prop-types';

import { ModalWrapper, Overlay, ModalHeader, ModalContent, ModalFooter, ButtonFooter, ModalFooterExtraContent } from './styled'
import KeyboardEventHandler from 'react-keyboard-event-handler';

class ModalNew extends React.Component {
  constructor(props) {
    super(props);
    
    this.modalWrapperRef = React.createRef();
    this.closeBtnRef = React.createRef();
    this.btnToFocusRef = React.createRef();
  }
  
  componentDidMount() {
    if (Array.isArray(this.props.buttons) && this.props.buttons.length > 0 && this.props.buttons.some(btn => btn?.shouldFocus === true)) {
      this.btnToFocusRef.current.focus();
      return;
    }
    
    this.closeBtnRef.current.focus();
  }
  
  render() {
    const { title, children, buttons, close, shouldHaveCloseButton, footerContent } = this.props;
    
    return (
      <KeyboardEventHandler 
        handleKeys={['esc']} 
        onKeyEvent={(key, e) => {if (key === 'esc') close();}} 
        isExclusive
      > 
        <Overlay>
          <ModalWrapper tabIndex={-1} innerRef={this.modalWrapperRef}>
            <ModalHeader>
              <h5>{title}</h5>
              <i className="material-icons" onClick={() => close()}>close</i>
            </ModalHeader>
            
            <ModalContent>
              {children}
            </ModalContent>
            
            <ModalFooter>
              <ModalFooterExtraContent>
                {footerContent}
              </ModalFooterExtraContent>
              
              {buttons && Array.isArray(buttons) && buttons.length > 0 && buttons.map(btn => (
                <ButtonFooter innerRef={btn.shouldFocus ? this.btnToFocusRef : undefined} color={btn.color} onClick={() => btn.onClick()}>{btn.text}</ButtonFooter>
              ))}
              
              {shouldHaveCloseButton && 
                <ButtonFooter innerRef={this.closeBtnRef} onClick={() => close()}>Fechar</ButtonFooter>
              }
            </ModalFooter>
          </ModalWrapper>
        </Overlay> 
      </KeyboardEventHandler>
      
    )
  }
}

ModalNew.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    shouldFocus: PropTypes.bool // esta propriedade deve ser única, somente um botão pode ser focado;
  })),
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  shouldHaveCloseButton: PropTypes.bool,
  footerContent: PropTypes.element
}

export default ModalNew;