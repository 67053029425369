import styled from 'styled-components';

export const Container = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 20px;
  list-style: none;
  flex: 1 1 auto;
`;
export const FooterContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;
  list-style: none;
  flex: 0 0 60px;
  box-shadow: 0 2px 2px 0 rgba(255,255,255,0.14), 0 3px 1px -2px rgba(255,255,255,0.12), 0 1px 15px 0 rgba(255,255,255,0.2);

  li {
    width: 100%;
  }
`;

