import React from 'react';
import { MessageDetail } from './style';
import { Modal } from '../modal2';
import parse from 'html-react-parser';
import Button from '../button';

export class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalMessage = React.createRef();
    this.state = { message: '' };
    this.field = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  closable = () => !!this.props.message.type;

  componentDidMount() {
    if (this.props.visible) {
      this.modalMessage.current.open();
      document.querySelector('.overlay').addEventListener('keydown', this.handleKeyDown);
      this.modalMessage.current.focus();
    }
  }

  componentDidUpdate() {
    this.modalMessage.current.focus();
  }

  componentWillUnmount() {
    document.querySelector('.overlay').removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (this.closable() && (e.key === 'Escape' || e.key === 'Enter')) {
      this.modalMessage.current.close();
    }
    e.preventDefault();
  }

  getIcon(type) {
    type = typeof (type) != 'undefined' ? type : 0;
    switch (type) {
      case 0://Processing
      default:
        return "cached";
      case 1://Warning
        return "warning";
      case 2://ok
        return "done";
      case 3://error
        return "error";
      case 99:
        return "help";
    }
  }

  render() {
    return (
      <Modal
        title={""}
        closable={false}
        ref={this.modalMessage}
        opened
        style={{
          margin: '0px 20%',
          overflow: 'hidden',
          maxWidth: '80vw',
          width: 'auto'
        }}
        zIndex="1100"
        handleClose={this.props.handleClose}
        closeOnOverlayClick={this.closable()}
      >
        <Modal.Body>
          <MessageDetail>
            {
              this.props.message
              && <i className="material-icons" icon={this.getIcon(this.props.message.type)}>{this.getIcon(this.props.message.type)}</i>
            }
            <p className="text">{parse(this.props.message.message)}</p>
            {this.props.message.moreInfo && this.props.message.moreInfo.length > 0 ?
              <div className="moreinfo">
                <div>Mais informações</div>
                <p>{parse(this.props.message.moreInfo)}</p>
              </div> : null
            }
            {
              this.closable() && <div className="close"><Button onClick={this.props.handleClose} >Fechar</Button></div>
            }
          </MessageDetail>
        </Modal.Body>
      </Modal>
    )
  }
}
export default InfoModal