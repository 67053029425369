import React, { LabelHTMLAttributes } from "react";

import Label from "./styled";

function LabelComponent(props: LabelHTMLAttributes<HTMLLabelElement>) {
    return (
        <Label>{props.children}</Label>
    )
}

export default LabelComponent;