import { AbstractService } from './abstractService';

class TefPbmSaleService extends AbstractService {
  constructor() {
    super('/TefPbmSale');
  }

  startSale = () => this.invokeAction('/startTefPbmSale');

  processParametersPbm = (params) => this.invokeAction('/processParametersSalePbm', params);

  cancelSalePbm = (param) => this.invokeAction('/cancelSalePbm', param);

  backSalePbm = () => this.invokeAction('/backSalePbm');

  openProductsPbm = (params) => this.invokeAction('/startProductsSalePbm', params);

  processProductsPbm = (params) => this.invokeAction('/processProductsSalePbm', params);
  
  applyProducts = (products) => this.invokeAction('/applyProductsSalePbm', products);

  reprintLastSalePbm = () => this.invokeAction('/reprintLastSalePbm');

  applyProductsInSalesConfirmationPbm  = authorizedItems => this.invokeAction('/applyProductsInSalesConfirmationPbm', { authorizedItems });

  confirmedSaleTransactionPbm = payload => this.invokeAction('/confirmedSaleTransactionPbm', payload);
}

export default new TefPbmSaleService();
