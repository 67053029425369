import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import {
  showCard,
  closeAllCards,
  DELIVERY,
  shouldShow,
} from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import CardDelivery from '../../shared/components/card-delivery';
import DeliveryService from '../../services/deliveryService';
import SaleService from '../../services/saleService';
import PaymentService from '../../services/paymentService';
import { OrderService } from '../../services/orderService';
import { RequireAuth } from '../../shared/components/modules';
import { showInfoModal } from '../../shared/utils/util';

export class CardDeliveryConteiner extends Component {
  title = 'CONTROLE DE DELIVERY';

  static propTypes = {
    showDelivery: PropTypes.bool,
    showDeliverySelector: PropTypes.func.isRequired,
    hideDeliverySelector: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    showDelivery: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.props.MessageBus.Subscribe('pos.delivery.control.show', this.handleShow);
    this.props.MessageBus.Subscribe('pos.delivery.listed', this.handleReturn);
    this.props.MessageBus.Subscribe('pos.delivery.updated', this.handleUpdated);
    this.props.MessageBus.Subscribe('pos.order.listOpened', this.handleIfFind);
    this.props.MessageBus.Subscribe('pos.delivery.refresh', this.handleIfFind);

    this.saleService = new SaleService();
    this.orderService = new OrderService();
    this.deliveryService = new DeliveryService();
    this.paymentService = new PaymentService();

    this.state = {
      data: [],
      status: [],
      check: false,
      buttonDisabled: 'disabled',
      requireReason: false,
      orderId: null,
      tpAmb: 2,
      filter: {
        query: '',
        deliveryStatus: 0,
      },
    };
  }

  handleIfFind = () => {
    if (this.props.showDelivery) {
      this.handleFind();
    }
  };

  handleChange = (e) => {
    let prop = this.state.filter;
    prop[e.target.id] = e.target.value;
    this.setState({
      filter: prop,
    });
  };

  handleShow = (ch, payload) => {
    this.setState({
      status: payload,
      filter: {
        query: '',
        deliveryStatus: 0,
      },
    });
    this.props.showDeliverySelector();
    this.handleFind();
  };

  handleUpdated = () => {
    this.handleFind();
    this.forceUpdate();
  };

  handleReturn = (ch, payload) => {
    this.setState({
      data: payload.map((item) => {
        if (
          item.deliveryInfo &&
          item.deliveryInfo.deliveryCustomer.deliveryCustomerAddress
        )
          item.deliveryInfo.deliveryCustomer.deliveryCustomerAddress.neighborhood =
            item.deliveryInfo.deliveryCustomer.deliveryCustomerAddress
              .neighborhood != null
              ? item.deliveryInfo.deliveryCustomer.deliveryCustomerAddress
                .neighborhood
              : ' ';
        return item;
      }),
    });
  };

  handleClose() {
    this.props.hideDeliverySelector();
    this.setState({
      data: [],
      status: [],
      check: false,
      requireReason: false,
      filter: {
        query: '',
        deliveryStatus: 0,
      },
    });
  }

  handleFind = () => {
    this.deliveryService.deliveryFind(this.state.filter);
    this.setState({ check: false });
    this.forceUpdate();
  };

  handleChangeSelected = (item) => {
    item.selected = !item.selected;
    var result = this.state.data.filter((item) => item.selected === true);
    if (result.length > 0 && item.deliveryInfo.deliveryStatus != 6)
      this.setState({ buttonDisabled: '' });
    else this.setState({ buttonDisabled: 'disabled' });
    this.forceUpdate();
  };

  handleCheck = () => {
    this.setState({ check: !this.state.check });
    this.state.data.map((item) => {
      item.selected = !this.state.check;
    });

    if (
      !this.state.check &&
      this.state.data[0].deliveryInfo.deliveryStatus != 6
    )
      this.setState({ buttonDisabled: '' });
    else this.setState({ buttonDisabled: 'disabled' });
    this.forceUpdate();
  };

  handleOutForDelivery = () => {
    var result = this.state.data.filter((item) => item.selected === true);
    this.deliveryService.updateDeliveryStatus(result, 4);
  };

  handlePrint = () => {
    var result = this.state.data.filter((item) => item.selected === true);
    this.deliveryService.printDelivery(result);
  };

  handleDelivered = () => {
    var result = this.state.data.filter((item) => item.selected === true);
    this.deliveryService.updateDeliveryStatus(result, 5);
  };

  handleAwaitingForDelivery = () => {
    var result = this.state.data.filter((item) => item.selected === true);
    this.deliveryService.updateDeliveryStatus(result, 3);
  };

  handleGetStatusPaymentLink = () => {
    var result = this.state.data.filter((item) => item.selected === true);
    this.deliveryService.getStatusPaymentLink(result);
  };

  handleRecovery = (order) => {
    if (order.paymentLinkInfo) this.deliveryService.paymentLinkSaleDone(order);
    else this.saleService.addItem(order.recoveryId, this.state.tpAmb);
    this.handleClose();
  };

  handleCancel = (order) => {
    if (order.status === 0) this.saleService.deleteOrderById(order.id);
    else {
      this.setState({
        requireReason: true,
        orderId: order.id,
      });
    }
  };

  handleRefreshPaymentLink = (order) => {
    this.deliveryService.handleRefreshPaymentLink(order);
  };

  handleSendToPosConnect = (order) => {
    this.paymentService.sendToPosConnect(order.id);
  };

  handleModalClose = () => {
    this.setState({
      requireReason: false,
    });
  };

  startCancelOperation = ({ reason }) => {
    if (reason.length < 15) {
      this.context.showMessage(
        'O motivo do cancelamento deve conter entre 15 e 255 caracteres.'
      );
      return;
    }
    this.setState({
      requireReason: false,
    });
    showInfoModal({ message: 'Buscando informações para o cancelamento' });
    this.orderService.startOrderCancellation(
      this.state.orderId,
      reason,
      this.props.tpAmb
    );
    this.handleClose();
  };

  render() {
    const { showDelivery } = this.props;

    return (
      <Fragment>
        {this.state.requireReason && (
          <RequireAuth
            title='Cancelar a venda'
            handleConfirm={this.startCancelOperation}
            handleClose={this.handleModalClose}
            requireUsername={false}
            requirePassword={false}
            requireReason
          />
        )}
        {showDelivery && (
          <CardDelivery
            data={this.state.data}
            status={this.state.status}
            title={this.title}
            buttonDisabled={this.state.buttonDisabled}
            check={this.state.check}
            filter={this.state.filter}
            handleChange={this.handleChange}
            handleCheck={this.handleCheck}
            handleClose={this.handleClose}
            handleRecovery={this.handleRecovery}
            handleOutForDelivery={this.handleOutForDelivery}
            handleGetStatusPaymentLink={this.handleGetStatusPaymentLink}
            handleAwaitingForDelivery={this.handleAwaitingForDelivery}
            handlePrint={this.handlePrint}
            handleDelivered={this.handleDelivered}
            handleCancel={this.handleCancel}
            handleRefreshPaymentLink={this.handleRefreshPaymentLink}
            handleSendToPosConnect={this.handleSendToPosConnect}
            handleFind={this.handleFind}
            handleChangeSelected={this.handleChangeSelected}
            posType={this.props.posType}
            isOnline={this.props.isOnline}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showDelivery: shouldShow(state, DELIVERY),
  tpAmb: state.config && state.config.danfe && state.config.danfe.tpAmb,
  posType: state.posType.posType,
  isOnline: state.pdvContingency.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
  showDeliverySelector: () => dispatch(showCard(DELIVERY, null, true)),
  hideDeliverySelector: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CardDeliveryConteiner)));
