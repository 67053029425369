/* eslint-disable react/no-direct-mutation-state */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { DiscountQuantity } from '../../shared/components/modules';
import SaleService from '../../services/saleService';

export class DiscountQuantityContainer extends React.Component {
  constructor(props) {
    super(props);

    this.saleService = new SaleService();

    this.state = {
      visible: false,
      data: {
        discountDefault : { description : ""},
        quantityDiscount : [],
        takePayDiscount : []
      }
    };

    this.props.MessageBus.Subscribe('pos.discountQuantity.show', this.handleShow);
    this.props.MessageBus.Subscribe('pos.order.updated', this.handleClose);

  }

  handleClose = () =>{
    this.setState({
      visible: false,
      data : {}
    });
  }

  handleChangeSelected = (item) => {
    this.state.data.takePayDiscount.map(s => s.selected = false);
    this.state.data.quantityDiscount.map(s => s.selected = false);
    this.state.data.discountDefault.selected = false;
    item.selected = !item.selected;
    this.forceUpdate();
  }

  handleShow = (ch, payload) => {
    this.setState({
      visible: true,
      data: payload
    });
  }

  handleCancel = () =>{
    this.saleService.deleteItem(this.state.data.discountDefault.productId, this.state.data.discountDefault.sequentialId);
    this.handleClose();
  }

  handleConfirm = () =>{
    var quantity = this.state.data.discountDefault.selected ? this.state.data.discountDefault.quantity : 0;
    if(quantity <= 0)
      quantity = this.state.data.quantityDiscount.filter(item => item.selected)[0]?.quantity ?? 0;
    if(quantity <= 0)
      quantity = this.state.data.takePayDiscount.filter(item => item.selected)[0]?.takeQuantity ?? 0;
    if (quantity <= 0) {
      this.context.showMessage('Nenhuma opção selecionada.');
      return;
    }

    var payload = {
      barcode: this.state.data.discountDefault.barcode,
      isFromPbm: false,
      quantity: quantity,
      sequentialId: this.state.data.discountDefault.sequentialId
    }
    this.saleService.updateItem(payload)
        .catch(error => this.context.showError(error));
  }

  render() {
    return (this.state.visible &&
      <Fragment>
        <DiscountQuantity
          data={this.state.data}
          handleCancel={this.handleCancel}
          handleConfirm={this.handleConfirm}
          handleChangeSelected={this.handleChangeSelected}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(DiscountQuantityContainer)));
