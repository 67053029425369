import styled from 'styled-components';

import { DatePicker } from '../../../../shared/components/date-picker';

export const InputDate = styled(DatePicker)`
  &:focus {
    border-color: #d5ae62;
    box-shadow: 0px 0px 0px 1px #f2cb80;
  }

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;

  width: 100%;
  height: 40px;

  :disabled {
    background: #f1f3f6;
  }
`;
