import React, { useEffect, useState } from 'react';

import { ModalComponent } from '../modal-component';
import { ContainerComponent } from '../container';
import { Field } from '../field';
import { ButtonComponent } from '../button-component';
import { AbasComponent } from '../abas-component';
import { IAbas } from '../abas-component/abas-component';
import { Authorization } from '../authorization/authorization';
import { verifyErrorInContainerDom } from '../validation-errors/validation-errors';
import MessageBusService from '../../../services/MessageBusService';
import { OperationEnum } from '../../enums/operation';
import { ISolicitationSangriaDespesaContract } from '../../interfaces/solicitation-sangria-despesa-contract';

interface INewCashWithDraw {
  user: any;
  handleClickConfirmExcess: any;
  handleClickConfirmPaymentExpenses: any;
  handleClickCancel: any;
}

enum typeWithDrawEnum {
  Excess = 1,
  PaymentExpenses = 2,
}

function NewCashWithDraw(props: INewCashWithDraw) {
  const [typeWithDraw, setTypeWithDraw] = useState<typeWithDrawEnum>(
    typeWithDrawEnum.Excess
  );
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [typeDespesa, setTypeDespesa] = useState('');
  const [touched, setTouched] = useState(false);
  const [abasSangria, setAbasSangria] = useState([
    {
      abaName: 'Excesso',
      selected: true,
    },
    {
      abaName: 'Pagamento de Despesas',
      selected: false,
    },
  ]);
  const [hasPermission, setHasPermission] = useState(true);
  const [lastCalledEndpoint, setLastCalledEndPoint] = useState('');
  const [hasAutorizationOnline, setHasAutorizationOnline] = useState<boolean>(
    true
  );

  const handleClickAbas = (abas: IAbas[]) => {
    setAbasSangria(abas);
    let index = abas.findIndex(
      (x) => x.abaName == abas.filter((x) => x.selected)[0].abaName
    );
    setTypeWithDraw(
      index == 0 ? typeWithDrawEnum.Excess : typeWithDrawEnum.PaymentExpenses
    );
  };

  const confirmar = () => {
    setTouched(true);

    if (verifyErrorInContainerDom('container-cash-withdraw')) return;

    setHasPermission(true);

    if (typeWithDraw === typeWithDrawEnum.Excess) {
      let objectToConfirm = {
        Amount: -amount,
        Description: description,
      };

      props.handleClickConfirmExcess(objectToConfirm);
    }

    if (typeWithDraw === typeWithDrawEnum.PaymentExpenses) {
      let objectToConfirm = {
        Amount: -amount,
        Description: description,
        ExpenseType: typeDespesa,
      };

      props.handleClickConfirmPaymentExpenses(objectToConfirm);
    }
  };

  const cancel = () => {
    props.handleClickCancel();
  };

  useEffect(() => {
    const requireAuthCashDesk = MessageBusService.GetInstance().Subscribe(
      'pos.open.requireAuthCashDesk',
      (ch, payload) => {
        setLastCalledEndPoint(payload.lastCalledEndpoint);
        setHasPermission(false);
      }
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(requireAuthCashDesk);
    };
  }, []);

  if (!hasPermission)
    return (
      <Authorization
        title={'Autorização de sangria'}
        labelTextUser={'Usuário'}
        btnAuthorizationTitle={'Autorizar Sangria'}
        handleClickConfirm={confirmar}
        handleClickCancel={props.handleClickCancel}
        authorizeOperator={false}
        lastCalledEndpoint={lastCalledEndpoint}
        showAutorizationOnline={
          abasSangria.some(
            (x) => x.selected && x.abaName === 'Pagamento de Despesas'
          ) && hasAutorizationOnline
        }
        operation={OperationEnum.SangriaDespesa}
        solicitation={
          {
            identificaoDespesa: typeDespesa,
            valor: amount,
          } as ISolicitationSangriaDespesaContract
        }
      />
    );

  return (
    <ModalComponent title='Sangria' show={true} onClose={() => cancel()}>
      <ContainerComponent id='container-cash-withdraw'>
        <div className='row'>
          <AbasComponent abas={abasSangria} handleClick={handleClickAbas} />
        </div>
        <div className='row' style={{ display: 'flex' }}>
          <Field
            id='valor-withdraw'
            placeholder='Informe o valor para retirada'
            labelText='Valor retirado'
            type='text'
            typeInput='money'
            showError={true}
            value={amount}
            onChange={(event, maskedValue, floatvalue) => setAmount(floatvalue)}
            touched={touched}
            autoFocus={true}
            styledForInputGroup={{
              style: {
                marginRight: '.3rem',
                flex: 'calc(1/2)',
              },
            }}
          />

          {typeWithDraw == typeWithDrawEnum.PaymentExpenses && (
            <Field
              id='descricao-tipo-despesa'
              placeholder='Tipo de despesa'
              labelText='Identifique a despesa'
              type='text'
              typeInput='normal'
              showError={true}
              value={typeDespesa}
              onChange={(event) => setTypeDespesa(event.target.value)}
              touched={touched}
              styledForInputGroup={{
                style: {
                  marginLeft: '.3rem',
                },
              }}
              maxLength={40}
            />
          )}
        </div>
        <div className='row'>
          <Field
            id='textarea-observacao'
            placeholder='observações'
            labelText='Observações'
            type=''
            typeInput='textarea'
            showError={false}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={100}
            rows={1}
            wrap='hard'
          />
        </div>
        <div className='row'>
          <div
            className='btn-group'
            style={{
              flexDirection: 'row-reverse',
            }}
          >
            <ButtonComponent
              className='btn-b-success'
              title={'Confirmar'}
              onClick={confirmar}
            />

            <ButtonComponent
              title={'Cancelar'}
              name='btn-cancel'
              onClick={() => cancel()}
            />
          </div>
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}

export default NewCashWithDraw;
