import React, { useEffect, useState } from 'react';

import { ModalComponent } from '../modal-component/modal-component';
import { ContainerComponent } from '../container/container-component';
import { ButtonComponent } from '../button-component/button-component';
import { Field } from '../field/field';
import { Authorization } from '../authorization/authorization';
import { verifyErrorInContainerDom } from '../validation-errors/validation-errors';
import MessageBusService from '../../../services/MessageBusService';

interface INewCashSupply {
  handleClickConfirm: any;
  handleClickCancel: any;
}

interface ICashSupply {
  amount: number | string;
  description: string;
}

export function NewCashSupply(props: INewCashSupply) {
  const [cashSupply, setCashSupply] = useState<ICashSupply>({
    amount: 0,
    description: '',
  });
  const [touched, setTouched] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const [lastCalledEndPoint, setLastCalledEndPoint] = useState('');

  const handleChangeMoeda = (event, maskedvalue, floatvalue) => {
    setCashSupply({
      ...cashSupply,
      ['amount']: floatvalue,
    });
  };

  const confirmar = (user?: { user: number; password: string }) => {
    setTouched(true);

    if (verifyErrorInContainerDom('container-cash-supply')) return;

    props.handleClickConfirm(cashSupply);
  };

  const cancel = () => {
    props.handleClickCancel();
  };

  useEffect(() => {
    const requireAuthCashDesk = MessageBusService.GetInstance().Subscribe(
      'pos.open.requireAuthCashDesk',
      (ch, payload) => {
        setLastCalledEndPoint(payload.lastCalledEndpoint);
        setHasPermission(false);
      }
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(requireAuthCashDesk);
    };
  }, []);

  if (!hasPermission)
    return (
      <Authorization
        title={'Autorização de suprimento'}
        labelTextUser={'Usuário'}
        btnAuthorizationTitle={'Autorizar Suprimento'}
        handleClickConfirm={confirmar}
        handleClickCancel={cancel}
        authorizeOperator={false}
        lastCalledEndpoint={lastCalledEndPoint}
      />
    );

  return (
    <ModalComponent
      title='Suprimento de caixa'
      show={true}
      onClose={() => cancel()}
    >
      <ContainerComponent id='container-cash-supply'>
        <div className='row'>
          <Field
            id='valor-supply'
            placeholder='Informe o valor inicial'
            labelText='Valor'
            type='text'
            typeInput='money'
            showError={true}
            value={cashSupply.amount}
            onChange={handleChangeMoeda}
            touched={touched}
            autoFocus={true}
          />
        </div>
        <div className='row'>
          <Field
            id='textarea-observacao'
            placeholder='observações'
            labelText='Observações'
            type=''
            typeInput='textarea'
            showError={false}
            value={cashSupply.description}
            onChange={(e) =>
              setCashSupply({
                ...cashSupply,
                description: e.target.value,
              })
            }
            maxLength={100}
            rows={1}
            wrap='hard'
          />
        </div>
        <div className='row'>
          <div
            className='btn-group'
            style={{
              flexDirection: 'row-reverse',
            }}
          >
            <ButtonComponent
              className='btn-b-success'
              title={'Confirmar'}
              onClick={() => confirmar()}
            />
            <ButtonComponent
              title={'Cancelar'}
              name='btn-cancel'
              onClick={() => cancel()}
            />
          </div>
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}
