import React, { useState, useEffect } from 'react';

import { ValidationErrors } from '../../validation-errors/validation-errors';
import { IInputBase } from '../interface/input.interface';
import { InputMaskMoney } from './input-money.style';

function InputMoneyComponent(props: IInputBase) {
  const [touched, setTouched] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [className, setClassName] = useState('');

  useEffect(() => setClass(), [hasError]);

  useEffect(() => setClass(), [touched]);

  useEffect(() => setTouched(props.touched as boolean), [props.touched]);

  const setClass = () => {
    setClassName(touched && hasError ? 'has-error' : hasError ? 'error' : '');
  };

  const onChangeSetError = (value: boolean) => {
    setHasError(value);
  };

  const onBlur = () => {
    setTouched(true);
  };

  return (
    <>
      <InputMaskMoney
        className={className}
        id={props.id}
        data-testid={props.id}
        placeholder={props.placeholder}
        decimalSeparator=','
        thousandSeparator='.'
        value={props.value}
        onBlur={onBlur}
        autoFocus={props.autoFocus}
        onChangeEvent={props.onChange}
        maxLength={10}
      />
      {props.showError && (
        <ValidationErrors
          fieldNameId={props.id}
          fieldValue={props.value}
          typeError='money'
          touched={touched}
          onChangeEvent={onChangeSetError}
        />
      )}
    </>
  );
}

export default InputMoneyComponent;
