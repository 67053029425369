import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const DiscountInfoWrapper = styled.div`
  position: absolute;
  top: 74px;
  display: flex;
  flex-direction: row;
  font-size: 0.7em;
  font-family: Dosis;
  font-weight: bold;
  justify-content: space-between;
  width: 100%;

  .group {
    display: flex;
    flex-direction: column;

    .errorMessage {
      span:first-child {
        padding-right: 2px;
      }
    }
  }

  div {
    width: 100%;
  }

  .red-color {
    color: red;
  }

  .green-color {
    color: #42b72a;
  }

  .disabled-color {
    color: #807f7c;
  }
`;

export const DetailWrapper = styled.div`
  margin-top: ${theme.metrics.baseMargin}px;
  width: 100%;

  .custom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
  }

  .green-colorIncreasePrice {
    color: #42b72a; 
    margin-top: 10px   
  }

  .groupIncreasePrice {
    display: flex;
    flex-direction: column;
    font-size: 0.7em;
    .errorMessage {
      span:first-child {
        padding-right: 2px;
      }
    }
  }

  &.loading {
    opacity: 0.4;
  }

  .aside-right {
    width: 210px;
  }
  .aside-item span {
    font-weight: bold;
  }

  .discount-area__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    align-items: center;
    padding: 20px 15px;

    .discount-area__input {
      width: 100%;
      height: 30px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      position: relative;

      > input {
        width: calc(100% - 30px);
        border: none;
        background: none;
        height: 30px;
        padding-left: 30px;
        text-indent: -30px;
      }

      .discount-area__prefix {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }

    label {
      white-space: nowrap;
    }
  }

  .discount-area__footer {
    padding: 10px;
  }

  .discount-type {
    border: 1px solid #5b2e90;
    display: grid;
    grid-template-columns: 30px 30px;
    grid-auto-rows: 20px;
    position: absolute;
    top: 4px;
    right: 6px;
    border-radius: 5px;

    span:last-child {
      border-left: 1px solid #000000;
    }
  }

  .discount-type__button {
    height: 100%;
    text-align: center;
    align-self: center;
    cursor: pointer;
  }

  .discount-type__button--active {
    background-color: #5b2e90;
    color: #fff;
  }

  .disabled {
    background-color: #ebebe4;
    color: #000000;
    border: 1px solid #000000;
  }

  .message-error {
    grid-column: 1/-1;
    font-size: 0.85em;
    color: #f00;
  }
`;

export const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: -170px 0px 0;
  height: 240px;

  .discount-area {
    border: 1px solid lightgray;
    background-color: white;
    width: 268px;
    position: absolute;
    top: 160px;
    left: calc(100% - 500px);
  }

  .discount-area__header {
    padding: 10px 20px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .discount-area__header-title {
    margin: 0;
  }

  .discount-area__header button {
    font-size: 1.25em;
  }

  .discount-area__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 0 20px;
  }

  .discount-type {
    display: flex;
    border: 1px solid #5b2e90;
    justify-content: stretch;
  }

  .discount-type__button {
    width: 50%;
    height: 100%;
    text-align: center;
    align-self: center;
    cursor: pointer;
    line-height: 30px;
  }

  .discount-type__button--active {
    background-color: #5b2e90;
    color: #fff;
  }
`;

export const Sumary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;

  .sub {
    margin-top: 14px;
    justify-content: space-between;
  }

 

  .total-wrapper {
    border-top: 1px solid ${theme.palette.border};
    padding-top: ${theme.metrics.basePadding}px;
  }
  .btn-outline {
    border: 2px solid #e9e9e9;
    color: #5b2e90;
  }

  .total-wrapper,
  .discount-wrapper,
  .subtotal-wrapper {
    strong {
      float: right;
    }
  }

  input {
    width: 57px;
    height: 36px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    text-align: center;
  }
`;

export const Details = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${theme.metrics.baseMargin * 3}px;

  h4 {
    margin-bottom: 14px;
  }
`;

export const DetailItem = styled.div`
  position: relative;
  font-weight: bold;
  text-transform: uppercase;

  h4 {
    font-weight: normal;
    font-size: 14px;
    color: black;
    padding-bottom: 8px;
    border-bottom: 1px solid ${theme.palette.border};
    text-transform: none;
  }

  input {
    width: 80%;
    min-width: 70px;
    border: 1px solid ${theme.palette.buttonBorder};
    padding: 5px;
    border-radius: 5px;
    font-family: Arial;
    height: 29px;
    font-size: 14px;
    text-align: center;

    &:disabled {
      background: ${theme.palette.lightGray};
      color: black;
    }

    &:focus{
      border: 1px solid ${theme.palette.primary};
      outline: none;
    }
  }
  .subtotal-input, .discount-input {
    min-width: 100px;
  }
  
  .no_discount_info {
    font-size: 10px;
    font-weight: bold;
    color: red;
    position: absolute;
    top: 24px;
    font-family: Dosis;
    text-transform: none;
  }

  .offer {
    height: 25px;
    border-bottom: 1px solid #d3d1d1;
  }

  .btn-offer {
    position: absolute;
    top: 42px;
    width: 60%;
    left: 20%;
    padding-left: 0;
    padding-right: 0;
  }
  
`;

export const AsideLeft = styled.div`
  width: 210px;

  div {
    padding-bottom: ${theme.metrics.baseMargin * 3}px;
  }

  h4 {
    border-bottom: 1px solid ${theme.palette.border};
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-weight: normal;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  div {
    flex: 1 1 60%;
  }
  div:nth-child(2){
    margin-left: 10px;
    flex: 1 1 40%;
  }
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f0eefb;
  padding: 20px;

  div:first-child{
    text-align: left;
  }
  div {
    flex: 1 1 50%; 
    text-align: right;
  }

  div.under-foot{
    display: flex;
  }

  button.btn-stock{
    background-color: transparent;
    color: #9376b8 !important;
    border: 2px solid #5b2e90;
    border-radius: 4px;
  }
  button.btn-stock:hover{
    color: #9376b8 !important;
    background-color: #fff;
  }
  button.btn-purple {
    display: inline-block;
  }
  button.btn-stock img {
    width: 20px;
    vertical-align: sub;
    margin-right: 5px;
  }
  button.btn-outline{
    background-color: #fff;
  }
  button.btn-custom{
    width: 120px;
  }
`;
