import React from 'react';
import { Card, Button } from '../modules';
import { Form, Col } from 'react-bootstrap';
import { RadioButton } from '../radio-button';
import { DatePicker } from '../date-picker';
import { Modal } from '../modal2';
import './pbm-initialization-orizon-component.scss';

function PbmInitializationOrizonComponent(props) {
    const {
        handleBack,
        handleAuthorize,
        discountValues,
        handleDiscountChange,
        form,
        formStep,
        handleChangeStep,
        handleChange,
        plans,
        handleZoomCard,
        modalRef,
        handleContinuedUseChange,
        handleReload
    } = props;
    return (
        <>
            <Card id="pbm-init-orizon-card" autoScroll={true} title="Pré-Autorização Orizon" handleClose={handleBack} closeEnable shouldFlex >
                <div className="body">
                    {formStep === 1 && <div className="group">
                        <span>Informações do plano</span>
                        <Form>
                            <Form.Row>
                                <Col>
                                    <Form.Group className="plans">
                                        <Form.Label>Plano*</Form.Label>
                                        <Form.Control as="select" onChange={handleChange} id="plano" autoFocus
                                            custom>
                                            {
                                                plans.map((p, i) => <option selected={p.selected ? "selected" : ""} key={i} id={p.codigoConvenio}>{p.nomeConvenio}</option>)
                                            }
                                        </Form.Control>
                                        <span className="updatePlans" onClick={handleReload}>
                                            <i className="material-icons">info</i>
                                            Atualizar Planos
                                        </span>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Comunicado - Instruções do plano</Form.Label>
                                        <Form.Control as="textarea" rows={3} readOnly
                                            defaultValue={form.plano.description}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div className="usercard">
                                        <span>Clique para ampliar</span>
                                        <div>
                                            <img onClick={handleZoomCard} src={form.plano.card} alt=""></img>
                                        </div>
                                    </div>
                                </Col>
                            </Form.Row>
                        </Form>
                    </div>}

                    {formStep === 1 && <div className="inlineGroup">
                        <div className="inlineSubGroup">
                            <span className="benef">Dados do beneficiário</span>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Número Carteirinha*</Form.Label>
                                        <Form.Control
                                            id="Cartao"
                                            placeholder="Núm. Carteirinha"
                                            defaultValue={form.beneficiario.cartao}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Data de nascimento*</Form.Label>
                                        <DatePicker
                                            placeholder="12/05/1984"
                                            selected={form.beneficiario.dataNascimento}
                                            className="form-control"
                                            onChange={(value) => {
                                                handleChange({ target: { id: 'DataNascimento', value } })
                                            }}>
                                        </DatePicker>
                                    </Form.Group>
                                    <Form.Group as={Col} >
                                        <Form.Label>Desconto em folha?</Form.Label>
                                        <RadioButton values={discountValues} onChange={handleDiscountChange} />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                        <div className="inlineSubGroup">
                            <span className="receita">Dados da receita</span>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Conselho</Form.Label>
                                        <Form.Control as="select"
                                            id="Conselho"
                                            placeholder="Selecione"
                                            selected={form.receita.conselho}
                                            custom
                                            onChange={handleChange}>
                                            <option>CRM</option>
                                            <option>CRA</option>
                                            <option>CRV</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Número do conselho*</Form.Label>
                                        <Form.Control
                                            id="NumConselho"
                                            placeholder="Núm. do conselho"
                                            defaultValue={form.receita.numero}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>UF*</Form.Label>
                                        <Form.Control as="select"
                                            id="UFConselho"
                                            placeholder="Selecione"
                                            custom
                                            defaultValue={form.receita.uf}
                                            onChange={handleChange}>
                                            <option>Selecione</option  >
                                            <option>AC</option>
                                            <option>AL</option>
                                            <option>AP</option>
                                            <option>AM</option>
                                            <option>BA</option>
                                            <option>CE</option>
                                            <option>DF</option>
                                            <option>ES</option>
                                            <option>GO</option>
                                            <option>MA</option>
                                            <option>MT</option>
                                            <option>MS</option>
                                            <option>MG</option>
                                            <option>PA</option>
                                            <option>PB</option>
                                            <option>PR</option>
                                            <option>PE</option>
                                            <option>PI</option>
                                            <option>RJ</option>
                                            <option>RN</option>
                                            <option>RS</option>
                                            <option>RO</option>
                                            <option>RR</option>
                                            <option>SC</option>
                                            <option>SP</option>
                                            <option>SE</option>
                                            <option>TO</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Data da Receita</Form.Label>
                                        <DatePicker
                                            id="fieldData"
                                            selected={form.receita.dataReceita}
                                            className="form-control"
                                            onChange={(value) =>
                                                handleChange({ target: { id: 'DataReceita', value } })
                                            }>
                                        </DatePicker>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div >
                    </div>
                    }
                    {formStep === 2 && <div className="group">
                        <span>Listagem de produtos</span>
                        <ul className="tblHeader">
                            <li>Nome</li>
                            <li>Preço(R$)</li>
                            <li>Quantidade</li>
                            <li>Uso contínuo</li>
                        </ul>
                        {
                            form.produtos.map((item, i) =>
                                <ul className="tblRow" key={i}>
                                    <li>{item.product}</li>
                                    <li>{item.value}</li>
                                    <li>{item.quantity}</li>
                                    <li>
                                        <RadioButton values={item.radioValues} onChange={handleContinuedUseChange} id={i} setFocus={i === 0} />
                                    </li>
                                </ul>
                            )
                        }
                    </div>
                    }
                    <div className="footer">
                        {formStep === 1 && <Button id="confirm" className="btn  btn-purple btn-custom" onClick={handleChangeStep}>Próximo</Button>}
                        {formStep === 2 && <Button id="nextstep" className="btn  btn-purple btn-custom" onClick={handleAuthorize}>Atualizar Venda</Button>}
                    </div>
                </div>
            </Card>
            <Modal
                title=""
                closeOnOverlayClick
                opened={false}
                ref={modalRef}
                style={{
                    whiteSpace: 'nowrap'
                }}>
                <Modal.Body>
                    <img src={form.plano.card} alt=""></img>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="closeimg" className="btn  btn-purple btn-custom" onClick={handleZoomCard}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}
export default PbmInitializationOrizonComponent;