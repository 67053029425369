import styled from 'styled-components';
import theme2 from '../../../themes/theme2';

export const Icon = styled.i`
  font-size: ${props => props.size ?? "24px"};
  color: ${props => props.color ?? theme2.palette.primary};

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }
`