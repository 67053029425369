import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const BackModalWraper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  z-index: 1002;    
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;  
  backdrop-filter: blur(5px);
`;

export const ModalWraper = styled.div`
  line-height: 20px;
  background: white;

  justify-self: center;
  align-self: center;

  border-radius: 4px;
  padding: ${theme.metrics.basePadding * 3}px;

  min-width: 300px;
  width: min-content;

  display: flex;
  flex-direction: column;
  overflow: hidden;    
`;

export const ModalHeader = styled.div`
  background: white;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  padding: unset;
  min-height: min-content;

  .title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 22px;
    font-weight: 500;
    font-family: Dosis;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    line-height: 32px;
  }

  .close {
    position: absolute;
    padding: 0;
    margin: 0;
    opacity: 1;
    
    &:hover{
      opacity: 1 !important;
    }
    
    i {
      color: #60656c;
      cursor: pointer;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  overflow: auto;
  padding: ${theme.metrics.basePadding * 2}px;
  margin: 0px;
  flex-grow: 1;
`;

export const ModalFooter = styled.div`
  background: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: min-content;

  button {
    min-width: 50px;
  }
`;
