import React from 'react';
import { Card } from '../modules';
import { ConfigFarmaciaPopular } from '../config-pbm-farmacia-popular'
import { ConfigEpharma } from '../config-pbm-epharma'
import { PbmConfigOrizon } from '../pbm-config-orizon'
import { PbmConfigSeven } from '../pbm-config-seven'
import { PbmConfigTrnCentre } from '../pbm-config-trncentre'
import { PbmConfigFuncional } from '../pbm-config-funcional'
import { Tab, Tabs } from 'react-bootstrap';
import './config-pbm-component.scss';


function ConfigPBMComponent(props) {
    const {
        handleClose,
        handleChangeOrizon,
        handleEnvironmentChangeOrizon,
        toggleLoading,
        environmentValuesOrizon,
        formDataOrizon,
        checkFormDataOrizon,
        environmentValuesFuncional,
        handleEnvironmentChangeFuncional,
        formFuncional,
        handleChangeFuncional,
        callMutationFuncional,
        environmentValuesEpharma,
        handleEnvironmentChangeEpharma,
        formEpharma,
        handleChangeEpharma,
        handleConfirmEpharma,
        formDataFarmaciaPopular,
        handleChangeFarmaciaPopular,
        environmentValuesFarmaciaPopular,
        handleEnvironmentChangeFarmaciaPopular,
        handleConfirmFarmaciaPopular,
        handleClickCadastrarComputador,
        terminalFarmaciaPopular,
        handleChangeSeven,
        handleConfirmSeven,
        formSeven,
        handleConfirmTrnCentre,
        handleLoadTableTrnCentre,
        handleChangeTrnCentre,
        formTrnCentre,
        retailerConfigs
    } = props;

    return (
        <Card id="config-pbm-card" autoScroll={false} title="Configurações PBM" handleClose={handleClose} closeEnable shouldFlex >
            <Tabs defaultActiveKey="fp" id="tabs">
                <Tab eventKey="fp" title="Farmácia Popular">
                    <ConfigFarmaciaPopular class="config-fp"
                        form={formDataFarmaciaPopular}
                        handleChange={handleChangeFarmaciaPopular}
                        enviromentValues={environmentValuesFarmaciaPopular}
                        handleEnvironmentChange={handleEnvironmentChangeFarmaciaPopular}
                        handleConfirm={handleConfirmFarmaciaPopular}
                        handleClickCadastrarComputador={handleClickCadastrarComputador}
                        terminalFarmaciaPopular={terminalFarmaciaPopular}
                    ></ConfigFarmaciaPopular>
                </Tab>
                <Tab eventKey="epharma" title="ePharma">
                    <ConfigEpharma
                        environmentValues={environmentValuesEpharma}
                        handleEnvironmentChange={handleEnvironmentChangeEpharma}
                        form={formEpharma}
                        handleChange={handleChangeEpharma}
                        handleConfirm={handleConfirmEpharma}
                    ></ConfigEpharma>
                </Tab>
                <Tab eventKey="funcional" title="Funcional Card">
                    <PbmConfigFuncional
                        environmentValues={environmentValuesFuncional}
                        handleEnvironmentChange={handleEnvironmentChangeFuncional}
                        form={formFuncional}
                        handleChange={handleChangeFuncional}
                        callMutation={callMutationFuncional}
                    ></PbmConfigFuncional>
                </Tab>
                <Tab eventKey="orizon" title="Orizon">
                    <PbmConfigOrizon
                        handleChange={handleChangeOrizon}
                        handleEnvironmentChange={handleEnvironmentChangeOrizon}
                        toggleLoading={toggleLoading}
                        environmentValues={environmentValuesOrizon}
                        form={formDataOrizon}
                        handleCheckValues={checkFormDataOrizon}
                    ></PbmConfigOrizon>
                </Tab>
                <Tab eventKey="sevenPdv" title="SevenPDV">
                    <PbmConfigSeven
                        handleChange={handleChangeSeven}
                        handleConfirm={handleConfirmSeven}
                        form={formSeven}
                    ></PbmConfigSeven>
                </Tab>
                {retailerConfigs?.filter(
                    (x) => x.name === 'PBM_PORTAL_DA_DROGARIA' && x.value === 'true'
                ).length > 0 ?
                    <Tab eventKey="trnCentre" title="Portal da Drogaria">
                        <PbmConfigTrnCentre
                            handleChange={handleChangeTrnCentre}
                            handleConfirm={handleConfirmTrnCentre}
                            form={formTrnCentre}
                            handleLoadTable={handleLoadTableTrnCentre}
                        ></PbmConfigTrnCentre>
                    </Tab>
                    : ""}
            </Tabs>
        </Card>
    );
}
export default ConfigPBMComponent;