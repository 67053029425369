import React from 'react';

import ptBr from 'date-fns/locale/pt-BR';
import * as moment from 'moment';
import Calendar, { registerLocale } from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';

import { DatepickerWrapper, MainWrapper } from './datepicker-style';

import './react-datepicker.css';

registerLocale('pt-BR', ptBr);

export class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      invalidMessage: null,
    };

    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
    this.calendarBlur = this.calendarBlur.bind(this);
    this.focus = this.focus.bind(this);
    this.inputBlur = this.inputBlur.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isValidDate = this.isValidDate.bind(this);

    this.mouseOverIcon = false;

    this.calendarRef = React.createRef();
  }

  componentDidUpdate() {
    this.secondBlur = this.props.onBlur;
  }

  handleKeyEvent(e) {
    this.props.handleKeyDown && this.props.handleKeyDown(e);
    if (!this.state.open) {
      switch (e.code) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
          e.stopPropagation();
          break;
        case 'Space':
          e.stopPropagation();
          this.calendarRef.current.input.inputElement.value = moment().format(
            'DD/MM/YYYY'
          );
          break;
        default:
          break;
      }
    }

    if (e.key === 'Escape' && this.state.open) {
      e.stopPropagation();
      this.closeCalendar();
    } else if (this.state.invalidMessage) {
      this.setState({
        invalidMessage: null,
      });
    }
  }

  closeCalendar() {
    this.setState({ open: false });
  }

  calendarBlur() {
    if (this.secondBlur) {
      this.secondBlur();
    }
    if (!this.mouseOverIcon) {
      this.closeCalendar();
    }

    if (this.props.nextOnBlur) this.props.onBlur();
  }

  componentDidMount() {
    this.calendarRef.current.input.inputElement.addEventListener(
      'keydown',
      this.handleKeyEvent
    );
    this.calendarRef.current.input.inputElement.addEventListener(
      'blur',
      this.inputBlur
    );

    this.props.autoFocus && this.calendarRef.current.input.inputElement.focus();

    const iconRef = this.wrapperRef.childNodes[1];

    iconRef.onmouseover = () => {
      this.mouseOverIcon = true;
    };
    iconRef.onmouseout = () => {
      this.mouseOverIcon = false;
    };
  }

  handleSelect(e) {
    this.setState({
      invalidMessage: null,
      open: false,
    });
  }

  componentWillUnmount() {
    this.calendarRef.current.input.inputElement.removeEventListener(
      'keydown',
      this.handleKeyEvent
    );
    this.calendarRef.current.input.inputElement.removeEventListener(
      'blur',
      this.inputBlur
    );
  }

  isValidDate() {
    const dateStr = this.calendarRef.current.input.inputElement.value;
    if (!dateStr || dateStr.indexOf('_') !== -1) {
      return false;
    }
    return true;
  }

  inputBlur() {
    let invalidMessage;
    if (!this.calendarRef.current || !this.calendarRef.current.inputElement)
      return;
    const dateStr = this.calendarRef.current.input.inputElement.value;

    if (this.calendarRef.current.input.inputElement.value) {
      if (dateStr.indexOf('_') !== -1) {
        this.setState({
          invalidMessage: 'Data inválida',
        });
        return;
      }
      const dayMonthYear = dateStr
        .toString()
        .split('/')
        .map((v) => parseFloat(v));
      const validDate = new Date();
      validDate.setFullYear(
        dayMonthYear[2],
        dayMonthYear[1] - 1,
        dayMonthYear[0]
      );

      if (
        !moment(validDate).isValid() ||
        validDate.getDate() !== dayMonthYear[0] ||
        validDate.getMonth() + 1 !== dayMonthYear[1] ||
        validDate.getFullYear() !== dayMonthYear[2]
      ) {
        invalidMessage = 'Data inválida';
      }
    }

    this.setState({
      invalidMessage,
    });

    if (this.state.open) this.focus();
  }

  focus() {
    this.calendarRef.current.input.inputElement.focus();
  }

  toggleCalendar() {
    if (this.props.disabled) return;
    this.setState(
      (old) => {
        if (!old.open) this.focus();
        return {
          open: !old.open,
        };
      },
      () => {
        if (this.state.open) {
          const toggle = this.wrapperRef.getElementsByClassName(
            'react-datepicker-popper'
          )[0];

          const container = this.wrapperRef
            .getElementsByClassName('react-datepicker__input-container')[0]
            .getBoundingClientRect();

          toggle.style.top = `${container.top}px`;
          toggle.style.left = `${container.left}px`;
        }
      }
    );
  }

  render() {
    return (
      <MainWrapper>
        <DatepickerWrapper
          innerRef={(ref) => (this.wrapperRef = ref)}
          onBlur={this.props.onBlur}
        >
          <Calendar
            ref={this.calendarRef}
            locale='pt-BR'
            className={`control ${this.props.className}`}
            dateFormat='dd/MM/yyyy'
            placeholder='dd/mm/yyyy'
            open={this.state.open}
            onSelect={this.handleSelect}
            {...this.props}
            onBlur={this.calendarBlur}
            customInput={
              <MaskedTextInput
                type='text'
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            }
          />
          <i
            onClick={this.toggleCalendar}
            className={`material-icons ${
              this.props.disabled ? 'disabled' : ''
            }`}
          >
            event
          </i>
        </DatepickerWrapper>
        <small className='invalidDate'>{this.state.invalidMessage}</small>
      </MainWrapper>
    );
  }
}

export default DatePicker;
