import styled from 'styled-components';

export const PbmAuthorizationList = styled.div`
  .more-options {
    width: 50px;
  }

  .dropdown-content {
    position: absolute;
    right: 24px;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  min-height: 55px;

  input,
  select {
    height: 40px;
  }

  button {
    margin-top: 22.5px;
  }
`;
