import React, { Component } from "react";
import PropTypes from "prop-types";
import PbmSaleService from '../../../services/pbmSaleService';
import { currencyMask } from "../../utils/masks";
import {
  ItemWrapper,
  ItemContainer,
  ExtraInfo,
  ItemInfo,
  ItemValues,
  ItemDesc
} from "./style";
import { Modal } from "../modal2";
import { Button } from "../modules";

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverflowed: false,
      showTooltip: false,
      hovered: false,
    };

    this.productNameRef = React.createRef();
    this.modalRef = React.createRef();

    this.timeoutId = null;
  }

  static propTypes = {
    productName: PropTypes.string.isRequired,
    subTotal: PropTypes.number.isRequired,
    subTotalDiscount: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    productReferenceId: PropTypes.number.isRequired,
    lotControlled: PropTypes.bool,
    isControlled: PropTypes.bool,
    handleEditPrescription: PropTypes.func.isRequired,
    handleEditLot: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handlePrev: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    applicableDiscount: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleEditContinuousUse: PropTypes.func.isRequired,
  };

  resizeListenerDebouncer = () => {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.productNameRef.current?.scrollWidth >
        this.productNameRef.current?.clientWidth
        ? this.setState({ isOverflowed: true })
        : this.setState({ isOverflowed: false });
    }, 500);
  };

  componentDidMount() {
    this.setState({
      isOverflowed:
        this.productNameRef.current.scrollWidth >
        this.productNameRef.current.clientWidth,
    });
    window.addEventListener("resize", this.resizeListenerDebouncer);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListenerDebouncer);
  }

  static defaultProps = {
    lotControlled: false,
    isControlled: false,
  };

  handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowUp":
        this.props.handlePrev();
        break;
      case "ArrowDown":
        this.props.handleNext();
        break;
      case " ":
      case "Enter":
        if (this.props.isActive) {
          let toSelect;
          if (this.props.applicableDiscount) {
            toSelect = document.querySelector("#input-subtotal");
          } else {
            toSelect = document.querySelector("#qtd");
          }
          if (toSelect) {
            e.stopPropagation();
            e.preventDefault();
            toSelect.select();
          }
        }
        break;
      case "Escape":
        this.props.handleClose();
        e.target.blur();
        break;
      default:
        break;
    }
  };

  processEvents = (event, productReferenceId, sequentialId, action) => {
    event.preventDefault();
    event.stopPropagation();

    action(productReferenceId, sequentialId);
    this.modalRef.current.close();
  };

  handleOpenTrnCentre = (event) => {
    event.preventDefault();
    event.stopPropagation();
    PbmSaleService.startTrnCentre();
  };

  handleClickItem = (e) => {
    if (e.target.id === "additionalInformation") return;
    this.props.handleClick(e);
  };

  render() {
    const {
      isTrnCentreProduct,
      productName,
      subTotal,
      subTotalDiscount,
      quantity,
      appliedDiscount,
      lotControlled,
      handleEditPrescription,
      handleEditLot,
      handleEditContinuousUse,
      sequentialId,
      isControlled,
      id,
      lotsQtt,
      isLast,
      isFirst,
      productReferenceId,
      productBarcode,
      continuousUse,
      anyContinuousUseFieldRequired
    } = this.props;

    const hasDiscount = !!appliedDiscount;

    const hasComplementarInformation =
      lotControlled ||
      isControlled ||
      continuousUse?.required;

    const hasMoreComplementarInformation =
      (isControlled && continuousUse?.required) ||
      (lotControlled && continuousUse?.required);

    const lotInformationComplete = lotsQtt === quantity || !lotControlled;
    const sngpcInformationComplete = lotsQtt === quantity || !isControlled;
    const infoContinuousUseComplete = continuousUse?.allRequiredFieldsFullfilled;

    const allInformationsComplete =
      lotInformationComplete && infoContinuousUseComplete && sngpcInformationComplete;
    return (
      <ItemContainer
        className="item-container"
        onMouseOver={() => this.setState({ hovered: true })}
        onMouseOut={() => this.setState({ hovered: false })}
      >
        {this.state.showTooltip && this.state.isOverflowed && (
          <div className={`item-name-tooltip ${isFirst ? "first-item" : ""}`}>
            {productName}
          </div>
        )}
        <ItemWrapper
          className="item-wrapper"
          id={id}
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
          onClick={(e) => this.handleClickItem(e)}
          hasBorderBottom={!isLast}
          hasAddInfo={hasDiscount || hasComplementarInformation || isTrnCentreProduct}
        >
          <ItemInfo
            className="item-info"
            hasOnlyDiscount={hasDiscount && !hasComplementarInformation && !isTrnCentreProduct}
          >
            <ItemDesc className="item-desc" hovered={this.state.hovered}>
              <span className="item-qtde">{`${quantity} UN`}</span>
              <span
                onMouseOver={() => this.setState({ showTooltip: true })}
                onMouseOut={() => this.setState({ showTooltip: false })}
                className="item-name"
                ref={this.productNameRef}
              >
                {productName}
              </span>
            </ItemDesc>

            <ExtraInfo id="item" className="extra-info" hovered={this.state.hovered}>

              {isTrnCentreProduct ?
                <p >
                  <img src="/assets/images/pbm.svg" alt="" className="tooltip-image" onClick={(e) => this.handleOpenTrnCentre(e)} />

                  <span className="tooltip-text">Produto pertence ao Portal da Drogaria</span>
                </p>
                : ""}

              {hasMoreComplementarInformation ? (
                <p>
                  <img
                    src={`/assets/images/${allInformationsComplete
                      ? "check_circle.svg"
                      : "warning.svg"
                      }`}
                    alt=""
                  />

                  <span
                    id="additionalInformation"
                    onClick={(e) => this.modalRef.current.open()}
                  >
                    {allInformationsComplete
                      ? "Informações complementares"
                      : "Preencher informações complementares"}
                  </span>
                </p>
              ) : (
                hasComplementarInformation && (
                  <>
                    {isControlled && (
                      <p>
                        <img
                          src={`/assets/images/${sngpcInformationComplete
                            ? "check_circle.svg"
                            : "warning.svg"
                            }`}
                          alt=""
                        />
                        <span
                          id="additionalInformation"
                          onClick={(e) =>
                            this.processEvents(
                              e,
                              productReferenceId,
                              sequentialId,
                              handleEditPrescription
                            )
                          }
                        >
                          {sngpcInformationComplete
                            ? "Informações da receita"
                            : "Preencher informações da receita"}
                        </span>
                      </p>
                    )}

                    {continuousUse?.required && (
                      <p>
                        {anyContinuousUseFieldRequired && (
                          <img
                            src={`/assets/images/${infoContinuousUseComplete
                              ? "check_circle.svg"
                              : "warning.svg"
                              }`}
                            alt=""
                          />
                        )}
                        <span
                          id="additionalInformation"
                          onClick={(e) =>
                            handleEditContinuousUse(productBarcode)
                          }
                        >
                          {infoContinuousUseComplete
                            ? "Informações de uso contínuo"
                            : "Preencher informações de uso contínuo"}
                        </span>
                      </p>
                    )}

                    {lotControlled && !isControlled && (
                      <p>
                        <img
                          src={`/assets/images/${lotInformationComplete
                            ? "check_circle.svg"
                            : "warning.svg"
                            }`}
                          alt=""
                        />
                        <span
                          id="additionalInformation"
                          onClick={(e) =>
                            this.processEvents(
                              e,
                              productReferenceId,
                              sequentialId,
                              handleEditLot
                            )
                          }
                        >
                          {lotInformationComplete
                            ? "Informações do lote"
                            : "Informar lote do produto"}
                        </span>
                      </p>
                    )}
                  </>
                )
              )}

            </ExtraInfo>
          </ItemInfo>

          <ItemValues>
            {hasDiscount && subTotal !== subTotalDiscount && (
              <div className="item-total">
                {`de: ${currencyMask(subTotal)}`}
              </div>
            )}

            <div className="item-total-desc">
              {hasDiscount && subTotal !== subTotalDiscount
                ? `por: ${currencyMask(subTotalDiscount)}`
                : currencyMask(subTotal)}
            </div>
          </ItemValues>
        </ItemWrapper>

        <Modal
          title={"Informações complementares"}
          ref={this.modalRef}
          closable={true}
          opened={false}
          style={{ width: "40%" }}
        >
          <Modal.Body style={{ padding: "16px 0 0 0" }}>
            <ExtraInfo
              className="extra-info"
              hovered={this.state.hovered}
              hasMoreInfo={hasMoreComplementarInformation}
            >
              {isControlled && (
                <p>
                  <img
                    src={`/assets/images/${sngpcInformationComplete ? "check_circle.svg" : "warning.svg"
                      }`}
                    alt=""
                  />

                  <span
                    onClick={(e) => {
                      this.processEvents(
                        e,
                        productReferenceId,
                        sequentialId,
                        handleEditPrescription
                      );
                    }}
                  >
                    {sngpcInformationComplete
                      ? "Informações da receita"
                      : "Preencher informações da receita"}
                  </span>
                </p>
              )}
              {lotControlled && !isControlled && (
                <p>
                  <span
                    id="additionalInformation"
                    onClick={(e) =>
                      this.processEvents(
                        e,
                        productReferenceId,
                        sequentialId,
                        handleEditLot
                      )
                    }
                  >
                    <img
                      src={`/assets/images/${lotInformationComplete
                        ? "check_circle.svg"
                        : "warning.svg"
                        }`}
                      alt=""
                    />
                    {lotInformationComplete
                      ? "Informações do lote"
                      : "Informar lote do produto"}
                  </span>
                </p>
              )}
              {continuousUse?.required && (
                <p>
                  {anyContinuousUseFieldRequired && (
                    <img
                      src={`/assets/images/${infoContinuousUseComplete
                        ? "check_circle.svg"
                        : "warning.svg"
                        }`}
                      alt=""
                    />
                  )}
                  <span
                    onClick={(e) => {
                      handleEditContinuousUse(productBarcode);
                      this.modalRef.current.close();
                    }}
                  >
                    {infoContinuousUseComplete
                      ? "Informações de uso contínuo"
                      : "Preencher informações de uso contínuo"}
                  </span>
                </p>
              )}
            </ExtraInfo>
          </Modal.Body>
          <Modal.Footer style={{ marginTop: "16px" }}>
            <Button
              id="btn-goBack"
              className="btn-outline"
              onClick={() => this.modalRef.current.close()}
            >
              VOLTAR
            </Button>
          </Modal.Footer>
        </Modal>
      </ItemContainer>
    );
  }
}

export default Item;
