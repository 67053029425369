import React, { Component } from 'react';
import CpfCnpjWrapper from './style';
import { Card } from '../modules';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';
import getShortcut, { FIRST_OPTION, SECOND_OPTION, THIRD_OPTION } from '../../../keyboardShortcuts.js';

class CpfCnpj extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  focusInput = () => {
    this.textInput.focus();
  };

  render() {
    const { handleKeyUp, handleClick, handleClose, handleKeyDown, handleConfirm, customer, labelDocument, labelCpfCnpj, formatedDocument } = this.props;
    return (
      <Card title="CPF NA NOTA?"  handleClose={handleClose} closeEnable>
        <CpfCnpjWrapper className="cpf_cnpj">
          {customer && (
            <div className="customer-container">
              <div className="customer-info">
                <div className="customer-data">
                  <div className="customer-data-group">
                    <span>Cliente selecionado</span>
                    <span>{customer?.name}</span>
                  </div>

                  <div className="customer-data-group">
                    <span>{labelDocument}</span>
                    <span>{formatedDocument}</span>
                  </div>
                </div>

                <button type="button" id="btn-send" className="button-linx button-primary" onClick={() => handleClick(customer.cpf)} tabIndex="0">
                  <ShortcutHint shortcut={getShortcut(SECOND_OPTION)} activateShortcut={() => handleClick(customer.cpf)} />

                  USAR {labelDocument} DO CLIENTE
                </button>
              </div>

              <div className="section-divider">
                <hr />
                <span>OU</span>
                <hr />
              </div>
            </div>
          )}

          <div className="cpf-container">
            <ShortcutHint shortcut={getShortcut(FIRST_OPTION)} activateShortcut={this.textInput.focus} />

            <div className="form-group-cpf">
              <label htmlFor="cpf_cnpj">{labelCpfCnpj}</label>
              <input
                id="cpf_cnpj"
                className="form-control"
                type="text"
                onKeyUp={e => handleKeyUp(e)}
                onKeyDown={handleKeyDown}
                placeholder="_ _ _ . _ _ _ . _ _ _ - _ _"
                maxLength="18"
                autoComplete="off"
                autoFocus
                ref={(input) => {this.textInput = input}}
              />
            </div>

            <div className="cpf-buttons">
              <button type="button" id="btn-without-cpf" className="button-linx button-outline" onClick={() => handleClick('')} tabIndex="0">
                <ShortcutHint shortcut={getShortcut(THIRD_OPTION)} activateShortcut={() => handleClick('')}/>
                Sem CPF na Nota
              </button>

              <button type="button" id="btn-confirm" className="button-linx button-primary" onClick={handleConfirm} tabIndex="0">
                Avançar
              </button>
            </div>
          </div>          
        </CpfCnpjWrapper>
      </Card>
    );
  }
}


export default CpfCnpj;
