import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from './styles';

class Shortcut extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false);
  }

  keyDown = (e) => {
    const {
      name,
      keyCode,
      onKeyCodeMatch,
      disabled,
      shortcutsToBlock,
    } = this.props;

    if (disabled) return;

    const shortcutBlocked = shortcutsToBlock.find(
      (shortcut) => shortcut === name
    );

    if (shortcutBlocked) return;

    if (e.altKey && e.keyCode === keyCode) {
      onKeyCodeMatch();
    }
  };

  render() {
    const { keys } = this.props;
    return <Container>{keys}</Container>;
  }
}

Shortcut.propTypes = {
  name: PropTypes.string.isRequired,
  keys: PropTypes.string.isRequired,
  keyCode: PropTypes.number.isRequired,
  onKeyCodeMatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  shortcutsToBlock: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  shortcutsToBlock: state.shortcuts.shortcutsToBlock,
});

export default connect(mapStateToProps)(Shortcut);
