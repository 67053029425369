import { AbstractService } from './abstractService';

export class PbmSalePaymentService extends AbstractService {
  constructor() {
    super('/PbmSalePayment');
  }

  saleFinished = payload => this.invokeAction('/finishedSalePbm', payload);
}

export default new PbmSalePaymentService();
