import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  posType: "1",
};

const posTypeReducer = createReducer(initialState, {
[types.POS_TYPE]: (state, action) => ({
    ...state,
    posType: action.payload,
  }),
});

export default posTypeReducer;