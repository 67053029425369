import React, { Fragment } from 'react';
import { Button } from '../modules';
import { Form, Col } from 'react-bootstrap';
import './pbm-config-seven-component.scss';

function PbmConfigSevenComponent(props) {
    const {
        form,
        handleChange,
        handleConfirm
    } = props;

    return (
        <Fragment>
            <div Id="bodyseven" className="body">
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="cnpjLojaTerminal">
                            <Form.Label>CNPJ de comunicação</Form.Label>
                            <Form.Control
                                type='number'
                                pattern='[0-9]'
                                id="cnpjLojaTerminal"
                                placeholder="CNPJ de comunicação"
                                defaultValue={form?.cnpjLojaTerminal}
                                onChange={(e) => handleChange(e)}
                                onKeyPress={(e) => {
                                    const keyCode = e.keyCode || e.which;
                                    const keyValue = String.fromCharCode(keyCode);
                                    if (e.target.value.length > 13)
                                        e.preventDefault();
                                    if (/\+|-|\.|,|e|E/.test(keyValue))
                                        e.preventDefault();
                                }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="reqDir">
                            <Form.Label>Diretório de Escrita</Form.Label>
                            <Form.Control
                                placeholder="Diretório de Escrita ex: C:/TRNCENTR/REQ/"
                                defaultValue={form?.reqDir}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="resDir">
                            <Form.Label>Diretório de Leitura</Form.Label>
                            <Form.Control
                                placeholder="Diretório de Leitura ex: C:/TRNCENTR/RESP/"
                                defaultValue={form?.resDir}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
            </div>
        </Fragment>
    );
}
export default PbmConfigSevenComponent;