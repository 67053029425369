import React, { Component } from 'react';

import './style.scss';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import DeliveryControlIcon from '../../assets/images/delivery-control.png';
import { OrderService } from '../../services/orderService';
import { SlideToggle } from '../../shared/components/modules';
import { getContext } from '../../shared/hoc/getContext';

class DeliveryOptionContainer extends Component {
  constructor(props) {
    super(props);
    this.orderService = new OrderService();
  }
  HandleOffline = () => {
    if (this.props.isOnline)
      return true;

    this.props.MessageBus.Publish('pos.dialog', { message: 'Funcionalidade não disponível com PDV em modo Offline' });
    return false;
  };

  handleToggleChange = (e) => {
    this.orderService.updateDeliveryInfo({ isDelivery: e.target.checked });
    this.forceUpdate();
  };

  handleClick = () => {
    this.orderService.updateDeliveryInfo({ isDelivery: true });
    this.forceUpdate();
  };

  deliveryAvailable() {
    const { configs } = this.props.configRetailer;
    if (configs === null) return false;

    return configs.find((item) => {
      return item.name === 'DeliveryAvailable' && item.value === 'true';
    });
  }

  render() {
    return this.deliveryAvailable() && this.props.posType === '1' ? (
      <div className="deliver-wrapper">
        <Button className="button" onClick={this.handleClick}>
          <img src={DeliveryControlIcon} alt="Delivery Control" />
          <span className="tooltiptext">Editar informações da entrega</span>
        </Button>
        <label>
          <b>DELIVERY</b>
        </label>
        <SlideToggle
          style={{
            alignSelf: 'center',
            marginLeft: '5px',
            marginBottom: 'unset',
          }}
          onChange={this.handleToggleChange}
          checked={this.props.isDelivery}
          id="delivery-option"
        />
      </div>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state) => ({
  configRetailer: state.configRetailer,
  isDelivery: state.order.order?.deliveryInfo && state.order.order?.deliveryInfo.isDelivery,
  posType: state.posType.posType,
  isOnline: state.pdvContingency.isOnline
});

export default connect(mapStateToProps)(withMessageBus(getContext(DeliveryOptionContainer)));
