import React, { useState, useEffect } from 'react';
import { Button } from '../button';

import './radio-button.scss';

function RadioButton(props) {
    const [values, setValues] = useState([]);
    function changes(item) {
        return [...values].map((elm, index) => {
            elm.selected = values.indexOf(item) === index;
            return elm;
        });
    }
    function handleClick(e, item) {
        let change = changes(item);
        setValues(change);
        if (props.onChange)
            props.onChange(change, props.id);
    }
    function renderItem(o, i) {
        return (
            <Button key={i} onClick={(e) => handleClick(e, o)}  autoFocus={props.setFocus && i===0}>
                <i className="material-icons">{o.selected ? "radio_button_checked" : "radio_button_unchecked"}</i>
                <span>{o.description}</span>
            </Button>
        );
    }
    useEffect(()=>{
        setValues(props.values);
    },[props.values]);
    return (
        <div className="rbContainer">
            {props.title && <div>{props.title}</div>}
            {values && values.map(renderItem)}
        </div>
    );
}
export default RadioButton;