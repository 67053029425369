import AbstractService from './abstractService';

class FinancialDocumentService extends AbstractService{
	constructor(){
			super('/FinancialDocumentReceiptment');
	}

	start = () => this.invokeAction('/start');
	back = () => this.invokeAction('/cancel');
	confirm = ( idsFinancialDocumentReceiptment, valueToDebit, discount, interest) => this.invokeAction('/confirm',{ idsFinancialDocumentReceiptment, valueToDebit, discount, interest });
	find = (criteria, startPeriod, endPeriod) => this.invokeAction('/find', criteria, startPeriod, endPeriod);
}

export default new FinancialDocumentService();