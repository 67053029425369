import React, { useEffect, useState } from "react"

import { Div } from './style';

interface IShowMaxLength {
    elementId: string;
    lengthInput: number;
}

export function ShowMaxLenght(show: IShowMaxLength) {
    const [maxLength, setMaxLength] = useState(0);

    useEffect(() => {
        getMaxLengthValue();
    }, []);

    const getMaxLengthValue = () => {
        let element = document.getElementById(show.elementId);

        let value = element?.getAttribute('maxlength');

        setMaxLength(+(value as string));
    }

    return (
        <Div>
            <span data-testid="show-max-lenght">{show.lengthInput}/{maxLength}</span>
        </Div>
    )
}