import styled from 'styled-components';
import theme2 from '../../../themes/theme2'

const handlePosition = (position, customValue) => {
  switch (position) {
    case "top":
      return "left: -138px; bottom: 0; transform: translateY(-50%);"
    case "left":
      return "left: -312px;"
    case "bottom":
      return "left: -138px; top: 0; transform: translateY(50%);"
    case "right":
      return "left: 30px;"
    case "custom":
        return customValue
    default:
      return "left: 30px;"
  }
}

export const ButtonIcon = styled.div`
  display: inline-flex;
  color: ${props => props.color ?? theme2.palette.primary};
  cursor: pointer;
  position: relative;

  align-items: center;
  justify-content: center;

  font-size: 16px;
  line-height: 16px;

  &:hover::after {
    opacity: 1;
    pointer-events: all;
  }

  &::after {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    white-space: pre-wrap;
    pointer-events: none;
    transition: 500ms;
    opacity: 0;
    content: attr(aria-label);
    background: #fff;
    color: #222;
    border-radius: 5px;
    padding: 6px;
    position: absolute;
    width: ${props => props.width ?? "300px"};
    text-align: center;
    height: fit-content;

    line-height: 1.5rem;
    z-index: 999;

    ${props => handlePosition(props.position, props.customValue)}
  }
`
