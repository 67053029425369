import { AbstractService } from './abstractService';

export class CustomerService extends AbstractService {
  constructor() {
    super('/Customer');
  }

  startCustomerSelection = () => this.invokeAction('/startCustomerIdentification');

  backCustomerSelection = () => this.invokeAction('/cancelCustomerIdentification');

  showCustomerRegistration = () => this.invokeAction('/showCustomerRegistration');

  executeQuery = (criteria, page, pageCount) => this.invokeAction('/ListCustomerByCriteria', { criteria, page, pageCount });

  customerRegistration = (customer) => this.invokeAction('/registerCustomer', customer);
}

export default CustomerService;
