/* eslint-disable camelcase */
export const OPERATION_NAME_FARMACIA_POPULAR = 'Farmácia Popular';
export const OPERATION_NAME_DTEF_Pbm = "Pbm's via TEF";
export const OPERATION_NAME_EPHARMA_PBM = 'ePharma';
export const OPERATION_NAME_FUNCIONAL_PBM = 'Funcional Card';
export const OPERATION_NAME_SEVEN_PBM = 'Seven PDV';
export const OPERATION_NAME_TRNCENTRE = 'Portal da Drogaria';
export const OPERATION_NAME_ORIZON = 'Orizon';
export const OPERATION_LAST_PBM_SALE = 'Reimprimir última venda PBM';
export const OPERATION_NEW_SALE = 'Nova venda';
export const OPERATION_CASH_OPEN = 'Abertura de caixa';
export const OPERATION_CASH_CLOSE = 'Fechamento de caixa';
export const OPERATION_CASH_CLOSE_PARTIAL = 'Conferência parcial';
export const OPERATION_SANGRIA = 'Sangria';
export const OPERATION_CASH_SUPPLY = 'Suprimento';
export const OPERATION_PRE_ORDER_LIST = 'Listagem de pré-vendas';
export const OPERATION_CANCEL_SALE = 'Cancelamento da venda (NFC-e/S@T)';
export const OPERATION_REPRINT_TEF = 'Reimprimir comprovante TEF';
export const OPERATION_CANCEL_TEF = 'Cancelar comprovante TEF';
export const OPERATION_MONITOR_NOTAS = 'Monitor notas fiscais';
export const OPERATION_PAY_OFF = 'Baixa de títulos';
export const OPERATION_LIST_AUTHORIZATIONS = 'Autorizações PBM';
export const OPERATION_NAME_LINX_CONECTA = 'Linx conecta';
export const OPERATION_NAME_DELIVERY_CONTROL = 'Controle de delivery';
export const OPERATION_NAME_VIRTUAL_VISIT = 'Visita virtual';
export const OPERATION_NAME_CONFIG_FUNCIONAL = 'PBM Funcional Card';
export const OPERATION_NAME_CONFIG_ORIZON = 'PBM Orizon';
export const OPERATION_NAME_CONFIG_TERMINAL = 'Configurações do terminal';
export const OPERATION_NAME_CONFIG_PBM = 'PBMs';
export const OPERATION_NAME_CONFIG_TEF = 'TEF';
export const OPERATION_NAME_CONFIG_POSCONNECT = 'Pos Connect'
export const OPERATION_VIRTUAL_TOUR = 'Visita virtual';
export const OPERATION_CHAT = 'Chat';
export const OPERATION_DOCUMENTATION = 'Documentação';
export const OPERATION_CLIENT_PORTAL = 'Portal do cliente';
export const OPERATION_OBJECTPRO = 'ObjectPro';
export const OPERATION_DOCUMENT_PRINTING = 'Imprimir Documentos';
