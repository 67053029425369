import styled from 'styled-components';

export const ViewsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &>* {
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
`;
