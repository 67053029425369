import styled from "styled-components";

import theme from "../../../themes/default";
import theme2 from "../../../themes/theme2";

const style = `
  display: inline-block;
  font-family: inherit;
  font-weight: bold;
  padding: 0 10px;
  height: 40px;
  font-family: Dosis;
  font-size: 13px;
  border: 0;
  background-color: ${theme2.palette.primary};
  border-radius: ${theme2.metrics.borderRadius}px;
  color: black;

  &:nth-of-type(n+2) {
    margin-left: 10px;
  }

  &, &:visited {
    display: inline-block;
    margin: 0;
    cursor: pointer;
  }

  &:active, &:focus {
    outline: none;
  }

  &:hover, &:active, &:focus {
    i { color: white; }
  }

  &:focus i { color: white; }

  i {
    vertical-align: middle;
    color: white;
    line-height: 24px
  }

  span {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &.btn-sm {
    height: 29px;
  }

  &.btn-clear {
    width: 80%;
    height: 100%;
    font-size: 17px;
  }

  &.btn-clear-large {
    width: 100%;
    height: 100%;
    font-size: 17px;
  }

  &.btn-large {
    width: 100%;
    font-size: 17px;
  }

  &.btn-default {
    color: ${theme.palette.primary[14]};
    padding: 0px 25px;
    background-color: #FFF;

    &:hover, &:focus {
      box-shadow: 0 0 8px ${theme2.palette.primary};
    }
  }

  &.btn-finish {
    margin-top: 2%;
    width: 150px;
    height:  50px;
    color: ${theme2.palette.primary};
    background-color: #F8F9FA;
    border: 1px solid #E9E9E9;

    &:hover, &:focus {
      border: 2px solid ${theme2.palette.primary};
      box-shadow: 0 0 8px ${theme2.palette.primary};
    }
  }

  &.btn-outline {
    background-color: unset;
    border: 2px solid ${theme2.palette.primary};
    border-radius: 4px;
    color: black;

    &:hover, &:focus {
      border: 2px solid ${theme2.palette.primary};
      background-color: ${theme2.palette.primary};
      color: white;

      i {
        color: white;
      }
    }

    i {
      color: ${theme2.palette.primary};
    }
  }

  &.btn-pay-values {
    background-color: white;
    border: 1px solid #EEEEEE;
    color: #757575;
    border-radius: 1px;

    &:active {
      background-color: ${theme2.palette.primary};
      color: white;
    }
  }

  &.btn-confirm-pay {
    border: 2px solid ${theme2.palette.primary};
    height: 100%;
    &:active {
      background-color: ${theme2.palette.primary};
      color: white;
    }
  }

  &.btn-purple {
    font-family: inherit;
    border: 2px solid ${theme2.palette.primary};
    background-color: ${theme2.palette.primary};
    border-radius: 4px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover, &:focus {
      box-shadow: 0 0 8px ${theme2.palette.primary};
    }
  }

  &.btn-orange {
    color: white;
    background-color: ${theme2.palette.accent2};

    &:hover, &:focus {
      box-shadow: 0 0 8px ${theme2.palette.accent2};
    }
  }

  &.btn-transparent {
    background: none;
  }

  &.disabled {
    color: #fff;
    background-color: ${theme2.palette.grayMedium}
    border: none
    &:hover {
      color: #fff !important;      
    }

  }
`;

export const ButtonWrapper = styled.button`
  ${style}
`;
export const AnchorWrapper = styled.a`
  ${style}
`;
