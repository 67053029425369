import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ShortcutHint } from '../shortcut-hint/shortcut-hint';
import {
  AutoCompleteWrapper,
  AutoCompleteItemsWrapper,
  DefaultItemRender,
} from './auto-complete.style';

// eslint-disable-next-line react/prefer-stateless-function
export class AutoComplete extends Component {
  static propTypes = {
    minLenghtToSearch: PropTypes.number,
    renderItem: PropTypes.func,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    onKeyPress: PropTypes.func,
    queryFn: PropTypes.func.isRequired,
    img: PropTypes.string,
    propToShow: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onLoadCompleteSearch: PropTypes.func,
    onSuggestByInput: PropTypes.func,
    debounceTime: PropTypes.number,
    value: PropTypes.objectOf(PropTypes.any),
    icon: PropTypes.string,
    containerStyle: PropTypes.object,
    suggestionStyle: PropTypes.object,
    itemsCustomStyle: PropTypes.string
  };

  static defaultProps = {
    minLenghtToSearch: 1,
    renderItem: this.defaultItemRender,
    placeholder: null,
    maxLength: null,
    onKeyPress: null,
    img: null,
    propToShow: null,
    onLoadCompleteSearch: null,
    onSuggestByInput: null,
    debounceTime: 500,
    icon: null,
    containerStyle: {},
    suggestionStyle: {},
    itemsCustomStyle: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      items: [],
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.showOptions = this.showOptions.bind(this);
    this.wrapperRenderItem = this.wrapperRenderItem.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.initOptionsAfterDebounce = this.initOptionsAfterDebounce.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleKeyDownItem = this.handleKeyDownItem.bind(this);
    this.handleEnterInInputText = this.handleEnterInInputText.bind(this);
    this.clearDebounceTimer = this.clearDebounceTimer.bind(this);
    this.focusInputField = this.focusInputField.bind(this);
    this.getText = this.getText.bind(this);
    this.openOptions = this.openOptions.bind(this);
  }

  componentDidMount() {
    this.innerCompleteRef.addEventListener(
      'focusin',
      this.handleFocusinInnerComplete
    );

    this.innerCompleteRef.addEventListener(
      'focusout',
      this.handleFocusOutInnerComplete
    );
    if (this.props.value) {
      this.textInput.value = this.props.value;
    }
    this.addClose();
  }

  componentDidUpdate() {
    const { value, propToShow } = this.props;
    if (value) {
      if (typeof value === 'object' && propToShow) {
        this.textInput.value = value[propToShow];
      } else {
        this.textInput.value = value;
      }
    }

    this.addClose();
  }

  addClose() {
    this.innerCompleteRef.querySelector('input').style['padding-right'] =
      '30px';
  }

  handleFocusinInnerComplete = () => {
    clearTimeout(this.timerFocusOut);
  };

  handleFocusOutInnerComplete = () => {
    this.timerFocusOut = setTimeout(() => {      
      this.clearDebounceTimer();

      if (this.props.hideItemsOnFocusOut) {
        this.setState({
          showOptions: false,
        });
      }

    }, 100);
  };

  static defaultItemRender(props) {
    const { item, propToShow } = props;

    const value = propToShow ? item[propToShow] : item.toString();

    return <DefaultItemRender {...props}>{value}</DefaultItemRender>;
  }

  wrapperRenderItem(item, index, onClick, searchType) {
    const Comp = this.props.renderItem;

    const { propToShow } = this.props;

    let addDisabled = false;
    
    if(item.barcode){
      this.props.adicionalInfoProducts.forEach(x => {
        x.id.forEach(id => {
          if(item?.barcode.includes(id)) addDisabled = x.insertlessByDescription
        });
      });
    }

    return (
      <Comp
        searchType={searchType}
        item={item}
        key={index}
        tabIndex="0"
        propToShow={propToShow}
        filterValue={this.getFilterValue}
        onClick={() => {
          onClick
            ? onClick(this.textInput.value())
            : this.handleClickItem(item, addDisabled);
        }}
        onKeyDown={(e) => this.handleKeyDownItem(e, item, onClick, addDisabled)}
      />
    );
  }

  getFilterValue() {
    if (!this.textInput || !this.textInput.value) {
      return null;
    }

    const { value } = this.textInput;

    if (typeof value === 'function') {
      return value();
    }

    return value;
  }

  handleClickItem(item, addDisabled) {
    if(addDisabled) {
      this.props.handleShowMessage(
        'Esse item só pode ser adicionado pela leitura ou digitação do código de barras.',
        'ADICIONAR PRODUTOS',
      );

      this.clearInput();
      return;
    }

    this.showOptions(false);

    this.clearInput();

    this.props.onSelect(item);
  }

  clearInput = () => {
    if (!this.textInput) return;

    if (this.textInput.clear) {
      this.textInput.clear();
    } else {
      this.textInput.value = null;
    }
  };

  handleKeyDownItem(e, item, onClick, addDisabled) {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        e.stopPropagation();
        onClick ? onClick(this.textInput.value()) : this.handleClickItem(item, addDisabled);
        break;
      case 'ArrowUp':
        this.navigate(-1, e);
        break;
      case 'ArrowDown':
        this.navigate(1, e);
        break;
      case 'Escape':
        this.escape(e);
        this.textInput.focus();
        break;
      default:
        break;
    }
  }

  navigate(sum, e) {
    if (this.state.showOptions) {
      const elements = Array.from(
        e.target
          .closest('.auto-complete')
          .querySelectorAll('input, [tabindex="0"]')
      );

      const currIndex = elements.indexOf(e.target);

      const toIndex = currIndex + sum;

      if (toIndex >= 0 && toIndex < elements.length) {
        elements[toIndex].focus();
      }
    }
  }

  focusInputField() {
    this.textInput.focus();
    this.props.clearStateOptions();
  }

  clear = () => {
    this.handleClickItem(null);
  };

  setInputValue = (value) => {
    this.textInput.value = value;
    this.props.clearStateOptions();
  };

  render() {
    const {
      component,
      shortcutInput,
      imagePath,
      id,
      placeholder,
      maxLength,
      autoFocus,
      value,
      icon,
      containerStyle,
      suggestionStyle,
      itemsCustomStyle
    } = this.props;
    const Component = component || 'input';

    return (
      <AutoCompleteWrapper
        className="auto-complete"
        hasPrefix={!!icon}
        innerRef={(ref) => {
          this.innerCompleteRef = ref;
        }}
        style={containerStyle}
      >
        {shortcutInput && (
          <ShortcutHint
            shortcut={shortcutInput}
            activateShortcut={this.focusInputField}
          />
        )}
        {icon && <i className="prefix material-icons">{icon}</i>}
        <Component
          autoComplete="off"
          imagePath={imagePath}
          id={id}
          className="control"
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChangeValue}
          ref={(input) => {
            this.textInput = input;
          }}
          autoFocus={autoFocus}
        />
        {value && (
          <i className="material-icons clear unselectable" onClick={this.clear}>
            clear
          </i>
        )}
        {this.state.showOptions && (
          <AutoCompleteItemsWrapper
            className={itemsCustomStyle}
            innerRef={(ref) => {
              this.innerItemsRef = ref;
            }}
            style={suggestionStyle}
          >
            {this.props.showAdvancedSearchOption &&
              this.wrapperRenderItem(
                this.textInput.value().replace('%', ''),
                null,
                this.props.advancedSearchSelected,
                this.textInput.value().length > 0 && this.textInput.value()[0] === "%" ? 1 : 0
              )}
            {this.state.items.map((i, index) =>
              this.wrapperRenderItem(i, index, null, 0)
            )}
          </AutoCompleteItemsWrapper>
        )}
      </AutoCompleteWrapper>
    );
  }

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.escape(e);
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e);
    }

    switch (e.key) {
      case 'Enter':
        this.handleEnterInInputText(e);
        break;
      case 'ArrowDown':
        this.navigate(1, e);
        break;
      default:
        break;
    }
  }

  getText() {
    return this.textInput.value();
  }

  escape(e) {
    this.showOptions(false);
    e.stopPropagation();
  }

  handleEnterInInputText(e) {
    e.preventDefault();
    this.clearDebounceTimer();
    this.showOptions(false);
    this.doSearch(e.target.value);

    if (this.props.onSuggestByInput) {
      this.props.onSuggestByInput(e.target.value, this.textInput.clear);
    }
  }

  clearDebounceTimer() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = null;
  }

  async handleChangeValue(event) {
    if (this.props.queryOnEnter) return;

    this.doSearch(event.target.value);
  }

  doSearch = (value) => {
    this.props.onSelect(null);

    if (!this.state.showOptions) {
      this.initOptionsAfterDebounce(value);
    } else {
      this.showOptions(value.length >= this.props.minLenghtToSearch, value);
    }
  };

  initOptionsAfterDebounce(value) {
    if (this.debounceTimer) return;

    this.debounceTimer = setTimeout(() => {
      const isFunction = this.textInput.value instanceof Function;

      this.showOptions(
        value.length >= this.props.minLenghtToSearch,
        isFunction ? this.textInput.value() : this.textInput.value
      );
    }, this.props.debounceTime);
  }

  showOptions(open, value) {
    if ((!open && this.state.showOptions === true) || !value) {
      this.setState({ showOptions: false });
      this.debounceTimer = null;
      return;
    }
    if (
      open &&
      this.props.showAdvancedSearchOption &&
      !this.state.showOptions
    ) {
      this.setState({ showOptions: true });
    }
    const result = this.props.queryFn(value, this.complete);

    if (!result || !result.then) {
      throw new Error('O método queryFn deve retornar uma promessa');
    }

    result.then((result) => {
      this.debounceTimer = null;
      if (!result || !result.items) return;

      const { items } = result;
      this.setState({
        items,
        showOptions: true,
      });
    });
  }

  openOptions() {
    this.setState({
      showOptions: true,
    });
  }
}