import styled from 'styled-components';

export const ListItemWrapper = styled.div`
  padding: 0 5px 5px 0;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 2px;
  box-sizing: border-box;
  align-items: center;

  display: grid;
  grid-template-columns: 30px 1fr 250px 80px;
  padding: 5px 10px;
  font-weight: bold;
  grid-gap: 10px;
  font-size: 16px;
  border: 2px solid #eee;
  border-radius: 5px;
  &:focus {
    border-color: #D0C5DD;
    background-color: #FFB200;
    outline: none;
  }
  &:hover{
    background-color: #FFB200;
    cursor: pointer;
  }
  span {
    display: block;
    padding: 8px 0;
  }
  .brand-name {
    font-size: 14px;
  }
  .listItemParcelNumber {
    border: 1px solid #E0E0E0;
    width: 25px;
    height: 25px;
    font-size: 12px;
    border-radius: 50%;
    .parcel-number {
      display: block;
      text-align: center;
      position: absolute;
      padding-top: 3px;
      padding-left: 8px;
    }
  }

`;
