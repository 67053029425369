/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { closeAllCards, getPayload, showCard, FARMACIA_POPULAR, shouldShow, FARMACIA_POPULAR_CONFIRM } from '../../redux/actions/routerAction';
import FarmaciaPopular from '../../shared/components/farmacia-popular';
import FarmaciaPopularBolsaFamilia from '../../shared/components/farmacia-popular-bolsa-familia';
import { Button } from '../../shared/components/modules';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import { Modal } from "../../shared/components/modal2";
const MESSAGE_BUS_CHANNEL_SHOW_CONFIRMATION = 'pos.farmaciapopular.authorized.show';
const MESSAGE_BUS_CHANNEL_SHOW_BOLSA_FAMILIA_ERROR = 'pos.farmaciapopular.bolsaFamiliaError';
const MESSAGE_BUS_CHANNEL_SHOW = 'pos.farmaciapopular.show';
const MESSAGE_BUS_CHANNEL_SHOWLOADING = 'pos.farmaciapopular.showLoading';
const MESSAGE_BUS_CHANNEL_HIDE = 'pos.farmaciapopular.hide';
const MESSAGE_BUS_CHANNEL_AUTORIZE_ERROR = 'pos.farmaciapopular.authorizeError';
const MESSAGE_MACHINE_DEVICE_DNA = 'machine.devices.fp.terminaldna';

class FarmaciaPopularContainer extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    showFarmaciaPopular: PropTypes.func.isRequired,
    hideFarmaciaPopular: PropTypes.func.isRequired,
    showLoading: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleShowError = this.handleShowError.bind(this);
    this.handleConfirmBolsaFamilia = this.handleConfirmBolsaFamilia.bind(this);
    this.handleConfirmBolsaFamilia = this.handleConfirmBolsaFamilia.bind(this);
    this.showBolsaFamiliaError = this.showBolsaFamiliaError.bind(this);
    this.showFarmaciaPopularConfirm = this.showFarmaciaPopularConfirm.bind(this);
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.back = this.back.bind(this);
    this.validatePrescriptions = this.validatePrescriptions.bind(this);

    let { MessageBus, showFarmaciaPopular, hideFarmaciaPopular, showLoading } = this.props;

    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SHOW, showFarmaciaPopular);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_HIDE, hideFarmaciaPopular);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_AUTORIZE_ERROR, this.handleShowError);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SHOW_CONFIRMATION, this.showFarmaciaPopularConfirm);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SHOW_BOLSA_FAMILIA_ERROR, this.showBolsaFamiliaError);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SHOWLOADING, showLoading);

    if (window.desktopApp) {
      window.desktopApp.subscribe(`${MESSAGE_MACHINE_DEVICE_DNA}.completed`, this.handleAuthorize);
      window.desktopApp.subscribe(`${MESSAGE_MACHINE_DEVICE_DNA}.error`, this.handleShowError);
    }
    this.state =
    {
      showFpMessage: false,
      fpMessage: null,
      fpHeaderMessage: null,
      authorizePayload: null,
      bolsaFamiliaProducts: null,
      novoArrayComItensAlterados: null
    }
  }

  showFarmaciaPopularConfirm = (ch, payload) => {
    this.props.showFarmaciaPopularConfirm({
      farmaciaPopular: this.props.payload,
      authorization: payload
    });
  }

  showBolsaFamiliaError = (ch, payload) => {
    this.setState({
      bolsaFamiliaProducts: payload
    });
    this.modalBolsaFamilia.open();
    this.modalBolsaFamilia.focus();
  }

  handleSignupLink(ch, payload) {
    if (window.desktopApp)
      window.desktopApp.publish('machine.browser.open', 'https://share.linx.com.br/x/ca6iG')
    else
      window.open(payload)
  }

  handleChangeBolsaFamiliaPrice = (value, barcode) => {
    var bolsaFamiliaPrice = parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.')) || 0

    this.setState({
      bolsaFamiliaProducts: this.state.bolsaFamiliaProducts.map(product => {
        if (product.barCode === barcode) {
          return { ...product, farmaciaPopularBolsaFamiliaPrice: bolsaFamiliaPrice };
        }
        return product;
      }),
    });
  }

  handleConfirmBolsaFamilia() {
    const updatedProducts = [...this.state.authorizePayload.products];

    updatedProducts.forEach((product) => {
      const matchingProduct = this.state.bolsaFamiliaProducts.find(
        (item) => item.barCode === product.barCode
      );

      if (matchingProduct) {
        product.price = matchingProduct.farmaciaPopularBolsaFamiliaPrice;
      }
    });

    let service = new FarmaciaPopularService();

    let payload = {
      dispensation: this.state.authorizePayload.dispensation,
      products: updatedProducts,
      terminalDna: this.state.authorizePayload.terminalDna,
      isBolsaFamiliaSale: true
    };
    service.Authorize(payload);
    this.modalBolsaFamilia.close();
  }

  handleAuthorize = (ch, terminalDna) => {
    this.props.payload.dispensation.products = this.props.payload.products;

    const dispensation = this.props.payload.dispensation;
    let payload = {
      dispensation: dispensation,
      products: this.props.payload.products,
      terminalDna: terminalDna
    };

    let service = new FarmaciaPopularService();
    this.setState({
      authorizePayload: payload
    });
    service.Authorize(payload);
  }

  handleShowError = (ch, payload) => {
    let msg = payload && (payload.error || payload.reason || payload);
    msg = msg.replace(/\n/g, '<br/><br/>');
    this.setState({
      showFpMessage: true,
      fpMessage: msg,
      fpHeaderMessage: 'Erro ao processar solicitação!'
    });
  }

  validatePrescriptions() {
    let valid = true;

    this.props.payload.products.forEach(p => {
      if (valid) {
        valid = p.prescriptionQuantity && (p.prescriptionQuantity > 0);
      }
    });
    return valid;
  }

  confirm() {
    this.setState({
      showFpMessage: !this.props.fp.online,
      fpMessage: null,
      fpHeaderMessage: null
    })
    if (!this.contentRef.validateAllFields()) return;

    if (!this.validatePrescriptions()) {
      this.handleShowError("", {
        reason: "A quantidade diária prescrita deve ser maior que zero (0)."
      });
      return;
    }

    if (!this.props.fp.online) {
      return;
    }

    if (window.desktopApp) {
      const { dispensation } = this.props.payload;
      window.desktopApp.publish(MESSAGE_MACHINE_DEVICE_DNA, dispensation);
    } else
      this.handleShowError("", { error: "Não é possível acessar as bibliotecas do Farmácia Popular a partir do navegador web." });
  }

  close = () => {
    this.setState({
      showFpMessage: false,
      fpHeaderMessage: null,
      fpMessage: null,
      authorizePayload: null,
      showBolsaFamilia: false,
      bolsaFamiliaProducts: null
    })
    let service = new FarmaciaPopularService();
    service.StartBack();
  }
  back = () => {
    this.setState({
      showFpMessage: false,
      fpHeaderMessage: null,
      fpMessage: null,
      authorizePayload: null,
      showBolsaFamilia: false,
      bolsaFamiliaProducts: null
    })
  }
  render() {
    const buttomBolsaFamiliaDisabled = this.state.bolsaFamiliaProducts?.some(item => item.farmaciaPopularBolsaFamiliaPrice <= 0);
    return (
      <Fragment>
        {this.props.show && (
          <FarmaciaPopular
            ref={ref => { this.contentRef = ref; }}
            title="FARMÁCIA POPULAR"
            options={this.props.payload.options}
            dispensation={this.props.payload.dispensation}
            products={this.props.payload.products}
            confirm={this.confirm}
            close={this.close}
            back={this.back}
            showFpMessage={this.state.showFpMessage}
            fpMessage={this.state.fpMessage}
            fpHeaderMessage={this.state.fpHeaderMessage}
          />
        )}
        <Modal
          ref={ref => this.modalBolsaFamilia = ref}
          noOverlay={false}
          opened={false}
          closable={true}
          className="modalBolsaFamilia"
          id="modalBolsaFamilia"
          style={{
            width: '70%',
            height: '60%',
            boxShadow: '0px 0px 5px 0 rgba(0, 0, 0, 0.2)',
          }}
        >
          <Modal.Body className='ModalBody'>
            <FarmaciaPopularBolsaFamilia
              handleChangeBolsaFamiliaPrice={this.handleChangeBolsaFamiliaPrice}
              productsList={this.state.bolsaFamiliaProducts}
              handleSignupLink={this.handleSignupLink}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="btn-confirm"
              className={buttomBolsaFamiliaDisabled ? "btn-default btn-purple disabled" : "btn-default btn-purple"}
              type="button"
              disabled={buttomBolsaFamiliaDisabled}
              onClick={this.handleConfirmBolsaFamilia}
              style={{ margin: '16px' }}
            >
              REAUTORIZAR
            </Button>
          </Modal.Footer>
        </Modal>
        {/* )} */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  show: shouldShow(state, FARMACIA_POPULAR), // state.farmaciaPopular.show && !state.farmaciaPopular.showConfirm,
  payload: getPayload(state, FARMACIA_POPULAR),
  fp: state.fp
});

const mapDispatchToProps = dispatch => ({
  showFarmaciaPopularConfirm: (payload) => dispatch(showCard(FARMACIA_POPULAR_CONFIRM, payload)),
  showFarmaciaPopular: (_ch, payload) => dispatch(showCard(FARMACIA_POPULAR, payload)),
  hideFarmaciaPopular: () => dispatch(closeAllCards()),
  showLoading: () => dispatch({ type: 'LOADING_SCREEN_SHOW', text: 'Comunicando com Farmácia Popular...' })
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(FarmaciaPopularContainer)));
