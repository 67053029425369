import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import getShortcut, {
  CLIENT_SEARCH,
  RECEIVE_PAYMENT,
  FINISH_PRE_ORDER,
} from '../../../keyboardShortcuts.js';
import { ORDER_DETAILS, SALE_FINISH } from '../../../redux/actions/views';
import { currencyMask } from '../../utils/masks';
import Button from '../button';
import CustomerContainer from '../customer-info';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';
import { CouponWrapper, FooterButton } from './styles';
import { FooterContent, ModalContent } from '../modal-new/styled';
import { ReactComponent as PurchaseHistoryIcon } from '../../../assets/images/purchase-history.svg';
import ModalNew from '../modal-new';
import TypePayment from '../../../container/type-payment';

const Coupon = ({
  order,
  selectedCustomer,
  handleClickListPayment,
  handleClickDonePreOrder,
  children,
  amountRemaning,
  changeMoney = false,
  handleClickCustomerSelection,
  formsPayment,
  handleFormPaymentCoupon,
  orderTotalSubsidy,
  totalPaidValue,
  openBusinessAgreements,
  hasBusinessAgreement,
  businessAgreementList,
  selectedBusinessAgreement,
  showBusinessAgreements,
  renderBusinessAgreementOption,
  openOrderDetail,
  handlePurchaseHistory,
  showProductMessageModal,
  showCustomerMessageModal,
  closeMessageModal,
  productMessage,
  customerInfo,
  authorizeSale,
  toggleAuthorizeForm,
  handleClientAuthorityChange,
  posType,
  currentBusinessAgreement,
  isOnline,
}) => {
  const isShownOrderDetail = useSelector(
    (state) => state.viewManager[ORDER_DETAILS]
  );

  const isShownSaleFinish = useSelector(
    (state) => state.viewManager[SALE_FINISH]
  );
  const allowAuthorization =
    customerInfo &&
    customerInfo.allowAuthorization &&
    (customerInfo.balance < 0 || customerInfo.status !== 0);

  return (
    <>
      {showProductMessageModal && (
        <ModalNew
          title='Mensagem de produto'
          close={() => closeMessageModal()}
          shouldHaveCloseButton
        >
          <ModalContent>
            <i className='material-icons'>info</i>
            <p>{productMessage}</p>
          </ModalContent>
        </ModalNew>
      )}
      {showCustomerMessageModal && (
        <ModalNew
          title='Mensagem de Cliente'
          close={() => closeMessageModal()}
          shouldHaveCloseButton
          footerContent={
            customerInfo.hasExpiredFinancialDocuments && (
              <FooterContent>
                <i className='material-icons-outlined'>info</i>
                <span>Este cliente possui títulos vencidos.</span>
              </FooterContent>
            )
          }
          buttons={
            allowAuthorization
              ? [
                {
                  text: 'Liberar',
                  color: '#F1CB10',
                  onClick: toggleAuthorizeForm,
                  shouldFocus: true,
                },
              ]
              : []
          }
        >
          <ModalContent>
            <CustomerContainer
              customerInfo={customerInfo}
              authorizeSale={authorizeSale}
              handleClientAuthorityChange={handleClientAuthorityChange}
            ></CustomerContainer>
          </ModalContent>
        </ModalNew>
      )}
      <CouponWrapper
        id='coupon-container'
        nowrapName={selectedCustomer?.name.length > 42}
      >
        <header>
          <div className='box-payment'>
            {/* <Button
            id='btn_payment_type'
            className='btn btn-outline'
            > */}
            <TypePayment id='type_payment' />
            {/* </Button> */}
          </div>
          <div className='box-b'>
            <Button
              id='btn_customer'
              className='btn btn-outline'
              handleClick={handleClickCustomerSelection}
            >
              {selectedCustomer && isOnline ? (
                <PurchaseHistoryIcon
                  width={30}
                  height={32}
                  style={{ cursor: isShownSaleFinish ? 'default' : 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePurchaseHistory();
                  }}
                />
              ) : (
                <i className='material-icons'>perm_identity</i>
              )}
              <span className='info'>
                <ShortcutHint
                  offsetLeft={0}
                  offsetTop={0}
                  shortcut={getShortcut(CLIENT_SEARCH)}
                  activateShortcut={handleClickCustomerSelection}
                />
                <span className='customer_name'>
                  {selectedCustomer
                    ? selectedCustomer.name
                    : 'CONSUMIDOR'}
                </span>
                {(hasBusinessAgreement || selectedBusinessAgreement) && (
                  <span
                    className='business_agreement_option'
                    onClick={openBusinessAgreements}
                  >
                    {selectedBusinessAgreement || 'Selecione um Convênio'}
                  </span>
                )}
              </span>
            </Button>
            {showBusinessAgreements && businessAgreementList?.length > 1 && (
              <div
                className={`menu-area-b-agreement ${order.order.recoveryId ? 'medium' : 'large'
                  }`}
                tabIndex={0}
              >
                {businessAgreementList?.map(renderBusinessAgreementOption)}
              </div>
            )}
            {order.order.recoveryId && (
              <Button
                disabled
                id='btn_shopping'
                className='btn btn-outline'
                icon='shopping_cart'
              >
                <span className='recovery-text'>
                  {order.order.recoveryDesc || order.order.recoveryId}
                </span>
              </Button>
            )}
          </div>
        </header>

        <main className='main-container'>
          {children}
          {currentBusinessAgreement && (
            <div className='sub-title-current-business-agreement'>{currentBusinessAgreement}</div>
          )}
          <div className='sub-total'>
            {formsPayment && formsPayment.length > 0 && (
              <span className='payments-subtitle'>Recebimentos</span>
            )}
            {formsPayment &&
              formsPayment.map((item, index) => (
                <div
                  className='sub-total-item payments-types-click'
                  key={index}
                  onClick={() => handleFormPaymentCoupon(item)}
                >
                  {item.namePay} {item.listPay && `( ${item.listPay.length} )`}
                  <span className='sub-total-valor'>
                    {currencyMask(item.amount)}
                  </span>
                </div>
              ))}
            {totalPaidValue ? (
              <div className='total-recebido bold'>
                Total Recebido
                <span className='sub-total-valor'>
                  {currencyMask(totalPaidValue)}
                </span>
              </div>
            ) : null}

            <div className='sub-total-item' onClick={openOrderDetail}>
              Subtotal
              <span className='sub-total-valor' style={{ paddingRight: '6px' }}>
                {order.order && order.order.orderTotal
                  ? currencyMask(order.order.orderTotal)
                  : 'R$ 0,00'}
              </span>
            </div>

            <div
              className='sub-total-item sub-total-valor'
              onClick={openOrderDetail}
            >
              Economia de
              <span className='sub-total-valor' style={{ paddingRight: '6px' }}>
                {currencyMask(order.order.orderDiscount)}
              </span>
            </div>

            {(orderTotalSubsidy && orderTotalSubsidy > 0) ||
              (order.order.orderTotalSubsidy &&
                order.order.orderTotalSubsidy > 0) ? (
              <div className='sub-total-item subsidy'>
                Subsídio
                <span className='sub-total-valor'>
                  {currencyMask(
                    orderTotalSubsidy && orderTotalSubsidy > 0
                      ? orderTotalSubsidy
                      : order.order.orderTotalSubsidy
                  )}
                </span>
              </div>
            ) : null}
          </div>
        </main>

        <footer className='footer-container'>
          <FooterButton>
            {order && posType === '2' && (
              <button
                type='button'
                id='btn_pay'
                className='btnListPreOrder'
                onClick={handleClickListPayment}
                disabled={changeMoney}
              >
                <ShortcutHint
                  shortcut={getShortcut(RECEIVE_PAYMENT)}
                  activateShortcut={handleClickListPayment}
                />
                {changeMoney ? 'TROCO' : 'Receber'}
              </button>
            )}

            {order && posType === '1' && (
              <button
                type='button'
                className='btnDonePreOrder'
                onClick={handleClickDonePreOrder}
              >
                <ShortcutHint
                  shortcut={getShortcut(FINISH_PRE_ORDER)}
                  activateShortcut={handleClickDonePreOrder}
                />
                {'Finalizar Pré-Venda'}
              </button>
            )}
            {changeMoney && (
              <p onClick={openOrderDetail} id='total'>
                {currencyMask(amountRemaning)}
              </p>
            )}
            {!changeMoney && (
              <p onClick={openOrderDetail} id='total'>
                {order.order && order.order.orderTotal
                  ? `${currencyMask(
                    order.order.orderTotalDiscount -
                    (order.order.orderTotalSubsidy || 0) -
                    totalPaidValue
                  )}`
                  : 'R$ 0,00'}
                <i className='material-icons'>
                  {isShownOrderDetail
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'}
                </i>
              </p>
            )}
          </FooterButton>
        </footer>
      </CouponWrapper>
    </>
  );
};

export default Coupon;
