import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialVisibleState = {
  onlineFiscalFlow: false  
};

const userSettingsReducer = createReducer(initialVisibleState, {
[types.CHECK_STATUS_FISCALFLOW]: (state, action) => ({
    ...state,
    onlineFiscalFlow: action.payload
  })
});

export default userSettingsReducer;