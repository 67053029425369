import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = null;

const configReducer = createReducer(initialState, {
  [types.UPDATE_CONFIG]: (state, { config }) => config
});

export default configReducer;
