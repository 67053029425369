import { Table as DefaultTable } from 'react-bootstrap';
import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const Container = styled.div``;

export const Filters = styled.div`
  display: flex;
  margin-bottom: ${theme.metrics.baseMargin * 2}px;

  button {
    margin-top: 22.5px;
  }

  input {
    height: 40px;
  }
`;

export const EmptyData = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: ${theme.metrics.baseMargin * 2}px;
  }
`;

export const Table = styled(DefaultTable)`
  position: relative;
  margin: 0 !important;

  th {
    text-align: center;
    vertical-align: bottom;

    &:last-child {
      padding-right: ${theme.metrics.basePadding}px;
    }
  }

  td {
    font-family: 'Ubuntu', 'Dosis';
    font-size: 14px;
    vertical-align: middle !important;
    text-align: center;
    padding: ${theme.metrics.basePadding}px;
    color: ${theme.palette.grayDark};
  }

  i {
    color: ${theme.palette.grayDark};
  }

  .alignRight {
    text-align: right;
  }

  .alignLeft {
    text-align: left;
  }

  .cxs {
    padding: 5px;
    width: 10px;
  }

  .active {
    color: ${theme.palette.primary};
    box-shadow: none;
  }

  tbody {
    tr {
      &:nth-child(odd) {
       
      }
    }
  }

  i {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
`;

export const Total = styled.h2`
  padding-top: ${theme.metrics.baseMargin}px;
  padding-right: ${theme.metrics.basePadding}px;
  text-align: right;
  font-size: 16px;
  color: ${theme.palette.primary};
  border-top: 1px solid ${theme.palette.tableBorder};

  span {
    padding-left: ${theme.metrics.basePadding * 4}px;
  }
`;

export const FooterItems = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 80px;
`;

export const FooterItemsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1 1 auto;
  
  &:last-child {
    justify-content: flex-end;
    flex-direction: column;
  }
  
  span.error {
      color: #F50101;
      text-align: right;
      display: block;
      font-size: 14px;
      margin-top: 10px;
      
  }

  span.error i {
    vertical-align: middle;
    padding-right: 5px;
  }
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 7px;

  span {
    padding-bottom: 3px;
    cursor: pointer;
  }
  span i {
    margin-left: 5px;
    font-size: 16px;
    vertical-align: middle;
    color: ${theme.palette.primary};
  }
  input {
    height: 40px;
    width: ${props => props.width || "140px"};
    border: 1px solid ${theme.palette.buttonBorder};
    border-radius: ${theme.metrics.borderRadius}px;
    font-family: 'Ubuntu', 'Dosis';
    font-size: 14px;
    padding: ${theme.metrics.basePadding}px;
    text-align: right;
  }
`;

export const FooterItemDiscount = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  .active {
    box-shadow: none;
    border: none;
  }

  label {
    padding-right: ${theme.metrics.basePadding * 2}px;
    padding-left: ${theme.metrics.basePadding}px;
    margin: 0;
    font-weight: bold;
  }

  i {
    cursor: pointer;

    &.active {
      color: ${theme.palette.primary};
    }
  }
`;

export const FooterItemReceive = styled(FooterItem)`
  color: ${theme.palette.primary};
  flex: 0 0 175px;
  align-items: flex-end;

  input {
    font-weight: bold;
    color: ${theme.palette.primary};
    width: 175px;
  }
  span i {
    margin-left: 5px;
    font-size: 16px;
    vertical-align: middle;
    cursor: pointer;
    color: ${theme.palette.primary};
  }
`;

export const ItemRender = styled.span`
  display: flex;
  height: 38px;
  border-top: 1px solid #e4e0e0;
  padding: 6px 12px;


  &.item-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
    

    span {
      font-family: 'Dosis';
    }

  }

  &.item-list .name-container {
    display: inline-block;
  }

  &.item-list .cpfTel-container {
    display: flex;
    justify-content: space-between;
  }

  &.item-list .name {
    font-weight: bold;
  }

  &.item-list .cpf {
    font-weight: bold;
  }

  &.item-list .tel {
    font-weight: bold;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    color: black;
    font-weight: bold;
    background-color: #ffb200;
  }
`;