import styled from 'styled-components';

import theme2 from '../../../../themes/theme2';

export const Container = styled.div`
  position: absolute;
  right: 48px;
  bottom: ${({ openTop }) => openTop && 0};
  top: ${({ openTop }) => !openTop && 0};
  min-width: 240px;
  padding: ${theme2.metrics.basePadding}px 0;
  z-index: 99;

  background: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  .button-popup-document-printing {
    width: 100% !important;
    background-color: transparent;
    border: 0;
    outline: none;
    padding: ${theme2.metrics.basePadding}px ${theme2.metrics.basePadding * 3}px;
    color: ${theme2.palette.primary};
    font-size: 14px;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      background-color: ${theme2.palette.hover} !important;
      color: ${theme2.palette.primary} !important;
      border: 0 !important;
    }

    &.cancel {
      color: ${theme2.palette.danger};

      &:hover,
      &:active,
      &:focus {
        color: ${theme2.palette.danger} !important;
      }
    }

    img {
      margin-right: ${theme2.metrics.basePadding}px;
    }
  }
`;
