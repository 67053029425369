import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactComponent as QuickIconSVG } from '../../assets/images/menu/star.svg';
import { ReactComponent as ConfigIconSVG } from '../../assets/images/menu/enginer.svg';
import { ReactComponent as PbmIconSVG } from '../../assets/images/menu/pill.svg';
import { ReactComponent as HelpIconSVG } from '../../assets/images/menu/help.svg';
import getShortcut, {
  MENU_QUICK_OPTIONS,
  PBM_LIST,
  MENU_HELP,
  CONFIGURATIONS,
} from '../../keyboardShortcuts';
import {
  shouldShow,
  showCard,
  CASH_OPEN,
  MONITOR_NOTAS_LIST,
  PRE_ORDER_LIST,
  ORDER_LIST,
  CONFIG_TERMINAL,
  CONFIG_PBM,
  CONFIG_TEF,
  CONFIG_POSCONNECT,
  CONFIG_FUNCIONAL,
  CONFIG_ORIZON,
  INITIALIZATION_ORIZON,
  OBJECTPRO,
} from '../../redux/actions/routerAction';
import * as shortcutsAction from '../../redux/actions/shortcutsAction';
import { showMessageAction } from '../../redux/modules/message/duck';
import * as cashDeskServices from '../../services/cashDeskServices';
import DeliveryService from '../../services/deliveryService';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import FinancialDocumentService from '../../services/financialDocumentService';
import DocumentPrintService from '../../services/documentPrintService';
import LinxConectaService from '../../services/linxConectaService';
import { OrderService } from '../../services/orderService';
import PaymentService from '../../services/paymentService';
import PbmSaleService, {
  PBM_EPHARMA,
  PBM_FUNCIONAL_CARD,
  PBM_ORIZON,
} from '../../services/pbmSaleService';
import SaleService from '../../services/saleService';
import TefPbmSaleService from '../../services/tefPbmSaleService';
import FarmaciaPopularStatusMessage from '../../shared/components/farmacia-popular-status-message';
import MenuItem from '../../shared/components/menu-item';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import configMenu from './config-menu';
import helpMenu from './help-menu';
import {
  OPERATION_LAST_PBM_SALE,
  OPERATION_NEW_SALE,
  OPERATION_CASH_OPEN,
  OPERATION_SANGRIA,
  OPERATION_CASH_SUPPLY,
  OPERATION_CANCEL_SALE,
  OPERATION_REPRINT_TEF,
  OPERATION_CANCEL_TEF,
  OPERATION_PAY_OFF,
  OPERATION_NAME_LINX_CONECTA,
  OPERATION_PRE_ORDER_LIST,
  OPERATION_CASH_CLOSE,
  OPERATION_CASH_CLOSE_PARTIAL,
  OPERATION_MONITOR_NOTAS,
  OPERATION_NAME_DTEF_Pbm,
  OPERATION_NAME_EPHARMA_PBM,
  OPERATION_LIST_AUTHORIZATIONS,
  OPERATION_NAME_FARMACIA_POPULAR,
  OPERATION_NAME_DELIVERY_CONTROL,
  OPERATION_NAME_CONFIG_FUNCIONAL,
  OPERATION_VIRTUAL_TOUR,
  OPERATION_CHAT,
  OPERATION_CLIENT_PORTAL,
  OPERATION_DOCUMENTATION,
  OPERATION_NAME_FUNCIONAL_PBM,
  OPERATION_NAME_CONFIG_ORIZON,
  OPERATION_NAME_CONFIG_TERMINAL,
  OPERATION_NAME_CONFIG_TEF,
  OPERATION_NAME_CONFIG_PBM,
  OPERATION_NAME_CONFIG_POSCONNECT,
  OPERATION_NAME_ORIZON,
  OPERATION_NAME_SEVEN_PBM,
  OPERATION_NAME_TRNCENTRE,
  OPERATION_OBJECTPRO,
  OPERATION_DOCUMENT_PRINTING,
} from './operations';
import pbmMenu from './pbm-menu';
import quickMenu from './quick-menu';
import { Container, FooterContainer } from './styles';
import UserPermissionService from '../../services/userPermissionService';
import { PERMISSION_PERMITE_REIMPRESSAO } from '../../services/permissions';

const MENU_CONFIG = 'Configurações';
const MENU_NAME_QUICK_OPTIONS = 'Acesso Rápido';
const MENU_NAME_PBM = "Pbm's disponíveis";
const MENU_NAME_HELP = 'Ajuda';

export class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null,
      show: true,
      quickMenuItems: quickMenu,
      pbmMenuItems: pbmMenu,
      configMenuItems: configMenu,
      helpMenuItems: helpMenu,
      showFpStatusMessage: false,
    };

    this.saleServices = new SaleService();
    this.paymentService = new PaymentService();
    this.orderService = new OrderService();
    this.deliveryService = new DeliveryService();

    this.opening = false;

    props.MessageBus.Subscribe('pos.reload', () => this.toggleMenuItems(true));

    props.MessageBus.Subscribe('pos.menu.showConfiguracoes', () =>
      this.handleMenuItemClick(MENU_CONFIG)
    );

    props.MessageBus.Subscribe('pos.open.success', () =>
      this.toggleMenuItems(true)
    );
    props.MessageBus.Subscribe('pos.close.success', () =>
      this.toggleMenuItems(false)
    );
    props.MessageBus.Subscribe('payment.done', () =>
      this.setState({ show: false })
    );
    props.MessageBus.Subscribe('sale.done', () =>
      this.setState({ show: true })
    );
    const pdvType = this.props.posType;
    if (pdvType === '1')
      props.MessageBus.Subscribe('pos.order.concluded', () =>
        this.setState({ show: false })
      );

    this.hideMenuItemsPayment.bind(this);
  }

  hideMenu = (menu) => {
    if (!menu.configName) return false;
    const configRetailer = this.props.configRetailer?.find(
      (config) => config.name.toLowerCase() === menu.configName.toLowerCase()
    );

    return configRetailer !== undefined && configRetailer.value !== 'true';
  };

  toggleMenuItems = (opening) => {
    this.opening = opening;
    const pdvType = this.props.posType;
    const quickMenuItems = quickMenu.map((item) => {
      if (item.showOnCashDeskOpened !== undefined) {
        const isShowAll = !(pdvType === '1');

        if ((!opening && pdvType === '2') === item.showOnCashDeskOpened) {
          item.isHidden = true;
        } else if (isShowAll) {
          item.isHidden = false;
        } else {
          item.isHidden = !item.showBothPdv;
        }

        if (!this.props.isOnline && !item.showPdvContingency) {
          item.isHidden = true;
        }
      }

      if (item.operationName === OPERATION_NAME_DELIVERY_CONTROL) {
        item.isHidden =
          this.props.configRetailer?.find((item) => {
            return item.name === 'DeliveryAvailable' && item.value === 'true';
          }) === undefined;
      }

      if (item.operationName === OPERATION_OBJECTPRO) {
        item.isHidden =
          this.props.configRetailer?.filter(
            (x) => x.name === 'OBJECT_PRO_PDV' && x.value === 'true'
          ).length === 0 || (!this.props.isOnline && !item.showPdvContingency);
      }

      if (item.operationName === OPERATION_DOCUMENT_PRINTING)
        item.isHidden = !UserPermissionService.GetInstance().hasPermission(
          PERMISSION_PERMITE_REIMPRESSAO
        );

      item.isHidden = item.isHidden || this.hideMenu(item);

      return item;
    });

    let pbmMenuItems = pbmMenu.map((item) => {
      item.isHidden = !opening;
      if (item.operationName === OPERATION_NAME_FARMACIA_POPULAR) {
        item.enabled = this.props.fp.online;
        item.isHidden =
          !this.props.fp.retailerHasConfig || !this.props.fp.userHasConfig;
      }

      if (item.operationName === OPERATION_NAME_TRNCENTRE) {
        item.subMenu = [
          {
            description: 'Iniciar Transação',
            onClick: this.handleTrnCentreStart,
          },
          ,
          {
            description: 'Extrato de Transações',
            onClick: this.handleTrnCentreStartResume,
          },
        ];
        item.isHidden =
          this.props.configRetailer?.filter(
            (x) => x.name === 'PBM_PORTAL_DA_DROGARIA' && x.value === 'true'
          ).length === 0;
      }

      item.isHidden = item.isHidden || this.hideMenu(item);

      return item;
    });

    const configMenuItems = configMenu.map((item) => {
      item.isHidden = !opening;
      if (item.operationName === OPERATION_NAME_CONFIG_POSCONNECT) {
        item.isHidden =
          this.props.configRetailer?.find((item) => {
            return item.name === 'POS_CONNECT' && item.value === '1';
          }) === undefined;
      }
      return item;
    });

    const visiblePbmMenus = pbmMenuItems.filter((item) => !item.isHidden);

    if (
      visiblePbmMenus.length === 1 &&
      visiblePbmMenus[0].operationName === OPERATION_LIST_AUTHORIZATIONS
    ) {
      pbmMenuItems = [];
    }

    if (!opening && pdvType === '2') {
      setTimeout(() => this.props.cashOpenBoxShow(), 10);
    }

    this.setState({ quickMenuItems, pbmMenuItems, configMenuItems });
  };

  handleTrnCentreStart = (e) => {
    e.preventDefault();
    PbmSaleService.startTrnCentre();
    this.setState({ activeMenu: null });
  };

  handleTrnCentreStartResume = (e) => {
    e.preventDefault();
    PbmSaleService.startTrnCentreResume();
    this.handleMenuItemClose();
  };

  handleClickMenuConfiguracoes = () => {
    this.saleServices.showConfigurationMenu();
  };

  handleMenuItemClick = (menu) => {
    const { activeMenu } = this.state;
    if (activeMenu === menu) return this.setState({ activeMenu: null });

    this.hideMenuItemsPayment();
    return this.setState({ activeMenu: menu });
  };

  handleMenuItemClose = () => {
    this.setState({ activeMenu: null });
    this.handleCloseSubMenus();
  };

  hideMenuItemsPayment = () => {
    const isPay = document.getElementsByTagName('section').length;
    if (!isPay) return this.toggleMenuItems(true);
    const quickMenuItems = quickMenu.map((item) => {
      if (item.operationName === OPERATION_NEW_SALE && this.opening) {
        item.isHidden = false;
      } else {
        item.isHidden = true;
      }
      return item;
    });

    return this.setState({ quickMenuItems });
  };

  handleCloseSubMenus() {
    let menus = this.state.pbmMenuItems.map((item) => {
      item.showSubMenu = false;
      return item;
    });
    this.setState({ pbmMenuItems: menus });
  }

  handleSubmenuClick(command) {
    let menus = this.state.pbmMenuItems.map((item) => {
      if (item.operationName === command) {
        item.showSubMenu = !item.showSubMenu;
      }
      return item;
    });
    this.setState({ pbmMenuItems: menus });
  }

  handleSubmenuItemClick = (command) => {
    switch (command) {
      case OPERATION_NAME_DTEF_Pbm:
        TefPbmSaleService.startSale();
        break;
      case OPERATION_LAST_PBM_SALE:
        TefPbmSaleService.reprintLastSalePbm();
        break;
      case OPERATION_NAME_EPHARMA_PBM:
        PbmSaleService.getInitializationData(PBM_EPHARMA);
        break;
      case OPERATION_NAME_FUNCIONAL_PBM:
        PbmSaleService.getInitializationData(PBM_FUNCIONAL_CARD);
        break;
      case OPERATION_NAME_SEVEN_PBM:
        PbmSaleService.startSevenPBM();
        break;
      case OPERATION_NAME_TRNCENTRE:
        this.handleSubmenuClick(command);
        return;
      case OPERATION_NAME_ORIZON:
        PbmSaleService.getInitializationData(PBM_ORIZON);
        break;
      case OPERATION_LIST_AUTHORIZATIONS:
        PbmSaleService.openAuthorizationList();
        break;
      case OPERATION_NEW_SALE:
        this.props.showMessage({
          message: 'Deseja realmente iniciar uma nova venda?',
          okText: 'Sim',
          onConfirm: this.confirmCancel,
          onCancel: () => { },
        });
        break;
      case OPERATION_CASH_OPEN:
        this.props.cashOpenBoxShow();
        break;
      case OPERATION_CASH_CLOSE:
        cashDeskServices
          .cashCloseStart(false)
          .catch((error) => this.context.showError(error));
        break;
      case OPERATION_CASH_CLOSE_PARTIAL:
        cashDeskServices
          .cashCloseStart(true)
          .catch((error) => this.context.showError(error));
        break;
      case OPERATION_SANGRIA:
        cashDeskServices
          .cashWithdrawStart()
          .catch((error) => this.context.showError(error));
        break;
      case OPERATION_CASH_SUPPLY:
        cashDeskServices
          .cashSupplyStart()
          .catch((error) => this.context.showError(error));
        break;
      case OPERATION_PRE_ORDER_LIST:
        this.orderService
          .getPreOrderList()
          .catch((error) => this.context.showError(error));
        this.props.preOrderListShow();
        break;
      case OPERATION_CANCEL_SALE:
        this.props.orderListShow({});
        this.GetListLastOrders();
        break;
      case OPERATION_REPRINT_TEF:
        this.props.orderListShow({ reprintTef: true });
        this.GetListLastOrders();
        break;
      case OPERATION_CANCEL_TEF:
        this.props.orderListShow({ cancelTef: true });
        this.GetListLastOrders();
        break;
      case OPERATION_NAME_FARMACIA_POPULAR:
        this.startFarmaciaPopular();
        break;
      case OPERATION_MONITOR_NOTAS:
        this.props.monitorNotasShow();
        break;
      case OPERATION_PAY_OFF:
        FinancialDocumentService.start();
        break;
      case OPERATION_NAME_LINX_CONECTA:
        LinxConectaService.listarEmpresas();
        break;
      case OPERATION_NAME_DELIVERY_CONTROL:
        this.deliveryService.deliveryShow();
        break;
      case OPERATION_VIRTUAL_TOUR:
        window.open('https://cliente.linx.com.br/visitavirtual');
        break;
      case OPERATION_CHAT:
        window.open('https://api.whatsapp.com/send/?phone=5511945584468&text&type=phone_number');
        break;
      case OPERATION_DOCUMENTATION:
        window.open('https://share.linx.com.br/x/Bc7-Bg');
        break;
      case OPERATION_CLIENT_PORTAL:
        window.open('https://cliente.linx.com.br/');
        break;
      case OPERATION_NAME_CONFIG_TERMINAL:
        this.props.configTerminalShow();
        break;
      case OPERATION_NAME_CONFIG_PBM:
        this.props.configPBMShow();
        break;
      case OPERATION_NAME_CONFIG_TEF:
        this.props.configTefShow();
        break;
      case OPERATION_NAME_CONFIG_POSCONNECT:
        this.paymentService.getPosConnectConfig();
        break;
      case OPERATION_NAME_CONFIG_FUNCIONAL:
        this.props.configFuncionalShow();
        break;
      case OPERATION_NAME_CONFIG_ORIZON:
        this.props.configOrizonShow();
        break;
      case OPERATION_OBJECTPRO:
        this.props.showMessage({
          message: 'Deseja realmente iniciar uma nova venda?',
          okText: 'Sim',
          onConfirm: this.confirmOpenObjectPro,
          onCancel: () => { },
        });
        break;
      case OPERATION_DOCUMENT_PRINTING:
        DocumentPrintService.start();
        break;
      default:
        break;
    }

    this.handleMenuItemClose();
  };

  confirmCancel = (reason) => {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.tef.undo', '');
    }

    this.saleServices
      .deleteOrder(reason)
      .catch((error) => this.context.showError(error));
  };

  confirmOpenObjectPro = (reason) => {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.tef.undo', '');
    }

    this.saleServices
      .deleteOrderObjectPro(reason)
      .catch((error) => this.context.showError(error));
  };

  GetListLastOrders = () => {
    const newDate = new Date();
    this.orderService
      .getListLastOrder(
        moment(newDate).startOf('day').toISOString(),
        moment(newDate).endOf('day').toISOString()
      )
      .catch((error) => this.context.showError(error));
  };

  startFarmaciaPopular = () => {
    if (this.props.fp.online) {
      const service = new FarmaciaPopularService();
      service.Start();
    } else {
      this.setState({
        showFpStatusMessage: true,
      });
    }
  };

  closeFpStatusMessage = () => {
    this.setState({
      showFpStatusMessage: false,
    });
  };

  render() {
    const {
      show,
      activeMenu,
      quickMenuItems,
      pbmMenuItems,
      configMenuItems,
      helpMenuItems,
      showFpStatusMessage,
    } = this.state;

    const { cashOpenShow } = this.props;
    return (
      <>
        <Container data-testid='menu-container'>
          {show && !cashOpenShow && (
            <>
              <li>
                <MenuItem
                  active={activeMenu === MENU_NAME_QUICK_OPTIONS}
                  name={MENU_NAME_QUICK_OPTIONS}
                  onClick={this.handleMenuItemClick}
                  onClose={this.handleMenuItemClose}
                  onSubmenuItemClick={this.handleSubmenuItemClick}
                  icon={<QuickIconSVG />}
                  items={quickMenuItems}
                  shortKey={getShortcut(MENU_QUICK_OPTIONS)}
                />
              </li>
              {pbmMenuItems.length > 0 && this.props.isOnline && (
                <li>
                  <MenuItem
                    active={activeMenu === MENU_NAME_PBM}
                    name={MENU_NAME_PBM}
                    onClick={this.handleMenuItemClick}
                    onClose={this.handleMenuItemClose}
                    onSubmenuItemClick={this.handleSubmenuItemClick}
                    icon={<PbmIconSVG />}
                    items={pbmMenuItems}
                    shortKey={getShortcut(PBM_LIST)}
                  />
                </li>
              )}
              {this.props.isOnline && (
                <li>
                  <MenuItem
                    active={activeMenu === MENU_NAME_HELP}
                    name={MENU_NAME_HELP}
                    onClick={this.handleMenuItemClick}
                    onClose={this.handleMenuItemClose}
                    onSubmenuItemClick={this.handleSubmenuItemClick}
                    icon={<HelpIconSVG />}
                    items={helpMenuItems}
                    shortKey={getShortcut(MENU_HELP)}
                  />
                </li>
              )}
            </>
          )}
        </Container>
        <FooterContainer>
          {show && this.props.isOnline && (
            <li>
              <MenuItem
                active={activeMenu === MENU_CONFIG}
                name={MENU_CONFIG}
                onClick={this.handleClickMenuConfiguracoes}
                onClose={this.handleMenuItemClose}
                onSubmenuItemClick={this.handleSubmenuItemClick}
                icon={<ConfigIconSVG />}
                items={configMenuItems}
                shortKey={getShortcut(CONFIGURATIONS)}
              />
            </li>
          )}
        </FooterContainer>
        <FarmaciaPopularStatusMessage
          visible={showFpStatusMessage}
          confirm={this.closeFpStatusMessage}
          okText='Ok'
        />
      </>
    );
  }
}

Menu.propTypes = {
  fp: PropTypes.shape({
    online: PropTypes.bool,
    userHasConfig: PropTypes.bool,
    retailerHasConfig: PropTypes.bool,
  }).isRequired,
  showMessage: PropTypes.func.isRequired,
  showObjectPro: PropTypes.func.isRequired,
  orderListShow: PropTypes.func.isRequired,
  cashOpenBoxShow: PropTypes.func.isRequired,
  cashOpenShow: PropTypes.bool.isRequired,
  monitorNotasShow: PropTypes.func.isRequired,
  configTerminalShow: PropTypes.func.isRequired,
  configPBMShow: PropTypes.func.isRequired,
  configTeFShow: PropTypes.func.isRequired,
  configPosConnectShow: PropTypes.func.isRequired,
  preOrderListShow: PropTypes.func.isRequired,
  MessageBus: PropTypes.shape({
    Publish: PropTypes.func,
    Subscribe: PropTypes.func,
  }).isRequired,
  configRetailer: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

Menu.defaultProps = {
  configRetailer: [],
};

const mapStateToProps = (state) => ({
  options: state.options,
  administrativeMenu: state.administrativeMenu,
  cardOpened: state.card.cardOpened,
  cashOpenShow: shouldShow(state, CASH_OPEN),
  order: state.order.order,
  fp: state.fp,
  configRetailer: state.configRetailer.configs,
  posType: state.posType.posType,
  isOnline: state.pdvContingency.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
  cashOpenBoxShow: () => dispatch(showCard(CASH_OPEN, null, true)),
  preOrderListShow: () => dispatch(showCard(PRE_ORDER_LIST, null, true)),
  orderListShow: (payload) => dispatch(showCard(ORDER_LIST, payload)),
  blockAllShortcutsBut: (shortcutsToNotBlock) =>
    dispatch(shortcutsAction.blockAllShortcutsBut(shortcutsToNotBlock)),
  unblockAllShortcuts: () => dispatch(shortcutsAction.unblockAllShortcuts()),
  monitorNotasShow: () => dispatch(showCard(MONITOR_NOTAS_LIST, null, true)),
  configTerminalShow: () => dispatch(showCard(CONFIG_TERMINAL, null, true)),
  configPBMShow: () => dispatch(showCard(CONFIG_PBM, null, true)),
  configTefShow: () => dispatch(showCard(CONFIG_TEF, null, true)),
  configPosConnectShow: () => dispatch(showCard(CONFIG_POSCONNECT, null, true)),
  showMessage: (payload) => dispatch(showMessageAction(payload)),
  configFuncionalShow: () => dispatch(showCard(CONFIG_FUNCIONAL, null, true)),
  configOrizonShow: () => dispatch(showCard(CONFIG_ORIZON, null, true)),
  initOrizonShow: () => dispatch(showCard(INITIALIZATION_ORIZON, null, true)),
  showObjectPro: () => dispatch(showCard(OBJECTPRO, null, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getContext(withMessageBus(getContext(Menu))));
