import React, { useEffect, useState } from 'react';

import { Card } from '../../card';
import { ContainerComponent } from '../../container/container-component';

import { ButtonComponent } from '../../button-component';

import { Title } from '../style';

import { IHeader } from '../../../interfaces/header';
import { Table } from './style';

import { Field } from '../../field';
import * as cashDeskServices from '../../../../services/cashDeskServices';
import MessageBusService from '../../../../services/MessageBusService';
import { showToast } from '../../../utils/util';

export interface IReportCashClose {
  posId: string;
  userName: string;
  userReferenceId: string;
  cashDeskCode: string;
  systemValues: {
    money: number;
    withDraw: number;
    creditCard: number;
    debitCard: number;
    creditSale: number;
    agreementSale: number;
    qrLinx: number;
    pix: number;
  };
  userValues: {
    money: number;
    withDraw: number;
    creditCard: number;
    debitCard: number;
    creditSale: number;
    agreementSale: number;
    qrLinx: number;
    pix: number;
  };
  configurations: {
    showMoney: boolean;
    showWithDraw: boolean;
    showCreditCard: boolean;
    showDebitCard: boolean;
    showCreditSale: boolean;
    showAgreementSale: boolean;
    showQrLinx: boolean;
    showPix: boolean;
  };
  handleConfirm: any;
}

interface IReportData {
  formaPagamento: string;
  valorRegistradoPdv: number;
  totalConferido: number;
  diferenca: number;
  show: boolean;
}

export function ReportCashClose(props: IReportCashClose) {
  const [description, setDescription] = useState('');
  const [htmlReport, setHtmlReport] = useState('');

  const headers = [
    {
      field: 'formaPagamento',
      label: 'Tipo de Movimento',
      show: true,
    },
    {
      field: 'valorRegistradoPdv',
      label: 'Registrado no PDV',
      spanValue: true,
      show: true,
    },
    {
      field: 'totalConferido',
      label: 'Total Conferido Acumulado',
      spanValue: true,
      show: true,
    },
    {
      field: 'diferenca',
      label: 'Diferença',
      spanValue: true,
      show: true,
    },
  ] as IHeader[];

  const reportData = [
    {
      formaPagamento: 'Dinheiro',
      valorRegistradoPdv: props.systemValues.money,
      totalConferido: props.userValues.money,
      diferenca: props.userValues.money - props.systemValues.money,
      show: props.configurations?.showMoney,
    },
    {
      formaPagamento: 'Sangrias realizadas',
      valorRegistradoPdv: props.systemValues.withDraw,
      totalConferido: props.userValues.withDraw,
      diferenca: props.userValues.withDraw - props.systemValues.withDraw,
      show: props.configurations?.showWithDraw,
    },
    {
      formaPagamento: 'Crediário',
      valorRegistradoPdv: props.systemValues.creditSale,
      totalConferido: props.userValues.creditSale,
      diferenca: props.userValues.creditSale - props.systemValues.creditSale,
      show: props.configurations?.showCreditSale,
    },
    {
      formaPagamento: 'Convênio',
      valorRegistradoPdv: props.systemValues.agreementSale,
      totalConferido: props.userValues.agreementSale,
      diferenca:
        props.userValues.agreementSale - props.systemValues.agreementSale,
      show: props.configurations?.showAgreementSale,
    },
    {
      formaPagamento: 'Cartão de crédito',
      valorRegistradoPdv: props.systemValues.creditCard,
      totalConferido: props.userValues.creditCard,
      diferenca: props.userValues.creditCard - props.systemValues.creditCard,
      show: props.configurations?.showCreditCard,
    },
    {
      formaPagamento: 'Cartão de débito',
      valorRegistradoPdv: props.systemValues.debitCard,
      totalConferido: props.userValues.debitCard,
      diferenca: props.userValues.debitCard - props.systemValues.debitCard,
      show: props.configurations?.showDebitCard,
    },
    {
      formaPagamento: 'QR Linx',
      valorRegistradoPdv: props.systemValues.qrLinx,
      totalConferido: props.userValues.qrLinx,
      diferenca: props.userValues.qrLinx - props.systemValues.qrLinx,
      show: props.configurations?.showQrLinx,
    },
    {
      formaPagamento: 'Pix',
      valorRegistradoPdv: props.systemValues.pix,
      totalConferido: props.userValues.pix,
      diferenca: props.userValues.pix - props.systemValues.pix,
      show: props.configurations?.showPix,
    },
  ] as IReportData[];

  useEffect(() => {
    const closeDescriptionSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.closedescription.success',
      props.handleConfirm
    );

    const closeDescriptionError = MessageBusService.GetInstance().Subscribe(
      'pos.closedescription.error',
      callErrorMessage
    );

    const machineDevicePrinter = MessageBusService.GetInstance().Subscribe(
      'machine.devices.printer',
      saveHtmlReport
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(closeDescriptionSuccess);
      MessageBusService.GetInstance().Unsubscribe(closeDescriptionError);
      MessageBusService.GetInstance().Unsubscribe(machineDevicePrinter);
    };
  }, []);

  const printReport = () => {
    MessageBusService.GetInstance().Publish(
      'machine.devices.printer',
      htmlReport
    );
  };

  const saveHtmlReport = (channel, payload) => {
    setHtmlReport(payload);
  };

  const close = () => {
    cashDeskServices.cashCloseSaveDescription(description);
  };

  function setMaskMoneyToSpan(value: number) {
    return value
      .toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      })
      .replace('R$', 'R$ ');
  }

  function setClass(data: IReportData, headerField: string) {
    if (headerField === 'valorRegistradoPdv') return '';

    if (data.diferenca === 0) return 'success';
    else if (data.diferenca < 0) return 'error';
    else return 'warning';
  }

  const callErrorMessage = () => {
    showToast({
      type: 2,
      title: 'Erro ao salvar observações!',
      html:
        'Erro ao salvar observações verifique sua conexão com a internet e tente novamente.',
      hideAfterMilliseconds: 5000,
    });
  };

  return (
    <Card
      id='card-close'
      title={`Fechamento de caixa`}
      subTitle={`Terminal de caixa: ${props.posId} - Operador: ${props.userName}, ID ${props.userReferenceId} - Sequência: ${props.cashDeskCode}`}
      handleClose={close}
      closeEnable
      autoScroll
      orientationVertical={true}
      style={{
        alignItems: 'center',
      }}
    >
      <ContainerComponent id='container-cash-close' style={{ width: '700px' }}>
        <div className='row'>
          <Title>
            <h1>Total movimentado</h1>
          </Title>
        </div>

        <div
          className='row'
          style={{ padding: '0.5rem 1rem 1rem 1rem', background: '#F4F8FB' }}
        >
          <Table data-testid={'table-cash-close-report'}>
            <thead>
              <tr>
                {headers
                  .filter((h) => h.show)
                  .map((header, index) => {
                    return <th key={index}>{header.label}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {reportData.length &&
                reportData
                  .filter((c) => c.show)
                  .map((data, index) => {
                    return (
                      <tr key={index}>
                        {headers
                          .filter((h) => h.show)
                          .map((header, idxHeader) => {
                            if (header.spanValue)
                              return (
                                <td
                                  key={idxHeader}
                                  className={setClass(data, header.field)}
                                  style={{ textAlign: 'right' }}
                                >
                                  {setMaskMoneyToSpan(data[header.field])}
                                </td>
                              );

                            return (
                              <td key={idxHeader}>{data[header.field]}</td>
                            );
                          })}
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </div>

        <div className='row' style={{ flexDirection: 'column' }}>
          <Title style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <h3>Insira observações em caso de diferença</h3>
          </Title>

          <Field
            id='field-description'
            placeholder='Em caso de divergências, insira observações aqui'
            labelText='Observações'
            type='text'
            typeInput='textarea'
            showError={false}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            showMaxLenght={true}
            maxLength={200}
          />
        </div>

        <div className='row'>
          <div className='btn-group' style={{ justifyContent: 'flex-end' }}>
            <ButtonComponent
              title='Imprimir relatório'
              name='btn-cancel'
              onClick={printReport}
              style={{ marginRight: '.5rem', width: '194px' }}
            />

            <ButtonComponent
              className='btn-b-success'
              title='Fechar'
              onClick={close}
            />
          </div>
        </div>
      </ContainerComponent>
    </Card>
  );
}
