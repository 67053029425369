import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const Container = styled.div`
  height: 100%;
  .card-body {
    padding: 0;
  }
`;

export const InputGroup = styled.div`
  span {
    color: #fe2e2e;
    height: 25px;
  }

  small {
    color: #888484;
  }
`;

export const CashCloseGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${theme.metrics.basePadding * 3}px;
  grid-row-gap: ${theme.metrics.basePadding * 2}px;

  label {
    margin: 0;
    line-height: 20px;
  }

  input {
    padding: 0 ${theme.metrics.basePadding * 2}px;
    width: 100%;
    height: 40px;
    border: 1px solid ${theme.palette.buttonBorder};
    border-radius: ${theme.metrics.borderRadius}px;
    font-family: 'Ubuntu', 'Dosis';
    font-size: 14px;
  }

  small {
    font-family: 'Ubuntu', 'Dosis';
    font-size: 12px;
    line-height: 20px;
    display: block;
    color: ${theme.palette.grayMedium} !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CashCloseFormActions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${theme.metrics.basePadding * 2}px;
  width: fit-content;
  margin: ${theme.metrics.baseMargin * 6}px 0 0 auto;

  .btn-custom {
    width: 130px;
  }

  .btn-outline {
    color: ${theme.palette.primary};

    &:focus {
      color: white;
    }
  }

  text-align: right;
`;

export const GridCellEmpty = styled.div``;

export const Form = styled.form``;
