import React from 'react';

import { AbasGroup, Span } from './style';

export interface IAbas {
    abaName: string;
    selected: boolean;
}

interface IAbasComponent {
    abas: IAbas[];
    handleClick: any;
}


export function AbasComponent(props: IAbasComponent) {
    const setSelectItem = (index: number) => {
        props.abas.filter(x => x.selected).forEach((x) => x.selected = false);
        props.abas[index].selected = true;

        props.handleClick([...props.abas]);
    }

    return (
        <AbasGroup>
            {props.abas.map((aba, index) => {
                return (
                    <Span key={index} data-testid={'aba'+index} className={aba.selected ? 'selected' : ''}
                        onClick={() => setSelectItem(index)}
                    >
                        <span className="title">
                            {aba.abaName}
                        </span>
                    </Span>
                )
            })}
        </AbasGroup>
    )
}