import styled from 'styled-components';

export const PbmConfirmWraper = styled.div`
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;

    .row-total {
        display: flex;
        flex: 0 0 90px;
        align-items: center;

        .total-group {
            flex: 1;
        }
    }

    b {
        margin-right: 5px;
    }

    .table-cell {
        align-items: center !important;
    }

    .circle {
        height: 15px;
        width: 15px;
        border-radius: 25px;
        display: block;

        &.approved {
            background: rgb(105,227,73);
            background: radial-gradient(circle, rgba(105,227,73,1) 0%, rgba(79,172,55,1) 61%, rgba(105,227,73,1) 100%);
        }

        &.partial {
            background: rgb(224,227,73);
            background: radial-gradient(circle, rgba(224,227,73,1) 0%, rgba(172,171,55,1) 61%, rgba(224,227,73,1) 100%);
        }

        &.refused {
            background: rgb(227,73,73);
            background: radial-gradient(circle, rgba(227,73,73,1) 0%, rgba(172,55,55,1) 61%, rgba(227,73,81,1) 100%);
        }
    }

    .legend {
        display: flex;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 8px 0 8px 12px;

        &::before {
            content: 'Legenda';
            position: absolute;
            margin-top: -19px;
            background: white;
            height: 20px;
            padding: 0 5px;
        }

        .legend-item {
            display: flex;
            flex: 1;
            align-items: center;

            .circle {
                margin-right: 5px;
            }
        }
    }
`;
