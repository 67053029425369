import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "../modules";
import { Formik, Form, Field } from "formik";
import { Modal } from "../modal2";
import { Button } from "../modules";
import { DatePicker } from "../date-picker";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  ContinuousUseFormWrapper,
  GroupContinuousUseItems,
  TitleSection,
} from "./style";
import { KeyboardNavigationProvider } from "../keyboard-navigation-form";
import FormControl from "../@form-control";
import { FormModal } from "./form-modal";
import { schema } from "./validation";
import { PrescriberSpecialty } from "./prescriber-specialty";
import { PrescriberContinuousUse } from "./prescriber";
import { PrescriptionOrigin } from "./prescription-origin";
import { HospitalClinic } from "./hospital-clinic";
import { Pathology } from "./pathology";
import { requiredFieldsMessage } from "../fieldset";
import { handleTypedDateChange } from "../../utils/handleDate";

const CardContinuousUse = (props) => {
  const {
    title,
    state,
    handleClose,
    handleSubmitRecord,
    handleNotInformContinuousUse,
    handleSubmit,
    autocompleteQueryFn,
    renderItem,
    modalRef,
  } = props;

  const {
    product,
    anyContinuousUseFieldRequired,
    fields,
    prescriberspecialtyList,
    prescriptionOriginList,
    hospitalClinicList,
    pathologyList,
    manage,
  } = state;
  const { continuousUse } = product ? product : "";

  const [newRecord, setNewRecord] = useState({});

  const metricUnitySelect = [
    { value: "un", name: "UN" },
    { value: "kg", name: "KG" },
    { value: "lt", name: "LT" },
    { value: "pct", name: "PCT" },
  ];

  const quantitySelect = [
    { value: 1, name: "1" },
    { value: 1.5, name: "1,5" },
    { value: 2, name: "2" },
    { value: 2.5, name: "2,5" },
    { value: 3, name: "3" },
    { value: 3.5, name: "3,5" },
    { value: 4, name: "4" },
    { value: 4.5, name: "4,5" },
    { value: 5, name: "5" },
    { value: 5.5, name: "5,5" },
    { value: 6, name: "6" },
    { value: 6.5, name: "6,5" },
    { value: 7, name: "7" },
    { value: 7.5, name: "7,5" },
    { value: 8, name: "8" },
    { value: 8.5, name: "8,5" },
    { value: 9, name: "9" },
    { value: 9.5, name: "9,5" },
    { value: 10, name: "10" },
    { value: 10.5, name: "10,5" },
  ];

  const periodSelect = [
    { value: 1, name: "Diário" },
    { value: 2, name: "Semanal" },
    { value: 3, name: "Quinzenal" },
    { value: 4, name: "Mensal" },
  ];

  const initialValues = {
    prescriber: {
      required: fields.prescriber.required,
      id: continuousUse?.prescriber?.id || "",
      referenceId: continuousUse?.prescriber?.referenceId || "",
      name: continuousUse?.prescriber?.name || "",
      documentNumber: continuousUse?.prescriber?.documentNumber || "",
      documentDescription: continuousUse?.prescriber?.documentType || "",
      documentUF: continuousUse?.prescriber?.documentUF || "",
    },
    prescriberSpecialty: {
      required: fields.prescriberSpecialty.required,
      id: continuousUse?.specialty?.id || "",
      referenceId: continuousUse?.specialty?.referenceId || "",
      name: continuousUse?.specialty?.name || "",
    },
    prescriptionOrigin: {
      required: fields.prescriptionOrigin.required,
      id: continuousUse?.origin?.id || "",
      referenceId: continuousUse?.origin?.referenceId || "",
      name: continuousUse?.origin?.name || "",
    },
    hospitalClinic: {
      required: fields.hospitalClinic.required,
      id: continuousUse?.hospitalClinic?.id || "",
      referenceId: continuousUse?.hospitalClinic?.referenceId || "",
      name: continuousUse?.hospitalClinic?.name || "",
      cnpj: continuousUse?.hospitalClinic?.hospitalCnpj || "",
    },
    pathology: {
      required: fields.pathology.required,
      id: continuousUse?.pathology?.id || "",
      referenceId: continuousUse?.pathology?.referenceId || "",
      name: continuousUse?.pathology?.name || "",
    },
    nextDateRequired: fields.nextDate.required,
    nextDate: continuousUse?.nextDate ? new Date(continuousUse.nextDate) : "",
    metricUnityRequired: fields.metricUnity.required,
    metricUnity: continuousUse?.metricUnity || "",
    quantityRequired: fields.quantity.required,
    quantity: continuousUse?.quantity || "",
    periodRequired: fields.period.required,
    period: continuousUse?.period || "",
    remarkRequired: fields.remark.required,
    remark: continuousUse?.remark || "",
  };

  const getDataCreateNew = (registration) => {
    const records = {
      newPrescriber: {
        title: "Cadastro de prescritor",
        newRecord: "newPrescriber",
      },
      newPrescriberSpecialty: {
        title: "Cadastro de especialidade",
        newRecord: "newPrescriberSpecialty",
      },
      newPrescriptionOrigin: {
        title: "Cadastro de origem",
        newRecord: "newPrescriptionOrigin",
      },
      newHospitalClinic: {
        title: "Cadastro de hospital ou clinica",
        newRecord: "newHospitalClinic",
      },
      newPathology: {
        title: "Cadastro de patologia",
        newRecord: "newPathology",
      },
    };

    return records[registration];
  };

  const handleCreateNew = (registration) => {
    setNewRecord(getDataCreateNew(registration));
    modalRef.current.open();
  };

  const closeModal = () => {
    setNewRecord("");
    modalRef.current.close();
  };

  const checkShowSection = {
    prescriberSection:
      fields.prescriber.show || fields.prescriberSpecialty.show,
    originSection: fields.prescriptionOrigin.show || fields.hospitalClinic.show,
    treatmentSection:
      fields.pathology.show ||
      fields.nextDate.show ||
      fields.metricUnity.show ||
      fields.quantity.show ||
      fields.period.show ||
      fields.remark.show,
  };

  return (
    <Card
      autoScroll={false}
      title={title}
      id="cardContinuousUse"
      closeEnable
      handleClose={handleClose}
    >
      <Modal
        title={newRecord ? newRecord.title : ""}
        ref={modalRef}
        closable={true}
        opened={false}
        style={{ width: "50%" }}
      >
        <Modal.Body style={{ padding: "16px 0 0 0" }}>
          <FormModal
            registration={newRecord.newRecord || ""}
            closeModal={closeModal}
            handleSubmit={handleSubmitRecord}
          />
        </Modal.Body>
      </Modal>

      <ContinuousUseSection>
        <GroupContinuousUseItems>
          <ContinuousUseItems flex={1}>
            <span>Descrição do produto</span>
            <span className="continuous-use-items-content">
              {product?.productName}
            </span>
          </ContinuousUseItems>
          <ContinuousUseItems flex={0.5}>
            <span>Fabricante</span>
            <span className="continuous-use-items-content">
              {product?.productSupplier}
            </span>
          </ContinuousUseItems>
          {anyContinuousUseFieldRequired && (
            <ContinuousUseItems flex={0.5}>
              <span className="continuous-use-items-required-fields">
                {requiredFieldsMessage}
              </span>
            </ContinuousUseItems>
          )}
        </GroupContinuousUseItems>
      </ContinuousUseSection>

      <ContinuousUseFormWrapper>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <KeyboardNavigationProvider>
                {checkShowSection.prescriberSection && (
                  <ContinuousUseSection margin="24px 0 0 0">
                    <TitleSection>
                      <strong>Informações do prescritor</strong>
                      <div />
                    </TitleSection>
                    {fields.prescriber.show && (
                      <PrescriberContinuousUse
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCreateNew={handleCreateNew}
                        autocompleteQueryFn={autocompleteQueryFn}
                        renderItem={renderItem}
                        errors={errors}
                        touched={touched}
                        manage={manage}
                      />
                    )}
                    {fields.prescriberSpecialty.show && (
                      <PrescriberSpecialty
                        data={prescriberspecialtyList}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCreateNew={handleCreateNew}
                        errors={errors}
                        touched={touched}
                        manage={manage}
                      />
                    )}
                  </ContinuousUseSection>
                )}

                {checkShowSection.originSection && (
                  <ContinuousUseSection margin="24px 0 0 0">
                    <TitleSection>
                      <strong>Informações de origem</strong>
                      <div />
                    </TitleSection>
                    {fields.prescriptionOrigin.show && (
                      <PrescriptionOrigin
                        data={prescriptionOriginList}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCreateNew={handleCreateNew}
                        errors={errors}
                        touched={touched}
                        manage={manage}
                      />
                    )}
                    {fields.hospitalClinic.show && (
                      <HospitalClinic
                        data={hospitalClinicList}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCreateNew={handleCreateNew}
                        errors={errors}
                        touched={touched}
                        manage={manage}
                      />
                    )}
                  </ContinuousUseSection>
                )}

                {checkShowSection.treatmentSection && (
                  <ContinuousUseSection margin="24px 0 0 0">
                    <TitleSection>
                      <strong>Informações de tratamento</strong>
                      <div />
                    </TitleSection>
                    {fields.pathology.show && (
                      <Pathology
                        data={pathologyList}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleCreateNew={handleCreateNew}
                        errors={errors}
                        touched={touched}
                        manage={manage}
                      />
                    )}
                    <GroupContinuousUseItems>
                      {fields.nextDate.show && (
                        <ContinuousUseItems
                          flex={1}
                          required={values.nextDateRequired}
                          hasError={errors.nextDate && touched.nextDate}
                        >
                          <FormControl
                            label="Data próxima compra"
                            id="nextDate"
                            name="nextDate"
                          >
                            <Field name="nextDate">
                              {() => (
                                <DatePicker
                                  className="continuous-use-items-input"
                                  onChange={(value) =>
                                    setFieldValue("nextDate", new Date(value))
                                  }
                                  onChangeRaw={(e) =>
                                    handleTypedDateChange(
                                      e.target.value,
                                      "nextDate",
                                      setFieldValue
                                    )
                                  }
                                  selected={values.nextDate || ""}
                                  minDate={new Date()}
                                  placeholderText="Data próxima compra"
                                />
                              )}
                            </Field>
                          </FormControl>
                        </ContinuousUseItems>
                      )}
                      {fields.metricUnity.show && (
                        <ContinuousUseItems
                          flex={1}
                          required={values.metricUnityRequired}
                          hasError={errors.metricUnity && touched.metricUnity}
                        >
                          <FormControl
                            className="continuous-use-items-input"
                            id="metricUnity"
                            name="metricUnity"
                            label="Unidade medida"
                            type="select"
                            options={metricUnitySelect}
                            placeholder="Selecione"
                          />
                        </ContinuousUseItems>
                      )}
                      {fields.quantity.show && (
                        <ContinuousUseItems
                          flex={1}
                          required={values.quantityRequired}
                          hasError={errors.quantity && touched.quantity}
                        >
                          <FormControl
                            className="continuous-use-items-input"
                            id="quantity"
                            name="quantity"
                            label="Quantidade"
                            type="select"
                            options={quantitySelect}
                            placeholder="Quantidade"
                          />
                        </ContinuousUseItems>
                      )}
                      {fields.period.show && (
                        <ContinuousUseItems
                          flex={1}
                          required={values.periodRequired}
                          hasError={errors.period && touched.period}
                        >
                          <FormControl
                            className="continuous-use-items-input"
                            id="period"
                            name="period"
                            label="Período"
                            type="select"
                            options={periodSelect}
                            placeholder="Selecione"
                          />
                        </ContinuousUseItems>
                      )}
                    </GroupContinuousUseItems>

                    {fields.remark.show && (
                      <GroupContinuousUseItems>
                        <ContinuousUseItems
                          flex={1}
                          required={values.remarkRequired}
                          hasError={errors.remark && touched.remark}
                        >
                          <FormControl
                            className="continuous-use-items-input"
                            id="remark"
                            name="remark"
                            label="Observações próxima compra"
                            maxLength="140"
                            lengthCounter={values.remark.length}
                          />
                        </ContinuousUseItems>
                      </GroupContinuousUseItems>
                    )}
                  </ContinuousUseSection>
                )}

                <ContinuousUseSection margin="24px 0 0 0">
                  <GroupContinuousUseItems>
                    {fields.allowSelectEmpty && (
                      <ContinuousUseItems>
                        <Button
                          className="btn-outline"
                          onClick={handleNotInformContinuousUse}
                        >
                          NÃO INFORMAR USO CONTÍNUO
                        </Button>
                      </ContinuousUseItems>
                    )}
                    <ContinuousUseItems width="10%">
                      <Button className="btn-purple" type="submit">
                        SALVAR
                      </Button>
                    </ContinuousUseItems>
                  </GroupContinuousUseItems>
                </ContinuousUseSection>
              </KeyboardNavigationProvider>
            </Form>
          )}
        </Formik>
      </ContinuousUseFormWrapper>
    </Card>
  );
};

CardContinuousUse.propTypes = {
  title: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmitRecord: PropTypes.func.isRequired,
  handleNotInformContinuousUse: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  autocompleteQueryFn: PropTypes.func.isRequired,
  renderItem: PropTypes.elementType.isRequired,
};

export default CardContinuousUse;
