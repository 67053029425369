import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { CardWrapper, CardTitle, CardFooter } from './style';
import store from '../../../redux/store';
import Col from 'react-bootstrap/Col';

export class Card extends React.Component {
  actions;

  constructor(props) {
    super(props);

    this.renderKeyBordEvent = this.renderKeyBordEvent.bind(this);

    this.actions = [
      {
        label: 'ATUALIZAR',
        icon: 'autorenew',
        propToShow: 'refreshEnable',
        action: 'handleRefreshClick',
      },
      {
        label: 'VOLTAR(ESC)',
        icon: 'arrow_back',
        propToShow: 'closeEnable',
        rootKey: 'esc',
        action: 'handleClose',
      },
      {
        label: 'FECHAR(ESC)',
        icon: 'close',
        propToShow: 'alternativeCloseEnable',
        rootKey: 'esc',
        action: 'handleClose',
      },
      {
        label: 'CANCELAR(ESC)',
        icon: 'close',
        propToShow: 'alternativeCloseCancel',
        rootKey: 'esc',
        action: 'handleClose',
      },
    ];
  }

  componentDidMount() {
    store.dispatch({ type: 'CARD_OPENED', title: this.props.title });
  }

  componentWillUnmount() {
    store.dispatch({ type: 'CARD_CLOSED', title: this.props.title });
  }

  renderKeyBordEvent(action) {
    if (action.rootKey === 'esc' && this.props.escAction) {
      return (
        <KeyboardEventHandler
          handleKeys={[action.rootKey]}
          handleFocusableElements
          onKeyEvent={this.props.escAction}
        />
      );
    } else {
      return (
        <KeyboardEventHandler
          handleKeys={[action.rootKey]}
          handleFocusableElements
          onKeyEvent={this.props[action.action]}
        />
      );
    }
  }

  render() {
    const selectedActions = this.actions.filter(
      (action) => this.props[action.propToShow]
    );
    let classesContet = ['card-body', this.props.className];

    if (this.props.autoScroll) {
      classesContet.push('scroll');
    }

    classesContet = classesContet.join(' ');

    return (
      <CardWrapper
        id={this.props.id}
        shouldFlex={this.props.shouldFlex}
        autoScroll={this.props.autoScroll}
        className={this.props.className || ''}
      >
        {this.props.title && (
          <CardTitle
            hasIcon={!!this.props.urlIcone}
            marginButtonTitle={this.props.marginButtonTitle}
          >
            {this.props.urlIcone && <img src={this.props.urlIcone} alt='' />}

            {this.props.orientationVertical && (
              <div className='container-title'>
                <h1 className='title'>{this.props.title}</h1>
                <span className='sub-title'>{this.props.subTitle}</span>
              </div>
            )}

            {!this.props.orientationVertical && (
              <h1 className='title'>
                {this.props.title}
                <span className='sub-title'>{this.props.subTitle}</span>
              </h1>
            )}

            {selectedActions.map((action) => (
              <Fragment key={action.label}>
                {action.rootKey && this.renderKeyBordEvent(action)}
                <button
                  id='btn-close'
                  data-testid='btn-close'
                  onClick={this.props[action.action]}
                >
                  <strong>
                    <i className='material-icons'>{action.icon}</i>
                  </strong>
                  <p>{action.label}</p>
                </button>
              </Fragment>
            ))}
          </CardTitle>
        )}
        <Col
          className='card-body flex-column d-flex overflow'
          style={this.props.style}
        >
          {this.props.children}
        </Col>
        {this.props.footer && <CardFooter>
          {this.props.footer}
        </CardFooter>
        }
      </CardWrapper>
    );
  }
}
Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  id: PropTypes.string,
  urlIcone: PropTypes.string,
  handleClick: PropTypes.func, // use by reflection
  closeEnable: PropTypes.bool, // use by reflection
  refreshEnable: PropTypes.bool, // use by reflection
  handleRefreshClick: PropTypes.func, // use by reflection
  className: PropTypes.string,
  escAction: PropTypes.func,
  autoScroll: PropTypes.bool,
  shouldFlex: PropTypes.bool,
  orientationVertical: PropTypes.bool,
  marginButtonTitle: PropTypes.string,
  footer: PropTypes.any
};

Card.defaultProps = {
  children: null,
  urlIcone: null,
  title: null,
  subTitle: null,
  id: null,
  handleClick: null,
  closeEnable: false,
  refreshEnable: null,
  handleRefreshClick: null,
  className: null,
  escAction: null,
  autoScroll: true,
  shouldFlex: false,
  orientationVertical: false,
  marginButtonTitle: null,
};

export default Card;