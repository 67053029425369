import React from "react";
import PropTypes from "prop-types";
import { MaskCNPJ } from "../../../utils/modules";
import FormControl from "../../@form-control";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  GroupContinuousUseItems,
} from "../style";

export const initialValuesNewHospitalClinic = {
  newHospitalClinic: {
    name: "",
    cnpj: "",
    emptyCnpj: true,
  },
};

export const NewHospitalClinic = ({ setFieldValue, errors, touched }) => {
  const handleChangeCnpj = (value) => {
    setFieldValue("newHospitalClinic.cnpj", MaskCNPJ(value));
    setFieldValue("newHospitalClinic.emptyCnpj", false);

    if (value === "") setFieldValue("newHospitalClinic.emptyCnpj", true);
  };

  return (
    <ContinuousUseSection>
      <GroupContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newHospitalClinic?.name && touched.newHospitalClinic?.name
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newHospitalClinic"
            name="newHospitalClinic.name"
            label="Nome *"
            placeholder="Informe o nome"
            autoFocus
          />
        </ContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newHospitalClinic?.cnpj && touched.newHospitalClinic?.cnpj
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newHospitalClinicCnpj"
            name="newHospitalClinic.cnpj"
            label="CNPJ"
            onChange={(e) => handleChangeCnpj(e.target.value)}
            data-select-submit-button="Tab"
            data-submit-with-enter={true}
          />
        </ContinuousUseItems>
      </GroupContinuousUseItems>
    </ContinuousUseSection>
  );
};

NewHospitalClinic.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};
