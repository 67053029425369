import * as Yup from 'yup';
import moment from 'moment';

const schema = Yup.mixed().test({ 
  name: 'date', 
  exclusive: false, 
  message: 'Data informada inválida.', 
  test: (value) => {
    if (value === "InvalidDate") {
      return false;
    } 
    return moment(value).isValid() || value === null
  }  
});

const validation = Yup.object().shape({
  startDate: schema,
  endDate: schema.test('endDateMin', 'A data inicial maior que a data final.', function (value) {
    const startDate = this.options.parent.startDate;
    const d = moment(startDate);
    return !moment(value).isBefore(d);
  })
})

export default validation;