import Configuration from '../configuration';
import HttpAccess from './httpAccess';
import handlePost from '../shared/utils/handlePost';
import { AbstractService } from './abstractService';
import Store from '../redux/store/index';

export const redoLastRequest = (
  lastSentBody,
  lastCalledEndpoint,
  httpAccess = HttpAccess,
  configuration = Configuration,
) => {
  const url = `${Store.getState().pdvContingency.isOnline && configuration.endPointContingency ? configuration.endPointContingency : configuration.endPoint}${lastCalledEndpoint}`;
  return handlePost(url, lastSentBody, httpAccess);
};

class AuthorizationService extends AbstractService {
  constructor() {
    super('/Authorization');
  }

  Authorize = (credential) => this.invokeAction('/creditsale', credential);
  AuthorizeValidation = (credential) => this.invokeAction('/authorizeValidation', credential);
  Back = () => this.invokeAction('/back');
}
export default AuthorizationService;
