import React, { Component } from 'react';
import Modal from 'react-modal';
import { ListBox } from '../modules';
import { OptionListWrapper, OptionsWrapper } from './style';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';
import getShortcut, { PBM_LIST } from '../../../keyboardShortcuts.js';

const optionsStyle = {
  content: {
    maxWidth: '660px',
    height: 'min-content',
    padding: '8px',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    display: 'block',
    padding: '1.2rem',
  },
};

const pbmModalStyle = {
  content: {
    maxWidth: '300px',
    height: 'min-content',
    padding: '8px',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    display: 'block',
    padding: '1.2rem',
  },
};

class OptionsList extends Component {
  constructor(props) {
    super(props);

    this.state = { openOptions: false, openPbms: false };

    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    this.optionsModalRef.node.addEventListener(
      'keydown',
      this.escFunction,
      false
    );
    this.pbmsModalRef.node.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    this.optionsModalRef.node.removeEventListener(
      'keydown',
      this.escFunction,
      false
    );
    this.pbmsModalRef.node.removeEventListener(
      'keydown',
      this.escFunction,
      false
    );
  }

  escFunction(event) {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.onCloseModal();
      this.props.handleModalClosed();
    }
  }

  onOpenOptionsModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.hideMenuItemsPayment(
      document.getElementsByTagName('section').length > 0
    );
    this.setState({ openOptions: true });
    this.props.handleModalOpened();
  };

  onOpenPbmsModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.hideMenuItemsPayment(
      document.getElementsByTagName('section').length > 0
    );
    this.setState({ openPbms: true });
    this.props.handleModalOpened();
  };

  onCloseModal = () => {
    this.setState({
      openOptions: false,
      openPbms: false,
    });
    this.props.handleModalClosed();
  };

  handlerGetCommand = (param) => {
    this.props.handleClick(param);
    this.onCloseModal();
  };

  render() {
    const { optionList, shortKey, pbmOptions } = this.props;
    const { openOptions, openPbms } = this.state;

    return (
      <OptionsWrapper>
        <div
          id="footer-more-options"
          className="option-text"
          onClick={this.onOpenOptionsModal}
        >
          {shortKey && (
            <ShortcutHint
              shortcut={shortKey}
              activateShortcut={this.onOpenOptionsModal}
            />
          )}
          <i className="material-icons">menu</i>
          Outras Opções
        </div>
        {pbmOptions && pbmOptions.length > 0 && (
          <div
            id="footer-pbm-options"
            className="option-text"
            onClick={this.onOpenPbmsModal}
          >
            <ShortcutHint
              shortcut={getShortcut(PBM_LIST)}
              activateShortcut={this.onOpenPbmsModal}
            />
            <i className="material-icons-outlined">local_offer</i>
            Pbm's
          </div>
        )}

        <Modal
          ref={(ref) => (this.optionsModalRef = ref)}
          isOpen={openOptions}
          onClose={this.onCloseModal}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          center
          style={optionsStyle}
          ariaHideApp={false}
        >
          <OptionListWrapper>
            <a className="esc-option" href="#/" onClick={this.onCloseModal}>
              {' '}
              VOLTAR (ESC){' '}
            </a>
            <span className="modal-title">OUTRAS OPÇÕES</span>
            <div className="overflowAuto">
              <ListBox
                items={optionList}
                handleClick={this.handlerGetCommand}
                tabIndex="0"
              />
            </div>
          </OptionListWrapper>
        </Modal>

        <Modal
          ref={(ref) => (this.pbmsModalRef = ref)}
          isOpen={openPbms}
          onClose={this.onCloseModal}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          center
          style={pbmModalStyle}
          ariaHideApp={false}
        >
          <OptionListWrapper>
            <a className="esc-option" href="#/" onClick={this.onCloseModal}>
              {' '}
              VOLTAR (ESC){' '}
            </a>
            <span className="modal-title">Pbm's DISPONÍVEIS</span>
            <div className="overflowAuto">
              <ListBox
                items={pbmOptions}
                handleClick={this.handlerGetCommand}
                tabIndex="0"
              />
            </div>
          </OptionListWrapper>
        </Modal>
      </OptionsWrapper>
    );
  }
}

export default OptionsList;
