import React from 'react';
import PropTypes from 'prop-types';
import ListBoxItem from '../listbox-item';

const DIRECTION_UP = 'UP';
const DIRECTON_DOWN = 'DOWN';

export class ListBox extends React.Component {
  constructor(props) {
    super(props);

    this.defaultStyle = {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      padding: '0',
      gridGap: '10px',
      margin: '0',
      paddingBottom: '8px',
    };
  }

  componentDidMount() {
    const firstOption = document.querySelector('#listOptions').firstChild;
    if (firstOption) firstOption.focus();
  }

  handleKeyDown = (e) => {
    const keycode = e.keyCode || e.which;
    if (keycode === 13) {
      e.preventDefault();
      e.target.click();
      return;
    }

    switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault();
        if (e.target.previousSibling) e.target.previousSibling.focus();
        break;
      case 'ArrowRight':
        e.preventDefault();
        if (e.target.nextSibling) e.target.nextSibling.focus();
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.focusUpDown(e.target, DIRECTION_UP);
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.focusUpDown(e.target, DIRECTON_DOWN);
        break;
      default:
        break;
    }
  };

  focusUpDown = (target, direction) => {
    const items = Array.from(target.parentElement.children);
    const left = target.offsetLeft;
    const top = target.offsetTop;

    if (direction === DIRECTION_UP) items.reverse();

    const index = items.findIndex((item) => {
      if (direction === DIRECTION_UP)
        return item.offsetTop < top && item.offsetLeft === left;

      return item.offsetTop > top && item.offsetLeft === left;
    });

    const item = items[index];

    if (item) item.focus();
  };

  render() {
    const { items, handleClick } = this.props;

    return (
      <ul id="listOptions" style={this.defaultStyle}>
        {items &&
          items.map(
            (item, index) =>
              !item.isHidden && (
                <ListBoxItem
                  id={item.operationName}
                  shortKey={item.shortKey}
                  handleClick={handleClick}
                  key={index}
                  imagePath={item.icoPath}
                  label={item.operationName}
                  handleKeyDown={this.handleKeyDown}
                  enabled={item.enabled}
                />
              )
          )}
      </ul>
    );
  }
}

ListBox.propTypes = {
  handleClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ListBox;
