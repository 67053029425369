import React from 'react';
import PropTypes from 'prop-types';

export const getContext = (Component) => {
  class GetContext extends React.Component {
      render() {
      return <Component {...this.props} {...this.context} />;
    }
  }

  Component.contextTypes = {
    showMessage: PropTypes.func,
    showError: PropTypes.func,
  };

  return GetContext;
};
