import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './more-options.scss';

class MoreOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreOptionsToggled: false
    }

    this.optionsRef = null;

    this.onClickEvent = this.onClickEvent.bind(this);

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickEvent);
  }

  onClickEvent(e) {
    this.state.moreOptionsToggled && e.target !== this.optionsRef && this.setState({ moreOptionsToggled: false });
  }

  toggleMoreOptions = () => {
    this.setState({
      moreOptionsToggled: !this.state.moreOptionsToggled
    });
  }

  componentDidUpdate() {
    if (!this.state.moreOptionsToggled || !this.contentRef.current) return;

    const bounding = this.contentRef.current.getBoundingClientRect();
    const outOfScreenX = bounding.x + bounding.width - window.innerWidth;

    const tX = outOfScreenX > 0 ? outOfScreenX + 10 : 0;

    const parentBounding = this.contentRef.current.parentNode.getBoundingClientRect();
    var tY = parentBounding.y - bounding.y + parentBounding.height;

    const outOfScreenY = bounding.y + tY + bounding.height - window.innerHeight;
    if (outOfScreenY > 0) {
      tY = parentBounding.y - bounding.y - bounding.height;
    }

    this.contentRef.current.style.transform = `translate3d(-${tX}px, ${tY}px, 0)`;
  }

  render() {
    const {
      options,
      data
    } = this.props;
    return (
      <Fragment>
        <li className="more-options">
          <i ref={ref => this.optionsRef = ref} className="material-icons" onClick={this.toggleMoreOptions}>more_vert</i>
          {this.state.moreOptionsToggled && (
            <div className="dropdown-content" ref={this.contentRef}>
              <span >Ações</span>
              {
                options.map(o =>
                  <span key={`option-${o.name}`}
                    className={(o.isEnabledFn && !o.isEnabledFn(data)) ? 'disabled' : ''}
                    onClick={() => (!o.isEnabledFn || o.isEnabledFn(data)) && o.onClick(data)}>{o.name}</span>
                )}
            </div>
          )}
        </li>
      </Fragment>
    )
  }
}

MoreOptions.propTypes = {
  data: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
      isEnabledFn: PropTypes.func
    })
  ),
};

MoreOptions.defaultProps = {
  options: []
}

export default MoreOptions;