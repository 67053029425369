import styled from 'styled-components';

export const TableWrapper = styled.div`
flex: 1 1;
overflow: auto;
&.scrollable {
    overflow: auto;
}

.table {
    width: 100%;
    border-spacing:0; 
    border-collapse: collapse;

    &.hover tr{
        &:hover {
            cursor:pointer;
            color:black;
            font-weight: bold;
            background-color: #FFB200;
        }
    }

    tr {
        td {
            border-bottom: 1px solid #eee;
        }
        outline: none;
    }
    thead {
        tr {
            padding-bottom: 10px;
            th {
                text-transform: uppercase;
                font-weight: bold;
                color: black;
                padding-bottom: 10px;
                font-size: 12px;
            }
        }
    }

    tbody {
        tr {
            &:focus {       
                color:black;
                font-weight: bold;
                background-color: #FFB200;        
            }
            &:last-child {           
                border-bottom: 1px solid #eee;
            }
            td {
                height: 26px;
                word-wrap: break-word;
                vertical-align: top;

                img {
                    border-radius: 50%;
                    height: 56px;
                    width: 56px;
                }
            }
            &.table__tr--link:hover td {
                cursor: pointer;

                &:first-child {
                border-radius: 5px 0 0 5px;
                }

                &:last-child {
                border-radius: 0 5px 5px 0;         
                }
            }
            &.is-actived{
                color:black;
                font-weight: bold;
                background-color: #5B2E90; 
                color: white;
            }
        }
    }
}

.collapsable-cell {
    display: block;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
}

.collapsable-value {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 29px;
    height: 30px;
}

.expand {
    right: 0;
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: top;
    margin-top: 3px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
`;

export default TableWrapper;
