import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 5px 8px 0;
  position: relative;
  margin-left: 5px;

  button {
    padding: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  &.active {
    background: white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
`;
