const theme2 = {
  palette: {
    primary: '#5b2e90',
    gray: '#999',
    lightGray: '#eee',
    grayMedium: '#9E9E9E',
    grayDark: '#60656C',

    border: '#d3d1d1',
    buttonBorder: '#c4c4c4',
    link: '#4482FF',
    warning: '#FEDEDE',
    warningBorder: '#D9D6D6',
    tableBorder: '#dee2e6',

    tableBgOdd: '#F2F2F2',

    brandLight: '#6E4B87',
    brandLinx: '#672A94',
    brandFarma: '#FFA400',

    accent2: '#ff9200',
    hover: '#f1f3f6',
    danger: '#F35958',
  },

  metrics: {
    borderRadius: 4,
    baseMargin: 8,
    basePadding: 8,

    sidebarWidth: 48,
  },
};

export default theme2;
