function handleGetWithoutLoading(url, httpAccess) {
  return httpAccess
    .getWithCors(url)
    .then(
      resp => {
        if (resp) {
          const jsonResponse = JSON.parse(resp);
          if (jsonResponse.status > 399) {
            throw jsonResponse;
          }
          return jsonResponse;
        }
      },
      err => {
        throw err;
      }
    )
    .catch(err => {
      throw err;
    });
}

export default handleGetWithoutLoading;
