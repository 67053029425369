import React from 'react';

const platform = {
  name: 'Platform',
};

const farma = {
  logo: 'https://stpublicfrontend.blob.core.windows.net/images/logolinx.png',
  name: 'Farma Cloud',
  toGoOption: false,
  promotion: './assets/images/banner@2x.png',
};

/* TODO: better naming */
export const envs = {
  farma,
};

export const PlatformContext = React.createContext(platform);
