import styled from 'styled-components';

export const ModalWrapper = styled.div`
  font-family: Dosis;
  display: flex;
  flex-direction: column;
  height: 100%;

  .esc-option {
      text-decoration: none;
      align-self: flex-end;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 19px;
    text-transform: uppercase;
    padding: 12px;
    align-self: center;
  }

  .modal-footer {
      display: flex;
      flex: 0 0 50px;
      padding: 10px 0px 0px 0px;
      position: relative;
      booton: 0px;
      align-items: center;
      justify-content: flex-end;
  }
`;