import styled from 'styled-components';

/* The switch - the box around the slider */
export const SlideToggleWrapper = styled.label `
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  
  
  /* Hide default HTML checkbox */
  input {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;

    ${props => props.disabled ? 
      `
      background-color: #EBEBE4;
      color: #000000;
      `
        : 
      `background-color: #ccc !important;`
    }
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #5b2e90 !important;;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 6px #5b2e90;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
`;
