import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../modules';
import ListParcelCard from '../list-parcel-card';

const PaymentBusinessAgreementParcel = (props) => {
  const {
    items,
    onHandleBusinessAgreement,
    handleClose,
  } = props;

  return (
    <Card
      title="CONVÊNIO"
      subTitle="Escolha as parcelas"
      handleClose={handleClose}
      urlIcone="./assets/images/payment-types/businessAgreement.svg"
      closeEnable
    >
      <ListParcelCard
        items={items}
        handle={onHandleBusinessAgreement}
      />
    </Card>
  );
};

PaymentBusinessAgreementParcel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  onHandleBusinessAgreement: PropTypes.func,
  handleClose: PropTypes.func,
};

PaymentBusinessAgreementParcel.defaultProps = {
  items: [],
  onHandleBusinessAgreement: null,
  handleClose: null,
};

export default PaymentBusinessAgreementParcel;
