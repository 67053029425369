/* eslint-disable react/prefer-stateless-function, default-case */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRESCRIPTION_ANTIMICROBIAL } from '../../shared/components/sngpc/types.js';
import {
  closeAllCards,
  SNGPC,
  shouldShow,
  showCard,
  getPayload,
} from '../../redux/actions/routerAction';
import { ControlledItemsService } from '../../services/controlledItemsService';
import { LotService } from '../../services/lotService';
import { Sngpc } from '../../shared/components/sngpc';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { ItemRender } from './style';
import CatalogService from '../../services/catalogService.js';
import { showToast } from '../../shared/utils/util.js';

export class SngpcContainer extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    showSngpc: PropTypes.func.isRequired,
    hideSngpc: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPrescriptionIndex: 0,
      controlledItems: null,
      newPrescriber: {},
    };

    this.modalRef = React.createRef();

    this.service = new ControlledItemsService();
    this.lotService = new LotService();
    this.catalogService = new CatalogService();

    this.props.MessageBus.Subscribe(
      'pos.controlledItem.ControlledItemListed',
      this.showReturned
    );
    this.props.MessageBus.Subscribe(
      'pos.controlledItem.back',
      this.props.hideSngpc
    );
    this.props.MessageBus.Subscribe(
      'pos.controlledItem.DoneControlledItems',
      this.doneControlledItem
    );
    this.props.MessageBus.Subscribe(
      'pos.controlledItem.ControlledItemFailed',
      this.handleShowError
    );
    this.props.MessageBus.Subscribe(
      'pos.controlledItem.DoctorsListed',
      this.handleDoctorsReturned
    );
    this.props.MessageBus.Subscribe(
      'pos.catalog.prescriberbysngpc.manage.success',
      this.handleManagePrescriber
    );
    this.props.MessageBus.Subscribe(
      'pos.catalog.prescriberbysngpc.error',
      this.handleShowError
    );

    this.shouldValidateOnUpdate = false;
    this.autocompleteResolve = null;
    this.lastQuery = null;
    this.sngpcAutocompleteId = 'sngpc-prescriber-autocomplete';
  }

  handleDoctorsReturned = (ch, payload) => {
    this.autocompleteResolve &&
      this.autocompleteResolve({ items: payload.pagedResult.resultList });
    this.autocompleteResolve = null;
  };

  doneControlledItem = () => {
    this.lotService.startLotIdentification(
      this.state.controlledItems && this.state.controlledItems.product.referenceId,
      this.state.controlledItems && this.state.controlledItems.product.sequentialId
    );
  };

  handleShowError = (ch, payload) => {
    this.context.showMessage(payload && (payload.reason || payload.message));
  };

  showReturned = (ch, payload) => {
    this.setState({ controlledItems: null });
    const {
      controlledItems,
      options,
      productPrescriptionType,
    } = payload;

    const buyer = controlledItems.buyer || {};

    const prescriptions = controlledItems.prescriptions || [
      {
        sequence: 1,
        products: options.controlledProducts,
        patient: this.getPatient(payload),
        prescriber: {},
      },
    ];

    const [product] = options.controlledProducts;

    const data = {
      buyer,
      prescriptions,
      product,
      productPrescriptionType,
    };

    payload.controlledItems = data;

    payload.options.receiptTypes = payload.options.receiptTypes.filter(function (o) {
      return product.antibiotic ?
        o.value === PRESCRIPTION_ANTIMICROBIAL :
        o.value !== PRESCRIPTION_ANTIMICROBIAL;
    });

    this.setState({ controlledItems: payload.controlledItems });
    this.props.showSngpc(payload);
  };

  newPrescription = (controlledItems, copyCurrentPrescription) => {
    const { prescriptions } = controlledItems;
    const { currentPrescriptionIndex } = this.state;

    if (copyCurrentPrescription) {
      prescriptions.push({
        ...prescriptions[currentPrescriptionIndex],
        sequence: prescriptions.sequence,
      });
    } else {
      const prescription = this.createPrescrition(prescriptions);
      prescriptions.push(prescription);
    }

    this.setState({
      currentPrescriptionIndex: prescriptions.length - 1,
      controlledItems,
    });
  };

  createPrescrition = (prescriptions = []) => ({
    sequence: prescriptions.length + 1,
    patient: this.getPatient(this.props.payload),
    prescriber: {},
    products: this.createCopyProducts(),
  });

  removePrescription = () => {
    const { controlledItems, currentPrescriptionIndex } = this.state;
    const qtdPrescriptions = controlledItems.prescriptions.length;

    if (qtdPrescriptions === 1) {
      return;
    }

    const prescriptions = controlledItems.prescriptions.filter(
      (item, index) => index !== currentPrescriptionIndex
    );

    const newPrescriptionIndex =
      currentPrescriptionIndex >= qtdPrescriptions - 1
        ? qtdPrescriptions - 2
        : currentPrescriptionIndex;

    this.setState({
      currentPrescriptionIndex: newPrescriptionIndex,
      controlledItems: { ...controlledItems, prescriptions },
    });

    this.forceUpdate();
  };

  getPatient = (payload) => {
    const name = (payload.customer && payload.customer.name) || '';
    const genre = (payload.customer && payload.customer.genre) || '';
    const dateOfBirth = (payload.customer && payload.customer.dateOfBirth) || '';
    return { name, genre, dateOfBirth };
  };

  createCopyProducts = () => {
    const products = this.props.payload.options.controlledProducts;

    if (!products) return null;

    const copyProducts = [];

    products.forEach((item) => {
      const product = {};

      Object.keys(item).forEach((key) => {
        product[key] = item[key];
      });

      copyProducts.push(product);
    });

    return copyProducts;
  };

  nextPrescription = (controlledItems) => {
    this.setState({ controlledItems });
    this.navigate(1);
  };

  prevPrescription = (controlledItems) => {
    this.setState({ controlledItems });
    this.navigate(-1);
  };

  navigate = (add) => {
    const { currentPrescriptionIndex, controlledItems } = this.state;

    const newIndex = currentPrescriptionIndex + add;

    if (newIndex < 0 || newIndex >= controlledItems.prescriptions.length)
      return;

    this.setState({
      currentPrescriptionIndex: newIndex,
    });
  };

  confirm = (controlledItems) => {
    this.setState({ controlledItems, newPrescriber: {} });

    controlledItems.prescriptions.map(p => p.prescriber.documentType = p.prescriber.documentType ? p.prescriber.documentType : 0)

    return this.service.addControledItem(controlledItems);
  };

  close = () => {
    this.setState({ newPrescriber: {} });
    this.service.cancelControlledItem();
  };

  autocompleteQueryFn = (value) => {
    this.lastQuery = value;
    this.service.getDoctors(value, 1, 10);
    return new Promise((resolve) => {
      this.autocompleteResolve = resolve;
    });
  };

  handleDoctorSelect = (value) => {
    const currentPrescriber = this.props.payload.controlledItems.prescriptions[
      this.state.currentPrescriptionIndex
    ].prescriber;
    currentPrescriber.name = (value && value.name) || '';
    currentPrescriber.documentType = (value && value.documentType) || '';
    currentPrescriber.documentTypeDescription =
      (value && value.documentTypeDescription) || '';
    currentPrescriber.documentNumber = (value && value.documentNumber) || '';
    currentPrescriber.documentUF = (value && value.documentUF) || '';
    currentPrescriber.referenceId = (value && value.referenceId) || '';
    currentPrescriber.documentSituation = value && value.documentSituation;
    if (currentPrescriber.name) {
      this.contentRef.focusOn('prescriptionType');
    }
    this.forceUpdate();
  };


  autocompleteOnKeyPress = (e) => {
    switch (e.key) {
      case 'Tab':
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'ArrowRight':
      case 'ArrowDown':
      case 'Shift':
    }
    const currentPrescriber = this.state.controlledItems.prescriptions[
      this.state.currentPrescriptionIndex
    ].prescriber;
    currentPrescriber.name = null;
    currentPrescriber.documentType = null;
    currentPrescriber.documentNumber = null;
    currentPrescriber.documentUF = null;
    currentPrescriber.referenceId = null;
    currentPrescriber.documentSituation = null;
    this.forceUpdate();
  };

  renderItem = (props) => {
    return (
      <ItemRender {...props}>
        <span className="left">
          <span className="info prescriber-name">{props.item.name}</span>
        </span>
        <span className="right">
          <span className="info prescriber-crm-type">{`${props.item.documentTypeDescription}:`}</span>
          <span className="info prescriber-crm">{props.item.documentNumber}</span>
          <span className="info prescriber-uf">{`UF: ${props.item.documentUF}`}</span>
        </span>
      </ItemRender>
    );
  };

  handleSubmitNewPrescriber = ({ newPrescriber }) => {
    const payload = {
      ...newPrescriber,
      status: true,
    };
    this.catalogService.addPrescriberBySngpc(payload);
  }

  handleManagePrescriber = (ch, payload) => {
    this.setState({
      newPrescriber: {
        name: payload.description,
        documentType: payload.documentType,
        documentTypeDescription: payload.documentDescription,
        documentNumber: payload.documentNumber,
        documentUF: payload.documentUF,
        referenceId: payload.referenceId,
      }
    });
    showToast({
      type: 0,
      title: `Cadastro de Prescritor`,
      html: `Cadastro de Prescritor realizado com sucesso!`,
      hideAfterMilliseconds: 5000,
    });
    this.modalRef.current.close();
  }

  render() {
    const { controlledItems } = this.state;
    return (
      this.props.show &&
      (
        controlledItems ? (
          <Sngpc
            ref={(ref) => {
              this.contentRef = ref;
            }}
            title={controlledItems.product.name}
            prevPrescription={this.prevPrescription}
            newPrescription={this.newPrescription}
            nextPrescription={this.nextPrescription}
            removePrescription={this.removePrescription}
            options={this.props.payload && this.props.payload.options}
            dispatchingAgencys={
              this.props.payload && this.props.payload.dispatchingAgencys
            }
            documentTypes={
              this.props.payload && this.props.payload.documentTypes
            }
            controlledItems={controlledItems}
            retailer={this.props.payload.retailer}
            customer={this.props.payload.customer}
            currentPrescriptionIndex={this.state.currentPrescriptionIndex}
            confirm={this.confirm}
            close={this.close}
            onSelectDoctor={this.handleDoctorSelect}
            autocompleteQueryFn={this.autocompleteQueryFn}
            sngpcAutocompleteId={this.sngpcAutocompleteId}
            autocompleteOnKeyPress={this.autocompleteOnKeyPress}
            renderItem={this.renderItem}
            modalRef={this.modalRef}
            handleSubmitNewPrescriber={this.handleSubmitNewPrescriber}
            newPrescriber={this.state.newPrescriber}
          />
        ) : (
          ''
        )
      )
    );
  }
}

const mapStateToProps = (state) => ({
  payload: getPayload(state, SNGPC),
  show: shouldShow(state, SNGPC),
});

const mapDispatchToProps = (dispatch) => ({
  showSngpc: (payload) => dispatch(showCard(SNGPC, payload)),
  hideSngpc: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(SngpcContainer)));
