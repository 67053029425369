/* eslint-disable eqeqeq, no-unused-vars, no-mixed-operators */

import React, { Fragment } from 'react';
import { InvoiceMonitor, RequireAuth, MessageBox } from '../../shared/components/modules';
import { OrderService } from '../../services/orderService';
import { PaymentService } from '../../services/paymentService';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { getPayload, MONITOR_NOTAS_LIST, shouldShow, closeCard } from '../../redux/actions/routerAction';
import moment from 'moment';
import { showToast, showInfoModal } from '../../shared/utils/util'
import FileSaver from 'file-saver';
import MonitorActions from './monitor-actions'

export class InvoiceMonitorContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      requireReason: false,
      orderId: null,
      tefTransaction: null,
      infoToShow: '',
      selectedItem: null,
      selectedIndex: -1,
    };
    
    this.DanfeType = {
      DanfeNFCe: 0,
      DanfeCFe: 1,
      DanfeNFCeCanc: 2,
      DanfeCFeCanc: 3
    };

    this.orderService = new OrderService();
    this.paymentService = new PaymentService();

    this.props.MessageBus.Subscribe('pos.dialog', this.handleDialog);
    this.props.MessageBus.Subscribe('pos.coupon.reauthorization', this.reauthorize);
    window.desktopApp && window.desktopApp.subscribe('pos.coupon.reauthorization', this.reauthorize);
    window.desktopApp && window.desktopApp.subscribe('pos.coupon.query.status', this.handleQueryStatus);
    window.desktopApp && window.desktopApp.subscribe('pos.coupon.query.sat.status', this.handleQuerySatStatus);
    this.props.MessageBus.Subscribe('pos.coupon.set.fiscal.document.success', this.showInfoMessage);
    window.desktopApp && window.desktopApp.subscribe('pos.monitor.validation.error', this.handleError);
    this.props.MessageBus.Subscribe('pos.order.cancellation.error', this.handleError);
    this.props.MessageBus.Subscribe('pos.monitor.validation.error', this.handleError);
    this.props.MessageBus.Subscribe('pos.coupon.query.success', this.handleOrderList);
    window.desktopApp && window.desktopApp.subscribe('pos.order.cancellation', this.handleOrderCancellationPayload);
    window.desktopApp && window.desktopApp.subscribe('pos.coupon.set.unsable', this.handleUnusablePayload);
    window.desktopApp && window.desktopApp.subscribe('machine.devices.coupon.print.completed', this.handlePrintConclude);
    window.desktopApp && window.desktopApp.subscribe('machine.devices.tef.reprintcoupon.completed', this.hideInfoModal);

    this.props.MessageBus.Subscribe('pos.coupon.reprint.tef', this.reprintTef);
    this.props.MessageBus.Subscribe('pos.order.cancelled', this.handleCancelOrderSuccess);

    this.form = {
      startDate: new Date(moment().year(), moment().month(), moment().date()),
      endDate: new Date(moment().year(), moment().month(), moment().date()),
      status: '',
      number: null,
      series: null,
      startValue: null,
      endValue: null
    }
  }
  handleShowChild = (index) => {
    var changes = this.state.data
    changes[index].showChild = !changes[index].showChild;
    changes[index].items = changes[index].items || [];
    this.setState({ data: changes });
  }
  handleSelectItem = (item, index) => {
    item = this.state.selectedIndex === index ? null : item;
    index = this.state.selectedIndex === index ? -1 : index;
    this.setState({ selectedItem: item, selectedIndex: index });
  }

  handleDialog = () => this.hideInfoModal();

  hideInfoModal = () => {
    showInfoModal({ message: null });
  }

  handleConsultar = (order) => {
    showInfoModal({ message: "Realizando a comunicação com o Mide" });
    this.setState({
      orderId: order.orderId
    });
    this.orderService.queryFiscalDocumentStatusOnMidE(order.orderId, this.props.tpAmb.toString());
  }

  handleQueryStatus = (ch, payload) => {
    const fiscalDocument = this.nfceResultToFiscalDocument(payload);
    var fiscalDocumentPayload = { orderId: this.state.orderId, fiscalDocument: fiscalDocument };
    this.orderService.setFiscalDocument(fiscalDocumentPayload);
    this.setState({
      infoToShow: this.getInfo(fiscalDocument)
    });
  }

  handleQuerySatStatus = (ch, payload) => {
    const { receiptNumber, receiptSeries } = this.state.selectedItem;
    
    const fileName = this.props.fiscalType === 2 ? 
      `Danfe[S${payload.nSerieSAT},N${payload.nCFe}].pdf` : // if SAT;
      `Danfe[S${receiptSeries},N${receiptNumber}].pdf`; // if NFC-e;
    
    this.orderService.decodeDanfe(payload.Danfe)
      .then((response) => {
        var file = response.arrayBuffer()
          .then(text => {
            var blob = new Blob([text], { type: "application/pdf;charset=iso-8859-1" });
            FileSaver.saveAs(blob, fileName, { autoBom: false });
          });
      });
  }

  reprintTef = (ch, payload) => {
    if (window.desktopApp) {
      showInfoModal({ message: "Enviando comando para a impressora" })
      window.desktopApp.publish('machine.devices.tef.reprintcoupon', payload);
    }
  }

  handleUnusable = (order) => {
    this.setState({ orderId: order.orderId });
    showInfoModal({ message: "Buscando informações para a inutilização" })
    this.orderService.setCouponUnusableOnMide(order.orderId);
  }

  handleUnusablePayload = (ch, payload) => {
    //recebe do desktopapp retorno da chamada de inutilizaçõa do coupon fiscal
    if (payload.cStat == "102") {
      showInfoModal({ message: "Salvando informações" });
      const fiscalDocument = this.nfceResultToFiscalDocument(payload);
      var fiscalDocumentPayload = { orderId: this.state.orderId, fiscalDocument: fiscalDocument };
      this.orderService.setFiscalDocument(fiscalDocumentPayload);

      this.setState({
        infoToShow: this.getInfo(fiscalDocument)
      });
    }
    else {
      showInfoModal({ message: payload.xMotivo, type: 99 })
    }
  }

  handleVisualize = (order) => {   
    this.orderService.queryStatusOnMidE(order.orderId, this.props.tpAmb);
  }

  handleReprintTef = (order) => {
    showInfoModal({ message: "Buscando informações para a reimpressão" })
    this.paymentService.newReprintTef(order.orderId);
  }

  handleReauthorize = (order) => {
    showInfoModal({ message: "Buscando informações de tributação" })
    this.setState({ orderId: order.orderId });
    this.orderService.reauthorizeCoupon(order.orderId, this.props.tpAmb);
  }

  reauthorize = (ch, payload) => {
    showInfoModal({ message: "Salvando informações" });
    const fiscalDocument = this.nfceResultToFiscalDocument(payload);
    var fiscalDocumentPayload = { orderId: this.state.orderId, fiscalDocument: fiscalDocument };
    this.orderService.setFiscalDocument(fiscalDocumentPayload);

    this.setState({
      infoToShow: this.getInfo(fiscalDocument)
    });
  }

  showInfoMessage = (ch, payload) => {
    let reason = payload.message ?? this.state.infoToShow.reason;
    let message = reason.length > 140 ? this.state.infoToShow.status : (payload == null ? this.state.infoToShow.status + " - " : "") + reason;
    let moreinfo = reason.length > 140 ? this.state.infoToShow.reason : null;
    let type = payload.type ?? 99;
    showInfoModal({ message: message, type, callback: this.handleSearch, moreInfo: moreinfo })
  }

  getInfo = (doc) => {
    return { status: doc.status, reason: doc.reason };
  }

  nfceResultToFiscalDocument = dataResult => {
    return ({
      accessKey: dataResult.chNFe,
      status: dataResult.cStat,
      returnDate: dataResult.dhRetorno ? dataResult.dhRetorno : moment().format(),
      protocol: dataResult.nProt ? dataResult.nProt : '',
      danfeUrl: dataResult.xUrlDanfe ? dataResult.xUrlDanfe : '',
      reason: dataResult.xMotivo,
      satTotalValue: dataResult.valorTotalCFe ? dataResult.valorTotalCFe : 0,
      satReceiptNumber: dataResult.nCFe ? dataResult.nCFe : 0,
      satReceiptSeries: dataResult.nSerieSAT ? dataResult.nSerieSAT : 0
    });
  }

  handleCancel = (order) => {
    this.setState({
      requireReason: true,
      orderId: order.orderId
    })
  }

  startCancelOperation = ({ reason }) => {
    if (reason.length < 15) {
      this.context.showMessage('O motivo do cancelamento deve conter entre 15 e 255 caracteres.');
      return;
    }
    this.setState({
      requireReason: false
    });
    showInfoModal({ message: "Buscando informações para o cancelamento" });
    this.orderService.startOrderCancellation(this.state.orderId, reason, this.props.tpAmb)
      .then(res => {
        if (res.status === 999)
          showInfoModal({ message: null });
      });
  }

  handleOrderCancellationPayload = (ch, result) => {
    if (result.cStat == "135" || result.cStat == "102" || result.cStat == "06000" || result.cStat == "07000") {
      //Adjust Protocol Number, if it IS S@T.
      if (this.props.fiscalType == 2 && document.protocolo) {
        result.nProt = document.protocolo;
      }
      showInfoModal({ message: result.xMotivo });
      if (this.props.fiscalType == 2) { //S@T
        result.danfeType = this.DanfeType.DanfeCFeCanc;
        window.desktopApp.printDANFE(result);
        //orderService.concludeOrderCancellation chamado no handle da routingkey machine.devices.coupon.print.complete
        //que é chamado após a finalização da impressao
        return;
      }

      var concludeCancelPayload = { FiscalCanceledDocument: this.nfceResultToFiscalDocument(result) }
      this.orderService.concludeOrderCancellation(concludeCancelPayload);
    }
    else if (result.cStat == "501") {
      showInfoModal({ message: 'Impossível efetuar o cancelamento.', type: 1, moreInfo: 'Não foi possível Cancelar pois o tempo limite da SEFAZ foi excedido.<br/><br/>Efetue uma Devolução de Venda no FarmaCloud Gestão  através do menu Movimentação - Cupom Fiscal.' });
    }
    else {
      showInfoModal({ message: result.cStat + "-" + result.xMotivo, type: 3 });
    }
  }

  handlePrintConclude = (data) => {
    var concludeCancelPayload = { FiscalCanceledDocument: this.nfceResultToFiscalDocument(data) }
    this.orderService.concludeOrderCancellation(concludeCancelPayload);
  }

  handleCancelOrderSuccess = (ch, payload) => {
    showInfoModal({ message: 'Cancelamento realizado com sucesso.', type: 2, callback: this.handleSearch });
  }

  handleError = (ch, payload) => {
    console.log(payload);
    var moreInfo = payload && payload.errors && payload.errors.map(m => m.errorMessage || m).join(';') || payload;
    showInfoModal({ message: payload && payload.title || "Erro ao realizar operação.", type: 3, moreInfo: moreInfo })
  }

  handleOrderList = (ch, payload) => {
    this.setState({
      data: payload.orders.filter(o => o.fiscalDocument)
    });
  }

  handleClose = () => {
    this.setState({
      data: [],
      selectedItem: null, 
      selectedIndex: -1,
    });
    this.form = {
      startDate: new Date(moment().year(), moment().month(), moment().date()),
      endDate: new Date(moment().year(), moment().month(), moment().date()),
      status: '',
      number: null,
      series: null,
      startValue: null,
      endValue: null
    }
    this.props.hideMonitor();
  }

  handleSearch = () => {
    this.setState({
      selectedItem: null, 
      selectedIndex: -1,
    });
    if (moment().diff(this.form.startDate, 'days') > 90) {
      showToast({
        type: 1,
        title: 'Monitor de notas',
        html: 'Atençao!<br>'
          + 'Documentos de período anterior a 90 dias estão disponíveis no Retaguarda<br>'
          + 'Acesse: Movimentação -> Cupom Fiscal',
        hideAfterMilliseconds: 8000
      });
    }

    if (moment(this.form.endDate).diff(this.form.startDate, 'days') < 0) {
      showToast({
        type: 1,
        title: 'Monitor de notas',
        html: 'Atençao!<br>'
          + 'Data final deve ser maior ou igual a Data inicial<br>',
        hideAfterMilliseconds: 8000
      });
      return;
    }
    this.form.fiscalType = this.props.fiscalType;
    this.orderService.queryCoupon(this.form)
      .catch((error) => {
        console.error(error);
        var errorMessage = 'Houve um erro ao buscar notas fiscais: ' + error.status + ' - ' + error.statusText;
        showInfoModal({ message: errorMessage, type: 3 });
      });
  }

  handleModalClose = () => {
    this.setState({
      requireReason: false
    })
  }

  confirmMessage = (confirmed) => {
    confirmed && window.desktopApp && window.desktopApp.publish('machine.devices.tef.cancelcoupon', this.state.tefTransaction);
    this.setState({
      message: null,
      tefTransaction: null
    });
  }

  render() {
    const { visible } = this.props;
    const { requireReason, data, message } = this.state;
    return (visible &&
      <Fragment>
        <InvoiceMonitor
          data={data}
          options={this.options}
          handleClose={this.handleClose}
          handleSearch={this.handleSearch}
          form={this.form}
          selectedItem={this.state.selectedItem}
          selectedIndex={this.state.selectedIndex}
          handleSelectItem={this.handleSelectItem}
          handleShowChild={this.handleShowChild}
          monitorActions={MonitorActions}
          handleVisualize={this.handleVisualize}
          handleReprintTef={this.handleReprintTef}
          handleReauthorize={this.handleReauthorize}
          handleConsultar={this.handleConsultar}
          handleCancel={this.handleCancel}
          handleUnusable={this.handleUnusable}
        />
        {requireReason &&
          <RequireAuth
            title="Cancelar a venda"
            handleConfirm={this.startCancelOperation}
            handleClose={this.handleModalClose}
            requireUsername={false}
            requirePassword={false}
            requireReason
          />
        }
        {message && (
          <MessageBox
            message={message}
            okText="Sim"
            handleConfirm={() => this.confirmMessage(true)}
            handleCancel={() => this.confirmMessage(false)}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  payload: getPayload(state, MONITOR_NOTAS_LIST) || {},
  visible: shouldShow(state, MONITOR_NOTAS_LIST),
  fiscalType: state.config && state.config.danfe && state.config.danfe.fiscalType,
  tpAmb: state.config && state.config.danfe && state.config.danfe.tpAmb
});

const mapDispatchToProps = dispatch => ({
  hideMonitor: () => dispatch(closeCard(MONITOR_NOTAS_LIST))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(InvoiceMonitorContainer)));
