import React, { Component } from 'react';
import { Card, Button } from '../modules';
import { TefInteractionWrapper, TefInteractionInput } from './style';
import NumberFormat from 'react-number-format';


function limit(val, max) {
  if (val.length === 1 && val[0] > max[0])
    val = '0' + val;

  if (val.length === 2) {
    if (Number(val) === 0)
      val = '01';
    else if (val > max)
      val = max;
  }

  return val;
}

function cardExpiry(val) {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 4);

  return month + (year.length ? '/' + year : '');
}

class TefInteraction extends Component {
  constructor(props) {
    super(props);
    this.tefInput = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (e) => {
    let textVal = this.tefInput.value.replace(/[_ /]+/g, '');
    if (e.key === 'Enter')
      this.props.handleClickFin(textVal);
  }

  componentDidMount = (e) => {
    document.getElementById('codTef').focus();
    var textValue = document.getElementById('codTef');
    textValue.addEventListener('keyup', this.onSubmit);
  }


  inputFormat = (typeInput) => {
    switch (typeInput.header) {
      case 'Digite o número do cartão':
        return <NumberFormat format="#### #### #### ####" mask="_" id="codTef" name="codTef" getInputRef={(el) => this.tefInput = el} />
      case 'VALIDADE: MM/AA':
        return <NumberFormat format={cardExpiry} placeholder="MM/YY" mask={['M', 'M', 'Y', 'Y']} id="codTef" name="codTef" getInputRef={(el) => this.tefInput = el} />
      case 'COD. SEGURANCA':
        return <NumberFormat format="#####" placeholder="CVV" id="codTef" name="codTef" getInputRef={(el) => this.tefInput = el} />
      case 'DIGITE 4 ULTIMOS DIGITOS: ':
        return <NumberFormat format="####" mask="_" id="codTef" name="codTef" getInputRef={(el) => this.tefInput = el} />
      default:
        return <NumberFormat format="#####" id="codTef" name="codTef" getInputRef={(el) => this.tefInput = el} />
    }
  }

  render() {
    const { handleClose, handleClickFin, title, subTitle, image, typeInput } = this.props
    const fieldFormated = this.inputFormat(typeInput);
    return (
      <Card title={title} subTitle={subTitle} urlIcone={image} handleClose={handleClose} closeEnable>
        <TefInteractionWrapper>
          <TefInteractionInput>
            {fieldFormated}
            <Button type="button" id="btnConfirm" className="btn-large btn-outline btn-confirm-pay" handleClick={(e) => handleClickFin(this.tefInput.value)}>
              Enviar
        </Button>
          </TefInteractionInput>
        </TefInteractionWrapper>
      </Card>
    )
  }
};

export default TefInteraction;
