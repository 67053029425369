import { AbstractService } from './abstractService';

export class LotService extends AbstractService {
  constructor() {
    super('/Lot');
  }

  startLotIdentification = (productReferenceId, sequentialId) => this.invokeAction('/StartLotIdentification', { productReferenceId, sequentialId });

  cancelLotIdentification = () => this.invokeAction('/CancelLotIdentification');

  setLotIdentification = payload => this.invokeAction('/SetLotIdentification', payload);
}

export default LotService;
