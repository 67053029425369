import {
    MODAL_MESSAGE_SHOW,
    MODAL_MESSAGE_HIDE,
    MODAL_MESSAGE_CHANGE,
  } from './actionTypes';
  
  export const modalMessageShow =  callback => (dispatch) => {
    dispatch({
      type: MODAL_MESSAGE_SHOW,
      callback
    });
  };
  
  export const modalMessageChange =  payload => (dispatch) => {
    dispatch({
      type: MODAL_MESSAGE_CHANGE,
      payload,
    });
  };

  export const modalMessageHide = () => (dispatch) => {
    dispatch({
      type: MODAL_MESSAGE_HIDE
    });
  };
  