import styled from 'styled-components';
import theme from '../../../themes/default';

export const DetailOrderWrapper = styled.div`
 margin-top: 20px;
 .sub-title {
   font-weight: bold;
 }
`;

export const TotalWrapper = styled.div`
   display: flex;
   justify-content: flex-end;
   margin-top: 30px;
   strong {     
     margin: 0 32px 0 10px;
   }
`;

export const InputWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  button {
      margin: -1px 0 0 10px;
      padding: 0 16px;
      color: #fff;
      background-color: #26a69a;
      letter-spacing: .5px;
      height: 50px;
      min-width: 100px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 2px;
      cursor: pointer;
    }

    input {
      padding-left: 20px;        
        width: 100%;
        height: 50px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }

  .value-area{
      font-size: 20px;
      height: 46px;
      width: 75%;
      border: transparent;       
      outline: none;
      color: ${theme.palette.grayscale.ultraDark};          
      
    &:focus {        
      outline: none;
    }
  } 

  .close-modal{
    float:right;
    top:1px;
  }
`;
