import styled from 'styled-components';

const LabelTitleValueWrapper = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;
  i { display:inline-block; }
  line-height: normal;

  .title-inactive{
    color: #EBEBE4;
  }

  .title-active{
    color: black;
  }
`;

export default LabelTitleValueWrapper;
