import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showCard, closeAllCards, LOT, shouldShow, getPayload } from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import { LotControl } from '../../shared/components/modules';
import { withMessageBus } from '../../shared/utils/messageBus';
import { LotService } from '../../services/lotService';

export class LotControlContainer extends Component {

  static propTypes = {
    item: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      lots: PropTypes.arrayOf(PropTypes.any),
    }),
    show: PropTypes.bool,
    showLot: PropTypes.func.isRequired,
    hideLot: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    item: null,
    show: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      item: null,
      totalQuantity: 0
    };

    this.handleShowError = this.handleShowError.bind(this);
    this.confirm = this.confirm.bind(this);

    this.props.MessageBus.Subscribe('pos.lot.showLot', this.handleShow);
    this.props.MessageBus.Subscribe('pos.lot.back', this.hideLot);
    this.props.MessageBus.Subscribe('pos.lot.identified', this.hideLot);
    this.props.MessageBus.Subscribe('pos.lot.lotIdentificationFailed', this.handleShowError);

    this.service = new LotService();
  }

  handleShow = (ch, payload) => {
    this.setState({
      item: payload
    });
    this.props.showLot(ch, payload);
  }

  hideLot = () => {
    this.props.hideLot();
  }

  handleShowError(ch, payload) {
    this.context.showMessage(payload && payload.reason);
  }

  handleChangeQuantity = (idx, value) => {
    let stateItem = this.state.item;
    let totalQuantity = 0;

    const updatedLots = stateItem.lots.map((lot, i) => {

      if (i === idx) {
        totalQuantity = totalQuantity + value
        return {
          ...lot,
          typedQuantity: value
        };
      } else {
        totalQuantity = totalQuantity + lot.typedQuantity;
      }

      return lot;
    });

    if (totalQuantity > this.state.item.product.quantity)
      return;

    stateItem.lots = updatedLots;

    this.setState({ item: stateItem, totalQuantity: totalQuantity });
  }


  confirm() {
    const { item } = this.state;
    const toSave = {
      sequentialId: item.sequentialId,
      productId: item.product.id,
      lots: item.lots.map(it => ({
        lotId: it.id,
        quantity: it.typedQuantity,
        referenceId: it.referenceId,
        number: it.number,
        expirationDate: it.expirationDate
      })),
    };

    this.service.setLotIdentification(toSave);
  }

  render() {
    const { show, item } = this.props;
    return (
      <Fragment>
        {show && (
          <LotControl
            title=""
            item={this.state.item}
            totalQuantity={this.state.totalQuantity}
            handleClose={this.service.cancelLotIdentification}
            handleConfirm={this.confirm}
            handleChangeQuantity={this.handleChangeQuantity}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  show: shouldShow(state, LOT),
  item: getPayload(state, LOT)
});

const mapDispatchToProps = dispatch => ({
  showLot: (ch, payload) => dispatch(showCard(LOT, payload, false)),
  hideLot: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(LotControlContainer)));
