import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialVisibleState = {
  preOrderListShow: false,
  pbmShow: false,
  pbmProductsShow: false,
  lastOrderListShow: false,
  reprintTefListShow: false,
  cancelamentoTefListShow:false,
  monitorNotasShow: false,
  reOpenMonitor: false
};

const administrativeMenuReducer = createReducer(initialVisibleState, {
  [types.PRE_ORDER_LIST_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    preOrderListShow: action.payload,
  }),
  [types.PBM_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    pbmShow: true,
    payload: action.payload
  }),
  [types.PBM_HIDE_ALL]: (state) => ({
    ...state,
    ...initialVisibleState,
    pbmShow: false,
    pbmProductsShow: false
  }),
  [types.PBM_PRODUCTS_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    pbmProductsShow: true,
    payload: action.payload
  }),
  [types.LAST_ORDER_LIST_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    lastOrderListShow: action.payload,
  }),
  [types.REPRINT_TEF_LIST_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    lastOrderListShow: action.payload,
    reprintTefListShow: action.payload,
  }),
  [types.CANCELAMENTO_TEF_LIST_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    lastOrderListShow: action.payload,
    cancelamentoTefListShow: action.payload,
  }),
  [types.MONITOR_NOTAS_LIST_SHOW]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    monitorNotasShow: true,
    payload: action.payload
  }),
  [types.REOPEN_MONITOR]: (state, action) => ({
    ...state,
    reOpenMonitor: action.payload,
  })
});

export default administrativeMenuReducer;
