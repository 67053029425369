import HttpAccess from './httpAccess';
import Configuration from '../configuration';
import handlePost from '../shared/utils/handlePost';
import { AbstractService } from './abstractService';

const abstractService = new AbstractService(
  'CashDesk',
  HttpAccess,
  Configuration
);

export const cashOpen = (
  openingAmount,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/CashDesk/open`;
  const body = {
    ...openingAmount,
  };

  return handlePost(url, body, httpAccess);
};

export const cashCloseStart = (
  partial = false,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/close/start/${partial}`;
  const body = {
    retailerId: configuration.retailerId,
    posId: configuration.PosId,
  };

  return handlePost(url, body, httpAccess);
};

export const cashCloseCancel = (
  barcodeToUnread = [],
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/close/cancel`;
  const body = {
    retailerId: configuration.retailerId,
    posId: configuration.PosId,
    barcodeToUnread,
  };

  return handlePost(url, body, httpAccess);
};

export const cashClose = (
  closeCashDeskValues,
  parcial = false,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/close`;
  const body = {
    closeCashDeskValues,
    parcial,
  };

  return handlePost(url, body, httpAccess);
};

export const cashWithdrawStart = (
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/withdraw/start`;
  return handlePost(url, {}, httpAccess);
};

export const cashWithdrawExcess = (
  objectToCashWithDraw,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/withdraw/excess`;
  const body = {
    ...objectToCashWithDraw,
  };
  return handlePost(url, body, httpAccess);
};

export const cashWithdrawPaymentExpenses = (
  objectToCashWithDraw,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/withdraw/paymentExpenses`;
  const body = {
    ...objectToCashWithDraw,
  };

  return handlePost(url, body, httpAccess);
};

export const cashWithdrawCancel = (
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/withdraw/cancel`;
  return handlePost(url, {}, httpAccess);
};

export const cashSupplyStart = (
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/supply/start`;
  return handlePost(url, {}, httpAccess);
};

export const cashSupply = (
  cashSupplyObject,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/supply`;

  const body = {
    ...cashSupplyObject,
  };

  return handlePost(url, body, httpAccess);
};

export const cashSupplyCancel = (
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/supply/cancel`;
  return handlePost(url, {}, httpAccess);
};

export const getBarCode = (
  barCode,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/close/${barCode}`;
  const body = {
    retailerId: configuration.retailerId,
    posId: configuration.PosId,
  };

  return handlePost(url, body, httpAccess);
};

export const cashCloseSaveDescription = (
  description,
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/close/description`;

  const body = {
    description,
  };

  return handlePost(url, body, httpAccess);
};

export const getBarCodeUnread = (
  barcodeToUnread = [],
  httpAccess = HttpAccess,
  configuration = Configuration
) => {
  const url = `${abstractService.getBaseUrl()}/cashDesk/close/unread`;
  const body = {
    retailerId: configuration.retailerId,
    posId: configuration.PosId,
    barcodeToUnread,
  };

  return handlePost(url, body, httpAccess);
};
