import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Button } from '../modules';
import { RadioButton } from '../radio-button';
import { PasswordInput } from '../password';
import './config-epharma-component.scss';

function ConfigEpharmaComponent(props) {
    const {
        environmentValues,
        handleEnvironmentChange,
        form,
        handleChange,
        handleConfirm
    } = props;

    const requiredLabelRender = (label) => {
        return (<>
            <span>{label} </span>
            <span style={{ color: "red", fontWeight: 600 }}>*</span>
        </>)
    }

    return (
        <div className="body">
            <Form>
                <Form.Row>
                    <RadioButton title="Ambiente" values={environmentValues} onChange={handleEnvironmentChange} />
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="url">
                        <Form.Label>Endereço Integração</Form.Label>
                        <Form.Control
                            placeholder="Url"
                            defaultValue={form?.url}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="user">
                        <Form.Label>{requiredLabelRender("Usuário")}</Form.Label>
                        <Form.Control
                            placeholder="Usuário"
                            defaultValue={form?.user}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="password">
                        <Form.Label>Senha</Form.Label>
                        <PasswordInput value={form?.password} onChange={(e) =>
                            handleChange({ target: { id: 'password', value: e.target.value } })
                        }></PasswordInput>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="codigoFarmacia">
                        <Form.Label>{requiredLabelRender("Código Farmácia - ePharma")}</Form.Label>
                        <Form.Control
                            placeholder="Código farmácia"
                            defaultValue={form?.codigoFarmacia}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form.Row>
            </Form>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
            </div>
        </div>
    );
}

export default ConfigEpharmaComponent;