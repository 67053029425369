import React, { Component } from 'react';
import { showToast } from '../../shared/utils/util';
import { connect } from 'react-redux';

import PagamentosIcon from '../../assets/images/pagamentos.png';
import { show } from '../../redux/actions/loadingScreenAction';
import {
  modalMessageChange,
  modalMessageHide,
} from '../../redux/actions/modalMessageAction';
import {
  showCard,
  AUTHORIZATION_DETAIL,
  shouldShow,
  getPayload,
  closeCard,
} from '../../redux/actions/routerAction';
import ConfigService from '../../services/configService';
import { OrderService } from '../../services/orderService';
import PaymentService from '../../services/paymentService';
import SaleService from '../../services/saleService';
import { Messages } from '../../shared/components/modules';
import QrCodeCancelMessage from '../../shared/components/qr-code-cancel-message';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';

export class MessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.modalQrCodeCancelMessage = React.createRef();
    this.state = {
      detailsOrder: '',
      message: '',
      showButtonClose: false,
      visible: false,
      wallets: {},
      showQRCode: true
    };
    this.service = new PaymentService();
    this.saleService = new SaleService();
    this.orderService = new OrderService();
    this.getBrandErrorMessage =
      'Não foram encontrados cadastros de administradoras de cartão com as definições de bandeiras que serão transacionadas. Cancele a operação, acesse o retaguarda e faça os cadastros.';

    this.setSubscriptions();
  }

  clearState() {
    this.setState({
      detailsOrder: '',
      message: '',
      showButtonClose: false,
      visible: false,
      wallets: {},
      showQRCode: true
    });
  }

  setSubscriptions() {
    this.props.MessageBus.Subscribe(
      'machine.devices.tef.creditcard',
      this.eventDetailsOrder
    );
    this.props.MessageBus.Subscribe(
      'machine.devices.tef.debitcard',
      this.eventDetailsOrder
    );
    this.props.MessageBus.Subscribe(
      'machine.devices.coupon.authorization',
      this.eventDetailsOrder
    );
    this.props.MessageBus.Subscribe(
      'machine.devices.coupon.cancellation',
      this.eventDetailsOrder
    );
    this.props.MessageBus.Subscribe(
      'pos.order.cancelled',
      this.eventOrderCancelledSuccess
    );
    this.props.MessageBus.Subscribe(
      'pos.order.cancelFailed',
      this.eventCloseMessages
    );
    this.props.MessageBus.Subscribe(
      'pos.order.authorizationNoteFinished',
      this.handleResendNoteFinished
    );

    if (window.desktopApp) {
      window.desktopApp.subscribe(
        'machine.devices.message',
        this.eventTefMessage
      );
      window.desktopApp.subscribe(
        'machine.devices.tef.cancelPaymentOnOrder',
        this.eventTefCancelPaymentOnOrder
      );
      window.desktopApp.subscribe(
        'machine.devices.tef.creditcard.completed',
        this.eventCreditCompleted
      );
      window.desktopApp.subscribe(
        'machine.devices.tef.debitcard.completed',
        this.eventDebitCompleted
      );
      window.desktopApp.subscribe(
        'machine.devices.coupon.authorization.completed',
        this.eventCouponAuthCompleted
      );
      window.desktopApp.subscribe(
        'machine.devices.coupon.cancellation.completed',
        this.eventCouponCanceledCompleted
      );
      window.desktopApp.subscribe(
        'machine.devices.coupon.cancellation.error',
        this.handleError
      );
      window.desktopApp.subscribe(
        'machine.devices.tef.debitcard.getBrand.completed',
        this.eventTefDebitCardBrandCompleted
      );
      window.desktopApp.subscribe(
        'machine.devices.tef.creditcard.getBrand.completed',
        this.eventTefCreditCardBrandCompleted
      );
      window.desktopApp.subscribe(
        'machine.devices.qrcode.cancel.pending.operation.completed',
        this.eventQrCodeError
      );
      window.desktopApp.subscribe(
        'machine.devices.payment.error',
        this.eventPaymentError
      );
      window.desktopApp.subscribe(
        'machine.devices.show.cancelButton',
        this.eventShowButtonClose
      );
      window.desktopApp.subscribe(
        'machine.devices.hide.cancelButton',
        this.eventHideButtonClose
      );
      window.desktopApp.subscribe(
        'machine.devices.close.messages',
        this.eventCloseMessages
      );
      window.desktopApp.subscribe(
        'machine.devices.qrcode.wallets',
        this.showWallets
      );
      window.desktopApp.subscribe('machine.devices.qrconfig', this.getQrConfig);
    }
  }

  showWallets = (channel, data) => {
    var wallets = [...new Set(data.map((item) => item.logo_link))];
    this.setState({
      wallets: wallets,
    });
  };

  getQrConfig = (channel, data) => {
    ConfigService.getQrCodeConfig(data.posId, data.retailerId);
  };

  eventDetailsOrder = (canal, payload) => {
    !this.props.showMessageModal && this.props.openAuthorizationDetail(payload);

    if (!window.desktopApp) {
      // Isto aqui é apenas para testes em ambiente de dev
      const r = window.confirm(
        `Gostaria de autorizar a nota.${JSON.stringify(payload)}`
      );
      if (r === true) {
        this.eventCouponAuthCompleted([]);
      } else {
        this.service.paymentError('Cancelado manualmente.');
      }
    }

    if (this.props.menu.reOpenMonitor) {
      this.props.MessageBus.Publish('pos.start.cancellation', payload);
    }
  };

  eventTefMessage = (ch, msg) => {
    this.setState({
      message: msg,
    });
    if (this.props.showMessageModal) {
      this.props.changeModalMessage({ message: msg });
    }
  };

  eventCreditCompleted = (ch, data) => {
    this.service.doneCreditTefPayment(data);
  };

  eventTefCancelPaymentOnOrder = (ch, data) => {
    this.service.cancelPaymentOnOrder(data.NSU);
  };

  eventDebitCompleted = (ch, data) => {
    this.service.doneDebitTefPayment(data);
  };

  eventCouponAuthCompleted = (ch, data) => {
    this.service.couponAuthorization(data);
  };

  eventCouponCanceledCompleted = (ch, data) => {
    this.orderService.concludeOrderCancellation(data);
  };

  eventTefDebitCardBrandCompleted = (ch, data) => {
    this.service.tefDebitCardBrand(data).catch((error) => {
      this.eventPaymentError(null, { error: this.getBrandErrorMessage });
    });
  };

  eventTefCreditCardBrandCompleted = (ch, data) => {
    this.service.tefCreditCardBrand(data).catch((error) => {
      this.eventPaymentError(null, { error: this.getBrandErrorMessage });
    });
  };

  eventPaymentError = (ch, data) => {
    this.service.paymentError(data.error);
    console.log(
      `machine.devices.payment.error called with error: ${data.error}`
    );
  };

  eventQrCodeError = (ch, data) => {
    this.service.paymentQrError();
  };

  eventOrderCancelledSuccess = () => {
    this.eventCloseMessages();
    if (this.props.showMessageModal) {
      // tratar da tela de cancelmento alterar a props
      this.props.changeModalMessage({
        message: 'Venda cancelada com sucesso!',
        messageType: 2,
      });
    }
    if (this.props.menu.reOpenMonitor)
      this.props.MessageBus.Publish('pos.order.noteCancelledSucess', '');
  };

  eventOrderCancelledFailed = () => {
    this.eventCloseMessages();
  };

  copyToClipBoard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    showToast({
      type: 0,
      title: 'QR Linx Copia e Cola',
      html: 'Link Copiado',
      hideAfterMilliseconds: 10000,
    });
  }

  handleCloseQrCodeCancelMessage = () => {
    this.modalQrCodeCancelMessage.current.close();
  };

  handleConfirmQrCodeCancelMessage = () => {
    if (window.desktopApp) {
      this.props.show('Carregando...');
      window.desktopApp.publish(
        'machine.devices.tef.cancel.pending.operation',
        ''
      );
    }
    this.modalQrCodeCancelMessage.current.close();
  };

  qrCodeOrPixHandle = () => {
    if (this.state.showQRCode) {
      this.copyToClipBoard(this.state.message.qrcode)
    }
    this.setState({
      showQRCode: !this.state.showQRCode,
    });
  };

  eventCloseMessages = () => {
    this.clearState();
    if (this.props.menu.reOpenMonitor) {
      this.props.closeAuthorizationDetail();
      this.props.MessageBus.Publish('pos.order.noteCancelledSucess', '');
    }
  };

  handleResendNoteFinished = () => {
    this.eventCloseMessages();
    this.props.MessageBus.Publish('pos.order.noteFinishedReOpen', '');
  };

  handleClose = () => {
    // TODO: Add Wait Window
    if (this.state.message.qrcode) {
      this.modalQrCodeCancelMessage.current.open();
      return;
    }
    if (window.desktopApp) {
      this.props.show('Carregando...');
      window.desktopApp.publish(
        'machine.devices.tef.cancel.pending.operation',
        ''
      );
    }
  };

  eventShowButtonClose = () => {
    this.setState({ showButtonClose: true });
  };

  eventHideButtonClose = () => {
    this.setState({ showButtonClose: false });
  };

  handleError = (ch, payload) => {
    if (this.props.showMessageModal) {
      this.props.changeModalMessage({
        messageType: 3, // Erro
        message: 'Houve um erro ao processar cancelamento da venda!',
        moreInfo: this.state.message,
      });
    } else {
      this.context.showMessage(payload.message);
    }

    if (this.props.menu.reOpenMonitor)
      this.props.MessageBus.Publish('pos.order.noteCancelledReOpen', payload);
  };

  handleModalClose = () => {
    this.props.modalMessage.callback && this.props.modalMessage.callback();
    this.props.hideModalMessage();
  };

  render() {
    const visible = this.props.showAuthorizationDetail;
    const propsOrder = this.props.detailsOrder;
    const messages = this.state.message;
    const showBtnClose = this.state.showButtonClose;
    return (
      <div>
        <QrCodeCancelMessage
          modalQrCodeCancelMessage={this.modalQrCodeCancelMessage}
          handleCloseQrCodeCancelMessage={this.handleCloseQrCodeCancelMessage}
          handleConfirmQrCodeCancelMessage={this.handleConfirmQrCodeCancelMessage}>
        </QrCodeCancelMessage>
        {visible && (
          <Messages
            items={propsOrder}
            messages={messages}
            imgUrl={PagamentosIcon}
            handleClose={this.handleClose}
            showBtnClose={showBtnClose}
            wallets={this.state.wallets}
            showQRCode={this.state.showQRCode}
            qrCodeOrPixHandle={this.qrCodeOrPixHandle}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showAuthorizationDetail: shouldShow(state, AUTHORIZATION_DETAIL),
  detailsOrder: getPayload(state, AUTHORIZATION_DETAIL),
  showMessageModal: state.modalMessage.visible,
  modalMessage: state.modalMessage,
  menu: state.administrativeMenu,
});

const mapDispatchToProps = (dispatch) => ({
  openAuthorizationDetail: (payload) =>
    dispatch(showCard(AUTHORIZATION_DETAIL, payload)),
  closeAuthorizationDetail: () => dispatch(closeCard(AUTHORIZATION_DETAIL)),
  show: (param) => dispatch(show({ text: param })),
  changeModalMessage: (param) => dispatch(modalMessageChange(param)),
  hideModalMessage: (param) => dispatch(modalMessageHide(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(MessagesContainer)));
