import PubSub from 'pubsub-js';

import Configuration from '../configuration';
import { removeFirstConnection } from '../redux/modules/connectionPool/duck';
import Store from '../redux/store/index';
import { PDV_CONTINGENCY_STATUS } from '../redux/actions/actionTypes';
import satConfig from '../shared/utils/sat';
import WSConnection from './WSConnection';
import { showInfoModal } from '../shared/utils/util';
import OfflineService from './offlineService';
import { showToast } from '../shared/utils/util';

class MessageBusService {
  connection = null;

  static _instance = new MessageBusService();

  _onConnected = (onConnectCallback) => {
    this.Publish('pos.wsConnected');
    onConnectCallback && onConnectCallback();
  };

  constructor() {
    this.changeContingencySubscriptions = [];
    window.desktopApp &&
      window.desktopApp.subscribe(
        'contigencychange',
        this.ContingencyChange.bind(this)
      );
    this.Subscribe(
      'pos.order.deletedByContingency',
      this.OrderDeletedByContingency.bind(this)
    );
  }

  async ContingencyChange(topic, message) {
    // Se o estado recebido da contingência for o mesmo, não refaz conexões nem exibe dialog de conexão perdida
    if (Store.getState().pdvContingency.isOnline === message.isOnline) {
      console.log(
        `ContingencyChange State not changed: ${
          Store.getState().pdvContingency.isOnline
        }`
      );
      return;
    }
    if (message.isOnline) {
      this.UnsubscribeContingency();
      console.log('ContingencyChange - Subscribed');

      const contingencyChangeEvent = () => {
        console.log('ContingencyChange - Called ConnectionChange.');
        this.ConnectionChange('contigencychange', {
          isOnline: true,
          systemStartup: false,
        });
      };

      this.changeContingencySubscriptions.push(
        this.Subscribe('sale.done', contingencyChangeEvent)
      );
      this.changeContingencySubscriptions.push(
        this.Subscribe('pos.open.success', contingencyChangeEvent)
      );
      this.changeContingencySubscriptions.push(
        this.Subscribe('pos.order.deleted', contingencyChangeEvent)
      );
      return;
    }
    await this.ConnectionChange(topic, message);
  }

  // eslint-disable-next-line class-methods-use-this
  Handle(message, pubSub = PubSub) {
    const parse = JSON.parse(message);

    if (parse) {
      console.log(`Event-Back: ${parse.routingKey}`);
      satConfig(parse.routingKey);
    }

    pubSub.publish(parse.routingKey, parse.payload);

    const blacklist = ['machine.devices.printer'];

    const splited = parse.routingKey.split('.');
    if (
      splited[splited.length - 1] !== 'ignore' &&
      !(
        parse.routingKey === 'front.toast.show' &&
        parse.payload.includeInBlacklist
      ) &&
      !(
        parse.routingKey === 'pos.dialog' && parse.payload.includeInBlacklist
      ) &&
      blacklist.indexOf(parse.routingKey) === -1
    ) {
      if (parse.routingKey !== 'pos.state.multipleKeys') {
        Store.dispatch(removeFirstConnection());
      } else {
        parse.payload.forEach(() => Store.dispatch(removeFirstConnection()));
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  Subscribe(channel, callbackPayload) {
    return PubSub.subscribe(channel, callbackPayload);
  }

  SubscribeOnce(channel, callbackPayload) {
    PubSub.subscribeOnce(channel, callbackPayload);
  }

  Unsubscribe(subscription) {
    PubSub.unsubscribe(subscription);
  }

  // eslint-disable-next-line class-methods-use-this
  Publish(channel, payload) {
    PubSub.publish(channel, payload);
  }

  ClearSubscriptions() {
    PubSub.clearAllSubscriptions();
  }

  HideLoading() {
    Store.dispatch({
      type: 'LOADING_SCREEN_HIDE',
    });
  }

  async ConnectionChange(topic, message) {
    console.log(`ConnectionChange. State: ${
      Store.getState().pdvContingency.isOnline
    },
        Message: ${message.isOnline}`);

    // Se o estado recebido do contingência for o mesmo, não refaz conexões nem exibe dialog de conexão perdida
    if (
      this.connection &&
      Store.getState().pdvContingency.isOnline === message.isOnline
    ) {
      console.log(
        `State not changed: ${Store.getState().pdvContingency.isOnline}`
      );
      return;
    }

    if (!message.isOnline) {
      this.HideLoading();
    }

    if (this.connection) {
      await this.ShowContingencyCommunicationMessage(message);

      this.connection.Stop();

      while (!this.connection.IsDisconnected()) {
        this.connection.Stop();
        console.log('WS Connection is still active, waiting ...');
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log('Closed WS Connection.');
    }

    this.CreateConnection(message.isOnline, () => {
      console.log('Callback create connection isOnline: ' + message.isOnline);
      if (message.isOnline) {
        //DISPARA Sincronização
        this.Publish('machine.sync.contingency', {});
        return;
      }
      //DISPARA ROTINA PARA LIMPAR ESTADOS
      var service = OfflineService.GetInstance();
      service.change(Store.getState().posType.posType, message.isOnline);
    });

    Store.dispatch({
      type: PDV_CONTINGENCY_STATUS,
      isOnline: message.isOnline,
    });
    this.UnsubscribeContingency();
  }

  async ShowContingencyCommunicationMessage(message) {
    var communicationMessage = message.isOnline
      ? 'Estabelecendo comunicação com o servidor, aguarde...'
      : 'Conexão perdida, estabelecendo conexão com o servidor de contingência, aguarde...';

    showInfoModal({ message: communicationMessage });

    if (!message.isOnline) this.OrderDeletedByContingency();

    await new Promise((resolve) => setTimeout(resolve, 2000));
  }

  async CreateConnection(isOnline, onConnectCallback) {
    let connectionString = isOnline
      ? Configuration.HubEndPoint
      : Configuration.HubEndPointContingency;

    if (this.connection) {
      this.connection.Stop();
    }

    const connection = new WSConnection(
      `${connectionString}?PosId=${Configuration.PosId}&RetailerId=${Configuration.retailerId}`,
      (Configuration.endPointContingency !== undefined && !isOnline) ||
        Configuration.endPointContingency === undefined
    );

    await connection.Start(this.Handle);

    this.connection = connection;
    this._onConnected(onConnectCallback);
  }

  async OrderDeletedByContingency() {
    let moreInfo = 'Lançar novamente os itens para continuar vendendo';
    showToast({
      type: 1,
      title: 'Pedido removido',
      html: moreInfo,
    });
    return;
  }

  UnsubscribeContingency() {
    console.log('ContingencyChange - Unsubscribed');
    this.changeContingencySubscriptions.forEach((subscription) =>
      this.Unsubscribe(subscription)
    );
    this.changeContingencySubscriptions = [];
  }

  OnConnected(handler) {
    this._onConnected = handler;
  }

  static GetInstance() {
    return (
      MessageBusService._instance ||
      (MessageBusService._instance = new MessageBusService())
    );
  }
}

export default MessageBusService;
