import { Component } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../../shared/utils/messageBus';
import Store from "../../../redux/store/index";

class FiscalFlowStatus extends Component {
  constructor(props) {
    super(props);

    this.ffInterval = null
    this.state = {
      ffStatusUrl: 'http://localhost:8089',
      ffIntervalMilisec: 20000      
    };    
    if (window.desktopApp){
      window.desktopApp.subscribe('machine.devices.fiscalflow.status.success', this.handleTestStatusSuccess);
      window.desktopApp.subscribe('machine.devices.fiscalflow.status.error', this.handleTestStatusError);
      window.desktopApp.subscribe('machine.devices.fiscalflow.status.start', this.ffCheckStatus);
      this.ffStatusTimer();
    } 
  }

  handleTestStatusSuccess()
  {
    console.log("Teste Fiscal Flow realizado com sucesso.");    
    Store.dispatch({
      type: 'CHECK_STATUS_FISCALFLOW',
      payload: true
  });
  }

  handleTestStatusError()
  {
    console.log("Falha ao realizar o teste Fiscal Flow");    
    Store.dispatch({
      type: 'CHECK_STATUS_FISCALFLOW',
      payload: false
  });
  }

  getUrl() {        
    return this.props.fiscalType === 1 ? this.props.mIDeUrl : this.props.satUrl;
  }

  ffStatusTimer = () => {
    if (this.ffInterval != null) {
      clearInterval(this.ffInterval);
    }
    else {
      this.ffCheckStatus();
    }
    this.ffInterval = setInterval(this.ffCheckStatus, this.state.ffIntervalMilisec);
  }

  ffCheckStatus = () => {               
    if (window.desktopApp)
      window.desktopApp.publish("machine.devices.fiscalflow.status", this.getUrl());
  }
  render() {
    return "";
  }
}

const mapStateToProps = state => ({  
  fiscalType : state.config && state.config.danfe && state.config.danfe.fiscalType,
  mIDeUrl : state.config && state.config.danfe && state.config.danfe.MIDeUrl,
  satUrl : state.config && state.config.danfe && state.config.danfe.sat && state.config.danfe.sat.url
});

export default connect(mapStateToProps, null)(withMessageBus(FiscalFlowStatus));
