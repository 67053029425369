import styled from 'styled-components';
import theme from '../../../themes';

const { palette } = theme.theme2;

export const Container = styled.div`
  margin-bottom: 20px;

  fieldset {
    border-top: 1px solid ${palette.border};
  }

  legend {
    width: inherit;
    font-size: 16px;
    font-weight: bold;
    padding-right: 8px;

    display: flex;
    align-items: center;

    div {
      padding-left: 5px;
      font-size: 12px;
      span {
        color: ${palette.link};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  > span {
    display: block;
    font-size: 12px;
    text-align: right;
    margin-bottom: -5px;
  }

  input[disabled] {
    background-color: #ebebe4;
    color: #000000;
  }

  select[disabled] {
    background-color: #ebebe4;
    color: #000000;
  }
`;
