import React from 'react'
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { RequireAuth } from '../../shared/components/modules';
import LinxConectaService from '../../services/linxConectaService';

export class LinxConectaPasswordContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: 'Senha Cartão Conecta'
    };
    
    this.props.MessageBus.Subscribe('pos.linxConecta.senha', this.handleOpen);
    this.props.MessageBus.Subscribe('pos.linxConecta.senhaClose', this.handleClose);
  }

  handleOpen = () => {
    this.setState({
      visible: true
    });
  }

  handleClose = () => {
    this.setState({
      visible: false
    });
  }

  handleConfirm = ({ password }) => {
    LinxConectaService.senhaCartao(password);
  }

  render() {
    return (this.state.visible &&
      <RequireAuth
        title={this.state.title}
        handleConfirm={this.handleConfirm}
        handleClose={this.handleModalClose}
        requireUsername={false}
        requirePassword
        closable={false}
      />
    )
  }
}

export default withMessageBus(getContext(LinxConectaPasswordContainer));