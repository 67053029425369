import { Form as DefaultForm } from 'react-bootstrap';
import styled from 'styled-components';

import theme from '../../../themes/theme2';
import { Button as DefaultButton } from '../modules';

export const Form = styled(DefaultForm)`
  display: flex;
  justify-content: space-between;
`;

export const ColumnIdentifier = styled.div`
  padding-right: ${theme.metrics.basePadding * 2}px;
  width: 100%;
`;

export const ColumnSearch = styled.div`
  display: flex;
  align-items: flex-end;
  /* padding-right: 5px !important; */
`;

export const OptionButton = styled(DefaultButton)`
  /* border: 2px solid ${theme.palette.border} !important; */
`;
