import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { closeCardWithoutHideOthers } from '../../redux/actions/routerAction';
import { getPayload, shouldShow, STOCKLIST } from '../../redux/actions/views';
import MessageBusService from '../../services/MessageBusService';
import ProductService from '../../services/productService';
import StockList from '../../shared/components/stock-list';
import { showInfoModal } from '../../shared/utils/util';
import KeyboardEventHandler from 'react-keyboard-event-handler';

export default function StockListContainer(props) {

    const [tableConfig, setTableConfig] = useState({
        customClass: 'stock-list',
        columns: [
            { title: 'Filial', label: 'cdFilial', enableSort: true, sortDirection: 'asc' },
            { title: 'Fantasia', label: 'nmFant' },
            { title: 'Estoque Atual', label: 'qtEst', textIfNull: '0', enableSort: true, sortDirection: 'desc' }
        ],
    });
    const [stock, setStock] = useState([]);
    const [orderDirection, setOrderDirection] = useState("desc");
    const [orderField, setOrderField] = useState("qtEst");
    const visible = useSelector(state => shouldShow(state, STOCKLIST));
    const currentItem = useSelector(state => getPayload(state, STOCKLIST));
    const dispatch = useDispatch();
    const close = function () {
        dispatch(closeCardWithoutHideOthers(STOCKLIST, false, currentItem.hideCoupon));
    }
    const productService = new ProductService();


    useEffect(() => {
        if (visible) {
            setStock([]);
            toggleLoading(true);
            productService.getStock(currentItem.referenceId);
        }
    }, [visible, currentItem.referenceId]);

    useEffect(() => {
        const stockSubscription = MessageBusService.GetInstance().Subscribe('pos.product.stock.success', handleStockQuantity);
        const stockErrorSubscription = MessageBusService.GetInstance().Subscribe('pos.product.stock.error', handleStockError);
        return () => {
            MessageBusService.GetInstance().Unsubscribe(stockSubscription);
            MessageBusService.GetInstance().Unsubscribe(stockErrorSubscription);
        };
    });

    const handleStockQuantity = (ch, payload) => {
        setStock(payload);
        toggleLoading(false);
    }
    const handleStockError = (ch, payload) => {
        setStock([]);
        toggleLoading(false);
        close();
        showInfoModal({ message: payload.error, type: 3, moreInfo: payload.stackTrace.replace(/[\n]/g, "<br/>") });
    }
    const toggleLoading = (show) => {
        dispatch({
            type: show ? 'LOADING_SCREEN_SHOW' : 'LOADING_SCREEN_HIDE',
            text: 'Buscando estoque nas filiais, aguarde...'
        });
    }

    const handleSort = (column) => {
        var sortField = orderField;
        var sortDirection = orderDirection;
        if (column.label !== sortField) {
            sortField = column.label;
            sortDirection = "asc";
        }
        else
            sortDirection = sortDirection === "asc" ? "desc" : "asc";

        setOrderField(sortField);
        setOrderDirection(sortDirection);

        var sorted = stock;
        sorted.sort((a, b) => sortDirection === "asc" ? a[sortField] - b[sortField] : b[sortField] - a[sortField]);
        setStock(sorted);

        tableConfig.columns[tableConfig.columns.indexOf(column)].sortDirection = sortDirection;
        setTableConfig(tableConfig);
    }

    return (visible && <KeyboardEventHandler
        handleKeys={['esc']}
        onKeyEvent={(key, e) => { if (key === 'esc') close(); }}
        isExclusive>
        <StockList onClose={close} visible={visible} item={currentItem} stock={stock} handleSort={handleSort} tableConfig={tableConfig} ></StockList>
    </KeyboardEventHandler>)
}