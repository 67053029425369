import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { showCard, closeAllCards, SEVEN, shouldShow } from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import CardSeven from '../../shared/components/card-seven';
import PbmSaleService from '../../services/pbmSaleService';
import { showToast } from '../../shared/utils/util';

export class CardSevenContainer extends Component {
  title = 'Portal da Drogaria';

  static propTypes = {
    showSeven: PropTypes.bool,
    showSevenSelector: PropTypes.func.isRequired,
    hideSevenSelector: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    showSeven: false,
  }

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.props.MessageBus.Subscribe('pos.seven.showStartScreen', this.handleShow);
    this.props.MessageBus.Subscribe('pos.seven.showItems', this.handleShowItens);
    this.props.MessageBus.Subscribe('pos.seven.closeItems', this.handleClose);
    this.props.MessageBus.Subscribe('pos.seven.error', this.handleError);
    this.props.MessageBus.Subscribe('pos.seven.cancelationSuccess', this.handleCancelSuccess);
    if (window.desktopApp) {
      window.desktopApp.subscribe("machine.devices.seven.retrieveFile", this.handleFileReturn);
      window.desktopApp.subscribe("machine.devices.seven.showError", this.handleError);
    }
    this.state = {
      authorizationCode: "",
      showItems: false,
      payload: null
    }
  }

  handleCancelSuccess = (ch, payload) => {
    showToast({
      type: 3,
      title: 'Portal da Drogaria',
      html: 'Transação Portal da Drogaria anulada com sucesso!',
      hideAfterMilliseconds: 5000
    });
  }

  handleFileReturn = (ch, payload) => {
    PbmSaleService.sevenProcessRecoveredFile(payload);
  }

  handleShowItens = (ch, payload) => {
    this.handleShow();
    this.setState({
      showItems: true,
      payload: payload
    });
  }

  handleError = (ch, payload) => {
    this.context.showMessage(payload);
  }

  handleChange = (e) => {
    this.setState({
      authorizationCode: e.target.value
    });
  };

  handleConfirm = () => {
    PbmSaleService.sevenRecoverAuthorization(this.state.authorizationCode)
  }

  handleConfirmItems = () => {
    PbmSaleService.sevenApplyProducts(this.state.payload)
    this.handleClose();
  }

  handleShow = (ch, payload) => {
    this.props.showSevenSelector();
  }

  handleClose() {
    this.props.hideSevenSelector();
    this.setState({
      authorizationCode: "",
      showItems: false,
      payload: null
    });
  }

  render() {
    const { showSeven } = this.props;

    return (
      <Fragment>
        {showSeven && (
          <CardSeven
            handleClose={this.handleClose}
            handleChange={this.handleChange}
            handleConfirm={this.handleConfirm}
            showItems={this.state.showItems}
            payload={this.state.payload}
            handleConfirmItems={this.handleConfirmItems}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showSeven: shouldShow(state, SEVEN),
});

const mapDispatchToProps = dispatch => ({
  showSevenSelector: () => dispatch(showCard(SEVEN, null, false)),
  hideSevenSelector: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(CardSevenContainer)));