import React from 'react';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { LicenseModal } from '../../shared/components/modules';
import AuthService from '../../services/authService';

export class LicenseModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props.MessageBus.Subscribe('pos.license.showLicenseMessage', this.openModal);

        this.state = {
            notCloseble: false,
            hasLink: false,
            allowUnlock: true,
            message: null,
            title: null
        }
    }

    init = () => {
        this.setState({
            notCloseble: false,
            hasLink: false,
            allowUnlock: true,
            message: null,
            title: null
        });
    }

    openModal = (ch, payload) => {
        this.setState({
            ...payload
        });
    }

    handleClose = () => {
        this.init();
        if (this.state.callback) {
            this.state.callback();
        }
    }

    sucessOnUnlock = () => { }
    errorOnUnlock = () => { }

    handleUnlock = () => {
        AuthService.licenseUnlock()
            .then(() =>
                this.sucessOnUnlock(),
                this.errorOnUnlock()
            );

        this.init();
        if (this.state.callback) {
            this.state.callback();
        }
    }

    render() {
        return (
            this.state.message &&
            <LicenseModal
                true
                handleClose={this.handleClose}
                message={this.state}
                handleUnlock={this.handleUnlock}
            ></LicenseModal>
        )
    }
}

export default withMessageBus(getContext(LicenseModalContainer));