import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card } from "../modules";
import { Button } from "../modules";
import {
  BarcodeCheckPreOrderWrapper,
  CheckPreOrderWrapper,
  HeaderCheckPreOrder,
  SectionCheckPreOrder,
  StatusTag,
  TableBodyPreOrder,
  TableCellPreOrder,
  TableCheckPreOrderWrapper,
  TableHeaderPreOrder,
  TableRowPreOrder,
} from "./style";
import moment from "moment";
import { currencyString } from "../../utils/masks";
import BarcodeIcon from "../../../assets/images/barcode.svg";
import { showToast } from "../../utils/util";

const CardCheckPreOrder = (props) => {
  const {
    preOrder,
    focusBarcode,
    handleClose,
    handleCheck,
    handleRestartPreOrder,
    handleConfirm,
  } = props;

  const [productBarcode, setProductBarcode] = useState("");
  const [productQuantity, setProductQuantity] = useState(1);

  const barcodeRef = useRef(null);
  const quantityRef = useRef(null);
  const confirmRef = useRef(null);

  useEffect(() => {
    barcodeRef.current && barcodeRef.current.focus();
  }, [preOrder, focusBarcode]);

  const handleChangeBarcode = (e) => {
    let value = e.target.value;
    let numberValue = value.replace(/\D+/g, "");

    if (numberValue.length <= 13) setProductBarcode(numberValue);
  };

  const handleChangeQuantity = (e) => {
    let value = e.target.value;
    let numberValue = value.replace(/\D+/g, "");

    if (numberValue.length <= 7) setProductQuantity(numberValue);
  };

  const showBarcode = (barcode) => {
    let length = barcode.length;
    if (preOrder?.isBlindCheck && length === 13) {
      return barcode.substr(length * -1, 10) + "***";
    }
    return barcode;
  };

  const handleFocusBarcode = (e) => {
    if (e.key === "Enter") {
      barcodeRef.current.focus();
    }
  };

  const handleNavigation = (e) => {
    if (e.key === "Tab") e.preventDefault();

    const previous = e.key === "ArrowUp" || e.key === "ArrowLeft";
    const next =
      e.key === "ArrowDown" || e.key === "ArrowRight" || e.key === "Tab";
    const name = e.target.name;

    if (name === "productQuantity" && next) {
      barcodeRef.current.focus();
    }
    if (name === "barcode" && previous) {
      quantityRef.current.focus();
    }
    if (
      name === "barcode" &&
      e.key === "Tab" &&
      preOrder?.isChecked &&
      !productBarcode
    ) {
      confirmRef.current.focus();
    }
  };

  const handleCheckBarcode = (e) => {
    if (e.key === "Enter") {
      if (!productBarcode && preOrder?.isChecked) {
        confirmRef.current.focus();
        return;
      }
      if (!productBarcode) {
        showToast({
          type: 1,
          title: "CONFERÊNCIA DE PRÉ-VENDA",
          html: "Informe um código de barras para conferência!",
          hideAfterMilliseconds: 3000,
        });
        barcodeRef.current.focus();
        return;
      }

      let object = {
        orderId: preOrder.orderId,
        barcode: productBarcode,
        quantity: productQuantity,
      };

      handleCheck(object);
      setProductQuantity(1);
      setProductBarcode("");
      barcodeRef.current.focus();
    }
  };

  const statusCheck = {
    0: { status: "Aguardando", bg: "#FFD880" },
    1: { status: "Conferido", bg: "#26B483" },
    2: { status: "Não exigido", bg: "#A5DFDF" },
  };

  return (
    <Card
      autoScroll={false}
      title="CONFERÊNCIA DE PRÉ-VENDAS"
      id="cardCheckPreOrder"
      closeEnable
      handleClose={handleClose}
    >
      <CheckPreOrderWrapper>
        <SectionCheckPreOrder
          display="flex"
          justify="space-between"
          minHeight="60px"
          margin="0 0 20px 0"
        >
          <HeaderCheckPreOrder>
            <span className="header-label">Identificador</span>
            <span className="header-data">{preOrder?.recoveryId}</span>
          </HeaderCheckPreOrder>
          <HeaderCheckPreOrder>
            <span className="header-label">Cliente</span>
            <span className="header-data">
              {preOrder?.customerName || "Consumidor"}
            </span>
          </HeaderCheckPreOrder>
          <HeaderCheckPreOrder>
            <span className="header-label">Vendedor</span>
            <span className="header-data">{preOrder?.sellerName}</span>
          </HeaderCheckPreOrder>
          <HeaderCheckPreOrder>
            <span className="header-label">Data da criação</span>
            <span className="header-data">
              {moment(preOrder?.createdAt).format("DD/MM/YYYY HH:mm")}
            </span>
          </HeaderCheckPreOrder>
          <HeaderCheckPreOrder>
            <span className="header-label">Valor</span>
            <span className="header-data">
              {currencyString(preOrder?.preOrderTotal)}
            </span>
          </HeaderCheckPreOrder>
        </SectionCheckPreOrder>

        <span className="title-barcode-wrapper">ESCANEAMENTO DE PRODUTOS</span>
        <SectionCheckPreOrder
          padding="10px 20px"
          bg="#f4f8fb"
          borderRadius="4px"
          margin="0 0 20px 0"
        >
          <BarcodeCheckPreOrderWrapper>
            <div className="quantity-group">
              <label htmlFor="productQuantity">Quantidade</label>
              <input
                type="text"
                ref={quantityRef}
                id="productQuantity"
                name="productQuantity"
                value={productQuantity}
                onChange={(e) => handleChangeQuantity(e)}
                onKeyPress={(e) => handleFocusBarcode(e)}
                onKeyDown={(e) => handleNavigation(e)}
              />
            </div>
            <div className="barcode-group">
              <div className="barcode-input">
                <img src={BarcodeIcon} alt="Icone código de barras" />
                <input
                  type="text"
                  ref={barcodeRef}
                  id="barcode"
                  name="barcode"
                  value={productBarcode}
                  placeholder="Busque pelo código de barras"
                  onChange={(e) => handleChangeBarcode(e)}
                  onKeyPress={(e) => handleCheckBarcode(e)}
                  onKeyDown={(e) => handleNavigation(e)}
                />
              </div>
            </div>
          </BarcodeCheckPreOrderWrapper>
        </SectionCheckPreOrder>

        <hr className="section-separator" />

        <SectionCheckPreOrder
          padding="10px 20px"
          bg="#f4f8fb"
          borderRadius="4px"
          margin="0 0 20px 0"
        >
          <TableCheckPreOrderWrapper>
            <TableHeaderPreOrder>
              <TableRowPreOrder padding="0 0 5px 0">
                <TableCellPreOrder minWidth="140px">
                  Código de barras
                </TableCellPreOrder>
                <TableCellPreOrder minWidth="280px">
                  Descrição
                </TableCellPreOrder>
                <TableCellPreOrder justify="center">
                  Quantidade da venda
                </TableCellPreOrder>
                <TableCellPreOrder justify="center">
                  Quantidade conferida
                </TableCellPreOrder>
                <TableCellPreOrder justify="center">
                  Status da conferência
                </TableCellPreOrder>
              </TableRowPreOrder>
            </TableHeaderPreOrder>
            <TableBodyPreOrder>
              {preOrder?.items.map((item) => {
                return (
                  <TableRowPreOrder padding="8px 0">
                    <TableCellPreOrder minWidth="140px">
                      {showBarcode(item.barcode)}
                    </TableCellPreOrder>
                    <TableCellPreOrder minWidth="280px">
                      {item.description}
                    </TableCellPreOrder>
                    <TableCellPreOrder justify="center">
                      {preOrder?.isBlindCheck ? "-" : item.orderQuantity}
                    </TableCellPreOrder>
                    <TableCellPreOrder justify="center">
                      {item.checkedQuantity || "-"}
                    </TableCellPreOrder>
                    <TableCellPreOrder justify="center">
                      <StatusTag bg={statusCheck[item.status].bg}>
                        {statusCheck[item.status].status}
                      </StatusTag>
                    </TableCellPreOrder>
                  </TableRowPreOrder>
                );
              })}
            </TableBodyPreOrder>
          </TableCheckPreOrderWrapper>
        </SectionCheckPreOrder>

        <SectionCheckPreOrder
          display="flex"
          justify="flex-end"
          minHeight="80px"
        >
          <Button className="btn-outline" onClick={handleRestartPreOrder}>
            REINICIAR CONFERÊNCIA
          </Button>
          <Button
            ref={confirmRef}
            className={!preOrder?.isChecked ? "disabled" : "btn-purple"}
            disabled={!preOrder?.isChecked}
            onClick={handleConfirm}
          >
            PROSSEGUIR
          </Button>
        </SectionCheckPreOrder>
      </CheckPreOrderWrapper>
    </Card>
  );
};

CardCheckPreOrder.propTypes = {
  preOrder: PropTypes.object.isRequired,
  focusBarcode: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleRestartPreOrder: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default CardCheckPreOrder;
