/* eslint-disable no-useless-constructor */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../card';
import { Button } from '../button';
import { DatePicker } from '../date-picker';
import { FormControl } from '../form-control';
import { FarmaciaPopularWrapper } from './style';
import { Select } from '../select';
import { MaskCPF } from '../../utils/modules';
import Input from '../input';
import FarmaciaPopularStatusMessage from '../farmacia-popular-status-message';
import moment from 'moment'
import { Table } from 'react-bootstrap';
import { currencyString } from '../../utils/masks';

export class FarmaciaPopular extends Component {
  constructor(props) {
    super(props);
  }
  customCellrender = (item) => (
    <input type="number" min="1"
      value={item.prescriptionQuantity}
      onChange={(e) => this.updateForecastEnding(e, item)}
    />
  );

  getHeaders = () => {
    return (
      <tr>
        <th>PRODUTO</th>
        <th>PREÇO</th>
        <th>QUANTIDADE</th>
        <th>APRESENTAÇÃO</th>
        <th>DOSAGEM DIÁRIA</th>
        <th>DATA TÉRMINO</th>
      </tr>
    );
  }
  getRow = (doc) => {
    return (
      <tr>
        <td>{doc.name}</td>
        <td>{currencyString(doc.price)}</td>
        <td>{doc.quantityRetailer}</td>
        <td>{doc.pillQuantityPresentation}</td>
        <td>{this.customCellrender(doc)}</td>
        <td>{moment(doc.forecastEnding).format('DD/MM/YYYY')}</td>
      </tr>
    );
  }

  updateForecastEnding = (e, item) => {
    item.prescriptionQuantity = parseInt(e.target.value);
    if (item.prescriptionQuantity > 0) {
      var days = (item.quantityRetailer * item.pillQuantityPresentation) / item.prescriptionQuantity;
      item.forecastEnding = moment().add(days, 'days');
    }
    else {
      item.forecastEnding = moment();
    }
    this.forceUpdate();
  }

  dispensationFieldsRef = {
    patientCpf: React.createRef(),
    doctorCrm: React.createRef(),
    doctorCrmUf: React.createRef(),
    dateRecipe: React.createRef(),
  }

  componentDidMount() {
    this.dispensationFieldsRef.patientCpf.current.focus();
  }

  validateAllFields() {
    let valid = true;

    Object.keys(this.dispensationFieldsRef).forEach((key) => {
      const v = this.dispensationFieldsRef[key].current.validate();
      valid = valid && v;
    });

    return valid;
  }

  handleKeyUp(e) {
    var maxLength = e.target.value.length < 14 ? e.target.value.length : 14;
    let value = e.target.value.substring(0, maxLength);
    // keyCode 8 refers to backspace
    if (e.keyCode !== 8) {
      value = MaskCPF(value);
    } else if (value.length === 14) {
      value = MaskCPF(e.target.value);
    }
    else if (e.keyCode === 8 && value.length === 1) {
      value = '';
    }
    e.target.value = value;
  }

  render() {
    const {
      title,
      dispensation,
      confirm,
      options,
      close,
      back,
      showFpMessage,
      fpMessage,
      fpHeaderMessage
    } = this.props;
    return (
      <Fragment>
        <Card title={title} closeEnable handleClose={close}>
          <FarmaciaPopularWrapper>
            <div className="dispensation">
              <h3>Dados de Dispensação</h3>
              <div className="custom-form-row">
                <FormControl
                  required
                  label="CPF Paciente"
                  object={dispensation}
                  field="patientCpf"
                  flex="0.5"
                  ref={this.dispensationFieldsRef.patientCpf}
                  component={Input}
                  onKeyUp={this.handleKeyUp}
                />
                <FormControl
                  required
                  label="Inscrição CR"
                  object={dispensation}
                  field="doctorCrm"
                  flex="0.3"
                  ref={this.dispensationFieldsRef.doctorCrm}
                />
                <FormControl
                  required
                  label="UF"
                  component={Select}
                  object={dispensation}
                  field="doctorCrmUf"
                  flex="0.2"
                  propToShow="name"
                  propToValue="name"
                  ref={this.dispensationFieldsRef.doctorCrmUf}
                  options={options?.states}
                />
                <FormControl
                  required
                  label="Data Receita"
                  component={DatePicker}
                  object={dispensation}
                  field="dateRecipe"
                  flex="0.2"
                  initialvalue={new Date(dispensation.dateRecipe)}
                  ref={this.dispensationFieldsRef.dateRecipe}
                />
              </div>
            </div>
            <Table className="table-sm" responsive >
              <thead>
                {this.getHeaders()}
              </thead>
              <tbody>
                {this.props.products.map(this.getRow)}
              </tbody>
            </Table>
            <div className="custom-form-row actions">
              <Button className="btn-outline" handleClick={confirm}>AUTORIZAR</Button>
            </div>
          </FarmaciaPopularWrapper>
        </Card>
        <FarmaciaPopularStatusMessage
          visible={showFpMessage}
          confirm={confirm}
          close={close}
          back={back}
          message={fpMessage}
          headerMessage={fpHeaderMessage}></FarmaciaPopularStatusMessage>
      </Fragment>
    );
  }
}

FarmaciaPopular.propTypes = {
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  dispensation: PropTypes.objectOf(PropTypes.any).isRequired,
  confirm: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  close: PropTypes.func.isRequired,
};

export default FarmaciaPopular;
