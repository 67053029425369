import styled from 'styled-components';

export const MessageDetail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  width: auto;

  i {
    display: block;
    font-size: 50px;
    flex: 0 0 40px;
    margin-right: 8px;
  }

  i[icon="help"]
  {
    color:#6A5A8C;
  }

  i[icon='warning']
  {
    color: #ffb200;
  }
  i[icon='done']
  {
    color: green;
  }
  i[icon='error']
  {
    color: #bd362f;
  }
  i[icon='cached']
  {
    color: #6A5A8C;
    animation: spin 1s linear infinite; 
  }
  
  p  {
    flex: 1 1 80%;
    margin: 0px;
    padding: 0px;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
  }
  div.moreinfo 
  {
      flex 1 1 500px;
      font-size: 14px;
      font-family: NeoSansProRegular;
      border: 1px solid #dfdfdf;
      padding: 10px;
      background: #f8f8f8;
      margin-top: 10px;
      font-weight: bold;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 560px;
  }
  div.moreinfo p
  {
      font-size: 14px;
      font-weight: normal;
      margin-top: 5px;
      line-height: 20px;
      word-break: break-word;
  }
  div.close
  {
    margin-top: 5px;
    text-align: center;
    width: 100%;
  }

  @-moz-keyframes spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
  }

  @-webkit-keyframes spin {
      100% {
          -webkit-transform: rotate(-360deg);
      }
  }

  @keyframes spin {
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
`;
