import React from 'react';
import { currencyMask } from '../../utils/masks';
import { Table } from '../table2/table';
import { Card } from '../modules';
import { ProductListWrapper } from './style';
import Button from '../button';

export class PbmProductList extends React.Component {

  componentDidMount() {
    const btn = document.querySelector('#btn-confirm-pbm-product-list');
    btn && btn.focus();
  }

  render() {
    const {
      title,
      handleClose,
      confirmProducts,
      noDiscountTotValue,
      discountTotValue,
      totalQuantity
    } = this.props;

    return (
      <Card id="card-pbm-product-list" alternativeCloseEnable title={title} handleClose={handleClose}>
        <Table data={this.props.products} id="pbm-table" config={this.props.tableConfig} />
        <ProductListWrapper>
          <span>
            <strong>TOTAL</strong>
          </span>
          <span>
            <span>
              {`Qtd: `}
            </span>
            <strong>{totalQuantity}</strong>
          </span>
          <span>
            {`Valor Bruto: `}
            <strong>{currencyMask(noDiscountTotValue)}</strong>
          </span>
          <span>
            {`Valor Liquido: `} 
            <strong>{currencyMask(discountTotValue)}</strong>
          </span>
        </ProductListWrapper>
        <div>
          <Button
              style={{
                width: "120px",
                height: "50px",
                float: "right",
                marginTop: "10px"
              }}
              id="btn-confirm-pbm-product-list"
              className="btn-outline btn-custom btn-purple"
              type="button"
              handleClick={confirmProducts}
            >
            CONFIRMAR
          </Button>
        </div>
      </Card>
    );
  }
}