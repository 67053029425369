/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { CashOpen } from '../../shared/components/modules';
import SaleService from '../../services/saleService';
import PaymentService from '../../services/paymentService';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { connect } from 'react-redux';
import OrderService from '../../services/orderService';
import {
  closeAllCards,
  IDENTIFY_SELLER,
  shouldShow,
  getPayload,
  showCard,
} from '../../redux/actions/routerAction';

export class IdentifySeller extends Component {
  constructor(props) {
    super(props);
    this.saleService = new SaleService();
    this.paymentService = new PaymentService();
    this.orderService = new OrderService();

    this.props.MessageBus.Subscribe(
      'pos.identifySeller',
      (channel, payload) => {
        this.props.openIdentifySeller(payload);
      }
    );

    this.sellerIdentified = this.sellerIdentified.bind(this);

    this.subscription = null;
  }

  handlerSubmit = (values) => {
    this.subscription = this.props.MessageBus.Subscribe(
      'pos.seller.identified',
      this.sellerIdentified
    );
    this.saleService
      .getSeller(values || 'null')
      .catch((error) => this.context.showError(error));
  };

  sellerIdentified() {
    if (this.subscription) {
      this.props.MessageBus.Unsubscribe(this.subscription);
    }
    if (this.props.payload && this.props.payload.farmaciaPopular) {
      let service = new FarmaciaPopularService();
      service.Start().catch((error) => this.context.showError(error));
    } else if (this.props.posType == 2) {
      this.paymentService
        .startPayment(this.props.order.order.paymentEndpoint)
        .catch((error) => this.context.showError(error));
    } else if (this.props.posType == 1) {
      const alternate = this.props.order.order.paymentEndpoint;
      if (alternate) {
        this.paymentService
          .startPayment(this.props.order.order.paymentEndpoint)
          .catch((error) => this.context.showError(error));
        return;
      }
      this.orderService
        .donePreOrder(this.props.order.order.paymentEndpoint)
        .catch((error) => this.context.showError(error));
    }
    this.props.closeIdentifySeller();
  }

  render() {
    return (
      this.props.showIdentifySeller && (
        <CashOpen
          title='Identificação do Operador'
          message={this.props.payload && this.props.payload.message}
          handleClose={() => this.props.closeIdentifySeller()}
          needUser
          needAmount={false}
          handleClick={this.handlerSubmit}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  showIdentifySeller: shouldShow(state, IDENTIFY_SELLER),
  payload: getPayload(state, IDENTIFY_SELLER),
  order: state.order,
  posType: state.posType.posType,
});

const mapDispatchToProps = (dispatch) => ({
  openIdentifySeller: (payload) =>
    dispatch(showCard(IDENTIFY_SELLER, payload, false, true)),
  closeIdentifySeller: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(IdentifySeller)));
