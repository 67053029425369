import React from 'react';
import { Icon } from './style';
import { showToast } from '../../utils/util';

const CopyToClipboard = (props) => {
  const createElementAndCopy = (string) => {
    if (string === undefined || null) {
      return;
    }

    const el = document.createElement('textarea');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    
    showToast({
      type: 0,
      title: props.title ?? 'Área de transferência',
      html: 'Copiado com sucesso!',
      hideAfterMilliseconds: 2000,
    });
  }

  return (
      <Icon 
        className="material-icons-outlined" 
        color={props.color}
        size={props.size}
        onClick={() => createElementAndCopy(props.content)}
      >
        content_copy
      </Icon>
  )
}

export default CopyToClipboard;