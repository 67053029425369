import styled from 'styled-components';

const MainWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  height: calc(100vh - 170px);
`;

export default MainWrapper;
