import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaymentPix } from '../../shared/components/modules';
import { showCard, closeAllCards, PAYMENT_PIX, shouldShow } from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import PaymentService from '../../services/paymentService';

export class PaymentPixContainer extends Component {
  constructor(props) {
    super(props);
    this.state = ({ pixyPayload: 0 });

    this.onHandleClickPay = this.onHandleClickPay.bind(this);

    this.props.MessageBus.Subscribe('payment.pix.inputValue', this.eventReturn.bind(this));
    this.props.MessageBus.Subscribe('pos.order.deleted', this.close.bind(this));
    this.paymentService = new PaymentService();
  }

  close(canal, payload) {
    this.props.closePaymentPix();
  }

  eventReturn(canal, payload) {
    this.setState({
      pixPayload: payload.amount,
      url: payload.confirmUrl,
      tef: false,
      pix: true,
    });

    this.props.openPaymentPix();
  }

  onHandleClickPay(amount) {
    this.paymentService.setPixInput(amount, this.state.url)
      .catch(err => this.context.showError(err));
  }

  handleClose = () => {
    this.props.closePaymentPix();
    this.paymentService.backPayment()
      .catch(err => this.context.showError(err));
  }

  render() {
    const pixPayload = this.state.pixPayload;
    return (
      <div>
        {this.props.showPaymentPix && this.state.pix && (
          <PaymentPix
            handleClickPay={this.onHandleClickPay}
            amount={pixPayload}
            handleClose={this.handleClose}
            image="./assets/images/payment-types/pix.png"
          />
        )}
      </div>
    );
  }

}

const mapStateToProps = state => ({
  payment: state.payment,
  showPaymentPix: shouldShow(state, PAYMENT_PIX)
});

const mapDispatchToProps = dispatch => ({
  openPaymentPix: payload => dispatch(showCard(PAYMENT_PIX, payload)),
  closePaymentPix: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PaymentPixContainer)));
