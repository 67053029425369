import styled from 'styled-components';
import theme from '../../../themes/default';

export const SaleFinishWrapper = styled.div`
    color: ${theme.text.color};
    text-align: center;
    margin-top: 3%;

    img{
        width: 20%;
        height: 50%;
    }

    .button img {
        width: 100%;
        height: 100%;
    }

    .button {
        width: 60px;
        height: 50px;
        margin-left: 0px;
    }

    .button i:hover, .button:focus i{
        color: #ffb200; 
    }

    .button i {
        color: #5b2e90; 
        font-size: 40px;
    }

    .sub-title {
        margin-top: 1%;
        font-size: 18px;
        font-weight: bold;
        text-transform: initial;
        color: #dcdcdc;
    }

    .paymentLinkInfo {
        margin-top: 1%;
        font-size: 18px;
        font-weight: bold;
        text-transform: initial;       
    }

    .button .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #5b2e90;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }          
    
    .button:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    .button {
        background-color: transparent;     
        border: none;  
        position: relative;
        display: inline-block;
        border-bottom: 0px dotted black;
    }
`;

export default SaleFinishWrapper;
