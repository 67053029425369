import styled from "styled-components";

import theme from "../../../themes";

const { palette } = theme.theme2;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    margin-right: 15px;
  }

  label {
    margin-bottom: 3px;
    white-space: nowrap;
    line-height: 20px;
  }

  input,
  select {
    padding: 0 10px;
    width: 100%;
    height: 40px;
    border: 1px solid ${palette.border};
    border-radius: 3px;
    box-sizing: border-box;
    background: #fff;
  }

  .error {
    color: red;
    font-size: 12px;
  }
`;

export const MaxLengthCounter = styled.span`
  color: ${palette.primary};
  font-weight: 600;
  position: absolute;
  right: 0;
  bottom: -20px;
`;
