import AbstractService from './abstractService';

class DocumentPrintService extends AbstractService {
    constructor() {
        super('/DocumentPrint');
    }

    start = () => this.invokeAction('/start');
    back = () => this.invokeAction('/cancel');
    find = (query) => this.invokeAction('/find', query);
    print = (data) => this.invokeAction('/print', data);
}

export default new DocumentPrintService();