import React, { Fragment } from 'react'
import { Card } from '../modules'
import { TefMessagesWrapper } from './style'
import { currencyMask } from '../../utils/masks';
import { Button } from "../modules";
import QRCode from 'qrcode.react';

const Messages = ({ items, messages, imgUrl, handleClose, wallets, showBtnClose, showQRCode, qrCodeOrPixHandle }) => {
  return (
    <Card title='Autorização' alternativeCloseCancel={showBtnClose} subTitle="Detalhes da autorização" urlIcone={imgUrl} handleClose={handleClose} >
      <TefMessagesWrapper>
        {items.coupon && items.amount && items.installments && items.installmentAmount ? (<div className="box-header">
          <div className="title-header"> Cupom: <span className="value-header">{items.coupon}</span> </div>
          <div className="title-header"> Total: <span className="value-header">{currencyMask(items.amount)}</span></div>
          <div className="title-header"> Nº de Parcelas: <span className="value-header">{items.installments}</span></div>
          <div className="title-header"> Total por parcela: <span className="value-header">{currencyMask(items.installmentAmount)}</span></div>
        </div>
        ) : null}
        <div className="list-itens">
          {messages.qrcode ? (
            <Fragment>
              <p>{messages.value}</p>
              <div className="divQR">
                {showQRCode ?
                  <QRCode
                    value={messages.qrcode}
                    size={250}
                    className="qrcode"
                  />
                  :
                  messages.qrcode
                }
              </div>
              <div className="row-wallets">
                {wallets.map(w => <div className="column-wallets">
                  <img className="img-wallets" src={w} alt="Wallet"></img>
                </div>)}
              </div>
              {showQRCode ?
                <Button className="btn-purple" type="submit" onClick={qrCodeOrPixHandle}>
                  Pix Copia e Cola
                </Button>
                :
                <Button className="btn-purple" type="submit" onClick={qrCodeOrPixHandle}>
                  QR Code
                </Button>
              }
            </Fragment>
          ) : (
            <p>{messages}</p>
          )}
        </div>
      </TefMessagesWrapper>
    </Card>
  )
}
export default Messages