import React from 'react';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { InfoModal } from '../../shared/components/modules';

export class InfoModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.props.MessageBus.Subscribe('front.infoModal.show', this.openModal);

    this.state = {
      message: null,
      type: null,
      moreInfo: ''      
    }
  }

  init = () => {
    this.setState({
      message: null,
      type: null,
      moreInfo: ''
    });
  }

  openModal = (ch, payload) => {
    this.setState({
      ...payload
    });
  }

  handleClose = () => {
    this.init();
    if(this.state.callback)
    {
      this.state.callback();
    }
  }

  render() {
    return (
      this.state.message &&
        <InfoModal
          visible
          handleClose={this.handleClose}
          message={this.state}
      ></InfoModal>
    )
  }
}


export default withMessageBus(getContext(InfoModalContainer));