import styled from 'styled-components';

export const AutoCompleteWrapper = styled.div`
  position: relative;

  .auto-complete {
    overflow: hidden;
    flex: 1;
  }

  .prefix {
    position: absolute;
    padding-top: 4px;
    padding-left: 5px;
  }

  .control {
    ${(props) => (props.hasPrefix ? 'padding-left: 30px !important;' : '')}
  }

  .clear {
    position: absolute;
    right: 0px;
    top: 4px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

export const AutoCompleteItemsWrapper = styled.div`
  font-family: Dosis;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  margin-top: 35px;
  flex-direction: column;
  background-color: white;
  width: 100%;
  z-index: 30;
  overflow: visible;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  &.showInfo {
    margin-top: 80px;
  }

  .item-list {
    height: 100%;
  }
`;

const focusStyle = `
  &:hover, &:focus {
    cursor:pointer;
    color:black;
    font-weight: bold;
    background-color: #FFB200;
    tab-index: 5;
  }
`;

export const AutoCompleteItemsHeaderWrapper = styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  padding: 0 10px;

  ${focusStyle}
`;

export const DefaultItemRender = styled.span`
  display: flex;
  flex: 0 0 50px;
  height: 50px;
  border-top: 1px solid #e4e0e0;
  align-items: center;
  padding-left: 6px;

  ${focusStyle}
`;
