/* eslint-disable react-hooks/exhaustive-deps, eqeqeq */

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { Container } from './styles';

export default function PopupMenu({
  item,
  handleRecovery,
  sendWhatsApp,
  sendEmail,
  copyToClipBoard,
  handleRefreshPaymentLink,
  handleSendToPosConnect,
  handleCancel,
  onClick,
  posType,
  openTop,
}) {
  const menuRef = React.createRef();

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClick(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleClick(event) {
    onClick(event);
  }

  return (
    <>
      {item.deliveryInfo.deliveryStatus != 6 && (
        <Container
          innerRef={menuRef}
          onClick={handleClick}
          openTop={openTop ? `${-90}px` : ''}
        >
          {((item.deliveryInfo.deliveryStatus == 2 && posType == '2') ||
            (posType == '1' && !item.paymentLinkInfo)) && (
              <Button
                type='button'
                className='button button-popup-delivery'
                onClick={() => handleRecovery(item)}
              >
                {posType == 1 ? (
                  <>
                    <img src='./assets/icons/editar.svg' alt='Editar pedido' />
                    Editar pedido
                  </>
                ) : (
                  <>
                    <img src='./assets/icons/nf.svg' alt='Emitir nota fiscal' />
                    Emitir nota fiscal
                  </>
                )}
              </Button>
            )}
          {item.deliveryInfo.deliveryStatus == 1 && (
            <>
              <Button
                data-testid='delivery-whatsapp'
                type='button'
                className='button button-popup-delivery'
                onClick={() =>
                  sendWhatsApp(
                    `55${item.deliveryInfo.deliveryCustomer.phone}`,
                    `${item.paymentLinkInfo.paymentLinkValues.accessUrl}`
                  )
                }
              >
                <img src='./assets/icons/whatsapp.svg' alt='Whatsapp' />
                Link via WhatsApp
              </Button>
              <Button
                data-testid='delivery-email'
                type='button'
                className='button button-popup-delivery'
                onClick={() =>
                  sendEmail(
                    item.deliveryInfo.deliveryCustomer.email,
                    `${item.paymentLinkInfo.paymentLinkValues.accessUrl}`
                  )
                }
              >
                <img src='./assets/icons/email.svg' alt='Email' />
                Link via E-mail
              </Button>
              <Button
                data-testid='delivery-copy'
                type='button'
                className='button button-popup-delivery'
                onClick={() =>
                  copyToClipBoard(
                    item.paymentLinkInfo.paymentLinkValues.accessUrl
                  )
                }
              >
                <img src='./assets/icons/copiar.svg' alt='Whatsapp' />
                Copiar Link
              </Button>
            </>
          )}
          {item.deliveryInfo.deliveryStatus == 11 && (
            <Button
              type='button'
              className='button button-popup-delivery'
              onClick={() => handleSendToPosConnect(item)}
            >
              <img src='./assets/icons/link.svg' alt='Enviar para PosConnect' />
              Enviar para PosConnect
            </Button>
          )}
          {item.deliveryInfo.deliveryStatus == 7 && (
            <Button
              type='button'
              className='button button-popup-delivery'
              onClick={() => handleRefreshPaymentLink(item)}
            >
              <img src='./assets/icons/link.svg' alt='Gerar Link' />
              Gerar Link
            </Button>
          )}
          <Button
            className='cancel button-popup-delivery'
            type='button'
            onClick={() => handleCancel(item)}
          >
            <img src='./assets/icons/x.svg' alt='Cancelar o pedido' />
            Cancelar o pedido
          </Button>
        </Container>
      )}
    </>
  );
}

PopupMenu.propTypes = {
  item: PropTypes.shape({
    deliveryInfo: PropTypes.shape({
      deliveryCustomer: PropTypes.shape({
        deliveryCustomerAddress: PropTypes.shape({
          street: PropTypes.string,
          neighborhood: PropTypes.string,
        }),
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      deliveryStatus: PropTypes.string,
    }),
    paymentLinkInfo: PropTypes.shape({
      paymentLinkValues: PropTypes.shape({
        accessUrl: PropTypes.string,
      }),
    }),
    selected: PropTypes.bool,
    createdAt: PropTypes.string,
    currentSellerName: PropTypes.string,
  }).isRequired,
  handleRecovery: PropTypes.func.isRequired,
  sendWhatsApp: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  copyToClipBoard: PropTypes.func.isRequired,
  handleRefreshPaymentLink: PropTypes.func.isRequired,
  handleSendToPosConnect: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
