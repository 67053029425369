import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectCustomer } from '../../redux/actions/custumerAction';
import {
  showCard,
  closeAllCards,
  CUSTOMER,
  shouldShow,
  CUSTOMER_REGISTRATION,
  FINANCIAL_DOCUMENT_RECEIPTMENT,
  getPayload,
} from '../../redux/actions/routerAction';
import { updateCustomer } from '../../redux/modules/customerRegistration/duck';
import { CustomerService } from '../../services/customerService';
import SaleService from '../../services/saleService';
import CardCustomer from '../../shared/components/card-customer';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';

export class CardCustomerConteiner extends Component {
  static propTypes = {
    showCustomer: PropTypes.bool,
    selectCustomer: PropTypes.func.isRequired,
    showCustomerSelector: PropTypes.func.isRequired,
    hideCustomerSelector: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    showCustomer: false,
  };

  constructor(props) {
    super(props);

    this.customerService = new CustomerService();
    this.saleService = new SaleService();

    this.handleClose = this.handleClose.bind(this);
    this.executeQuery = this.executeQuery.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.close = this.close.bind(this);
    this.selectCustomer = this.selectCustomer.bind(this);
  }

  componentDidMount() {
    this.props.MessageBus.Subscribe(
      'pos.customer.setCustomer',
      this.props.showCustomerSelector
    );
    this.props.MessageBus.Subscribe('pos.customer.back', this.close);
    this.props.MessageBus.Subscribe('pos.customer.identified', this.close);
    this.props.MessageBus.Subscribe(
      'pos.customer.identifyFailed',
      this.handleCloseShowError.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.order.updated',
      this.selectCustomer.bind(this)
    );
  }

  componentWillUnmount() {}

  close() {
    this.props.hideCustomerSelector();
  }

  handleClose() {
    if (this.props.initialQuery.source === 'sale') {
      this.customerService
        .backCustomerSelection()
        .catch((err) => this.context.showError(err));
    } else if (
      this.props.initialQuery.source === 'financial-document-receiptment'
    ) {
      this.props.showFinancialDocumentReceiptment();
    }
  }

  handleClick() {
    const localCustomerService = new CustomerService();
    localCustomerService.showCustomerRegistration();
  }

  handleCloseShowError(event, payload) {
    this.context.showMessage(payload && payload.reason);
  }

  executeQuery(filter, page, limit) {
    return this.customerService.executeQuery(filter, page, limit);
  }

  selectCustomer(event, payload) {
    const param = payload.customer && {
      name: payload.customer.name,
      referenceId: payload.customer.referenceId,
    };

    if (this.props.showCustomer) {
      this.close();
    }

    this.props.selectCustomer(param);
  }

  handleSelect = (selected) => {
    if (this.props.initialQuery.source === 'sale') {
      this.insertSelectedCustomerSale(selected);
    } else if (
      this.props.initialQuery.source === 'financial-document-receiptment'
    ) {
      this.props.initialQuery.onSelect(selected);
    }
  };

  insertSelectedCustomerSale = (selected) => {
    if (selected && selected.id) {
      if (this.props.initialQuery.source === 'sale') {
        this.saleService.notifySelectCustomer(
          selected.id,
          selected.referenceId
        );
      }
    }
  };

  handleEdit = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = {
      referenceId: item.referenceId,
      cpf: item.cpf,
      name: item.name,
      phoneNumber: item.phoneNumber[0],
      email: item.email,
      city: item.address[0]?.city,
      state: item.address[0]?.state,
      number: item.address[0]?.number,
      street: item.address[0]?.street,
      zipCode: item.address[0]?.zipCode,
      neighborhood: item.address[0]?.neighborhood,
      complement: item.address[0]?.complement,
    };
    this.props.editCustomer(payload);
  };

  render() {
    const { showCustomer } = this.props;

    return (
      <Fragment>
        {showCustomer && (
          <CardCustomer
            title={
              this.props.initialQuery.source === 'sale'
                ? 'ADICIONAR CONSUMIDOR'
                : 'PESQUISAR CONSUMIDOR'
            }
            onRowSelect={this.handleSelect}
            handleClose={this.handleClose}
            handleClick={this.handleClick}
            onEditClick={this.handleEdit}
            executeQuery={this.executeQuery}
            initialQuery={this.props.initialQuery}
            isOnline={this.props.isOnline}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showCustomer: shouldShow(state, CUSTOMER),
  initialQuery: getPayload(state, CUSTOMER),
  isOnline: state.pdvContingency.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
  showCustomerSelector: () =>
    dispatch(
      showCard(
        CUSTOMER,
        {
          query: '',
          source: 'sale',
          onSelect: (selected) => {
            this.insertSelectedCustomerSale(selected);
          },
        },
        false,
        true
      )
    ),
  hideCustomerSelector: () => dispatch(closeAllCards()),
  selectCustomer: (selectedCustomer) =>
    dispatch(selectCustomer(selectedCustomer)),
  editCustomer: (payload) => {
    dispatch(updateCustomer(payload));
    dispatch(showCard(CUSTOMER_REGISTRATION, null, true, true));
  },
  showFinancialDocumentReceiptment: () =>
    dispatch(showCard(FINANCIAL_DOCUMENT_RECEIPTMENT, null, true, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CardCustomerConteiner)));
