import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from './../modules';
import { PeriodWraper } from './period.style';
import { FormControl } from '../form-control';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { showToast } from '../../utils/util';
import moment from 'moment';

export class Period extends Component {

  static propTypes = {
    object: PropTypes.objectOf(PropTypes.any),
    labelStart: PropTypes.string,
    labelEnd: PropTypes.string,
    fieldStart: PropTypes.string,
    fieldEnd: PropTypes.string,
    requiredStart: PropTypes.bool,
    requiredEnd: PropTypes.bool,
    initialValueStart: PropTypes.objectOf(PropTypes.any),
    initialValueEnd: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    handleKeyDown: PropTypes.func,
    autoFocus: PropTypes.bool
  }
  
  static defaultProps = {
    object: null,
    labelStart: 'Data Inicial',
    labelEnd: 'Data Final',
    requiredStart: false,
    requiredEnd: false,
    fieldStart: 'startDate',
    fieldEnd: 'endDate',
    initialValueStart: null,
    initialValueEnd: null,
    className: '',
    handleKeyDown: null,
    autoFocus: false
  }

  constructor(props) {
    super(props);
    
    this.state = {
      errorMessageStart: null,
      errorMessageEnd: null
    }
  }

  focus = () => {
    this.startRef.focus();
  }

  validate = () => {
    let valid = this.startRef.validate() && this.endRef.validate();
    if(!valid) return false;

    const initialDate = this.startRef.getValue()
    const endDate = this.endRef.getValue();

    if (moment(initialDate).isAfter(moment(endDate))) {
      if(this.props.toastError) {
        showToast({
          title: 'Filtro inválido',
          type: 2,
          message: 'A data inicial não pode ser maior que a data final.',
          hideAfterMilliseconds: 4000
        });
      } else {
        this.setState({
          errorMessageEnd: 'A data inicial não pode ser maior que a data final.'
        });
      }
      return false
    }
    this.setState({
      errorMessageEnd: ''
    });
    return true;
  }

  getValue = () => {
    return{
      start:this.startRef.getValue(),
      end:this.endRef.getValue()
    }
  }

  isValidDate = () => {
    return this.startRef.isValidDate() && this.endRef.isValidDate();
  }

  handleStartChange = (e) => {
    this.props.object[this.props.fieldStart] = e;
    this.state.errorMessageEnd && this.validate();
    this.forceUpdate();
  }

  handleEndChange = (e) => {
    this.props.object[this.props.fieldEnd] = e;
    this.state.errorMessageEnd && this.validate();
    this.forceUpdate();
  }

  render() {
    const {
      labelStart,
      labelEnd,
      fieldStart,
      fieldEnd,
      initialValueStart,
      initialValueEnd,
      requiredStart,
      requiredEnd,
      object,
      flex,
      bootstrap,
      className
    } = this.props;

    const {
      errorMessageStart,
      errorMessageEnd
    } = this.state;

    return (bootstrap ?
      <Fragment>
        <Form.Group as={Col} className={className}>
          <Form.Label>Data inicial</Form.Label>
          <DatePicker 
            id="fieldStart"
            ref={ref => this.startRef = ref}
            className="form-control"
            onChange={this.handleStartChange}
            value={this.props.object[this.props.fieldStart]}
            selected={this.props.object[this.props.fieldStart]}
            handleKeyDown={this.props.handleKeyDown}
            autoFocus={this.props.autoFocus}
          />
        </Form.Group>
        <Form.Group as={Col} className={className}>
          <Form.Label>Data Final</Form.Label>
          <DatePicker
            id="fieldEnd"
            ref={ref => this.endRef = ref}
            className="form-control"
            onChange={this.handleEndChange}
            value={this.props.object[this.props.fieldEnd]}
            selected={this.props.object[this.props.fieldEnd]}
            handleKeyDown={this.props.handleKeyDown}
          />
        </Form.Group>
      </Fragment>
      :
      <PeriodWraper flex={flex} className="period-date">
        <FormControl
          ref={ref => this.startRef = ref}
          required={requiredStart}
          label={labelStart}
          component={DatePicker}
          object={object}
          field={fieldStart}
          onChange={() => this.state.errorMessageEnd && this.validate()}
          flex="0.5"
          selected={initialValueStart}
          errorMessage={errorMessageStart}
          handleKeyDown={this.props.handleKeyDown}
          autoFocus={this.props.autoFocus}
        />
        <FormControl
          ref={ref => this.endRef = ref}
          required={requiredEnd}
          label={labelEnd}
          component={DatePicker}
          onChange={() => this.state.errorMessageEnd && this.validate()}
          object={object}
          field={fieldEnd}
          flex="0.5"
          selected={initialValueEnd}
          errorMessage={errorMessageEnd}
          handleKeyDown={this.props.handleKeyDown}
        />
      </PeriodWraper>
    );
  }
}
