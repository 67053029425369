import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialVisibleState = {
  onlineSat: false,
  satFiscalType: false
};

const userSettingsReducer = createReducer(initialVisibleState, {
[types.CHECK_STATUS_SAT]: (state, action) => ({
    ...state,
    onlineSat: action.payload,
    satFiscalType: true
  })
});

export default userSettingsReducer;