import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  connections: [],
};

export const TYPE_ADD_CONNECTION = '@CONNECTION/ADD';
export const TYPE_REMOVE_FIRST_CONNECTION = '@CONNECTION/REMOVE_FIRST';
export const TYPE_REMOVE_CONNECTION = '@CONNECTION/REMOVE';
export const TYPE_CLEAR_CONNECTIONS = '@CONNECTION/CLEAR';

export const addConnection = createAction(
  TYPE_ADD_CONNECTION,
  ({ id, name = '', timeout = 65000 }) => ({
    id,
    name,
    timeout,
    startTime: new Date(),
  })
);

export const removeFirstConnection = createAction(TYPE_REMOVE_FIRST_CONNECTION);

export const removeConnection = createAction(TYPE_REMOVE_CONNECTION);

export const clearConnections = createAction(TYPE_CLEAR_CONNECTIONS);

const reducer = handleActions(
  {
    [TYPE_ADD_CONNECTION]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.connections.push(payload);
      }),

    [TYPE_REMOVE_FIRST_CONNECTION]: (state) =>
      produce(state, (draft) => {
        draft.connections.splice(0, 1);
      }),
    [TYPE_REMOVE_CONNECTION]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.connections = draft.connections.filter(
          (connection) => connection.id !== payload
        );
      }),
    [TYPE_CLEAR_CONNECTIONS]: (state) =>
      produce(state, (draft) => {
        draft.connections = [];
      }),
  },
  defaultState
);

export default reducer;
