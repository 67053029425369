import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialVisibleState = {
  visible: false,
  message: 'Processando solicitação',
  moreInfo: '',
  messageType: 0,
  callback: null
};

const modalMessageReducer = createReducer(initialVisibleState, {
  [types.MODAL_MESSAGE_SHOW]: (state, action) => {
    return {
      visible: true,
      callback: action.callback
    }
  },
  [types.MODAL_MESSAGE_CHANGE]: (state, action) => {
    return {
      visible: true,
      messageType: action.payload.messageType,
      message: action.payload.message,
      moreInfo: action.payload.moreInfo,
      callback: state.callback
    }
  },
  [types.MODAL_MESSAGE_HIDE]: (state, action) => {
    return {
      visible: false,
    }
  }
});

export default modalMessageReducer;
