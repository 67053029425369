// Declare private attributes
const scrollIsGreatherPercent = Symbol('scrollIsGreatherPercent');
const bindFunctions = Symbol('bindFunctions');
const validate = Symbol('validate');
const setParamsToInstance = Symbol('setParamsToInstance');
const hasNextPage = Symbol('hasNextPage');

// Declare private functions
const waitingLastQuery = Symbol('waitingLastQuery');
const curPage = Symbol('curPage');

export class LazyTableService {
  /**
   * When the data is  not returned on 10 seconds, no  message will be  throwed, but the query will be unlocked
   *
   * ######### avaliable configurations ############
   * {
   *   lazy: (page, limit) => Promise<any>    !Required
   *   limitPerPage: number                      !default 200
   *   reloadInPercent: number (between 0 - 1)   !used only for infinite scroll default 0.8
   * }
   */
  [waitingLastQuery] = false;

  // infinite scroll can consider theres already a page loaded on screen
  // so the next search (when scroll hits the bottom) will go as page 2
  [curPage] = 2;

  [hasNextPage] = true;

  constructor(configuration) {
    this[bindFunctions].bind(this)();

    this[validate](configuration);

    this[setParamsToInstance](configuration);
    this.setHasNextPage = this.setHasNextPage.bind(this);
  }

  setHasNextPage(has) {
    this[hasNextPage] = has;
  }

  [bindFunctions]() {
    this.listenScroll = this.listenScroll.bind(this);
    this[setParamsToInstance] = this[setParamsToInstance].bind(this);
  }

  [setParamsToInstance] = (configuration) => {
    this.queryFn = configuration.queryFn;
    this.limit = configuration.limitPerPage || 200;
    this.reloadInPercent = configuration.reloadInPercent || 0.8;
  };

  [validate] = (configuration) => {
    if (!configuration) {
      throw Error('É obrigatório configurar o servico de carregamento lazy da tabela');
    }

    if (!configuration.queryFn) {
      throw Error('O parâmetro queryFn é obrigatório');
    }

    if (configuration.reloadInPercent && (configuration.reloadInPercent < 0.2 || configuration.reloadInPercent > 1)) {
      throw Error('O parâmetro reloadInPercent deve ser maior que 0.2 e menor que 1');
    }
  };

  [scrollIsGreatherPercent](element) {
    const a = element.scrollTop;
    const b = element.scrollHeight - element.clientHeight;
    const position = a / b;

    return position >= this.reloadInPercent;
  }

  listenScroll(event) {
    if (!event) {
      throw Error('listenScroll espera receber o parametro (scrollEvent)');
    }

    const newPosition = event.target.scrollHeight;

    const element = event.target;
    if (this[hasNextPage] && this[scrollIsGreatherPercent](element) && !this[waitingLastQuery]) {
      this[waitingLastQuery] = true;

      const response = this.queryFn(this[curPage], this.limit);

      if (!response || !response.then) {
        throw new Error('A chamada a função queryFn espera como retorno uma promise');
      }

      response.then((nextPage) => {
        this[waitingLastQuery] = false;
        this[curPage] += 1;
        this[hasNextPage] = nextPage;

        element.scrollTo(0, newPosition);

        setTimeout(() => {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
        });
      });

      this.timeout = setTimeout(() => {
        this[waitingLastQuery] = false;
      }, 10000);
    }
  }
}

export default LazyTableService;
