/* eslint-disable react-hooks/exhaustive-deps, eqeqeq */

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { Container } from './styles';

export default function PopupMenu({
  item,
  handlePrintDocument,
  onClick,
  openTop,
}) {
  const menuRef = React.createRef();

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClick(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleClick(event) {
    onClick(event);
  }

  return (
    <>
      <Container
        innerRef={menuRef}
        onClick={handleClick}
        openTop={openTop ? `${-90}px` : ''}
      >
        <Button
          className='button button-popup-document-printing'
          type='button'
          onClick={() => handlePrintDocument(item)}
        >
          <img src='./assets/icons/nf.svg' alt='Imprimir documento' />
          Imprimir
        </Button>
      </Container>
    </>
  );
}

PopupMenu.propTypes = {
  item: PropTypes.shape({
    deliveryInfo: PropTypes.shape({
      deliveryCustomer: PropTypes.shape({
        deliveryCustomerAddress: PropTypes.shape({
          street: PropTypes.string,
          neighborhood: PropTypes.string,
        }),
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      deliveryStatus: PropTypes.string,
    }),
    paymentLinkInfo: PropTypes.shape({
      paymentLinkValues: PropTypes.shape({
        accessUrl: PropTypes.string,
      }),
    }),
    selected: PropTypes.bool,
    createdAt: PropTypes.string,
    currentSellerName: PropTypes.string,
  }).isRequired,
  handleRecovery: PropTypes.func.isRequired,
  sendWhatsApp: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  copyToClipBoard: PropTypes.func.isRequired,
  handleRefreshPaymentLink: PropTypes.func.isRequired,
  handleSendToPosConnect: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
