import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { addQuantity } from '../../redux/actions/quantityAction';
import KeyboardQuantity from '../../shared/components/keyboard-quantity';

class KeyBoardQuantityContainer extends Component {
  handleClick = (qtd) => {
    this.props.addQuantity(qtd);
  }

  render() {
    return (
      <Fragment>
        {
          !this.props.quantityCurrent.quantity
          && <KeyboardQuantity
                isVisible={false}
                quantityCurrent={this.props.quantityCurrent.quantity}
                handleClick={this.handleClick} />
        }
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addQuantity: quantity => dispatch(addQuantity(quantity)),
});

const mapStateToProps = state => ({
  quantityCurrent: state.quantity,
  menu: state.administrativeMenu,
  detailsWindowIsOpened: state.order.showDetailsItem
});

export default connect(mapStateToProps, mapDispatchToProps)(KeyBoardQuantityContainer);
