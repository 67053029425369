import uuid from 'uuid/v4';

import { addConnection } from '../../redux/modules/connectionPool/duck';
import Store from '../../redux/store/index';

function addConnectionToPool(id, url) {
  const messages = {
    '/item': 'Buscando produto',
    '/identifySeller': 'Autenticando vendedor',
    '/Authentication': 'Abrindo caixa',
    '/listProductsByCriteria': 'Pesquisa de Produtos',
  };

  const name = messages[url.substr(url.lastIndexOf('/'))];
  Store.dispatch(addConnection({ id, name }));
}

function handlePost(url, body, httpAccess, showLoader = true) {
  const id = uuid();
  if (showLoader) addConnectionToPool(id, url);

  Store.dispatch({
    type: 'UPDATE_LAST_SENT_BODY',
    payload: body,
  });

  return httpAccess
    .post(url, body, id)
    .then(
      (res) => {
        if (res) {
          // the 999 is manually set in a middleware, meaning it will return a hub message, therefore
          // the frontend should not hide the loading screen until the message is received via WebSocket
          // Retorna objeto response completo quando Content-Type for application/pdf
          if (res.status === 999 || (res.headers && res.headers.has('Content-Type') && res.headers.get('Content-Type').indexOf("application/pdf" > -1)))
            return res;
          const jsonResponse = JSON.parse(res);
          return jsonResponse;
        }
        return undefined;
      },
      (err) => {
        throw err;
      }
    )
    .catch((err) => {
      throw err;
    });
}

export default handlePost;
