import React from 'react';
import { Form, Col, Row, InputGroup, Button } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import { Card } from '../modules';

export class LinxConectaPayment extends React.Component {
  constructor(props) {
    super(props);

    this.paymentFormId = React.createRef();    
  }

  render() {
    const {
      items,
      handleConfirm,
      handleClose,
      conectaInfo
    } = this.props;

    return (
      <Card title="Forma de Pagamento Conecta" closeEnable handleClose={handleClose}>
        <Row style={{paddingBottom: '15px'}}>
          <Col xs={12} md={6} lg={4}>
            <b>Nome: </b>
            <span>{conectaInfo.linxconectacartao.titulaR_NOME}</span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <b>Limite do Titular R$: </b>
            <span>{conectaInfo.linxconectacartao.titulaR_LIMITEMES}</span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <b>Limite Cartão R$: </b>
            <span>{conectaInfo.linxconectacartao.cartaO_LIMITEMES}</span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <b>Saldo Devedor R$: </b>
            <span>{conectaInfo.linxconectacartao.saldO_DEVEDOR}</span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <b>Saldo Restante R$: </b>
            <span>{conectaInfo.linxconectacartao.saldO_RESTANTE}</span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <b>Saldo de pontos: </b>
            <span>{conectaInfo.linxconectacartao.saldO_PONTOS}</span>
          </Col>
        </Row>
        <Form>
          <Form.Row>
            <Form.Group as={Col} className="col-xs-12 col-sm-12 col-md-6 col-lg">
              <Form.Label>Forma de Pagamento</Form.Label>
              <Form.Control
                required
                as="select"
                placeholder="Forma de Pagamento"
                defaultValue={items[0].codigo}
                id="paymentFormId"
                ref={this.paymentFormId}
                >
                  {this.props.items.map(p => {
                    return <option key={p.codigo} value={p.codigo}>{p.descricao}</option>
                  })}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} className="col-xs-12 col-md-6 col-lg">
              <Form.Label>Valor</Form.Label>
              <InputGroup>
                <CurrencyInput
                  id="paymentValue"
                  prefix="R$"
                  allowNegative
                  className="form-control" 
                  decimalSeparator=","
                  maxLength={11}
                  thousandSeparator="."                  
                  value={this.props.amount}
                  disabled
                  />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Button
                style={{width: '100px'}}
                onClick={() => handleConfirm(this.paymentFormId.current.value)}>
                Confirmar
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Card>
    );
  }
}

export default LinxConectaPayment;