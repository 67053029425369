import styled from 'styled-components';

import { Button as DefaultButton } from '../button';

export const Container = styled.div`
  height: 100%;

  header {
    flex: none;
    justify-content: space-between;
    padding: 0 0 1.25rem 1.25rem;
  }

  .card-body {
    padding-top: 10px;
  }

  .auto-complete {
    div {
      margin-top: 0;
    }

    .prefix {
      padding-top: 8px;
    }

    .clear {
      top: 8px;
    }
  }

  @media (max-width: 1439px) {
    .formControl {
      &:first-child {
        width: 100%;
        flex: inherit !important;
        margin-right: 0;
        margin-bottom: 10px;
      }

      width: 100%;
      flex: 1 !important;
    }
  }
`;

export const SngpcWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .lot-information {
    width: 28px;
    height: 28px;
    padding-top: 3px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;

    i {
      font-size: 20px;
    }
  }

  button {
    float: right;
    height: 50px;
    width: 200px;
  }

  .actions {
    margin-top: 20px;
    justify-content: flex-end;
  }

  .table {
    flex: none;
  }
`;

export const Footer = styled.div`
  display: flex;
`;

export const Buttons = styled.div`
  flex: 1;
  text-align: right;
`;

export const Button = styled(DefaultButton)`
  width: 150px;
  height: 40px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
  }
`;
