import React from 'react';

import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Button from '../button';
import { Container } from './styles';
import Store from '../../../redux/store';
import { blockAllShortcutsBut, unblockAllShortcuts } from '../../../redux/actions/shortcutsAction';

const customStyle = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-4px 4px 5px 1px rgba(0,0,0,0.39)',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
    minWidth: 'min-content',
    minHeight: 'min-content',
    top: '20%',
    bottom: 'unset',
    textAlign: 'center',
    maxWidth: '600px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    display: 'block',
  },
};

export default function DialogBox({
  icon,
  title,
  message,
  onNegativeButtonClick,
  negativeButtonText,
  focusOnNegativeButton,
  onNeutralButtonClick,
  neutralButtonText,
  focusOnNeutralButton,
  onPositiveButtonClick,
  positiveButtonText,
  focusOnPositiveButton,
}) {

  React.useEffect(() => {
    Store.dispatch(blockAllShortcutsBut());
    
    return () => {
      Store.dispatch(unblockAllShortcuts());
    }
  }, []);

  function handleKeyDown(event) {
    switch (event.key) {
      case 'ArrowLeft': {
        event.stopPropagation();
        if (event.target.previousSibling) event.target.previousSibling.focus();
        break;
      }
      case 'ArrowRight': {
        event.stopPropagation();
        if (event.target.nextSibling) event.target.nextSibling.focus();
        break;
      }
      default:
        break;
    }
  }
  return (
    <Modal
      isOpen
      showCloseIcon={false}
      shouldCloseOnOverlayClick
      center
      ariaHideApp={false}
      style={customStyle}
    >
      <Container data-testid="dialog-box">
        {title && (
          <h1>
            {icon && <i className="material-icons">{icon}</i>}
            {title}
          </h1>
        )}
        <p>{message}</p>
        {onNegativeButtonClick && (
          <Button
            data-testid="negative-button"
            className="btn-default btn-outline"
            type="button"
            onClick={onNegativeButtonClick}
            autoFocus={focusOnNegativeButton}
            onKeyDown={handleKeyDown}
          >
            {negativeButtonText}
          </Button>
        )}
        {onNeutralButtonClick && (
          <Button
            data-testid="neutral-button"
            className="btn-default btn-outline"
            type="button"
            onClick={onNeutralButtonClick}
            autoFocus={focusOnNeutralButton}
            onKeyDown={handleKeyDown}
          >
            {neutralButtonText}
          </Button>
        )}
        {onPositiveButtonClick && (
          <Button
            data-testid="positive-button"
            className="btn-default btn-outline"
            type="button"
            onClick={onPositiveButtonClick}
            autoFocus={focusOnPositiveButton}
            onKeyDown={handleKeyDown}
          >
            {positiveButtonText}
          </Button>
        )}
      </Container>
    </Modal>
  );
}

DialogBox.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onNegativeButtonClick: PropTypes.func,
  negativeButtonText: PropTypes.string,
  focusOnNegativeButton: PropTypes.bool,
  onNeutralButtonClick: PropTypes.func,
  neutralButtonText: PropTypes.string,
  focusOnNeutralButton: PropTypes.bool,
  onPositiveButtonClick: PropTypes.func,
  positiveButtonText: PropTypes.string,
  focusOnPositiveButton: PropTypes.bool,
};

DialogBox.defaultProps = {
  icon: null,
  onNegativeButtonClick: null,
  negativeButtonText: '',
  focusOnNegativeButton: false,
  onNeutralButtonClick: null,
  neutralButtonText: '',
  focusOnNeutralButton: false,
  onPositiveButtonClick: null,
  positiveButtonText: '',
  focusOnPositiveButton: false,
};
