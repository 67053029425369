import React, { useEffect, useState } from "react";

import { Span } from './validation-errors-styled';

interface IValidationErrors {
    fieldNameId: string | undefined;
    fieldValue: any;
    typeError: 'required' | 'money';
    onChangeEvent: any;
    touched?: boolean;
    errorMessage?: string;
}

function useVerifyError(validation: IValidationErrors) {
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        verify();
    }, [validation.fieldValue]);

    const handleOnChangeEvent = (value: boolean) => {
        validation.onChangeEvent(value);
    }

    const verify = () => {
        if (validation.typeError === 'required') {
            let result = validation.fieldValue == 0 || validation.fieldValue == undefined;

            setErrorMessage(
                result ? 'Campo obrigatório!' : ''
            );

            handleOnChangeEvent(result);
        }

        if (validation.typeError === 'money') {
            let result = validation.fieldValue == 0 || validation.fieldValue == undefined || validation.fieldValue == '0,00';

            setErrorMessage(
                result ? 'Valor informado deve ser maior que 0,00.' : ''
            );

            handleOnChangeEvent(result);
        }
    }

    return errorMessage;
}

export function verifyErrorInContainerDom(idContainer: string) {
    let container = document.getElementById(idContainer) as HTMLElement;

    let errors: boolean = false;

    if (container)
        errors = container.querySelectorAll('.error').length > 0;

    if (!errors) 
        errors = container.querySelectorAll('.has-error').length > 0;
        
    return errors;
}

export function ValidationErrors(validation: IValidationErrors) {
    const errorMessage = useVerifyError(validation);

    return (
        <>
            {errorMessage && validation.touched && <Span>{errorMessage} </Span>}
        </>
    )
}