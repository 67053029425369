import React, { Component } from 'react';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { SelectPaymentType } from '../../shared/components/select-payment-type';
import PaymentService from '../../services/paymentService';
import getShortcut, { PAYMENT_FORMS } from './../../keyboardShortcuts.js';
import { ShortcutHint } from './../../shared/components/shortcut-hint';
import { connect } from 'react-redux';
import { CASH_OPEN, shouldShow } from '../../redux/actions/routerAction';

class TypePaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.paymentList = this.paymentList.bind(this);
    this.clickFormPayment = this.clickFormPayment.bind(this);

    this.paymentService = new PaymentService();

    this.state = {
      formPayment: [],
      isToggleOnOption: false,
      selectedPaymentMethodName: null,
      isInside: false,
    };

    this.props.MessageBus.Subscribe(
      'pos.formOfPayments.list',
      this.paymentList.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.order.updated',
      this.recoveryPaymentList.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'sale.done',
      this.eventSaleFinishReturn.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.order.deleted',
      this.eventSaleFinishReturn.bind(this)
    );

    document.body.addEventListener('click', this.clickOutsideCloseMenu);
  }

  eventSaleFinishReturn() {
    this.setState({ selectedPaymentMethodName: null });
  }

  clickOutsideCloseMenu = ({ path }) => {
    if (!path) return;

    const inSideList = path.some(
      (el) => el.classList && el.classList.contains('menu-area')
    );
    const inTitle = path.some(
      (el) => el.classList && el.classList.contains('title-select')
    );

    this.setState({ isInside: inSideList });
    if (!inSideList && !inTitle) {
      this.setState({
        isToggleOnOption: false,
        icon: true,
      });
    }
  };

  clickFormPayment = (e) => {
    this.paymentService.selectFormOfPayment(e.target.id);
  };

  paymentList(channel, payload) {
    const listFormPayment = payload.map((item) => ({
      id: item.id,
      refId: item.referenceId,
      name: item.name,
    }));
    this.setState(
      {
        formPayment: listFormPayment,
        isToggleOnOption: true,
      },
      this.openOptions
    );
  }

  openOptions = () => {
    const container = this.selectRef.querySelector('.menu-area');
    if (!container) return;

    const options = Array.from(container.querySelectorAll('span'));

    if (options && options.length > 0) {
      options[0].focus();
    }

    container.addEventListener(
      'keyup',
      (e) => {
        switch (e.key) {
          case 'ArrowUp':
            if (e.target.previousSibling) {
              e.target.previousSibling.focus();
            }
            break;
          case 'ArrowDown':
            if (e.target.nextSibling) {
              e.target.nextSibling.focus();
            }
            break;
          case 'Escape':
            this.setState({
              isToggleOnOption: !this.state.isToggleOnOption,
            });
            break;
          default:
            break;
        }
      },
      true
    );
  };

  recoveryPaymentList(channel, payload) {
    if (!payload.paymentMethod) {
      this.setState({
        selectedPaymentMethodName: null,
      });
      return;
    }
    this.setState({
      selectedPaymentMethodName: payload.paymentMethod.paymentMethodName,
      isToggleOnOption: false,
    });
  }

  handleShouldOpen = () => {
    const shouldOpen = !this.state.isToggleOnOption;
    if (!shouldOpen) {
      this.setState({ isToggleOnOption: shouldOpen });
      return;
    }

    this.paymentService.getFormOfPaymentList("pos.formOfPayments.list");
  };

  render() {
    return (
      <SelectPaymentType
        clickFormPayment={this.clickFormPayment}
        listFormPayment={this.state.formPayment}
        handleClickToogleMenu={this.handleShouldOpen}
        isToggleOnOption={this.state.isToggleOnOption}
        selectedPaymentMethodName={this.props.selectedPaymentMethodName}
        ref={(ref) => (this.selectRef = ref)}
        disabled={this.props.showCashOpen}
      >
        <ShortcutHint
          shortcut={getShortcut(PAYMENT_FORMS)}
          activateShortcut={this.handleShouldOpen}
        />
      </SelectPaymentType>
    );
  }
}

const mapStateToProps = (state) => ({
  showCashOpen: shouldShow(state, CASH_OPEN),
  selectedPaymentMethodName: state.order && state.order.order && state.order.order.paymentMethod && state.order.order.paymentMethod.paymentMethodName
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(TypePaymentContainer)));
