import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  closeAllCards,
  CASH_CLOSE,
  shouldShow,
  getPayload,
  showCard,
} from '../../redux/actions/routerAction';
import * as cashDeskServices from '../../services/cashDeskServices';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import NewCashClose from '../../shared/components/new-cash-close/new-cash-close';
import { ReportCashClose } from '../../shared/components/new-cash-close/report-cash-close/report-cash-close';
import { showToast } from '../../shared/utils/util';

export class CashCloseContainer extends Component {
  static propTypes = {
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
    closeCashClose: PropTypes.func,
  };

  static defaultProps = {
    closeCashClose: null,
  };

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.cashCloseStart = this.cashCloseStart.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.eventFailedReturn = this.eventFailedReturn.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCloseReportCashClose = this.handleCloseReportCashClose.bind(
      this
    );
    this.setMessageSuccess = this.setMessageSuccess.bind(this);

    this.props.MessageBus.Subscribe('pos.close.start', this.cashCloseStart);
    this.props.MessageBus.Subscribe(
      'pos.cash.close.success',
      this.handleSuccess
    );
    this.props.MessageBus.Subscribe(
      'pos.close.cancel',
      this.props.closeCashClose
    );
    this.props.MessageBus.Subscribe('pos.close.fail', this.eventFailedReturn);
    this.props.MessageBus.Subscribe(
      'pos.cash.close.parcial.success',
      this.handleClose
    );

    this.props.MessageBus.Subscribe('pos.barcode.login.success', () => { });

    this.state = {
      disableConfirm: false,
      systemValues: {
        money: 100,
        withDraw: 50,
        creditCard: 50,
        debitCard: 0,
        creditSale: 0,
        agreementSale: 0,
        qrLinx: 0,
        pix: 0,
      },
      userValues: {
        money: 100,
        withDraw: 0,
        creditCard: 100,
        debitCard: 0,
        creditSale: 0,
        agreementSale: 0,
        qrLinx: 0,
        pix: 0,
      },
      showReportCashClose: false,
    };
  }

  eventFailedReturn(channel, payload) {
    showToast({
      type: 2,
      title: `FECHAMENTO DE CAIXA`,
      html:
        payload && payload.message
          ? payload.message
          : 'Usuário não autorizado!',
      hideAfterMilliseconds: 5000,
    });
  }

  cashCloseStart(channel, payload) {
    this.props.cashCloseBoxShow(payload);
  }

  close(barCodes = []) {
    cashDeskServices.cashCloseCancel(barCodes);
  }

  handleSuccess(channel, payload) {
    this.setState({
      ...this.state,
      systemValues: payload.systemValues,
      userValues: payload.userValues,
      showReportCashClose: true,
    });
  }

  setMessageSuccess(type, title, message) {
    showToast({
      type: type,
      title: title,
      html: message,
      hideAfterMilliseconds: 5000,
    });
  }

  handleClose() {
    this.setMessageSuccess(
      0,
      'CONFERÊNCIA PARCIAL DE CAIXA',
      'Conferência parcial de caixa realizado com sucesso!'
    );

    this.props.closeCashClose();
  }

  handleClick(closeCashDeskValues, parcial) {
    cashDeskServices.cashClose(closeCashDeskValues, parcial);
  }

  handleCloseReportCashClose() {
    this.setState({
      ...this.state,
      showReportCashClose: false,
    });
  }

  render() {
    const { showCashClose, paymentTypesLoad } = this.props;
    const { systemValues, userValues, showReportCashClose } = this.state;

    if (showReportCashClose)
      return (
        <ReportCashClose
          {...paymentTypesLoad}
          systemValues={systemValues}
          userValues={userValues}
          configurations={paymentTypesLoad.configurations}
          handleConfirm={this.handleCloseReportCashClose}
        />
      );

    return (
      showCashClose &&
      paymentTypesLoad && (
        <NewCashClose
          handleConfirm={this.handleClick}
          handleCancel={this.close}
          propsToCashClose={paymentTypesLoad}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  showCashClose: shouldShow(state, CASH_CLOSE),
  paymentTypesLoad: getPayload(state, CASH_CLOSE),
});

const mapDispatchToProps = (dispatch) => ({
  cashCloseBoxShow: (payload) =>
    dispatch(showCard(CASH_CLOSE, payload, true, false, true)),
  closeCashClose: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CashCloseContainer)));
