import React from 'react';
import { Modal } from '../modal2';
import { FormControl, Button } from '../modules';
import { Select } from '../select';


function PbmSelectPatient(props) {
    const {
        formRefs,
        form,
        handleClose,
        handleConfirm,
        patients
    } = props;

    function getFirstValue(array) {
        if (array && array.length > 0)
            return array[0];
        return null
    }

    return (
        <Modal
            title='SELECIONAR PACIENTE'
            ref={formRefs.modalRef}
            opened={false}
            closable={true}
            onClickClose={handleClose}
            className="col-lg-4 col-md-7 col-sm-8 col-xs-8"
            style={{
                whiteSpace: 'nowrap'
            }}
        >
            <Modal.Body>
                <div className="custom-form-row">
                    <FormControl
                        required
                        label="Paciente"
                        component={Select}
                        ref={formRefs.selectedPatient}
                        object={form}
                        field="patient"
                        flex="1"
                        propToShow="name"
                        options={patients}
                        initialvalue={getFirstValue(patients)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    id="btn-confirm"
                    className="btn-default btn-purple"
                    type="button"
                    onClick={handleConfirm}
                >
                    Confirmar
                </Button>

                <Button
                    id="btn-cancel"
                    className="btn-default btn-outline"
                    type="button"
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PbmSelectPatient;