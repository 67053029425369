import React from 'react'
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { ConfigTef } from '../../shared/components/config-tef'
import { CONFIG_TEF, shouldShow, closeCard } from '../../redux/actions/routerAction';
import { showToast } from '../../shared/utils/util';

export class ConfigTefContainer extends React.Component {
  constructor(props) {    
    super(props);    
    this.state = {
      visible: false,
    }; 
  }

  handleClose = () => {        
    this.props.close()    
  }

  handleChange = (e) => {            
    this.props.config.tef[e.target.id] = e.target.value 
    this.forceUpdate();
  }

  handleToggleChange = (e) => {
    this.props.config.tef.hasPinPad = e.target.checked 
    this.forceUpdate();
  };

  handleConfirm = () => {    
    if (window.desktopApp)
    {    
      window.desktopApp.publish('machine.devices.config.saveConfig', this.props.config);    
      showToast({
        type: 3,
        title: 'Configurações Terminal',
        html: 'Novas configurações só farão efeito após reiniciar o sistema.',
        hideAfterMilliseconds: 5000
      });   
      this.handleClose();
    } else
    {  
      showToast({
        type: 2,
        title: 'Configurações Terminal',
        html: 'Configurações nao podem ser alteradas a partir do navegador',
        hideAfterMilliseconds: 5000
      });   
    }
  }

  render() {
    return (
        this.props.visible && <ConfigTef 
            handleClose={this.handleClose}    
            form={this.props.config.tef}
            handleChange={this.handleChange} 
            handleConfirm={this.handleConfirm} 
            handleToggleChange={this.handleToggleChange}  
        >                
        </ConfigTef>
    );
  }
}
const mapStateToProps = (state) => ({
    visible: shouldShow(state, CONFIG_TEF),
    config: state.config 
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeCard(CONFIG_TEF))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(ConfigTefContainer)));
