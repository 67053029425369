import React, { useEffect, useState } from 'react';

import Card from '../card';

import { Field } from '../field/field';
import { ButtonComponent } from '../button-component';

import { Container, Row, Table } from './objectpro-styled';
import { IHeader } from '../../../shared/interfaces/header';
import MessageBusService from '../../../services/MessageBusService';
import ObjectProNewRegisterComponent from './cadastro/objectpro-new-register.component';
import { IRegisterObjectPro } from '../../interfaces/register-objectpro';
import { showToast } from '../../utils/util';
import { ShowMessage } from '../show-message/show-message';

interface IObjectProComponent {
  eventclickSearch: (cpfCartao: string) => any;
  cancel: () => any;
  getCep: (cep: string) => any;
  saveCliente: (cliente: IRegisterObjectPro) => any;
  selectClient: (cliente: IRegisterObjectPro) => any;
  clientesList?: IRegisterObjectPro[];
}

export default function ObjectProComponent(props: IObjectProComponent) {
  const [search, setSearch] = useState<string>('');
  const [clientesList, setClientesList] = useState<IRegisterObjectPro[]>(
    props.clientesList ?? []
  );
  const [newRegister, setNewRegister] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [
    registerObject,
    setRegisterObject,
  ] = useState<IRegisterObjectPro | null>(null);

  const headers: IHeader[] = [
    {
      field: 'nome',
      label: 'Nome',
      show: true,
    } as IHeader,
    {
      field: 'cartao',
      label: 'Cartão',
      show: true,
    } as IHeader,
    {
      field: 'empresa',
      label: 'Empresa',
      show: true,
    } as IHeader,
    {
      field: 'cpf',
      label: 'Cpf',
      show: true,
    } as IHeader,
    {
      field: 'pontos',
      label: 'Pontos',
      show: true,
    } as IHeader,
    {
      field: 'status',
      label: 'Status',
      show: true,
    } as IHeader,
    {
      field: 'lgpd',
      label: 'Lgpd',
      show: true,
    } as IHeader,
  ];

  useEffect(() => {
    const showObjectPro = MessageBusService.GetInstance().Subscribe(
      'pos.objectpro.consultarCliente.success',
      (ch, payload) => loadClients(ch, payload)
    );

    const objectProCreate = MessageBusService.GetInstance().Subscribe(
      'pos.objectpro.createOrder.success',
      () => {
        props.cancel();
      }
    );

    const objectProCreateError = MessageBusService.GetInstance().Subscribe(
      'pos.objectpro.createOrder.error',
      callErrorMessage
    );

    const objectConsultaProNotFound = MessageBusService.GetInstance().Subscribe(
      'pos.objectpro.consultarCliente.notfound',
      (ch, payload) => {
        setShowMessage(true);
        setClientesList([]);

        if (payload) {
          let object = { ...payload };

          object.nascimento = new Date(object.nascimento);

          setRegisterObject(object);
        }
      }
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(showObjectPro);
      MessageBusService.GetInstance().Unsubscribe(objectProCreate);
      MessageBusService.GetInstance().Unsubscribe(objectProCreateError);
      MessageBusService.GetInstance().Unsubscribe(objectConsultaProNotFound);
    };
  }, []);

  function callErrorMessage(response, payload) {
    showToast({
      type: 2,
      title: `Erro ao gravar cliente`,
      html: payload.errors[0].errorMessage
        ? getMessageErrors(payload.errors[0].errorMessage)
        : payload.errors[0],
      hideAfterMilliseconds: 5000,
    });
  }

  const getMessageErrors = (errorMessage: string[]) => {
    let messageList: string[] = [];

    errorMessage.forEach((message) => {
      messageList.push(!message.includes('.') ? `${message}.` : message);
    });

    return messageList.join('<br />');
  };

  const loadClients = (event, payload) => {
    setClientesList([payload]);
  };

  const cancel = () => {
    props.cancel();
  };

  const eventClickSearch = () => {
    if (!search) {
      showToast({
        type: 1,
        title: `Atenção`,
        html: 'Informe o CPF/Cartão para continuar',
        hideAfterMilliseconds: 5000,
      });

      return;
    }

    props.eventclickSearch(search);
  };

  const novoCadastro = () => {
    setRegisterObject(null);
    setNewRegister(true);
  };

  const setNewRegisterWithObject = () => {
    setNewRegister(true);
    setShowMessage(false);
  };

  const solicitarTermo = () => {};

  const consultarStatusLgpd = () => {};

  const selectClient = (cliente: IRegisterObjectPro) => {
    props.selectClient(cliente);
  };

  if (newRegister)
    return (
      <ObjectProNewRegisterComponent
        clickSave={props.saveCliente}
        registerObject={{ ...(registerObject as IRegisterObjectPro) }}
        cancel={() => setNewRegister(false)}
        getCep={props.getCep}
      />
    );

  return (
    <>
      {!newRegister && (
        <Card
          id='card-consulta-objectpro'
          title={'OBJECTPRO - CONSULTA E CADASTRO DE CLIENTE'}
          handleClose={() => cancel()}
          closeEnable
          autoScroll
          orientationVertical={true}
          marginButtonTitle={'.5rem'}
          escAction={() => cancel()}
        >
          <Container id='container-cash-close'>
            <Row>
              <div className='flex-1' style={{ flex: '5' }}>
                <Field
                  id='search-cliente'
                  placeholder='Pesquisar o cliente pelo CPF ou pelo cartão'
                  labelText='CPF ou cartão'
                  type='text'
                  typeInput='normal'
                  showError={false}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  autoFocus={true}
                />
              </div>

              <div className='group-btn'>
                <ButtonComponent
                  className='btn-b-success'
                  title={'Localizar'}
                  onClick={eventClickSearch}
                  sizeSpan={'14px'}
                  style={{ textTransform: 'uppercase' }}
                />

                <ButtonComponent
                  className='btn-b-success'
                  title={'Cadastrar Cartão'}
                  onClick={novoCadastro}
                  style={{ width: '188px', textTransform: 'uppercase' }}
                  sizeSpan={'14px'}
                />
              </div>
            </Row>

            {clientesList.length > 0 && (
              <>
                <Row style={{ display: 'none' }}>
                  <div
                    className='group-btn'
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <ButtonComponent
                      className='btn-b-success'
                      title={'Solicitar termos LGPD'}
                      onClick={solicitarTermo}
                      style={{
                        minWidth: '188px',
                        textTransform: 'uppercase',
                        flex: '25%',
                      }}
                      sizeSpan={'14px'}
                    />

                    <ButtonComponent
                      className='btn-b-success'
                      title={'Consultar status LGPD'}
                      onClick={consultarStatusLgpd}
                      style={{
                        minWidth: '188px',
                        textTransform: 'uppercase',
                        flex: '25%',
                      }}
                      sizeSpan={'14px'}
                    />

                    <div style={{ flex: '25%' }}></div>
                  </div>
                </Row>
                <Row>
                  <Table data-testid={'table-id-objectpro'}>
                    <thead>
                      <tr>
                        {headers
                          .filter((h) => h.show)
                          .map((header, index) => {
                            return <th key={index}>{header.label}</th>;
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {clientesList.length &&
                        clientesList.map((cliente, index) => {
                          return (
                            <tr
                              key={index}
                              onDoubleClick={() => selectClient(cliente)}
                            >
                              {headers
                                .filter((h) => h.show)
                                .map((header, idxHeader) => {
                                  return (
                                    <td key={idxHeader}>
                                      {cliente[header.field]}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </>
            )}
          </Container>
        </Card>
      )}

      {showMessage && (
        <ShowMessage
          title={'Atenção!'}
          message={'Cartão não encontrado. Deseja cadastrar agora?'}
          handleClickConfirm={() => setNewRegisterWithObject()}
          handleClickCancel={() => setShowMessage(false)}
          type={'warning'}
          showBtnCancel={true}
          titleBtnCancel='Não'
          titleBtnConfirm='Sim'
        />
      )}
    </>
  );
}
