import React from 'react';

import { Formik, Form as FormFormik, Field } from 'formik';
import PropTypes from 'prop-types';
import MaskedTextInput from 'react-text-mask';

import FormControl from '../@form-control';
import DialogBox from '../dialog-box';
import { Button, Card } from '../modules';
import { Cep, CepHeader, Container } from './styles';
import SchemaValidation from './validation';

export class CustomerRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.numberRef = React.createRef();
    this.formik = React.createRef();
    this.state = { showMessage: false, message: '' };
  }
  componentDidUpdate(prevProps) {
    if (this.props.cepCallback) {
      this.numberRef.current.focus();
    }
  }

  handleSubmit = (values) => {
    if (values.referenceId === 0) delete values.referenceId;
    this.props.handleConfirmSave({
      ...values,
      cpf: values.cpf.replace(/[^0-9]+/g, ''),
      zipCode: values.zipCode ?? values.zipCode.replace(/[^0-9]+/g, ''),
      phoneNumber: values.phoneNumber?.replace(/[^0-9]+/g, ''),
    });
  };

  onIdentifyCepClick = () => {
    this.props.onIdentifyCepClick(this.formik.current.values);
  };

  handleClose = () => {
    const message =
      this.props.data.referenceId === 0
        ? 'Não foi acionado Cadastrar o cliente, deseja sair da tela?'
        : 'Não foi acionada atualização do cadastro, deseja sair da tela?';
    this.setState({ showMessage: true, message });
  };
  onChange = (e) => {
    this.props.handleChange(e.target.name, e.target.value);
  };

  requiredLabelRender = (label) => {
    return (
      <>
        <span>{label} </span>
        <span style={{ color: 'red', fontWeight: 600 }}>*</span>
      </>
    );
  };

  render() {
    const { data, handleClose } = this.props;
    const { showMessage, message } = this.state;

    return (
      <>
        <Card title="Cadastro de clientes" handleClose={this.handleClose} closeEnable>
          <Formik
            innerRef={this.formik}
            initialValues={{ ...data }}
            onSubmit={this.handleSubmit}
            validationSchema={SchemaValidation}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              this.setFieldValue = setFieldValue;
              return (
                <FormFormik>
                  <Container>
                    <div className="custom-form-row">
                      <FormControl
                        placeholder="Nome completo (nome e sobrenome)"
                        label={this.requiredLabelRender('Nome do cliente')}
                        name="name"
                        maxLength="150"
                        flex="0.6"
                        autoFocus={!this.props.cepCallback}
                        onChange={(event) => {
                          setFieldValue('name', event.target.value);
                          this.onChange(event);
                        }}
                      />
                      <FormControl label={this.requiredLabelRender('CPF')} name="cpf" flex="0.4">
                        <Field name="cpf">
                          {({ props }) => (
                            <MaskedTextInput
                              {...props}
                              placeholder="000.000.000-00"
                              onChange={(event) => {
                                setFieldValue('cpf', event.target.value);
                                event.target.name = 'cpf';
                                this.onChange(event);
                              }}
                              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                              defaultValue={values.cpf}
                              disabled={values.referenceId !== 0}
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>
                    <div className="custom-form-row">
                      <FormControl
                        placeholder="email@email.com.br"
                        label="E-mail"
                        name="email"
                        maxLength="50"
                        flex="0.6"
                        onChange={(event) => {
                          setFieldValue('email', event.target.value);
                          this.onChange(event);
                        }}
                      />

                      <FormControl label="Celular/WhatsApp" name="phoneNumber" flex="0.4">
                        <Field name="phoneNumber">
                          {({ props }) => (
                            <MaskedTextInput
                              {...props}
                              placeholder="(00) 0 0000-0000"
                              onChange={(event) => {
                                setFieldValue('phoneNumber', event.target.value);
                                event.target.name = 'phoneNumber';
                                this.onChange(event);
                              }}
                              mask={[
                                '(',
                                /\d/,
                                /\d/,
                                ')',
                                ' ',
                                /\d/,
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              defaultValue={values.phoneNumber}
                            />
                          )}
                        </Field>
                      </FormControl>
                    </div>

                    <div className="custom-form-row">
                      <Cep className="formControl">
                        <CepHeader>
                          <label htmlFor="customer-zipcode">Cep</label>
                          <button type="button" onClick={this.onIdentifyCepClick}>
                            Não sei o CEP
                          </button>
                        </CepHeader>
                        <Field name="zipCode">
                          {({ props }) => (
                            <MaskedTextInput
                              id="customer-zipcode"
                              {...props}
                              placeholder="00000-000"
                              onChange={(event) => {
                                setFieldValue('zipCode', event.target.value);
                                event.target.name = 'zipCode';
                                this.onChange(event);
                              }}
                              onBlur={this.props.handleBlurZipCode}
                              onKeyDown={this.props.handleKeyDownZipCode}
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                              defaultValue={values.zipCode}
                            />
                          )}
                        </Field>
                      </Cep>

                      <FormControl
                        placeholder="Informe o CEP"
                        label="UF"
                        name="state"
                        maxLength="2"
                        flex="0.266"
                        disabled="1"
                        onChange={(event) => {
                          setFieldValue('name', event.target.value);
                          this.onChange(event);
                        }}
                      />
                      <FormControl
                        placeholder="Informe o CEP"
                        label="Cidade"
                        name="city"
                        maxLength="100"
                        flex="0.266"
                        disabled="1"
                        onChange={(event) => {
                          setFieldValue('name', event.target.value);
                          this.onChange(event);
                        }}
                      />
                      <FormControl
                        placeholder="Bairro"
                        label="Bairro"
                        name="neighborhood"
                        maxLength="50"
                        flex="0.266"
                        onChange={(event) => {
                          setFieldValue('name', event.target.value);
                          this.onChange(event);
                        }}
                      />
                    </div>

                    <div className="custom-form-row">
                      <FormControl
                        placeholder="Endereço"
                        label="Endereço"
                        name="street"
                        maxLength="150"
                        flex="0.6"
                        onChange={(event) => {
                          setFieldValue('name', event.target.value);
                          this.onChange(event);
                        }}
                      />
                      <FormControl label="Número" name="number" flex="0.2">
                        <Field name="number">
                          {({ field }) => (
                            <input
                              autoFocus={this.props.cepCallback}
                              ref={this.numberRef}
                              type="number"
                              {...field}
                              placeholder="Número"
                              defaultValue={values.number}
                              onChange={(event) => {
                                if (event.target.value.length <= 15) {
                                  setFieldValue('number', event.target.value);
                                  event.target.name = 'number';
                                  this.onChange(event);
                                }
                              }}
                            />
                          )}
                        </Field>
                      </FormControl>
                      <FormControl
                        placeholder="Complemento"
                        label="Complemento"
                        name="complement"
                        maxLength="50"
                        flex="0.2"
                        onChange={(event) => {
                          setFieldValue('complement', event.target.value);
                          this.onChange(event);
                        }}
                      />
                    </div>

                    <Button className="btn-purple" type="submit">
                      {data.referenceId === 0 ? 'Cadastrar' : 'Atualizar Cadastro'}
                    </Button>
                  </Container>
                </FormFormik>
              );
            }}
          </Formik>
        </Card>
        {showMessage && (
          <DialogBox
            message={message}
            onNegativeButtonClick={() => this.setState({ showMessage: false })}
            negativeButtonText="Não"
            onPositiveButtonClick={handleClose}
            positiveButtonText="Sim"
            focusOnPositiveButton
          />
        )}
      </>
    );
  }
}

CustomerRegistration.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirmSave: PropTypes.func.isRequired,
  handleBlurZipCode: PropTypes.func.isRequired,
  handleKeyDownZipCode: PropTypes.func.isRequired,
  onIdentifyCepClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    referenceId: PropTypes.number,
    name: PropTypes.string,
    cpf: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    cep: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
  }).isRequired,
};

export default CustomerRegistration;
