/* eslint-disable no-sequences */

import React, { useEffect } from 'react';
import { OptionListWrapper } from './style';
import { Card } from '../modules';
import ListTefOptions from './list-tef-options';

const onFocusOnload = () => {
  var list = document.querySelector('ul#listParcel');
  var items = list.querySelectorAll('div.divList');
  items[0].focus();
}

const handleTab = e => {
  if (!e.shiftKey)
    return 1

  return -1
}

const onHandleKey = e => {
  e.preventDefault();
  var list = document.querySelector('ul#listParcel');
  var items = list.querySelectorAll('div.divList');
  var keycode = e.keyCode || e.which;
  if (keycode === 13) {
    e.preventDefault();
    e.target.click();
  }

  var codes = {
    37: -1,
    38: -1,
    39: 1,
    40: 1,
    9: handleTab(e),

  };

  for (var i = 0; i < items.length; i++) {
    items[i].count = i;
  }

  let key = codes[keycode];
  if (key) {
    var indexDiv = e.target.childNodes[0].count !== undefined ? parseInt(e.target.childNodes[0].count) : e.target.count
    if (indexDiv >= 0) {
      if (items[indexDiv + key])
        items[indexDiv + key].focus();
    }
  }
}

const TefOptions = ({ items, header, urlIcone, onHandleOption, handleClose }) => (
  useEffect(() => {
    const mainElement = document.getElementById("listParcel").firstChild;
    if (!mainElement)
      return;
    else
      onFocusOnload();

    var elems = document.getElementById('listParcel');
    elems.addEventListener('keydown', onHandleKey)

  }),
  <Card title={header} handleClose={handleClose} urlIcone={urlIcone} closeEnable>
    <OptionListWrapper id="listParcel">
      {items && items.map((item, index) => (
        <ListTefOptions key={item.key} item={item} handle={onHandleOption} />
      ))}
    </OptionListWrapper>
  </Card>
);

export default TefOptions;
