import * as types from '../actions/actionTypes';
import * as views from '../actions/views';
import createReducer from './createReducer';

function gererateInitialState() {
  const initialState = {};

  Object.keys(views).forEach((key) => {
    const view = views[key];
    initialState[view] = false;
    initialState[`${view}Payload`] = null;
  });

  return initialState;
}

export const initialState = gererateInitialState();

export const reducers = {
  [types.SHOW]: (state, { payload, card, hideCoupon, showShortCutMenu }) => ({
    ...state,
    ...initialState,
    [card]: true,
    [views.payloadName(card)]: payload,
    hideCoupon,
    showShortCutMenu: showShortCutMenu ? showShortCutMenu : false,
  }),
  [types.SHOW_WITH_OTHERS]: (
    state,
    { payload, card, hideCoupon, showShortCutMenu }
  ) => ({
    ...state,
    [card]: true,
    [views.payloadName(card)]: payload,
    hideCoupon,
    showShortCutMenu: showShortCutMenu ? showShortCutMenu : false,
  }),
  [types.SHOW_WITH_SEARCH_BAR]: (
    state,
    { payload, card, hideCoupon, showShortCutMenu }
  ) => ({
    ...state,
    ...initialState,
    [card]: true,
    [views.SEARCH_CODE]: true,
    [views.payloadName(card)]: payload,
    hideCoupon,
    showShortCutMenu: showShortCutMenu ? showShortCutMenu : false,
  }),
  [types.CLOSE_WITHOUT_HIDE_OTHERS]: (state, { card, showShortCutMenu, hideCoupon }) => ({
    ...state,
    [card]: false,
    [views.payloadName(card)]: null,
    hideCoupon: hideCoupon,
    showShortCutMenu: showShortCutMenu ? showShortCutMenu : false,
  }),
  [types.CLOSE]: (state, { card }) => ({
    ...state,
    ...initialState,
    [card]: false,
    [views.payloadName(card)]: null,
    hideCoupon: false,
    showShortCutMenu: false,
  }),
  [types.CLOSE_ALL]: (state, { payload }) => ({
    ...state,
    ...initialState,
    payload,
    hideCoupon: false,
    showShortCutMenu: true,
  }),
};

export default createReducer(initialState, reducers);
