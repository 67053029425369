import { startOfDay } from "date-fns";
import * as Yup from "yup";
import { ValidateCNPJ } from "../../utils/modules";

const defaultMessageRequired = "Campo obrigatório";
const defaultMessageNumber = "Campo somente números";

export const schema = Yup.object().shape({
  prescriber: Yup.object().shape({
    required: Yup.boolean(),
    name: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
    documentNumber: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
    documentDescription: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
    documentUF: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
  }),
  prescriberSpecialty: Yup.object().shape({
    required: Yup.boolean(),
    name: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
  }),
  prescriptionOrigin: Yup.object().shape({
    required: Yup.boolean(),
    name: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
  }),
  hospitalClinic: Yup.object().shape({
    required: Yup.boolean(),
    name: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
  }),
  pathology: Yup.object().shape({
    required: Yup.boolean(),
    name: Yup.string().when("required", {
      is: true,
      then: Yup.string().required(defaultMessageRequired),
    }),
  }),
  nextDateRequired: Yup.boolean(),
  nextDate: Yup.date()
    .min(
      startOfDay(new Date()),
      "Data próxima não pode ser menor que a data de hoje"
    )
    .when("nextDateRequired", {
      is: true,
      then: Yup.date().required(defaultMessageRequired),
    }),
  metricUnityRequired: Yup.boolean(),
  metricUnity: Yup.string().when("metricUnityRequired", {
    is: true,
    then: Yup.string().required(defaultMessageRequired),
  }),
  quantityRequired: Yup.boolean(),
  quantity: Yup.string().when("quantityRequired", {
    is: true,
    then: Yup.string().required(defaultMessageRequired),
  }),
  periodRequired: Yup.boolean(),
  period: Yup.string().when("periodRequired", {
    is: true,
    then: Yup.string().required(defaultMessageRequired),
  }),
  remarkRequired: Yup.boolean(),
  remark: Yup.string().when("remarkRequired", {
    is: true,
    then: Yup.string().required(defaultMessageRequired),
  }),
});

export const schemaNewPrescriber = Yup.object().shape({
  newPrescriber: Yup.object().shape({
    description: Yup.string().required(defaultMessageRequired),
    documentNumber: Yup.number()
      .integer()
      .typeError(defaultMessageNumber)
      .required(defaultMessageRequired),
    documentType: Yup.string().required(defaultMessageRequired),
    documentUF: Yup.string().required(defaultMessageRequired),
  }),
});
export const schemaNewPrescriberSpecialty = Yup.object().shape({
  newPrescriberSpecialty: Yup.object().shape({
    prescriberSpecialty: Yup.string().required(defaultMessageRequired),
  }),
});
export const schemaNewPrescriptionOrigin = Yup.object().shape({
  newPrescriptionOrigin: Yup.object().shape({
    prescriptionOrigin: Yup.string().required(defaultMessageRequired),
  }),
});
export const schemaNewHospitalClinic = Yup.object().shape({
  newHospitalClinic: Yup.object().shape({
    name: Yup.string().required(defaultMessageRequired),
    cnpj: Yup.string().when("emptyCnpj", {
      is: false,
      then: Yup.string().test("validateCnpj", "CNPJ inválido", (value) => {
        return ValidateCNPJ(value);
      }),
    }),
  }),
});
export const schemaNewPathology = Yup.object().shape({
  newPathology: Yup.object().shape({
    pathology: Yup.string().required(defaultMessageRequired),
  }),
});
