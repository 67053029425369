import styled, { injectGlobal } from 'styled-components';

injectGlobal`
  .ReactModal__Body--open #keybord-quantity { display: none; }
  .ReactModal__Overlay { z-index: 1000; }
`

export const KeyboardQuantityWrapper = styled.div`
  width: 270px;
  z-index: 6;
  position: absolute;
  top: 100px;
  left: 25px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    left: 50%;
    margin-left: -113px;
    vertical-align: middle;
    margin-right: 10px;
    width: 0;
    height: 0;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
  }
  .key-header {
    background-color: #fff;
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    text-transform: uppercase;
    font-size: 14px;
    display: grid;
    grid-template-columns: 220px 20px;
    .button {
      box-shadow: none;
    }
  }

  .input__container {
    box-shadow: none;
    margin-top: 0;
    padding: 10px;
    box-sizing: border-box;
    height: 70px;
    input {
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      margin: 0;
      background: #F5F5F5;
      width: 77px;
      text-align: center;
      font-size: 14px;
    }
  }

  &.keyboard--hidden { display: none; }

  button { padding: 0; }

  .button-wrapper {
    display: grid;
    grid-template-columns: 1fr;

    .content {
      padding: 0;
      margin: 0;
      width: 270px;
      justify-content: flex-start;
      align-items: center;
      display: grid;
      grid-template-columns: repeat(3, 90px);

      .button {
        width: 90px;
        height: 70px;
        border: 1px solid #ebebeb;
        font-size: 24px;
        box-shadow: none;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
    #btn-pronto{
        background-color: #5B2E90;
        height: 98%;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        color: white;
        font-size: 14px;
        padding: 20px 0;
      }

      #keybord-quantity {
        background-color: red;
      }
  }

`;

export default KeyboardQuantityWrapper;
