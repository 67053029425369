import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { OptionsList, MessageBox } from '../../shared/components/modules';
import { getContext } from '../../shared/hoc/getContext';
import { stringErrors } from '../../shared/utils/modules';
import SaleService from '../../services/saleService';
import { OrderService } from '../../services/orderService';
import PaymentService from '../../services/paymentService';
import PbmSaleService, { PBM_EPHARMA } from '../../services/pbmSaleService';
import LinxConectaService from '../../services/linxConectaService';
import DeliveryService from '../../services/deliveryService';
import * as shortcutsAction from '../../redux/actions/shortcutsAction';
import { shouldShow } from '../../redux/actions/routerAction';
import * as cashDeskServices from '../../services/cashDeskServices';
import { showInfoModal } from '../../shared/utils/util'
import getShortcut,
{
  FIRST_OPTION,
  PAY_OFF,
  SECOND_OPTION,
  THIRD_OPTION,
  PRE_SALE_LIST,
  CLOSE_REGISTER,
  NEW_SALE,
  REGISTER_PICK_UP,
  REPRINT_LAST_VOUCHER,
  OPEN_REGISTER,
  SUPPLY,
  OTHER_OPTIONS,
  LIST_AUTHORIZATIONS,
  LINX_CONECTA,
  DELIVERY_CONTROL,
  NOTES_MONITOR
} from '../../keyboardShortcuts.js';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import TefPbmSaleService from '../../services/tefPbmSaleService';
import {
  showCard,
  CASH_OPEN,
  CASH_WITH_DRAW,
  CASH_SUPPLY,
  MONITOR_NOTAS_LIST,
  PRE_ORDER_LIST,
  ORDER_LIST
} from '../../redux/actions/routerAction';
import authServices from '../../services/authService';
import FinancialDocumentService from '../../services/financialDocumentService';
import moment from 'moment';
import FarmaciaPopularStatusMessage from '../../shared/components/farmacia-popular-status-message';

// exporting due to unit test purposes
export const OPERATION_NAME_FARMACIA_POPULAR = 'Farmácia Popular';
export const OPERATION_NAME_DTEF_Pbm = 'Pbm\'s via TEF';
export const OPERATION_NAME_EPHARMA_PBM = 'ePharma';

export const OPERATION_LAST_PBM_SALE = 'REIMPRIMIR ÚLTIMA VENDA PBM';
export const OPERATION_NEW_SALE = 'NOVA VENDA';
export const OPERATION_CASH_OPEN = 'ABERTURA CAIXA';
export const OPERATION_CASH_CLOSE = 'FECHAMENTO CAIXA';
export const OPERATION_SANGRIA = 'SANGRIA';
export const OPERATION_CASH_SUPPLY = 'SUPRIMENTO';
export const OPERATION_PRE_ORDER_LIST = 'LISTAGEM DE PRÉ-VENDAS';
export const OPERATION_CANCEL_SALE = 'CANCELAMENTO DA VENDA (NFC-e/S@T)';
export const OPERATION_REPRINT_TEF = 'REIMPRIMIR COMPROVANTE TEF';
export const OPERATION_CANCEL_TEF = 'CANCELAR COMPROVANTE TEF';
export const OPERATION_MONITOR_NOTAS = 'MONITOR NOTAS FISCAIS';
export const OPERATION_PAY_OFF = 'BAIXA DE TÍTULOS';
export const OPERATION_LIST_AUTHORIZATIONS = 'Autorizações PBM';
export const OPERATION_NAME_LINX_CONECTA = 'LINX CONECTA';
export const OPERATION_NAME_DELIVERY_CONTROL = 'CONTROLE DE DELIVERY';
export const OPERATION_NAME_VIRTUAL_VISIT = 'VISITA VIRTUAL';

const pbmOptions = [
  {
    operationName: OPERATION_NAME_DTEF_Pbm,
    icoPath: './assets/images/tef.png',
    isHidden: true,
    shortKey: getShortcut(FIRST_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_NAME_FARMACIA_POPULAR,
    icoPath: './assets/images/farmacia-popular.png',
    isHidden: true,
    shortKey: getShortcut(SECOND_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_NAME_EPHARMA_PBM,
    icoPath: './assets/images/epharma.png',
    isHidden: true,
    shortKey: getShortcut(THIRD_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_LIST_AUTHORIZATIONS,
    icoPath: './assets/images/listar-pre-order.png',
    isHidden: true,
    shortKey: getShortcut(LIST_AUTHORIZATIONS),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
]

const options = [
  {
    operationName: 'LISTAGEM DE PRÉ-VENDAS',
    icoPath: './assets/images/listar-pre-order.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(PRE_SALE_LIST),
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: 'FECHAMENTO CAIXA',
    icoPath: './assets/images/cash-register.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(CLOSE_REGISTER),
    showBothPdv: false,
    enabled: true
  },
  {
    operationName: OPERATION_NEW_SALE,
    icoPath: './assets/images/cancelar-cupom.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(NEW_SALE),
    showBothPdv: true,
    enabled: true
  },
  // {
  //   operationName: OPERATION_CANCEL_SALE,
  //   icoPath: './assets/images/cancelar-cupom.png',
  //   isHidden: true,
  //   showOnCashDeskOpened: true,
  //   shortKey: getShortcut(CANCEL_SALE),
  //   showBothPdv: false,
  //   enabled: true
  // },
  {
    operationName: OPERATION_LAST_PBM_SALE,
    icoPath: './assets/images/pagamentos.png',
    isHidden: true,
    // shortKey: EventList.pbm,
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_SANGRIA,
    icoPath: './assets/images/sangria.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(REGISTER_PICK_UP),
    showBothPdv: false,
    enabled: true
  },
  {
    operationName: OPERATION_REPRINT_TEF,
    icoPath: './assets/images/pagamentos.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(REPRINT_LAST_VOUCHER),
    showBothPdv: false,
    enabled: true
  },
  {
    operationName: OPERATION_CASH_OPEN,
    icoPath: './assets/images/cash-register.png',
    showOnCashDeskOpened: false,
    shortKey: getShortcut(OPEN_REGISTER),
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_CASH_SUPPLY,
    icoPath: './assets/images/cash-hand.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(SUPPLY),
    showBothPdv: false,
    enabled: true
  },
  {
    operationName: OPERATION_CANCEL_TEF,
    icoPath: './assets/images/pagamentos.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    //shortKey: EventList.reprintLastVoucher,
    showBothPdv: false,
    enabled: true
  },
  {
    operationName: 'MONITOR NOTAS FISCAIS',
    icoPath: './assets/images/listar-pre-order.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(NOTES_MONITOR),
    showBothPdv: false,
    enabled: true
  },
  {
    operationName: OPERATION_PAY_OFF,
    icoPath: './assets/images/baixatitulos.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    shortKey: getShortcut(PAY_OFF),
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_NAME_LINX_CONECTA,
    icoPath: './assets/images/linx-conecta.png',
    isHidden: true,
    shortKey: getShortcut(LINX_CONECTA),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
  {
    operationName: OPERATION_NAME_DELIVERY_CONTROL,
    icoPath: './assets/images/delivery-control.png',
    isHidden: true,
    shortKey: getShortcut(DELIVERY_CONTROL),
    showOnCashDeskOpened: true,
    showBothPdv: true,
  enabled: true
  },

  // ESTA OPCAO DEVERA SER MANTIDA COMO A ULTIMA
  {
    operationName: OPERATION_NAME_VIRTUAL_VISIT,
    icoPath: './assets/images/visita-virtual.png',
    isHidden: true,
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true
  },
];

export class OptionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [...options],
      showMessage: false,
      pbmOptions: [...pbmOptions],
      showFpStatusMessage: false
    };

    this.handleClick = this.handleClick.bind(this);

    this.saleServices = new SaleService();
    this.paymentService = new PaymentService();
    this.orderService = new OrderService();
    this.deliveryService = new DeliveryService();

    this.props.MessageBus.Subscribe('pos.open.success', () => this.toggleMenuItems(true));
    this.props.MessageBus.Subscribe('pos.close.success', () => this.toggleMenuItems(false));
    this.props.MessageBus.Subscribe('payment.done', () => this.setState({ hideOptions: true }));
    this.props.MessageBus.Subscribe('sale.done', () => this.setState({ hideOptions: false }));
    this.props.MessageBus.Subscribe('pos.order.deleteFailed', this.orderDeletedError);


    this.hideMenuItemsPayment = this.hideMenuItemsPayment.bind(this);
    this.handleModalOpened = this.handleModalOpened.bind(this);
    this.handleModalClosed = this.handleModalClosed.bind(this);
    this.handleCancelOperation = this.handleCancelOperation.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);
    this.errorOnAuth = this.errorOnAuth.bind(this);
    this.confirmMessage = this.confirmMessage.bind(this);
    this.closeFpStatusMessage = this.closeFpStatusMessage.bind(this);

    const shortcutsToNotBlock = [];

    pbmOptions.forEach(e => {
      if (e.shortKey) shortcutsToNotBlock.push(e.shortKey.name);
    });
    options.forEach(e => {
      if (e.shortKey) shortcutsToNotBlock.push(e.shortKey.name);
    });
    this.shortcutsToNotBlock = shortcutsToNotBlock;
  }

  orderDeletedError(channel, payload) {
    var moreinfo = payload.cause.type + "<br/>" + payload.cause.message + "<br/>" + payload.cause.stackTrace;
    showInfoModal({ message: "Não foi possível iniciar uma nova venda.", type: 3, moreInfo: moreinfo })
  }

  toggleMenuItems(opening) {
    this.opening = opening;

    const pdvType = this.props.posType;
    this.setState({
      items: this.state.items.map((item) => {
        if (item.hasOwnProperty('showOnCashDeskOpened')) {
          let isShowAll = !(pdvType === '1');

          if ((!opening && pdvType === '2') === item.showOnCashDeskOpened) {
            item.isHidden = true;
          } else {
            if (isShowAll) {
              item.isHidden = false;
            } else {
              item.isHidden = !item.showBothPdv;
            }
          }
        }
        return item;
      }) || [],
      pbmOptions: this.state.pbmOptions
        .map((item) => {
          item.isHidden = !opening;
          if (item.operationName === OPERATION_NAME_FARMACIA_POPULAR) {
            item.enabled = this.props.fp.online;
            item.isHidden = !this.props.fp.retailerHasConfig || !this.props.fp.userHasConfig;
          }
          return item;
        }),
    });

    if (!opening && pdvType === '2') {
      this.props.cashOpenBoxShow();
    }
  }

  hideMenuItemsPayment(isPay) {
    if (!isPay)
      this.toggleMenuItems(true);
    else {
      this.setState({
        items: this.state.items.map((item) => {
          if (item.operationName === 'NOVA VENDA' && this.opening) {
            item.isHidden = false;
          } else {
            item.isHidden = true;
          }
          return item;
        }) || [],
      });
    }
  }

  handleClick(command) {
    switch (command) {
      case OPERATION_NAME_DTEF_Pbm:
        TefPbmSaleService.startSale();
        break;
      case OPERATION_LAST_PBM_SALE:
        TefPbmSaleService.reprintLastSalePbm();
        break;
      case OPERATION_NAME_EPHARMA_PBM:
        PbmSaleService.getInitializationData(PBM_EPHARMA);
        break;
      case OPERATION_LIST_AUTHORIZATIONS:
        PbmSaleService.openAuthorizationList();
        break;
      case OPERATION_NEW_SALE:
        this.setState({ showMessage: true });
        break;
      case OPERATION_CASH_OPEN:
        this.props.cashOpenBoxShow();
        break;
      case OPERATION_CASH_CLOSE:
        cashDeskServices.cashCloseStart()
          .catch(error => this.context.showError(error));
        break;
      case OPERATION_SANGRIA:
        this.props.cashWithdrawBoxShow();
        break;
      case OPERATION_CASH_SUPPLY:
        this.props.cashSupplyBoxShow();
        break;
      case OPERATION_PRE_ORDER_LIST:
        this.orderService.getPreOrderList()
          .catch(error => this.context.showError(error));
        this.props.preOrderListShow();
        break;
      case OPERATION_CANCEL_SALE:
        this.props.orderListShow({});
        this.GetListLastOrders();
        break;
      case OPERATION_REPRINT_TEF:
        this.props.orderListShow({ reprintTef: true });
        this.GetListLastOrders();
        break;
      case OPERATION_CANCEL_TEF:
        this.props.orderListShow({ cancelTef: true });
        this.GetListLastOrders();
        break;
      case OPERATION_NAME_FARMACIA_POPULAR:
        this.startFarmaciaPopular();
        break;
      case OPERATION_MONITOR_NOTAS:
        this.props.monitorNotasShow();
        break;
      case OPERATION_PAY_OFF:
        FinancialDocumentService.start();
        break;
      case OPERATION_NAME_LINX_CONECTA:
        LinxConectaService.listarEmpresas();
        break;
      case OPERATION_NAME_DELIVERY_CONTROL:
        this.deliveryService.deliveryShow();
        break;
      case OPERATION_NAME_VIRTUAL_VISIT:
        this.props.MessageBus.Publish('machine.browser.open', 'https://cliente.linx.com.br/visitavirtual')
        break;
      default:
        break;
    }
  }

  startFarmaciaPopular = () => {
    if (this.props.fp.online) {
      let service = new FarmaciaPopularService();
      service.Start();
    }
    else {
      this.setState({
        showFpStatusMessage: true
      });
      //this.context.showMessage('Não foi possível obter uma resposta do Farmácia Popular. Possíveis causas:<br/>- Loja sem internet<br/>- Ambiente do Programa Farmácia Popular fora do ar<br/>- Falha de comunicação entre a loja e o Programa Farmácia Popular');
    }
  }

  confirmCancel(reason) {
    if (window.desktopApp) {
      window.desktopApp.publish('machine.devices.tef.undo', '');
    }

    this.saleServices.deleteOrder(reason)
      .catch(error => this.context.showError(error));
  }

  GetListLastOrders() {
    const newDate = new Date();
    this.orderService.getListLastOrder(moment(newDate).startOf('day').toISOString(), moment(newDate).endOf('day').toISOString())
      .catch(error => this.context.showError(error));
  }

  handleModalOpened() {
    this.props.blockAllShortcutsBut(this.shortcutsToNotBlock);
  }

  handleModalClosed() {
    this.props.unblockAllShortcuts();
  }

  errorOnAuth(error) {
    if (error.status === 401)
      this.context.showMessage('Código do operador e/ou senha incorreto(s)');
    else
      this.context.showMessage(stringErrors(error))
  }

  handleCancelOperation({ operatorRefId, password, reason }) {
    if (reason.length < 15) {
      this.context.showMessage('O motivo do cancelamento deve conter entre 15 e 255 caracteres.');
      return;
    }
    authServices.authentication(operatorRefId, password)
      .then(() => this.confirmCancel(reason), this.errorOnAuth);
  }

  confirmMessage(confirm) {
    if (confirm) {
      this.confirmCancel();
    }
    this.setState({ showMessage: false })
  }
  closeFpStatusMessage() {
    this.setState({
      showFpStatusMessage: false
    });
  }

  render() {
    const { items } = this.state;
    const { cashWithdrawShow, cashSupplyShow } = this.props.administrativeMenu;
    const isCashWithdrawOrCashSupplyOpen = cashWithdrawShow || cashSupplyShow;

    return !this.state.hideOptions && !this.props.cashOpenShow && (
      <React.Fragment>
        {this.state.showMessage && (
          <MessageBox
            message="Deseja realmente iniciar uma nova venda?"
            okText="Sim"
            handleConfirm={() => this.confirmMessage(true)}
            handleCancel={() => this.confirmMessage(false)}
          />
        )}
        <OptionsList
          customClassName={isCashWithdrawOrCashSupplyOpen ? 'disabled' : ''}
          shortKey={getShortcut(OTHER_OPTIONS)}
          handleClick={this.handleClick}
          hideMenuItemsPayment={this.hideMenuItemsPayment}
          optionList={items}
          pbmOptions={pbmOptions}
          handleModalOpened={this.handleModalOpened}
          handleModalClosed={this.handleModalClosed}
          farmaciaPopularOffline={this.farmaciaPopularOffline}
        />
        <FarmaciaPopularStatusMessage visible={this.state.showFpStatusMessage} confirm={this.closeFpStatusMessage} okText="Ok" ></FarmaciaPopularStatusMessage>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  options: state.options,
  administrativeMenu: state.administrativeMenu,
  cardOpened: state.card.cardOpened,
  cashOpenShow: shouldShow(state, CASH_OPEN),
  order: state.order.order,
  fp: state.fp,
  posType: state.posType.posType
});

const mapDispatchToProps = dispatch => ({
  cashOpenBoxShow: () => dispatch(showCard(CASH_OPEN)),
  cashWithdrawBoxShow: () => dispatch(showCard(CASH_WITH_DRAW)),
  cashSupplyBoxShow: () => dispatch(showCard(CASH_SUPPLY)),
  preOrderListShow: () => dispatch(showCard(PRE_ORDER_LIST)),
  orderListShow: (payload) => dispatch(showCard(ORDER_LIST, payload, true)),
  blockAllShortcutsBut: shortcutsToNotBlock => dispatch(shortcutsAction.blockAllShortcutsBut(shortcutsToNotBlock)),
  unblockAllShortcuts: () => dispatch(shortcutsAction.unblockAllShortcuts()),
  monitorNotasShow: () => dispatch(showCard(MONITOR_NOTAS_LIST, null, true))
});


export default connect(mapStateToProps, mapDispatchToProps)(getContext(withMessageBus(getContext(OptionListContainer))));