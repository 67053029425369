import React, { Component } from 'react';
import {
  showCard,
  closeAllCards,
  DOCUMENTPRINT,
  shouldShow,
} from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import CardDocumentPrinting from '../../shared/components/card-document-printing';
import DocumentPrintService from '../../services/documentPrintService';

export class CardDocumentPrintingConteiner extends Component {
  title = 'IMPRESSÃO DE DOCUMENTOS';
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFind = this.handleFind.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handlePrintDocument = this.handlePrintDocument.bind(this);
    this.updateLabelData = this.updateLabelData.bind(this);
    this.props.MessageBus.Subscribe('pos.documentPrint.start', this.handleShow);
    this.props.MessageBus.Subscribe('pos.documentPrint.cancel', this.handleCancel);
    this.props.MessageBus.Subscribe('pos.documentPrint.find', this.handleReturn);

    //this.props.MessageBus.Subscribe('pos.delivery.listed', this.handleReturn);
    //this.props.MessageBus.Subscribe('pos.delivery.updated', this.handleUpdated);
    //this.props.MessageBus.Subscribe('pos.delivery.refresh', this.handleIfFind);

    this.state = {
      data: [],
      documentTypes: [],
      filter: {
        Query: '',
        Type: 0,
      },
      documentToPrint: {
        Type: 0,
        Id: ""
      },
      labelData: "Data fechamento"
    };
  }

  handleFind() {
    this.setState({ data: [] });
    DocumentPrintService.find(this.state.filter);
    this.forceUpdate();
  };

  handleChange(e) {
    let prop = this.state.filter;
    prop[e.target.id] = e.target.value;
    this.setState({
      filter: prop,
    });
    this.updateLabelData(e.target.id, e.target.value);
  };

  updateLabelData(id, val) {
    if (id != "Type") return;
    switch (val) {
      case 2:
        this.state.labelData = "Data fechamento";
        break;
      default:
        break;
    }
  }

  handlePrintDocument(doc) {
    DocumentPrintService.print(doc);
  }

  handleShow = (ch, payload) => {
    this.setState({
      documentTypes: payload,
      filter: {
        Query: '',
        Type: 2,
      },
    });
    this.props.showDocumentPrintSelector();
    this.updateLabelData("Type", 2);
    //this.handleFind();
  };

  handleClose() {
    DocumentPrintService.back();
  }

  handleCancel() {
    this.props.hideDocumentPrintSelector();
    this.setState({
      data: [],
      status: [],
      check: false,
      requireReason: false,
      filter: {
        query: '',
        deliveryStatus: 0,
      },
    });
  }

  handleReturn(ch, payload) {
    console.log('return', payload)
    this.setState({
      data: payload
    });
  };

  render() {
    const { showDocumentPrint } = this.props;

    return (
      showDocumentPrint && (
        <CardDocumentPrinting
          handleClose={this.handleClose}
          handleFind={this.handleFind}
          handleChange={this.handleChange}
          handlePrintDocument={this.handlePrintDocument}
          documentTypes={this.state.documentTypes}
          labelData={this.state.labelData}
          data={this.state.data}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  showDocumentPrint: shouldShow(state, DOCUMENTPRINT),
});

const mapDispatchToProps = (dispatch) => ({
  showDocumentPrintSelector: () => dispatch(showCard(DOCUMENTPRINT, null, true)),
  hideDocumentPrintSelector: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CardDocumentPrintingConteiner)));
