import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input';
import Modal from 'react-modal';

import { Button } from '../modules';
import { DetailWrapper, ButtonWrapper } from './style';

const customStyle = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '140px',
    boxShadow: '-4px 4px 5px 1px rgba(0,0,0,0.39)',
    background: '#ffffff',
    margin: 'auto',
    border: 'none',
    position: 'absolute',
    minWidth: 'min-content',
    minHeight: 'min-content',
    bottom: '80px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    display: 'block',
  },
};

class OrderDetail extends Component {
  componentDidMount() {
    // the timeout is needed for the input to be found 
    setTimeout(() => {
      const input = document.querySelector('#order-value-input');
      input && input.focus();
    }, 0);
  }

  render() {
    const {
      handleKeyDown,
      discountPercentage,
      totalValue,
      handleDiscountChange,
      handleTotalValueChange,
      handleClose,
      updateDiscount,
      detailDialogPosition,
    } = this.props;

    // calculating the modal position based on the mouse position
    // the modal has borders of 40px, removing that offset here
    customStyle.content.left = `${detailDialogPosition.x - parseFloat(customStyle.content.maxWidth) - 40
      }px`;
    customStyle.content.top = `${detailDialogPosition.y - parseFloat(customStyle.content.maxHeight) - 40
      }px`;

    return (
      <Modal
        id="order-detail-modal"
        isOpen
        showCloseIcon={false}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick
        center
        style={customStyle}
        ariaHideApp={false}
      >
        <DetailWrapper>
          <span className="header">Valores da venda</span>
          <span className="input-header">% Desconto</span>
          <CurrencyInput
            id="order-discount-input"
            className="order-input"
            allowNegative
            suffix="%"
            value={discountPercentage}
            onFocus={(e) => {
              e.persist();
              setTimeout(() => {
                e.target.setSelectionRange(0, e.target.value.length * 2);
              }, 10);
            }}
            decimalSeparator=","
            maxLength="6"
            thousandSeparator="."
            onKeyDown={handleKeyDown}
            onChange={handleDiscountChange}
          />

          <span className="input-header">Valor Total</span>
          <CurrencyInput
            id="order-value-input"
            className="order-input"
            prefix="R$"
            allowNegative
            value={totalValue}
            onFocus={(e) => {
              e.persist();
              setTimeout(() => {
                e.target.setSelectionRange(0, e.target.value.length * 2);
              }, 10);
            }}
            onKeyDown={handleKeyDown}
            onChange={handleTotalValueChange}
            decimalSeparator=","
            maxLength={11}
            thousandSeparator="."
          />
        </DetailWrapper>
        <ButtonWrapper>
          <Button
            id="btn-confirm"
            className="btn-default btn-purple"
            type="button"
            onClick={updateDiscount}
          >
            Confirmar
          </Button>
        </ButtonWrapper>
      </Modal>
    );
  }
}

export default OrderDetail;
