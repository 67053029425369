/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import AdministrativeService from '../../services/AdministrativeService';
import CatalogService from '../../services/catalogService';
import SearchCepComponent from '../../shared/components/search-cep';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';

export const SearchCep = ({ MessageBus, onClose, onSelect, show }) => {
  const ufOptions = [
    { name: 'AC', value: 'AC' },
    { name: 'AL', value: 'AL' },
    { name: 'AP', value: 'AP' },
    { name: 'AM', value: 'AM' },
    { name: 'BA', value: 'BA' },
    { name: 'CE', value: 'CE' },
    { name: 'DF', value: 'DF' },
    { name: 'ES', value: 'ES' },
    { name: 'GO', value: 'GO' },
    { name: 'MA', value: 'MA' },
    { name: 'MT', value: 'MT' },
    { name: 'MS', value: 'MS' },
    { name: 'MG', value: 'MG' },
    { name: 'PA', value: 'PA' },
    { name: 'PB', value: 'PB' },
    { name: 'PR', value: 'PR' },
    { name: 'PE', value: 'PE' },
    { name: 'PI', value: 'PI' },
    { name: 'RJ', value: 'RJ' },
    { name: 'RN', value: 'RN' },
    { name: 'RS', value: 'RS' },
    { name: 'RO', value: 'RO' },
    { name: 'RR', value: 'RR' },
    { name: 'SC', value: 'SC' },
    { name: 'SP', value: 'SP' },
    { name: 'SE', value: 'SE' },
    { name: 'TO', value: 'TO' }
  ];
  const cities = [
    { name: 'Carregando...' }
  ];
  const retailerInfo = useSelector(state =>  state.retailer);
  const [selectedUf, setSelectedUf] = useState(retailerInfo.retailer.state);
  const [selectedCity, setSelectedCity] = useState(retailerInfo.retailer.city);
  const [data, setData] = useState([]);
  const [cityOptions, setCityOptions] = useState(cities);

  useEffect(() => {
    const subscribe = MessageBus.Subscribe('pos.administrative.getCepByAddressReturn', handleCepByAddress);
    const serviceCall = () => {
      new CatalogService().getCityByState(retailerInfo.retailer.state)
        .then(value => {
          setCityOptions(value);
        })
        .catch(error => console.log(error));
    };
    if (show)
      serviceCall();

    return () => {
      MessageBus.Unsubscribe(subscribe);
    };
  }, [show]);

  function handleCepByAddress(ch, payload) {
    setData(payload);
  }
  function handleSearch(values) {
    new AdministrativeService().getCepByAddress(
      selectedUf,
      selectedCity,
      values.address,
      ''
    );
  }
  function handleCityChange(e) {
    setSelectedCity(e.target.value);
  }
  function handleUfChange(e) {
    setSelectedUf(e.target.value);
    new CatalogService().getCityByState(e.target.value)
      .then(value => {
        setCityOptions(value);
        setSelectedCity(value[0].name);
      })
      .catch(error => console.log(error));
  }

  return (show &&
    <SearchCepComponent
      title="Identificar o Cep"
      onClose={onClose}
      onSearch={handleSearch}
      onSelect={onSelect}
      onUFChange={handleUfChange}
      onCityChange={handleCityChange}
      data={data}
      ufOptions={ufOptions}
      cityOptions={cityOptions}
      selectedUf={selectedUf}
      selectedCity={selectedCity}
    />
  );
};

SearchCep.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    previous: PropTypes.string,
  }).isRequired,
};

export default withMessageBus(getContext(SearchCep));
