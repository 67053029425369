export enum BarCodeTypeEnum {
  Sangria = 1,
  Convênio = 2,
  Crediário = 3,
}

export enum BarCodeTypeCategoryEnum {
  withDraw = 1,
  creditSale = 2,
  agreementSale = 3,
}
