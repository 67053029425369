import styled from 'styled-components';

export const ImgContainer = styled.div`
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }

  .line {
    border: 2px solid #f35958;
    position: absolute;
    width: 470px;
    top: 0;
    height: 0;
    left: -11px;
    margin-top: 0px;
    margin-bottom: 0px;
    animation: write-code 1.7s;
    animation-iteration-count: infinite;
  }

  @keyframes write-code {
    0% {
      transform: translateY(16px);
    }

    50% {
      transform: translateY(50px);
    }

    100% {
      transform: translateY(16px);
    }
  }
`;

export const ContainerTable = styled.div`
  max-height: 300px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
`;
