import styled from 'styled-components';

export const PaymentMoneyWrapper = styled.div`
    margin: 30px 20px 15px 20px;
    position:relative;

    .btn-finish{
        position:absolute;
        bottom:0;
        width: 150px;
        height: 50px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const PaymentMoneyHeader = styled.header`
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight:bold;
`;

export const PaymentMoneyInput = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  grid-gap: 10px;
	grid-row-gap: 0px;
	&:after {
	  content: '';
		display: block;
		height: 30vh;
  }
  
  .error {
    color: red;
  }

  .box {
    grid-column: 1/3;
    height: 60px;
    text-align: center;
    border: 1px solid #e8e8e8;
    padding: 30px;
    line-height: 1;
    margin-bottom: 20vh;

    span {
      display: block;
      font-size: 2em;
      color: #000;
      margin-top: 10px;
    }
  }

  input {
    height: 50px;
    font-size: 18px ;
    padding-left: 20px;
    border: 1px solid #e8e8e8;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button.btn-outline {
    text-transform: uppercase;
    color: #5B2E90;
    letter-spacing: 1px;
    &:focus {
      background-color: #5B2E90;
      color: white;
    }
  }
`;
