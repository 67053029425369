import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  closeAllCards,
  CASH_SUPPLY,
  showCard,
  shouldShow,
} from '../../redux/actions/routerAction';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { NewCashSupply } from '../../shared/components/new-cash-supply/new-cash-supply';
import { showToast } from '../../shared/utils/util';
import { cashSupply, cashSupplyCancel } from '../../services/cashDeskServices';

export class CashSupplyContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.callErrorMessage = this.callErrorMessage.bind(this);
    this.handleSupplySuccess = this.handleSupplySuccess.bind(this);

    this.props.MessageBus.Subscribe(
      'pos.supply.start',
      this.props.cashSupplyBoxShow
    );
    this.props.MessageBus.Subscribe(
      'pos.supply.success',
      this.handleSupplySuccess
    );
    this.props.MessageBus.Subscribe('pos.supply.error', this.callErrorMessage);
    this.props.MessageBus.Subscribe('pos.supply.cancel', this.close);
  }

  handleSupplySuccess() {
    showToast({
      type: 0,
      title: 'SUPRIMENTO DE CAIXA',
      html: 'Suprimento realizado com sucesso!',
      hideAfterMilliseconds: 5000,
    });

    this.props.closeCashSupply();
  }

  callErrorMessage() {
    showToast({
      type: 2,
      title: 'SUPRIMENTO DE CAIXA',
      html: 'Usuário não autorizado!',
      hideAfterMilliseconds: 5000,
    });
  }

  handleClick(cashSupplyObject) {
    if (cashSupplyObject) {
      cashSupply(cashSupplyObject);
    }
  }

  handleClickCancel() {
    cashSupplyCancel();
  }

  close = () => {
    this.props.closeCashSupply();
  }

  render() {
    return (
      this.props.showCashSupply && (
        <NewCashSupply
          handleClickConfirm={this.handleClick}
          handleClickCancel={this.handleClickCancel}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  showCashSupply: shouldShow(state, CASH_SUPPLY),
});

const mapDispatchToProps = (dispatch) => ({
  cashSupplyBoxShow: () => dispatch(showCard(CASH_SUPPLY, null, true)),
  closeCashSupply: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CashSupplyContainer)));
