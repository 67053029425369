import React, { Component } from 'react';
import './qr-code-cancel-message.scss';
import { Modal } from '../modal2';
import { Button } from '../button';

class QrCodeCancelMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isButtonDisabled: true,
            countdown: 5            
        }
    }

    onOpenModal = () => {
        this.setState({
          isButtonDisabled: true,
          countdown: 5 
        });
        
        setTimeout( () => {
            this.setState({
                isButtonDisabled: false, 
                countdown : 0      
            });
            clearInterval(this.interval);
        },5000) //this will enable button after 30 seconds you can change time here.
        
        this.interval = setInterval(
          () => { 
            let value = this.state.countdown - 1            
            this.setState({
                countdown: value,
            });
          },
          1000
        );
    }

    render() 
    {
    return (
        <Modal
            onOpen={this.onOpenModal}
            ref={this.props.modalQrCodeCancelMessage}
            id='qr-code-cancel-message'
            opened={false}
            title="Atenção"
            style={{
                maxHeight: '80vh',
                height: "fit-content",
                width: '50vw',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '-4px 4px 5px 1px rgba(0,0,0,0.39)',
                background: '#ffffff',
                margin: 'auto',
                border: 'none',
                minWidth: 'min-content',
                minHeight: 'min-content',
                top: '20%',
                bottom: 'unset',
                textAlign: 'center',
                maxWidth: '700px'
            }}
            closable={false}>

            <Modal.Body className='modal-body'>
                O pagamento via QRCode será cancelado!
                <br />
                Caso o valor tenha sido debitado da conta do cliente, o mesmo será
                <br />
                estornado em até 48h.
                <br />
                <br />
                <b>Tem certeza que deseja cancelar a cobrança?</b>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.state.isButtonDisabled} className="btn btn-purple" onClick={this.props.handleConfirmQrCodeCancelMessage}>Cancelar a cobrança {this.state.countdown > 0 ? "("+this.state.countdown+")" : ""}</Button>
                <Button className="btn btn-purple" onClick={this.props.handleCloseQrCodeCancelMessage} >Voltar</Button>
            </Modal.Footer>
        </Modal>
    )
        }
}

export default QrCodeCancelMessage