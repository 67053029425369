import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  lastSentBody: null,
  shouldAuthenticateOperation: false,
};

const utilReducer = createReducer(initialState, {
  [types.UPDATE_LAST_SENT_BODY]: (state, action) => ({
    ...state,
    lastSentBody: action.payload,
  }),
  [types.UPDATE_SHOULD_AUTHENTICATE_OPERATION]: (state, action) => ({
    ...state,
    shouldAuthenticateOperation: action.payload,
  }),
  [types.LOGIN_IS_OPEN]: (state, action) => ({
    ...state,
    loginIsOpen: action.payload,
  }),
});

export default utilReducer;
