import styled from 'styled-components';
import theme from '../../../themes/default';

export const CardListBrandWrapper = styled.div`

  padding: 1.7em 0;
  margin-top: .4em;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    overflow-y: overlay;
  }

  @media screen and (max-height: 800px) {
    .wrapper {
      height: 30vh
    }
  }

  @media screen and (min-height: 820px) {
    .wrapper {
        height: 25vh
    }
  }

  .flag {
    vertical-align: middle;
    position: absolute;
    top: 15px;left: 15px;
    width: 30px;
    height:30px;
  }

  .text-card {
    padding-left: 25px;
    font-size: 12px;
    color: #757575;
  }

  .list-button {
    cursor: pointer;
    font-size: 1rem;
    height: 60px;
    border: 1px solid ${theme.palette.border[2]};
    padding: 0 15px 0 15px;
    border-radius: 4px;
    position: relative;
    background-color: #FFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:focus {
      outline: none;
      border: 1px solid ${theme.palette.border[1]};
      background-color: ${theme.palette.grayscale.active};
    }
  }

  .list-button:hover,
  .list-button--actived {
    border: 1px solid ${theme.palette.border[1]};
    background-color: ${theme.palette.grayscale.active};
  }

`;

export const PaymentCreditWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-gap: 10px;
  margin: 30px 0;
  position: relative;
  .btn-clear {
    width: 100%;
    &:focus {
      color: #fff;
      background-color: #5B2E90;
    }
  }
`;
export const PaymentCreditInput = styled.div`
  position: relative;
  input {
    width: 100%;
    height: 50px;
    font-size: 18px ;
    padding-left: 20px;
    border-radius: 5px;
    border: 2px solid #eee;
    box-sizing: border-box;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .inputError {
    position: absolute;
    color: red;
    left: 0;
    bottom: -20px;
  }
`;
export const ButtonsPayValueWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 15px;
  column-gap: 9px;
  height: 50px;
`;
