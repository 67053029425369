import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = { quantity: 1 };

const quantityReducer = createReducer(initialState, {
  [types.ADD_QUANTITY]: (state, action) => ({
    ...state,
    quantity: action.payload
  })
});

export default quantityReducer;
