import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Logo from '../../../assets/images/logo-1.png';
import Menu from '../../../container/menu';
import { getContext } from '../../../shared/hoc/getContext';
import { withMessageBus } from '../../../shared/utils/messageBus';
import { Container } from './styles';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    props.MessageBus.Subscribe('pos.login.success', () =>
      this.setState({ show: true })
    );

    props.MessageBus.Subscribe('pos.open.login', () =>
      this.setState({ show: false })
    );
  }

  render() {
    const { show } = this.state;
    return (
      <Container
        data-testid="sidebar"
        style={{ display: show ? 'flex' : 'none' }}
      >
        <div>
          <img src={Logo} alt="Linx Logo" />
        </div>
        <Menu />
      </Container>
    );
  }
}

Sidebar.propTypes = {
  MessageBus: PropTypes.shape({
    Subscribe: PropTypes.func,
  }).isRequired,
};

export default withMessageBus(getContext(Sidebar));
