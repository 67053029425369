import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withMessageBus } from "../../shared/utils/messageBus";
import {
  showCard,
  closeAllCards,
  CONTINUOUS_USE,
  shouldShow,
} from "../../redux/actions/routerAction";
import { getContext } from "../../shared/hoc/getContext";
import SaleService from "../../services/saleService";
import CatalogService from "../../services/catalogService";
import CardContinuousUse from "../../shared/components/card-continuous-use";
import { MessageBox } from "../../shared/components/modules";
import { ControlledItemsService } from "../../services/controlledItemsService";
import { getNumberValue, showToast } from "../../shared/utils/util";

export class CardContinuousUseContainer extends Component {
  title = "CONTROLE DE USO CONTÍNUO";

  static propTypes = {
    showContinuousUse: PropTypes.bool,
    showContinuousUseSelector: PropTypes.func.isRequired,
    hideContinuousUseSelector: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    showContinuousUse: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.saleService = new SaleService();
    this.controlledItemsService = new ControlledItemsService();
    this.catalogService = new CatalogService();

    this.modalRef = React.createRef();

    this.props.MessageBus.Subscribe(
      "pos.continuoususe.dialog.show",
      this.handleShow
    );
    this.props.MessageBus.Subscribe(
      "pos.continuoususe.update.show",
      this.handleShowUpdateContinuousUse
    );
    this.props.MessageBus.Subscribe(
      "pos.continuoususe.update.cancel",
      this.handleCloseCard
    );
    this.props.MessageBus.Subscribe(
      "pos.continuoususe.update.error",
      this.handleError
    );
    this.props.MessageBus.Subscribe(
      "pos.continuoususe.update.success",
      this.handleSuccessContinuousUse
    );
    this.props.MessageBus.Subscribe(
      "pos.continuoususe.update.requiredfields",
      this.handleErrorRequiredFields
    );

    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriber.list.success",
      this.handlePrescriberList
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriber.manage.success",
      (ch, payload) =>
        this.handleManageRegistrations(payload, "prescriber", "Prescritor")
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriber.error",
      this.handleError
    );

    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriberspecialty.list.success",
      (ch, payload) => this.setState({ prescriberspecialtyList: payload })
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriberspecialty.manage.success",
      (ch, payload) =>
        this.handleManageRegistrations(
          payload,
          "prescriberSpecialty",
          "Especialidade"
        )
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriberspecialty.error",
      this.handleError
    );

    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriptionorigin.list.success",
      (ch, payload) => this.setState({ prescriptionOriginList: payload })
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriptionorigin.manage.success",
      (ch, payload) =>
        this.handleManageRegistrations(payload, "prescriptionOrigin", "Origem")
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.prescriptionorigin.error",
      this.handleError
    );

    this.props.MessageBus.Subscribe(
      "pos.catalog.hospitalclinic.list.success",
      (ch, payload) => this.setState({ hospitalClinicList: payload })
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.hospitalclinic.manage.success",
      (ch, payload) =>
        this.handleManageRegistrations(
          payload,
          "hospitalClinic",
          "Hospital / Clinica"
        )
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.hospitalclinic.error",
      this.handleError
    );

    this.props.MessageBus.Subscribe(
      "pos.catalog.pathology.list.success",
      (ch, payload) => this.setState({ pathologyList: payload })
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.pathology.manage.success",
      (ch, payload) =>
        this.handleManageRegistrations(payload, "pathology", "Patologia")
    );
    this.props.MessageBus.Subscribe(
      "pos.catalog.pathology.error",
      this.handleError
    );

    this.state = {
      showMessage: false,
      product: {},
      barcode: "",
      anyContinuousUseFieldRequired: false,
      fields: {},
      prescriberspecialtyList: [],
      prescriptionOriginList: [],
      hospitalClinicList: [],
      pathologyList: [],
      manage: {},
    };

    this.autocompleteResolve = null;
  }

  handleShow = (ch, payload) => {
    this.setState({
      showMessage: true,
      product: payload,
      continuousUse: payload.continuousUse,
      barcode: payload.productBarcode,
    });
  };

  confirmInitContinuousUse = () => {
    const payload = {
      barcode: this.state.barcode,
    };
    this.saleService.continuousUseStart(payload);
  };

  handleShowUpdateContinuousUse = (ch, payload) => {
    this.setState({
      showMessage: false,
      barcode: payload.product.productBarcode,
      product: payload.product,
    });

    this.setState({
      anyContinuousUseFieldRequired: this.props.stateProps.order.order
        ?.anyContinuousUseFieldRequired,
    });

    this.saleService.continuousUseCheckFields().then((response) => {
      this.setState({ fields: response });
      this.props.showContinuousUseSelector();

      this.callSelectLists(response);
    });
  };

  callSelectLists = (response) => {
    if (response.prescriberSpecialty.show)
      this.catalogService.getPrescriberSpecialty();
    if (response.prescriptionOrigin.show)
      this.catalogService.getPrescriptionOrigin();
    if (response.hospitalClinic.show) this.catalogService.getHospitalClinic();
    if (response.pathology.show) this.catalogService.getPathology();
  };

  handleSubmitRecord = (values) => {
    const endpoint = Object.keys(values)[0];
    this.callActionService(endpoint, values[endpoint]);
  };

  callActionService = (endpoint, values) => {
    switch (endpoint) {
      case "newPrescriber": {
        const payload = {
          ...values,
          status: true,
        };
        this.catalogService.addPrescriber(payload);
        break;
      }
      case "newPrescriberSpecialty": {
        const payload = {
          description: values.prescriberSpecialty,
          status: true,
        };
        this.catalogService.addOrUpdatePrescriberSpecialty(payload);
        break;
      }
      case "newPrescriptionOrigin": {
        const payload = {
          description: values.prescriptionOrigin,
          status: true,
        };
        this.catalogService.addOrUpdatePrescriptionOrigin(payload);
        break;
      }
      case "newHospitalClinic": {
        const payload = {
          description: values.name,
          name: values.name,
          hospitalCnpj: getNumberValue(values.cnpj) || null,
          status: true,
        };
        this.catalogService.addOrUpdateHospitalClinic(payload);
        break;
      }
      case "newPathology": {
        const payload = {
          description: values.pathology,
          status: true,
        };
        this.catalogService.addOrUpdatePathology(payload);
        break;
      }
      default:
        break;
    }
  };

  handleManageRegistrations = (payload, registration, text) => {
    this.setState({ manage: { [registration]: payload } });
    showToast({
      type: 0,
      title: `Cadastro de ${text}`,
      html: `Cadastro de ${text} realizado com sucesso!`,
      hideAfterMilliseconds: 5000,
    });
    this.modalRef.current.close();
  };

  handleSubmit = (values) => {
    const payload = {
      barcode: this.state.barcode,
      prescriber: values.prescriber.id
        ? {
          id: values.prescriber.id,
          referenceId: values.prescriber.referenceId,
          name: values.prescriber.name,
          documentType: values.prescriber.documentDescription,
          documentNumber: values.prescriber.documentNumber,
          documentUF: values.prescriber.documentUF,
        }
        : null,
      specialty: values.prescriberSpecialty.id
        ? {
          id: values.prescriberSpecialty.id,
          referenceId: values.prescriberSpecialty.referenceId,
          name: values.prescriberSpecialty.name,
        }
        : null,
      origin: values.prescriptionOrigin.id
        ? {
          id: values.prescriptionOrigin.id,
          referenceId: values.prescriptionOrigin.referenceId,
          name: values.prescriptionOrigin.name,
        }
        : null,
      hospitalClinic: values.hospitalClinic.id
        ? {
          id: values.hospitalClinic.id,
          referenceId: values.hospitalClinic.referenceId,
          name: values.hospitalClinic.name,
          hospitalCnpj: values.hospitalClinic.cnpj,
        }
        : null,
      pathology: values.pathology.id
        ? {
          id: values.pathology.id,
          referenceId: values.pathology.referenceId,
          name: values.pathology.name,
        }
        : null,
      nextDate: values.nextDate ? values.nextDate.toISOString() : "",
      metricUnity: values.metricUnity,
      quantity: values.quantity ? parseFloat(values.quantity) : 0,
      period: values.period ? values.period : 0,
      remark: values.remark,
      empty: false,
    };

    this.saleService.continuousUseSave(payload);
  };

  handleNotInformContinuousUse = () => {
    const payload = {
      barcode: this.state.barcode,
      empty: true,
    };
    this.saleService.continuousUseSave(payload);
  };

  handleSuccessContinuousUse = () => {
    this.setState({ product: {}, manage: {} });
    this.props.hideContinuousUseSelector();
  };

  handleError = (ch, payload) => {
    console.error("channel:", ch);
    console.error("channel:", ch, "error:", payload);
    this.context.showMessage(payload?.message, this.title);
  };

  handleErrorRequiredFields = (ch, payload) => {
    const arrMessage = [];

    payload &&
      payload.forEach((field) => {
        arrMessage.push(`<p>${field?.errorMessage}</p>`);
      });

    const message = arrMessage.toString().replace(/'|,/g, "");

    this.context.showMessage(message, this.title);
  };

  handleClose = () => {
    this.saleService.continuousUseCancel();
  };

  handleCloseCard = () => {
    this.setState({ product: {}, manage: {} });
    this.props.hideContinuousUseSelector();
  };

  handlePrescriberList = (ch, payload) => {
    this.autocompleteResolve &&
      this.autocompleteResolve({ items: payload.pagedResult.resultList });
    this.autocompleteResolve = null;
  };

  autocompleteQueryFn = (value) => {
    this.lastQuery = value;

    this.catalogService.getPrescriber(value, 1, 10);
    return new Promise((resolve) => {
      this.autocompleteResolve = resolve;
    });
  };

  renderItem = (props) => {
    return (
      <div className="render-item" {...props}>
        <div>
          <span>{props.item.name}</span>
        </div>
        <div className="prescriber-info">
          <span>{`${props.item.documentTypeDescription}: `}</span>
          <span>{props.item.documentNumber}</span>
          <span>{`UF: ${props.item.documentUF}`}</span>
        </div>
      </div>
    );
  };

  render() {
    const { showContinuousUse } = this.props;

    return (
      <Fragment>
        {this.state.showMessage && (
          <MessageBox
            title="Uso contínuo"
            message="Deseja preencher as informações de uso contínuo desse produto?"
            okText="Sim"
            handleConfirm={() => this.confirmInitContinuousUse()}
            handleCancel={() => this.setState({ showMessage: false })}
            visible={this.state.showMessage}
            focusCancelButton
          />
        )}
        {showContinuousUse && (
          <CardContinuousUse
            title={this.title}
            state={this.state}
            handleClose={this.handleClose}
            handleRestartPreOrder={this.handleRestartPreOrder}
            handleSubmit={this.handleSubmit}
            handleSubmitRecord={this.handleSubmitRecord}
            handleNotInformContinuousUse={this.handleNotInformContinuousUse}
            autocompleteQueryFn={this.autocompleteQueryFn}
            renderItem={this.renderItem}
            modalRef={this.modalRef}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showContinuousUse: shouldShow(state, CONTINUOUS_USE),
  stateProps: state,
});

const mapDispatchToProps = (dispatch) => ({
  showContinuousUseSelector: () =>
    dispatch(showCard(CONTINUOUS_USE, null, true)),
  hideContinuousUseSelector: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CardContinuousUseContainer)));
