import styled from 'styled-components';
import theme from '../../../themes/default';

export const FarmaciaPopularWrapper = styled.div`
  overflow: auto;
  flex: 1;
  display: block;

  button {
    float: right;
    height: 50px;
    width: 100px;
  }
  .card-body
  {
    padding: 5px !important;
    display: block !important;
  }
  .actions {
    margin-top: 20px;
    justify-content: flex-end;
  }

  div.flexrow i {
    vertical-align: middle;
    color: red;
  }
  div.flexrow i.approved
  {
    color: green;
  }
  h3 {
    font-size: 14px;
    margin: 0px 0px 7px 0px;
    font-family: 'NeoSansProRegular';
  }
  h3:nth-of-type(2)
  {
    margin-top: 25px;
  }
  div.flexcontainer {
    border-bottom: dotted 2px #ccc;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  div.flexcontainer:last-child{
    border:none;
  }
  div.flexrow, div.flexheader {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    text-align: left;
    font-size: 14px;
  }
  div.flexrow:last-child
  {
    border-bottom: none;
  }
  div.flexheader {
    font-weight: bold;
    border-bottom: none;
  }
  div.flexrow div, div.flexheader div
  {
    display: block;
    padding: 5px;
  }
  div.flexrow:nth-child(2) div, div.flexheader:first-child div
  {
    flex: 1 1 25%;
    text-align: right;
  }
  div.flexrow:nth-child(4) div, div.flexheader:nth-child(3) div
  {
    flex: 1 1 25%;
    text-align: right;
  }
  div.flexrow:nth-child(4) div:first-child, div.flexheader:nth-child(3) div:first-child
  {
    text-align: left;
  }
  div.flexrow div.status, div.flexheader div.status
  {
    flex: 0 0 50px;
    text-align: left;
  }
  div.flexrow div.product, div.flexheader div.product
  {
    flex: 1 1 66%;
    text-align: left;
  }
  div.flexrow.notapproved div.product, div.flexheader.notapproved div.product
  {
    flex: 1 1 40%;
  }
  div.flexrow:last-child div:last-child, div.flexheader:last-child div:last-child
  {
    flex: 0 0 100%;
    text-align: left;
  }
  div.flexheader div
  {
    padding: 0px 5px;
  }

  div.flexheader div.aligncenter, div.flexrow div.aligncenter
  {
    text-align: center;
  }

  
`;

export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .authorization {
    flex: 1 1 auto;
  }
  table {
    display: block;
  }
  table tr {
    border: none !important;
  }
  table tr th, table tr td{
    padding: 0px 5px !important;
    border: none !important;
  }
`;

export const Summary = styled.div`
  width: 180px;
  display: grid;
  grid-template-columns: 2fr;
  grid-row-gap: 0px;
  margin-left: 10px;
  padding-bottom: 0px;

  .total-wrapper {
    border-top: 1px solid #ccc;
  }
  .total-wrapper, .discount-wrapper, .subtotal-wrapper {
    strong {
      float: right;
    }
  }
  .discount-wrapper {
    color: ${theme.palette.color[1]};
  }
`;

export default FarmaciaPopularWrapper;
