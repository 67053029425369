import React, { Component, Fragment } from 'react';
import Configuration from '../../configuration';
import {
  HAS_CONFIG_USER_FP,
  LOGIN_IS_OPEN,
} from '../../redux/actions/actionTypes';
import { blockAllShortcutsBut } from '../../redux/actions/shortcutsAction';
import Store from '../../redux/store';
import AuthService from '../../services/authService';
import { CacheFinder } from '../../shared/components/auto-complete/cache-finder';
import { connect } from 'react-redux';
import { LoginComponent, RequireAuth } from '../../shared/components/modules';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { payloadToBase64Zip } from '../../shared/utils/util';
import { SolicitationAuthorizeService } from '../../services/solicitation-authorize.service';
import { getTextOfJSDocComment } from 'typescript';

export class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      modalVisible: false,
      modalTitle: null,
    };

    this.form = {
      username: '',
      password: '',
    };

    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleLoginOpen = this.handleLoginOpen.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);

    this.props.MessageBus.Subscribe(
      'pos.open.loginModal',
      this.handleModalOpen
    );
    this.props.MessageBus.Subscribe(
      'pos.close.loginModal',
      this.handleModalClose
    );
    this.props.MessageBus.Subscribe('pos.open.login', this.handleLoginOpen);
    this.props.MessageBus.Subscribe(
      'pos.login.success',
      this.handleLoginSuccess
    );

    this.solicitationAuthorizeService = new SolicitationAuthorizeService();
  }

  componentDidMount() {
    Store.dispatch(blockAllShortcutsBut());

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USER) {
      this.form = {
        username: process.env.REACT_APP_USER,
        password: process.env.REACT_APP_PASSWORD,
      };

      setTimeout(() => this.handleConfirm({ preventDefault: () => { } }), 3000);
    }
  }

  handleLoginOpen() {
    Store.dispatch(blockAllShortcutsBut());
    this.setState({
      visible: true,
    });
    Store.dispatch({
      type: LOGIN_IS_OPEN,
      payload: true,
    });
  }

  handleModalOpen(ch, payload) {
    Store.dispatch(blockAllShortcutsBut());
    this.setState({
      modalVisible: true,
      modalTitle: payload.title,
      closable: payload.closable,
    });
    Store.dispatch({
      type: LOGIN_IS_OPEN,
      payload: true,
    });
  }

  handleModalClose() {
    this.setState({
      modalVisible: false,
      modalTitle: null,
    });
    Store.dispatch({
      type: LOGIN_IS_OPEN,
      payload: false,
    });
  }

  getShowAutorizationOnline() {
    this.solicitationAuthorizeService.showAutorizationOnline();
  }

  handleLoginSuccess() {
    this.setState({
      visible: false,
      modalVisible: false,
      modalTitle: false,
    });
    Store.dispatch({
      type: LOGIN_IS_OPEN,
      payload: false,
    });
    this.getShowAutorizationOnline();
  }

  handleConfirm(e) {
    e.preventDefault();
    const payload = {
      retailerId: Configuration.retailerId,
      posType: this.props.posType,
      posId: Configuration.PosId,
      ...this.form,
    };
    payloadToBase64Zip(JSON.stringify(payload)).then((res) => {
      AuthService.login(res).then((payload) => {
        Store.dispatch({
          type: HAS_CONFIG_USER_FP,
          payload: payload && payload.hasFarmaciaPopularConfig,
        });
        payload &&
          payload.token &&
          window.sessionStorage.setItem('Access_Token', payload.token);
        new CacheFinder().start();
      });
    });
  }

  resetForm() {
    this.form = {
      username: '',
      password: '',
    };
  }

  handleLoginConfirm = (form) => {
    const payload = {
      retailerId: Configuration.retailerId,
      posType: this.props.posType,
      posId: Configuration.PosId,
      username: form.operatorRefId,
      password: form.password,
    };
    payloadToBase64Zip(JSON.stringify(payload)).then((res) => {
      AuthService.login(res).then((payload) => {
        Store.dispatch({
          type: HAS_CONFIG_USER_FP,
          payload: payload && payload.hasFarmaciaPopularConfig,
        });
        payload &&
          payload.token &&
          window.sessionStorage.setItem('Access_Token', payload.token);
      });
    });
  };

  handleLoginClose = () => {
    this.setState({
      modalVisible: false,
      modalTitle: null,
    });
    Store.dispatch({
      type: LOGIN_IS_OPEN,
      payload: false,
    });
  };

  render() {
    const { retailer } = this.props;
    let status = retailer.retailer?.status ?? 0;
    return (

      <Fragment>
        {this.state.visible && (
          <LoginComponent
            form={this.form}
            handleConfirm={this.handleConfirm}
            resetForm={this.resetForm}
            loginAllowed={status === 1 || status === 5}
          />
        )}
        {this.state.modalVisible && (
          <RequireAuth
            title={this.state.modalTitle}
            handleConfirm={this.handleLoginConfirm}
            handleClose={this.handleUserChangeClose}
            closable={this.state.closable}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  posType: state.posType.posType,
  retailer: state.retailer
});

export default connect(mapStateToProps)(
  withMessageBus(getContext(LoginContainer))
);
