import styled from 'styled-components';

export const TefMessagesWrapper = styled.div`
    
    .box-header {
    
        display: flex;
        justify-content: space-around;
        margin-top: 3rem;    
    }

    .value-header {    

        font-weight: bolder;     
    }
    .title-header {
        font-weight: normal;
    
    }

    .wallets {
        font-weight: normal;    
    }

    .column-wallets {
        padding: 10px;
        display: flex;     
        max-width: 200px;        
    }

    .divQR {
        font-size: initial;
    }

    .img-wallets{
        display: flex;
        height: 100%;
        width: 100%;
        padding: 10px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); 
    }

    .row-wallets {
        display: flex;
        padding: 15px;        
        margin-left: auto;
        margin-right: auto;
      }

    .list-itens {
        
        overflow-y: auto;
        // height: calc(100vh - 500px); // Viewport height mínimo
        height: min-content;
        width: 100%;
        display: grid;
        align-items: center;
        text-align: center;
        color: #9e9e9e;
        font-size: 2em;        
        font-weight: 200;

        p:first-letter { text-transform: uppercase; }
    }
`;
