import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../modules';
import { Button, Input } from '../modules';
import './card-trncentre.scss';
import { currencyString, currencyMask } from '../../utils/masks';
import { HeaderTrnCentre, InfoBoxTrnCentre } from './styles';

class CardTrnCentre extends Component {
  config;

  constructor(props) {
    super(props);

    this.modalItems = React.createRef();
  }

  getHeaders = () => {
    return (
      <div className="headerTbTrnCentre">
        <div>Código de Barras</div>
        <div>Descrição</div>
        <div>Valor</div>
        <div>Desc. PBM</div>
        <div>Desc. Loja</div>
        <div>Vlr. Líq.</div>
        <div style={{ minWidth: "44px" }}>Quant.</div>
        <div>Total</div>
        <div>Informações</div>
      </div>
    );
  }

  getRow = (item) => {
    return (
      <div tabIndex={0} className={item.quantityChanged ? "rowTbTrnCentre quantityChanged" : "rowTbTrnCentre"} >
        <div>{item.codigoBarras}</div>
        <div>{item.name}</div>
        <div>{currencyString(item.precoBruto)}</div>
        <div>{currencyString(item.descontoPbm)}</div>
        <div>{currencyString(item.descontoLoja)}</div>
        <div>{currencyString(item.precoLiquido)}</div>
        <div>{item.quantidadeAprovada}</div>
        <div>{currencyString(item.quantidadeAprovada * item.precoLiquido)}</div>
        <div className="info-icon"><i className="material-icons">info</i>
          <a id={item.codigoBarras} onClick={this.props.handleShowMessageProd} href="#">Detalhes</a></div>
      </div>
    );
  }

  render() {
    const {
      handleClose,
      handleChangeCpf,
      handleChangeCardNumber,
      handleChangeNsu,
      handleConfirm,
      showItems,
      handleConfirmItems,
      payload,
      showCpfField,
      showCardField
    } = this.props;

    const footer = <div className='divFooter'>
      <span class="dot"></span>
      <div>O PBM autorizou uma quantidade inferior a que foi solicitada.
        Para as quantidades restantes, você pode fazer uma nova venda no Farma Cloud utilizando condições de pagamento e descontos da farmácia.</div>
    </div>

    if (showItems) {
      return (
        <Card
          ref={(ref) => (this.modelRef = ref)}
          autoScroll={false}
          id="card-trncentre"
          title="Portal da Drogaria - ITENS"
          closeEnable
          handleClose={handleClose}
          footer={payload.showQuantityChangedMessage ? footer : ''}
        >
          <HeaderTrnCentre>
            <div className="customer-name-conecta">
              <p>Efetivando a Autorização</p>
              <p>{payload.authorization}</p>
            </div>
            <div className="info-box-wrapper">
              <InfoBoxTrnCentre>
                <p>Total bruto</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(payload.valorBruto).slice(2)}</p>
              </InfoBoxTrnCentre>
              <InfoBoxTrnCentre>
                <p>Economia de</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(payload.valorBruto - payload.valorTotal).slice(2)}</p>
              </InfoBoxTrnCentre>
              <InfoBoxTrnCentre>
                <p>Total líquido</p>
                <p className="info-box-value"><span>R$</span> {currencyMask(payload.valorTotal).slice(2)}</p>
              </InfoBoxTrnCentre>
            </div>
          </HeaderTrnCentre>
          <div className="tbTrnCentre">
            {this.getHeaders()}
            {payload.items.map(this.getRow)}
          </div>
          <Button
            onClick={handleConfirmItems}
            className="btn-default btn-outline btn"
          >
            CONFIRMAR
          </Button>
        </Card>
      );
    }

    return (
      <Card
        ref={(ref) => (this.modelRef = ref)}
        autoScroll={false}
        id="card-trncentre"
        title="Portal da Drogaria"
        closeEnable
        handleClose={handleClose}
      >
        <div className="options">
          {showCpfField ?
            <div className="input">
              CPF
              <Input
                required
                id="input-recoverAuth-cpf"
                onChange={handleChangeCpf}
                type="text"
                placeholder="CPF do cliente"
                ref={this.nameRef}
              />
            </div>
            : ""}
          {showCardField ?
            <div className="input">
              Cartão
              <Input
                required
                id="input-recoverAuth-card-number"
                onChange={handleChangeCardNumber}
                type="text"
                placeholder="Número do cartão"
                ref={this.nameRef}
              />
            </div>
            : ""}
          <div className="input">
            NSU
            <Input
              required
              id="input-recoverAuth-nsu"
              onChange={handleChangeNsu}
              type="text"
              placeholder="NSU da transação (caso exista)"
              ref={this.nameRef}
            />
          </div>
        </div>
        <Button
          onClick={handleConfirm}
          className="btn-default btn-purple btn"
        >
          VALIDAR PRODUTOS
        </Button>
      </Card >
    );
  }
}

CardTrnCentre.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CardTrnCentre;
