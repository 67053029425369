import React from 'react';
import { Card, Button, SlideToggle } from '../modules';
import { Col, Form } from 'react-bootstrap';
import './config-tef-component.scss';


function ConfigTefComponent(props) {
    const {
        handleClose,
        handleToggleChange,
        form,
        handleChange,
        handleConfirm
    } = props;

    return (
        <Card id="config-tef-card" autoScroll={false} title="Configurações TEF" handleClose={handleClose} closeEnable shouldFlex >
            <div className="body">
                <Form>
                    <Form.Row>
                        <Form.Label class="label-title">Configurações gerais</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="dtefWorkingFolder">
                            <Form.Label>Diretório de trabalho *</Form.Label>
                            <Form.Control
                                defaultValue={form?.dtefWorkingFolder}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <label class="label-text">
                            Possui PIN PAD
                        </label>
                        <SlideToggle controlId="hasPinPad" class="toggle"
                            style={{
                                alignSelf: 'center',
                                marginLeft: '5px',
                                marginBottom: 'unset'
                            }}
                            onChange={handleToggleChange}
                            checked={form?.hasPinPad}
                            id="option"
                        />
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="pinpadPort">
                            <Form.Label>Porta PIN PAD *</Form.Label>
                            <Form.Control
                                defaultValue={form?.pinpadPort}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="idTerminal">
                            <Form.Label>Número identificação ou série da Maquina de cartão</Form.Label>
                            <Form.Control
                                defaultValue={form?.idTerminal}
                                onChange={handleChange}
                                maxLength={40}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
            </div>
        </Card>
    );
}
export default ConfigTefComponent;