import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  referenceId: 0,
  documentNumber: '',
  name: '',
  cpf: '',
  phoneNumber: '',
  email: '',
  city: '',
  state: '',
  number: '',
  street: '',
  zipCode: '',
  neighborhood: '',
  complement: '',
};

export const TYPE_UPDATE_CUSTOMER = '@CUSTOMER_REGISTRATION/UPDATE';
export const TYPE_CLEAR_CUSTOMER = '@CUSTOMER_REGISTRATION/CLEAR';

export const updateCustomer = createAction(TYPE_UPDATE_CUSTOMER);
export const clearCustomer = createAction(TYPE_CLEAR_CUSTOMER);

const reducer = handleActions(
  {
    [TYPE_UPDATE_CUSTOMER]: (state, { payload }) => ({ ...state, ...payload }),
    [TYPE_CLEAR_CUSTOMER]: () => defaultState,
  },
  defaultState
);

export default reducer;
