import React, { Component, Fragment } from 'react';
import './style.scss';
import InputMask from 'react-input-mask';
import { CepHeader } from './styles';

export class FarmaciaPopularCustomerInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='farmacia-popular-customer-info'>
        <div className='div-titulo'>
          FARMÁCIA POPULAR
        </div>
        <div className='div-mensagem'>
          <i className='material-icons'>info</i>
          Caso o endereço não seja informado o sistema reservará o espaço para inclusão manual na impressão do documento.</div>
        <div className="div-label-title">
          <label className="form-label-title">
            Endereço do paciente
          </label>
        </div>
        <div className="form-row">
          <div className="form-group">
            <CepHeader>
              <label className="form-label">
                CEP
              </label>
              <button type="button"
                onClick={this.props.onIdentifyCepClick}
              >
                Não sei o CEP
              </button>
            </CepHeader>
            <InputMask
              className="form-control"
              value={this.props.customerInfo?.address?.zipCode}
              id="zipCode"
              mask="99999-999"
              onChange={e => this.props.handleChangeCustomerInfo(e)}
              placeholder="00000-000"
              onBlur={this.props.handleBlurZipCode}
              onKeyDown={this.props.handleKeyDownZipCode}
            />
          </div>
          <div
            id="form-group-width"
            className="form-group">
            <label className="form-label">
              Endereço
            </label>
            <input
              value={this.props.customerInfo?.address?.street}
              className="form-control"
              id="street"
              onChange={e => this.props.handleChangeCustomerInfo(e)}
              placeholder="Informe o CEP"
              disabled={true}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label className="form-label">
              Número
            </label>
            <input
              ref={this.props.inputNumberRef}
              value={this.props.customerInfo?.address?.number}
              className="form-control"
              type="number"
              id="number"
              maxLength="10"
              onChange={e => this.props.handleChangeCustomerInfo(e)}
              placeholder="Número"
            />
          </div>
          <div
            id="form-group-width"
            className="form-group">
            <label className="form-label">
              Complemento
            </label>
            <input
              value={this.props.customerInfo?.address?.complement}
              className="form-control"
              id="complement"
              onChange={e => this.props.handleChangeCustomerInfo(e)}
              placeholder="Complemento"
            />
          </div>
          <div
            id="form-group-width"
            className="form-group">
            <label className="form-label">
              Bairro
            </label>
            <input
              disabled={true}
              value={this.props.customerInfo?.address?.neighborhood}
              className="form-control"
              id="neighborhood"
              onChange={e => this.props.handleChangeCustomerInfo(e)}
              placeholder="Informe o CEP"
            />
          </div>
        </div>
      </div >
    );
  }
}

export default FarmaciaPopularCustomerInfo;
