import React, { useEffect, useState } from 'react';

import Input from './style';
import { IInputBase } from '../interface/input.interface';
import { ValidationErrors } from '../../validation-errors/validation-errors';
import onKeyDownEnter from '../../../utils/onKeyDownEnterToNextInput';

function InputComponent(props: IInputBase) {
  const [touched, setTouched] = useState(false);
  const [hasError, setError] = useState(false);
  const [className, setClassName] = useState('');

  useEffect(() => setClass(), [hasError]);

  useEffect(() => setClass(), [touched]);

  useEffect(() => setTouched(props.touched as boolean), [props.touched]);

  const setClass = () => {
    setClassName(touched && hasError ? 'has-error' : hasError ? 'error' : '');
  };

  const onChangeSetError = (value: boolean) => {
    setError(value);
  };

  const onBlur = () => {
    setTouched(true);
  };

  const eventkeyPress = (event) => {
    if (!props.onlyNumbers) return;

    if (event.charCode >= 48 && event.charCode <= 57) return true;
    else event.preventDefault();
  };

  return (
    <>
      <Input
        {...props}
        data-testid={props.id}
        onBlur={onBlur}
        autoFocus={props.autoFocus}
        className={className}
        maxLength={props.maxLength}
        onKeyPress={eventkeyPress}
        disabled={props.disabled}
        onKeyDown={($event) => onKeyDownEnter($event, props.id)}
      />
      {props.showError && (
        <ValidationErrors
          fieldNameId={props.id}
          fieldValue={props.value}
          typeError='required'
          touched={touched}
          onChangeEvent={onChangeSetError}
        />
      )}
    </>
  );
}

export default InputComponent;
