import React from "react";
import PropTypes from "prop-types";
import { ufOptions } from "../../../../container/search-cep/uf-options";
import FormControl from "../../@form-control";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  GroupContinuousUseItems,
} from "../style";
import { getNumberValue } from "../../../utils/util";

export const initialValuesNewPrescriber = {
  newPrescriber: {
    description: "",
    documentNumber: "",
    documentDescription: "",
    documentType: "",
    documentUF: "",
  },
};

export const NewPrescriber = ({ setFieldValue, errors, touched }) => {
  const documentTypeSelect = [
    { value: "0", name: "NÃO DEFINIDO" },
    { value: "1", name: "CRM" },
    { value: "2", name: "CRO" },
    { value: "3", name: "CRMV" },
    { value: "4", name: "CRN" },
    { value: "5", name: "CRP" },
    { value: "6", name: "COREN" },
    { value: "7", name: "RMS" },
  ];

  const handleChange = (value) => {
    setFieldValue("newPrescriber.documentType", value);
    setFieldValue(
      "newPrescriber.documentDescription",
      documentTypeSelect.find((item) => item.value === value).name
    );
  };

  return (
    <ContinuousUseSection height="180px">
      <GroupContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newPrescriber?.description &&
            touched.newPrescriber?.description
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newPrescriber"
            name="newPrescriber.description"
            label="Nome do prescritor *"
            placeholder="Informe o nome"
            autoFocus
          />
        </ContinuousUseItems>
      </GroupContinuousUseItems>
      <GroupContinuousUseItems margin="12px 0 0 0">
        <ContinuousUseItems
          width="40%"
          hasError={
            errors.newPrescriber?.documentNumber &&
            touched.newPrescriber?.documentNumber
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newDocumentNumber"
            name="newPrescriber.documentNumber"
            label="Número registro *"
            placeholder="Informe o número"
            onChange={(e) => {
              setFieldValue(
                "newPrescriber.documentNumber",
                getNumberValue(e.target.value)
              );
            }}
          />
        </ContinuousUseItems>
        <ContinuousUseItems
          width="30%"
          hasError={
            errors.newPrescriber?.documentType &&
            touched.newPrescriber?.documentType
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newDocumentType"
            name="newPrescriber.documentType"
            label="Conselho *"
            type="select"
            options={documentTypeSelect}
            placeholder="Tipo do conselho"
            onChange={(e) => handleChange(e.target.value)}
          />
        </ContinuousUseItems>
        <ContinuousUseItems
          flex={1}
          hasError={
            errors.newPrescriber?.documentUF &&
            touched.newPrescriber?.documentUF
          }
        >
          <FormControl
            className="continuous-use-items-input"
            id="newDocumentUF"
            name="newPrescriber.documentUF"
            label="UF conselho *"
            type="select"
            options={ufOptions}
            placeholder="Selecione a UF"
            data-select-submit-button="Tab"
          />
        </ContinuousUseItems>
      </GroupContinuousUseItems>
    </ContinuousUseSection>
  );
};

NewPrescriber.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};
