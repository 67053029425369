import styled from 'styled-components';

import CurrencyInput from 'react-currency-input';

export const Table = styled.table`
  width: 100%;

  thead > tr > th {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    text-align: center;

    padding: 0.5rem;
  }

  thead > tr > th:first-child {
    text-align: start;
  }

  tbody > tr {
    border: 1px solid #cccccc;
    border-left: none;
    border-right: none;

    &:hover {
      background-color: unset;
    }

    td {
      font-family: Ubuntu;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 138.2%;
      color: #000000;

      padding: 0.3rem;

      input {
        height: 35px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 4px;
        text-align: center;
      }

      &.conferencia-aberta-ok {
        color: #26b483;
      }
    }

    td:last-child {
      padding-right: 0px;
    }
  }
`;

interface ValuesWrapperProps {
  disabledIcon?: boolean;
}

export const ValuesWrapper = styled.div<ValuesWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 35px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;

  img {
    width: 22px;
    margin-left: 5px;
    opacity: ${({ disabledIcon }) => disabledIcon && 0.5};
    cursor: ${({ disabledIcon }) => (disabledIcon ? 'initial' : 'pointer')};
  }
`;

export const Span = styled.span`
  background: #ffffff;
  text-align: center;

  &.conferencia-aberta-ok {
    color: #26b483;
  }

  &.conferencia-aberta-error {
    color: #f35958;
  }

  &.conferencia-aberta-warning {
    color: #faa46b;
  }
`;

export const InputMoneyToCheck = styled(CurrencyInput)`
  width: 100%;
`;

export const SpanBtnAdd = styled.span`
  position: absolute;
  right: 1rem;
  width: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const InputGroupBtn = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
