import styled from 'styled-components';

import theme from '../../../themes/theme2';

export const Container = styled.div`
  height: 100%;

  .card-cash {
    height: 100%;
  }

  .card-body {
    align-items: center;
    justify-content: center;
  }
`;

export const CashOpenWrapper = styled.form`
  display: flex;

  align-items: flex-end;
  justify-content: center;

  & > div {
    margin-right: ${theme.metrics.baseMargin * 2}px;
    width: 300px;
  }

  input {
    padding: 0 ${theme.metrics.basePadding * 2}px;
    width: 100%;
    height: 40px;
    border: 1px solid ${theme.palette.buttonBorder};
    border-radius: ${theme.metrics.borderRadius}px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  #form-cash {
    display: flex;
    flex-direction: column;
  }

  .btn-custom {
    width: 120px;
  }

  .error {
    color: #fe2e2e;
    height: 25px;
  }
`;
