import React, { useEffect, useState } from 'react';

import { Span, Table, ValuesWrapper } from './style';

import InputCheckPartial from './input-check-partial/input-check-partial';

import { IHeader } from '../../../interfaces/header';
import { IConference } from '../interfaces/conference';
import { UndoValuesRecord } from './undo-values-record';
import UndoIcon from '../../../../assets/images/icon-desfazer.svg';

export interface ICheckPartial {
  tipoMovimento: string;
  valorRegistradoPdv: number;
  totalConferido: number;
  show: boolean;
  category:
  | 'money'
  | 'withDraw'
  | 'creditCard'
  | 'debitCard'
  | 'creditSale'
  | 'agreementSale'
  | 'qrLinx'
  | 'pix';
}

function ConferenciaParcialComponent(props: {
  checkPartial: ICheckPartial[];
  conferenceListScanner: IConference[];
  updateCheckPartial: any;
  updateConferenceList: any;
  hasUnsavedValues: any;
  conferenciaCega: boolean;
  setFocusBtnSuccess: any;
}) {
  const headers = [
    {
      field: 'tipoMovimento',
      label: 'Tipo de Movimento',
      show: true,
      spanValue: false,
      inputValue: false,
    },
    {
      field: 'valorRegistradoPdv',
      label: 'Registrado no PDV',
      show: !props.conferenciaCega,
      spanValue: true,
      inputValue: false,
      style: { border: '0px', textAlign: 'right' },
    },
    {
      field: 'totalConferido',
      label: 'Total Conferido Acumulado',
      show: true,
      spanValue: true,
      inputValue: false,
      style: { textAlign: 'right', paddingRight: '.5rem' },
    },
    {
      field: 'aConferir',
      label: 'Adicionar valores',
      show: true,
      spanValue: false,
      inputValue: true,
      style: { textAlign: 'right' },
    },
  ] as IHeader[];

  const [showUndoValuesRecord, setShowUndoValuesRecord] = useState<boolean>(
    false
  );
  const [valuesToCheck, setValuesToCheck] = useState<any>({});
  const [dataToUndoValues, setDataToUndoValues] = useState<any>({});

  useEffect(() => {
    if (Object.keys(valuesToCheck).length > 0) {
      props.hasUnsavedValues(true);
    }
  }, [valuesToCheck]);

  useEffect(() => {
    const listScanner = new Map();

    props.conferenceListScanner?.forEach((item) => {
      if (!listScanner.has(item.id)) {
        listScanner.set(item.id, item);
      }
    });

    if ([...listScanner.values()].length > 0 && props.updateConferenceList) {
      let objectValues = valuesToCheck;

      [...listScanner.values()].map((item) => {
        let barcodeValues = [
          { inputType: 'barcode', checkValue: item.value, id: item.id },
        ];

        if (valuesToCheck[item.category]?.length > 0) {
          if (valuesToCheck[item.category].some((x) => x.id === item.id))
            return;
        }

        if (objectValues.hasOwnProperty([item.category])) {
          objectValues[item.category].concat(barcodeValues);

          setValuesToCheck((old) => ({
            ...old,
            [item.category]: old[item.category].concat(barcodeValues),
          }));
        } else {
          Object.assign(objectValues, { [item.category]: barcodeValues });

          setValuesToCheck((old) => ({
            ...old,
            [item.category]: barcodeValues,
          }));
        }
      });
    }
  }, [props.updateConferenceList]);

  const addValueToCheckPartial = (index: number, value: number) => {
    let checkPartialArray = [
      ...props.checkPartial.filter((h) => h.show).map((row, indexRow) => {
        if (index === indexRow) {
          row.totalConferido = row.totalConferido + value;

          if (valuesToCheck.hasOwnProperty([row.category])) {
            setValuesToCheck((old) => ({
              ...old,
              [row.category]: old[row.category].concat([
                { inputType: 'manual', checkValue: value },
              ]),
            }));
          } else {
            setValuesToCheck((old) => ({
              ...old,
              [row.category]: [{ inputType: 'manual', checkValue: value }],
            }));
          }
        }

        return row;
      }),
    ];

    props.updateCheckPartial(checkPartialArray);
  };

  const undoValuesRecord = (item) => {
    valuesToCheck[item.category].map((value, i) => {
      return { ...value, id: i };
    });

    let values = valuesToCheck[item.category];
    setDataToUndoValues({
      category: item.category,
      tipoMovimento: item.tipoMovimento,
      valuesToCheck: values,
      item: item,
    });

    setShowUndoValuesRecord(true);
  };

  const removeValue = (index, category) => {
    const newValues = valuesToCheck[category].filter(
      (item) => valuesToCheck[category].indexOf(item) !== index
    );

    setValuesToCheck((old) => ({
      ...old,
      [category]: newValues,
    }));
  };

  const undoIsDisabled = (check: ICheckPartial) => {
    let checkValues = valuesToCheck[check.category]?.length > 0;

    if (checkValues) {
      return false;
    } else {
      return true;
    }
  };

  const handleClickCancel = () => {
    setValuesToCheck((old) => ({
      ...old,
      [dataToUndoValues.category]: dataToUndoValues.valuesToCheck,
    }));

    setShowUndoValuesRecord(false);
  };

  const handleClickConfirm = (newItemValues) => {
    let newItemValuesArray = [
      ...props.checkPartial.map((row) => {
        if (newItemValues.category === row.category) {
          row.totalConferido = newItemValues.totalConferido;
        }

        return row;
      }),
    ];

    props.updateCheckPartial(newItemValuesArray);
    setShowUndoValuesRecord(false);
  };

  function setMaskMoneyToSpan(value: number) {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  }

  function setClass(check: ICheckPartial) {
    const conferred = Math.round(check.totalConferido * 100) / 100;
    const registered = Math.round(check.valorRegistradoPdv * 100) / 100;

    return conferred === registered && !props.conferenciaCega
      ? 'conferencia-aberta-ok'
      : conferred > registered && !props.conferenciaCega
        ? 'conferencia-aberta-warning'
        : !props.conferenciaCega
          ? 'conferencia-aberta-error'
          : '';
  }

  return (
    <>
      {showUndoValuesRecord && (
        <UndoValuesRecord
          title={'Desfazer valores adicionados'}
          dataToUndoValues={dataToUndoValues}
          dataValues={valuesToCheck}
          removeValue={removeValue}
          handleClickConfirm={handleClickConfirm}
          handleClickCancel={handleClickCancel}
        />
      )}
      <Table data-testid={'table-id-conferencia'}>
        <thead>
          <tr>
            {headers
              .filter((h) => h.show)
              .map((header, index) => {
                return <th key={index}>{header.label}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          {props.checkPartial.length &&
            props.checkPartial
              .filter((c) => c.show)
              .map((check, index) => {
                return (
                  <tr key={index}>
                    {headers
                      .filter((h) => h.show)
                      .map((header, idxHeader) => {
                        if (header.spanValue)
                          return (
                            <td key={idxHeader}>
                              <ValuesWrapper
                                disabledIcon={undoIsDisabled(check)}
                                style={header.style}
                              >
                                <Span
                                  className={setClass(check)}
                                  style={header.style}
                                >
                                  {setMaskMoneyToSpan(check[header.field])}
                                </Span>
                                {header.field === 'totalConferido' && (
                                  <img
                                    src={UndoIcon}
                                    title='Desfazer valores'
                                    onClick={
                                      undoIsDisabled(check)
                                        ? () => { }
                                        : () => undoValuesRecord(check)
                                    }
                                  />
                                )}
                              </ValuesWrapper>
                            </td>
                          );

                        if (header.inputValue)
                          return (
                            <td key={idxHeader}>
                              <InputCheckPartial
                                index={index}
                                setFocusBtnSuccess={props.setFocusBtnSuccess}
                                handleClickBtnAdd={addValueToCheckPartial}
                              />
                            </td>
                          );

                        return <td key={idxHeader}>{check[header.field]}</td>;
                      })}
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </>
  );
}

export default ConferenciaParcialComponent;
