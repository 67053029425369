import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { currencyMask } from '../../utils/masks';
import { PaymentButtonListWrapper } from './style';
import { Card } from '../modules';
import getShortcut, { DEBIT, TEF_CREDIT, CREDIT, TEF_DEBIT, MONEY, CREDIT_SALE, BUSINESS_AGREEMENT, PIX } from '../../../keyboardShortcuts.js';

class PaymentButtonList extends Component {

  PaymentTypeIcons = {
    '/payment/debit': './assets/images/payment-types/debito.svg',
    '/payment/credit/tef': './assets/images/payment-types/creditcard.svg',
    '/payment/credit': './assets/images/payment-types/creditcard.svg',
    '/payment/debit/tef': './assets/images/payment-types/debito.svg',
    '/payment/money': './assets/images/payment-types/dinheiro.svg',
    '/payment/pix': './assets/images/payment-types/pix.png',
    '/payment/creditSale': './assets/images/payment-types/creditSale.svg',
    '/payment/businessAgreement': './assets/images/payment-types/businessAgreement.svg',
  };

  PaymentTypeShortCuts = {
    '/payment/debit': getShortcut(DEBIT).keys,
    '/payment/credit/tef': getShortcut(TEF_CREDIT).keys,
    '/payment/credit': getShortcut(CREDIT).keys,
    '/payment/debit/tef': getShortcut(TEF_DEBIT).keys,
    '/payment/money': getShortcut(MONEY).keys,
    '/payment/pix': getShortcut(PIX).keys,
    '/payment/creditSale': getShortcut(CREDIT_SALE).keys,
    '/payment/businessAgreement': getShortcut(BUSINESS_AGREEMENT).keys
  };

  getIcon = (enumValue, url) => {
    if (enumValue === 12) {
      return './assets/images/payment-types/QR_Code.png';
    }
    return this.PaymentTypeIcons[url];
  }

  onHandleKey = (e) => {
    e.preventDefault();

    const code = e.keyCode || e.which;

    if ((code === 9 && !e.shiftKey) || (code === 39 || code === 40)) {
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
        e.preventDefault();
      }
    } else if ((code === 9 && e.shiftKey) || (code === 37 || code === 38)) {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
        e.preventDefault();
      }
    } else if (code === 13) {
      e.preventDefault();
      e.target.click();
    }
  }

  componentDidMount() {
    const { firstChild } = document.getElementById('list-payments');

    if (!firstChild) {
      return;
    }

    firstChild.focus();

    const elems = document.getElementById('list-payments');

    elems.addEventListener('keydown', this.onHandleKey);
  }

  render() {
    const paymentTypesArr = this.props.paymentTypeList;
    if (_.isEmpty(paymentTypesArr)) {
      return null;
    }

    const currentPaymentTypeId = _.get(this.props.order, 'order.paymentType.id', null);

    let paymentTypeBtnClass;

    return (
      <Card title="Meios de Recebimento" handleClose={this.props.handleClose} closeEnable>
        <PaymentButtonListWrapper id="list-payments">
          {paymentTypesArr.filter(type => type.availableAmount > 0 && type.enumValue !== 99 && type.url !== '')
            .map((paymentType, index) => {
              paymentTypeBtnClass = `list-button ${paymentType.id === currentPaymentTypeId ? ' list-button--actived' : ''}`;

              return (
                <Fragment key={index}>
                  <KeyboardEventHandler
                    handleKeys={[this.PaymentTypeShortCuts[paymentType.url]]}
                    handleFocusableElements
                    onKeyEvent={() => { this.props.handlerOnPaymentType(paymentType); }}
                  />
                  <button
                    className={paymentTypeBtnClass}
                    id={index}
                    key={`${index}__`}
                    onClick={() => { this.props.handlerOnPaymentType(paymentType); }}
                    tabIndex="0"
                  >
                    <div className="description-group">
                      <img src={this.getIcon(paymentType.enumValue, paymentType.url)} className="item-icon" alt="" />
                      <span className="description">{`${paymentType.name}`}</span>
                      <span className="shortcuts-text">{`(${[this.PaymentTypeShortCuts[paymentType.url]]})`}</span>
                    </div>
                    <span className="item-total">
                      {currencyMask(paymentType.availableAmount).substring(2)}
                    </span>
                  </button>
                </Fragment>
              );
            })
          }
        </PaymentButtonListWrapper>
      </Card>
    );
  }
}

export default PaymentButtonList;
