/* eslint-disable no-useless-constructor */

import React from 'react';
import { SlideToggleWrapper } from './style';

export class SlideToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SlideToggleWrapper id={this.props.id} style={this.props.style} disabled={this.props.disabled}>
        <input
          {...this.props}
          id={this.props.id ? 'input_' + this.props.id : undefined}
          type="checkbox"
        />
        <span className="slider round" />
      </SlideToggleWrapper>
    );
  }
}

export default SlideToggle;
