import AbstractService from './abstractService';

class DeliveryService extends AbstractService {
  constructor() {
    super('/Delivery');
  }

  deliveryShow = () => this.invokeAction('/DeliveryControlStart');  
  deliveryFind = (query, deliveryStatus) => this.invokeAction('/GetDelivery',  query, deliveryStatus );  
  updateCustomer = (deliveryInfo, saveRegistration) => this.invokeAction('/UpdateDeliveryCustomer',  {deliveryInfo, saveRegistration});
  showDeliveryInfo = () => this.invokeAction('/DeliveryInfoShow'); 
  updateDeliveryStatus = (orders, deliveryStatus) => this.invokeAction('/UpdateDeliveryStatus', {orders, deliveryStatus} );  
  getStatusPaymentLink = (orders) => this.invokeAction('/GetStatusPaymentLink', {orders} );
  printDelivery = (orders) => this.invokeAction('/PrintDelivery', {orders} );  
  cancelOrder = (order) => this.invokeAction('/CancelOrder', {order} ); 
  handleRefreshPaymentLink = (orderContract) => this.invokeAction('/RefreshPaymentLink', {orderContract} ); 
  paymentLinkSaleDone = (order) => this.invokeAction('/PaymentLinkSaleDone', {order} );  
}

export default DeliveryService;