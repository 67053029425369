import Store from "../../redux/store/index";

var subscribed = null;

function satConfig(event) {
    switch (event) {
        case 'pos.reload':
        case 'pos.documentNumber.show':
        case 'payment.done':
        case 'pos.order.deleted':
            verifiySat();
            break;
        default:
    }
}

function verifiySat() {
    if (window.desktopApp) {

        window.desktopApp.publish('machine.devices.tef.getTypeTef', '');
        if (subscribed === null) {
            subscribed = window.desktopApp.subscribe('machine.devices.desktop.config', handleDesktopApp);
            window.desktopApp.subscribe('machine.devices.sat.test.success', handleSatTestSuccess);
            window.desktopApp.subscribe('machine.devices.sat.test.error', handleSatTestError);
        }
    }
}

function handleDesktopApp(canal, payload) {
    if (payload.danfe.fiscalType === 2) {
        window.desktopApp.publish('machine.devices.sat.test', '');
    }
}


function handleSatTestSuccess(payload) {
    console.log("Teste S@T realizado com sucesso.");

    Store.dispatch({
        type: 'CHECK_STATUS_SAT',
        payload: true
    });
}

function handleSatTestError(payload) {
    console.log("Falha ao realizar o teste S@T");

    Store.dispatch({
        type: 'CHECK_STATUS_SAT',
        payload: false
    });
}

export default satConfig;