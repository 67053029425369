import React from 'react';
import PropTypes from 'prop-types';

const Tfoot = ({
  items, colspan, title, label, Component,
}) => (
  <tfoot>
    <tr>
      <td colSpan={colspan}>
        {title && <span className="table-tfoot--title">{title}</span>}
        {label && <span className="table-tfoot--label">{label}</span>}
        {Component && <Component items={items} />}
      </td>
    </tr>
  </tfoot>
);
Tfoot.propTypes = {
  items: PropTypes.array,
  colspan: PropTypes.number,
  title: PropTypes.string,
  label: PropTypes.string,
  Component: PropTypes.func,
};

export default Tfoot;
