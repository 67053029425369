import React from 'react';

import { Switch, Route, Router } from 'react-router-dom';

import SalePage from '../pages/Farma/sale';
import history from './history';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={SalePage} />
    </Switch>
  </Router>
);

export default Routes;
