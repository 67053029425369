import React from 'react';
import { Card, Button, SlideToggle, TooltipIcon } from '../modules';
import { Form, Row } from 'react-bootstrap';
import { RadioButton } from '../radio-button';
import './config-terminal-component.scss';
import { Tab, Tabs } from 'react-bootstrap';

function ConfigTerminalComponent(props) {
  const {
    handleClose,
    handleChange,
    handleConfirm,
    form,
    handleToggleKioskChange,
    handleChangeType,
    handleChangeDocument,
    handleChangePrinterOption,
    handleToggleConfigFromBdChange
  } = props;

  return (
    <Card id="config-terminal-card" autoScroll={false} title="Configurações do terminal" handleClose={handleClose} closeEnable shouldFlex >
      <div className="body">
        <Tabs defaultActiveKey="variaveis" id="tabs">
          <Tab eventKey="variaveis" title="Variáveis de acesso">
            <Form.Row>
              <label class="label-title">Variáveis de acesso</label>
            </Form.Row>
            <Form.Row>
              <label class="label-text">Retailer ID</label>
              <Form.Control
                id="retailerId"
                defaultValue={form?.retailerId}
                onChange={handleChange}
                disabled='true'
              />
            </Form.Row>
            <Form.Row>
              <label class="label-text">URL</label>
              <Form.Control
                id="posType"
                defaultValue={form?.url}
                onChange={handleChange}
                disabled='true'
              />
            </Form.Row>
            <Form.Row>
              <RadioButton onChange={handleChangeType} class="radio" title="Tipo" values={[{ description: 'Balcão', value: 1, selected: form?.posType === 1 }, { description: 'Caixa', value: 2, selected: form?.posType === 2 }]} />
            </Form.Row>
            <Form.Row className="align-items-center">
              <label className="label-text">Pos ID</label>
            </Form.Row>
            <Form.Row className="align-items-center">
              <Form.Control
                className="mr-2"
                id="posId"
                defaultValue={form?.posId}
                onChange={handleChange}
              />
              <TooltipIcon position="top">É necessário cadastrar o terminal no ambiente do retaguarda, acessando: Cadastro &gt; Caixa.</TooltipIcon>
            </Form.Row>
          </Tab>
          <Tab eventKey="emissaoDocumentos" title="Emissão de documentos">
            <label class="label-title">Emissão de documentos</label>
            <Form.Row>
              <RadioButton onChange={handleChangeDocument} class="radio" title="Tipo" values={[{ description: 'NFC-e', value: 1, selected: form?.danfe?.fiscalType === 1 }, { description: 'SAT', value: 2, selected: form?.danfe?.fiscalType === 2 }]} />
            </Form.Row>
            {form?.danfe?.fiscalType === 2 &&
              <Form.Row>
                <label class="label-text">CNPJ Homologação - SAT</label>
                <Form.Control
                  id="cnpjHomolog"
                  defaultValue={form?.danfe?.sat?.cnpjHomolog}
                  onChange={handleChange}
                />
              </Form.Row>
            }
            <Form.Row>
              <label class="label-title subtitle-left">Outras configurações</label>
            </Form.Row>
            <Form.Row style={{ alignItems: 'center' }}>
              <SlideToggle controlId="kiosk" class="toggle"
                style={{
                  alignSelf: 'center',
                  marginLeft: '5px',
                  marginBottom: 'unset'
                }}
                onChange={handleToggleKioskChange}
                checked={form?.window?.kiosk}
                id="option"
              />
              <label class="label-text mx-2">Habilitar modo tela cheia</label>
              <TooltipIcon position="right">Permite que o PDV seja apresentado em modo tela cheia. Após salvar a configuração é necessário fechar o sistema e abrir novamente.</TooltipIcon>
            </Form.Row>
            <Form.Row style={{ alignItems: 'center' }}>
              <SlideToggle controlId="useConfigsFromBD" class="toggle"
                style={{
                  alignSelf: 'center',
                  marginLeft: '5px',
                  marginBottom: 'unset'
                }}
                onChange={handleToggleConfigFromBdChange}
                checked={form?.useConfigsFromBD}
                id="option"
              />
              <label class="label-text mx-2">Usar configuração do cadastro de terminal</label>
              <TooltipIcon position="right">Quando ativo, irá usar PosType e FiscalType do cadastro do terminal no retaguarda, ao invés de pegar do arquivo config.json.</TooltipIcon>
            </Form.Row>
          </Tab>
          <Tab eventKey="impressao" title="Impressão">
            <label class="label-title">Impressão</label>
            <Form.Row className="radioPrinter">
              <RadioButton onChange={handleChangePrinterOption} class="radio" title="Impressão de cupom fiscal" values={[{ description: 'Sempre imprimir', value: 1, selected: form?.danfe?.printerOption === 1 }, { description: 'Imprimir apenas em vendas Crediário, Convênio e PBM', value: 2, selected: form?.danfe?.printerOption === 2 }, { description: 'Não imprimir', value: 3, selected: form?.danfe?.printerOption === 3 }]} />
            </Form.Row>
            <Form.Row>
              <div className="d-flex align-items-center">
                <label class="label-text d-flex mr-2 align-items-center">Impressora</label>
                <TooltipIcon position="right">Inserir o nome da impressora conforme está configurada no sistema operacional.</TooltipIcon>
              </div>
              <Form.Control
                id="name"
                defaultValue={form?.printer?.name}
                onChange={handleChange}
              />
            </Form.Row>
            <Form.Row>
              <label class="label-text">Porta</label>
              <Form.Control
                id="port"
                defaultValue={form?.printer?.port}
                onChange={handleChange}
              />
            </Form.Row>
          </Tab>
        </Tabs>
        <Form>
          <Row className="px-2">



          </Row>
        </Form>
      </div>
      <div className="footer">
        <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
      </div>
    </Card>
  );
}
export default ConfigTerminalComponent;
