import styled from 'styled-components';

export const ListItemWrapper = styled.li`
    list-style-type: none;    
    padding: 0 16px 4px 0;
`;

export const ListItemContentWrapper = styled.div`
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 3px 6px #cfcfd1;
    margin-bottom: 2px;
    border: 1px solid #eee;
    &:focus {
        background-color: #FFB200;
        outline: none;
    }
    &:hover{        
        background-color: #FFB200;
        cursor: pointer;
    }

`;

export const ListWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2% 3% 0 5%;
    font-weight: bold;
`;
