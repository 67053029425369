import React from 'react';
import ButtonItemWrapper from './style';


const ButtonItem = ({
  handleClick, actived, label, children, ...attrs
}) => (
  <ButtonItemWrapper>
    <button
      {...attrs}
      className={`button ${actived ? 'button--actived' : ''} ${label > 5 ? 'button--text-overflowed' : ''} `}
      onClick={handleClick}
    >
      {' '}
      {children}
      {' '}
      {label}
      {' '}

    </button>
  </ButtonItemWrapper>
);

export default ButtonItem;
