import React, { Component, Fragment } from 'react';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import OperatorInput from '../../shared/components/operator-input';
import { RequireAuth } from '../../shared/components/modules';
import SaleService from '../../services/saleService';
import { connect } from 'react-redux';
import {
  identifySeller,
  identifySellerId,
} from '../../redux/actions/orderAction';
import AuthService from '../../services/authService';
import Configuration from '../../configuration';
import { payloadToBase64Zip } from '../../shared/utils/util';
import Store from '../../redux/store';
import { HAS_CONFIG_USER_FP } from '../../redux/actions/actionTypes';
import { ConfigService } from '../../services/configService';

class OperatorContainer extends Component {
  constructor(props) {
    super(props);
    this.identifySeller = this.identifySeller.bind(this);
    this.handleOptionLogout = this.handleOptionLogout.bind(this);
    this.disidentifySeller = this.disidentifySeller.bind(this);
    this.disidentifySellerFail = this.disidentifySellerFail.bind(this);
    this.handleClickEscape = this.handleClickEscape.bind(this);
    this.clickToogleMenu = this.clickToogleMenu.bind(this);
    this.saleService = new SaleService();

    this.subscription = null;

    this.state = {
      isExpanded: false,
      isToggleOnInputOperator: false,
      operatorName: null,
      hasUserLogged: false,
      requireAuth: false,
    };

    this.props.MessageBus.Subscribe(
      'pos.seller.identified',
      this.identifySeller.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.order.updated',
      this.orderUpdate.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.seller.identifyFailed',
      this.identifySellerFail.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.close.success',
      this.disidentifySeller.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.order.deleted',
      this.disidentifySeller.bind(this)
    );
    this.props.MessageBus.Subscribe(
      'pos.seller.disidentifyFailed',
      this.disidentifySellerFail.bind(this)
    );
    this.props.MessageBus.Subscribe('sale.done', this.disidentifySeller);
  }

  componentWillUnmount() {
    this.clearSubscription();
  }

  orderUpdate(ch, payload) {
    this.setState({
      operatorName: payload.currentSellerName,
      hasUserLogged: true,
      isExpanded: false,
    });
  }

  disidentifySeller() {
    if (this.props.shouldAuthenticate) return;
    this.props.identifySeller(null);
    this.setState({
      isExpanded: false,
      isToggleOnInputOperator: false,
      operatorName: null,
      hasUserLogged: false,
    });
  }

  disidentifySellerFail(ch, payload) {
    this.context.showMessage(payload.reason);
  }

  identifySeller(canal, payload) {
    let sellerName = payload ? payload.sellerName : payload;
    let sellerId = payload ? payload.referenceId : payload;

    this.props.identifySeller(sellerName);
    this.props.identifySellerId(sellerId);

    this.setState({
      operatorName: sellerName,
      hasUserLogged: true,
      isExpanded: false,
    });
    // trecho de código adicionado para tratar validação de configs de farmacia popular em pos do tipo balcão
    if (!this.props.shouldAuthenticate && payload) {
      var fpConfigService = new ConfigService();
      fpConfigService.getFarmaciaPopularConfig(payload.sellerId).then((res) =>
        Store.dispatch({
          type: HAS_CONFIG_USER_FP,
          payload: res && res.hasFarmaciaPopularConfig,
        })
      );
    }
  }

  identifySellerFail(canal, payload) {
    if (payload.reason === 'User not found') {
      this.context.showMessage('Usuário não encontrado');
    } else {
      this.context.showMessage(payload.reason);
    }
  }

  handleOptionLogout(event) {
    this.setState({
      isExpanded: false,
    });
    if (this.props.shouldAuthenticate) {
      AuthService.logout().then(() => {
        window.sessionStorage.removeItem('Access_Token');
        Store.dispatch({ type: HAS_CONFIG_USER_FP, payload: false });
      });
      return;
    }
    event.preventDefault();
    this.saleService.disidentifySeller();
  }

  Handler(message) {
    this.setState({ operator: message });
  }

  handleClickEscape() {
    if (this.state.isToggleOnInputOperator === true) {
      this.setState({
        isToggleOnInputOperator: false,
      });
    }

    !this.state.isExpanded
      ? document.body.addEventListener('click', this.clickOutsideCloseMenu)
      : document.body.removeEventListener('click', this.clickOutsideCloseMenu);
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.saleService
        .getSeller(e.target.value ? e.target.value : 'null')
        .catch((error) => this.context.showError(error));

      e.target.value = '';
      this.setState({ isExpanded: false, isToggleOnInputOperator: false });
    }
  };

  clickOutsideCloseMenu = ({ path }) => {
    const isInside = path?.some(
      (el) => el.classList && el.classList.contains('operator-area')
    );

    !isInside &&
      this.setState({
        isToggleOnInputOperator: false,
        isExpanded: false,
      });
  };

  clickToogleMenu = () => {
    const isLogged = !!this.state.operatorName;
    !this.state.isExpanded
      ? document.body.addEventListener('click', this.clickOutsideCloseMenu)
      : document.body.removeEventListener('click', this.clickOutsideCloseMenu);

    if (!isLogged) {
      this.setState({
        isToggleOnInputOperator: true,
      });
      return;
    }
    this.setState({
      isExpanded: !this.state.isExpanded,
      isToggleOnInputOperator: false,
    });
  };

  handleOptionChangeUser = () => {
    if (this.props.shouldAuthenticate) {
      this.setState({
        requireAuth: true,
        isExpanded: false,
      });
      return;
    }
    this.setState({
      isToggleOnInputOperator: !this.state.isToggleOnInputOperator,
      isExpanded: false,
    });
  };

  clearSubscription = () => {
    this.subscription && this.props.MessageBus.Unsubscribe(this.subscription);
    this.subscription = null;
  };

  handleLoginSuccess = () => {
    this.clearSubscription();
    this.setState({
      requireAuth: false,
    });
  };

  handleUserChangeConfirm = (form) => {
    !this.subscription &&
      this.props.MessageBus.Subscribe(
        'pos.login.success',
        this.handleLoginSuccess
      );
    const payload = {
      retailerId: Configuration.retailerId,
      posType: this.props.posType,
      posId: Configuration.PosId,
      username: form.operatorRefId,
      password: form.password,
    };
    payloadToBase64Zip(JSON.stringify(payload)).then((res) => {
      AuthService.login(res).then((payload) => {
        Store.dispatch({
          type: HAS_CONFIG_USER_FP,
          payload: payload && payload.hasFarmaciaPopularConfig,
        });
        payload.token &&
          window.sessionStorage.setItem('Access_Token', payload.token);
      });
    });
  };

  handleUserChangeClose = () => {
    this.setState({
      requireAuth: false,
    });
  };

  render() {
    return (
      <Fragment>
        <OperatorInput
          handleClickEscape={this.handleClickEscape}
          handleKeyPress={this.handleKeyPress}
          handleClickToogleMenu={this.clickToogleMenu}
          handleOptionChangeUser={this.handleOptionChangeUser}
          handleOptionLogout={this.handleOptionLogout}
          operatorName={this.state.operatorName}
          isExpanded={this.state.isExpanded}
          isToggleOnInputOperator={this.state.isToggleOnInputOperator}
          hasUserLogged={this.state.hasUserLogged}
        />
        {this.state.requireAuth && (
          <RequireAuth
            title='Trocar usuário'
            handleConfirm={this.handleUserChangeConfirm}
            handleClose={this.handleUserChangeClose}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  shouldAuthenticate: state.util.shouldAuthenticateOperation,
  posType: state.posType.posType,
});

const mapDispatchToProps = (dispatch) => ({
  identifySeller: (seller) => dispatch(identifySeller(seller)),
  identifySellerId: (sellerId) => dispatch(identifySellerId(sellerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(OperatorContainer)));
