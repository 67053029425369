import styled from 'styled-components';

export const ContainerImg = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  h2 {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 131.4%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #5b2e90;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  h4 {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 138.2%;
    text-align: center;
    color: #212529;
  }
`;
