import React, { Component } from 'react';
import Modal from 'react-modal';
import PropType from 'prop-types';
import MessageBoxWrapper from './style';
import { Button } from '../button';
import { overlayService } from '../overlay';
import parse from 'html-react-parser';

class MessageBox extends Component {
  constructor(props) {
    super(props);

    this.keyDown = this.keyDown.bind(this);

    this.customStyle = {
      content: {
        maxWidth: '600px',
        background: '#ffffff',
        margin: 'auto',
        border: 'none',
        top: '18%',
        bottom: 'unset'
      },
      overlay: {
        background: 'rgba(0, 0, 0, 0.60)',
        display: 'block',
        zIndex: '99999',
      },
      iconCloseModal: {
        float: 'right',
        cursor: 'pointer',
      },
    };
  }

  componentDidMount() {
    this.modalRef &&
    setTimeout(() => {
      this.modalRef.addEventListener('keydown', this.keyDown, false);

      if (this.cancelBtnRef) {
        this.cancelBtnRef.focus();
      } else if (this.confirmBtnRef) {
        this.confirmBtnRef.focus();
      }
    });
  }

  keyDown(e) {
    switch (e.key) {
      case 'Escape': {
        e.stopPropagation();

        const action = this.props.handleClose || this.props.handleCancel;

        if (action) {
          action();
        }

        break;
      }
      case 'ArrowLeft': {
        e.stopPropagation();
        this.confirmBtnRef.focus();
        break;
      }
      case 'ArrowRight': {
        e.stopPropagation();
        this.cancelBtnRef.focus();
        break;
      }
      default: break;
    }
  }

  componentWillUnmount() {
    this.modalRef && this.modalRef.removeEventListener('keydown', this.escFunction);
  }

  render() {
    const {
      message,
      handleCancel,
      handleClose,
      handleConfirm,
      okText = 'OK',
      title,
      focusConfirmButton,
      focusCancelButton
    } = this.props;

    const overlayOpened = overlayService.overlayOpened();

    if(overlayOpened) {
      this.customStyle.overlay.background = 'rgba(0, 0, 0, 0.40)';
    }

    if (this.confirmBtnRef && focusConfirmButton) this.confirmBtnRef.focus();
    if (this.cancelBtnRef && focusCancelButton) this.cancelBtnRef.focus();

    return message && (
      <Modal
        contentRef={(ref) => { this.modalRef = ref; }}
        isOpen
        closeOnOverlayClick={false}
        center
        style={this.customStyle}
        ariaHideApp={false}
      >
        { okText !== 'OK' && (
        <span
          className="material-icons attention close-modal"
          style={this.customStyle.iconCloseModal}
          onClick={handleClose || handleCancel}
        >
          cancel
        </span>
        )
      }
        <MessageBoxWrapper>
          <h3 id="title">{title || 'Mensagem do sistema'}</h3>
          <p>{parse(message)}</p>
          <div className="modal__footer">
            <Button
              id="btn-confirm"
              ref={(btn) => { this.confirmBtnRef = btn; }}
              className="btn-default btn-outline"
              type="button"
              onClick={handleConfirm || handleClose || handleCancel}
            >
              {okText}
            </Button>
            { handleCancel && (
              <Button
                id="btn-cancel"
                ref={(btn) => { this.cancelBtnRef = btn; }}
                className="btn-default btn-outline"
                type="button"
                onClick={handleCancel}
              >
                Não
              </Button>
            ) }
          </div>
        </MessageBoxWrapper>
      </Modal>
    );
  }
}

MessageBox.propTypes = {
  message: PropType.string,
  handleClose: PropType.func,
  handleCancel: PropType.func,
  handleConfirm: PropType.func,
  okText: PropType.string,
  focusConfirmButton: PropType.bool,
  focusCancelButton: PropType.bool
};

MessageBox.defaultProps = {
  message: null,
  handleClose: null,
  handleCancel: null,
  handleConfirm: null,
  okText: 'OK',
};

export default MessageBox;
