import React, { Fragment } from 'react';

import { LoaderWrapper } from './style'

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.focusedElement = document.activeElement;
  }

  componentDidMount() {
    this.loaderRef.focus();
  }

  componentWillUnmount() {
    this.focusedElement.focus();
  }

  render() {
    const { text } = this.props;
    return (
      <Fragment>
        <LoaderWrapper tabIndex={0} innerRef={(ref) => this.loaderRef = ref} className="loader-wrapper">
        {/* <div class="lds-ripple"><div></div><div></div></div> */}
          <div className='loader'></div>
          { text && <span className="loader-text">{text}</span> }
        </LoaderWrapper>
      </Fragment>
    );
  }
}

export default Loader