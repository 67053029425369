import { combineReducers } from 'redux';

import connectionPool from '../modules/connectionPool/duck';
import customerRegistration from '../modules/customerRegistration/duck';
import message from '../modules/message/duck';
import administrativeMenuReducer from './administrativeMenuReducer';
import cardReducer from './cardReducer';
import configReducer from './configReducer';
import configRetailerReducer from './configRetailerReducer';
import customerReducer from './customerReducer';
import fpStatusReducer from './farmaciaPopularStatusReducer';
import funcionalCardStatusReducer from './funcionalCardStatusReducer';
import posTypeReducer from './posTypeReducer';
import loadingScreenReducer from './loadingScreenReducer';
import modalMessageReducer from './modalMessageReducer';
import orderReducer from './orderReducer';
import paymentReducer from './paymentReducer';
import quantityReducer from './quantityReducer';
import routerReducer from './routerReducer';
import satReducer from './satReducer';
import fiscalFlowReducer from './fiscalFlowReducer';
import shortcutsReducer from './shortcutsReducer';
import utilReducer from './utilReducer';
import retailerReducer from './retailerReducer';
import showAutorizationOnlineReducer from './showAutorizationOnlineReducer';
import pdvContingencyStatusReducer from './pdvContingencyStatusReducer';

const Reducers = combineReducers({
  order: orderReducer,
  quantity: quantityReducer,
  payment: paymentReducer,
  administrativeMenu: administrativeMenuReducer,
  loading: loadingScreenReducer,
  customer: customerReducer,
  shortcuts: shortcutsReducer,
  card: cardReducer,
  viewManager: routerReducer,
  modalMessage: modalMessageReducer,
  util: utilReducer,
  sat: satReducer,
  fiscalFlow: fiscalFlowReducer,
  fp: fpStatusReducer,
  config: configReducer,
  configRetailer: configRetailerReducer,
  message,
  funcionalcard: funcionalCardStatusReducer,
  connectionPool,
  customerRegistration,
  retailer: retailerReducer,
  pdvContingency: pdvContingencyStatusReducer,
  posType: posTypeReducer,
  showAutorizationOnline: showAutorizationOnlineReducer
});

export default Reducers;
