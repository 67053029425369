import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input';

import getShortcut, { DELETE } from '../../../keyboardShortcuts.js';
import { currencyMask } from '../../utils/masks';
import { Button, Card } from '../modules';
import { ShortcutHint } from '../shortcut-hint/shortcut-hint';

import {
  DetailWrapper,
  Sumary,
  DiscountInfoWrapper,
  DetailItem,
  Details,
  AsideLeft,
  Header,
  Footer,
} from './style';
import OfferCoverage from '../offer-coverage/index.js';
import IncreasePrice from '../increase-price/index.js';
import Stock from '../../../assets/images/stock-sb.png';

class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
    };
  }

  componentDidUpdate() {
    // Setando a quantidade no state devido a erros sendo lançados:
    // "A component is changing an uncontrolled input of type undefined to be controlled"
    if (this.state.quantity !== this.props.itemSelected.quantity) {
      this.setState({
        quantity: this.props.itemSelected.quantity,
      });
    }
  }

  componentDidMount() {
    this.setState({ quantity: this.props.itemSelected.quantity });
  }

  escape() {
    const comp = document.querySelector('.item-list .item-wrapper.active');

    if (comp) {
      comp.focus();
    }
  }

  render() {
    const { applicableDiscount } = this.props.itemSelected;
    const { appliedDiscount } = this.props.itemSelected;

    const {
      handleClose,
      sendToUpdate,
      sendToDelete,
      handleKeyPress,
      children,
      errorMessage,
      itemSelected,
      increaseValueTotal,
      showIncreaseValueValidationMessage,
      order,
      productHasDiscount,
      subTotalDiscountError,
      handleChangeQtt,
      handleChangeDiscount,
      handleChangeSubTotal,
      handleOpenModalOfferCoverage,
      handleOpenModalIncreasePrice,
      handleCloseModalOfferCoverage,
      handleCloseModalIncreasePrice,
      handleConfirmIncreasePrice,
      handleChangeIncreaseValue,
      handleChangeOfferModal,
      handleChangeDiscountPercentOffer,
      handleChangeSubTotalOffer,
      handleFindUser,
      handleConfirmOfferCoverage,
      verifyDiscountPercentageLimits,
      offerModalIsVisible,
      increasePriceModalIsVisible,
      loggedUserHasPermission,
      userWithoutPermission,
      messageErrorOfferCoverage,
      inputError,
      discountPercentOffer,
      subTotalOffer,
      reasonDiscountOffer,
      codeUserOffer,
      nameUserOffer,
      passwordUserOffer,
      showAutorizationOnline,
      openStockList,
      showAditionalInfo,
      margemLucro
    } = this.props;

    const errorMin = errorMessage === 'min';
    const errorMax = errorMessage === 'max';

    const subTotalMinError = subTotalDiscountError === 'min';
    const subTotalMaxError = subTotalDiscountError === 'max';

    let showButtonOfferCoverage = true;
    let showButtonIncreasePrice = true;

    if (
      order.infoLinxConecta ||
      order.infoPbm ||
      itemSelected.appliedDiscount.isOfferCoverage
    ) {
      showButtonIncreasePrice = false;
    }

    if (
      itemSelected.applicableDiscount?.discountType === 14 ||
      itemSelected.applicableDiscount?.discountType === 24 ||
      order.infoLinxConecta ||
      order.infoPbm ||
      itemSelected.increasedPriceValue > 0
    ) {
      showButtonOfferCoverage = false;
    }
    const cardFooter = (
      <Footer>
        <div className='under-foot'>
          <Button
            id='estoque'
            type='button'
            className='btn-stock'
            onClick={openStockList}
          >
            <img src={Stock}></img>ESTOQUE DE FILIAIS
          </Button>
          {showButtonIncreasePrice && (
            <Button
              id='btn-increase-price'
              type='submit'
              className='btn-stock'
              onClick={handleOpenModalIncreasePrice}
            >
              ACRÉSCIMO
            </Button>
          )}
          {showButtonOfferCoverage && (
            <Button
              id='btn-offer-coverage'
              type='submit'
              className='btn-stock'
              onClick={handleOpenModalOfferCoverage}
            >
              COBRIR OFERTA
            </Button>
          )}
        </div>
        <div>
          <Button
            id='delete'
            type='submit'
            className='btn-outline btn-custom'
            handleClick={sendToDelete}
          >
            <ShortcutHint
              shortcut={getShortcut(DELETE)}
              activateShortcut={sendToDelete}
            />
            EXCLUIR
          </Button>
          <Button
            id='save'
            type='submit'
            className='btn-purple btn-custom'
            handleClick={sendToUpdate}
          >
            SALVAR
          </Button>
        </div>
      </Footer>
    );
    return (
      <Card
        title='DETALHES'
        handleClose={handleClose}
        closeEnable
        escAction={this.escape}
        footer={cardFooter}
      >
        {increasePriceModalIsVisible ? (
          <IncreasePrice
            handleCloseModalIncreasePrice={handleCloseModalIncreasePrice}
            handleConfirmIncreasePrice={handleConfirmIncreasePrice}
            handleChangeIncreaseValue={handleChangeIncreaseValue}
            increaseValueTotal={increaseValueTotal}
            showIncreaseValueValidationMessage={showIncreaseValueValidationMessage}
            itemSelected={itemSelected}
          />
        ) : (
          ''
        )}
        {offerModalIsVisible ? (
          <OfferCoverage
            handleOpenModalOfferCoverage={handleOpenModalOfferCoverage}
            handleOpenModalIncreasePrice={handleOpenModalIncreasePrice}
            handleCloseModalOfferCoverage={handleCloseModalOfferCoverage}
            handleChangeOfferModal={handleChangeOfferModal}
            handleChangeDiscountPercentOffer={handleChangeDiscountPercentOffer}
            handleChangeSubTotalOffer={handleChangeSubTotalOffer}
            handleFindUser={handleFindUser}
            handleConfirmOfferCoverage={handleConfirmOfferCoverage}
            verifyDiscountPercentageLimits={verifyDiscountPercentageLimits}
            loggedUserHasPermission={loggedUserHasPermission}
            userWithoutPermission={userWithoutPermission}
            offerModalIsVisible={offerModalIsVisible}
            messageErrorOfferCoverage={messageErrorOfferCoverage}
            inputError={inputError}
            discountPercentOffer={discountPercentOffer}
            subTotalOffer={subTotalOffer}
            reasonDiscountOffer={reasonDiscountOffer}
            codeUserOffer={codeUserOffer}
            nameUserOffer={nameUserOffer}
            passwordUserOffer={passwordUserOffer}
            productPrice={itemSelected.productPrice}
            factoryPrice={itemSelected.productFactoryPrice}
            showAutorizationOnline={showAutorizationOnline}
            showAditionalInfo={showAditionalInfo}
            margemLucro={margemLucro}
          />
        ) : (
          ''
        )}
        <DetailWrapper>
          <Header>
            <DetailItem className='header'>
              <h4>Descrição do produto</h4>
              <span>{itemSelected.productName}</span>
            </DetailItem>
            <DetailItem className='header'>
              <h4>Vendedor</h4>
              <span>
                {itemSelected.sellerReferenceId} - {itemSelected.sellerName}
              </span>
            </DetailItem>
          </Header>

          <Details>
            <DetailItem>
              <h4>Valor unitário</h4>
              <span>{currencyMask(itemSelected.productPrice)}</span>
              {itemSelected.increasedPriceValue > 0 ?
                <div className='groupIncreasePrice'>
                  <span
                    className="errorMessage green-colorIncreasePrice"
                  >
                    <span>
                      De {currencyMask(itemSelected.originalPriceValue)} por {currencyMask(itemSelected.increasedPriceValue)} acréscimo de {currencyMask(itemSelected.increasedPriceValue - itemSelected.originalPriceValue)}
                    </span>
                  </span>
                </div>
                : ''}
            </DetailItem>
            <DetailItem>
              <h4>Quantidade</h4>
              <input
                id='qtd'
                type='number'
                disabled={
                  itemSelected.applicableDiscount?.discountType === 24 ||
                  itemSelected.applicableDiscount?.discountType === 14 ||
                  (itemSelected.isFromPbm && order.infoPbm?.pbmEnum === 75000)
                }
                maxLength={9}
                ref={(ref) => (this.inputRef = ref)}
                onKeyPress={handleKeyPress}
                value={this.state.quantity}
                onChange={handleChangeQtt}
              />
            </DetailItem>
            <DetailItem>
              {!productHasDiscount && (
                <small className='no_discount_info'>
                  Produto não possui desconto
                </small>
              )}
              <h4>% Desconto</h4>
              <CurrencyInput
                id='inputDiscount'
                allowNegative
                disabled={!productHasDiscount}
                suffix='%'
                value={
                  productHasDiscount ? itemSelected.appliedDiscount.value : 0
                }
                className='discount-input'
                decimalSeparator=','
                maxLength='6'
                thousandSeparator='.'
                onKeyPress={handleKeyPress}
                onChange={handleChangeDiscount}
              />
              {applicableDiscount ? (
                <DiscountInfoWrapper>
                  <div className='group'>
                    <span
                      className={`errorMessage ${appliedDiscount.isOfferCoverage
                        ? 'disabled-color'
                        : errorMin
                          ? 'red-color'
                          : 'green-color'
                        }`}
                    >
                      <span>Mínimo:</span>
                      <span>{`${applicableDiscount.minimumPercentage.toFixed(
                        2
                      )}% `}</span>
                    </span>
                    <span
                      className={`errorMessage ${appliedDiscount.isOfferCoverage
                        ? 'disabled-color'
                        : errorMax
                          ? 'red-color'
                          : 'green-color'
                        }`}
                    >
                      <span>Máximo:</span>
                      <span>{`${applicableDiscount.maximumPercentage.toFixed(
                        2
                      )}%`}</span>
                    </span>
                    {appliedDiscount.isOfferCoverage ? (
                      <span className='green-color'>
                        <span>Cobertura de oferta: </span>
                        <span>{`${appliedDiscount.percentageDiscountValue.toFixed(
                          2
                        )}%`}</span>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </DiscountInfoWrapper>
              ) : (
                <DiscountInfoWrapper>
                  <div className='group'>
                    {appliedDiscount.isOfferCoverage ? (
                      <span className='green-color'>
                        <span>Cobertura de oferta: </span>
                        <span>{`${appliedDiscount.percentageDiscountValue.toFixed(
                          2
                        )}%`}</span>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </DiscountInfoWrapper>
              )}
            </DetailItem>
            <DetailItem>
              <h4>Valor Total</h4>
              <CurrencyInput
                id='input-subtotal'
                disabled={!productHasDiscount}
                prefix='R$'
                allowNegative
                value={itemSelected.subTotalDiscount}
                className='subtotal-input'
                decimalSeparator=','
                maxLength={11}
                thousandSeparator='.'
                onKeyPress={handleKeyPress}
                onChange={handleChangeSubTotal}
              />
              {applicableDiscount ? (
                <DiscountInfoWrapper className='subtotal-info'>
                  <div className='group total-value-group'>
                    <span
                      className={`errorMessage ${appliedDiscount.isOfferCoverage
                        ? 'disabled-color'
                        : subTotalMinError
                          ? 'red-color'
                          : 'green-color'
                        }`}
                    >
                      <span>Mínimo:</span>
                      <span>{`R$ ${applicableDiscount.minimumApplicableValue} `}</span>
                    </span>
                    <span
                      className={`errorMessage ${appliedDiscount.isOfferCoverage
                        ? 'disabled-color'
                        : subTotalMaxError
                          ? 'red-color'
                          : 'green-color'
                        }`}
                    >
                      <span>Máximo:</span>
                      <span>{`R$ ${applicableDiscount.maximumApplicableValue}`}</span>
                    </span>
                    {appliedDiscount.isOfferCoverage ? (
                      <span className='green-color'>
                        <span>Cobertura de oferta: </span>
                        <span>{`R$ ${appliedDiscount.totalDiscountValue.toFixed(
                          2
                        )}`}</span>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </DiscountInfoWrapper>
              ) : (
                <DiscountInfoWrapper className='subtotal-info'>
                  <div className='group total-value-group'>
                    {appliedDiscount.isOfferCoverage ? (
                      <span className='green-color'>
                        <span>Cobertura de oferta: </span>
                        <span>{`R$ ${appliedDiscount.totalDiscountValue.toFixed(
                          2
                        )}`}</span>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </DiscountInfoWrapper>
              )}
            </DetailItem>
          </Details>

          <div className='custom-row'>
            <AsideLeft className='aside-left'>
              <div className='aside-item'>
                <h4 className='aside-title'>Linha</h4>
                <span>{itemSelected.productType}</span>
              </div>
              <div className='aside-item'>
                <h4 className='aside-title'>Fabricante</h4>
                <span>{itemSelected.productSupplier}</span>
              </div>
              <div className='aside-item'>
                <h4 className='aside-title'>Estoque</h4>
                <span>{itemSelected.productStock}</span>
              </div>
            </AsideLeft>
            <div className='aside-right'>
              {children}
              <Sumary>
                {productHasDiscount && (
                  <div className='sub subtotal-wrapper'>
                    <label>SUBTOTAL</label>
                    <strong>{currencyMask(itemSelected.subTotal)}</strong>
                  </div>
                )}
                {productHasDiscount && (
                  <div className='sub discount-wrapper'>
                    <label>ECONOMIA DE</label>
                    <strong>
                      {currencyMask(
                        itemSelected.subTotal - itemSelected.subTotalDiscount
                      )}
                    </strong>
                  </div>
                )}

                <div className='sub total-wrapper'>
                  <label>TOTAL</label>
                  <strong>{currencyMask(itemSelected.subTotalDiscount)}</strong>
                </div>
              </Sumary>
            </div>
          </div>
        </DetailWrapper>
      </Card>
    );
  }
}

export default Detail;
