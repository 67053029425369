import { AbstractService } from './abstractService';

export default class SaleService extends AbstractService {
  constructor() {
    super('/SalesEvents');
  }

  addItem = (barcode, quantity = 1) =>
    this.invokeAction('/item', { barcode, quantity });

  showConfigurationMenu = () =>
    this.invokeAction('/showConfigurationMenu');

  openItem = (productId) =>
    this.invokeAction('/startDetailItem', { productId });

  closeItem = (productId) =>
    this.invokeAction('/cancelDetailItem', { productId });

  updateItem = (payload) => this.invokeAction('/updateItem', payload);

  deleteItem = (productId, sequentialId) =>
    this.invokeAction('/deleteItem', { productId, sequentialId });

  getSeller = (referenceId) =>
    this.invokeAction('/identifySeller', { referenceId });

  setValidationResolved = (barcode, type) => this.invokeAction('/setValidationResolved', { barcode, type });

  disidentifySeller = () => this.invokeAction('/disidentifySeller');

  deleteOrder = (reason) => this.invokeAction('/deleteOrder', { reason });

  deleteOrderObjectPro = (reason) =>
    this.invokeAction('/deleteOrderObjectPro', { reason });

  lotControlProductSave = (productId, lot) =>
    this.invokeAction('/lotControlProduct', { productId, lot });

  notifySelectCustomer = (id, referenceId) =>
    this.invokeAction('/identifyCustomer', { id, referenceId });

  deleteOrderById = (id) => this.invokeAction('/deleteOrderById', { id });

  startUpdateItem = (productId, sequentialId) =>
    this.invokeAction('/startUpdateItem', { productId, sequentialId });

  cancelUpdateItem = () => this.invokeAction('/cancelUpdateItem');

  findUser = (referenceId) =>
    this.invokeAction('/identifySeller', { referenceId });

  offerCoverage = (payload) => this.invokeAction('/offerCoverage', payload);

  increasePriceValue = (payload) => this.invokeAction('/increasePriceValue', payload);

  cleanPreOrderCheck = (orderId) => this.invokeAction('/cleanPreOrderCheck', { orderId });

  checkItemPreOrder = (payload) => this.invokeAction('/checkItemPreOrder', payload);

  restartPreOrderCheck = (orderId) => this.invokeAction('/restartPreOrderCheck', { orderId });

  recoverPreOrder = (recoveryId, isFromPreOrderChecking) => this.invokeAction('/recoverPreOrder', { recoveryId, isFromPreOrderChecking });

  continuousUseStart = (payload) => this.invokeAction('/item/continuoususe/start', payload);

  continuousUseCancel = (payload) => this.invokeAction('/item/continuoususe/cancel', payload);

  continuousUseSave = (payload) => this.invokeAction('/item/continuoususe', payload);

  continuousUseCheckFields = (payload) => this.invokeAction('/continuoususe/fields', payload);

  orderValidationShow = (orderId) => this.invokeAction('/order/validations/show', { orderId });

  orderValidationClose = () => this.invokeAction('/order/validations/close');
}
