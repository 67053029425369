import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { showCard, TEF_OPTIONS,AUTHORIZATION_DETAIL, shouldShow, closeCardWithoutHideOthers } from '../../redux/actions/routerAction';
import { show } from '../../redux/actions/loadingScreenAction';
import { TefOptions } from '../../shared/components/modules'
import PaymentService from '../../services/paymentService';

class TefMenuOptionsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { payload: null }
        this.service = new PaymentService();

        if (window.desktopApp) {
            window.desktopApp.subscribe('machine.devices.tef.menuoptions', (channel, data) => {
                this.eventReturn(channel, data);
            });
        }
    }

    mockpayload = {
        header: "Selecione a forma de pagamento",
        options: [
            { key: 1, value: "A Vista" },
            { key: 2, value: "Parcelado Loja" },
            { key: 3, value: "Parcelado pela Administradora" }
        ]
    };

    eventReturn(channel, data) {
        this.setState({
            payload: data
        });
        this.props.showCard();
    }

    handleClick = command => {
        if (window.desktopApp) {
            window.desktopApp.publish('machine.devices.tef.menuoptions.completed', command);
            this.props.closeCard();
        }
    }

    handleClose() {
        //TODO: Add Wait Window

        if (window.desktopApp) {
            this.props.showLoading('Carregando...');
            window.desktopApp.publish('machine.devices.tef.cancel.pending.operation', '');
        }
    }

    render() {
        const visible = this.props.visible;
        const payload = this.state.payload;
        return (
            <div>
                {visible && (
                    <TefOptions
                        items={payload.options}
                        header={payload.header}
                        urlIcone='./assets/images/payment-types/outros.svg'
                        onHandleOption={this.handleClick}
                        handleClose={() => this.handleClose()}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    visible: shouldShow(state, TEF_OPTIONS),
})

const mapDispatchToProps = dispatch => ({
    showCard: () => dispatch(showCard(TEF_OPTIONS)),
    closeCard: () => {
        dispatch(showCard(AUTHORIZATION_DETAIL));
        dispatch(closeCardWithoutHideOthers(TEF_OPTIONS));
    },
    showLoading: param => dispatch(show({ text: param })),
})

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(TefMenuOptionsContainer)));
