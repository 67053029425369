import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { SelectBoxItem, SelectBoxItems, SelectBoxWrapper } from "./style";

export const SelectBox = ({ data, searchTerm, field, setValue, ...props }) => {
  const [showItems, setShowItems] = useState(false);
  const [activeSelecion, setActiveSelecion] = useState(false);
  const [inputFocusOut, setInputFocusOut] = useState(false);
  const [showClearInput, setShowClearInput] = useState(false);
  const [valueSelected, setValueSelected] = useState(false);

  const dataFiltred = data?.filter((item) =>
    item.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const items = document.querySelectorAll("[data-item]");
  const inputSelect = document.querySelector(`[data-input="${field}"]`);

  const handleShowItems = () => {
    setInputFocusOut(false);
    if (!valueSelected) setShowItems(true);
  };

  useEffect(() => {
    if (!activeSelecion && inputFocusOut) setShowItems(false);
  }, [inputFocusOut]);

  const handleSetValue = (item) => {
    setValueSelected(true);
    inputSelect.focus();
    setShowItems(false);
    setShowClearInput(true);
    setActiveSelecion(false);
    setValue(item);
  };

  const handleClearInput = () => {
    setValueSelected(false);
    inputSelect.focus();
    setShowItems(true);
    setShowClearInput(false);
    setActiveSelecion(false);
    setValue();
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    const items2 = document.querySelectorAll(`[data-item]`);
    if (key === "ArrowDown" && items2.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      setActiveSelecion(true);
      items2[0].focus();
    }
    if (key === "Delete") handleClearInput();
    if (key === "Backspace" && searchTerm.length === 1) handleClearInput();
  };

  const handleKeyDownItem = (e, index, item) => {
    const { key } = e;
    e.preventDefault();
    e.stopPropagation();

    if (key === "ArrowDown" && index < items.length - 1)
      items[index + 1].focus();

    if (key === "ArrowUp" && index > 0) items[index - 1].focus();

    if (key === "ArrowUp" && index === 0) inputSelect.focus();

    if (key === "Enter") handleSetValue(item);
  };

  useEffect(() => {
    if (inputSelect?.value) setShowClearInput(true);
  }, [inputSelect]);

  return (
    <SelectBoxWrapper>
      <Field
        data-input={field}
        name={field}
        {...props}
        type="text"
        onFocus={handleShowItems}
        onKeyDown={(e) => handleKeyDown(e)}
        onBlur={() => setInputFocusOut(true)}
      />
      {showClearInput && (
        <i
          className="clear-input-select material-icons"
          onClick={handleClearInput}
        >
          clear
        </i>
      )}
      {showItems && (
        <SelectBoxItems
          onMouseOver={() => setActiveSelecion(true)}
          onMouseOut={() => setActiveSelecion(false)}
        >
          {dataFiltred.map((item, i) => {
            return (
              <SelectBoxItem
                key={i}
                data-item={i}
                tabIndex="0"
                onClick={() => handleSetValue(item)}
                onKeyDown={(e) => handleKeyDownItem(e, i, item)}
              >
                {item.name}
              </SelectBoxItem>
            );
          })}
        </SelectBoxItems>
      )}
    </SelectBoxWrapper>
  );
};

SelectBox.propTypes = {
  data: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
