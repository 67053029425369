import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "../../@form-control";
import { Button } from "../../modules";
import { ContinuousUseItems, GroupContinuousUseItems } from "../style";
import { SelectBox } from "../../select-box";

export const PrescriptionOrigin = ({
  data,
  values,
  setFieldValue,
  handleCreateNew,
  errors,
  touched,
  manage,
}) => {
  const prescriptionOriginSelect = data
    ?.filter(({ active }) => active)
    .map((item) => {
      return { ...item, name: item.description };
    });

  prescriptionOriginSelect.sort((a, b) => {
    return a.name?.localeCompare(b.name);
  });

  const handleSelectPrescriptionOrigin = (value) => {
    setFieldValue("prescriptionOrigin.id", value?.id || "");
    setFieldValue("prescriptionOrigin.referenceId", value?.referenceId || "");
    setFieldValue("prescriptionOrigin.name", value?.name || "");
  };

  useEffect(() => {
    if (manage?.hasOwnProperty("prescriptionOrigin")) {
      let value = {
        ...manage.prescriptionOrigin,
        name: manage.prescriptionOrigin.description,
      };
      handleSelectPrescriptionOrigin(value);
    }
  }, [manage]);

  return (
    <GroupContinuousUseItems>
      <ContinuousUseItems
        width="80%"
        required={values.prescriptionOrigin.required}
        hasError={
          errors.prescriptionOrigin?.name && touched.prescriptionOrigin?.name
        }
      >
        <FormControl
          label="Origem da prescrição"
          id="prescriptionOrigin"
          name="prescriptionOrigin.name"
        >
          <SelectBox
            className="continuous-use-items-input"
            placeholder="Selecione a origem"
            data={prescriptionOriginSelect}
            searchTerm={values.prescriptionOrigin.name}
            field={"prescriptionOrigin.name"}
            setValue={(value) => handleSelectPrescriptionOrigin(value)}
          />
        </FormControl>
      </ContinuousUseItems>
      <ContinuousUseItems flex={1}>
        <Button
          className="btn-outline"
          onClick={() => handleCreateNew("newPrescriptionOrigin")}
        >
          CRIAR NOVA ORIGEM
        </Button>
      </ContinuousUseItems>
    </GroupContinuousUseItems>
  );
};

PrescriptionOrigin.propTypes = {
  data: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCreateNew: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  manage: PropTypes.object.isRequired,
};
