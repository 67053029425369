import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PosInformation from '../../shared/components/pdv-information';
import Configuration from '../../configuration';
import { withMessageBus } from '../../shared/utils/messageBus';
import { connect } from 'react-redux';
import { getContext } from '../../shared/hoc/getContext';

class PosInformationConteiner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PosName: "",
      openingCount: '',
      posType: '2',
    };
    // TODO What about this event loadInicialState?
    this.props.MessageBus.Subscribe('pos.loadInicialState', (canal, payload) =>
      this.setNumberPos(payload)
    );
    this.props.MessageBus.Subscribe('pos.open.success', this.setOpen);
    this.props.MessageBus.Subscribe('pos.close.success', this.setOpen);
  }

  posCash = this.props.posType === '2';
  setNumberPos(payload) {
    this.setState({
      PosName: payload.numberPos,
    });
  }

  setOpen = (ch, payload) => {
    this.setState({
      ...this.state,
      openingCount: payload && payload.openingCount.toString(),
    });
  };

  render() {
    const { PosName, openingCount } = this.state;
    return (

      <PosInformation
        PosName={this.props.posType === '1'
          ? `Balcão ${Configuration.PosId}`
          : `Caixa ${Configuration.PosId}`}
        openingCount={openingCount}
        posCash={this.posCash}
      />
    );
  }
}

PosInformationConteiner.propTypes = {
  PosName: PropTypes.string,
  openingCount: PropTypes.string,
};

const mapStateToProps = (state) => ({
  posType: state.posType.posType,
});

export default connect(mapStateToProps)(
  withMessageBus(getContext(PosInformationConteiner))
);
