import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as cashDeskServices from '../../services/cashDeskServices';
import {
  closeAllCards,
  CASH_OPEN,
  shouldShow,
  showCard,
} from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { NewCashOpen } from '../../shared/components/new-cash-open/new-cash-open';
import AuthService from '../../services/authService';
import Store from '../../redux/store';
import { HAS_CONFIG_USER_FP } from '../../redux/actions/actionTypes';
import { showToast } from '../../shared/utils/util';

export class CashOpenContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSuccess = this.handleSuccess.bind(this);
    this.eventFailedReturn = this.eventFailedReturn.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);

    this.props.MessageBus.Subscribe('pos.open.success', this.handleSuccess);
    this.props.MessageBus.Subscribe('pos.open.failed', this.eventFailedReturn);
    this.props.MessageBus.Subscribe(
      'pos.open.cashOpen',
      this.props.cashOpenBoxShow
    );
  }

  eventFailedReturn(canal, payload) {
    showToast({
      type: 2,
      title: 'ABERTURA DE CAIXA',
      html: 'Usuário não autorizado!',
      hideAfterMilliseconds: 5000,
    });
  }

  handleSuccess() {
    this.props.closeOpenCash();
  }

  handleClick(openingAmount) {
    cashDeskServices.cashOpen(openingAmount);
  }

  handleClickCancel() {
    this.props.closeOpenCash();

    AuthService.logout().then(() => {
      window.sessionStorage.removeItem('Access_Token');
      Store.dispatch({ type: HAS_CONFIG_USER_FP, payload: false });
    });
  }

  render() {
    return (
      this.props.showCashOpen &&
      !this.props.loginIsOpen && (
        <NewCashOpen
          handleClickConfirm={this.handleClick}
          handleClickCancel={this.handleClickCancel}
        />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  showCashOpen: shouldShow(state, CASH_OPEN),
  loginIsOpen: state.util.loginIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
  closeOpenCash: () => dispatch(closeAllCards()),
  cashOpenBoxShow: () => dispatch(showCard(CASH_OPEN)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(CashOpenContainer)));
