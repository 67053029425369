import React, { useState, useEffect } from 'react';

import { ModalComponent } from '../modal-component/modal-component';
import { ContainerComponent } from '../container/container-component';
import { Field } from '../field/field';
import { ButtonComponent } from '../button-component/button-component';
import { showToast } from '../../utils/util';

import MessageBusService from '../../../services/MessageBusService';
import { verifyErrorInContainerDom } from '../validation-errors/validation-errors';
import AuthService from '../../../services/authService';
import { useSelector } from 'react-redux';

import { OperationEnum } from '../../enums/operation';
import AuthorizationOnline from '../authorization-online/authorization-online';

interface IAuthorization {
  title: string;
  btnAuthorizationTitle: string;
  handleClickConfirm: any;
  handleClickCancel: any;
  labelTextUser: string;
  authorizeOperator?: boolean;
  lastCalledEndpoint?: any;
  page?: 'ABERTURA_DE_CAIXA';
  showAutorizationOnline?: boolean;
  operation?: OperationEnum;
  solicitation?: any;
  closeModal?: any;
}

export function Authorization(props: IAuthorization) {
  const [userName, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [touched, setTouched] = useState(false);

  const userLogado = useSelector((state) => (state as any).order.sellerId);
  const lastSentBody = useSelector((state) => (state as any).util.lastSentBody);
  const showAutorizationOnline = useSelector(
    (state) => (state as any).showAutorizationOnline
  );

  const confirmar = () => {
    if (verifyErrorInContainerDom('container-authorization')) return;

    if (props.authorizeOperator)
      AuthService.authorizationSuperUser({ userName, password });
    else
      AuthService.authorizationSuperUserWithLastRequest(
        { userName, password },
        lastSentBody,
        props.lastCalledEndpoint,
        props.handleClickCancel
      );
  };

  const callHandleClickConfirm = (response, payload) => {
    props.handleClickConfirm(payload);
  };

  const callErrorMessage = (response, payload) => {
    showToast({
      type: 2,
      title: 'Erro ao autenticar',
      html: payload.message,
      hideAfterMilliseconds: 5000,
    });
  };

  useEffect(() => {
    const authorizeSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.authorization.success',
      callHandleClickConfirm
    );
    const authorizeError = MessageBusService.GetInstance().Subscribe(
      'pos.cashdesk.auth.error',
      callErrorMessage
    );

    return () => {
      MessageBusService.GetInstance().Unsubscribe(authorizeSuccess);
      MessageBusService.GetInstance().Unsubscribe(authorizeError);
    };
  }, []);

  useEffect(() => {
    if (
      props.page !== 'ABERTURA_DE_CAIXA' &&
      props.page !== 'LISTAGEM DE PRÉ-VENDAS' &&
      props.authorizeOperator
    )
      setUser(userLogado);
  }, [userLogado]);

  const cancel = () => {
    props.handleClickCancel();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setTouched(true);
      confirmar();
    }
  };

  return (
    <ModalComponent title={props.title} show={true} onClose={() => cancel()}>
      <ContainerComponent
        id='container-authorization'
        onKeyDown={handleKeyPress}
      >
        {props.showAutorizationOnline && showAutorizationOnline?.show && (
          <AuthorizationOnline
            operation={props.operation as OperationEnum}
            solicitation={props.solicitation}
            lastCalledEndpoint={props.lastCalledEndpoint}
            handleClickConfirm={props.handleClickConfirm}
            handleClickCancel={props.handleClickCancel}
            validateFields={() => true}
            closeModal={props.closeModal}
          />
        )}

        <div className='row'>
          <Field
            id='operador-responsavel'
            placeholder='Usuário'
            labelText={props.labelTextUser}
            type='text'
            typeInput='normal'
            showError={true}
            value={userName}
            onChange={(e) => setUser(e.target.value)}
            touched={touched}
            autoFocus={true}
            maxLength={Number.MAX_SAFE_INTEGER}
            onlyNumbers={true}
          />
        </div>
        <div className='row'>
          <Field
            id='senha-solicitante'
            placeholder='Senha'
            labelText='Senha'
            type='password'
            typeInput='normal'
            showError={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            touched={touched}
          />
        </div>
        <div className='row'>
          <div className='btn-group'>
            <ButtonComponent
              className='btn-b-success'
              title={props.btnAuthorizationTitle}
              onClick={confirmar}
              style={{ flex: 1 }}
            />
          </div>
        </div>
      </ContainerComponent>
    </ModalComponent>
  );
}
