import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SaleFinish } from '../../shared/components/modules';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import PaymentService from '../../services/paymentService';
import { showCard, getPayload, closeAllCards, SALE_FINISH, shouldShow } from '../../redux/actions/routerAction';
import * as paymentActions from '../../redux/actions/paymentAction';
import { showToast } from '../../shared/utils/util';
import { clearOrder } from '../../redux/actions/orderAction';

export class SaleFinishContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoToReprint: null
    };

    this.eventPaymentReturnFail = this.eventPaymentReturnFail.bind(this);

    this.props.MessageBus.Subscribe('payment.done', this.eventPaymentReturn.bind(this));
    this.props.MessageBus.Subscribe('payment.not.done', this.eventPaymentReturnFail);
    this.props.MessageBus.Subscribe('payment.money.done', this.props.openSaleFinish);
    this.props.MessageBus.Subscribe('payment.creditcard.done', this.props.openSaleFinish);
    this.props.MessageBus.Subscribe('payment.debit.done', this.props.openSaleFinish);
    this.props.MessageBus.Subscribe('sale.done', this.eventSaleFinishReturn.bind(this));
    this.props.MessageBus.Subscribe('pos.order.concluded', this.donePreOrder.bind(this));
    if (window.desktopApp) {
      window.desktopApp.subscribe('machine.devices.coupon.lastDanfe', this.infoToReprint.bind(this));
      window.desktopApp.subscribe('machine.devices.coupon.reprint.error', this.reprintError.bind(this));
    }
  }

  infoToReprint(ch, payload) {
    console.log(payload);
    this.setState({
      infoToReprint: payload.infoToReprint
    });
  }

  reprintError(ch, payload) {
    showToast({
      type: 2,
      title: 'IMPRIMIR CUPOM FISCAL',
      html: payload,
      hideAfterMilliseconds: 10000,
    });
  }

  eventPaymentReturn() {
    const componentPayload = {
      title: 'VENDA EFETUADA',
      text: 'Venda finalizada com sucesso!',
      textButton: 'NOVA VENDA',
      saleSuccess: true
    };
    this.props.openSaleFinish(componentPayload);
  }

  eventPaymentReturnFail(ch, payload) {
    if (!window.desktopApp) {
      this.eventPaymentReturn();
      return;
    }

    const componentPayload = {
      title: 'VENDA NÃO FINALIZADA',
      text: payload.fiscalDocumentRejectedMessage,
      textButton: 'NOVA VENDA',
      saleSuccess: false
    };
    this.props.openSaleFinish(componentPayload);
  }

  eventSaleFinishReturn(canal, payload) {
    this.props.closeSaleFinish();
    this.setState({
      infoToReprint: null
    });
    this.props.clearOrder();
  }

  handleClickImprimirCupom = () => {
    if (window.desktopApp) {
      window.desktopApp.publish("machine.devices.cupom.printdanfe", this.state.infoToReprint);
    }
  }

  donePreOrder(ch, payload) {
    const componentPayload = {
      title: 'PRÉ-VENDA EFETUADA',
      text: payload.messageDisplay,
      textButton: 'NOVA PRÉ-VENDA',
      paymentLinkInfo: payload.paymentLinkInfo,
      saleSuccess: true
    };
    this.props.openSaleFinish(componentPayload);
  };

  onHandleClick = () => {
    const paymentService = new PaymentService();
    paymentService.donePayment()
      .catch(err => this.context.showError(err));;
  }

  render() {
    return (
      this.props.showSaleFinish && (
        <SaleFinish {...this.props.payload}
          showPrinterButton={this.props.printerOption !== 1 && this.props.posType === '2' && this.state.infoToReprint}
          handleClickImprimirCupom={this.handleClickImprimirCupom}
          handleClickDone={this.onHandleClick} />
      )
    );
  }
}

const mapStateToProps = state => ({
  showSaleFinish: shouldShow(state, SALE_FINISH),
  payload: getPayload(state, SALE_FINISH),
  posType: state.posType.posType,
  printerOption: state.config && state.config.danfe && state.config.danfe.printerOption
});

const mapDispatchToProps = dispatch => ({
  openSaleFinish: payload => dispatch(showCard(SALE_FINISH, payload)),
  closeSaleFinish: () => dispatch(closeAllCards()),
  clearOrder: () => dispatch(clearOrder()),
  paymentAmountRemaing: () => dispatch(paymentActions.paymentAmountRemaing(0))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(SaleFinishContainer)));
