import styled from 'styled-components';

export const CardPaymentWrapper = styled.div`
  width: 75%;
  margin-left: 15%;
`;

export const TefInteractionWrapper = styled.div`
  margin: 30px 20px 15px 20px;
  height: 200px;
  position:relative;

  .btn-finish{
    position:absolute;
    bottom:0;
    width: 150px;
    height: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;



export const TefInteractionInput = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;

  input {
    width: calc(100% - 40px);
    height: 50px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    border: 2px solid #eee;
  }
`;
