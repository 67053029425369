import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { LazyTableService } from './lazyTableService';
import { TableWrapper } from './style';
import { Body } from './body';
import Order from '../../../assets/images/ordenar.svg';
import OrderDown from '../../../assets/images/ordenar-down.svg';

export const CELL_ALIGN_RIGTH = 'flex-start';
export const CELL_ALIGN_CENTER = 'center';
export const CELL_ALIGN_LEFT = 'flex-end';

export const STYLE_SPACED_ROW_CARD = 'spaced-rows-in-card';

const Header = ({ columns, haveMoreOptionsToShow, handleSort }) => (
  <thead className="table-header">
    <tr className="table-row">
      {columns.map((column, index) => <th className={column.titleAlign || ''} key={column.label || index}>{column.title} {column.enableSort && <img onClick={() => handleSort(column)} className='sort' src={column.sortDirection === "asc" ? OrderDown : Order} alt="Ordernar"></img>}</th>)}
      {haveMoreOptionsToShow && <th></th>}
    </tr>
  </thead>
);

export class Table extends Component {
  constructor(props) {
    super(props);

    this.listenScroll = this.listenScroll.bind(this);
    this.focusFirst = this.focusFirst.bind(this);

    if (this.props.config && this.props.config.lazy) {
      this.initLazyService.bind(this)();
    }

    this.focusRowIdx = this.focusRowIdx.bind(this);
  }

  initLazyService() {
    this.lazyService = new LazyTableService({
      queryFn: this.props.config.lazy,
      reloadInPercent: this.props.config.reloadInPercent,
      limitPerPage: this.props.config.limitPerPage,
    });
  }

  listenScroll(event) {
    if (!this.lazyService) return;

    this.lazyService.listenScroll(event);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    if (this.lazyService) {
      this.lazyService.setHasNextPage(!this.props.disableLazyLoading);
    }
  }
  focusRowIdx(idx) {
    this.tableRef.querySelectorAll('.table-body').children[idx].focus();
  }

  focusFirst() {
    const firstElem = this.tableRef.querySelector('.table-body').firstChild;
    if (firstElem) {
      this.tableRef.querySelector('.table-body').firstChild.focus();
    }
  }

  render() {
    const {
      config, data, id, onRowSelect, messageWhenEmptyTable, selected, handleSort
    } = this.props;

    return (
      <Fragment>
        <TableWrapper
          id={id}
          style={config.style}
          className={`table ${config.customClass} ${config.selectable ? ' selectable' : ''}`}
          onScroll={this.listenScroll}
          innerRef={ref => this.tableRef = ref}
        >
          {(data && (data.length > 0 || (config.showHeaderEmptyData))) && <Header columns={config.columns} haveMoreOptionsToShow={!!config.moreOptionsToShow} handleSort={handleSort} />}
          {!data && config.emptyMessage && <span className="empty-message">{config.emptyMessage}</span>}
          {data && (
            <Body
              customClass={config.customClass}
              focusFilter={this.props.focusFilter}
              columns={config.columns}
              data={data}
              selectable={config.selectable}
              onRowSelect={onRowSelect}
              messageWhenEmptyTable={messageWhenEmptyTable}
              selected={selected}
              moreOptionsToShow={config.moreOptionsToShow}
            />
          )}
        </TableWrapper>
        {(data && data.length === 0) && messageWhenEmptyTable}
      </Fragment>
    );
  }
}

Header.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string,
  onRowSelect: PropTypes.func,
};

Table.defaultProps = {
  data: null,
  id: null,
  onRowSelect: null,
};
