import styled from 'styled-components';

const QuantityItemButtomWrapper = styled.div`
    .container-teste {
      width: 100%;
      height: 50px;
      display: grid;
      &:focus {
        outline-width: 1px;
        outline-color: #5B2E90;
        outline-style: solid;
      }

      > div:nth-child(4) {
        grid-column: 4;
      }

      .warning-message {
        color: red;
        font-size: 12px;
        position: absolute;
        top: 80px;
      }

      .input__container {
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        margin-top: 0;
        &:focus {
          outline-width: 1px;
          outline-color: #5B2E90;
          outline-style: solid;
        }
        .without-image {
          margin: 2px 0 0 0;
        }
        .input {
          height: 45px;
          width: 70px;
          padding: 5px;
          box-sizing: border-box;
          font-size: 16px;
          text-align: center;
          border-radius: 5px;
        }
      }
    }
`;

export default QuantityItemButtomWrapper;
