/* eslint-disable eqeqeq */

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

// import logger from 'redux-logger';
import Configuration from '../../configuration';
import sagas from '../modules/rootSaga';
import Reducers from '../reducers/index';

const middlewares = [thunk];

const addLoggingToDispatch = (store) => {
  const rawDispatch = store.dispatch;
  if (Configuration.enableStateLog == 1) {
    return (action) => {
      const prevState = store.getState();
      var prevStateToLog = {
        order: prevState.order,
        quantity: prevState.quantity,
        sat: prevState.sat,
        payment: prevState.payment,
        config: prevState.config,
        card: prevState.card,
      };
      const returnValue = rawDispatch(action);
      const nextState = store.getState();
      var nextStateToLog = {
        order: nextState.order,
        quantity: nextState.quantity,
        sat: nextState.sat,
        payment: nextState.payment,
        config: nextState.config,
        card: prevState.card,
      };
      if (window.desktopApp && action.type != null) {
        window.desktopApp.log(`State changed. Action: ${action.type}`);
        window.desktopApp.log('State :');
        window.desktopApp.log(prevStateToLog);
      } else if (action.type != null) {

        prevStateToLog = prevState;
        nextStateToLog = nextState;

        console.group(action.type);
        console.log('%c prev state', 'color: gray', prevStateToLog);
        console.log('%c action', 'color: blue', action);
        console.log('%c next state', 'color: green', nextStateToLog);
        console.groupEnd(action.type);
      }
      return returnValue;
    };
  }

  return rawDispatch;
};

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);

const composer =
  process.env.NODE_ENV === 'development'
    ? compose(
        applyMiddleware(...middlewares),
        console.tron
          ? console.tron.createEnhancer()
          : window &&
              window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
              window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      )
    : compose(applyMiddleware(...middlewares));

const Store = createStore(Reducers, composer);

sagaMiddleware.run(sagas);

Store.dispatch = addLoggingToDispatch(Store);

export default Store;
