import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  visible: true,
  text: '',
};

const loadingScreenReducer = createReducer(initialState, {
  [types.LOADING_SCREEN_SHOW]: (state, action) => (
    {
      visible: true,
      text: action.text,
    }),
  [types.LOADING_SCREEN_HIDE]: (state, action) => (
    {
      ...initialState,
      visible: false
    }),
});

export default loadingScreenReducer;
