import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .row {
    display: grid;
    flex-direction: row;
    margin: 0px 0px 0.5rem 0px;

    .input-group {
      display: flex;
      flex-wrap: nowrap;
      flex: 1;
      flex-direction: column;

      label {
        font-family: 'Dosis';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
      }

      input {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;

        width: 100%;
        height: 40px;
      }

      textarea {
        background: #ffffff;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;

        width: 100%;
        min-height: 40px;
        resize: none;
        overflow: hidden;
      }
    }

    .btn-group {
      margin: 0px;
      margin-top: 1rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
