import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Thead from './thead';
import Tbody from './tbody';
import Tfoot from './tfoot';
import { TableWrapper } from './style';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order === 'desc' ? props.order : 'asc',
    };
    this.tableRef = React.createRef();
  }

  focusFirstRow = () => {
    const elem = this.tableRef.current && this.tableRef.current.querySelector('tbody tr');
    elem && elem.focus();
  }

  render() {
    const {
      items, columns, lines, footer, lineClick, headerClick, className, typeList, scrollable, handleHeader, hover
    } = this.props;

    if (columns.length === 0) {
      return false;
    }

    return (
      <TableWrapper className={scrollable ? 'scrollable' : ''} >
        <table ref={this.tableRef} className={`table ${className} ${hover ? 'hover' : ''}`} >
          <Thead
            columns={columns}
            items={items}
            order={this.state.order}
            handleHeader={handleHeader}
            handleClick={(colRef, orderBy) => {
              const order = orderBy === 'asc' ? 'desc' : 'asc';
              this.setState({ order });

              if (headerClick) {
                headerClick({ colRef, order });
              }
            }}
          />

          {items
            && (
              <Tbody
                items={items}
                columns={columns}
                lines={lines}
                styleLink={!!lineClick}
                handleClick={(item, colRef, index) => (lineClick ? lineClick({ item, colRef, index }) : null)}
                typeList={typeList}
                {...this.props}
              />
            )
          }

          {footer
            && <Tfoot items={items} {...footer} />
          }
        </table>
      </TableWrapper>
    );
  }
}

Table.propTypes = {
  items: PropTypes.array,
  lines: PropTypes.object,
  columns: PropTypes.array.isRequired,
  footer: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    component: PropTypes.any,
  }),
  order: PropTypes.string,
  lineClick: PropTypes.func,
  headerClick: PropTypes.func,
  children: PropTypes.any,
};

export default Table;
