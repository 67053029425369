import React from 'react';

import LoadingScreen from '../../../container/loading-screen';
import MessageBoxContainer from '../../../container/message-box';
import MessagesContainer from '../../../container/messages';
import {
  PaymentButtonListContainer,
  KeyBoardQuantityContainer,
  SearchContainer,
  CashOpenContainer,
  CashCloseContainer,
  CashWithdrawContainer,
  CashSupplyContainer,
  CpfCnpjContainer,
  LotContainer,
  CardPaymentAuthorizationContainer,
  DetailItemContainer,
  PbmContainer,
  ListOrderContainer,
  IdentifySeller,
  PreOrderContainer,
  PaymentCrediCardParcelContainer,
  PaymentCreditSaleParcelContainer,
  PaymentBusinessAgreementParcelContainer,
  ListBrandCardContainer,
  SaleFinishContainer,
  PaymentMoneyContainer,
  PaymentPixContainer,
  CardCustomerConteiner,
  TefInteractionContainer,
  SngpcContainer,
  ProductSearchContainer,
  FarmaciaPopularContainer,
  FarmaciaPopularConfirmContainer,
  FarmaciaPopularErrorContainer,
  PbmConfirmContainer,
  InvoiceMonitorContainer,
  FinancialDocumentReceiptmentContainer,
  OrderDetailContainer,
  PbmInitializationContainer,
  ApplyPbmProductsContainer,
  PbmAuthorizationListContainer,
  TrnCentreResumeContainer,
  RequireAuthContainer,
  LoginContainer,
  LinxConectaInitContainer,
  LinxConectaProductsContainer,
  OrderValidationContainer,
  ToastContainer,
  LinxConectaPaymentContainer,
  LinxConectaPasswordContainer,
  InfoModalContainer,
  DeliveryInfoContainer,
  DiscountQuantityContainer,
  CustomerRegistrationContainer,
  CardDeliveryConteiner,
  CardSevenConteiner,
  CardTrnCentreContainer,
  CardCheckPreOrderContainer,
  CardContinuousUseContainer,
  PurchaseHistoryContainer,
  ObjectProContainer,
  StockListContainer,
  PbmSelectPatientContainer,
  CardDocumentPrintingConteiner,
  LicenseModalContainer
} from '../../../container/modules';
import ConfigTerminalContainer from '../../../container/config-terminal/config-terminal-container';
import ConfigPBMContainer from '../../../container/config-pbm/config-pbm-container';
import ConfigTefContainer from '../../../container/config-tef/config-tef-container';
import ConfigPosConnectContainer from '../../../container/config-posconnect/config-posconnect-container';
import PbmInitializationOrizonContainer from '../../../container/pbm-initialization-orizon/pbm-initialization-orizon-container';
import PbmExternalAuthorizationContainer from '../../../container/pbm-external-authorization/pbm-external-authorization-container';
import TefMenuOptionsContainer from '../../../container/tef-menu-options';
import Page from '../../@template';
import { PlatformContext, envs } from '../../context';
import { ViewsWrapper } from './style';

const SalePage = () => (
  <PlatformContext.Provider value={envs.farma}>
    <ToastContainer />
    <LoginContainer />
    <MessageBoxContainer />
    <Page>
      <SearchContainer />
      <ViewsWrapper id='wrapper-views'>
        <StockListContainer />
        <LinxConectaProductsContainer />
        <OrderValidationContainer />
        <CashOpenContainer />
        <CashCloseContainer />
        <CashWithdrawContainer />
        <CashSupplyContainer />
        <PreOrderContainer />
        <PaymentMoneyContainer />
        <PaymentPixContainer />
        <PaymentButtonListContainer />
        <CpfCnpjContainer />
        <LotContainer />
        <KeyBoardQuantityContainer />
        <PaymentCrediCardParcelContainer />
        <PaymentBusinessAgreementParcelContainer />
        <PaymentCreditSaleParcelContainer />
        <ListBrandCardContainer />
        <CardPaymentAuthorizationContainer />
        <SaleFinishContainer />
        <DetailItemContainer />
        <MessagesContainer />
        <PbmContainer />
        <ListOrderContainer />
        <IdentifySeller />
        <TefMenuOptionsContainer />
        <LoadingScreen />
        <CardCustomerConteiner />
        <TefInteractionContainer />
        <SngpcContainer />
        <ProductSearchContainer />
        <FarmaciaPopularContainer />
        <FarmaciaPopularConfirmContainer />
        <FarmaciaPopularErrorContainer />
        <PbmConfirmContainer />
        <InvoiceMonitorContainer />
        <FinancialDocumentReceiptmentContainer />
        <OrderDetailContainer />
        <PbmInitializationContainer />
        <ApplyPbmProductsContainer />
        <PbmSelectPatientContainer />
        <PbmAuthorizationListContainer />
        <TrnCentreResumeContainer />
        <RequireAuthContainer />
        <LinxConectaInitContainer />
        <LinxConectaPaymentContainer />
        <LinxConectaPasswordContainer />
        <InfoModalContainer />
        <DeliveryInfoContainer />
        <DiscountQuantityContainer />
        <CustomerRegistrationContainer />
        <CardDeliveryConteiner />
        <CardSevenConteiner />
        <CardTrnCentreContainer />
        <CardCheckPreOrderContainer />
        <CardContinuousUseContainer />
        <ConfigTerminalContainer />
        <ConfigPBMContainer />
        <ConfigTefContainer />
        <ConfigPosConnectContainer />
        <PbmExternalAuthorizationContainer />
        <PbmInitializationOrizonContainer />
        <PurchaseHistoryContainer />
        <ObjectProContainer />
        <CardDocumentPrintingConteiner />
        <LicenseModalContainer />
      </ViewsWrapper>
    </Page>
  </PlatformContext.Provider>
);

export default SalePage;
