import { AbstractService } from './abstractService';

export class OrderService extends AbstractService {
  constructor() {
    super('/Order');
  }

  donePreOrder = (recoveryDesc) => this.invokeAction('/done', { recoveryDesc });

  addPreOrderId = (recoveryId) =>
    this.invokeAction('/setPreOrderId', { recoveryId });

  getPreOrderList = (recoveryDesc) =>
    this.invokeAction('/listOpenPreOrders', { recoveryDesc });

  concludeOrderCancellation = (data) =>
    this.invokeAction('/concludeOrderCancellation', data);

  getListLastOrder = (startDate, endDate) =>
    this.invokeAction('/finished', { startDate, endDate });

  cancelOrderId = (orderId, reason, tpAmbMide) =>
    this.invokeAction('/startOrderCancellation', { orderId, reason, tpAmbMide });

  cancelIdentifyUser = (referenceId, password, reason) =>
    this.invokeAction('/identifyCancellationUser', {
      referenceId,
      password,
      reason,
    });

  businessAgreementSelected = (businessAgreementReferenceId) =>
    this.invokeAction('/businessAgreementSelected', {
      businessAgreementReferenceId,
    });

  updateDiscount = (discountValue) =>
    this.invokeAction('/updateDiscount', { discountValue });

  noteMonitorFilter = (data) => this.invokeAction('/noteMonitorFilter', data);

  authorizeFiscalDocument = (orderId) =>
    this.invokeAction('/authorizeFiscalDocument', { orderId });

  setRecoveryDesc = (recoveryDesc) =>
    this.invokeAction('/setRecoveryDesc', { recoveryDesc });

  getDocumentNumberInfo = () => this.invokeAction('/documentNumberInfo');

  updateDeliveryInfo = (data) => this.invokeAction('/updateDelivery', data);

  // invoice-monitor
  reauthorizeCoupon = (orderId, tpAmbMide) =>
    this.invokeAction('/reauthorizeCoupon', { orderId, tpAmbMide }, false);

  queryCoupon = (form) => this.invokeAction('/queryCoupon', form);

  setFiscalDocument = (payload) =>
    this.invokeAction('/setFiscalDocument', payload, false);

  queryFiscalDocumentStatusOnMidE = (orderId, tpAmbMide) =>
    this.invokeAction(
      '/queryFiscalDocumentStatusOnMidE',
      { orderId, tpAmbMide },
      false
    );

  queryStatusOnMidE = (orderId, tpAmbMide) =>
    this.invokeAction('/querySatStatusOnMidE', { orderId, tpAmbMide });

  reprintCouponTef = () => {
    this.invokeAction('/reprintCouponTef');
  };

  startOrderCancellation = (
    orderId,
    reason,
    tpAmbMide,
    showLoading = false
  ) => {
    return this.invokeAction(
      '/startOrderCancellation',
      { orderId, reason, tpAmbMide },
      showLoading
    );
  };

  setCouponUnusableOnMide = (orderId) => {
    this.invokeAction('/setCouponUnusableOnMide', { orderId }, false);
  };
  
  decodeDanfe = (encodedDanfe) => {
    return this.invokeAction('/decodeDanfe', { encodedDanfe });
  };
  
  getPurchaseHistory = (payload, showLoader = true) => {
    this.invokeAction('/getHistory', payload, showLoader);
  }

  setTefPaymentToCancelled = (payload) => {
    this.invokeAction('/setTefPaymentToCancelled', payload, false);
  }

}

export default OrderService;
