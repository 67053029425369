export const CANCEL_SALE = 'CANCEL_SALE';
export const FIRST_OPTION = 'FIRST_OPTION';
export const SECOND_OPTION = 'SECOND_OPTION';
export const THIRD_OPTION = 'THIRD_OPTION';
export const FOURTH_OPTION = 'FOURTH_OPTION';
export const FIFTH_OPTION = 'FIFTH_OPTION';
export const SIXTH_OPTION = 'SIXTH_OPTION';
export const SEVENTH_OPTION = 'SEVENTH_OPTION';
export const CLOSE_REGISTER = 'CLOSE_REGISTER';
export const OPEN_REGISTER = 'OPEN_REGISTER';
export const NEW_SALE = 'NEW_SALE';
export const PRE_SALE_LIST = 'PRE_SALE_LIST';
export const REGISTER_PICK_UP = 'REGISTER_PICK_UP';
export const REPRINT_LAST_VOUCHER = 'REPRINT_LAST_VOUCHER';
export const SUPPLY = 'SUPPLY';
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const CLIENT_SEARCH = 'CLIENT_SEARCH';
export const OTHER_OPTIONS = 'OTHER_OPTIONS';
export const SET_USER = 'SET_USER';
export const DELETE = 'DELETE';
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT';
export const PBM_LIST = 'PBM_LIST';
export const QUANTITY_INPUT_FOCUS = 'QUANTITY_INPUT_FOCUS';
export const PAYMENT_FORMS = 'PAYMENT_FORMS';
export const COUPON_ITEMS = 'COUPON_ITEMS';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const MONEY = 'MONEY';
export const PIX = 'PIX';
export const DEBIT = 'DEBIT';
export const TEF_DEBIT = 'TEF_DEBIT';
export const CREDIT = 'CREDIT';
export const TEF_CREDIT = 'TEF_CREDIT';
export const CREDIT_SALE = 'CREDIT_SALE';
export const BUSINESS_AGREEMENT = 'BUSINESS_AGREEMENT';
export const FINISH_PRE_ORDER = 'FINISH_PRE_ORDER';
export const PAY_OFF = 'PAY_OFF';
export const LIST_AUTHORIZATIONS = 'LIST_AUTHORIZATIONS';
export const LINX_CONECTA = 'LINX_CONECTA';
export const DELIVERY_CONTROL = 'DELIVERY_CONTROL';
export const NOTES_MONITOR = 'NOTES_MONITOR';
export const MENU_QUICK_OPTIONS = 'MENU_QUICK_OPTIONS';
export const MENU_HELP = 'MENU_HELP';
export const CONFIGURATIONS = 'CONFIGURATIONS';
export const OBJECTPRO = 'SHOWOBJECTPRO';

export const shortcutList = [
  { name: CANCEL_SALE, keys: 'alt+c', keyCode: 67 },
  { name: CLOSE_REGISTER, keys: 'alt+f', keyCode: 70 },
  { name: NEW_SALE, keys: 'alt+n', keyCode: 78 },
  { name: PRE_SALE_LIST, keys: 'alt+l', keyCode: 76 },
  { name: REGISTER_PICK_UP, keys: 'alt+a', keyCode: 65 },
  { name: REPRINT_LAST_VOUCHER, keys: 'alt+t', keyCode: 84 },
  { name: OPEN_REGISTER, keys: 'alt+x', keyCode: 88 },
  { name: SUPPLY, keys: 'alt+s', keyCode: 83 },
  { name: FIRST_OPTION, keys: 'alt+1', keyCode: 49 },
  { name: SECOND_OPTION, keys: 'alt+2', keyCode: 50 },
  { name: THIRD_OPTION, keys: 'alt+3', keyCode: 51 },
  { name: FOURTH_OPTION, keys: 'alt+4', keyCode: 52 },
  { name: FIFTH_OPTION, keys: 'alt+5', keyCode: 53 },
  { name: SIXTH_OPTION, keys: 'alt+6', keyCode: 54 },
  { name: SEVENTH_OPTION, keys: 'alt+7', keyCode: 55 },
  { name: PRODUCT_SEARCH, keys: 'alt+b', keyCode: 66 },
  { name: CLIENT_SEARCH, keys: 'alt+c', keyCode: 67 },
  { name: OTHER_OPTIONS, keys: 'alt+o', keyCode: 79 },
  { name: LINX_CONECTA, keys: 'alt+o', keyCode: 79 },
  { name: SET_USER, keys: 'alt+u' },
  { name: DELETE, keys: 'alt+delete' },
  { name: DELIVERY_CONTROL, keys: 'alt+d' },
  { name: RECEIVE_PAYMENT, keys: 'alt+r', keyCode: 82 },
  { name: PBM_LIST, keys: 'alt+p', keyCode: 80 },
  { name: QUANTITY_INPUT_FOCUS, keys: 'alt+q' },
  { name: PAYMENT_FORMS, keys: 'alt+g' },
  { name: COUPON_ITEMS, keys: 'alt+i' },
  { name: SUBMIT_FORM, keys: 'enter' },
  { name: MONEY, keys: 'f2' },
  { name: TEF_DEBIT, keys: 'f3' },
  { name: DEBIT, keys: 'f4' },
  { name: CREDIT, keys: 'f5' },
  { name: TEF_CREDIT, keys: 'f6' },
  { name: BUSINESS_AGREEMENT, keys: 'f7' },
  { name: CREDIT_SALE, keys: 'f8' },
  { name: PIX, keys: 'f9' },
  { name: FINISH_PRE_ORDER, keys: 'alt+f', keyCode: 70 },
  { name: PAY_OFF, keys: 'alt+b', keyCode: 66 },
  { name: LIST_AUTHORIZATIONS, keys: 'alt+l', keyCode: 76 },
  { name: NOTES_MONITOR, keys: 'alt+m', keyCode: 77 },
  { name: MENU_QUICK_OPTIONS, keys: 'alt+a', keyCode: 65 },
  { name: MENU_HELP, keys: 'alt+h', keyCode: 72 },
  { name: CONFIGURATIONS, keys: 'alt+z', keyCode: 122 },
];

export const getShortcut = (name) => {
  return shortcutList.find((s) => s.name === name);
};

export default getShortcut;
