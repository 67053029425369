import React, { Component } from 'react';

import PropTypes from 'prop-types';

import MessageBusService from '../../../services/MessageBusService';
import Button from '../button';
import { Card } from '../modules';
import { Table } from '../table2';
import { Form, Input, EmptyData, Container, Select, OptionsRender } from './styles';

class CardQueryComponent extends Component {
  tableConfig = {};

  clearOldDataList = false;

  cachePage = 1;

  cacheFilter = '';

  queryFn;

  constructor(props) {
    super(props);

    this.state = {
      resultList: [],
      disableLazyLoading: true,
      searchValue: props.initialQuery.query,
    };

    const { config } = this.props;

    this.listenResponsePagination(config);

    this.createTableConfig(config);
  }

  componentDidMount() {
    if (this.props.initialQuery.source === "financial-document-receiptment") {
      this.executeQuery(1, this.props.config.limitPerPage, this.state.searchValue)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.resultList !== prevState.resultList) {
      if (prevProps.initialQuery.source === "financial-document-receiptment" && this.state.resultList.length === 1) {
        this.props.initialQuery.onSelect(this.state.resultList[0]);
      }
    }
  }

  componentWillUnmount() {
    MessageBusService.GetInstance().Unsubscribe(this.listeningTo);
  }

  focusRowIdx = (idx) => {
    this.tableRef.focusRowIdx(idx);
  };

  createTableConfig(config) {
    Object.keys(config).forEach((key) => {
      if (['queryFn', 'eventToListenResponse'].indexOf(key) > -1) return;

      this.tableConfig[key] = config[key];
    });

    this.tableConfig.lazy = (page, limit) => this.executeQuery(page, limit);
  }

  listenResponsePagination(config) {
    if (!config.channelToListenResponse) return;

    this.listeningTo = MessageBusService.GetInstance().Subscribe(
      config.channelToListenResponse,
      (ch, payload) => {
        const list = config.manipuleResult
          ? config.manipuleResult(payload)
          : this.processResponse(config, payload);
        this.setState({
          resultList: list,
          disableLazyLoading:
            payload[config.fieldPaginated || 'pagedResult'].resultList.length <
            this.props.config.limitPerPage,
        });

        if (this.resolveQuery) {
          this.resolveQuery(
            payload[config.fieldPaginated || 'pagedResult'].metaData.hasNextPage
          );
        }
      }
    );
  }

  processResponse(config, payload) {
    const oldList = this.clearOldDataList ? [] : this.state.resultList || [];

    this.clearOldDataList = false;

    let newList =
      payload[config.fieldPaginated || 'pagedResult'].resultList || [];

    if (this.props.toggleOnOptionStockSelected === 1 && !this.props.consumer)
      newList = newList.filter(f => f.stock > 0);
    if (this.props.toggleOnOptionStockSelected === 2 && !this.props.consumer)
      newList = newList.filter(f => f.stock <= 0);

    return oldList.concat(newList);
  }

  handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      this.tableRef.focusFirst();
    }
  };

  handleChange = (event) => this.setState({ searchValue: event.target.value });

  ClickSelectToggleOnOptionSearchRule = (option) => {
    this.clearOldDataList = true;
    this.props.clickSelectToggleOnOptionSearchRule(option)
    let query = option.value === 1 ? "&%" + this.state.searchValue : "&" + this.state.searchValue;
    this.executeQuery(1, this.props.config.limitPerPage, query);
  }

  ClickSelectToggleOnOptionStock = (option) => {
    this.clearOldDataList = true;
    this.props.clickSelectToggleOnOptionStock(option)
    this.executeQuery(1, this.props.config.limitPerPage, this.state.searchValue);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { searchValue } = this.state;
    this.clearOldDataList = true;

    this.executeQuery(1, this.props.config.limitPerPage, searchValue);
  };

  executeQuery(page = this.cachePage, limit = 200, filter = this.cacheFilter) {
    this.cacheFilter = filter;
    this.cachePage = page;
    return new Promise((resolve) => {
      this.resolveQuery = resolve;

      this.props.config.queryFn(filter, page, limit);
    });
  }

  focusInput() {
    const input = document.getElementById('input-product-search');
    input.value = '';
    input.focus();
  }

  render() {
    const {
      placeholder,
      idInput,
      title,
      onRowSelect,
      handleClose,
      initialQuery,
      messageWhenEmptyTable,
      consumer,
      clickToggleOnOption,
      options,
      toggleOnOptionStockSelected,
      isToggleOnOptionStock,
      toggleOnOptionSearchRuleSelected,
      isToggleOnOptionSearchRule,
    } = this.props;

    const items = this.state.resultList;

    return (
      <Card
        id="card-query-component"
        className="card-query-component"
        title={title}
        closeEnable
        handleClose={handleClose}
      >
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Input
              id={idInput || ''}
              placeholder={placeholder}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              defaultValue={initialQuery.query}
              autoFocus
            />
            <Button type="submit">
              <i className="material-icons">search</i>
            </Button>
            {this.props.config.showAddCustomerBtn ? (
              <Button
                className="btn-outline"
                onClick={() => this.props.config.handleClick()}
              >
                <i className="material-icons">person_add</i>
              </Button>
            ) : (
              ''
            )}
          </Form>

          {!consumer ?
            <OptionsRender>
              <div className="options">
                <div className="stockOptions">
                  <div>
                    Produto:
                  </div>
                  <div>
                    <Select className={`title-select`} onClick={() => clickToggleOnOption('isToggleOnOptionStock')} >
                      {options?.toggleOnOptionStock[toggleOnOptionStockSelected]?.name}
                      <div className="icon-align">
                        <i className="material-icons">{isToggleOnOptionStock ? 'expand_less' : 'expand_more'}</i>
                      </div>
                      {isToggleOnOptionStock && (
                        <div className="menu-area">
                          {options?.toggleOnOptionStock.map(option => (
                            <span
                              key={option.value}
                              id='toggleOnOptionStockSelected'
                              tabIndex="0"
                              onClick={e => { this.ClickSelectToggleOnOptionStock(option) }}
                            >
                              {option.name}
                            </span>
                          ))}
                        </div>
                      )}
                    </Select>
                  </div>
                </div>
                <div className="ruleOptions">
                  <div>
                    Regra de pesquisa:
                  </div>
                  <div>
                    <Select className={`title-select`} onClick={() => clickToggleOnOption('isToggleOnOptionSearchRule')} >
                      {options?.toggleOnOptionSearchRule[toggleOnOptionSearchRuleSelected]?.name + (this.state.searchValue != null ? this.state.searchValue : '')}
                      < div className="icon-align">
                        <i className="material-icons">{isToggleOnOptionSearchRule ? 'expand_less' : 'expand_more'}</i>
                      </div>
                      {isToggleOnOptionSearchRule && (
                        <div className="menu-area">
                          {options?.toggleOnOptionSearchRule.map(option => (
                            <span
                              key={option.value}
                              id='toggleOnOptionSearchRuleSelected'
                              tabIndex="0"
                              onClick={e => { this.ClickSelectToggleOnOptionSearchRule(option) }}
                            >
                              {option.name + (this.state.searchValue != null ? this.state.searchValue : '')}
                            </span>
                          ))}
                        </div>
                      )}
                    </Select>
                  </div>
                </div>
              </div>
            </OptionsRender>
            : ''}

          <Table
            focusFilter={this.focusFilter}
            ref={(ref) => (this.tableRef = ref)}
            config={this.tableConfig}
            data={items}
            onRowSelect={onRowSelect}
            messageWhenEmptyTable={messageWhenEmptyTable}
            disableLazyLoading={this.state.disableLazyLoading}
          />

          {items.length === 0 && consumer && (
            <EmptyData>
              <img
                src="/assets/images/adicionar-consumidor.svg"
                alt="Pesquisar consumidor"
              />
              <p>Comece pesquisando um cliente</p>
            </EmptyData>
          )}
        </Container>
      </Card>
    );
  }
}

CardQueryComponent.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  messageWhenEmptyTable: PropTypes.string.isRequired,
  initialQuery: PropTypes.string,
  consumer: PropTypes.bool,
};

CardQueryComponent.defaultProps = {
  initialQuery: '',
  consumer: false,
};

export default CardQueryComponent;
