import React, { Component } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { KeyboardQuantityWrapper } from './style';
import { Input } from '../modules';
import ButtonItem from '../button-item';
import getShortcut, { SUBMIT_FORM } from '../../../keyboardShortcuts.js';

class KeyboardQuantity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: '',
    };
  }

  changeValue(e) {
    this.setState({ quantity: e.target.value });
  }

  setQuantity(qtd) {
    const newValue = qtd === '<-' ? this.state.quantity.slice(0, -1) : this.state.quantity + qtd;
    this.setState({ quantity: newValue });
  }

  handleConfirm() {
    this.props.handleClick(parseInt(this.state.quantity ? this.state.quantity : '1', 10));
    this.setState({ quantity: '' });
  }

  render() {
    const { isVisible } = this.props;

    return (
      <KeyboardQuantityWrapper id="keybord-quantity" className={isVisible ? '' : 'keyboard--hidden'} >
        <KeyboardEventHandler
          handleKeys={['esc']}
          handleFocusableElements
          onKeyEvent={() => this.handleConfirm()}
        />
        <div className="key-header">
          <span className="key-title">Quantidade</span>
          <ButtonItem className="key-close" label="x" handleClick={() => this.handleConfirm()} />
        </div>
        <Input maxLength="50" onChange={e => this.changeValue(e)} value={this.state.quantity} autoFocus />
        <div className="button-wrapper">
          <div className="content">
            <ButtonItem className="button" handleClick={() => this.setQuantity('1')} label="1" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('2')} label="2" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('3')} label="3" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('4')} label="4" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('5')} label="5" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('6')} label="6" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('7')} label="7" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('8')} label="8" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('9')} label="9" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('00')} label="00" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('0')} label="0" />
            <ButtonItem className="button" handleClick={() => this.setQuantity('<-')}><i className="material-icons">backspace</i></ButtonItem>
          </div>
          <KeyboardEventHandler
            handleKeys={[getShortcut(SUBMIT_FORM).keys]}
            handleFocusableElements
            onKeyEvent={() => this.handleConfirm()}
          />
          <ButtonItem id="btn-pronto" label="PRONTO" handleClick={() => this.handleConfirm()} />
        </div>

      </KeyboardQuantityWrapper>
    );
  }
}

export default KeyboardQuantity;
