import React from 'react';
import { Modal } from '../modal2';
import { Button } from '../modules'
import './authorization-validation-component.style.scss';
import AuthorizationOnline from '../authorization-online/authorization-online';
import PasswordInput from '../password/password-input-component';
import AuthorizationService from '../../../services/authorizationService';
import { payloadToBase64Zip } from '../../utils/util';
import MessageBusService from '../../../services/MessageBusService';

export class AuthorizationValidation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      authenticationMessage: ""
    };

    this.modalConfirm = React.createRef();
    this.userPassword = React.createRef();
    this.userReferenceId = React.createRef();
    this.authorizationService = new AuthorizationService();

    MessageBusService.GetInstance().Subscribe('pos.authorizationValidation.error', this.handleValidationError);
    MessageBusService.GetInstance().Subscribe('pos.authorizationValidation.success', this.handleValidationSuccess);
  }

  handleValidationError = (ch, payload) => {
    this.setState({
      authenticationMessage: payload.message
    });
  }

  handleValidationSuccess = (ch, payload) => {
    this.props.handleAuthorizeSucces();
    this.props.handleClose();
  }

  componentDidMount() {
    this.modalConfirm.current.open();
  }

  componentWillUnmount() {
    if (this.modalConfirm.current !== null) this.modalConfirm.current.close();
  }

  handleModalClose = () => {
    this.props.handleClose();
    if (this.modalConfirm.current !== null)
      this.modalConfirm.current.close();
  }

  confirmClick = () => {
    const credentials = {
      username: this.userReferenceId.current.value,
      password: this.userPassword.current.value,
    };

    payloadToBase64Zip(JSON.stringify(credentials)).then((token) => {
      this.authorizationService.AuthorizeValidation({ credentials: token, operation: this.props.operationType });
    });
  }

  render() {
    const {
      handleAuthorizeSucces,
      operationType,
      solicitation,
      showAutorizationOnline,
      handleClose
    } = this.props;

    return (
      <Modal
        noOverlay={true}
        closeOnOverlayClick
        ref={this.modalConfirm}
        opened={false}
        closable={true}
        onClickClose={handleClose}
        className="modalAuthorization"
        id="modalAuthorization"
        style={{
          width: '450px',
          height: '360px'
        }}
      >
        <Modal.Body className='ModalBody'>
          {showAutorizationOnline?.show ? <AuthorizationOnline
            operation={operationType}
            solicitation={solicitation}
            handleClickConfirm={handleAuthorizeSucces}
            handleClickCancel={() => undefined}
            validateFields={() => true}
          /> : <div className='divDescricaoSupervisor'><b>INFORME OS DADOS DO SUPERVISOR</b></div>
          }
          <div className='authorization'>
            <input
              ref={this.userReferenceId}
              type='number'
              className='operator-input'
              placeholder='Informe o supervisor'
            />
            <PasswordInput
              ref={this.userPassword}
              className='password-input'
              placeholder='Senha supervisor'
            ></PasswordInput>
            <Button
              className='btn-purple'
              onClick={this.confirmClick}
            >
              CONFIRMAR
            </Button>
            {this.state.authenticationMessage && this.state.authenticationMessage.length > 0 && (
              <span className='spanAuthorizationMessage'>{this.state.authenticationMessage}</span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AuthorizationValidation;