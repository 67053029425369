import createReducer from './createReducer';
import * as types from '../actions/actionTypes';

const initialState = {
  isOpen: true,
  paymentTypeList: [],
  changeMoney: false,
  amountRemaning: null
};

const initialVisibleState = {
  showPaymentTypeList: false,
  showSaleFinish: false,
  showPaymentMoney: false,
  showPaymentPix: false,
  showDebitoDetailAuthorization: false,
  showTefMessage: false,
  showDocumentBox: false,
  showTefOptions: false,
  showTefInteraction: false
};

const paymentReducer = createReducer(initialState, {
  [types.END_PAYMENT]: (state, action) => ({
    ...state,
    payment: action.payload,
  }),
  [types.SHOW_LIST_PAYMENTS]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    showPaymentTypeList: true,
  }),
  [types.CLOSE_LIST_PAYMENTS]: (state, action) => ({
    ...state,
    showPaymentTypeList: false,
    changeMoney: false
  }),
  [types.PAYMENT_INPUT_VALUE]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    showPaymentMoney: true,
  }),
  [types.PAYMENT_CHANGE_MONEY]: (state, action) => ({
    ...state,
    changeMoney: action.payload
  }),
  [types.PAYMENT_AMOUNT_REMANING]: (state, action) => ({
    ...state,
    amountRemaning: action.payload
  }),
  [types.PAYMENT_TOGGLE_DOCUMENT_BOX]: (state, action) => ({
    ...state,
    showDocumentBox: action.payload
  }),
  [types.TEF_OPTIONS_SELECT]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    showTefOptions: true
  }),
  [types.TEF_OPTIONS_COMPLETED]: (state, action) => ({
    ...state,
    ...initialVisibleState,
    showTefMessage: true
  }),
});

export default paymentReducer;
