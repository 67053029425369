import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '../button-component';

import { Card } from '../card';
import { ContainerComponent } from '../container/container-component';

import ImgBarCode from '../../../assets/images/barcode.svg';
import ImgBarCodeBtn from '../../../assets/images/barcode-btn.svg';
import { BoxContainer } from './style';
import { ConferenceScanner } from './conference-scanner/conference-scanner';

import ConferenciaParcialComponent, {
  ICheckPartial,
} from './conferencia-parcial/conferencia-parcial';

import { IPayloadCashClose } from './interfaces/payloadCashClose';
import { IConference } from './interfaces/conference';
import MessageBusService from '../../../services/MessageBusService';
import {
  BarCodeTypeCategoryEnum,
  BarCodeTypeEnum,
} from './enum/barCodeTypeEnum';
import { ShowMessage } from '../show-message/show-message';

interface INewCashClose {
  handleConfirm: any;
  handleCancel: any;
  propsToCashClose: IPayloadCashClose;
}

function NewCashClose(newCashClose: INewCashClose) {
  const [valuesToCheck, setValuesToCheck] = useState<ICheckPartial[]>([]);
  const [conferenceToScanner, setConferenceToScanner] = useState<boolean>(
    false
  );
  const [conferenceListScanner, setConferenceListScanner] = useState<
    IConference[]
  >([]);
  const [handleBarCodeSuccess, setHandleBarCodeSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageCancel, setShowMessageCancel] = useState(false);
  const [showMessageCancelClosing, setShowMessageCancelClosing] = useState(
    false
  );
  const [showMessageConfirmClosing, setShowMessageConfirmClosing] = useState(
    false
  );
  const [hasValueInputs, setHasValueInputs] = useState(false);
  const [hasUsavedValues, setHasUsavedValues] = useState(false);
  const [updateConferenceList, setUpdateConferenceList] = useState(false);

  const partialClosure = newCashClose.propsToCashClose.parcial;

  useEffect(() => {
    setObjectValuesToCheck();

    const barCodeSuccess = MessageBusService.GetInstance().Subscribe(
      'pos.barcode.login.success',
      reloadWithMultipleKeys
    );

    return () => MessageBusService.GetInstance().Unsubscribe(barCodeSuccess);
  }, []);

  useEffect(() => {
    if (!handleBarCodeSuccess) return;

    getDataConferenceScanner(conferenceListScanner);
  }, [handleBarCodeSuccess]);

  const reloadWithMultipleKeys = (response, data) => {
    let conference: IConference[] = data.map((d) => {
      return {
        id: d.id,
        descricao: BarCodeTypeEnum[d.barCodeType],
        value: d.amount,
        category: BarCodeTypeCategoryEnum[d.barCodeType],
      } as IConference;
    });

    setConferenceListScanner(conference);
    setHandleBarCodeSuccess(true);
  };

  const setObjectValuesToCheck = () => {
    let values = [
      {
        tipoMovimento: 'Dinheiro',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.money
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.money
          : 0,
        show: newCashClose.propsToCashClose.configurations.showMoney,
        category: 'money',
      },
      {
        tipoMovimento: 'Sangrias realizadas',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.withDraw
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.withDraw
          : 0,
        show: newCashClose.propsToCashClose.configurations.showWithDraw,
        category: 'withDraw',
      },
      {
        tipoMovimento: 'Crediário',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.creditSale
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.creditSale
          : 0,
        show: newCashClose.propsToCashClose.configurations.showCreditSale,
        category: 'creditSale',
      },
      {
        tipoMovimento: 'Convênio',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.agreementSale
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.agreementSale
          : 0,
        show: newCashClose.propsToCashClose.configurations.showAgreementSale,
        category: 'agreementSale',
      },
      {
        tipoMovimento: 'Cartão de crédito',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.creditCard
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.creditCard
          : 0,
        show: newCashClose.propsToCashClose.configurations.showCreditCard,
        category: 'creditCard',
      },
      {
        tipoMovimento: 'Cartão de débito',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.debitCard
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.debitCard
          : 0,
        show: newCashClose.propsToCashClose.configurations.showDebitCard,
        category: 'debitCard',
      },
      {
        tipoMovimento: 'QR Linx',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.qrLinx
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.qrLinx
          : 0,
        show: newCashClose.propsToCashClose.configurations.showQrLinx,
        category: 'qrLinx',
      },
      {
        tipoMovimento: 'PIX',
        valorRegistradoPdv: newCashClose.propsToCashClose.systemValues
          ? newCashClose.propsToCashClose.systemValues.pix
          : 0,
        totalConferido: newCashClose.propsToCashClose.userValues
          ? newCashClose.propsToCashClose.userValues.pix
          : 0,
        show: newCashClose.propsToCashClose.configurations.showPix,
        category: 'pix',
      },
    ] as ICheckPartial[];

    setValuesToCheck(values);
  };

  const updateCheckPartial = (valuesToCheckToUpdate: ICheckPartial[]) => {
    setValuesToCheck(valuesToCheckToUpdate);
  };

  const handleConfirm = () => {
    if (verifyInputsWithValue()) return;
    setShowMessageConfirmClosing(!showMessageConfirmClosing);
  };

  const confirmar = (callToBtnFechamento: boolean) => {
    setShowMessageCancel(false);
    setShowMessageConfirmClosing(false);

    if (verifyInputsWithValue()) return;

    let closeCashDeskValues = valuesToCheck
      .filter((x) => x.show)
      .reduce(
        (prev, current) => ({
          ...prev,
          [current.category]: current.totalConferido,
        }),
        {}
      );

    newCashClose.handleConfirm(
      closeCashDeskValues,
      callToBtnFechamento ? false : partialClosure
    );
  };

  const cancel = (forceCancel: boolean) => {
    if (verifyInputsWithValue()) return;

    if (partialClosure && hasUsavedValues && !forceCancel) {
      setShowMessageCancel(true);
      return;
    }
    if (hasUsavedValues && !forceCancel) {
      setShowMessageCancelClosing(true);
      return;
    }

    newCashClose.handleCancel(conferenceListScanner.map((x) => x.id));
  };

  const getDataConferenceScanner = (conferenceList: IConference[]) => {
    let conferenceListArray = conferenceListScanner;

    conferenceList.map((conf) => {
      conferenceListArray.push(conf);

      valuesToCheck
        .filter((x) => x.category === conf.category)
        .forEach((x) => {
          x.totalConferido = x.totalConferido + +conf.value;
        });
    });

    setConferenceListScanner(conferenceListArray);
    setUpdateConferenceList(true);

    updateCheckPartial([...valuesToCheck]);
  };

  const closeConferenceScanner = (conferenceList: IConference[]) => {
    setConferenceToScanner(false);
    setShowMessage(true);
    getDataConferenceScanner(conferenceList);
  };

  const handleConferenceToScanner = () => {
    setUpdateConferenceList(false);
    setConferenceToScanner(true);
  };

  const verifyInputsWithValue = () => {
    let inputs = document.querySelectorAll('input');

    let result = Array.from(inputs).some((x) => x.defaultValue !== '0,00');

    setHasValueInputs(result);

    return result;
  };

  const setFocusBtnSuccess = () => {
    let btn = document.querySelector('#btn-b-succes-save') as any;

    if (btn) btn.focus();
  };

  return (
    <Card
      id='card-close'
      title={partialClosure ? `Conferência Parcial` : `Fechamento de caixa`}
      subTitle={`Abertura: ${newCashClose.propsToCashClose.openingDate} - Operador: ${newCashClose.propsToCashClose.userReferenceId} - ${newCashClose.propsToCashClose.userName}, Terminal: ${newCashClose.propsToCashClose.posId} - Seq: ${newCashClose.propsToCashClose.cashDeskCode}`}
      handleClose={() => cancel(false)}
      closeEnable
      autoScroll
      orientationVertical={true}
      style={{
        alignItems: 'center',
      }}
      marginButtonTitle={'.5rem'}
      escAction={() => cancel(false)}
    >
      {conferenceToScanner && (
        <ConferenceScanner
          handleClickConfirm={closeConferenceScanner}
          handleClickCancel={() => setConferenceToScanner(false)}
        />
      )}

      {showMessage && (
        <ShowMessage
          title={'Conferência Realizada com sucesso!'}
          message={'As movimentações foram adicionadas ao total movimentado.'}
          handleClickConfirm={() => setShowMessage(false)}
          handleClickCancel={() => setShowMessage(false)}
          type={'success'}
        />
      )}

      {hasValueInputs && (
        <ShowMessage
          title={'Atenção!'}
          message={
            'Existem valores pendentes a serem adicionados na conferência. Verifique.'
          }
          handleClickConfirm={() => setHasValueInputs(false)}
          handleClickCancel={() => setHasValueInputs(false)}
          type={'warning'}
          showBtnCancel={false}
          titleBtnCancel={''}
          titleBtnConfirm={'Ok'}
        />
      )}

      {showMessageCancel && (
        <ShowMessage
          title={'As alterações feitas não foram salvas!'}
          message={
            'Para não perder o que foi preenchido, recomendamos que você salve as alterações primeiro.'
          }
          handleClickConfirm={confirmar}
          handleClickCancel={() => cancel(true)}
          type={'warning'}
          showBtnCancel={true}
          titleBtnCancel={'Voltar sem Salvar'}
          titleBtnConfirm={'Quero Salvar'}
        />
      )}

      {showMessageCancelClosing && (
        <ShowMessage
          title={'As alterações feitas não serão salvas!'}
          message={
            'Existem valores adicionados no fechamento, ao cancelar, esses valores serão perdidos.'
          }
          handleClickConfirm={() => setShowMessageCancelClosing(false)}
          handleClickCancel={() => cancel(true)}
          type={'warning'}
          showBtnCancel={true}
          titleBtnCancel={'Cancelar Fechamento'}
          titleBtnConfirm={'Finalizar'}
        />
      )}

      {showMessageConfirmClosing && (
        <ShowMessage
          title={'Confirmar fechamento de caixa!'}
          message={
            'Após o fechamento, esse caixa não poderá ser reaberto e valores preenchidos não poderão ser alterados.'
          }
          handleClickConfirm={() => confirmar(true)}
          handleClickCancel={() => handleConfirm()}
          type={'warning'}
          showBtnCancel={true}
          titleBtnCancel={'Voltar'}
          titleBtnConfirm={'Confirmar'}
        />
      )}

      <ContainerComponent id='container-cash-close' style={{ width: '700px' }}>
        <div className='row'>
          <BoxContainer>
            <div className='box'>
              <div className='codigo-barra'>
                <img src={ImgBarCode} />
              </div>
              <div className='descricao'>
                <span>Agilize a conferência escaneando os comprovantes.</span>
              </div>
              <div className='btn'>
                <ButtonComponent
                  className='btn-b-success'
                  title={'Escanear Comprovantes'}
                  tooltip={'Escanear Comprovantes de Sangria'}
                  onClick={() => handleConferenceToScanner()}
                  icon={ImgBarCodeBtn}
                  style={{
                    width: '210px',
                    padding: '0px',
                  }}
                  sizeSpan={'14px'}
                />
              </div>
            </div>
          </BoxContainer>
        </div>

        <div className='row'>
          <ConferenciaParcialComponent
            checkPartial={valuesToCheck}
            conferenceListScanner={conferenceListScanner}
            updateCheckPartial={updateCheckPartial}
            updateConferenceList={updateConferenceList}
            hasUnsavedValues={setHasUsavedValues}
            conferenciaCega={newCashClose.propsToCashClose.blindConference}
            setFocusBtnSuccess={setFocusBtnSuccess}
          />
        </div>

        <div className='row' style={{ display: 'flex' }}>
          <div className='btn-group' style={{ justifyContent: 'flex-start' }}>
            <ButtonComponent
              title='Fechar caixa'
              name='btn-fechar-caixa'
              onClick={() => handleConfirm()}
              style={{
                marginRight: '.5rem',
                background: '#FF9200',
                border: '1px solid #FF9200',
              }}
              colorSpan='#FFFFFF'
              hidden={!partialClosure}
            />
          </div>
          <div
            className='btn-group'
            style={{
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <ButtonComponent
              id={'btn-b-succes-save'}
              className='btn-b-success'
              title={partialClosure ? 'Salvar' : 'Finalizar'}
              onClick={() =>
                partialClosure ? confirmar(false) : handleConfirm()
              }
            />

            <ButtonComponent
              title='Cancelar'
              name='btn-cancel'
              onClick={() => cancel(false)}
              style={{ marginRight: '.5rem' }}
            />
          </div>
        </div>
      </ContainerComponent>
    </Card>
  );
}

export default NewCashClose;
