import styled from 'styled-components';

const ItemBoxWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -15px;
  flex: 1 1 auto;

  .item-control-controller {
    vertical-align: middle;

    min-height: 100px;
    max-height: 350px;
    width: 10%;
  }

  .control {
    width: 30%;

    float: left;
  }

  a {
    color: #222;
    text-decoration: none;
  }
`;

export default ItemBoxWrapper;

export const HeaderWrapper = styled.div`
  position: relative;

  .item-header {
    background-color: #994499;
  }

  p {
    width: calc(100% - 40px);
    margin-top: 0;

    float: left;
    font-weight: bold;
  }

  #countItem {
    text-align: center;

    height: 27px;
    width: 35px;
    border-radius: 35px;
    padding-top: 2px;

    float: right;

    background-color: #eee;
  }
`;
