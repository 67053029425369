/* eslint-disable no-control-regex */

import React, { Component } from 'react';

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showCard } from '../../redux/actions/routerAction';
import {
  FARMACIA_POPULAR_ERROR,
  getPayload,
  shouldShow,
} from '../../redux/actions/views';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import OrderService from '../../services/orderService';
import DialogBox from '../../shared/components/dialog-box';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';

export class FarmaciaPopularError extends Component {
  componentDidMount() {
    const { MessageBus } = this.props;
    MessageBus.Subscribe(
      'pos.farmaciapopular.confirmingAuthorizationError',
      this.showError
    );

    MessageBus.Subscribe('pos.farmaciapopular.cancelOrder', this.cancelOrder);
  }

  showError = (ch, data) => {
    this.props.showFarmaciaPopular(data);
  };

  cancelOrder = (ch, data) => {
    new OrderService().startOrderCancellation(
      data.orderId,
      'Falha no serviço de confirmação do Farmácia Popular',
      this.props.tpAmb,
      true
    );
  };

  handleCancel = () => {
    new FarmaciaPopularService().startOrderCancellation();
  };

  handleRetry = () => {
    new FarmaciaPopularService().retryAuthorization();
  };

  render() {
    const { show, payload } = this.props;
    return show ? (
      <DialogBox
        icon="warning"
        message={parse(
          payload.message.replace(new RegExp('\r?\n', 'g'), '<br />')
        )}
        title="Erro ao processar solicitação"
        onNegativeButtonClick={this.handleCancel}
        negativeButtonText="Cancelar operação"
        onPositiveButtonClick={this.handleRetry}
        positiveButtonText="Tentar novamente"
        focusOnPositiveButton
      />
    ) : (
      ''
    );
  }
}

FarmaciaPopularError.propTypes = {
  MessageBus: PropTypes.shape({
    Subscribe: PropTypes.func,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  showFarmaciaPopular: PropTypes.func.isRequired,
  tpAmb: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    message: PropTypes.string,
  }),
};

FarmaciaPopularError.defaultProps = {
  payload: null,
};

const mapStateToProps = (state) => ({
  show: shouldShow(state, FARMACIA_POPULAR_ERROR),
  payload: getPayload(state, FARMACIA_POPULAR_ERROR),
  tpAmb: state.config && state.config.danfe && state.config.danfe.tpAmb,
});

const mapDispatchToProps = (dispatch) => ({
  showFarmaciaPopular: (payload) =>
    dispatch(showCard(FARMACIA_POPULAR_ERROR, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(FarmaciaPopularError)));
