/* eslint-disable react/prefer-stateless-function */
import { Button } from '../../shared/components/modules';
import { Modal } from "../../shared/components/modal2";
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { closeAllCards, getPayload, showCard, FARMACIA_POPULAR_CONFIRM, FARMACIA_POPULAR, shouldShow } from '../../redux/actions/routerAction';
import FarmaciaPopularConfirm from '../../shared/components/farmacia-popular-confirm';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import AdministrativeService from '../../services/AdministrativeService';
import FarmaciaPopularCustomerInfo from '../../shared/components/farmacia-popular-customer-info';
import SearchCepContainer from '../search-cep';

const MESSAGE_BUS_CHANNEL_HIDE = 'pos.farmaciapopular.authorized.hide';
const MESSAGE_BUS_CHANNEL_ERROR = 'pos.farmaciapopular.authorize.error';
const MESSAGE_BUS_CHANNEL_APPLIED = 'pos.farmaciapopular.applied';
const MESSAGE_BUS_CHANNEL_SHOWCUSTOMERINFO = 'pos.farmaciapopular.showCustomerInfo';
const MESSAGE_BUS_CHANNEL_SETCUSTOMERINFOSUCCESS = 'pos.farmaciapopular.updateCustomerInfoSuccess';
const MESSAGE_BUS_CHANNEL_SETCUSTOMERINFOERROR = 'pos.farmaciapopular.updateCustomerInfoError';
const MESSAGE_BUS_CHANNEL_GETCEPRETURN = 'pos.administrative.getCepReturn';

export class FarmaciaPopularConfirmContainer extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    hideFarmaciaPopularConfirm: PropTypes.func.isRequired,
    appliedFarmaciaPopular: PropTypes.func.isRequired,
    MessageBus: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.modalFarmaciaPopularCustomerInfo = React.createRef();
    this.inputNumberRef = React.createRef();

    this.state = {
      showCustomerInfo: false,
      customerInfo: null,
      showSearchCep: false,
      isKeyDownEnter: false
    };

    this.handleShowError = this.handleShowError.bind(this);
    this.handleDialogMessage = this.handleDialogMessage.bind(this);
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.handleShowCustomerInfo = this.handleShowCustomerInfo.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSetCustomerInfo = this.handleSetCustomerInfo.bind(this);
    this.handleSetCustomerInfoSuccess = this.handleSetCustomerInfoSuccess.bind(this);
    this.handleSearchCep = this.handleSearchCep.bind(this);
    this.handleSearchCepClose = this.handleSearchCepClose.bind(this);
    this.handleIdentifyCepClick = this.handleIdentifyCepClick.bind(this);

    const { MessageBus, hideFarmaciaPopularConfirm, appliedFarmaciaPopular } = this.props;

    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_HIDE, (rk, payload) => {
      hideFarmaciaPopularConfirm(this.props.farmaciaPopular ?? payload);
    });
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_ERROR, this.handleShowError);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_APPLIED, appliedFarmaciaPopular);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SHOWCUSTOMERINFO, this.handleShowCustomerInfo);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SETCUSTOMERINFOSUCCESS, this.handleSetCustomerInfoSuccess);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_SETCUSTOMERINFOERROR, this.handleShowError);
    MessageBus.Subscribe(MESSAGE_BUS_CHANNEL_GETCEPRETURN, this.handleCepReturn);
  }

  handleSearchCep() {
    if (this.state.isKeyDownEnter) {
      this.setState({ isKeyDownEnter: false });
    } else {
      const cep = this.state.customerInfo?.address?.zipCode?.replace(/[^0-9]+/g, '');
      if (cep?.length !== 8) return;
      new AdministrativeService().getCep(cep);
    }
  };

  handleCepReturn = (ch, payload) => {
    this.setState(prevState => {
      const updatedCustomerInfo = { ...prevState.customerInfo };
      if (updatedCustomerInfo && updatedCustomerInfo.address) {
        updatedCustomerInfo.address.street = payload.rua;
        updatedCustomerInfo.address.city = payload.cidade;
        updatedCustomerInfo.address.state = payload.uf;
        updatedCustomerInfo.address.neighborhood = payload.bairro;
      }
      return { customerInfo: updatedCustomerInfo };
    });
    this.setState({ cepCallback: true });
    if (this.inputNumberRef.current) {
      this.inputNumberRef.current.focus();
    }
  };

  handleSetCustomerInfo() {
    const service = new FarmaciaPopularService();
    service.setCustomerInfo(this.state.customerInfo);
  }

  handleChangeCustomerInfo = (e) => {
    const { id, value } = e.target;
    this.setState(prevState => {
      const updatedCustomerInfo = { ...prevState.customerInfo };
      if (updatedCustomerInfo && updatedCustomerInfo.address)
        updatedCustomerInfo.address[id] = value;
      return { customerInfo: updatedCustomerInfo };
    });
  };

  handleKeyDownZipCode = (e) => {
    if (e.key === 'Enter') {
      this.setState({ isKeyDownEnter: true });
      e.preventDefault();
      this.handleSearchCep();
    }
  };

  handleSetCustomerInfoSuccess(ch, payload) {
    const payloadToSend = {
      authorization: this.props.authorization
    };
    const service = new FarmaciaPopularService();
    service.AuthorizedConfirmed(payloadToSend);
    this.modalFarmaciaPopularCustomerInfo.current.close();
  }

  handleShowCustomerInfo(ch, payload) {
    this.setState({
      customerInfo: payload
    });
    this.modalFarmaciaPopularCustomerInfo.current.open();
    this.modalFarmaciaPopularCustomerInfo.current.focus();
  }

  handleCloseModal() {
    this.modalFarmaciaPopularCustomerInfo.current.close();
  }

  handleShowError(ch, payload) {
    this.context.showMessage(payload && payload.reason);
  }

  handleDialogMessage(message) {
    this.context.showMessage(message);
  }

  confirm() {
    const service = new FarmaciaPopularService();
    service.showCustomerInfo();
  }

  close() {
    const service = new FarmaciaPopularService();
    service.AuthorizedBack();
  }

  handleSearchCepClose() {
    this.setState({ showSearchCep: false });
    this.modalFarmaciaPopularCustomerInfo.current.open();
  }

  handleIdentifyCepClick = () => {
    this.setState({ showSearchCep: true });
    this.modalFarmaciaPopularCustomerInfo.current.close();
  };

  handleSelectCep = (payload) => {
    this.setState(prevState => {
      const updatedCustomerInfo = { ...prevState.customerInfo };
      if (updatedCustomerInfo && updatedCustomerInfo.address) {
        updatedCustomerInfo.address.street = payload.rua;
        updatedCustomerInfo.address.neighborhood = payload.bairro;
        updatedCustomerInfo.address.city = payload.cidade;
        updatedCustomerInfo.address.state = payload.uf;
        updatedCustomerInfo.address.zipCode = payload.cep;
        return {
          customerInfo: updatedCustomerInfo,
          showSearchCep: false
        };
      }
    });

    this.modalFarmaciaPopularCustomerInfo.current.open();
  };

  render() {
    return (
      this.props.show && (
        <Fragment>
          <FarmaciaPopularConfirm
            ref={ref => { this.contentRef = ref; }}
            title="FARMÁCIA POPULAR"
            authorization={this.props.authorization}
            confirm={this.confirm}
            close={this.close}
            message={this.handleDialogMessage}
          />
          {this.state.showSearchCep ?
            <SearchCepContainer
              show={true}
              onClose={this.handleSearchCepClose}
              onSelect={this.handleSelectCep}
            /> : ''}
          <Modal
            ref={this.modalFarmaciaPopularCustomerInfo}
            noOverlay={false}
            opened={false}
            closable={true}
            className="modalFarmaciaPopularCustomerInfo"
            id="modalFarmaciaPopularCustomerInfo"
            style={{
              height: '450px',
              width: '70vw',
              boxShadow: '0px 0px 5px 0 rgba(0, 0, 0, 0.2)',
            }}
          >
            <Modal.Body className='ModalBody'>
              <FarmaciaPopularCustomerInfo
                inputNumberRef={this.inputNumberRef}
                customerInfo={this.state.customerInfo}
                handleChangeCustomerInfo={this.handleChangeCustomerInfo}
                handleBlurZipCode={this.handleSearchCep}
                handleKeyDownZipCode={this.handleKeyDownZipCode}
                onIdentifyCepClick={this.handleIdentifyCepClick}
                showSearchCep={this.state.showSearchCep}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-outline"
                id="btn-back"
                type="button"
                onClick={this.handleCloseModal}
                style={{ margin: '16px', width: '100px' }}
              >
                VOLTAR
              </Button>
              <Button
                className="btn btn-outline"
                id="btn-confirm"
                type="button"
                onClick={this.handleSetCustomerInfo}
                style={{ margin: '16px', width: '100px' }}
              >
                CONFIRMAR
              </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      )
    );
  }
}

const mapStateToProps = state => ({
  show: shouldShow(state, FARMACIA_POPULAR_CONFIRM),
  authorization: getPayload(state, FARMACIA_POPULAR_CONFIRM).authorization,
  farmaciaPopular: getPayload(state, FARMACIA_POPULAR_CONFIRM).farmaciaPopular
});

const mapDispatchToProps = dispatch => ({
  hideFarmaciaPopularConfirm: (farmaciaPopular) => { dispatch(showCard(FARMACIA_POPULAR, farmaciaPopular)) },
  appliedFarmaciaPopular: () => dispatch(closeAllCards())
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(FarmaciaPopularConfirmContainer)));
