import React, { useEffect, useState } from 'react';
import onKeyDownEnter from '../../../utils/onKeyDownEnterToNextInput';
import { ValidationErrors } from '../../validation-errors/validation-errors';

import { IInputBase } from '../interface/input.interface';
import { InputDate } from './style';

export default function DatetimeComponent(props: IInputBase) {
  const [touched, setTouched] = useState(false);
  const [hasError, setError] = useState(false);
  const [className, setClassName] = useState('');

  useEffect(() => setClass(), [hasError]);

  useEffect(() => setClass(), [touched]);

  useEffect(() => setTouched(props.touched as boolean), [props.touched]);

  const setClass = () => {
    setClassName(touched && hasError ? 'has-error' : hasError ? 'error' : '');
  };

  const onChangeSetError = (value: boolean) => {
    setError(value);
  };

  const onBlur = () => {
    setTouched(true);
  };

  return (
    <>
      <InputDate
        {...props}
        data-testid={props.id}
        onBlur={onBlur}
        autoFocus={props.autoFocus}
        className={`form-control ${className}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        selected={props.value}
        onKeyDown={($event) => onKeyDownEnter($event, props.id)}
      />
      {props.showError && (
        <ValidationErrors
          fieldNameId={props.id}
          fieldValue={props.value}
          typeError='required'
          touched={touched}
          onChangeEvent={onChangeSetError}
        />
      )}
    </>
  );
}
