import React, { Fragment } from 'react';

import { connect } from 'react-redux';

import {
  PBM_AUTHORIZATION_LIST,
  showCard,
  shouldShow,
  closeAllCards,
  getPayload,
} from '../../redux/actions/routerAction';
import FarmaciaPopularService from '../../services/farmaciaPopularService';
import PbmSaleService from '../../services/pbmSaleService';
import { PbmAuthorizationListComponent } from '../../shared/components/modules';
import { getContext } from '../../shared/hoc/getContext';
import { withMessageBus } from '../../shared/utils/messageBus';
import { showToast } from '../../shared/utils/util';
import { addDays, subDays, subMinutes } from 'date-fns';

export class PbmAuthorizationListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizations: [],
    };

    this.props.MessageBus.Subscribe('pos.open.pbmAuthList', this.handleOpened);
    this.props.MessageBus.Subscribe('pos.close.pbmAuthList', this.handleClosed);
    this.props.MessageBus.Subscribe('pos.pbm.pbmAuthListed', this.handleList);
    this.props.MessageBus.Subscribe('pos.pbm.reprintSuccess', this.handleReprintSuccess);
    this.props.MessageBus.Subscribe('pos.seven.cancelationSuccess', this.handleCancelSuccess);

    this.props.MessageBus.Subscribe(
      'pos.pbm.prescriptionUploaded',
      this.handleUploadSuccess
    );
    this.props.MessageBus.Subscribe(
      'pos.pbm.prescriptionUploadFailed',
      this.handleUploadError
    );

    this.props.MessageBus.Subscribe(
      'pos.pbm.pbmReversed',
      this.handleReverseSuccess
    );

    this.props.MessageBus.Subscribe(
      'pos.pbm.pbmReverseFail',
      this.handleReverseError
    );

    // this.props.MessageBus.Subscribe('pos.pbm.pbmCanceled', this.handlePbmCanceled);
    // this.props.MessageBus.Subscribe('pos.pbm.pbmCancelFailed', this.handlePbmCancelFailed);

    this.inputFile = React.createRef();

    this.prescriptions = [];
    this.selected = null;
  }

  handleCancelSuccess = (ch, payload) => {
    const minDate = subDays(new Date(), 7);
    const maxDate = subMinutes(addDays(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 1), 1);

    const initialFilter = {
      initialDate: minDate,
      finalDate: maxDate,
      pbm: '-1',
      status: '-1',
    };
    this.handleSearch(initialFilter);
  };

  handleUploadSuccess = (ch, payload) => {
    const auth = this.state.authorizations.find(
      (a) => a.authorization === this.selected.authorization
    );
    auth.prescriptionUploaded = true;
    this.forceUpdate();
    this.context.showMessage(payload.message);
  };

  handleReprintSuccess = (ch, payload) => {
    showToast({
      type: 3,
      title: 'Autorizações PBM',
      html: 'Transação reimpressa com sucesso!',
      hideAfterMilliseconds: 5000
    });
  };

  handleUploadError = (ch, payload) => {
    this.context.showMessage(payload.message);
  };

  handleReverseSuccess = () => {
    const { authorizations } = this.state;
    const newAuthorizations = authorizations.map((item) =>
      item.orderId === this.selected.orderId ? { ...item, status: 2 } : item
    );

    this.setState({ authorizations: newAuthorizations });

    this.context.showMessage(
      'Estorno da autorização Farmácia Popular realizado com êxito.'
    );
  };

  handleReverseError = () => {
    this.context.showMessage(
      'Não foi possível realizar o estorno dessa autorização. Entre em contato com o Farmácia Popular.'
    );
  };

  handleUploadError = (ch, payload) => {
    console.log(payload);
    this.context.showMessage(payload.message);
  };

  handleOpened = (ch, payload) => {
    this.props.open({
      pbmOptions: payload.pbmOptions,
      statusOptions: payload.statusOptions,
    });
  };

  handleClosed = () => {
    this.props.close();
    this.filterReference = {
      initialDate: null,
      finalDate: null,
      pbm: null,
      status: null,
    };
    this.setState({
      authorizations: null,
    });
  };

  handleFileChange = (fileEvent) => {
    function loadFile(file, length) {
      const reader = new FileReader();
      if (file.size / 1024 / 1024 > 2) {
        this.context.showMessage(
          'Arquivos maiores que 2MB não são permitidos.'
        );
      }
      reader.readAsDataURL(fileEvent.target.files[i]);
      reader.onload = (e) => {
        this.prescriptions.push({
          extensao: file.name.split('.')[file.name.split('.').length - 1],
          base64: reader.result.substring(reader.result.indexOf('base64,') + 7),
        });
        this.prescriptions.length === length && this.uploadPrescriptions();
      };
    }

    for (var i = 0; i < fileEvent.target.files.length; i++) {
      loadFile.bind(this)(
        fileEvent.target.files[i],
        fileEvent.target.files.length
      );
    }
    fileEvent.target.value = '';
  };

  handlePbmCancel = (infoPbm) => {
    this.selected = infoPbm;
    PbmSaleService.cancelPbm(
      infoPbm.authorization,
      infoPbm.pbmEnum,
      infoPbm.chaveNFCe,
      infoPbm.orderId
    );
  };

  handleReprint = (infoPbm) => {
    this.selected = infoPbm;
    if (infoPbm.pbmEnum === 0)
      return new FarmaciaPopularService().reprintCouponPbm(infoPbm.orderId);
    return PbmSaleService.reprintCoupon(infoPbm.authorization, infoPbm.pbmEnum);
  };

  handlePrescriptionUpload = (infoPbm) => {
    this.selected = infoPbm;
    this.inputFile.current.click();
  };

  uploadPrescriptions = () => {
    PbmSaleService.uploadPrescription(
      this.selected.authorization,
      this.selected.pbmEnum,
      this.selected.orderId,
      this.prescriptions
    );
    this.prescriptions = [];
  };

  handleList = (ch, payload) => {
    this.setState({
      authorizations: payload,
    });
  };

  handleSearch = (filter) => {
    PbmSaleService.filterAuthorizations(filter);
  };

  handleClose = () => {
    PbmSaleService.closeAuthorizationList();
  };

  handleReverse = (item) => {
    this.selected = item;
    //pbmEnum ===0 -> Cancelamento Farmacia Popular
    if (item.pbmEnum === 0)
      PbmSaleService.reverse(item.orderId);
    else
      PbmSaleService.cancelPbm(item.authorization, item.pbmEnum, item.chaveNFCe, item.orderId);
  };

  render() {
    return (
      this.props.visible && (
        <Fragment>
          <input
            style={{ display: 'none' }}
            type="file"
            multiple
            ref={this.inputFile}
            onChange={this.handleFileChange}
          />
          <PbmAuthorizationListComponent
            pbmOptions={this.props.payload.pbmOptions}
            statusOptions={this.props.payload.statusOptions}
            authorizations={this.state.authorizations}
            handleSearch={this.handleSearch}
            handleClose={this.handleClose}
            handlePbmCancel={this.handlePbmCancel}
            handleReprint={this.handleReprint}
            handleReverse={this.handleReverse}
            handlePrescriptionUpload={this.handlePrescriptionUpload}
            iconPath="./assets/images/pbm-auth-list.png"
            title="Autorizações PBM"
          />
        </Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  visible: shouldShow(state, PBM_AUTHORIZATION_LIST),
  payload: getPayload(state, PBM_AUTHORIZATION_LIST),
  order: state.order.order,
});

const mapDispatchToProps = (dispatch) => ({
  open: (payload) => dispatch(showCard(PBM_AUTHORIZATION_LIST, payload, true)),
  close: () => dispatch(closeAllCards()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(getContext(PbmAuthorizationListContainer)));
