import React from 'react';

import KeyboardEventHandler from 'react-keyboard-event-handler';

import store from '../../../redux/store';
import { ShortcutHintWrapper } from './style';

export class ShortcutHint extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.clear = this.clear.bind(this);
    this.shortcutBlocked = this.shortcutBlocked.bind(this);

    const keys = this.props.shortcut.keys.split('+');
    this.state = {
      name: this.props.shortcut.name,
      show: false,
      offsetLeft: this.props.offsetLeft ? this.props.offsetLeft : 0,
      offsetTop: this.props.offsetTop ? this.props.offsetTop : 0,
      firstKey: keys[0].toLowerCase(),
      secondKey: keys[1].toLowerCase(),
      keyCode: props.shortcut.keyCode,
    };
    this.timeoutFunc = null;

    // Essa variável foi criada devido à erros que estavam sendo lançados,
    // relacionados à tentar atualizar o estado em um componente que está unmounted
    // De alguma forma, creio que devido ao uso dos setTimeout's, o componente continuava a linha de execução
    // mesmo depois de não estar renderizado na tela
    // Nenhum setState deve ser executado caso componentWillUnmount tenha sido executado
    this.unmounting = null;

    window.addEventListener('blur', () => {
      this.clear();
      this.hide();
    });
  }

  shortcutBlocked() {
    const shortcutsToBlock = store.getState(store).shortcuts.shortcutsToBlock;
    if (!shortcutsToBlock || shortcutsToBlock.length === 0) {
      return false;
    }
    const found =
      shortcutsToBlock.findIndex((a) => a === this.state.name) !== -1;

    return found;
  }

  handleKeyDown(key, e) {
    if (this.shortcutBlocked()) return;
    e.preventDefault();
    if (!this.unmounting) {
      key = key === 'ctrl' ? 'control' : key;
      if (e.key.toLowerCase() === this.state.firstKey) {
        if (this.timeoutFunc) return;
        this.timeoutFunc = setTimeout(() => {
          this.showTooltip();
        }, 500);
      } else if (
        key.toLowerCase() === this.state.firstKey &&
        (e.key.toLowerCase() === this.state.secondKey ||
          e.keyCode === this.state.keyCode)
      ) {
        if (this.props.activateShortcut) {
          this.props.activateShortcut();
        }
        this.clear();
      }
    }
  }

  handleKeyUp(key, e) {
    e.preventDefault();
    if (!this.unmounting) {
      if (e.key.toLowerCase() === this.state.firstKey) {
        this.hide();
        this.clear();
      }
    }
  }

  showTooltip() {
    if (!this.unmounting) {
      if (
        (this.props.offsetLeft || this.props.offsetLeft === 0) &&
        (this.props.offsetTop || this.props.offsetTop === 0)
      ) {
        this.setState({
          show: true,
          offsetLeft: this.props.offsetLeft,
          offsetTop: this.props.offsetTop,
        });
      } else {
        const { offsetLeft, offsetTop } = this.shortcutInnerRef.parentNode;
        this.setState({
          show: true,
          offsetTop: offsetTop - 18 < 1 ? 1 : offsetTop - 18,
          offsetLeft: offsetLeft + 10,
        });
      }
    }
  }

  hide() {
    if (!this.unmounting) {
      this.setState({ show: false, offsetTop: 0, offsetLeft: 0 });
    }
  }

  clear() {
    clearTimeout(this.timeoutFunc);
    this.timeoutFunc = null;
  }

  componentWillUnmount() {
    this.unmounting = true;
    clearTimeout(this.timeoutFunc);
  }

  render() {
    const { firstKey, secondKey } = this.state;
    return (
      <ShortcutHintWrapper
        innerRef={(ref) => {
          this.shortcutInnerRef = ref;
        }}
        style={{
          visibility: this.state.show ? 'unset' : 'hidden',
          left: `${this.state.offsetLeft}px`,
          top: `${this.state.offsetTop}px`,
        }}
        className="hint"
      >
        {(secondKey === 'delete'
          ? 'DEL'
          : secondKey === 'control'
          ? 'CTRL'
          : secondKey
        ).toUpperCase()}
        <KeyboardEventHandler
          handleEventType="keydown"
          handleKeys={[firstKey === 'control' ? 'ctrl' : firstKey]}
          handleFocusableElements
          onKeyEvent={this.handleKeyDown}
        />
        <KeyboardEventHandler
          handleEventType="keyup"
          handleKeys={[firstKey === 'control' ? 'ctrl' : firstKey]}
          handleFocusableElements
          onKeyEvent={this.handleKeyUp}
        />
      </ShortcutHintWrapper>
    );
  }
}
