import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '../form-control';
import { Card, Button, Select } from '../modules';
import { PbmOpenWrapper } from './style';
import { SlideToggle } from '../slide-toggle/slide-toggle';

class Pbm extends Component {
  constructor(props) {
    super(props);

    this.formObject = {
      pbm: null,
      authorizationCode: null
    };
    this.getFirstValue = this.getFirstValue.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  getFirstValue(list) {
    if(list && list.length > 0) {
      return list[0];
    }
    return null;
  }

  componentDidMount() {
    this.codeRef.focus();
  }

  onKeyDown(e) {
    if(e.key === 'Enter') {
      this.props.handleClick(this.formObject.pbm, this.formObject.authorizationCode);
    }
  }
 
  renderSlider() {
    const { isCardOrCpfNumber, onNumberTypeChanged } = this.props;

    return (
      <div className="slideToggle">
        <label style={{marginBottom: '0px'}} className={isCardOrCpfNumber ? 'disable' : ''}>Número do atendimento (NSU)&nbsp;&nbsp;</label>
        <SlideToggle onChange={onNumberTypeChanged} checked={isCardOrCpfNumber} id="change-number-type"/>
        <label style={{marginBottom: '0px'}} className={!isCardOrCpfNumber ? 'disable' : ''}>&nbsp;&nbsp;Cartão / CPF</label>
      </div>
    );
  }

  render() {
    const {
      title,
      handleClose,
      handleClick,
      items,
      imgUrl
    } = this.props;

    return (
      <Card 
        id="card-pbm"
        closeEnable
        title={title}
        handleClose={handleClose}
        urlIcone={imgUrl}
      >
        <PbmOpenWrapper>
          <div className="custom-form-row">
            <FormControl
              required
              label="Benefícios disponíveis"
              component={Select}
              object={this.formObject}
              field="pbm"
              flex="0.5"
              propToShow="name"
              options={items}
              initialvalue={this.getFirstValue(items)}
            />
            <div>
              {this.renderSlider()}
              <FormControl
                ref={ref => this.codeRef = ref}
                id="codigo"
                required              
                object={this.formObject}
                field="authorizationCode"
                flex="0.5"
                onKeyDown={this.onKeyDown}
              />
            </div>
          </div>

          <Button
            id="btn-confirm"
            className="btn-large btn-outline btn-custom"
            type="button"
            handleClick={() => handleClick(this.formObject.pbm, this.formObject.authorizationCode)}
          >
            Confirmar
          </Button>
        </PbmOpenWrapper>
      </Card>
    );
  }
}

Pbm.propTypes = {
  onNumberTypeChanged: PropTypes.func,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  handleClick: PropTypes.func,
  imgUrl: PropTypes.string,
  isCardOrCpfNumber: PropTypes.bool
};

Pbm.defaultProps = {
  title: null,
  handleClose: null,
  handleClick: null,
  imgUrl: null,
  isCardOrCpfNumber: false
};

export default Pbm;
