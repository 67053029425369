import React from 'react';

import PropTypes from 'prop-types';

import LabelTitleValueWrapper from './style';

const LabelTitleValue = ({
  title,
  value,
  handleClick,
  icon,
  textClass,
  changeArrow,
}) => (
  <LabelTitleValueWrapper
    className="label-title-value-wrapper"
    onClick={handleClick}
  >
    {icon ? (
      <i className="material-icons icon" onClick={handleClick}>
        search
      </i>
    ) : (
      ''
    )}
    <div className={`TitleWrapper title-${textClass}`}>
      <div>{title}</div>
      <span>{value}</span>
    </div>
  </LabelTitleValueWrapper>
);

LabelTitleValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.bool,
  textClass: PropTypes.string,
  changeArrow: PropTypes.bool,
};

LabelTitleValue.defaultProps = {
  handleClick: null,
  changeArrow: null,
  textClass: '',
  icon: null,
};

export default LabelTitleValue;
