import styled from 'styled-components';


export const FarmaciaPopularModalMessageWrapper = styled.div`
  h1{
    padding: 10px 0px;
    margin: 0px;
    font-size: 20px;
    display: block;
    background-color: #f8f8f8;
    border-bottom: 1px dotted #ccc;
    line-height: 35px;
  }
  h1 i
  {
    vertical-align: middle;
    font-size: 35px;
    color: #FED859;
  }
  p
  {
    text-align: left;
    padding: 20px;
  }
  button.btn-default {
    display: inline;
  }
`
export default FarmaciaPopularModalMessageWrapper