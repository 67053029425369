import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardPaymentAuthorization } from '../../shared/components/modules';
import { showCard, CARD_AUTHORIZATION, shouldShow, getPayload } from '../../redux/actions/routerAction';
import PaymentService from '../../services/paymentService';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';

export class CardPaymentAuthorizationContainer extends Component {
  constructor(props) {
    super(props);
    this.props.MessageBus.Subscribe('payment.creditcard.details', this.eventCreditReturn);
    this.props.MessageBus.Subscribe('payment.debit.details', this.eventDebitReturn);

    this.paymentService = new PaymentService();
  }

  eventCreditReturn = (canal, payload) => {
    const componentPayload = {
      cardDetailAuthorization: payload,
      title: 'CRÉDITO',
      subTitle: 'Detalhe',
      image: './assets/images/payment-types/creditcard.svg',
      isCredit: true,
    };
    this.props.openCardAuthorization(componentPayload);
  }

  eventDebitReturn = (canal, payload) => {
    const componentPayload = {
      cardDetailAuthorization: payload,
      title: 'DÉBITO',
      subTitle: 'Detalhe',
      image: './assets/images/payment-types/debito.svg',
      isCredit: false,
    };
    this.props.openCardAuthorization(componentPayload);
  }

  onHandleClickFin = (creditDetails, code) => {
    this.paymentService.setCodeCardPayment(creditDetails, code)
    .catch(error => this.context.showError(error))
  }

  handleClose = () => {
    this.paymentService.backPayment()
      .catch(err => this.context.showError(err));
  }

  render() {
    const visible = this.props.showCardAuthorization;
    return (
      <div>
        {visible && (
          <CardPaymentAuthorization
            items={this.props.payload.cardDetailAuthorization}
            handleClickFin={this.onHandleClickFin}
            handleClose={this.handleClose}
            title={this.props.payload.title}
            subTitle={this.props.payload.subTitle}
            image={this.props.payload.image}
            isCredit={this.props.payload.isCredit}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showCardAuthorization: shouldShow(state, CARD_AUTHORIZATION),
  payload: getPayload(state, CARD_AUTHORIZATION)
});

const mapDispatchToProps = dispatch => ({
  openCardAuthorization: payload => dispatch(showCard(CARD_AUTHORIZATION, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(CardPaymentAuthorizationContainer)));
