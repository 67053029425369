import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Modal from 'react-modal';

import {
  blockAllShortcutsBut,
  unblockAllShortcuts,
} from '../../../redux/actions/shortcutsAction';
import Store from '../../../redux/store';
import Button from '../button';
import { Card, FormContent } from './styles';

const customStyle = {
  content: {
    maxWidth: '500px',
    boxShadow: '-4px 4px 5px 1px rgba(0,0,0,0.39)',
    background: '#ffffff',
    margin: 'auto',
    padding: '20px 20px 10px',
    border: 'none',
    maxHeight: 'min-content',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export class PreOrderId extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = { inputValue: '' };
  }

  componentDidMount() {
    Store.dispatch(blockAllShortcutsBut());
  }

  componentWillUnmount() {
    Store.dispatch(unblockAllShortcuts());
  }

  handleInputValueChange = (e) => this.setState({ inputValue: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();

    const { finishPreOrder } = this.props;
    const { inputValue } = this.state;

    finishPreOrder(inputValue);
  };

  render() {
    const { handleClose } = this.props;
    const { inputValue } = this.state;
    return (
      <Modal
        id="pre-order-id-modal"
        isOpen
        showCloseIcon={false}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick
        center
        style={customStyle}
        ariaHideApp={false}
      >
        <Card
          title="Identificador da Pré-venda"
          closeEnable
          handleClose={handleClose}
        >
          <Form onSubmit={this.handleSubmit}>
            <FormContent>
              <Form.Control
                id="pre-order-id"
                placeholder="Identificador"
                onKeyDown={this.handleKeyDown}
                maxLength={20}
                defaultValue={inputValue}
                onChange={this.handleInputValueChange}
                autoFocus
                autoComplete="off"
              />
              <Button
                data-testid="pre-order-id-submit"
                className="btn-purple"
                type="submit"
              >
                Salvar
              </Button>
            </FormContent>
          </Form>
        </Card>
      </Modal>
    );
  }
}

PreOrderId.propTypes = {
  handleClose: PropTypes.func.isRequired,
  finishPreOrder: PropTypes.func.isRequired,
};

export default PreOrderId;
