import React from 'react';
import { Card, Button } from '../modules';
import { Form, Col } from 'react-bootstrap';
import { PasswordInput } from '../password';
import './config-posconnect-component.scss';

function ConfigPosConnectComponent(props) {
    const {
        handleClose,
        handleConfirm,
        handleChange,
        form
    } = props;

    return (

        <Card id="config-posconnect-card" autoScroll={false} title="Configurações do PosConnect" handleClose={handleClose} closeEnable shouldFlex >
            <div className="body">
                <Form>
                    <Form.Row>
                        <Form.Label class="label-title">Configurações gerais</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="apiBaseUrl">
                            <Form.Label>Base url de comunicação da API</Form.Label>
                            <Form.Control
                                defaultValue={form?.apiBaseUrl ?? "https://api.pagar.me/core/v5"}
                                onChange={(e) => handleChange(e)}
                                placeholder={"https://api.pagar.me/core/v5"}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="publicKey">
                            <Form.Label>Chave pública</Form.Label>
                            <Form.Control
                                defaultValue={form?.publicKey}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Chave privada</Form.Label>
                            <PasswordInput value={form?.secretKey} onChange={(e) =>
                                handleChange({ target: { id: 'secretKey', value: e.target.value } })
                            }></PasswordInput>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
            <div className="footer">
                <Button id="confirm" className="btn btn-large btn-purple btn-custom" onClick={handleConfirm}>Salvar</Button>
            </div>
        </Card>
    );
}
export default ConfigPosConnectComponent;