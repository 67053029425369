import React, { Component } from 'react';

import PropTypes from 'prop-types';

import FormControl from '../../@form-control';
import Fieldset from '../../fieldset';

class Buyer extends Component {
  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
  }

  clearBuyer = () => {
    const { setFieldValue } = this.props;
    setFieldValue('buyer.name', '');
    setFieldValue('buyer.documentType', '50');
    setFieldValue('buyer.documentNumber', '');
    setFieldValue('buyer.documentState', '');
    setFieldValue('buyer.shippingAgency', '50');
  };

  focus = () => {
    this.nameRef.current.focus();
  };

  render() {
    const { options, showClearFields } = this.props;

    const states = [{ name: '', value: '' }, ...options.states];

    states.sort((a, b) => {
      return a.name?.localeCompare(b.name);
    });
    return (
      <Fieldset
        legend="Informações do comprador"
        hasRequiredField
        onClearFieldsClick={this.clearBuyer}
        clearFieldsText={
          showClearFields ? 'Limpar informações do comprador' : ''
        }
      >
        <div className="custom-form-row">
          <FormControl
            label="Nome do comprador*"
            name="buyer.name"
            flex="0.6"
            ref={this.nameRef}
          />
          <FormControl
            label="Tipo do Documento"
            name="buyer.documentType"
            flex="0.4"
            options={options.documentTypes}
            type="select"
          />

          <FormControl
            label="UF expedição*"
            name="buyer.documentState"
            flex="0.15"
            options={states}
            type="select"
          />
          <FormControl
            label="Órgão Expedidor*"
            name="buyer.shippingAgency"
            flex="0.15"
            options={options.dispatchingAgencys}
            type="select"
          />
          <FormControl
            label="Número documento*"
            name="buyer.documentNumber"
            flex="0.2"
          />
        </div>
      </Fieldset>
    );
  }
}

Buyer.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.shape({
    documentTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    dispatchingAgencys: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    states: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  showClearFields: PropTypes.bool.isRequired,
};

export default Buyer;
