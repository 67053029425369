import createReducer from './createReducer';
import * as types from '../actions/actionTypes';
import { shortcutList } from '../../keyboardShortcuts.js';

const initialState = { shortcutsToBlock: [] };

const shortcutsReducer = createReducer(initialState, {
  [types.BLOCK_SHORTCUTS]: (state, action) => {
      const scToBlock = state.shortcutsToBlock;
      if(action.shortcutsToBlock) {
        action.shortcutsToBlock.forEach(s => {
            if(scToBlock.findIndex(sc => s === sc) === -1)
                scToBlock.push(s)
        })
      }
      return ({
        ...state,
        shortcutsToBlock: scToBlock,
      });
  },
  [types.UNBLOCK_SHORTCUTS]: (state, action) => {
    const scToBlock = state.shortcutsToUnblock;
    if(action.shortcutsToUnblock) {
      action.shortcutsToUnblock.forEach(s => {
        const idx = scToBlock.findIndex(s);
          if(idx !== -1)
            scToBlock.splice(idx, 1);
      })
    }
    return ({
        ...state,
      shortcutsToBlock: scToBlock || []
    });
  },

  [types.BLOCK_ALL_SHORTCUTS_BUT]: (state, action) => {
    var scToBlock = state.shortcutsToBlock || [];
    if(!action.shortcutsToNotBlock || !action.shortcutsToNotBlock.length) {
      scToBlock = shortcutList.map(s => s.name);
      return ({
        ...state,
      shortcutsToBlock: scToBlock
    });
    }
    shortcutList.forEach(s => {
      if(action.shortcutsToNotBlock.findIndex(e => e === s.name) === -1) {
        scToBlock.push(s.name);
      }
    });
    return ({
      ...state,
    shortcutsToBlock: scToBlock
  });
  },

  [types.UNBLOCK_ALL_SHORTCUTS]: (state, action) => {
    return ({
        ...state,
      shortcutsToBlock: []
    });
  }
});

export default shortcutsReducer;
