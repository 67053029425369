import React, { HTMLAttributes, TextareaHTMLAttributes } from 'react';
import InputComponent from './input/input';

import LabelComponent from './label/label';
import InputGroup from './style';
import InputMoneyComponent from './input-money/input-money';
import TextAreaComponent from './textarea/textarea';
import RadioComponent from './radio/radio-component';
import { IRadioList } from './interface/radio.interface';
import DatetimeComponent from './datetime/datetime.component';
import InputMaskCustom from './input-mask-custom/inputMaskCustom';

interface IField extends HTMLAttributes<HTMLInputElement> {
  id: string;
  placeholder: string;
  labelText: string;
  type: string;
  showError: boolean;
  value: any;
  typeInput?: 'normal' | 'money' | 'textarea' | 'radio' | 'datetime' | 'custom';
  onBlur?: any;
  onChange?: any;
  touched?: boolean;
  maxLength?: number;
  rows?: number;
  wrap?: string;
  styledForInputGroup?: HTMLAttributes<HTMLDivElement>;
  autoFocus?: boolean;
  showMaxLenght?: boolean;
  onlyNumbers?: boolean;
  disabled?: boolean;
  radioList?: IRadioList[];
  mask?: any;
}

export function Field(props: IField) {
  return (
    <InputGroup {...props.styledForInputGroup}>
      <LabelComponent>{props.labelText}</LabelComponent>
      {props.typeInput === 'normal' && <InputComponent {...props} />}
      {props.typeInput === 'money' && <InputMoneyComponent {...props} />}
      {props.typeInput === 'textarea' && (
        <TextAreaComponent
          {...(props as TextareaHTMLAttributes<any>)}
          showMaxLenght={props.showMaxLenght ?? true}
        />
      )}
      {props.typeInput === 'radio' && (
        <RadioComponent
          radioList={props.radioList as IRadioList[]}
          {...props}
        />
      )}
      {props.typeInput === 'datetime' && <DatetimeComponent {...props} />}
      {props.typeInput === 'custom' && (
        <InputMaskCustom mask={props.mask as any} {...props} />
      )}
      {props.children && props.children}
    </InputGroup>
  );
}
