import React from 'react'
import { FormDetail } from './style';
import { FormControl, Button, SlideToggle } from '../modules'
import { Modal } from '../modal2';
import { Select } from '../select';
import { DatePicker } from '../date-picker';

class PbmInitialization extends React.Component {
  constructor(props) {
    super(props);

    this.getFirstValue = this.getFirstValue.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.modalConfirm = React.createRef();
  }

  formRefs = {
    convenioRef: React.createRef(),
    cardNumberRef: React.createRef(),
    prescriptorTypeRef: React.createRef(),
    prescriptorDocumentRef: React.createRef(),
    ufRef: React.createRef(),
    prescriptionDateRef: React.createRef(),
    withPrescriptionRef: React.createRef()
  }

  getFirstValue(array) {
    if(array && array.length > 0)
      return array[0];
    return null
  }

  componentDidMount() {
    this.modalConfirm.current.open();
    setTimeout(this.formRefs.cardNumberRef.current.focus, 10)
    document.querySelector('.modal-back-wrapper').addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    const overlay = document.querySelector('.modal-back-wrapper');
    overlay && overlay.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e) {
    if(e.key === 'Enter') {
      this.props.handleConfirm();
      return;
    }
    if(e.key === 'Escape') {
      this.props.handleClose();
    }
  }

  render() {
    const {
      handleConfirm,
      handleClose,
      form,
      convenios,
      pbmDescription,
      handleToggleChange,
      slideToggleValue,
      prescriptorTypes,
      states
    } = this.props;

    return (<Modal 
    title={`Inicialização ${pbmDescription}`}
    className="col-lg-4 col-md-7 col-sm-8 col-xs-8"
    closeOnOverlayClick
    ref={this.modalConfirm}
    opened={true}
    onClickClose={handleClose}
    style={{
      whiteSpace: 'nowrap'
    }}
  >
  <Modal.Body>
    <FormDetail>
      <div className="custom-form-row">
        <FormControl
          required
          label="Convênio"
          component={Select}
          object={form}
          ref={this.formRefs.convenioRef}
          field="convenio"
          flex="1"
          propToShow="nomeConvenio"
          options={convenios}
          initialvalue={this.getFirstValue(convenios)}
        />
      </div>
      <div className="custom-form-row">
        <FormControl
          required
          label="Número cartão beneficiário"
          object={form}
          ref={this.formRefs.cardNumberRef}
          field="cardNumber"
          flex="1"
        />
        <label style={{alignSelf: 'center'}}>Com receita&nbsp;</label>
        <SlideToggle 
          style={{alignSelf: 'center'}}
          onChange={handleToggleChange}
          checked={slideToggleValue}
          id="with-prescription"/>
      </div>
      <div className="custom-form-row">
        <FormControl
          required
          disabled={!slideToggleValue}
          label="Data Receita"
          object={form}
          component={DatePicker}
          ref={this.formRefs.prescriptionDateRef}
          field="prescriptionDate"
          flex="0.5"
        />
        <FormControl
          required
          haveNullValue
          disabled={!slideToggleValue}
          label="UF"
          component={Select}
          object={form}
          ref={this.formRefs.ufRef}
          options={states}
          field="prescriptorUf"
          flex="0.5"
        />
      </div>
      <div className="custom-form-row">
        <FormControl
            required
            haveNullValue
            disabled={!slideToggleValue}
            label="Tipo doc. prescritor"
            object={form}
            ref={this.formRefs.prescriptorTypeRef}
            field="prescriptorType"
            flex="0.5"
            component={Select}
            options={prescriptorTypes}
            propToShow="descricaoPrescritor"
            propToValue="tipoPrescritor"
          />
        <FormControl
          required
          disabled={!slideToggleValue}
          label="Doc. prescritor"
          object={form}
          ref={this.formRefs.prescriptorDocumentRef}
          field="prescriptorDocument"
          flex="0.5"
        />
      </div>
    </FormDetail>
  </Modal.Body>
  <Modal.Footer>
    <Button
      id="btn-confirm"
      className="btn-default btn-purple"
      type="button"
      onClick={handleConfirm}
    >
      Confirmar
    </Button>
    <Button
      id="btn-cancel"
      className="btn-default btn-outline"
      type="button"
      onClick={handleClose}
    >
      Cancelar
    </Button>
  </Modal.Footer>
</Modal>)
  }
}

export default PbmInitialization;