import AbstractService from './abstractService';

class LinxConectaService extends AbstractService {
  constructor() {
    super('/LinxConecta');
  }

  listarEmpresas = () => this.invokeAction('/ListarEmpresas');

  getConfig = () => this.invokeAction('/GetConfig');

  setConfig = (payload) => this.invokeAction('/SetConfig', payload);

  consultarCartoes = (empresaId, query) => this.invokeAction('/ConsultarCartoes', { empresaId, query });

  abrirTransacao = (linxConectaCartao) => this.invokeAction('/AbrirTransacao', { linxConectaCartao });

  solicitarTermosLGPD = (linxConectaCartao) => this.invokeAction('/SolicitarTermosLGPD', { linxConectaCartao });

  aplicarDescontos = () => this.invokeAction('/AplicarDescontos');

  cadastrarCartao = (empresaId) => this.invokeAction('/CadastrarCartao', { empresaId });

  senhaCartao = (senha) => this.invokeAction('/SenhaCartao', { senha });

  finalizarVenda = () => this.invokeAction('/FinalizarVenda');

  validarProdutos = () => this.invokeAction('/ValidarProdutos');

  consultarPecPbmsConveniado = (linxConectaCartao) => this.invokeAction('/consultarPecPbmsConveniado', { linxConectaCartao });

  obterFidelizaMais = (linxConectaCartao) => this.invokeAction('/obterFidelizaMais', { linxConectaCartao });

  editarCartao = (linxConectaCartao, empresaId) => this.invokeAction('/editarCartao', { linxConectaCartao, empresaId });
}

export default new LinxConectaService();
