import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardQueryComponent from '../card-query-component';

class CardCustomer extends Component {
  config;

  constructor(props) {
    super(props);

    this.config = {
      customClass: 'customer',
      selectable: true,
      limitPerPage: 300,
      channelToListenResponse: 'pos.customer.listed',
      queryFn: (criteria, page, limit) => props.executeQuery(criteria, page, limit),
      handleClick: () => props.handleClick(),
      lazy: true,
      reloadInPercent: 0.95,
      showAddCustomerBtn: this.props.initialQuery.source === 'sale' && this.props.isOnline,
      columns: [
        {
          title: 'CPF',
          label: 'cpf',
          customCellrender: this.renderObsInactive,
          fixedWidth: '12vw',
        },
        { title: 'Nome', label: 'name', fixedWidth: '28vw' },
        {
          title: 'Telefone',
          label: 'phoneNumber',
          fixedWidth: '12vw',
          collapsable: true,
        },
        this.props.initialQuery.source === 'sale' &&
          this.props.isOnline && {
            title: 'Editar',
            customCellrender: this.renderEdit,
            fixedWidth: '5vw',
          },
      ],
    };
  }

  renderObsInactive = (item) => {
    return <span id="spanCpf">{item.cpf}</span>;
  };

  renderEdit = (item) => {
    return (
      <i
        onClick={item.active === 'ACTIVE' ? (event) => this.props.onEditClick(event, item) : false}
        className="material-icons"
      >
        create
      </i>
    );
  };

  render() {
    return (
      <CardQueryComponent
        {...this.props}
        config={this.config}
        placeholder="Pesquisar por CPF, nome ou telefone"
        consumer
      />
    );
  }
}

CardCustomer.propTypes = {
  title: PropTypes.string.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  executeQuery: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default CardCustomer;
