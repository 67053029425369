/* eslint-disable no-useless-escape, no-control-regex */
export const currencyMask = (value) => {
  const formated = `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
  return formated;
};

export const maskDate = (value) => {
  let v = value.replace(/\D/g, '').slice(0, 10);

  if (v.length >= 5) return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4, 8)}`;
  else if (v.length >= 3) return `${v.slice(0, 2)}/${v.slice(2)}`;

  return v;
};

export const maskHour = (value) => {
  let number = value.replace(/(\D)+/g, '');
  const len = number.length;
  let valor, values, n;
  switch (len) {
    case 1:
    case 2:
      return number > 24 ? '23' : number;
    case 3:
    case 4:
      valor = number.replace(/(\d{2})([0-5][0-9]{0,2})$/, '$1:$2');
      values = valor.split(':');
      n = values.length;
      if (n > 1) {
        values[0] = values[0] > 23 ? 23 : values[0];
        values[1] = values[1] > 59 ? 59 : values[1];
      }
      if (n === 1) {
        values = values > 23 ? 23 : values;
      }
      return values.join(':');
    case 5:
      return number.replace(/^(\d{2})(\d{2})(\d{1})$/, '$1:$2:$3');
    case 6:
      valor = number.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2:$3');
      values = valor.split(':');
      n = values.length;
      if (n > 2) {
        values[0] = values[0] > 23 ? 23 : values[0];
        values[1] = values[1] > 59 ? 59 : values[1];
        values[2] = values[2] > 59 ? 59 : values[2];
      }
      return values.join(':');
    default:
      return number.substring(0, 6).replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2:$3');
  }
};

export const numberMask = (e) => {
  var regex = new RegExp('^[0-9,\b]+$');
  var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!regex.test(key)) {
    e.preventDefault();
    return false;
  }
  return true;
};

export const numberIntMask = (evt) => {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;

  return true;
};

export const autorizationMask = (e) => {
  var regex = /^[A-Za-z0-9]+/;
  var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!regex.test(key)) {
    e.preventDefault();
    return false;
  }
  return true;
};

export const currencyString = (value) => {
  return (value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const formatDateHour = (value) => {
  let dateHour = new Date(value);

  let dateHourFormated = new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'America/Bahia',
  }).format(dateHour);

  return dateHourFormated;
};
