import styled from 'styled-components';

import MaskedTextInput from 'react-text-mask';

export const InputMasked = styled(MaskedTextInput)`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;

  width: 100%;
  height: 40px;
`;
