import styled from 'styled-components';

export const PaymentPixWrapper = styled.div`
  .btn-finish {
    position:absolute;
    bottom:0;
    width: 150px;
    height: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-btn {
    float: right;
    .button {
      margin: 10px 0 15px;
    }
    /* &.btn-large {
      color: red;
      &:focus {
        background-color: #462171;
      }
    } */
  }
`;

export const PaymentPixHeader = styled.header`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight:bold;
`;

export const PaymentPixInput = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-row-gap: 0;
  grid-template-columns: 1fr 1fr;

  &:after {
    content: '';
    display: block;

  }

  .error {
    color: red;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 10px 0;
    label {
      display: block;
      margin-bottom: 8px;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    border: 1px solid #e8e8e8;
    margin: 10px 0;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      font-size: 2em;
      justify-content: center;
      item-align: center;
      color: #000;
      margin-top: 10px;
    }

    .leftover {
      flex: 0 0 20px;
      div {
        margin-top: unset;
      }
    }

    div {
      margin-top: 10px;
      color: #000;
      display: block;
    }

  }

  input {
    height: 50px;
    font-size: 18px ;
    border: 2px solid #eee;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 5px;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  div {
    /* grid-column: 7/8; */
    .button {
      margin: 10px 0 15px;
    }
  }


  /* button.btn-outline {
    text-transform: uppercase;
    color: #5B2E90;
    letter-spacing: 1px;
  } */
`;

export const InputWrapper = styled.div`
`;
