function MonitorActions() {
    this.isTefSale = (order) => order != null && order.paymentList.some(p => p.controlCode != null);
    this.shouldEnableVisualizar = (order) => order != null && order.orderStatus.code === 0;
    this.shouldEnableReprintTef = (order) => order != null && this.isTefSale(order);
    this.shouldEnableReauthorize = (order) => order != null && order.orderStatus.code === 2;
    this.shouldEnableConsultar = (order) => order != null && [0, 1, 2, 5, 6].includes(order.orderStatus.code);
    this.shouldEnableCancel = (order) => order != null && order.orderStatus.code === 0;
    this.shouldEnableInutilizar = (order) => order != null && order.orderStatus.code === 5;
    this.shouldEnableCancelTef = (order) => this.isTefSale(order);
}
export default new MonitorActions();