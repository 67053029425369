import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

export const requiredFieldsMessage =
  "(*) Informações com preenchimento obrigatório";
function Fieldset({
  legend,
  hasRequiredField,
  clearFieldsText,
  onClearFieldsClick,
  children,
}) {
  return (
    <Container>
      {hasRequiredField && (
        <span>{requiredFieldsMessage}</span>
      )}
      <fieldset>
        <legend>
          {legend}
          {clearFieldsText && (
            <div data-testid="clearField">
              ({' '}
              <span className="clearField" onClick={onClearFieldsClick}>
                {clearFieldsText}
              </span>{' '}
              )
            </div>
          )}
        </legend>
        <div>{children}</div>
      </fieldset>
    </Container>
  );
}

Fieldset.propTypes = {
  legend: PropTypes.string.isRequired,
  hasRequiredField: PropTypes.bool,
  clearFieldsText: PropTypes.string,
  onClearFieldsClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Fieldset.defaultProps = {
  hasRequiredField: false,
  clearFieldsText: null,
  onClearFieldsClick: null,
};

export default Fieldset;
