import styled from 'styled-components';

export const LotControlWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    font-size: 18px;
    letter-spacing: 2px;
    margin-bottom: 45px;
    color: #848484;
  }

  input {
    height: 25px;
    width: 80px;
    text-align: center;
  }

  #table {
    flex: 1;
  }

  .actions {
    margin-top: 20px;
    justify-content: flex-end;
  }
`;

export const WarningLabel = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: #848484;
`
