import React from 'react';

export class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.tAreaRef = React.createRef();
  }

  focus = () => {
    this.tAreaRef.current.focus();
  }

  render() {
    const {
      className,
      onChange,
      value,
      onKeyDown
    } = this.props;
    return (
      <textarea 
        onChange={onChange}
        value={value}
        className={`control ${className}`}
        onKeyDown={onKeyDown}
        ref={this.tAreaRef}
        >
      </textarea>
    );
  }
}