import ConfigTerminalIcon from '../../assets/images/menu/configTerminal.png';
import ConfigTefIcon from '../../assets/images/menu/tef.png';
import ConfigPBMIcon from '../../assets/images/menu/pbms.png';
import getShortcut, {
  FIRST_OPTION,
  SECOND_OPTION,
  THIRD_OPTION,
  FOURTH_OPTION
} from '../../keyboardShortcuts';
import {
  OPERATION_NAME_CONFIG_TERMINAL,
  OPERATION_NAME_CONFIG_PBM,
  OPERATION_NAME_CONFIG_TEF,
  OPERATION_NAME_CONFIG_POSCONNECT
} from './operations';

const items = [
  {
    operationName: OPERATION_NAME_CONFIG_TERMINAL,
    icon: ConfigTerminalIcon,
    isHidden: true,
    shortKey: getShortcut(FIRST_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
  {
    operationName: OPERATION_NAME_CONFIG_PBM,
    icon: ConfigPBMIcon,
    isHidden: true,
    shortKey: getShortcut(SECOND_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
  {
    operationName: OPERATION_NAME_CONFIG_TEF,
    icon: ConfigTefIcon,
    isHidden: true,
    shortKey: getShortcut(THIRD_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  },
  {
    operationName: OPERATION_NAME_CONFIG_POSCONNECT,
    icon: ConfigTerminalIcon,
    isHidden: true,
    shortKey: getShortcut(FOURTH_OPTION),
    showOnCashDeskOpened: true,
    showBothPdv: true,
    enabled: true,
  }
];

export default items;
