/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { PbmInitializationOrizon } from '../../shared/components/pbm-initialization-orizon'
import { shouldShow, closeCard, INITIALIZATION_ORIZON, showCard } from '../../redux/actions/routerAction';
import { showToast } from '../../shared/utils/util';
import pbmSaleService, { PBM_ORIZON } from '../../services/pbmSaleService';
import MessageBusService from '../../services/MessageBusService';
import moment from 'moment';

function PbmInitializationOrizonContainer(props) {
    const modalRef = React.createRef();
    const initialPlan = [{ codigoConvenio: 0, nomeConvenio: 'Selecione', imagemCartao: './assets/images/credit-card.png', comunicado: '' }];
    const dispatch = useDispatch();
    const visible = useSelector(state => shouldShow(state, INITIALIZATION_ORIZON));
    const [discountValues, setDiscountValues] = useState([]);
    const [showLargeCard, setShowLargeCard] = useState(false);
    const [plans, setPlans] = useState([]);
    const [formData, setFormData] = useState({
        plano: {
            value: 0,
            description: '',
            card: './assets/images/credit-card.png'
        },
        beneficiario: {
            descontoFolha: 0,
            cartao: '',
            dataNascimento: ''
        },
        receita: {
            conselho: 'CRM',
            numero: '',
            dataReceita: new Date(moment().year(), moment().month(), moment().date()),
            uf: 'Selecione'
        },
        produtos: []
    });
    const [formStep, setFormStepData] = useState(1);
    const [isPlanReload, setIsPlanReload] = useState(false);
    const closeCardOrizon = (channel, payload) => {
        dispatch(closeCard(INITIALIZATION_ORIZON));
    }
    const handleInitialization = (channel, payload) => {
        setPlans(initialPlan.concat(payload.convenios));
        if (isPlanReload) {
            setFormData({
                ...formData, plano: {
                    value: 0,
                    description: '',
                    card: './assets/images/credit-card.png'
                },
            });
        }
        else {
            let products = payload.orderItems.map(i => {
                return {
                    product: i.productName,
                    barcode: i.productBarcode,
                    quantity: i.quantity,
                    value: i.subTotalDiscount,
                    continuousUse: false,
                    radioValues: [{ description: 'Não', value: 0, selected: true }, { description: 'Sim', value: 1 }]
                }
            });
            setFormData({
                plano: {
                    value: 0,
                    description: '',
                    card: './assets/images/credit-card.png'
                },
                beneficiario: {
                    descontoFolha: 0,
                    cartao: '',
                    dataNascimento: ''
                },
                receita: {
                    conselho: 'CRM',
                    numero: '',
                    dataReceita: new Date(moment().year(), moment().month(), moment().date()),
                    uf: ''
                },
                produtos: products
            });
            setFormStepData(1);
            setDiscountValues([{ description: 'Não', value: 0, selected: true }, { description: 'Sim', value: 1, selected: false }]);
            dispatch(showCard(INITIALIZATION_ORIZON, null, true));
        }
        setIsPlanReload(false);
    }
    function handleClose() {
        pbmSaleService.closeInitialization(PBM_ORIZON)
    };
    function handleZoomCard() {
        var showCard = !showLargeCard;
        setShowLargeCard(showCard);
        if (showCard)
            modalRef.current.open();
        else
            modalRef.current.hide();
    }
    function handleChange(e) {
        let form = formData;
        switch (e.target.id) {
            case 'plano':
                var selPlans = plans.map(p => { p.selected = p.codigoConvenio === e.target.options[e.target.options.selectedIndex].id; return p; });
                setPlans(selPlans);
                let plan = selPlans.find(p => p.selected);
                setFormData({
                    ...formData,
                    plano: {
                        value: plan.codigoConvenio,
                        card: plan.imagemCartao,
                        description: plan.comunicado ? plan.comunicado : ''
                    }
                });
                break;
            case 'Cartao':
                setFormData({
                    ...formData,
                    beneficiario: {
                        cartao: e.target.value,
                        dataNascimento: formData.beneficiario.dataNascimento,
                        descontoFolha: formData.beneficiario.descontoFolha
                    }
                });
                break;
            case 'DataNascimento':
                setFormData({
                    ...formData,
                    beneficiario: {
                        cartao: formData.beneficiario.cartao,
                        dataNascimento: e.target.value,
                        descontoFolha: formData.beneficiario.descontoFolha
                    }
                });
                break;
            case 'DataReceita':
                setFormData({
                    ...formData,
                    receita: {
                        conselho: formData.receita.conselho,
                        numero: formData.receita.numero,
                        uf: formData.receita.uf,
                        dataReceita: e.target.value
                    }
                });
                break;
            case 'NumConselho':
                setFormData({
                    ...formData,
                    receita: {
                        conselho: formData.receita.conselho,
                        numero: e.target.value,
                        uf: formData.receita.uf,
                        dataReceita: formData.receita.dataReceita
                    }
                });
                break;
            case 'Conselho':
                setFormData({
                    ...formData,
                    receita: {
                        conselho: e.target.value,
                        numero: formData.receita.numero,
                        uf: formData.receita.uf,
                        dataReceita: formData.receita.dataReceita
                    }
                });
                break;
            case 'UFConselho':
                setFormData({
                    ...formData,
                    receita: {
                        conselho: formData.receita.conselho,
                        numero: formData.receita.numero,
                        uf: e.target.value,
                        dataReceita: formData.receita.dataReceita
                    }
                });
                break;
            default:
                form[e.target.id] = e.target.value || null;
                setFormData(form);
                break;
        }
    }
    function handleDiscountChange(values) {
        let value = values.find(f => f.selected).value;
        setFormData({
            ...formData,
            beneficiario: {
                cartao: formData.beneficiario.cartao,
                dataNascimento: formData.beneficiario.dataNascimento,
                descontoFolha: value
            }
        });
    }
    function handleContinuedUseChange(values, id) {
        let changes = formData.produtos.map((o, i) => {
            if (formData.produtos.indexOf(o) === id) {
                o.continuousUse = values.find(v => v.value === 1).selected;
                o.radioValues = [...values];
            }
            return o;
        });
        setFormData({
            ...formData,
            produtos: changes
        });
    }
    function validateForm() {
        return formData.plano.value > 0 &&
            formData.beneficiario.cartao.trim().length > 0 &&
            formData.beneficiario.dataNascimento !== '' &&
            formData.beneficiario.dataNascimento !== null &&
            formData.receita.numero.trim().length > 0 &&
            formData.receita.uf !== "Selecione";
    }
    function handleChangeStep() {
        if (validateForm())
            setFormStepData(formStep + 1);
        else
            showToast({
                type: 1,
                title: 'Inicialização PBM Orizon',
                html: 'Os campos: "Plano", "Número Carteirinha" e "Data de nascimento", "Número do conselho" e "UF" são obrigatórios.',
                hideAfterMilliseconds: 8000
            });
    }
    function handleBack() {
        if (formStep === 2)
            setFormStepData(formStep - 1);
        else
            handleClose();
    }
    function handleAuthorize() {
        //chama posstate
        pbmSaleService.startSale(PBM_ORIZON, parseForm());
    }
    function parseForm() {
        return {
            convenio: {
                codigoConvenio: formData.plano.value,
            },
            cardNumber: formData.beneficiario.cartao,
            prescriptionDate: formData.receita.dataReceita,
            prescriptorUf: formData.receita.uf,
            prescriptorType: formData.receita.conselho,
            prescriptorDocument: formData.receita.numero,
            products: parseProducts(formData.produtos),
            patientBirthDate: formData.beneficiario.dataNascimento,
            isPayrollDiscount: formData.beneficiario.descontoFolha === 1
        }
    }
    function parseProducts(items) {
        var parsed = items.map(o => {
            return {
                quantity: o.quantity,
                product: {
                    name: o.product,
                    barcode: o.barcode,
                    price: o.value,
                    isContinuedUseMedication: o.continuousUse
                }
            }
        });
        console.log(parsed);
        return parsed;
    }
    function reload() {
        setIsPlanReload(true);
        pbmSaleService.getInitializationData(PBM_ORIZON);
    }
    useEffect(() => {
        const showSubscription = MessageBusService.GetInstance().Subscribe('pos.pbm.initialization.orizoninitialization', handleInitialization);
        const closeSubscription = MessageBusService.GetInstance().Subscribe('pos.pbm.closeInitialization', closeCardOrizon);
        return () => {
            MessageBusService.GetInstance().Unsubscribe(showSubscription);
            MessageBusService.GetInstance().Unsubscribe(closeSubscription);
        };
    }, [visible, handleInitialization, closeCardOrizon]);

    return (visible && <PbmInitializationOrizon
        plans={plans}
        handleChangeStep={handleChangeStep}
        formStep={formStep}
        discountValues={discountValues}
        handleDiscountChange={handleDiscountChange}
        handleChange={handleChange}
        handleAuthorize={handleAuthorize}
        handleBack={handleBack}
        form={formData}
        handleZoomCard={handleZoomCard}
        modalRef={modalRef}
        handleContinuedUseChange={handleContinuedUseChange}
        handleReload={reload}
    ></PbmInitializationOrizon>);
}
export default PbmInitializationOrizonContainer;
