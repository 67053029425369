import React from 'react';
import { ProductService } from '../../services/productService';
import SaleService from '../../services/saleService';
import ProductSearch from '../../shared/components/product-search/product-search';
import { STOCKLIST } from '../../redux/actions/views';
import { closeAllCards, PRODUCT_SEARCH, shouldShow, getPayload, showWithOthers } from '../../redux/actions/routerAction';
import { connect } from 'react-redux';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import { cacheSngpc } from '../sngpc/cache-sngpc';
import { StockWrapper } from '../../shared/components/product-search/styles';
import Stock from '../../assets/images/stock.png';
import { showToast } from '../../shared/utils/util';

export class ProductSearchContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: null,
      isToggleOnOptionStock: false,
      isToggleOnOptionSearchRule: false,
      options: {
        toggleOnOptionStock: [
          {
            value: 0,
            name: 'Cadastrado (Todos)',
          },
          {
            value: 1,
            name: 'Com estoque',
          },
          {
            value: 2,
            name: 'Sem estoque',
          }
        ],
        toggleOnOptionSearchRule: [
          {
            value: 0,
            name: 'Iniciar por: ',
          },
          {
            value: 1,
            name: 'Contém: ',
          }
        ]
      },
      toggleOnOptionStockSelected: 0,
      toggleOnOptionSearchRuleSelected: 0
    }

    this.productService = new ProductService();
    this.saleService = new SaleService();

    this.addProduct = this.addProduct.bind(this);
    this.focusProductFilter = this.focusProductFilter.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderBranchesStock = this.renderBranchesStock.bind(this);

    this.waitingOrderUpdate = false;

    this.props.MessageBus.Subscribe('pos.product.showcase.cancel', this.props.closeProductSearch);
    this.props.MessageBus.Subscribe('pos.order.updated', this.orderUpdated.bind(this));
  }

  ClickSelectToggleOnOptionRule = (option) => {
    this.setState({ toggleOnOptionSearchRuleSelected: option.value });
  };

  ClickSelectToggleOnOptionStock = (option) => {
    this.setState({ toggleOnOptionStockSelected: option.value });
  };

  ClickToggleOnOption = (value) => {
    this.setState({ [value]: !this.state[value] });
  };

  handleClose() {
    this.productService.cancelShowCaseProduct();
    this.setState({ isToggleOnOptionStock: false, isToggleOnOptionSearchRule: false });
  }

  addProduct(product) {
    if (product.insertlessByDescription) {
      this.context.showMessage(
        'Esse item só pode ser adicionado pela leitura ou digitação do código de barras.',
        'ADICIONAR PRODUTOS',
        this.handleFocus
      );
      return;
    }

    this.waitingOrderUpdate = true;
    const quantity = this.props.quantityCurrent.quantity > 1 ? this.props.quantityCurrent.quantity : 1;
    this.saleService
      .addItem(product.barcodes[0], quantity)
      .catch(error => this.context.showError(error));
  }

  handleFocus = () => {
    this.waitingOrderUpdate = true;
    this.productSearchRef.focusInput();
  }

  orderUpdated() {
    if (this.waitingOrderUpdate && !cacheSngpc.lotConfirmedFromSngpc) {
      this.handleClose();
      this.waitingOrderUpdate = false;
    }
  }

  focusProductFilter() {
    if (this.productSearchRef)
      this.productSearchRef.focusFilter();
  }

  renderBranchesStock(item) {
    return (<StockWrapper><button onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.props.isOnline) {
        item.hideCoupon = true;
        this.props.openStockList(item);
      }
      else
        showToast({
          type: 2,
          title: 'Estoque de filiais',
          html: 'Funcionalidade não disponível na malha offline!',
          hideAfterMilliseconds: 5000
        });
    }}><img src={Stock}></img>
    </button></StockWrapper >);
  }

  render() {
    const query = typeof this.props.queryProductSearch === 'string'
      ? { query: this.props.queryProductSearch, source: 'product-search' }
      : '';

    const quantity = this.props.quantityCurrent.quantity > 1 ? this.props.quantityCurrent.quantity : 1;

    return (this.props.showProductSearch && (
      <ProductSearch
        {...this.props}
        ref={ref => this.productSearchRef = ref}
        handleClose={this.handleClose}
        onRowSelect={this.addProduct}
        idInput="input-product-search"
        placeholder="Pesquisar"
        initialQuery={query}
        quantity={quantity}
        renderBranchesStock={this.renderBranchesStock}
        isToggleOnOptionSearchRule={this.state.isToggleOnOptionSearchRule}
        isToggleOnOptionStock={this.state.isToggleOnOptionStock}
        clickToggleOnOption={this.ClickToggleOnOption}
        options={this.state.options}
        toggleOnOptionStockSelected={this.state.toggleOnOptionStockSelected}
        toggleOnOptionSearchRuleSelected={this.state.toggleOnOptionSearchRuleSelected}
        clickSelectToggleOnOptionSearchRule={this.ClickSelectToggleOnOptionRule}
        clickSelectToggleOnOptionStock={this.ClickSelectToggleOnOptionStock}
      />
    ))
  }
}

const mapStateToProps = state => ({
  showProductSearch: shouldShow(state, PRODUCT_SEARCH),
  queryProductSearch: getPayload(state, PRODUCT_SEARCH),
  isOnline: state.pdvContingency.isOnline,
  quantityCurrent: state.quantity
});

const mapDispatchToProps = dispatch => ({
  closeProductSearch: () => dispatch(closeAllCards()),
  openStockList: (item) => dispatch(showWithOthers(STOCKLIST, item, true))
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(ProductSearchContainer)));
