import styled from 'styled-components';
import theme2 from '../../../themes/theme2';

export const FormDetail = styled.div`
  .require-auth {
    padding: 15px 0 0 0;

    .form-group-require-auth {
      margin-bottom: 0.5rem;
    }
    
    label {
      margin-bottom: 4px;
      font-size: initial;
      line-height: 20px;
      font-weight: bold;
    }

    input {
      height: 40px;
      font-size: 14px;
    }

    input:focus, textarea:focus {
      border: 1px solid ${theme2.palette.primary};
      box-shadow: none;
      outline: none;
    }
  
    textarea {
      height: 120px !important;
      resize: none;
    }

    button {
      margin-top: ${theme2.metrics.baseMargin}px;
      color: #fff;
    }

    .input-error {
      border: 1px solid #F35958;

      &:focus {
        border: 1px solid #F35958;
      }
    }

    .message-error {
      display: block;
      color: #F35958;
      font-size: 12px;
      height: 15px;
      margin-top: 0.3rem;
    }  
  }

`;
