import React, { useState } from 'react';

import { Form, Formik, Field } from 'formik';
import { utc } from 'moment';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';
import moment from 'moment';

import { currencyString } from '../../utils/masks';
import FormControl from '../@form-control';
import { DatePicker } from '../date-picker';
import { Button, Card, TooltipIcon } from '../modules';


import './financial-document-receiptment.style.scss';
import {
  EmptyData,
  Filters,
  FooterItem,
  FooterItemDiscount,
  FooterItemsContainer,
  FooterItems,
  Table,
  Total,
  FooterItemReceive,
} from './styles';
import validationSchema from './validation';
import { Fragment } from 'react';

export function FinancialDocumentReceiptmentComponent({
  data,
  handleConfirm,
  handleClose,
  handleFind,
  discountType,
  handleDiscountTypeChange,
  totalSelected,
  discountValue,
  debitValue,
  handleDebitChange,
  handleDiscountChange,
  valueToReceive,
  interestSelectedValue,
  interestManualValue,
  handleInterestChange,
  handleChangeSelected,
  handleChangeSelectedAll,
  selectedCustomer,
  handleCleanCustomer,
  isAnyDataReturned,
  startDateState,
  endDateState,
  handleShowChild,
  handleValueToReceiveChange,
  showDialog,
  debitValueErrorMessage }) {
  const [selectedAll, setSelectedAll] = useState(false);
  const [initialValues, setInitialValues] = useState({
    startDate: startDateState !== null ? startDateState : null,
    endDate: endDateState !== null ? endDateState : null,
    cliente: ''
  })

  React.useEffect(() => {
    setInitialValues({
      ...initialValues,
      startDate: startDateState,
      endDate: endDateState,
    });
    // eslint-disable-next-line
  }, [startDateState, endDateState])

  function handleCheckboxSelectAllChange() {
    setSelectedAll(!selectedAll);
    handleChangeSelectedAll(!selectedAll);
  }

  function getHeaders() {
    return (
      <tr>
        <th>
          <i
            data-testid="select-all"
            className={`material-icons ${selectedAll ? 'active' : ''}`}
            onClick={handleCheckboxSelectAllChange}
          >
            {selectedAll ? 'check_box' : 'check_box_outline_blank'}
          </i>
        </th>
        <th className="alignLeft"></th>
        <th className="alignLeft">Nome</th>
        <th>Vencimento</th>
        <th>Parcela</th>
        {/* <th>Nota</th> */}
        <th className="alignRight">Valor do título</th>
        <th className="alignRight">Saldo do título</th>
        <th className="alignRight">Juros</th>
        <th className="alignRight">Saldo devedor</th>
      </tr>
    );
  }

  function handleKeyDown(e, item) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleChangeSelected(item);
    }
    if (e.key === 'ArrowUp' && e.target.previousSibling)
      e.target.previousSibling.focus();
    if (e.key === 'ArrowDown' && e.target.nextSibling)
      e.target.nextSibling.focus();
  }

  function customCellrender(item) {
    return (
      <i className={`material-icons ${item.selected ? 'active' : ''}`}>
        {item.selected ? 'check_box' : 'check_box_outline_blank'}
      </i>
    );
  }

  function getRow(doc, index) {
    return (
      <Fragment>
        <tr
          key={index}
          tabIndex="0"
          className={doc.financialDocumentStatus === 99 ? "selectable processing" : "selectable"}
          onClick={() => handleChangeSelected(doc)}
          onKeyDown={(e) => handleKeyDown(e, doc)}
        >
          <td className="cxs">{doc.financialDocumentStatus === 99 ? "" : customCellrender(doc)}</td>
          <td className="cxs"><i onClick={(event) => { event.stopPropagation(); handleShowChild(index) }} className="material-icons purple">{doc.showChild ? "remove_circle_outline" : "add_circle_outline"}</i></td>
          <td
            className="alignLeft"
            style={{
              width: "35%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "0"
            }}
            title={doc.customerName}
          >{doc.customerName}</td>
          <td>{utc(doc.financialDocumentExpiration).format('DD/MM/YYYY')}</td>
          <td>{doc.installments}</td>
          <td className="alignRight">
            {currencyString(doc.financialDocumentAmount).replace('R$', 'R$ ')}
          </td>
          <td className="alignRight">
            {doc.financialDocumentStatus === 99 ? "" : currencyString(doc.financialDocumentBalance).replace('R$', 'R$ ')}
          </td>
          <td className="alignRight">
            {doc.financialDocumentStatus === 99 ? "Em processamento" : currencyString(doc.interestValue).replace('R$', 'R$ ')}
          </td>
          <td className="alignRight">
            <strong>
              {doc.financialDocumentStatus === 99 ? "" : currencyString(doc.financialDocumentBalanceWithInterest).replace('R$', 'R$ ')}
            </strong>
          </td>
        </tr>
        {
          doc.items ? getChild(doc) : getMessage(doc)
        }
      </Fragment>
    );
  }

  function getMessage(doc) {
    return (
      doc.showChild ?
        <tr>
          <td colSpan="8">
            <div className="child">
              <ul className="headerchild">
              </ul>
              <li className="alignLeft">Título sem produtos, incluído manualmente e sem vínculo com venda.</li>
            </div>
          </td>
        </tr>
        : ""
    )
  }

  function getChild(doc) {
    return (
      doc.showChild ?
        <tr>
          <td colSpan="8">
            <div className="child">
              <ul className="headerchild">
                <li>
                  Descrição do produto
                </li>
                <li>
                  Fabricante
                </li>
                <li>
                  Preço unitário
                </li>
              </ul>
              {doc.items.sort((a, b) => a.name.localeCompare(b.name)).map(o => <ul className="datachild">
                <li className="alignLeft"><div className="circle"></div> {o.name}</li>
                <li className="alignLeft">{o.supplier}</li>
                <li className="alignLeft">{currencyString(o.price).replace('R$', 'R$ ')}</li>
              </ul>)}
            </div>
          </td>
        </tr>
        : ""
    )
  }

  function selectRange(e) {
    e.persist();
    setTimeout(() => {
      e.target.setSelectionRange(20, 20);
    });
  }
  return (
    <Card
      autoScroll={false}
      id="card-financial-document-receiptment"
      title="Baixa de Títulos"
      closeEnable
      handleClose={handleClose}
      shouldFlex={!!data.length}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFind}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          const handleChangeRawDate = (e, field) => {
            const date = e.target.value;

            if (date === undefined) {
              return;
            }

            const formatedDate = moment(date, "DD/MM/YYYY");

            if (date.length === 10 && !date.includes("_")) {
              setFieldValue(field, formatedDate.toDate())
            } else if (date.length === 10 && date.includes("_")) {
              setFieldValue(field, "InvalidDate")
            }
          }
          return (
            <Form>
              <Filters style={{ display: "flex", alignItems: "flex-end", marginBottom: "8px" }}>
                <div id="receiptmentClienteContainer">
                  <FormControl
                    name="cliente"
                    label="Cliente"
                    placeholder={selectedCustomer !== null ? selectedCustomer?.name : "Pesquise pelo Nome, CPF ou Telefone do cliente..."}
                    flex="1"
                    autoFocus
                  />
                  <Button
                    id="btnDeleteCustomer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCleanCustomer();
                    }}
                  >
                    Limpar
                  </Button>
                </div>
                <FormControl label="Período de vencimento" flex="0.3" name="startDate">
                  <Field name="startDate">
                    {({ props }) => (
                      <DatePicker
                        data-testid="startDate"
                        {...props}
                        onChange={(value) => {
                          setFieldValue('startDate', value)
                        }}
                        onChangeRaw={e => handleChangeRawDate(e, 'startDate')}
                        selected={(() => {
                          if (values.startDate === "InvalidDate") {
                            return null;
                          }
                          if (!moment(values.startDate).isValid() && values.startDate !== null) {
                            return null
                          }
                          if (values.startDate === null) {
                            return null
                          }
                          return values.startDate
                        })()}
                        placeholderText="Data inicial"
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl flex="0.3" name="endDate">
                  <Field name="endDate">
                    {({ props }) => (
                      <DatePicker
                        {...props}
                        onChange={(value) => {
                          setFieldValue('endDate', value)
                        }}
                        onChangeRaw={e => handleChangeRawDate(e, 'endDate')}
                        selected={(() => {
                          if (values.endDate === "InvalidDate") {
                            return null;
                          }
                          if (!moment(values.endDate).isValid() && values.endDate !== null) {
                            return null
                          }
                          if (values.endDate === null) {
                            return null
                          }
                          return values.endDate
                        })()}
                        placeholderText="Data final"
                      />
                    )}
                  </Field>
                </FormControl>
                <Button
                  data-testid="financial-document-receiptment-submit"
                  type="submit"
                >
                  <i className="material-icons">search</i>
                </Button>
              </Filters>
            </Form>
          );
        }}
      </Formik>
      {data && data.length > 0 ? (
        <>
          <Table className="table-sm" striped responsive hover>
            <thead>{getHeaders()}</thead>
            <tbody>{data.map((item, index) => getRow(item, index))}</tbody>
          </Table>
          <div className="footer">
            <Total>
              Total saldo devedor selecionado
              <span>{currencyString(totalSelected).replace('R$', '')}</span>
            </Total>
            <FooterItems>
              <FooterItemsContainer>
                <FooterItem>
                  <span>Total de juros<TooltipIcon position="custom" customValue="bottom: 0; right: -180px; transform: translateY(-50%);">Retire ou altere o valor de juros a cobrar aqui.</TooltipIcon></span>
                  <CurrencyInput
                    prefix="R$ "
                    field="interestInput"
                    id="interestInput"
                    decimalSeparator=","
                    thousandSeparator="."
                    value={interestManualValue}
                    onChange={handleInterestChange}
                    onClick={selectRange}
                    disabled={
                      discountValue !== "0,00" &&
                      discountValue !== ""
                    }
                  />
                </FooterItem>
                <FooterItem width="180px">
                  <span>Total saldo dos títulos<TooltipIcon position="top">Informe o valor a receber dos títulos.</TooltipIcon></span>
                  <CurrencyInput
                    prefix="R$ "
                    field="amountInput"
                    id="amountInput"
                    decimalSeparator=","
                    thousandSeparator="."
                    value={debitValue}
                    allowNegative
                    onChange={handleDebitChange}
                    onClick={selectRange}
                  />
                </FooterItem>

                <FooterItem>
                  <span>Desconto</span>
                  <CurrencyInput
                    prefix={discountType === 1 ? 'R$ ' : '% '}
                    field="discountInput"
                    id="discountInput"
                    decimalSeparator=","
                    thousandSeparator="."
                    allowNegative
                    maxLength={discountType === 1 ? 13 : 8}
                    value={discountValue}
                    onChange={handleDiscountChange}
                    onClick={selectRange}
                    disabled={
                      interestManualValue !== 0 && (
                        interestSelectedValue !== interestManualValue ||
                        interestSelectedValue > 0)
                    }
                  />
                </FooterItem>
                <FooterItemDiscount>
                  <i
                    onClick={() => handleDiscountTypeChange(1)}
                    className={`material-icons ${discountType === 1 ? 'active' : ''
                      }`}
                  >
                    {discountType === 1
                      ? 'radio_button_checked'
                      : 'radio_button_unchecked'}
                  </i>
                  <label htmlFor="real">R$</label>
                  <i
                    onClick={() => handleDiscountTypeChange(2)}
                    className={`material-icons ${discountType === 2 ? 'active' : ''
                      }`}
                  >
                    {discountType === 2
                      ? 'radio_button_checked'
                      : 'radio_button_unchecked'}
                  </i>
                  <label htmlFor="real">%</label>
                </FooterItemDiscount>
              </FooterItemsContainer>
              <FooterItemsContainer>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <FooterItemReceive>
                    <span>Valor a receber do cliente<TooltipIcon position="custom" customValue="bottom: 20px;">Informe o valor total a receber do cliente. Se existir valor de juros esse será cobrado e a diferença será amortizada do valor original dos títulos.</TooltipIcon></span>
                    <CurrencyInput
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      value={valueToReceive}
                      onClick={selectRange}
                      onChange={handleValueToReceiveChange}
                    />
                  </FooterItemReceive>
                  <FooterItem>
                    <Button
                      id="confirm"
                      className="btn btn-large btn-purple btn-custom"
                      onClick={handleConfirm}
                      disabled={debitValueErrorMessage != null ? 'disabled' : ''}
                    >
                      Receber
                    </Button>
                  </FooterItem>
                </div>
                {debitValueErrorMessage != null && <span className="error"><i className="material-icons">error</i>{debitValueErrorMessage}</span>}
              </FooterItemsContainer>
            </FooterItems>
          </div>
        </>
      ) : (
        <EmptyData>
          <img
            src="/assets/images/baixa-de-titulos.svg"
            alt="Baixa de Titulos"
          />
          {selectedCustomer !== null && !isAnyDataReturned ? <p>Não foi encontrado nenhum título para este cliente.</p> : ''}
        </EmptyData>
      )}
    </Card>
  );
}

FinancialDocumentReceiptmentComponent.propTypes = {
  handleFind: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChangeSelected: PropTypes.func.isRequired,
  handleChangeSelectedAll: PropTypes.func.isRequired,
};
