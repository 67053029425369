import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Button } from "../modules";
import {
  ContinuousUseItems,
  ContinuousUseSection,
  GroupContinuousUseItems,
} from "./style";
import {
  schemaNewHospitalClinic,
  schemaNewPathology,
  schemaNewPrescriber,
  schemaNewPrescriberSpecialty,
  schemaNewPrescriptionOrigin,
} from "./validation";
import {
  initialValuesNewPrescriber,
  NewPrescriber,
} from "./prescriber/new-prescriber";
import {
  initialValuesNewPrescriberSpecialty,
  NewPrescriberSpecialty,
} from "./prescriber-specialty/new-presciber-specialty";
import {
  initialValuesNewPrescriptionOrigin,
  NewPrescriptionOrigin,
} from "./prescription-origin/new-prescription-origin";
import {
  initialValuesNewHospitalClinic,
  NewHospitalClinic,
} from "./hospital-clinic/new-hospital-clinic";
import {
  initialValuesNewPathology,
  NewPathology,
} from "./pathology/new-pathology";
import { requiredFieldsMessage } from "../fieldset";
import { KeyboardNavigationProvider } from "../keyboard-navigation-form";

export const FormModal = ({
  closeModal,
  handleSubmit,
  registration,
  ...props
}) => {
  const getNewRegistrationData = (
    registration,
    setFieldValue,
    errors,
    touched,
    props
  ) => {
    const newRecords = {
      newPrescriber: {
        initialValues: initialValuesNewPrescriber,
        schema: schemaNewPrescriber,
        component: (
          <NewPrescriber
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            {...props}
          />
        ),
      },
      newPrescriberSpecialty: {
        initialValues: initialValuesNewPrescriberSpecialty,
        schema: schemaNewPrescriberSpecialty,
        component: (
          <NewPrescriberSpecialty
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            {...props}
          />
        ),
      },
      newPrescriptionOrigin: {
        initialValues: initialValuesNewPrescriptionOrigin,
        schema: schemaNewPrescriptionOrigin,
        component: (
          <NewPrescriptionOrigin
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            {...props}
          />
        ),
      },
      newHospitalClinic: {
        initialValues: initialValuesNewHospitalClinic,
        schema: schemaNewHospitalClinic,
        component: (
          <NewHospitalClinic
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            {...props}
          />
        ),
      },
      newPathology: {
        initialValues: initialValuesNewPathology,
        schema: schemaNewPathology,
        component: (
          <NewPathology
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            {...props}
          />
        ),
      },
    };

    return newRecords[registration];
  };
  return (
    <Formik
      validationSchema={getNewRegistrationData(registration).schema}
      initialValues={getNewRegistrationData(registration).initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <KeyboardNavigationProvider>
            {
              getNewRegistrationData(
                registration,
                setFieldValue,
                errors,
                touched,
                props
              ).component
            }

            <ContinuousUseSection margin="16px 0 0 0">
              <GroupContinuousUseItems>
                <span className="modal-required-fields">
                  {requiredFieldsMessage}
                </span>
                <ContinuousUseItems width="20%">
                  <Button
                    id="formModal-btn-goBack"
                    className="btn-outline"
                    onClick={closeModal}
                  >
                    VOLTAR
                  </Button>
                </ContinuousUseItems>
                <ContinuousUseItems width="20%">
                  <Button
                    id="formModal-btn-save"
                    className="btn-purple"
                    type="submit"
                  >
                    CADASTRAR
                  </Button>
                </ContinuousUseItems>
              </GroupContinuousUseItems>
            </ContinuousUseSection>
          </KeyboardNavigationProvider>
        </Form>
      )}
    </Formik>
  );
};

FormModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  registration: PropTypes.string.isRequired,
};
