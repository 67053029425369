import { AbstractService } from './abstractService';

export class ConfigService extends AbstractService {
    constructor() {
        super('/Config');
    }
    getQrCodeConfig = (posId, retailerId) =>  this.invokeAction('/qrCodeConfig', { posId, retailerId }, false);    
    getFarmaciaPopularConfig = (userId) =>  this.invokeAction('/farmaciaPopular', { userId }, false);
    getRetailerConfig = (retailerId) =>  this.invokeAction('/retailerConfig', { retailerId }, false);
    insertConfigIfNotExists = (retailerId, posId, configuration) =>  this.invokeAction('/insertifnotexists', { retailerId, posId, configuration }, false);
}

export default new ConfigService();