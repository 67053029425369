import styled from 'styled-components';
import theme from '../../../themes/default';

export const PbmOpenWrapper = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;

  .btn-custom {
    margin-top: 10px;
    width: 120px;
    height: 50px;
    align-self: flex-end;
    border: 2px solid ${theme.palette.primary[14]};
    color: ${theme.palette.primary[14]};
  }

  #change-number-type {
    height: 17px;

    input + .slider {
      background-color: #41a6f4;
    }

    .slider:before {
      height: 11px;
      width: 11px;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  label.disable {
    color: #c7c7c7;
  }
`;
