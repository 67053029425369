import React, { Component } from 'react';
import { connect } from 'react-redux';

import StatusConnection from '../../shared/components/status-connection';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';

class ServiceOnOff extends Component {
  constructor(props) {
    super(props);    

    this.state = {
      isOnline: 'online',
      fiscalFlowOnline: 'offline',      
    };

  }

  componentDidUpdate(prevProps) {      
    if (prevProps.fiscalFlow.onlineFiscalFlow !== this.props.fiscalFlow.onlineFiscalFlow) {
      this.setState({
        fiscalFlowOnline: this.props.fiscalFlow.onlineFiscalFlow ? 'online' : 'offline',        
      });
      if (this.props.fiscalFlow.onlineFiscalFlow === false) {
        this.context.showMessage('Falha na conexão com Fiscal Flow. Para emissão de nota inicie o serviço do programa Linx.Mid-e.Client.ServicoConfig.')
      }
    }
  }

  isConnect(payload) {
    return payload.isOnline ? 'online' : 'offline'
  }

  render() {    
    const { fiscalFlowOnline } = this.state;
    return (
      <>
        <StatusConnection title={'STATUS:'} isOnline={this.isConnect(this.props.pdvContingency)} textClass={this.isConnect(this.props.pdvContingency)} visible={true}/>
        <StatusConnection title={'FISCAL FLOW:'} isOnline={fiscalFlowOnline} textClass={fiscalFlowOnline} visible={this.props.posType === 2}/>
      </>
    )
  }
}

const mapStateToProps = (state) => ({  
  fiscalFlow: state.fiscalFlow,
  posType: state.config && state.config.posType,
  pdvContingency: state.pdvContingency
});

export default connect(mapStateToProps, null)(withMessageBus(getContext(ServiceOnOff)));