import styled from 'styled-components';

import theme from '../../../themes/default';

const select = styled.div`  
  position: relative;
  background-color: #fff;
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;  
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  border: 2px solid #5b2e90;
  border-radius: 4px;
  &.title-select {
    display: flex;
    white-space: nowrap;
  }
  &.disabled {
    color: #ebebe4 !important;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: #5B2E90;
    color: white;   
  }

  .icon-align {
    display: flex;
  }

  .menu-area {
    margin-top: 55px;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 100.5%;
    display: grid;    
    background-color: white;
    box-shadow: 0 0 8px #5b2e90;
    font-size: 15px;
    z-index: 500;    
    
    span {      
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 auto;
      font-weight: bold;
      height: 40px;
      line-height: 42px;
      padding: 0 10px;
      color: ${theme.palette.grayscale.ultraDark};
      &:focus,
      &:hover {
        background-color: #ffb200;
      }
    }
  }
`;
export default select;
