import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentButtonList from '../../shared/components/payment-button-list';
import { showCard, closeAllCards, PAYMENT_OPTIONS, shouldShow } from '../../redux/actions/routerAction';
import { withMessageBus } from '../../shared/utils/messageBus';
import { getContext } from '../../shared/hoc/getContext';
import SaleService from '../../services/saleService';
import PaymentService from '../../services/paymentService';

export class PaymentButtonListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = (
      {
        paymentType: '',
      }
    );
    this.handleClose = this.handleClose.bind(this);
    this.props.MessageBus.Subscribe('pos.payment.typesListed', this.eventReturn.bind(this));
    this.props.MessageBus.Subscribe('pos.payment.didSetType', this.closeListPayments.bind(this));
    this.props.MessageBus.Subscribe('pos.order.deleted', this.closeListPayments.bind(this));
    this.props.MessageBus.Subscribe('pos.documentNumber.show', this.closeListPayments.bind(this));

    this.saleService = new SaleService();
    this.paymentService = new PaymentService();
  }

  closeListPayments(canal, payload) {
    this.props.closeListPayments(payload);
  }

  handleClose() {
    this.props.closeListPayments();
    this.paymentService.backPayment()
      .catch(err => this.context.showError(err));
  }

  eventReturn(canal, payload) {
    if (payload.paymentTypes.length <= 0) {
      this.context.showMessage('Nenhum tipo de pagamento encontrado');
    } else {
      let data = this.props.isOnline ? payload.paymentTypes :
      payload.paymentTypes.filter(f => {
        let onlyOnlineTypes = [4, 5, 9, 11, 12];
        return onlyOnlineTypes.indexOf(f.enumValue) === -1
      });
      this.setState({
        paymentType: data,
        amountRemaining: payload.amount || this.props.order.order.orderTotal,
      });
    }
    this.props.openPaymentOptions(payload);
  }

  onHandlePaymentType = (paymentType) => {
    if(this.props.order.order.paymentMethod.pecFidelidade && paymentType.enumValue === 7){
      this.context.showMessage('Não é permitido selecionar meio de recebimento convênio na modalidade PEC Fidelidade.');
      return;
    }

    this.paymentService.pay(paymentType)
      .then(resp => resp, (error) => this.context.showError(error));
  }

  render() {
    const paymentTypes = this.state.paymentType;
    const amountRemaining = this.state.amountRemaining;
    return (
      <div>
        {
          this.props.showPaymentOptions && paymentTypes.length > 0 && (
            <PaymentButtonList
              {...this.props}
              amountRemaining={amountRemaining}
              paymentTypeList={paymentTypes}
              handlerOnPaymentType={this.onHandlePaymentType}
              handleClose={this.handleClose}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showPaymentOptions: shouldShow(state, PAYMENT_OPTIONS),
  order: state.order,
  isOnline: state.pdvContingency.isOnline
});

const mapDispatchToProps = dispatch => ({
  openPaymentOptions: () => dispatch(showCard(PAYMENT_OPTIONS)),
  closeListPayments: () => dispatch(closeAllCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMessageBus(getContext(PaymentButtonListContainer)));
